import React from 'react';
import Switch from '../../../../Switch';
import classes from './styles.module.scss';

export default function NotificationItem({
  label,
  description,
  value,
  setValue,
}) {
  return (
    <div className={classes.NotificationItem}>
      <div className={classes.container}>
        <span className={classes.label}>{label}</span>
        <span className={classes.description}>{description}</span>
      </div>
      <Switch isEnabled={value} onClick={setValue} />
    </div>
  );
}
