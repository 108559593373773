/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import EmojiPicker from 'emoji-picker-react';
import useOnClickOutside from '../../../../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function EmojiPickerContainer({
  textRef,
  setValue,
  top,
  left,
  handleClose,
  iconRef,
}) {
  const [emojiPickerdExceedsBounds, setEmojiPickerdExceedsBounds] =
    useState(false);

  const emojiPickerRef = useRef(null);

  useOnClickOutside(
    emojiPickerRef,
    () => {
      handleClose();
    },
    iconRef
  );

  useEffect(() => {
    const hideOnScroll = (event) => {
      if (emojiPickerRef?.current?.contains(event.target)) {
        return;
      }
      handleClose();
    };

    window.addEventListener('wheel', hideOnScroll);

    return () => {
      window.removeEventListener('wheel', hideOnScroll);
    };
  }, [handleClose]);

  const handleCheckEmojiPickerBounds = useCallback(() => {
    const emojiPicker = emojiPickerRef?.current;
    const emojiPickerHeight = emojiPicker?.offsetHeight;
    const windowHeight = window.innerHeight;

    if (windowHeight < top + emojiPickerHeight) {
      setEmojiPickerdExceedsBounds(true);
    } else {
      setEmojiPickerdExceedsBounds(false);
    }
  }, [top]);

  useEffect(() => {
    handleCheckEmojiPickerBounds();
  }, [handleCheckEmojiPickerBounds]);

  return (
    <div
      className={classes.EmojiPickerContainer}
      style={{
        top: emojiPickerdExceedsBounds
          ? `calc(${top - emojiPickerRef.current?.offsetHeight}px)`
          : top,
        left: left - 350,
      }}
      ref={emojiPickerRef}
    >
      <EmojiPicker
        onEmojiClick={(a) => {
          textRef.innerHTML += a.emoji;
          setValue((prev) => prev + a.emoji);
          handleClose();
        }}
      />
    </div>
  );
}
