import React from 'react';

import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import errorIcon from '../../assets/images/modals/error.svg';
import deleteIcon from '../../assets/images/modals/delete.svg';
import infoIcon from '../../assets/images/modals/info.svg';
import saveChangesIcon from '../../assets/images/modals/save-changes.svg';
import classes from './styles.module.scss';
import Button from '../../components/Button';

const icons = {
  error: errorIcon,
  delete: deleteIcon,
  info: infoIcon,
  saveChanges: saveChangesIcon,
};

export default function ModalComponent({
  show,
  handleClose,
  icon,
  title,
  text,
  dismissButtonLabel,
  confirmButtonLabel,
  onConfirm,
  onCancel,
  dismissButtonVariant,
  confirmButtonVariant,
}) {
  const { t } = useTranslation();

  const confirm = () => {
    handleClose();
    if (onConfirm) {
      onConfirm();
    }
  };

  const cancel = () => {
    handleClose();
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.Modal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <img
          width={32}
          height={32}
          className={classes.icon}
          src={icons[icon] || icons.info}
          alt="Icon"
        />
        <h1>{title}</h1>
        <p>{text}</p>
        {onConfirm ? (
          <div className={classes.buttons}>
            <Button
              variant={dismissButtonVariant || 'modal'}
              onClick={cancel}
              width={128}
            >
              {dismissButtonLabel || t('common.gotIt')}
            </Button>
            <Button
              onClick={confirm}
              width={128}
              variant={confirmButtonVariant}
            >
              {confirmButtonLabel || t('common.yes')}
            </Button>
          </div>
        ) : (
          <div className={classes.buttons}>
            <Button onClick={cancel} variant={dismissButtonVariant} width={144}>
              {dismissButtonLabel || t('common.gotIt')}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}
