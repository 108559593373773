import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import SearchInput from '../SearchInput';
import Column from './Column';

import classes from './styles.module.scss';

export default function AvailableColumns({
  columns,
  selectedColumns,
  setSelectedColumns,
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const { t } = useTranslation();

  const toggleSelection = (column) => {
    const columnIndex = selectedColumns.findIndex((col) => col === column);

    if (columnIndex !== -1) {
      setSelectedColumns((prev) => [
        ...prev.slice(0, columnIndex),
        ...prev.slice(columnIndex + 1),
      ]);
    } else {
      setSelectedColumns((prev) => [...prev, column]);
    }
  };

  return (
    <div className={classes.AvailableColumns}>
      <div className={classes.columnHeader}>
        <div className={classes.container}>
          <h2>
            {t(
              'dashboardComponents.Reports.RightPanel.EditColumnsModal.AvailableColumns.availableColumns'
            )}
          </h2>
          <span
            className={classNames(classes.amount, {
              [classes.green]: columns?.length > 0,
            })}
          >
            {columns?.length || 0}
          </span>
        </div>
        {columns?.length === selectedColumns?.length ? (
          <span
            className={classNames(classes.selectAll, {
              [classes.hide]: columns?.length === 0,
            })}
            onClick={() => setSelectedColumns([])}
          >
            {t(
              'dashboardComponents.Reports.RightPanel.EditColumnsModal.AvailableColumns.deselectAll'
            )}
          </span>
        ) : (
          <span
            className={classNames(classes.selectAll, {
              [classes.hide]: columns?.length === 0,
            })}
            onClick={() => {
              if (!columns) {
                return;
              }

              setSelectedColumns([...columns]);
            }}
          >
            {t(
              'dashboardComponents.Reports.RightPanel.EditColumnsModal.AvailableColumns.selectAll'
            )}
          </span>
        )}
      </div>
      <div className={classes.search}>
        <SearchInput value={searchTerm} setValue={setSearchTerm} />
      </div>
      <div className={classes.content}>
        {columns
          ?.filter((column) =>
            column.toLowerCase().includes(searchTerm.toLowerCase())
          )
          ?.sort((a, b) => a.localeCompare(b))
          ?.map((column) => (
            <Column
              key={column}
              column={column}
              isSelected={selectedColumns.includes(column)}
              toggleSelection={() => toggleSelection(column)}
            />
          ))}
      </div>
    </div>
  );
}
