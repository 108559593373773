import React, { useState, useRef } from 'react';

import classes from './styles.module.scss';

export default function AddCandidateMagnifier({ handleClickOnMagnifier }) {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState(0);
  const viewRef = useRef();

  const onMouseDown = () => {
    setIsDragging(true);
  };

  const onMouseMove = (event) => {
    if (isDragging) {
      // Calculate new position
      const newY = position + event.movementY;

      // Restrict movement within container bounds
      /*   const containerHeight = viewRef.current.parentElement.offsetHeight;
      const elementHeight = viewRef.current.offsetHeight;
      const maxPosition = containerHeight - elementHeight; */

      const maxPosition = 228;
      const updatedY = Math.min(Math.max(newY, 0), maxPosition);

      setPosition(updatedY);
    }
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className={classes.AddCandidateMagnifier} ref={viewRef}>
      <div
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseUp}
        className={classes.view}
        style={{
          top: position,
        }}
      >
        <div
          className={classes.magnifier}
          onClick={() => handleClickOnMagnifier(viewRef, position)}
        />
      </div>
    </div>
  );
}
