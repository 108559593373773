import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Calendar from '../../Calendar';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function DateRange({
  toValue,
  fromValue,
  toName,
  fromName,
  setFieldValue,
  touched,
  error,
  setFieldTouched,
  dateFieldWidth,
  fromLabel,
  toLabel,
  readOnly,
  isPopupOpened,
  height,
  style,
}) {
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const calendarRef = useRef();
  const valueRef = useRef();
  const componentRef = useRef();

  const { t } = useTranslation();

  useOnClickOutside(calendarRef, () => setIsCalendarVisible(false));

  useEffect(() => {
    if (isCalendarVisible && !isPopupOpened) {
      const coords = valueRef.current.getBoundingClientRect();
      componentRef.current.style.setProperty(
        '--top',
        `${coords.bottom + 25}px`
      );
      componentRef.current.style.setProperty('--left', `${coords.left}px`);
      componentRef.current.style.setProperty('--bottom', 'unset');

      // Check if enough space to position calender at the bottom of the date input
      if (
        window.innerHeight - (coords.bottom + 25) <
        calendarRef.current.clientHeight
      ) {
        componentRef.current.style.setProperty('--top', 'unset');
        componentRef.current.style.setProperty('--bottom', '0px');
      }
    }
  }, [isCalendarVisible, isPopupOpened]);

  const value = [fromValue, toValue];

  const setValue = (rangeValue) => {
    setFieldValue?.(fromName, rangeValue[0]);
    setFieldValue?.(toName, rangeValue[1]);

    setTimeout(() => {
      setFieldTouched(fromName, true);
      setFieldTouched?.(toName, true);
    });
  };

  return (
    <div
      className={classNames(classes.DateRange, {
        [classes.withFieldWidth]: !!dateFieldWidth,
      })}
      ref={componentRef}
    >
      <div
        style={{ width: dateFieldWidth }}
        className={classes.dateContainer}
        onClick={() => {
          if (readOnly) {
            return;
          }

          setIsCalendarVisible(true);
        }}
      >
        <h2>{fromLabel || 'From'}</h2>
        <div
          className={classes.value}
          ref={valueRef}
          style={{ ...style, height }}
        >
          {typeof fromValue === 'object' ? (
            moment(fromValue)?.format('DD MMMM YYYY')
          ) : (
            <span className={classes.placeholder}>
              {t('common.selectDate')}
            </span>
          )}
        </div>
      </div>
      <div
        className={classes.dateContainer}
        onClick={() => {
          if (readOnly) {
            return;
          }

          setIsCalendarVisible(true);
        }}
        style={{ width: dateFieldWidth }}
      >
        <h2>{toLabel || 'To'}</h2>
        <div className={classes.value} style={{ ...style, height }}>
          {typeof toValue === 'object' ? (
            moment(toValue)?.format('DD MMMM YYYY')
          ) : (
            <span className={classes.placeholder}>
              {t('common.selectDate')}
            </span>
          )}
        </div>
      </div>
      {isCalendarVisible && (
        <div
          className={classNames(classes.calendarContainer, {
            [classes.calendarContainerPopup]: isPopupOpened,
          })}
        >
          <Calendar
            calendarRef={calendarRef}
            value={value}
            setValue={setValue}
            selectRange
          />
        </div>
      )}
      {touched && error && <div className={classes.error}>{error}</div>}
    </div>
  );
}
