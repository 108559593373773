import React, { createContext, useState, useMemo } from 'react';

export const AuthContext = createContext({
  email: '',
});

export default function AuthContextProvider({ children }) {
  const [email, setEmail] = useState('');

  const value = useMemo(
    () => ({
      email,
      setEmail,
    }),
    [email]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
