/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import InfoIcon from '../../../../InfoIcon';
import classes from './styles.module.scss';
import ReportContextMenu from '../ReportContextMenu';

export default function Group({
  group,
  activeReport,
  setActiveReport,
  downloadReport,
  printReport,
}) {
  const [isListVisible, setIsListVisible] = useState(false);
  const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);
  const [contextMenuReport, setContextMenuReport] = useState('');
  const [contextMenuClientX, setContextMenuClientX] = useState(0);
  const [contextMenuClientY, setContextMenuClientY] = useState(0);

  const { t } = useTranslation();

  const openContextMenu = (event, report) => {
    event.preventDefault();

    setContextMenuReport(report);
    setIsContextMenuOpened(true);
    setContextMenuClientX(event.clientX);
    setContextMenuClientY(event.clientY);
  };

  useEffect(() => {
    if (group.listTitle === 'By Category') {
      setIsListVisible(true);
    }
  }, [group.listTitle]);

  return (
    <div className={classes.Group}>
      <div
        className={classes.listTitle}
        onClick={() => setIsListVisible(!isListVisible)}
      >
        <h2>{group.listTitle}</h2>
        <div className={classes.infoIconContainer}>
          <InfoIcon
            infoWidth={110}
            info={group.listInfo}
            tooltipDirection="bottom-left"
          />
          <span
            className={classNames(classes.arrow, {
              [classes.arrowUp]: isListVisible,
            })}
          />
        </div>
      </div>
      {isListVisible && (
        <ul>
          {group.list.map((item) => (
            <li
              className={classNames({
                [classes.active]: activeReport?.title === item?.title,
              })}
              onClick={() => {
                setActiveReport(item);
              }}
              key={item.title}
              onContextMenu={(event) => openContextMenu(event, item)}
            >
              <img src={item.icon} alt={item.title} />
              <span>{item.title}</span>
            </li>
          ))}
        </ul>
      )}

      <ReportContextMenu
        isContextMenuOpened={isContextMenuOpened}
        setIsContextMenuOpened={setIsContextMenuOpened}
        contextMenuReport={contextMenuReport}
        setActiveReport={setActiveReport}
        downloadReport={downloadReport}
        printReport={printReport}
        options={[
          t('dashboardComponents.Reports.LeftPanel.ReportList.Report.open'),
          t('dashboardComponents.Reports.LeftPanel.ReportList.Report.email'),
          t('dashboardComponents.Reports.LeftPanel.ReportList.Report.download'),
          t('dashboardComponents.Reports.LeftPanel.ReportList.Report.print'),
        ]}
        top={contextMenuClientY}
        left={contextMenuClientX}
      />
    </div>
  );
}
