import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Calendar from '../../Calendar';
import CalendarNew from '../../CalendarNew';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

function convertToDateIfNeeded(value) {
  // Check if value is already a Date object
  if (value instanceof Date) {
    return value;
  }

  // Check if value is a valid date string
  const date = new Date(value);
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(date.getTime())) {
    return date;
  }

  // Return new Date if value is not a valid date string
  return value;
}

export default function DatePicker({
  name,
  value,
  setFieldValue,
  touched,
  error,
  setFieldTouched,
  dateFieldWidth,
  label,
  isPopupOpened,
  height,
  labelStyle,
  style,
  width,
  calendarPosition,
  onlyPastDates,
  warning,
  staticErrorPositon,
  readonly,
  newCalendar,
}) {
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const calendarRef = useRef();
  const valueRef = useRef();
  const componentRef = useRef();

  const { t } = useTranslation();

  useOnClickOutside(
    calendarRef,
    () => setIsCalendarVisible(false),
    componentRef
  );

  useEffect(() => {
    if (calendarPosition) {
      return;
    }

    if (isCalendarVisible && !isPopupOpened) {
      const coords = valueRef.current.getBoundingClientRect();
      componentRef.current.style.setProperty(
        '--top',
        `${coords.bottom + 25}px`
      );
      componentRef.current.style.setProperty('--left', `${coords.left}px`);
      componentRef.current.style.setProperty('--bottom', 'unset');

      // Check if enough space to position calender at the bottom of the date input
      if (
        window.innerHeight - (coords.bottom + 25) <
        calendarRef.current.clientHeight
      ) {
        componentRef.current.style.setProperty('--top', 'unset');
        componentRef.current.style.setProperty('--bottom', '0px');
      }
    }
  }, [isCalendarVisible, isPopupOpened, calendarPosition]);

  const setValue = (date) => {
    setFieldValue?.(name, date);

    setTimeout(() => {
      setFieldTouched(name, true);
    });
  };

  const dateValue = convertToDateIfNeeded(value);

  return (
    <div
      className={classNames(classes.DateRange, {
        [classes.withFieldWidth]: !!dateFieldWidth,
        [classes.withCalendarPosition]: !!calendarPosition,
        [classes.staticErrorPositon]: staticErrorPositon,
        [classes.readonly]: readonly,
      })}
      ref={componentRef}
      tabIndex={0}
      role="button"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setIsCalendarVisible(!isCalendarVisible);
        }
      }}
    >
      <div
        style={{ width: dateFieldWidth }}
        className={classes.dateContainer}
        onClick={() => setIsCalendarVisible(!isCalendarVisible)}
      >
        <h2 style={labelStyle}>{label || 'Date'}</h2>
        <div
          className={classes.value}
          ref={valueRef}
          style={{ ...style, height, width }}
        >
          {dateValue ? (
            moment(dateValue)?.format('DD MMMM YYYY')
          ) : (
            <span className={classes.placeholder}>
              {t('common.selectDate')}
            </span>
          )}
        </div>
      </div>
      {isCalendarVisible && (
        <div
          className={classNames(classes.calendarContainer, {
            [classes.calendarContainerPopup]: isPopupOpened,
          })}
        >
          {newCalendar ? (
            <CalendarNew
              calendarRef={calendarRef}
              value={value === 'Invalid DateTime' ? new Date() : dateValue}
              setValue={setValue}
              onlyPastDates={onlyPastDates}
            />
          ) : (
            <Calendar
              calendarRef={calendarRef}
              value={value === 'Invalid DateTime' ? new Date() : dateValue}
              setValue={setValue}
              onlyPastDates={onlyPastDates}
            />
          )}
        </div>
      )}
      {touched && error && <div className={classes.error}>{error}</div>}
      {!error && warning && <div className={classes.warning}>{warning}</div>}
    </div>
  );
}
