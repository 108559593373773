import React, { useState, useEffect, useContext } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import SaveResetButtons from '../SaveResetButtons';
import ColorTile from './ColorTile';
import ColorPalette from './ColorPalette';
import SuggestedColors from './SuggestedColors';

import { BrandKitContext } from '../../../../../../../context/BrandKitContext';
import { hasColorSettingsChanged } from '../../../../../../../helpers/compareBrandKitSettings';
import classes from './styles.module.scss';

let timer;

export default function Color({
  brandColors,
  setBrandColors,
  savedColorSettings,
  setSavedColorSettings,
}) {
  // hex color value for color picker component (not used directly)
  const [currentEditingColor, setCurrentEditingColor] = useState('');
  // array of hex color values for "Pick a color" and
  // "Previous color selections" sections
  const [prevPickedColors, setPrevPickedColors] = useState([]);
  // responsible for showing edit icon on prev picked colors ("Pick a color" section)
  const [editingPrevColor, setEditingPrevColor] = useState('');

  // index of active color in "Your Brand Color" section ('brandColors' prop)
  const {
    activeColorIndex,
    setActiveColorIndex,
    saveButtonValidation,
    setSaveButtonValidation,
  } = useContext(BrandKitContext);

  const { t } = useTranslation();

  useEffect(() => {
    if (activeColorIndex !== null) {
      setCurrentEditingColor(brandColors[activeColorIndex]);
    }
  }, [activeColorIndex, brandColors]);

  // change active color of "Your Brand Color" section
  // based on color picker value (with debounce)
  useEffect(() => {
    clearTimeout(timer);
    if (
      currentEditingColor &&
      activeColorIndex !== null &&
      currentEditingColor !== brandColors[activeColorIndex]
    ) {
      // find index of current editing color in prev picked colors
      const prevColorIndex = prevPickedColors
        .slice(0, 4)
        .findIndex((color) => color === editingPrevColor);

      timer = setTimeout(() => {
        setBrandColors((prevColors) => {
          const newColors = prevColors.map((color, index) => {
            if (index === activeColorIndex) {
              return currentEditingColor;
            }
            return color;
          });

          return newColors;
        });

        setPrevPickedColors((prevColors) => {
          if (prevColors.includes(currentEditingColor)) {
            return prevColors;
          }

          // if current editing color is one of the prev picked colors
          // replace it with the new one
          if (prevColorIndex !== -1) {
            return prevColors.map((color, index) => {
              if (index === prevColorIndex) {
                return currentEditingColor;
              }
              return color;
            });
          }

          if (prevColors.length === 4) {
            return [currentEditingColor, ...prevColors.slice(0, 3)];
          }

          return [currentEditingColor, ...prevColors].slice(0, 18);
        });
        setEditingPrevColor(currentEditingColor);
      }, 1000);
    }
  }, [
    currentEditingColor,
    activeColorIndex,
    brandColors,
    setBrandColors,
    prevPickedColors,
    editingPrevColor,
  ]);

  // Save prev picked colors to local storage
  useEffect(() => {
    if (prevPickedColors.length) {
      localStorage.setItem(
        'prevPickedColors',
        JSON.stringify(prevPickedColors)
      );
    }
  }, [prevPickedColors]);

  // Get prev picked colors from local storage
  useEffect(() => {
    const prevColors = JSON.parse(localStorage.getItem('prevPickedColors'));
    if (prevColors) {
      setPrevPickedColors(prevColors);
    }
  }, []);

  useEffect(() => {
    const isSaveDisabled = !hasColorSettingsChanged(
      brandColors,
      savedColorSettings
    );

    setSaveButtonValidation((prevValidation) => ({
      ...prevValidation,
      isColorSaveButtonDisabled: isSaveDisabled,
    }));
  }, [brandColors, savedColorSettings, setSaveButtonValidation]);

  const changeColor = (color) => {
    setCurrentEditingColor(color);
    setEditingPrevColor(color);
    setBrandColors((prevColors) => {
      const newColors = prevColors.map((prevColor, index) => {
        if (index === activeColorIndex) {
          return color;
        }
        return prevColor;
      });

      return newColors;
    });
  };

  const resetColorSelection = () => {
    setActiveColorIndex(null);
    setEditingPrevColor('');
    setCurrentEditingColor('');
  };

  const saveColorSettings = () => {
    setSavedColorSettings(brandColors);
  };

  const resetColorSettings = () => {
    setBrandColors(
      typeof savedColorSettings[0] === 'string'
        ? savedColorSettings
        : savedColorSettings.map((color) => color.value)
    );
    resetColorSelection();
  };

  return (
    <div className={classes.Color}>
      <div
        className={classNames(classes.header, {
          [classes.active]: activeColorIndex !== null,
        })}
      >
        <h2 className={classes.title}>
          {t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Color.yourBrandColor'
          )}
        </h2>
        <div className={classes.colorsContainer}>
          <div className={classes.colors}>
            {brandColors.map((color, index) => (
              <ColorTile
                // eslint-disable-next-line react/no-array-index-key
                key={`${color}${index}`}
                hexCode={color}
                isActive={activeColorIndex === index}
                onClick={() => {
                  setActiveColorIndex(index);
                  setEditingPrevColor('');
                }}
              />
            ))}
          </div>
          <SaveResetButtons
            onSave={saveColorSettings}
            onReset={resetColorSettings}
            isSaveDisabled={saveButtonValidation.isColorSaveButtonDisabled}
            isResetDisabled={saveButtonValidation.isColorSaveButtonDisabled}
          />
        </div>
      </div>
      <div className={classes.options}>
        <div className={classes.col}>
          <h3 className={classes.pickColorTitle}>
            {t(
              'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Color.pickAColor'
            )}
          </h3>
          <div className={classes.prevColors}>
            <ColorTile
              hexCode="#C1C5D6"
              isEditing={!editingPrevColor || activeColorIndex === null}
              onClick={() => {
                setCurrentEditingColor('');
                setEditingPrevColor('');
              }}
            />
            {prevPickedColors?.slice(0, 4).map((color) => (
              <ColorTile
                key={color}
                hexCode={color}
                isEditing={
                  editingPrevColor === color && activeColorIndex !== null
                }
                onClick={() => {
                  // if (activeColorIndex !== null) {
                  //   changeColor(color);
                  // }
                  changeColor(color);
                }}
              />
            ))}
          </div>
          <ColorPalette
            currentEditingColor={currentEditingColor}
            setCurrentEditingColor={setCurrentEditingColor}
            prevPickedColors={prevPickedColors}
            changeColor={changeColor}
          />
        </div>
        <div className={classes.col}>
          <div className={classes.suggestedColorsHeader}>
            <h3>
              {t(
                'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Color.suggestedColorSchemes'
              )}
            </h3>
          </div>
          <SuggestedColors
            brandColors={brandColors}
            setBrandColors={setBrandColors}
            resetColorSelection={resetColorSelection}
          />
        </div>
      </div>
    </div>
  );
}
