/* eslint-disable react/jsx-handler-names */
import React, { useState, useEffect, useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { COUNTRY_OPTIONS } from '../../../../../../constants/main';
import useSwitchableRowData from '../../../../../../hooks/useSwitchableRowData';
import { UiContext } from '../../../../../../context/UiContext';
import AdminService from '../../../../../../services/AdminService';
import FormDropdown from '../../../../../Form/FormDropdown';
import TextInput from '../../../../../Form/TextInput';
import SidePopup from '../../../SidePopup';
import classes from './styles.module.scss';
import Button from '../../../../../Button';

export default function AddOrEditRestaurant({
  isVisible,
  handleClose,
  refetch,
  showAgain,
  isEdit,
  isAdd,
  selectedRows,
  restaurants,
  hasNoAnimation,
}) {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);
  const [isAddressVerified, setIsAddressVerified] = useState(false);

  const { t } = useTranslation();

  const {
    showModal,
    showUnknownErrorModal,
    setIsFetching,
    setIsCreatingOrUpdating,
    isCreatingOrUpdating,
  } = useContext(UiContext);
  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableRowData(restaurants, selectedRows);

  const createRestaurant = async (values) => {
    try {
      setIsFetching(true);
      setIsCreatingOrUpdating(true);

      await AdminService.createRestaurant({
        name: values.name,
        address: values.address,
        locationCityId: values.city.value,
        postCode: values.postCode,
        country: values.country.value,
        managerIds: [values.manager.value],
        locationCityDiscrictId: values.locationCityDiscrictId.value,
      });
      await refetch();
      showModal({
        title: t(
          'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.Success!'
        ),
        text: t(
          'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.successMessageAdd'
        ),
        dismissButtonLabel: t(
          'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.addMore'
        ),
        confirmButtonLabel: t('common.gotIt'),
        onConfirm: () => {},
        onCancel: showAgain,
      });
      handleClose();
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsFetching(false);
      setIsCreatingOrUpdating(false);
    }
  };

  const updateRestaurant = async (values) => {
    try {
      setIsFetching(true);
      setIsCreatingOrUpdating(true);

      await AdminService.updateRestaurant({
        name: values.name,
        address: values.address,
        locationCityId: values.city.value,
        postCode: values.postCode,
        country: values.country.value,
        managerIds: [values.manager.value],
        id: restaurants?.[currentDataIndex]?.id,
        locationCityDiscrictId: values.locationCityDiscrictId.value,
      });
      await refetch();
      showModal({
        title: t(
          'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.Success!'
        ),
        text: t(
          'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.successMessageEdit'
        ),
        dismissButtonLabel: t('common.gotIt'),
        dismissButtonVariant: ' ',
      });
      setIsTriedToSubmit(false);
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsFetching(false);
      setIsCreatingOrUpdating(false);
    }
  };

  const country = COUNTRY_OPTIONS.find(
    (countr) => countr.value === restaurants?.[currentDataIndex]?.country
  );

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .trim()
          .required(
            t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.errorMessages.required'
            )
          ),
        address: yup
          .string()
          .trim()
          .required(
            t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.errorMessages.required'
            )
          ),
        locationCityDiscrictId: yup
          .object()
          .required(
            t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.errorMessages.required'
            )
          ),
        city: yup
          .object()
          .required(
            t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.errorMessages.required'
            )
          ),
        postCode: yup
          .string()
          .trim()
          .required(
            t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.errorMessages.required'
            )
          )
          .matches(
            /^\d+$/,
            t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.errorMessages.alphabetsNotAllowed'
            )
          ),
        country: yup
          .object()
          .required(
            t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.errorMessages.required'
            )
          ),
        manager: yup
          .object()
          .required(
            t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.errorMessages.required'
            )
          ),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      name: isAdd ? '' : restaurants?.[currentDataIndex]?.name,
      address: isAdd ? '' : restaurants?.[currentDataIndex]?.address,
      locationCityDiscrictId: isAdd
        ? ''
        : {
            value: restaurants?.[currentDataIndex]?.district.id,
            label: restaurants?.[currentDataIndex]?.district.name,
          },
      city: isAdd
        ? ''
        : {
            value: restaurants?.[currentDataIndex]?.city.id,
            label: restaurants?.[currentDataIndex]?.city.name,
          },
      postCode: isAdd ? '' : restaurants?.[currentDataIndex]?.postCode,
      country: isAdd ? '' : country,
      manager: isAdd
        ? ''
        : {
            label: restaurants?.[currentDataIndex]?.managers?.[0]?.userName,
            value: restaurants?.[currentDataIndex]?.managers?.[0]?.id,
          },
    },
    validationSchema,
    onSubmit: isAdd ? createRestaurant : updateRestaurant,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!isVisible) {
      formik.resetForm();
      setIsTriedToSubmit(false);
      setIsAddressVerified(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const isVerifyButtonDisabled =
    !formik.values.address || !formik.values.postCode || !formik.values.city;

  return (
    <SidePopup
      isVisible={isVisible}
      handleClose={handleClose}
      title={
        isEdit
          ? t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.titleEdit'
            )
          : t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.titleAdd'
            )
      }
      leftButtonLabel={isAdd ? t('common.clear') : t('common.reset')}
      rightButtonLabel={isAdd ? t('common.add') : t('common.save')}
      onLeftButtonClick={formik.resetForm}
      onRightButtonClick={() => {
        setIsTriedToSubmit(true);
        formik.handleSubmit();
      }}
      isRightButtonDisabled={
        Object.values(formik.values).some((val) => !val) || isCreatingOrUpdating
      }
      switchNext={isEdit && selectedRows.length > 1 && switchToNextDataElement}
      switchPrev={isEdit && selectedRows.length > 1 && switchToPrevDataElement}
      hasNoAnimation={hasNoAnimation}
    >
      <div className={classes.AddRestaurant}>
        <div className={classes.verifyAddress}>
          <p>
            {t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.verifyAddressText'
            )}
          </p>
          {isAddressVerified ? (
            <div className={classes.verified}>
              {t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.verifyAddressVerified'
              )}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 11.0799V11.9999C21.9988 14.1563 21.3005 16.2545 20.0093 17.9817C18.7182 19.7088 16.9033 20.9723 14.8354 21.5838C12.7674 22.1952 10.5573 22.1218 8.53447 21.3744C6.51168 20.6271 4.78465 19.246 3.61096 17.4369C2.43727 15.6279 1.87979 13.4879 2.02168 11.3362C2.16356 9.18443 2.99721 7.13619 4.39828 5.49694C5.79935 3.85768 7.69278 2.71525 9.79619 2.24001C11.8996 1.76477 14.1003 1.9822 16.07 2.85986"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 4L12 14.01L9 11.01"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          ) : (
            <Button
              width={163}
              variant="secondary"
              height={50}
              disabled={isVerifyButtonDisabled}
              onClick={() => setIsAddressVerified(true)}
            >
              {isVerifyButtonDisabled
                ? t(
                    'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.verifyButtonDisabledLabel'
                  )
                : t(
                    'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.verifyButtonLabel'
                  )}
            </Button>
          )}
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <TextInput
              label={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.labels.Restaurant Name'
              )}
              placeholder={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.placeholders.Restaurant Name here'
              )}
              value={formik.values.name}
              height={50}
              touched={isTriedToSubmit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="name"
              error={formik.errors.name}
            />
          </div>
          <div className={classes.col}>
            <FormDropdown
              label={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.labels.District'
              )}
              height={50}
              fetchOptions={{
                dataName: 'districtsOptionsRestaurant',
                getDataHandler: AdminService.getDistricts,
              }}
              value={formik.values.locationCityDiscrictId}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              name="locationCityDiscrictId"
              error={formik.errors.district}
              touched={isTriedToSubmit}
              placeholder={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.placeholders.Pick the Restaurant district from here'
              )}
            />
          </div>
        </div>
        <div className={classes.row}>
          <TextInput
            width={670}
            label={t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.labels.Address'
            )}
            value={formik.values.address}
            height={50}
            touched={isTriedToSubmit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="address"
            error={formik.errors.address}
            placeholder={t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.placeholders.Restaurant Address here'
            )}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <FormDropdown
              label={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.labels.City'
              )}
              value={formik.values.city}
              height={50}
              touched={isTriedToSubmit}
              onBlur={formik.handleBlur}
              name="city"
              error={formik.errors.city}
              setFieldValue={formik.setFieldValue}
              placeholder={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.placeholders.Pick the Restaurant City from the list'
              )}
              fetchOptions={{
                dataName: 'citiesOptionsRest',
                getDataHandler: AdminService.getCities,
              }}
            />
            <TextInput
              label={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.labels.Zipcode'
              )}
              value={formik.values.postCode}
              height={50}
              touched={isTriedToSubmit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="postCode"
              error={formik.errors.postCode}
              placeholder={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.placeholders.Restaurant Zipcode here'
              )}
            />
          </div>
          <div className={classes.col}>
            <FormDropdown
              label={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.labels.Country'
              )}
              value={formik.values.country}
              height={50}
              touched={isTriedToSubmit}
              onBlur={formik.handleBlur}
              name="country"
              error={formik.errors.country}
              setFieldValue={formik.setFieldValue}
              options={COUNTRY_OPTIONS}
              placeholder={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.placeholders.Pick the Restaurant Country from the list'
              )}
            />
            <FormDropdown
              label={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.labels.RGM'
              )}
              value={formik.values.manager}
              height={50}
              touched={isTriedToSubmit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="manager"
              error={formik.errors.manager}
              setFieldValue={formik.setFieldValue}
              placeholder={t(
                'dashboardComponents.Console.RestaurantManagement.Restaurants.AddOrEditRestaurant.placeholders.Pick the Restaurant Manager from the list'
              )}
              fetchOptions={{
                dataName: 'managersOptionsRestaurant',
                getDataHandler: AdminService.getUsers,
                labelName: 'userName',
                isUserList: true,
              }}
            />
          </div>
        </div>
      </div>
    </SidePopup>
  );
}
