import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function ReportContextMenu({
  isContextMenuOpened,
  setIsContextMenuOpened,
  contextMenuReport,
  setActiveReport,
  showDeleteModal,
  downloadReport,
  printReport,
  renameReport,
  options,
  top,
  left,
}) {
  const [contextMenuExceedsBounds, setContextMenuExceedsBounds] =
    useState(false);
  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => {
    setIsContextMenuOpened(false);
  });

  const { t } = useTranslation();

  const handleCheckContextMenuBounds = useCallback(() => {
    const contextMenu = dropdownRef.current;
    const contextMenuHeight = contextMenu.offsetHeight;
    const windowHeight = window.innerHeight;

    if (windowHeight < top + contextMenuHeight + 20) {
      setContextMenuExceedsBounds(true);
    } else {
      setContextMenuExceedsBounds(false);
    }
  }, [top]);

  useEffect(() => {
    handleCheckContextMenuBounds();
  }, [handleCheckContextMenuBounds]);

  const handleEmailClick = () => {
    const email = 'mailto:';
    const subject = contextMenuReport.title;
    const body = '';

    const data = `?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    const emailUrl = email + data;

    window.location.href = emailUrl;
  };

  return (
    <div
      className={classNames(classes.popup, {
        [classes.active]: isContextMenuOpened,
      })}
      style={{
        top: contextMenuExceedsBounds
          ? `calc(${top - dropdownRef.current.offsetHeight}px)`
          : top,
        left,
      }}
      ref={dropdownRef}
      onClick={(e) => e.stopPropagation()}
    >
      {options.map((option) => {
        switch (option) {
          case t(
            'dashboardComponents.Reports.LeftPanel.ReportList.Report.open'
          ):
            return (
              <div
                className={classes.option}
                onClick={() => {
                  setActiveReport(contextMenuReport);
                  setIsContextMenuOpened(false);
                }}
                key={option}
              >
                {option}
              </div>
            );
          case t(
            'dashboardComponents.Reports.LeftPanel.ReportList.Report.rename'
          ):
            return (
              <div
                className={classes.option}
                onClick={() => {
                  renameReport();
                  setIsContextMenuOpened(false);
                }}
                key={option}
              >
                {option}
              </div>
            );
          case t(
            'dashboardComponents.Reports.LeftPanel.ReportList.Report.email'
          ):
            return (
              <div
                className={classes.option}
                onClick={() => {
                  handleEmailClick();
                  setIsContextMenuOpened(false);
                }}
                key={option}
              >
                {option}
              </div>
            );
          case t(
            'dashboardComponents.Reports.LeftPanel.ReportList.Report.download'
          ):
            return (
              <div
                className={classes.option}
                onClick={() => {
                  downloadReport(contextMenuReport);
                  setIsContextMenuOpened(false);
                }}
                key={option}
              >
                {option}
              </div>
            );
          case t(
            'dashboardComponents.Reports.LeftPanel.ReportList.Report.print'
          ):
            return (
              <div
                className={classes.option}
                onClick={() => {
                  setActiveReport(contextMenuReport);
                  setTimeout(() => {
                    printReport();
                  }, 600);
                  setIsContextMenuOpened(false);
                }}
                key={option}
              >
                {option}
              </div>
            );
          case t(
            'dashboardComponents.Reports.LeftPanel.ReportList.Report.delete'
          ):
            return (
              <div
                className={classes.option}
                onClick={() => {
                  showDeleteModal();
                  setIsContextMenuOpened(false);
                }}
                key={option}
              >
                {option}
              </div>
            );
          default:
            return (
              <div
                className={classes.option}
                onClick={() => setIsContextMenuOpened(false)}
                key={option}
              >
                {option}
              </div>
            );
        }
      })}
    </div>
  );
}
