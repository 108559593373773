import React, { useEffect, useState } from 'react';

import EventDetails from '../EventDetails';
import Day from './Day';
import Month from './Month';
import Week from './Week';
import EventContextMenu from './EventContextMenu';

export default function EventCard({
  mode,
  setActiveType,
  events,
  setCurrentDate,
  refetch,
  user,
}) {
  const [isEventDetailsVisible, setIsEventDetailsVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);
  const [contextMenuClientX, setContextMenuClientX] = useState(0);
  const [contextMenuClientY, setContextMenuClientY] = useState(0);

  useEffect(() => {
    setSelectedEvent(events.find((event) => event?.id === selectedEvent?.id));
  }, [events, selectedEvent?.id]);

  return (
    <>
      {mode === 'DAY' && (
        <Day
          events={events}
          refetch={refetch}
          user={user}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          isEventDetailsVisible={isEventDetailsVisible}
          setIsEventDetailsVisible={setIsEventDetailsVisible}
          setIsContextMenuOpened={setIsContextMenuOpened}
          setContextMenuClientX={setContextMenuClientX}
          setContextMenuClientY={setContextMenuClientY}
          isContextMenuOpened={isContextMenuOpened}
        />
      )}
      {mode === 'WEEK' && (
        <Week
          events={events}
          setActiveType={setActiveType}
          setCurrentDate={setCurrentDate}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          isEventDetailsVisible={isEventDetailsVisible}
          setIsEventDetailsVisible={setIsEventDetailsVisible}
          setIsContextMenuOpened={setIsContextMenuOpened}
          setContextMenuClientX={setContextMenuClientX}
          setContextMenuClientY={setContextMenuClientY}
          isContextMenuOpened={isContextMenuOpened}
        />
      )}
      {mode === 'MONTH' && (
        <Month
          events={events}
          setSelectedEvent={setSelectedEvent}
          setIsEventDetailsVisible={setIsEventDetailsVisible}
          setIsContextMenuOpened={setIsContextMenuOpened}
          setContextMenuClientX={setContextMenuClientX}
          setContextMenuClientY={setContextMenuClientY}
          isContextMenuOpened={isContextMenuOpened}
        />
      )}
      {isEventDetailsVisible && (
        <EventDetails
          isEventDetailsVisible={isEventDetailsVisible}
          handleClose={() => {
            setIsEventDetailsVisible(false);
            setSelectedEvent(null);
          }}
          event={selectedEvent}
          refetch={refetch}
          currentUser={user}
        />
      )}
      {user?.id === selectedEvent?.userId ||
      user?.id === selectedEvent?.event?.organizer.id ? (
        <EventContextMenu
          isContextMenuOpened={isContextMenuOpened}
          setIsContextMenuOpened={setIsContextMenuOpened}
          setIsEventDetailsVisible={setIsEventDetailsVisible}
          options={['Open', 'Cancel']}
          top={contextMenuClientY}
          left={contextMenuClientX}
          event={selectedEvent}
          refetch={refetch}
          currentUser={user}
        />
      ) : (
        <EventContextMenu
          isContextMenuOpened={isContextMenuOpened}
          setIsContextMenuOpened={setIsContextMenuOpened}
          setIsEventDetailsVisible={setIsEventDetailsVisible}
          options={['Open', 'Accept', 'Decline']}
          top={contextMenuClientY}
          left={contextMenuClientX}
          event={selectedEvent}
          refetch={refetch}
          currentUser={user}
        />
      )}
    </>
  );
}
