/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ReportContextMenu from '../../ReportContextMenu';

import { UiContext } from '../../../../../../context/UiContext';
import ReportsService from '../../../../../../services/ReportsService';
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function Report({
  reportSection,
  report,
  isEditable,
  activeReport,
  setActiveReport,
  refetch,
  downloadReport,
  printReport,
}) {
  const [reportTitle, setReportTitle] = useState(report.title);
  const [isReportEdit, setIsReportEdit] = useState(false);
  const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);
  const [contextMenuReport, setContextMenuReport] = useState('');
  const [contextMenuClientX, setContextMenuClientX] = useState(0);
  const [contextMenuClientY, setContextMenuClientY] = useState(0);

  const { showModal, setIsFetching } = useContext(UiContext);

  const reportRef = useRef(null);
  const inputRef = useRef(null);

  const { t } = useTranslation();

  const openContextMenu = (event) => {
    event.preventDefault();

    setContextMenuReport(report);
    setIsContextMenuOpened(true);
    setContextMenuClientX(event.clientX);
    setContextMenuClientY(event.clientY);
  };

  useOnClickOutside(reportRef, () => setIsReportEdit(false));

  const renameReport = async () => {
    try {
      setIsFetching(true);
      await ReportsService.updateStoredReport({
        id: report.id,
        title: reportTitle,
        type: report.type,
        term: report.term,
        orderBy: report.orderBy,
        fields: report.fields,
      });

      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const deleteReport = async () => {
    try {
      setIsFetching(true);
      await ReportsService.deleteReportFromStored({
        id: report?.id,
      });

      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setActiveReport(null);
      setIsFetching(false);
    }
  };

  const showDeleteModal = () => {
    showModal({
      title: t(
        'dashboardComponents.Reports.LeftPanel.ReportList.Report.deleteSavedReport'
      ),
      text: t(
        'dashboardComponents.Reports.LeftPanel.ReportList.Report.confirmDeleteReport'
      ),
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
      onConfirm: () => {
        deleteReport();
      },
      onCancel: () => {},
    });
  };

  useEffect(() => {
    if (isReportEdit) {
      inputRef.current.focus();
    }
  }, [isReportEdit]);

  return (
    <div className={classes.Report}>
      {isEditable ? (
        <div
          className={classNames(classes.report, {
            [classes.active]: activeReport?.id === report?.id,
          })}
          ref={reportRef}
          onContextMenu={openContextMenu}
          onClick={() => setActiveReport(report)}
        >
          <input
            ref={inputRef}
            value={reportTitle}
            onChange={(event) => {
              if (!isReportEdit) {
                return;
              }
              setReportTitle(event.target.value);
            }}
            className={classNames({ [classes.disabled]: !isReportEdit })}
            maxLength={30}
            onFocus={(e) =>
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length
              )
            }
            onClick={() => setActiveReport(report)}
          />
          <div
            className={classNames(classes.buttons, {
              [classes.disabled]: activeReport?.id !== report?.id,
            })}
          >
            {isReportEdit ? (
              <>
                <span
                  className={classes.save}
                  onClick={(event) => {
                    event.stopPropagation();
                    renameReport();
                    setIsReportEdit(false);
                  }}
                />
                <span
                  className={classes.cancel}
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsReportEdit(false);
                  }}
                />
              </>
            ) : (
              <>
                <span
                  className={classes.edit}
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsReportEdit(true);
                  }}
                />
                <span
                  className={classes.delete}
                  onClick={(event) => {
                    event.stopPropagation();
                    showDeleteModal();
                  }}
                />
              </>
            )}
          </div>
        </div>
      ) : (
        <li
          onContextMenu={openContextMenu}
          onClick={() => setActiveReport(report)}
          className={classNames({
            [classes.active]: activeReport === report,
          })}
        >
          <span>{report}</span>
        </li>
      )}

      <ReportContextMenu
        isContextMenuOpened={isContextMenuOpened}
        setIsContextMenuOpened={setIsContextMenuOpened}
        contextMenuReport={contextMenuReport}
        setActiveReport={setActiveReport}
        showDeleteModal={showDeleteModal}
        renameReport={() => setIsReportEdit(true)}
        downloadReport={downloadReport}
        options={
          reportSection === 'Saved Report'
            ? [
                t(
                  'dashboardComponents.Reports.LeftPanel.ReportList.Report.open'
                ),
                t(
                  'dashboardComponents.Reports.LeftPanel.ReportList.Report.rename'
                ),
                t(
                  'dashboardComponents.Reports.LeftPanel.ReportList.Report.email'
                ),
                t(
                  'dashboardComponents.Reports.LeftPanel.ReportList.Report.download'
                ),
                t(
                  'dashboardComponents.Reports.LeftPanel.ReportList.Report.print'
                ),
                t(
                  'dashboardComponents.Reports.LeftPanel.ReportList.Report.delete'
                ),
              ]
            : [
                t(
                  'dashboardComponents.Reports.LeftPanel.ReportList.Report.open'
                ),
                t(
                  'dashboardComponents.Reports.LeftPanel.ReportList.Report.email'
                ),
                t(
                  'dashboardComponents.Reports.LeftPanel.ReportList.Report.download'
                ),
                t(
                  'dashboardComponents.Reports.LeftPanel.ReportList.Report.print'
                ),
              ]
        }
        top={contextMenuClientY}
        left={contextMenuClientX}
        printReport={printReport}
      />
    </div>
  );
}
