import React, { createContext, useState, useMemo } from 'react';

export const BrandKitContext = createContext({
  activeColorIndex: null,
  setActiveColorIndex: () => {},
  isBrandNameEditingMode: false,
  setIsBrandNameEditingMode: () => {},
  isPublishButtonDisabled: true,
  setIsPublishButtonDisabled: () => {},
  saveButtonValidation: {
    isLogoSaveButtonDisabled: true,
    isColorSaveButtonDisabled: true,
    isFontSaveButtonDisabled: true,
  },
  setSaveButtonValidation: () => {},
});

export default function BrandKitContextProvider({ children }) {
  const [activeColorIndex, setActiveColorIndex] = useState(null);
  const [isBrandNameEditingMode, setIsBrandNameEditingMode] = useState(false);
  const [isPublishButtonDisabled, setIsPublishButtonDisabled] = useState(true);
  const [saveButtonValidation, setSaveButtonValidation] = useState({
    isLogoSaveButtonDisabled: true,
    isColorSaveButtonDisabled: true,
    isFontSaveButtonDisabled: true,
  });

  const value = useMemo(
    () => ({
      activeColorIndex,
      setActiveColorIndex,
      isBrandNameEditingMode,
      setIsBrandNameEditingMode,
      isPublishButtonDisabled,
      setIsPublishButtonDisabled,
      saveButtonValidation,
      setSaveButtonValidation,
    }),
    [
      activeColorIndex,
      isBrandNameEditingMode,
      isPublishButtonDisabled,
      saveButtonValidation,
    ]
  );

  return (
    <BrandKitContext.Provider value={value}>
      {children}
    </BrandKitContext.Provider>
  );
}
