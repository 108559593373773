import classNames from 'classnames';
import React from 'react';
import classes from './styles.module.scss';
import { MONTHS } from '../../../../../constants/main';

export default function MonthPicker({ viewDate, setViewDate, handleClose }) {
  const setViewMonth = (month) => {
    setViewDate(new Date(viewDate.getFullYear(), month, 1));
  };

  return (
    <div className={classes.container}>
      {MONTHS.map((month, i) => (
        <div
          className={classNames(classes.month, {
            [classes.activeMonth]: i === viewDate.getMonth(),
          })}
          key={month}
          onClick={() => {
            setViewMonth(i);
            handleClose();
          }}
        >
          {month}
        </div>
      ))}
    </div>
  );
}
