import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function QuestionsHeader({
  title,
  onEditIconClick,
  questionCount,
}) {
  const { t } = useTranslation();

  let count;

  if (questionCount?.toString().length < 2) {
    count = `0${questionCount}`;
  } else {
    count = questionCount;
  }

  return (
    <div className={classes.QuestionsHeader}>
      <div className={classes.title}>
        <h1>
          {title ||
            t(
              'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.QuestionsHeader.untitledAssessment'
            )}
        </h1>
        <i onClick={onEditIconClick}>{t('common.edit')}</i>
      </div>
      <div className={classes.questionCount}>
        <span>
          {t(
            'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.QuestionsHeader.noOfQuestions'
          )}
        </span>
        <div className={classes.counter}>{count}</div>
      </div>
    </div>
  );
}
