import React, { useState } from 'react';
import classes from './styles.module.scss';
import Table from './Table';
import ReportHeader from './ReportHeader';
import EditColumnsModal from './EditColumnsModal';

export default function RightPanel({
  activeReport,
  reports,
  fields,
  refetch,
  setFields,
  rowsPerPage,
  setRowsPerPage,
  activeSortHeader,
  setActiveSortHeader,
  searchTerm,
  setSearchTerm,
  sortOrder,
  setSortOrder,
  isLeftPanelOpened,
  setIsLeftPanelOpened,
  downloadReport,
  tableRef,
  printReport,
  savedReports,
  reportTypes,
  setIsReportAlreadyAdded,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <div className={classes.RightPanel}>
      <ReportHeader
        reports={reports}
        refetch={refetch}
        activeReport={activeReport}
        savedReports={savedReports}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        activeSortHeader={activeSortHeader}
        setActiveSortHeader={setActiveSortHeader}
        setIsModalVisible={setIsModalVisible}
        isLeftPanelOpened={isLeftPanelOpened}
        setIsLeftPanelOpened={setIsLeftPanelOpened}
        downloadReport={downloadReport}
        printReport={printReport}
        reportTypes={reportTypes}
        setIsReportAlreadyAdded={setIsReportAlreadyAdded}
      />
      <Table
        activeReport={activeReport}
        reports={reports}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        activeSortHeader={activeSortHeader}
        setActiveSortHeader={setActiveSortHeader}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        isLeftPanelOpened={isLeftPanelOpened}
        tableRef={tableRef}
      />
      {isModalVisible && (
        <EditColumnsModal
          reports={reports}
          activeReport={activeReport}
          fields={fields}
          setFields={setFields}
          handleClose={() => setIsModalVisible(false)}
          refetch={refetch}
          savedReports={savedReports}
          searchTerm={searchTerm}
          activeSortHeader={activeSortHeader}
        />
      )}
    </div>
  );
}
