import React from 'react';

import Scheme from './Scheme';

import classes from './styles.module.scss';

const colorSchemes = [
  ['#D52D34', '#242833', '#FDF2E4', '#FFFFFF', '#C1C5D6'],
  ['#D52D34', '#242833', '#FAF7F3', '#FFFFFF', '#C1C5D6'],
  ['#D52D34', '#242833', '#FFFFFF', '#FFFFFF', '#C1C5D6'],
  ['#D52D34', '#242833', '#FFFFFF', '#FFFFFF', '#F2F4F8'],
  ['#D52D34', '#242833', '#FDF2E4', '#FFFFFF', '#242833'],
];

export default function SuggestedColors({
  brandColors,
  setBrandColors,
  resetColorSelection,
}) {
  return (
    <div className={classes.SuggestedColors}>
      {colorSchemes.map((scheme, index) => {
        return (
          <Scheme
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            scheme={scheme}
            isActive={scheme.every((color, i) => {
              return (
                color.toLowerCase?.() === brandColors?.[i]?.toLowerCase?.()
              );
            })}
            onClick={() => {
              setBrandColors(scheme);
              resetColorSelection();
            }}
          />
        );
      })}
    </div>
  );
}
