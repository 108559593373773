// TODO: Move everything that is not related to modals and notifications to zustand store (e.g. useUiStore)

/* eslint-disable react/jsx-handler-names */
import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from 'react';

import Notification from '../components/Notification';
import Modal from '../modals/Modal';
import Loader from '../components/Loader';

let notificationTimer;

export const UiContext = createContext({
  showUnknownErrorModal: () => {},
  showModal: () => {},
  showDuplicateDataModal: () => {},
  showSelectionNeededModal: () => {},
  showNotification: () => {},
  showReportCandidateModal: () => {},
  showShareModal: () => {},
  setIsFetching: () => {},
  showConflictModal: () => {},
  isCreatingOrUpdating: false,
  isFetching: false,
  setIsCreatingOrUpdating: () => {},
});

export default function UiContextProvider({ children }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({
    icon: 'info',
    title: null,
    text: null,
    dismissButtonLabel: null,
    confirmButtonLabel: null,
    dismissButtonVariant: null,
    confirmButtonVariant: null,
    onConfirm: () => {},
    onCancel: () => {},
    topMenuActiveButton: null,
    setTopMenuActiveButton: () => {},
  });
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [notification, setNotification] = useState('');
  const [notificationUndo, setNotificationUndo] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isCreatingOrUpdating, setIsCreatingOrUpdating] = useState(false);
  const [topMenuActiveButton, setTopMenuActiveButton] = useState(null);

  useEffect(() => {
    let timer;
    if (!isModalVisible) {
      timer = setTimeout(() => {
        setModalData({
          icon: 'info',
          title: null,
          text: null,
          dismissButtonLabel: null,
          confirmButtonLabel: null,
          onConfirm: () => {},
          onCancel: () => {},
          dismissButtonVariant: null,
          confirmButtonVariant: null,
        });
      }, 200);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isModalVisible]);

  const showModal = useCallback(
    ({
      icon,
      title,
      text,
      dismissButtonLabel,
      confirmButtonLabel,
      onConfirm,
      onCancel,
      dismissButtonVariant,
      confirmButtonVariant,
    }) => {
      setModalData({
        icon,
        title,
        text,
        dismissButtonLabel,
        confirmButtonLabel,
        onConfirm,
        onCancel,
        dismissButtonVariant,
        confirmButtonVariant,
      });
      setIsModalVisible(true);
    },
    []
  );

  const showUnknownErrorModal = useCallback(() => {
    showModal({
      title: 'Ooops',
      text: 'Something wrong. Please try again after some time.',
      dismissButtonLabel: 'Got It',
    });
  }, [showModal]);

  const showConflictModal = useCallback(
    (message) => {
      showModal({
        icon: 'warning',
        title: 'Conflict Detected',
        text: message,
        dismissButtonLabel: 'Ok',
        dismissButtonVariant: 'modal',
      });
    },
    [showModal]
  );

  const showDuplicateDataModal = useCallback(
    (dataName) => {
      showModal({
        icon: 'error',
        title: `Duplicate ${dataName}`,
        text: `${dataName} already exists. Please check the details.`,
        dismissButtonLabel: 'OK',
      });
    },
    [showModal]
  );

  const showSelectionNeededModal = useCallback(() => {
    showModal({
      title: 'Selection Needed',
      text: 'Please select a record before choosing an action.',
      dismissButtonLabel: 'OK',
    });
  }, [showModal]);

  const showNotification = useCallback(
    // eslint-disable-next-line no-unused-vars
    ({ text = 'Something happened', undo }) => {
      clearTimeout(notificationTimer);
      setIsNotificationVisible(false);
      setNotificationUndo(null);
      setNotification('');

      setNotification(text);
      setIsNotificationVisible(true);
      setNotificationUndo(() => undo);

      notificationTimer = setTimeout(() => {
        setIsNotificationVisible(false);
        setNotificationUndo(null);
        setNotification('');
      }, 3000);
    },
    []
  );

  const value = useMemo(
    () => ({
      showUnknownErrorModal,
      showModal,
      showSelectionNeededModal,
      showDuplicateDataModal,
      showNotification,
      setIsFetching,
      showConflictModal,
      isFetching,
      isCreatingOrUpdating,
      setIsCreatingOrUpdating,
      topMenuActiveButton,
      setTopMenuActiveButton,
    }),
    [
      showUnknownErrorModal,
      showModal,
      showSelectionNeededModal,
      showDuplicateDataModal,
      showNotification,
      showConflictModal,
      isFetching,
      isCreatingOrUpdating,
      setIsCreatingOrUpdating,
      topMenuActiveButton,
    ]
  );

  return (
    <UiContext.Provider value={value}>
      {children}
      <Modal
        show={isModalVisible}
        title={modalData.title}
        text={modalData.text}
        icon={modalData.icon}
        dismissButtonLabel={modalData.dismissButtonLabel}
        confirmButtonLabel={modalData.confirmButtonLabel}
        onConfirm={modalData.onConfirm}
        onCancel={modalData.onCancel}
        handleClose={() => setIsModalVisible(false)}
        dismissButtonVariant={modalData.dismissButtonVariant}
        confirmButtonVariant={modalData.confirmButtonVariant}
      />
      <Notification
        isVisible={isNotificationVisible}
        text={notification}
        undo={notificationUndo}
        handleClose={() => setIsNotificationVisible(false)}
      />
      <Loader isFetching={isFetching} />
    </UiContext.Provider>
  );
}

export const useUiContext = () => useContext(UiContext);
