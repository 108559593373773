import classNames from 'classnames';
import React from 'react';
import EventPopup from '../EventPopup';
import classes from './styles.module.scss';

export default function Month({
  events,
  setSelectedEvent,
  setIsEventDetailsVisible,
  setIsContextMenuOpened,
  setContextMenuClientX,
  setContextMenuClientY,
  isContextMenuOpened,
}) {
  const handleRightClick = (e, event) => {
    e.preventDefault();
    setSelectedEvent(event);
    setIsContextMenuOpened(!isContextMenuOpened);
    setContextMenuClientX(e.clientX);
    setContextMenuClientY(e.clientY);
  };

  return (
    <div className={classes.month}>
      {events.length >= 3 ? (
        <>
          {events.slice(0, 2).map((event) => {
            const { id, type, jobPost } = event;
            const text = `${type} for ${jobPost.jobTitle}`;

            return (
              <div
                className={classNames(classes.card, {
                  [classes.accepted]: event?.event.status === 'Accepted',
                  [classes.canceled]:
                    event?.status === 'Canceled' ||
                    event?.event.status === 'Fail',
                  [classes.completed]: event?.event.status === 'Pass',
                  [classes.declined]: event?.event.status === 'Declined',
                  [classes.pending]:
                    event?.event.status === 'Scheduled' ||
                    event?.event.status === 'ChangeRequest',
                })}
                key={`${id}${type}`}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedEvent(event);
                  setIsEventDetailsVisible(true);
                }}
                onContextMenu={(e) => {
                  e.stopPropagation();
                  handleRightClick(e, event);
                }}
              >
                <h2 className={classes.title}>{`${text.slice(0, 9)} ...`}</h2>
                <div className={classes.popup}>
                  <EventPopup event={event} month />
                </div>
              </div>
            );
          })}
          <span className={classes.left}>+{events.length - 2}</span>
        </>
      ) : (
        events.map((event) => {
          const { id, type, userFirstName, userLastName } = event;
          const text = `${type} with ${userFirstName} ${userLastName}`;
          return (
            <div
              className={classNames(classes.card, {
                [classes.accepted]: event?.event.status === 'Accepted',
                [classes.canceled]:
                  event?.event.status === 'Canceled' ||
                  event?.event.status === 'Fail',
                [classes.completed]: event?.event.status === 'Pass',
                [classes.declined]: event?.event.status === 'Declined',
                [classes.pending]:
                  event?.event.status === 'Scheduled' ||
                  event?.event.status === 'ChangeRequest',
              })}
              key={`${id}${type}`}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedEvent(event);
                setIsEventDetailsVisible(true);
              }}
              onContextMenu={(e) => {
                e.stopPropagation();
                handleRightClick(e, event);
              }}
            >
              <h2 className={classes.title}>{`${text.slice(0, 9)} ...`}</h2>
              <div className={classes.popup}>
                <EventPopup event={event} month />
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}
