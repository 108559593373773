import React from 'react';

import Chat from './Chat';
import Onboarding from './Onboarding';
import Offer from './Offer';
import Job from './Job';
import New from './New';
import Interview from './Interview';
import Default from './Default';
import Feed from './Feed';

import NotificationsService from '../../../../services/NotificationsService';
import classes from './styles.module.scss';

export default function Notification({
  notification,
  refetch,
  hideNotifications,
  userTimezone,
}) {
  const deleteNotification = async (event) => {
    try {
      event.stopPropagation();
      await NotificationsService.deleteNotification({
        notificationId: notification.id,
      });
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const selectComponent = () => {
    switch (notification.category) {
      case 'Feed':
        return Feed;
      case 'Interview':
        return Interview;
      case 'New':
        return New;
      case 'Job':
        return Job;
      case 'Offer':
        return Offer;
      case 'Onboarding':
        return Onboarding;
      case 'Chat':
        return Chat;
      default:
        return Default;
    }
  };

  const isRead = !!notification.readAt;

  const Component = selectComponent();

  return (
    <Component
      classes={classes}
      hideNotifications={hideNotifications}
      notification={notification}
      deleteNotification={deleteNotification}
      refetch={refetch}
      isRead={isRead}
      userTimezone={userTimezone}
    />
  );
}
