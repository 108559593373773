import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function NoFavorites({ candidates, jobs, calendar }) {
  const { t } = useTranslation();

  return (
    <div className={classes.NoFavorites}>
      {candidates && (
        <p className={classes.text}>
          {t('dashboardComponents.Favorites.NoFavorites.candidatesText')}
          <br /> {t(
            'dashboardComponents.Favorites.NoFavorites.candidatesTag1'
          )}{' '}
          <span className={classes.star}>
            {t('dashboardComponents.Favorites.NoFavorites.candidatesTag2')}
          </span>{' '}
          {t('dashboardComponents.Favorites.NoFavorites.candidatesTag3')}
        </p>
      )}
      {jobs && (
        <p className={classes.text}>
          {t('dashboardComponents.Favorites.NoFavorites.jobsText')} <br />
          {t('dashboardComponents.Favorites.NoFavorites.jobsTag1')}{' '}
          <span className={classes.star}>
            {t('dashboardComponents.Favorites.NoFavorites.jobsTag2')}
          </span>{' '}
          {t('dashboardComponents.Favorites.NoFavorites.jobsTag3')}
        </p>
      )}

      {calendar && (
        <p className={classes.text}>
          {t('dashboardComponents.Favorites.NoFavorites.calendarText')} <br />
          {t('dashboardComponents.Favorites.NoFavorites.calendarTag1')}{' '}
          <span className={classes.star}>
            {t('dashboardComponents.Favorites.NoFavorites.calendarTag2')}
          </span>{' '}
          {t('dashboardComponents.Favorites.NoFavorites.calendarTag3')}
        </p>
      )}

      <div className={classes.icons} />
    </div>
  );
}
