import React, { useEffect, useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import Switch from '../../../../../../Switch';
import Badge from './Badge';

import { UiContext } from '../../../../../../../context/UiContext';
import classes from './styles.module.scss';
import AdminService from '../../../../../../../services/AdminService';

export default function Badges({ job, refetch }) {
  const [areBadgesEnabled, setAreBadgesEnabled] = useState(false);
  const [enabledBadges, setEnabledBadges] = useState([]);

  const { setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const { data: tags } = useQuery({
    queryKey: ['tags'],
    queryFn: () =>
      AdminService.getTags({
        pageSize: 50,
      }),
  });

  useEffect(() => {
    if (job?.jobPostTags.length) {
      setEnabledBadges(job?.jobPostTags);
      setAreBadgesEnabled(true);
    }
  }, [job]);

  const addTagToJobPost = async (jobTags) => {
    try {
      setIsFetching(true);
      await AdminService.addTagToJobPost({
        id: job?.id,
        jobPostTagIds: areBadgesEnabled ? jobTags?.map((tag) => tag.id) : [],
      });
      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.badgesContainer}>
      <Switch
        label={t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.JobSettings.Badges.enableBadges'
        )}
        isEnabled={areBadgesEnabled}
        onClick={() => {
          if (areBadgesEnabled) {
            setAreBadgesEnabled(false);
            addTagToJobPost();

            return;
          }
          setAreBadgesEnabled((prevState) => !prevState);
        }}
      />
      <div
        className={classNames(classes.badges, {
          [classes.active]: areBadgesEnabled,
        })}
      >
        {tags?.data?.map?.((tag) => (
          <Badge
            key={tag.id}
            badge={tag}
            areBadgesEnabled={areBadgesEnabled}
            enabledBadges={enabledBadges}
            addTagToJobPost={addTagToJobPost}
          />
        ))}
      </div>
    </div>
  );
}
