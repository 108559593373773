import React, { useState } from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import SearchInput from '../SearchInput';
import Column from './Column';
import classes from './styles.module.scss';

export default function SelectedColumns({
  columns,
  setColumns,
  mandatoryColumns,
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const { t } = useTranslation();

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const newArray = Array.from(columns);
    const [removed] = newArray.splice(sourceIndex, 1);
    newArray.splice(destinationIndex, 0, removed);

    setColumns(newArray);
  };

  const deleteColumn = (field) => {
    const fieldIndex = columns.findIndex((column) => column === field);

    setColumns((prev) => [
      ...prev.slice(0, fieldIndex),
      ...prev.slice(fieldIndex + 1),
    ]);
  };

  return (
    <div className={classes.SelectedColumns}>
      <div className={classes.columnHeader}>
        <h2>
          {t(
            'dashboardComponents.Reports.RightPanel.EditColumnsModal.SelectedColumns.title'
          )}
        </h2>
        <span
          className={classNames(classes.amount, {
            [classes.green]: columns?.length > 0,
          })}
        >
          {columns?.length}
        </span>
      </div>
      <div className={classes.search}>
        <SearchInput value={searchTerm} setValue={setSearchTerm} />
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="columns">
          {(provided) => (
            <div
              className={classes.content}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {columns
                .filter((column) =>
                  column.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((column, index) => (
                  <Draggable
                    key={column}
                    draggableId={`item-${column}`}
                    index={index}
                  >
                    {(provid) => (
                      <div
                        {...provid.draggableProps}
                        {...provid.dragHandleProps}
                        ref={provid.innerRef}
                      >
                        <Column
                          key={column}
                          column={column}
                          deleteColumn={() => deleteColumn(column)}
                          isMandatory={mandatoryColumns?.includes(
                            column[0].toLowerCase() + column.slice(1)
                          )}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
