import React from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import notFoundIcon from '../../assets/images/notFound.svg';
import classes from './styles.module.scss';

export default function NotFound() {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const doesAnyHistoryEntryExist = location.key !== 'default';

  return (
    <div className={classes.NotFound}>
      <div className={classes.container}>
        <div className={classes.illustration}>
          <div className={classes.header}>
            <div className={classes.circle} />
            <div className={classes.circle} />
            <div className={classes.circle} />
          </div>
          <div className={classes.body}>
            <img src={notFoundIcon} alt="Not found" />
          </div>
        </div>
        <div className={classes.message}>
          <h1>{t('miscComponents.NotFound.message')}</h1>
          <button
            type="button"
            onClick={() => {
              if (doesAnyHistoryEntryExist) {
                navigate(-1);
              } else {
                navigate('/');
              }
            }}
          >
            {t('miscComponents.NotFound.goBack')}
          </button>
        </div>
      </div>
    </div>
  );
}
