import React, { useState, useRef, useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import classNames from 'classnames';
import Table from '../../../Table';
import TableMenu from '../../Console/TableMenu';
import Stat from './Stat';
import JobSummary from './JobSummary';
import PostAJobBanner from './PostAJobBanner';

import { UiContext } from '../../../../context/UiContext';
import { getAuthData } from '../../../../helpers/authStorage';
import useDebouncedSearchWithHistory from '../../../../hooks/useDebouncedSearchWithHistory';
import AdminService from '../../../../services/AdminService';
import classes from './styles.module.scss';
import tableColsToSortOptions from '../../../../helpers/tableColsToSortOptions';
import GridView from './GridView';

export default function MyJobs({ my, isViewExpanded, setIsViewExpanded }) {
  const [activeSortOption, setActiveSortOption] = useState({
    label: 'Created On',
    value: 'createdAt',
  });
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [view, setView] = useState('list');
  const [selectedRows, setSelectedRows] = useState([]);
  const [activeAction, setActiveAction] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const myJobsPageRef = useRef();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const columns = [
    {
      field: 'status',
      name: t('dashboardComponents.Jobs.MyJobs.status'),
      sortable: true,
      type: 'jobStatus',
    },
    {
      field: 'jobTitle',
      name: t('dashboardComponents.Jobs.MyJobs.jobTitle'),
      sortable: true,
    },
    {
      field: 'id',
      name: t('common.jobId'),
      sortable: true,
    },
    {
      field: 'recruiter',
      name: t('dashboardComponents.Jobs.MyJobs.recruiter'),
      type: 'user',
      sortField: 'recruiter.lastName',
      sortable: true,
    },
    {
      field: 'locations[0].name',
      name: t('dashboardComponents.Jobs.MyJobs.restaurant'),
      sortable: true,
    },
    {
      field: 'locationCity.name',
      name: t('dashboardComponents.Jobs.MyJobs.city'),
      sortable: true,
    },
    {
      field: 'summary',
      name: t('dashboardComponents.Jobs.MyJobs.hiringPipeline'),
      sortable: true,
      type: 'hiringPipeline',
    },
    {
      field: 'expiresAt',
      name: t('dashboardComponents.Jobs.MyJobs.daysOpen'),
      type: 'daysFromCreation',
      sortable: true,
    },
    {
      field: 'createdAt',
      name: t('common.createdOn'),
      sortable: true,
      type: 'date',
    },
  ];

  const sortOptions = tableColsToSortOptions(columns);

  const {
    showSelectionNeededModal,
    showUnknownErrorModal,
    showModal,
    setIsFetching,
  } = useContext(UiContext);

  const { debouncedSearchTerm, searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    'jobPostsMyJobs'
  );

  const { userId } = getAuthData();

  const {
    data: jobPages,
    refetch: refetchJobs,
    isFetching,
    fetchNextPage,
  } = useQuery({
    queryKey: [
      'myJobs',
      sortOrder,
      activeSortOption,
      debouncedSearchTerm,
      my,
      userId,
      currentPage,
    ],
    queryFn: () =>
      AdminService.getMyJobs({
        orderBy: activeSortOption.value || 'id',
        term: debouncedSearchTerm,
        sortOrder,
        pageNumber: currentPage,
        RecruiterId: my ? userId : null,
      }),
    keepPreviousData: true,
  });

  const jobs = jobPages?.data?.filter((job) => job.status !== 'Draft');

  const { data: myJobsStats } = useQuery({
    queryKey: ['myJobsStats'],
    queryFn: AdminService.getMyJobsStats,
  });

  const jobStats = [
    {
      title: t('dashboardComponents.Jobs.MyJobs.allJobs'),
      subtitle: 'Active & Inactive',
      value: myJobsStats?.allJobs.count,
      dynamic: `+${myJobsStats?.allJobs.change}`,
      graphColor: 'purple',
    },
    {
      title: t('dashboardComponents.Jobs.MyJobs.activeJobs'),
      subtitle: 'Active & Inactive',
      value: myJobsStats?.activeJobs.count,
      dynamic: `+${myJobsStats?.activeJobs.change}`,
      graphColor: 'orange',
    },
    {
      title: t('dashboardComponents.Jobs.MyJobs.redAlertJobs'),
      value: myJobsStats?.rscJobs.count,
      dynamic: `+${myJobsStats?.rscJobs.change}`,
      graphColor: 'red',
    },
    {
      title: t('dashboardComponents.Jobs.MyJobs.filledJobs'),
      value: myJobsStats?.myJobs.count,
      dynamic: `+${myJobsStats?.myJobs.change}`,
      graphColor: 'green',
    },
    {
      title: t('dashboardComponents.Jobs.MyJobs.totalJobViews'),
      value: myJobsStats?.jobsViews.count,
      dynamic: `+${myJobsStats?.jobsViews.change}`,
      graphColor: 'blue',
    },
  ];

  const changeJobStatus = async (jobIds, status) => {
    try {
      const promises = [];

      // Remove unPublushed jobs from the list as their status cannot be changed
      const filteredJobIds = jobIds.filter((jobId) => {
        // eslint-disable-next-line no-shadow
        const job = jobs.find((job) => job.id === jobId);
        return job && job.status !== 'UnPublished';
      });

      filteredJobIds.forEach((jobId) => {
        // eslint-disable-next-line no-shadow
        const job = jobs.find((job) => job.id === jobId);
        if (job && job.status !== status) {
          promises.push(AdminService.changeMyJobStatus({ jobId, status }));
        }
      });

      if (!promises.length) {
        setSelectedRows([]);
        return;
      }

      setIsFetching(true);

      await Promise.all(promises);
      await refetchJobs();
      setSelectedRows([]);
      showModal({
        title: t('dashboardComponents.Jobs.MyJobs.success'),
        text: t('dashboardComponents.Jobs.MyJobs.updatedSuccess'),
      });
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsFetching(false);
    }
  };

  let actions = [
    {
      label: t('dashboardComponents.Jobs.MyJobs.publish'),
      value: 'publish',
      onClick: () => {
        if (selectedRows.length) {
          changeJobStatus(selectedRows, 'Active');
        } else {
          showSelectionNeededModal();
        }
      },
    },
    {
      label: t('dashboardComponents.Jobs.MyJobs.unpublish'),
      value: 'unpublish',
      onClick: () => {
        if (!selectedRows.length) {
          showSelectionNeededModal();
          return;
        }

        if (
          jobs.some((job) => {
            return (
              selectedRows.includes(job.id) && job.status !== 'UnPublished'
            );
          })
        ) {
          showModal({
            title: t('dashboardComponents.Jobs.MyJobs.unpublishJob'),
            text: t('dashboardComponents.Jobs.MyJobs.confirmUnpublishText'),
            onConfirm: () => {
              changeJobStatus(selectedRows, 'UnPublished');
            },
            confirmButtonLabel: t('common.yes'),
            dismissButtonLabel: t('common.no'),
          });
        } else {
          setSelectedRows([]);
        }
      },
    },
    {
      label: t('dashboardComponents.Jobs.MyJobs.putOnHold'),
      value: 'putOnHold',
      onClick: () => {
        if (selectedRows.length) {
          changeJobStatus(selectedRows, 'OnHold');
        } else {
          showSelectionNeededModal();
        }
      },
    },
  ];

  // Remove actions for unpublished jobs
  if (
    selectedRows.length &&
    selectedRows.every((jobId) => {
      return jobs.find((job) => job.id === jobId)?.status === 'UnPublished';
    })
  ) {
    actions = [];
  }

  let tableHeight = isViewExpanded
    ? 'calc(100vh - 80px - 37px - 26px - 95px  - 13px)'
    : 'calc(100vh - 80px - 37px - 300px - 26px - 95px  - 13px)';

  if (isTabletOrMobile) {
    tableHeight = '';
  }

  let tableContent = (
    <Table
      height={tableHeight}
      minHeight="420px"
      name="JobPosts"
      isFetching={isFetching}
      fetchNextPage={fetchNextPage}
      onRowClick={(id) => navigate(`/tools/jobs/my-jobs/${id}`)}
      rows={jobs}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      sortOptions={sortOptions}
      setActiveSortOption={setActiveSortOption}
      activeSortOption={activeSortOption}
      setSortOrder={setSortOrder}
      sortOrder={sortOrder}
      columns={columns}
      theadHeight={100}
      thTopPadding={21}
    />
  );

  let gridHeight = isViewExpanded
    ? 'calc(100vh - 80px - 37px - 26px - 95px  - 13px)'
    : 'calc(100vh - 80px - 37px - 300px - 26px - 95px  - 13px)';

  if (isTabletOrMobile) {
    gridHeight = '';
  }

  let gridContent = (
    <GridView
      height={gridHeight}
      data={jobs}
      containerRef={myJobsPageRef}
      isFetching={isFetching}
      fetchNextPage={fetchNextPage}
      dataCount={jobs?.length}
      refetch={refetchJobs}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
    />
  );

  if (jobPages && !jobs.length) {
    const noData = (
      <div className={classes.noData}>
        <p>{t('dashboardComponents.Jobs.MyJobs.noData')}</p>
        <p>
          {t('dashboardComponents.Jobs.MyJobs.noDataInstructions')}{' '}
          <span className={classes.bold}>+</span>{' '}
          {t('dashboardComponents.Jobs.MyJobs.noDataInstructions2')}{' '}
          <span className={classes.bold}>
            + {t('dashboardComponents.Jobs.MyJobs.noDataInstructions3')}
          </span>{' '}
          {t('dashboardComponents.Jobs.MyJobs.noDataInstructions4')}
        </p>
      </div>
    );
    tableContent = noData;
    gridContent = noData;
  }

  let statsToDisplay = jobStats;

  if (isTabletOrMobile) {
    statsToDisplay = jobStats.slice(0, 2);
  }

  return (
    <div className={classes.MyJobs}>
      <header
        className={classes.header}
        style={{ display: isViewExpanded ? 'none' : 'flex' }}
      >
        <div className={classes.col}>
          <h1 className={classes.title}>Jobs</h1>
          <PostAJobBanner />
          <div className={classes.stats}>
            {statsToDisplay.map((stat) => {
              return (
                <Stat
                  key={stat.title}
                  title={stat.title}
                  subtitle={stat.subtitle}
                  value={stat.value}
                  dynamic={stat.dynamic}
                  graphColor={stat.graphColor}
                />
              );
            })}
          </div>
        </div>
        <div className={classes.col}>
          <JobSummary stats={myJobsStats?.summary} />
        </div>
      </header>
      <div
        className={classNames(classes.tableContainer, {
          [classes.noShadow]: view === 'grid',
        })}
      >
        <div className={classes.tableMenuContainer}>
          <div className={classes.innerContainer}>
            <TableMenu
              style={{
                height: isTabletOrMobile ? '' : 95,
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
              }}
              actions={actions}
              activeAction={activeAction}
              setActiveAction={setActiveAction}
              sortOptions={sortOptions}
              activeSortOption={activeSortOption}
              setActiveSortOption={setActiveSortOption}
              selectedCount={selectedRows.length}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchHistory={searchHistory}
              view={view}
              setView={setView}
              setSortOrder={setSortOrder}
              isFullScreenModeEnabled={isViewExpanded}
              setIsFullScreenModeEnabled={setIsViewExpanded}
              withFullScreenButton
              currentPage={currentPage}
              totalPages={jobPages?.totalPages || 1}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
        {view === 'list' ? (
          tableContent
        ) : (
          <div className={classes.outerGridContainer} ref={myJobsPageRef}>
            <div className={classes.gridContainer}>{gridContent}</div>
          </div>
        )}
      </div>
    </div>
  );
}
