import React from 'react';
import Cell from './Cell';

export default function Row({ row }) {
  const cols = Object.entries(row);

  return (
    <tr>
      {cols.map((col) => (
        <Cell key={`${Math.random()}`} col={col} />
      ))}
    </tr>
  );
}
