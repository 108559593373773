import React, { useContext, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { UiContext } from '../../../../../context/UiContext';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import AdminService from '../../../../../services/AdminService';
import classes from './styles.module.scss';

export default function EventContextMenu({
  isContextMenuOpened,
  setIsContextMenuOpened,
  setIsEventDetailsVisible,
  options,
  top,
  left,
  event,
  refetch,
  currentUser,
}) {
  const { showModal, showUnknownErrorModal, showNotification, setIsFetching } =
    useContext(UiContext);

  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => {
    setIsContextMenuOpened(false);
  });

  const { t } = useTranslation();

  const cancelEvent = () => {
    if (event?.type === 'Interview') {
      const prevStatus = event?.event?.status;
      const undo = async () => {
        try {
          setIsFetching(true);

          await AdminService.changeJobApplicationInterview({
            interviewId: event?.id,
            jobApplicationId: event?.jobApplicationId,
            interviewDate: event?.event?.interviewDate,
            type: event?.event?.type,
            level: event?.event?.level,
            customLocation: event?.event?.customLocation,
            connectionDetail: event?.event?.connectionDetail,
            notificationType: event?.event?.notificationType,
            notificationMethod: event?.event?.notificationMethod,
            notificationValue: event?.event?.notificationValue,
            userIds: event?.event?.users.map((user) => user.id),
            note: event?.event?.note,
            startTime: event?.event?.startTime,
            endTime: event?.event?.endTime,
            status: prevStatus,
          });
          await refetch();
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
        } finally {
          setIsFetching(false);
        }
      };

      showModal({
        icon: 'infoIcon',
        title: t(
          'dashboardComponents.Calendar.EventCard.EventContextMenu.confirmationDialogs.cancelInterviewTitle'
        ),
        text: t(
          'dashboardComponents.Calendar.EventCard.EventContextMenu.confirmationDialogs.cancelInterviewText'
        ),
        onConfirm: async () => {
          try {
            setIsFetching(true);

            await AdminService.changeJobApplicationInterview({
              interviewId: event?.id,
              jobApplicationId: event?.jobApplicationId,
              interviewDate: event?.event?.interviewDate,
              type: event?.event?.type,
              level: event?.event?.level,
              customLocation: event?.event?.customLocation,
              connectionDetail: event?.event?.connectionDetail,
              notificationType: event?.event?.notificationType,
              notificationMethod: event?.event?.notificationMethod,
              notificationValue: event?.event?.notificationValue,
              userIds: event?.event?.users.map((user) => user.id),
              note: event?.event?.note,
              startTime: event?.event?.startTime,
              endTime: event?.event?.endTime,
              status: 'Fail',
            });
            await refetch();
            showNotification({
              text: t(
                'dashboardComponents.Calendar.EventCard.EventContextMenu.notificationMessages.interviewCanceled'
              ),
              undo,
            });
          } catch (error) {
            console.log(error);
            showUnknownErrorModal();
          } finally {
            setIsContextMenuOpened(false);
            setIsFetching(false);
          }
        },
        onCancel: () => {},
        dismissButtonLabel: t('common.no'),
        confirmButtonLabel: t('common.yes'),
      });
    }

    if (event?.type === 'Onboarding') {
      const prevStatus = event?.event?.status;
      const undo = async () => {
        try {
          setIsFetching(true);

          await AdminService.changeJobApplicationInterview({
            onboardingId: event?.id,
            jobApplicationId: event?.jobApplicationId,
            onboardingDate: event?.event?.onboardingDate,
            startTime: event?.event?.startTime,
            endTime: event?.event?.startEnd,
            location: event?.event?.location,
            completeDate: event?.event?.completeDate,
            note: event?.event?.note,
            userIds: event?.event?.users.map((user) => user.id),
            type: event?.event?.type,
            status: prevStatus,
          });
          await refetch();
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
        } finally {
          setIsFetching(false);
        }
      };

      showModal({
        icon: 'infoIcon',
        title: t(
          'dashboardComponents.Calendar.EventCard.EventContextMenu.confirmationDialogs.cancelOnboardingTitle'
        ),
        text: t(
          'dashboardComponents.Calendar.EventCard.EventContextMenu.confirmationDialogs.cancelOnboardingText'
        ),
        onConfirm: async () => {
          try {
            setIsFetching(true);

            await AdminService.changeJobApplicationOnboarding({
              onboardingId: event?.id,
              jobApplicationId: event?.jobApplicationId,
              onboardingDate: event?.event?.onboardingDate,
              startTime: event?.event?.startTime,
              endTime: event?.event?.startEnd,
              location: event?.event?.location,
              completeDate: event?.event?.completeDate,
              note: event?.event?.note,
              userIds: event?.event?.users.map((user) => user.id),
              type: event?.event?.type,
              status: 'Canceled',
            });
            await refetch();
            showNotification({
              text: t(
                'dashboardComponents.Calendar.EventCard.EventContextMenu.notificationMessages.onboardingCanceled'
              ),
              undo,
            });
          } catch (error) {
            console.log(error);
            showUnknownErrorModal();
          } finally {
            setIsContextMenuOpened(false);
            setIsFetching(false);
          }
        },
        onCancel: () => {},
        dismissButtonLabel: t('common.no'),
        confirmButtonLabel: t('common.yes'),
      });
    }

    if (event?.type === 'Joining') {
      const prevStatus = event?.event?.status;
      const undo = async () => {
        try {
          setIsFetching(true);

          await AdminService.changeJobApplicationInterview({
            joiningId: event?.id,
            jobApplicationId: event?.jobApplicationId,
            joiningDate: event?.event?.joiningDate,
            note: event?.event?.note,
            startTime: event?.event?.startTime,
            endTime: event?.event?.endTime,
            userIds: event?.event?.users.map((user) => user.id),
            location: event?.event?.location,
            status: prevStatus,
          });
          await refetch();
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
        } finally {
          setIsFetching(false);
        }
      };
      showModal({
        icon: 'infoIcon',
        title: t(
          'dashboardComponents.Calendar.EventCard.EventContextMenu.confirmationDialogs.cancelJoiningTitle'
        ),
        text: t(
          'dashboardComponents.Calendar.EventCard.EventContextMenu.confirmationDialogs.cancelJoiningText'
        ),
        onConfirm: async () => {
          try {
            setIsFetching(true);

            await AdminService.changeJobApplicationStartDate({
              joiningId: event?.id,
              jobApplicationId: event?.jobApplicationId,
              joiningDate: event?.event?.joiningDate,
              note: event?.event?.note,
              startTime: event?.event?.startTime,
              endTime: event?.event?.endTime,
              userIds: event?.event?.users.map((user) => user.id),
              location: event?.event?.location,
              status: 'Canceled',
            });
            await refetch();
            showNotification({
              text: t(
                'dashboardComponents.Calendar.EventCard.EventContextMenu.notificationMessages.joiningCanceled'
              ),
              undo,
            });
          } catch (error) {
            console.log(error);
            showUnknownErrorModal();
          } finally {
            setIsContextMenuOpened(false);
            setIsFetching(false);
          }
        },
        onCancel: () => {},
        dismissButtonLabel: t('common.no'),
        confirmButtonLabel: t('common.yes'),
      });
    }
  };

  const declineEvent = async () => {
    if (event?.type === 'Interview') {
      const currentInvite = event?.event?.jobApplicationInterviewInvites.find(
        (invite) => invite.userId === currentUser.id
      );
      const prevStatus = currentInvite.status;

      const undo = async () => {
        try {
          setIsFetching(true);

          await AdminService.changeJobApplicationInterviewInvite({
            interviewId: event?.id,
            jobApplicationId: event?.jobApplicationId,
            invitationid: currentInvite.id,
            note: currentInvite.note,
            notificationType: currentInvite.notificationType,
            notificationMethod: currentInvite.notificationMethod,
            notificationValue: currentInvite.notificationValue,
            status: prevStatus,
          });
          await refetch();
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
        } finally {
          setIsFetching(false);
        }
      };

      try {
        setIsFetching(true);

        await AdminService.changeJobApplicationInterviewInvite({
          interviewId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          invitationid: currentInvite.id,
          note: currentInvite.note,
          notificationType: currentInvite.notificationType,
          notificationMethod: currentInvite.notificationMethod,
          notificationValue: currentInvite.notificationValue,
          status: 'Declined',
        });
        await refetch();
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventCard.EventContextMenu.notificationMessages.interviewInvitationDeclined'
          ),
          undo,
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        setIsContextMenuOpened(false);
        setIsFetching(false);
      }
    }

    if (event?.type === 'Joining') {
      const currentInvite = event?.event?.jobApplicationJoiningInvites.find(
        (invite) => invite.userId === currentUser.id
      );
      const prevStatus = currentInvite.status;
      const undo = async () => {
        try {
          setIsFetching(true);

          await AdminService.changeJobApplicationStartDateInvite({
            joiningId: event?.id,
            jobApplicationId: event?.jobApplicationId,
            invitationid: currentInvite.id,
            note: currentInvite.note,
            status: prevStatus,
          });
          await refetch();
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
        } finally {
          setIsFetching(false);
        }
      };

      try {
        setIsFetching(true);

        await AdminService.changeJobApplicationStartDateInvite({
          joiningId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          invitationid: currentInvite.id,
          note: currentInvite.note,
          status: 'Declined',
        });
        await refetch();
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventCard.EventContextMenu.notificationMessages.joiningInvitationDeclined'
          ),
          undo,
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        setIsContextMenuOpened(false);
        setIsFetching(false);
      }
    }

    if (event?.type === 'Onboarding') {
      const currentInvite = event?.event?.jobApplicationOnboardingInvites.find(
        (invite) => invite.userId === currentUser.id
      );
      const prevStatus = currentInvite.status;
      const undo = async () => {
        try {
          setIsFetching(true);

          await AdminService.changeJobApplicationOnboardingInvite({
            onboardingId: event?.id,
            jobApplicationId: event?.jobApplicationId,
            invitationid: currentInvite.id,
            note: currentInvite.note,
            status: prevStatus,
          });
          await refetch();
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
        } finally {
          setIsFetching(false);
        }
      };
      try {
        setIsFetching(true);

        await AdminService.changeJobApplicationOnboardingInvite({
          onboardingId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          invitationid: currentInvite.id,
          note: currentInvite.note,
          status: 'Declined',
        });
        await refetch();
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventCard.EventContextMenu.notificationMessages.onboardingInvitationDeclined'
          ),
          undo,
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        setIsFetching(false);
      }
    }
  };

  const acceptEvent = async () => {
    if (event?.type === 'Interview') {
      const currentInvite = event?.event?.jobApplicationInterviewInvites.find(
        (invite) => invite.userId === currentUser.id
      );
      try {
        setIsFetching(true);
        await AdminService.changeJobApplicationInterviewInvite({
          interviewId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          invitationid: currentInvite.id,
          note: currentInvite.note,
          notificationType: currentInvite.notificationType,
          notificationMethod: currentInvite.notificationMethod,
          notificationValue: currentInvite.notificationValue,
          status: 'Accepted',
        });
        await refetch();
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventCard.EventContextMenu.notificationMessages.interviewInvitationAccepted'
          ),
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        setIsFetching(false);
      }
    }

    if (event?.type === 'Joining') {
      try {
        setIsFetching(true);
        const currentInvite = event?.event?.jobApplicationJoiningInvites.find(
          (invite) => invite.userId === currentUser.id
        );
        await AdminService.changeJobApplicationStartDateInvite({
          joiningId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          invitationid: currentInvite.id,
          note: currentInvite.note,
          status: 'Accepted',
        });
        await refetch();
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventCard.EventContextMenu.notificationMessages.joiningInvitationAccepted'
          ),
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        setIsFetching(false);
      }
    }

    if (event?.type === 'Onboarding') {
      const currentInvite = event?.event?.jobApplicationOnboardingInvites.find(
        (invite) => invite.userId === currentUser.id
      );
      try {
        setIsFetching(true);
        await AdminService.changeJobApplicationOnboardingInvite({
          onboardingId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          invitationid: currentInvite.id,
          note: currentInvite.note,
          status: 'Accepted',
        });
        await refetch();
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventCard.EventContextMenu.notificationMessages.onboardingInvitationAccepted'
          ),
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        setIsFetching(false);
      }
    }
  };

  return (
    <div
      className={classNames(classes.popup, {
        [classes.active]: isContextMenuOpened,
      })}
      style={{ top, left }}
      ref={dropdownRef}
      onClick={(e) => e.stopPropagation()}
    >
      {options.map((option) => {
        switch (option) {
          case 'Open':
            return (
              <div
                className={classes.option}
                onClick={() => {
                  setIsContextMenuOpened(false);
                  setIsEventDetailsVisible(true);
                }}
                key={option}
              >
                {option}
              </div>
            );
          case 'Cancel':
            return (
              <div
                className={classes.option}
                onClick={() => {
                  cancelEvent();
                  setIsContextMenuOpened(false);
                }}
                key={option}
              >
                {option}
              </div>
            );
          case 'Accept':
            return (
              <div
                className={classes.option}
                onClick={() => {
                  acceptEvent();
                  setIsContextMenuOpened(false);
                }}
                key={option}
              >
                {option}
              </div>
            );
          case 'Decline':
            return (
              <div
                className={classes.option}
                onClick={() => {
                  declineEvent();
                  setIsContextMenuOpened(false);
                }}
                key={option}
              >
                {option}
              </div>
            );
          default:
            return (
              <div
                className={classes.option}
                onClick={() => setIsContextMenuOpened(false)}
                key={option}
              >
                {option}
              </div>
            );
        }
      })}
    </div>
  );
}
