import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import NoData from './NoData';
import Notification from './Notification';
import Header from './Header';

import { UiContext } from '../../../context/UiContext';
// import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';
import NotificationsService from '../../../services/NotificationsService';
import UserService from '../../../services/UserService';

export default function Notifications({ hideNotifications }) {
  const [currentSortOption, setCurrentSortOption] = useState('Recent');
  const [notificationsToShow, setNotificationsToShow] = useState([]);

  const { setIsFetching } = useContext(UiContext);

  const notificationsRef = useRef();

  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(
    t('dashboardComponents.Notifications.all')
  );

  const { data: notifications, refetch } = useQuery({
    queryKey: ['notifications'],
    queryFn: NotificationsService.getNotifications,
  });

  const { data: me } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const actions = useMemo(
    () => notifications?.filter((ntfcn) => ntfcn.type === 'Action'),
    [notifications]
  );
  const alerts = useMemo(
    () => notifications?.filter((ntfcn) => ntfcn.type === 'Alert'),
    [notifications]
  );
  const infos = useMemo(
    () => notifications?.filter((ntfcn) => ntfcn.type === 'Info'),
    [notifications]
  );

  const tabs = [
    {
      title: t('dashboardComponents.Notifications.all'),
      type: 'All',
      count: notifications?.length,
    },
    {
      title: t('dashboardComponents.Notifications.actions'),
      type: 'Action',
      count: actions?.length,
    },
    {
      title: t('dashboardComponents.Notifications.alerts'),
      type: 'Alert',
      count: alerts?.length,
    },
    {
      title: t('dashboardComponents.Notifications.info'),
      type: 'Info',
      count: infos?.length,
    },
  ];

  useEffect(() => {
    let currentNotifications = [];

    if (activeTab === 'Action') {
      currentNotifications = actions;
    } else if (activeTab === 'Alert') {
      currentNotifications = alerts;
    } else if (activeTab === 'Info') {
      currentNotifications = infos;
    } else {
      currentNotifications = notifications;
    }

    if (currentSortOption === 'Recent') {
      currentNotifications = currentNotifications?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    } else {
      const unreadMessages = currentNotifications
        ?.filter((ntfcn) => !ntfcn.readAt)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      const readMessages = currentNotifications
        ?.filter((ntfcn) => ntfcn.readAt)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      currentNotifications = [...unreadMessages, ...readMessages];
    }

    setNotificationsToShow(currentNotifications);
  }, [actions, activeTab, alerts, currentSortOption, infos, notifications]);

  const readNotifications = async () => {
    try {
      setIsFetching(true);
      const unreadNotifications = notificationsToShow?.filter(
        (ntfcn) => !ntfcn.readAt
      );

      if (unreadNotifications?.length) {
        const promises = unreadNotifications.map((ntfcn) =>
          NotificationsService.readNotification({ notificationId: ntfcn.id })
        );

        await Promise.all(promises);
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = 'unset';
    };
  }, []);

  console.log(notificationsToShow);

  return (
    <>
      <div className={classes.overlay} onClick={hideNotifications} />
      <div className={classes.Notifications} ref={notificationsRef}>
        <Header
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          readNotifications={readNotifications}
          currentSortOption={currentSortOption}
          setCurrentSortOption={setCurrentSortOption}
        />
        {notificationsToShow && notificationsToShow.length ? (
          <ul className={classes.notificationsList}>
            {notificationsToShow?.map((notification) => {
              return (
                <Notification
                  key={notification.id}
                  notification={notification}
                  refetch={refetch}
                  hideNotifications={hideNotifications}
                  userTimezone={me?.userProfile?.timezone}
                />
              );
            })}
          </ul>
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
}
