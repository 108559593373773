/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useContext, useCallback } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { UiContext } from '../../../../../../context/UiContext';
import MediaUploader from './MediaUploader';
import TextInput from '../../../../../Form/TextInput';
import Textarea from '../../../../../Form/Textarea';
import SidePopup from '../../../SidePopup';
import useSwitchableRowData from '../../../../../../hooks/useSwitchableRowData';
import classes from './styles.module.scss';
import AdminService from '../../../../../../services/AdminService';

const validationSchema = yup.object({
  location: yup.string().trim(),
  instructions: yup.string().trim(),
});

export default function EditContent({
  isVisible,
  handleClose,
  data,
  selectedRows,
  hasNoAnimation,
  refetch,
}) {
  const [file, setFile] = useState(null);
  const [hasFileChanged, setHasFileChanged] = useState(false);

  const { t } = useTranslation();

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableRowData(data, selectedRows);

  const {
    showUnknownErrorModal,
    showModal,
    setIsFetching,
    setIsCreatingOrUpdating,
    isCreatingOrUpdating,
  } = useContext(UiContext);

  const updateContent = async (values) => {
    try {
      setIsFetching(true);
      setIsCreatingOrUpdating(true);

      let newFile;
      let fileType;
      if (file) {
        if (file?.type.includes('image')) {
          newFile = await AdminService.uploadImage(file, 'jobpostcover');
          fileType = 'Photo';
        } else if (file?.type.includes('video')) {
          fileType = 'Video';
          newFile = await AdminService.uploadVideo(file);
        }
      }

      await AdminService.updateContentMedia({
        location: values.location,
        url: newFile ? newFile.uri : data?.[currentDataIndex]?.url,
        mimeType: newFile
          ? newFile.mimeType
          : data?.[currentDataIndex]?.mimeType,
        type: newFile ? fileType : data?.[currentDataIndex]?.type,
        fileName: newFile
          ? newFile.fileName
          : data?.[currentDataIndex]?.fileName,
        id: data?.[currentDataIndex]?.id,
      });
      await refetch();

      showModal({
        title: t(
          'dashboardComponents.Console.ContentManagement.PicVideo.EditContent.Success'
        ),
        text: 'Changes saved successfully',
      });
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsFetching(false);
      setIsCreatingOrUpdating(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      location: data?.[currentDataIndex]?.location,
      instructions: data?.[currentDataIndex]?.instructions,
    },
    validationSchema,
    onSubmit: updateContent,
    enableReinitialize: true,
  });

  const resetForm = useCallback(() => {
    formik.resetForm();
    setFile(null);
    setHasFileChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isVisible) {
      resetForm();
    }
  }, [isVisible, resetForm]);

  return (
    <SidePopup
      isVisible={isVisible}
      handleClose={handleClose}
      hasNoAnimation={hasNoAnimation}
      title={t(
        'dashboardComponents.Console.ContentManagement.PicVideo.EditContent.Title'
      )}
      switchNext={selectedRows.length > 1 && switchToNextDataElement}
      switchPrev={selectedRows.length > 1 && switchToPrevDataElement}
      leftButtonLabel={t('common.reset')}
      rightButtonLabel={t('common.save')}
      onRightButtonClick={formik.handleSubmit}
      onLeftButtonClick={resetForm}
      isRightButtonDisabled={isCreatingOrUpdating}
    >
      <div className={classes.EditContent}>
        <div className={classes.row}>
          <div className={classes.col}>
            <TextInput
              height={50}
              label={t(
                'dashboardComponents.Console.ContentManagement.PicVideo.PageReference'
              )}
              value={formik.values.location}
              name="location"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.location}
            />
          </div>
          <div className={classes.col}>
            <Textarea
              height={150}
              label={t(
                'dashboardComponents.Console.ContentManagement.PicVideo.Instructions'
              )}
              value={formik.values.instructions}
            />
          </div>
        </div>
        <div className={classes.row}>
          <MediaUploader
            file={file}
            setFile={setFile}
            initialFile={data?.[currentDataIndex]?.url}
            initialFileType={data?.[currentDataIndex]?.type}
            hasChanged={hasFileChanged}
            setHasChanged={setHasFileChanged}
          />
        </div>
      </div>
    </SidePopup>
  );
}
