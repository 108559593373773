import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import useDebouncedSearchWithHistory from '../../../../hooks/useDebouncedSearchWithHistory';
import AdminService from '../../../../services/AdminService';

import Dropdown from '../../../Form/Dropdown';
import SearchInput from '../../../Form/SearchInput';
import Timeline from '../../Jobs/MyJobs/JobDetails/Insights/Timeline';

import classes from './styles.module.scss';
import { getDate, getTimelineTitle } from '../../../../helpers/getTimeline';

function getDaysInMonth(month, year) {
  const date = new Date(year, month, 1);
  const dayList = [];

  while (date.getMonth() === month) {
    dayList.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dayList;
}

const dateToday = new Date();

export default function CandidateTimeline({ candidate }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeAction, setActiveAction] = useState('');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [days, setDays] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);

  const daysRef = useRef(null);

  const { t } = useTranslation();

  const { searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    'timelineSearchHistory'
  );

  const { data: candidateTimeline } = useQuery({
    queryKey: [`candidateTimeLine${candidate?.id}`],
    queryFn: () =>
      AdminService.getJobApplicationTimeline({ id: candidate?.id }),
  });

  useEffect(() => {
    setDays([
      ...getDaysInMonth(currentDate.getMonth(), currentDate.getFullYear()),
    ]);
    setSelectedDays([]);
  }, [currentDate]);

  useEffect(() => {
    if (dateToday.getMonth() === currentDate.getMonth()) {
      daysRef.current.scrollLeft = 67 * (dateToday.getDate() - 1);
    }
  }, [currentDate]);

  const actions = [
    {
      label: t('dashboardComponents.Candidate.CandidateTimeline.dateAsc'),
      value: 'asc',
    },

    {
      label: t('dashboardComponents.Candidate.CandidateTimeline.dateDsc'),
      value: 'dsc',
    },
  ];

  const getPrevMonth = () => {
    if (currentDate.getMonth() - 1 === -1) {
      setCurrentDate((prev) => new Date(prev.getFullYear() - 1, 11));

      return;
    }

    setCurrentDate((prev) => new Date(prev.getFullYear(), prev.getMonth() - 1));
  };

  const getNextMonth = () => {
    if (currentDate.getMonth() + 1 === 12) {
      setCurrentDate((prev) => new Date(prev.getFullYear() + 1, 0));

      return;
    }

    setCurrentDate((prev) => new Date(prev.getFullYear(), prev.getMonth() + 1));
  };

  const getPrevDay = () => {
    daysRef.current.scrollLeft -= 67 * 2;
  };

  const getNextDay = () => {
    daysRef.current.scrollLeft += 67 * 2;
  };

  const selectDays = (day) => {
    if (day === selectedDays[0] && selectedDays.length === 1) {
      setSelectedDays([]);

      return;
    }

    if (day === selectedDays[0]) {
      setSelectedDays((prev) => [prev[1]]);

      return;
    }

    if (day === selectedDays[1]) {
      setSelectedDays((prev) => [prev[0]]);

      return;
    }

    if (selectedDays.length === 0 || selectedDays.length === 1) {
      setSelectedDays((prev) => [...prev, day].sort((a, b) => a - b));

      return;
    }

    if (Math.abs(selectedDays[0] - day) < Math.abs(selectedDays[1] - day)) {
      if (selectedDays[0] < day && selectedDays[1] > day) {
        setSelectedDays((prev) => [day, prev[1]]);

        return;
      }

      if (selectedDays[0] > day) {
        setSelectedDays((prev) => [day, prev[1]]);

        return;
      }
    } else if (
      Math.abs(selectedDays[0] - day) > Math.abs(selectedDays[1] - day)
    ) {
      setSelectedDays((prev) => [prev[0], day]);

      return;
    }

    if (
      Math.abs(selectedDays[1] - day) === 1 &&
      Math.abs(selectedDays[0] - day) === 1
    ) {
      setSelectedDays((prev) => [prev[0] + 1, prev[1]]);
    }
  };

  const events = useMemo(
    () =>
      candidateTimeline?.map((event) => {
        const eventDate = new Date(event?.createdAt);

        return {
          title: getTimelineTitle(event),
          date: getDate(eventDate),
          day: eventDate?.getDate(),
          month: eventDate?.getMonth(),
          year: eventDate?.getFullYear(),
          event,
        };
      }),
    [candidateTimeline]
  );

  const visibleEvents = useMemo(
    () =>
      events
        ?.filter((event) => {
          let isSelectedDate = true;

          if (selectedDays.length === 1) {
            isSelectedDate = event.day === selectedDays[0];
          }

          if (selectedDays.length === 2) {
            isSelectedDate =
              event.day >= selectedDays[0] && event.day <= selectedDays[1];
          }

          return (
            event.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
            isSelectedDate
          );
        })
        .sort((prev, next) =>
          activeAction.value === 'asc'
            ? new Date(prev.event?.createdAt) - new Date(next.event?.createdAt)
            : new Date(next.event?.createdAt) - new Date(prev.event?.createdAt)
        ),
    [events, searchTerm, selectedDays, activeAction]
  );

  useEffect(() => {
    setSearchTerm('');
  }, [candidate]);

  console.log(visibleEvents?.length);

  return (
    <div className={classes.timeline}>
      <div className={classes.header}>
        <div className={classes.date}>
          <span className={classes.prev} onClick={getPrevMonth} />
          <span className={classes.currentDate}>
            {`${currentDate.toLocaleString('en-US', {
              month: 'long',
            })}, ${currentDate.getFullYear()}`}
          </span>
          <span className={classes.next} onClick={getNextMonth} />
        </div>
        <div className={classes.legend}>
          <h3 className={classes.legendTitle}>Legend</h3>
          <div className={classes.legendContent}>
            <div className={classes.today}>
              <div className={classes.circle} />
              Today
            </div>
            <div className={classes.eventsLegend}>
              <div className={classes.circle} />
              Events
            </div>
            <div className={classes.selected}>
              <div className={classes.circle} />
              Selected
            </div>
          </div>
        </div>
        <div className={classes.inputs}>
          <SearchInput
            value={searchTerm}
            setValue={setSearchTerm}
            searchHistory={searchHistory}
          />
          <Dropdown
            options={actions}
            placeholder="All events"
            activeOption={activeAction}
            setActiveOption={setActiveAction}
            width={140}
          />
        </div>
      </div>

      <div className={classes.calendar}>
        <span className={classes.prev} onClick={getPrevDay} />
        <div className={classes.days} ref={daysRef}>
          {days.map((day) => {
            const dayNum = day.getDate();
            const dayMonth = day.getMonth();
            const dayYear = day.getFullYear();

            return (
              <div
                className={classNames(classes.day, {
                  [classes.selectedLine]:
                    dayNum >= selectedDays[0] && dayNum <= selectedDays[1],
                  [classes.selectedLineFirst]: dayNum === selectedDays[0],
                  [classes.selectedLineSecond]: dayNum === selectedDays[1],
                })}
                onClick={() => selectDays(day.getDate())}
                key={day}
              >
                <span className={classes.dayOfWeek}>
                  {day
                    .toLocaleString('en-US', {
                      weekday: 'long',
                    })
                    .slice(0, 3)}
                </span>
                <span
                  className={classNames({
                    [classes.eventToday]:
                      dayNum === dateToday.getDate() &&
                      day.getMonth() === dateToday.getMonth() &&
                      day.getFullYear() === dateToday.getFullYear(),
                    [classes.eventDay]: events?.some(
                      (eventDate) =>
                        eventDate?.day === dayNum &&
                        eventDate?.month === dayMonth &&
                        eventDate?.year === dayYear
                    ),
                    [classes.eventSelected]:
                      dayNum === selectedDays[0] || dayNum === selectedDays[1],
                  })}
                >
                  {dayNum}
                </span>
              </div>
            );
          })}
        </div>
        <span className={classes.next} onClick={getNextDay} />
      </div>

      {visibleEvents?.length ? (
        <div className={classes.timeline}>
          <Timeline data={visibleEvents} candidatePopup />
        </div>
      ) : (
        <div className={classes.noData}>
          {t('dashboardComponents.Candidate.CandidateTimeline.noSearchResults')}
        </div>
      )}
    </div>
  );
}
