import React from 'react';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';

import { getAuthData } from '../../../../../../../helpers/authStorage';
import classes from './styles.module.scss';
import Checkmark from './Checkmark';
import Feedback from './Feedback';

export default function Message({
  message,
  jobApplicationUserFeedbacks,
  jobApplicationId,
  refetchMessages,
}) {
  const { userId } = getAuthData();

  const { t } = useTranslation();

  const isMyMessage = message.authorId === +userId;
  let messageText = message.message;

  let feedback;

  if (!messageText) {
    feedback = jobApplicationUserFeedbacks?.find(
      (fdbck) => fdbck.id === message.objectAttachmentId
    );
    messageText = feedback?.feedbackNote;
  }

  let messagePoint = message.applicationStatus;

  if (
    message.applicationStatus === 'Onboard' &&
    message.applicationSubStatus === 'Pending'
  ) {
    messagePoint = 'Offer';
  }

  return (
    <section
      className={classNames(classes.Message, {
        [classes.my]: isMyMessage,
      })}
    >
      <header className={classes.header}>
        {isMyMessage ? (
          <h5>
            {t(
              'dashboardComponents.Chat.RightPanel.ChatWindow.MessagesList.Message.myMessage'
            )}
          </h5>
        ) : (
          <div className={classes.userHeaderContent}>
            <h5>
              {message.author?.userProfile?.firstName}’s{' '}
              {t(
                'dashboardComponents.Chat.RightPanel.ChatWindow.MessagesList.Message.message'
              )}
              : {t(`common.jobApplicationStatuses.${messagePoint}`)}{' '}
              {t(
                'dashboardComponents.Chat.RightPanel.ChatWindow.MessagesList.Message.point'
              )}
            </h5>
            <Checkmark
              isResolved={!!message.addressedAt}
              jobApplicationId={jobApplicationId}
              messageId={message.id}
              refetchMessages={refetchMessages}
            />
          </div>
        )}
      </header>
      <div className={classes.text}>
        {message.type === 'UserFeedback' && (
          <Feedback
            rating={feedback?.feedbackRating}
            isRecommended={feedback?.isRecommended}
          />
        )}
        {messageText}
      </div>
      <footer className={classes.footer}>
        <div className={classes.createdAt}>
          {moment(message.createdAt).format('Do MMM YYYY hh:mm A')}
        </div>
      </footer>
    </section>
  );
}
