import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';
import chevron from '../../../../../assets/images/dashboard/candidate/calendar/chevron.svg';

export default function MoveButton({ prev, next, handleClick, style }) {
  return (
    <button
      style={style}
      className={classNames({ [classes.prev]: prev, [classes.next]: next })}
      onClick={handleClick}
      type="button"
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleClick();
        }
      }}
    >
      <img src={chevron} alt="Chevron" />
    </button>
  );
}
