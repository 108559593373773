import React, { useContext } from 'react';

import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import InactiveOverlay from './InactiveOverlay';
import LogoPreview from './LogoPreview';
import ColorsPreview from './ColorsPreview';
import PagePreview from './PagePreview';
import BrowserPreview from './BrowserPreview';
import HeaderPreview from './HeaderPreview';

import { BrandKitContext } from '../../../../../../context/BrandKitContext';
import classes from './styles.module.scss';

const isActive = (previewType, activeTab, isBrandNameEditingMode) => {
  const activePreviews = {
    Logo: isBrandNameEditingMode
      ? ['Logo', 'Browser']
      : [
          'Logo',
          'LandingDesktop',
          'LandingMobile',
          'Browser',
          'Header',
          'JobsPage',
        ],
    Color: ['Colors', 'LandingDesktop', 'LandingMobile', 'Header', 'JobsPage'],
    Font: ['Logo', 'LandingDesktop', 'LandingMobile', 'Header', 'JobsPage'],
  };

  return activePreviews[activeTab].includes(previewType);
};

export default function Preview({
  brandColors,
  logoSettings,
  fonts,
  activeTab,
}) {
  const { isBrandNameEditingMode } = useContext(BrandKitContext);

  const isActiveArguments = [activeTab, isBrandNameEditingMode];

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const col = (
    <div className={classes.col}>
      <BrowserPreview
        backgroundColor={brandColors[2]}
        name={logoSettings.brandName}
        favicon={logoSettings.favicon}
        isActive={isActive('Browser', ...isActiveArguments)}
      />
      <HeaderPreview
        backgroundColor={brandColors[2]}
        logo={logoSettings.logo}
        titleFontFamily={fonts.titleFont?.fontFamily?.value}
        brandColor={brandColors[0]}
        isActive={isActive('Header', ...isActiveArguments)}
      />
      <div
        className={classNames(classes.pagePreviewCutContainer, {
          [classes.active]: isActive('JobsPage', ...isActiveArguments),
        })}
      >
        <InactiveOverlay
          isActive={isActive('JobsPage', ...isActiveArguments)}
        />
        <PagePreview
          noActiveBorder
          width={isTabletOrMobile ? '100%' : 292}
          cut
          brandColors={brandColors}
          innerWidth={1512}
          height={isActive('JobsPage', ...isActiveArguments) ? 292 : 297}
          logoSettings={logoSettings}
          path="/jobs/city/3/Test%20City"
        />
      </div>
    </div>
  );

  return (
    <div className={classes.Preview}>
      <div className={classes.firstRow}>
        <LogoPreview
          logoSettings={logoSettings}
          isActive={isActive('Logo', ...isActiveArguments)}
        />
        <ColorsPreview
          brandColors={brandColors}
          isActive={isActive('Colors', ...isActiveArguments)}
        />
      </div>
      <div className={classes.secondRow}>
        <PagePreview
          width={isTabletOrMobile ? '100%' : ''}
          innerWidth={1512}
          brandColors={brandColors}
          logoSettings={logoSettings}
          path="/"
          isActive={isActive('LandingDesktop', ...isActiveArguments)}
        />
        <PagePreview
          width={isTabletOrMobile ? 80 : 52}
          innerWidth={428}
          brandColors={brandColors}
          logoSettings={logoSettings}
          path="/"
          isActive={isActive('LandingMobile', ...isActiveArguments)}
        />
        {!isTabletOrMobile && col}
      </div>
      {isTabletOrMobile && col}
    </div>
  );
}
