import React, { useState, useRef, useMemo } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import DropDown from '../Dropdown';

import { getAllTimezonesNames } from '../../../../../helpers/timezones';
import classes from './styles.module.scss';

export default function TypeSwitch({
  events,
  activeType,
  setActiveType,
  timezone,
  setTimezone,
}) {
  const [isTimezonesDropdownOpened, setIsTimezonesDropdownOpened] =
    useState(false);

  const timezonesRef = useRef();

  const { t } = useTranslation();

  const viewTypes = [
    { text: 'Day', amount: events.eventsToday.length },
    { text: 'Week', amount: events.eventsInWeek.length },
    { text: 'Month', amount: events.eventsInMonth.length },
  ];

  const timezones = useMemo(() => getAllTimezonesNames(), []);

  return (
    <div className={classes.TypeSwitch}>
      {viewTypes.map((type) => (
        <div
          className={classNames(classes.view, {
            [classes.active]: activeType === type.text,
          })}
          onClick={() => setActiveType(type.text)}
          key={type.text}
          role="button"
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setActiveType(type.text);
            }
          }}
        >
          <span className={classes.text}>
            {t(
              `dashboardComponents.Calendar.CalendarMenu.viewTypes.${type.text}`
            )}
          </span>
          <span className={classes.counter}>{type.amount}</span>
        </div>
      ))}
      <div
        className={classes.zone}
        ref={timezonesRef}
        onClick={() => setIsTimezonesDropdownOpened(!isTimezonesDropdownOpened)}
        role="button"
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setIsTimezonesDropdownOpened(!isTimezonesDropdownOpened);
          }
        }}
      >
        {timezone}
        {isTimezonesDropdownOpened && (
          <DropDown
            options={timezones}
            value={timezone}
            setValue={setTimezone}
            width="400px"
            handleClose={() => setIsTimezonesDropdownOpened(false)}
            labelRef={timezonesRef}
          />
        )}
      </div>
    </div>
  );
}
