/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function SubMenu({ isVisible, options, setVisibleSubmenu }) {
  const [isNotEnoughSpace, setIsNotEnoughSpace] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const submenuRef = useRef();

  useOnClickOutside(submenuRef, () => setVisibleSubmenu(''));

  useEffect(() => {
    if (isVisible) {
      const { top } = submenuRef.current.getBoundingClientRect();
      if (window.innerHeight - top < submenuRef.current.clientHeight) {
        setIsNotEnoughSpace(true);
      } else {
        setIsNotEnoughSpace(false);
      }
    } else {
      setIsNotEnoughSpace(false);
    }
  }, [isVisible]);

  return (
    <>
      {isVisible ? (
        <div
          className={classNames(classes.SubMenu, {
            [classes.onTop]: isNotEnoughSpace,
          })}
          ref={submenuRef}
        >
          <ul>
            {options.map((option) => (
              <li
                className={classNames(classes.option, {
                  [classes.selected]: location.pathname === option.path,
                })}
                key={option.path}
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(option.path);
                  setVisibleSubmenu('');
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
}
