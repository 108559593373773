/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import classes from './styles.module.scss';

export default function ManagementMenu({ options }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/tools/admin') {
      navigate(options[0]?.path, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <div className={classes.ManagementMenu}>
      <ul>
        {options.map((option) => (
          <li
            key={option.path}
            onClick={() => navigate(option.path)}
            className={classNames({
              [classes.active]: location.pathname === option.path,
            })}
            role="button"
            tabIndex={0}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                navigate(option.path);
              }
            }}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
}
