import React, { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function SearchInput({ value, setValue }) {
  const inputRef = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <div className={classes.SearchInput}>
      <input
        type="text"
        placeholder={`${t('common.search')}...`}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        ref={inputRef}
      />
    </div>
  );
}
