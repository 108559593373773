/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';

import classNames from 'classnames';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function Dropdown({
  options,
  activeOption,
  setActiveOption,
  placeholder,
  width,
  height,
  dangerOption,
  style,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  return (
    <div
      ref={dropdownRef}
      className={classNames(classes.Dropdown, {
        [classes.open]: isOptionsListVisible,
      })}
      tabIndex={0}
      role="listbox"
      style={{ width }}
    >
      <div
        style={{ ...style, width, height }}
        className={classes.selector}
        onClick={() => setIsOptionsListVisible((prevState) => !prevState)}
      >
        {activeOption ? (
          <span className={classes.hasOption}>{activeOption.label}</span>
        ) : (
          <span>{placeholder}</span>
        )}
        <div className={classes.arrow} />
      </div>
      {isOptionsListVisible && (
        <div className={classes.options}>
          <ul>
            {options.map((option) => (
              <li
                key={option.value}
                onClick={() => {
                  setActiveOption(option);
                  setIsOptionsListVisible(false);

                  if (option.onClick) {
                    option.onClick();
                  }
                }}
                className={classNames({
                  [classes.active]: activeOption.value === option.value,
                  [classes.danger]: dangerOption === option.value,
                  [classes.withDivider]: option.withDivider,
                })}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
