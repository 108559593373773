import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Recipient from '../Recipient';

import star from '../../../../../../../assets/images/dashboard/discover/star.svg';
import arrowRight from '../../../../../../../assets/images/dashboard/discover/arrowRightColor.svg';

export default function FiveStarFeedback({ feedPost, classes }) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const jobApplication = feedPost?.jobApplication;

  const recruiter = {
    userProfile: {
      firstName: jobApplication?.recruiter?.firstName,
      lastName: jobApplication?.recruiter?.lastName,
      avatar: jobApplication?.recruiter?.avatar,
    },
  };
  const user = jobApplication?.jobSeeker;
  const message = feedPost?.jobApplicationUserFeedback?.feedbackNote;

  return (
    <div className={classNames(classes.container, classes.fiveStarFeedback)}>
      <div className={classes.content}>
        <h3 className={classes.title}>
          {t(
            'dashboardComponents.Discover.Feed.Post.Appreciation.FiveStarFeedback.fiveStarFeedback'
          )}
        </h3>
        <ul className={classes.stars}>
          {Array(5)
            .fill(null)
            .map((_, i) => i)
            .map((i) => (
              <li key={i}>
                <img src={star} alt="Star" />
              </li>
            ))}
        </ul>
        <div className={classes.userContainer}>
          <span className={classes.bold}>{user?.fullName}</span> (
          {t(
            'dashboardComponents.Discover.Feed.Post.Appreciation.FiveStarFeedback.candidateFor'
          )}{' '}
          {jobApplication?.jobTitle})
        </div>
        <div className={classes.message}>{message}</div>
        <div className={classes.likeBadges}>
          <div className={classes.outerBadge}>
            <div className={classes.innerBadge} />
          </div>
          <img src={arrowRight} alt="Arrow" />
          <Recipient user={recruiter} rounded />
        </div>
      </div>
      {!isTabletOrMobile && <div className={classes.background} />}
    </div>
  );
}
