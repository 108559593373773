import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Tooltip from '../../../../../../Tooltip';

import classes from './styles.module.scss';
import pinIcon from '../../../../../../../assets/images/dashboard/discover/pin.svg';
import pinGrayIcon from '../../../../../../../assets/images/dashboard/discover/pinGray.svg';

export default function PinIcon({ isPinned, togglePin }) {
  const [isGradientIconVisible, setIsGradientIconVisible] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    if (isPinned) {
      setTimeout(() => {
        setIsGradientIconVisible(false);
      }, 100);
    } else {
      setTimeout(() => {
        setIsGradientIconVisible(true);
      }, 100);
    }
  }, [isPinned]);

  return (
    <div
      className={classNames(classes.PinIcon, {
        [classes.pinned]: isPinned,
      })}
      onClick={togglePin}
    >
      <Tooltip
        text={t(
          'dashboardComponents.Discover.Feed.Post.Header.PinIcon.Pin to top'
        )}
        direction="bottom-left"
        width={100}
      >
        <img src={isGradientIconVisible ? pinGrayIcon : pinIcon} alt="Viewed" />
      </Tooltip>
    </div>
  );
}
