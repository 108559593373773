/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import Questions from './Questions';
import RadioButton from '../../../../../../Form/RadioButton';
import SectionTitle from './SectionTitle';
import Switch from '../../../../../../Switch';

import classes from './styles.module.scss';
import TextInput from '../../../../../../Form/TextInput';
import ReadonlyQuestions from './ReadonlyQuestions';
import Assessments from './Assessments';

export default function VrAndAssessment({
  formik,
  vrQuestions,
  setVrQuestions,
  data,
  isJobPostForm,
  isTriedToSubmit,
  areVrQuestionsValid,
  useDefaultVrQuestions,
  setUseDefaultVrQuestions,
  readOnly,
  assessments,
  setAssessments,
}) {
  // const [isAssessmentNeeded, setIsAssessmentNeeded] = useState(true);
  const [isWide, setIsWide] = useState(false);

  const vrFormRef = useRef();

  const { t } = useTranslation();

  const addVrQuestion = () => {
    setVrQuestions((prevState) => {
      if (prevState.length === 5) {
        return prevState;
      }

      return [
        ...prevState,
        {
          id: `${Math.random()}new`,
          savedQuestion: {},
          duration: 30,
          title: '',
          question: '',
        },
      ];
    });
  };

  useEffect(() => {
    if (window.innerWidth > 1544 && isJobPostForm) {
      setIsWide(true);
    }
  }, [isJobPostForm]);

  const radioButtonsValue = data
    ? data.applicationType
    : formik?.values.applicationType;

  const duration = formik
    ? vrQuestions?.reduce((acc, currValue) => acc + currValue.duration, 0)
    : data?.videoResumeQuestions?.reduce(
        (acc, currValue) => acc + currValue.duration,
        0
      );

  let durationWarning;
  let durationError;

  if (duration > 90 && duration <= 120) {
    durationWarning = t(
      'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.DurationWarning'
    );
  }

  if (duration > 120) {
    durationError = t(
      'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.DurationError'
    );
  }

  const durationCounter = (
    <div className={classes.counterContainer}>
      <div
        className={classNames(
          classes.counter,
          {
            [classes.amber]: duration > 90 && duration <= 120,
          },
          { [classes.red]: duration > 120 }
        )}
      >
        {duration}
        {durationError && <div className={classes.error}>{durationError}</div>}
        {durationWarning && (
          <div className={classNames(classes.error, classes.warning)}>
            {durationWarning}
          </div>
        )}
      </div>
      <span>
        (
        {t(
          'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.totalDuration'
        )}
        )
      </span>
    </div>
  );

  return (
    <div className={classes.VrAndAssessment} ref={vrFormRef}>
      <div className={classes.assessmentContainer}>
        <Switch
          label={t(
            'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.AssessmentNeededLabel'
          )}
          info={t(
            'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.AssessmentInfo'
          )}
          infoWidth={190}
          tooltipDirection="top-right"
          backgroundColor="#00E4AC"
          isEnabled={
            data?.assignedAssessments.length ||
            formik?.values.isAssessmentNeeded
          }
          onClick={
            data
              ? () => {}
              : () => {
                  if (formik?.values.isAssessmentNeeded) {
                    formik?.setFieldValue?.('isAssessmentNeeded', false);
                    setAssessments([]);
                  } else {
                    formik?.setFieldValue?.('isAssessmentNeeded', true);
                  }
                }
          }
          readOnly={readOnly}
        />
        {(data?.assignedAssessments.length ||
          formik?.values.isAssessmentNeeded) && (
          <div className={classes.assessment}>
            <div className={classes.col}>
              {formik ? (
                <Assessments
                  readOnly={readOnly}
                  touched={isTriedToSubmit}
                  assessments={assessments}
                  setAssessments={setAssessments}
                  formik={formik}
                />
              ) : (
                <TextInput
                  height={45}
                  label={t(
                    'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.AssessmentModuleLabel'
                  )}
                  readonly
                  value={data?.assessment?.name}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <div
        className={classNames(classes.row, {
          [classes.jobPostForm]: isJobPostForm,
        })}
      >
        <SectionTitle
          title={t(
            'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.ApplicationMethodTitle'
          )}
          info={t(
            'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.ApplicationMethodInfo'
          )}
          infoWidth={204}
        />
        <div
          className={classNames(classes.applicationMethodContainer, {
            [classes.jobPostForm]: isJobPostForm,
          })}
        >
          <div className={classes.row}>
            <RadioButton
              label={t(
                'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.VideoResumeOnlyLabel'
              )}
              isSelected={radioButtonsValue === 'VideoOnly'}
              onClick={() =>
                formik?.setFieldValue('applicationType', 'VideoOnly')
              }
              readOnly={readOnly}
            />
            <RadioButton
              label={t(
                'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.WordPdfResumeOnlyLabel'
              )}
              isSelected={radioButtonsValue === 'DocumentOnly'}
              onClick={() =>
                formik?.setFieldValue('applicationType', 'DocumentOnly')
              }
              readOnly={readOnly}
            />
          </div>
          <div className={classes.row}>
            <RadioButton
              label={t(
                'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.VideoOrWordPdfResumeLabel'
              )}
              isSelected={radioButtonsValue === 'VideoOrDocument'}
              onClick={() =>
                formik?.setFieldValue('applicationType', 'VideoOrDocument')
              }
              readOnly={readOnly}
            />
            <RadioButton
              label={t(
                'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.VideoPlusWordPdfResumeLabel'
              )}
              isSelected={radioButtonsValue === 'VideoAndDocument'}
              onClick={() =>
                formik?.setFieldValue('applicationType', 'VideoAndDocument')
              }
              readOnly={readOnly}
            />
          </div>
        </div>
      </div>
      {radioButtonsValue !== 'DocumentOnly' && (
        <>
          <div
            className={classNames(classes.vrTitle, {
              [classes.wide]: isWide,
            })}
          >
            <div className={classes.col}>
              <SectionTitle
                title={t(
                  'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.VideoResumeQuestionsTitle'
                )}
                note={t(
                  'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.VideoResumeQuestionsNote'
                )}
                info="No idea"
                column
              />
              <span className={classes.optional}>(Optional)</span>
            </div>
            {!readOnly && (
              <div className={classes.col}>
                <Switch
                  label={t(
                    'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.UseDefaultVrQuestionsLabel'
                  )}
                  isEnabled={useDefaultVrQuestions}
                  onClick={
                    data
                      ? () => {}
                      : () => {
                          setUseDefaultVrQuestions((prevState) => !prevState);
                          if (useDefaultVrQuestions) {
                            setVrQuestions?.([]);
                          }
                        }
                  }
                  backgroundColor="#00E4AC"
                />
                {isWide && durationCounter}
              </div>
            )}
          </div>
          <div className={classes.vrContainer}>
            {!isWide && durationCounter}
            {formik && !readOnly ? (
              <Questions
                vrQuestions={vrQuestions}
                setVrQuestions={setVrQuestions}
                areVrQuestionsValid={areVrQuestionsValid}
                isTriedToSubmit={isTriedToSubmit}
                isJobPostForm={isJobPostForm}
              />
            ) : (
              <ReadonlyQuestions
                questions={vrQuestions || data?.videoResumeQuestions}
              />
            )}
            {formik && !readOnly && !useDefaultVrQuestions && (
              <button
                type="button"
                onClick={addVrQuestion}
                className={classes.vrQuestionButton}
              >
                +{' '}
                {t(
                  'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.AddVrQuestionButton'
                )}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
}
