import { DateTime } from 'luxon';
import { TIMEZONES } from '../constants/main';

export function setTimezoneTime(time, timezone = 'Turkey') {
  if (!timezone) {
    return setTimezoneTime(time, 'Turkey');
  }

  const startTime = DateTime.fromFormat(time, 'HH:mm', { zone: timezone });

  const utcTime = startTime.toUTC().toFormat('HH:mm');

  return utcTime;
}
export function getTimezoneTime(time, timezone = 'Turkey') {
  if (!timezone) {
    return getTimezoneTime(time, 'Turkey');
  }

  if (!time) {
    return '00:00';
  }

  const parts = time?.split(':');
  const hours = parseInt(parts?.[0], 10);
  const minutes = parseInt(parts?.[1], 10);

  const now = new Date();

  now.setUTCHours(hours);
  now.setUTCMinutes(minutes);

  const utcTime = new Date(now.toLocaleString('en-US', { timeZone: timezone }));

  const UTCHours = utcTime.getHours();
  const UTCMinutes = utcTime.getMinutes();

  return `${UTCHours.toString().padStart(
    2,
    '0'
  )}:${UTCMinutes.toString().padStart(2, '0')}`;
}

export function setTimezoneDate(date, startTime, timezone) {
  if (!date) {
    return null;
  }

  const tempDate = date.getDate?.();
  const year = date.getFullYear?.();
  const month = date.getMonth?.() + 1;

  const newDate = DateTime.local(
    +year,
    +month,
    +tempDate,
    +startTime.split(':')[0],
    +startTime.split(':')[1],
    { zone: timezone }
  );

  return newDate.toString();
}

export function getTimezoneDate(date, timezone, startTime, endTime) {
  if (!timezone) {
    return getTimezoneDate(date, 'Turkey', startTime, endTime);
  }

  const timezoneDate = new Date(
    new Date(
      new Date(date).getFullYear(),
      new Date(date).getMonth(),
      new Date(date).getDate(),
      new Date(date).getHours(),
      new Date(date).getMinutes()
    )?.toLocaleString('en-US', {
      timeZone: `${timezone}`,
    })
  );

  const timezoneStartTime = getTimezoneTime(startTime, timezone);
  const timezoneEndTime = getTimezoneTime(endTime, timezone);

  return [timezoneDate, timezoneStartTime, timezoneEndTime];
}

export function getAllTimezonesNames() {
  const timezoneNames = [];

  TIMEZONES.forEach((timeZone) => {
    timezoneNames.push({ label: timeZone, value: timeZone });
  });

  return timezoneNames;
}
