/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import classNames from 'classnames';

import Tooltip from '../../../../../../Tooltip';
import Checkbox from '../../../../../../Form/Checkbox';

import { IMAGES_URL } from '../../../../../../../constants/main';
import classes from './styles.module.scss';

export default function User({
  user,
  reference,
  allMembers,
  addressees,
  setAddressees,
  onUserAdd,
  isActive,
}) {
  let avatar;

  if (
    user.userProfile?.avatar &&
    typeof user.userProfile?.avatar === 'string' &&
    !allMembers
  ) {
    avatar = `${IMAGES_URL}/avatar/${user.userProfile.avatar}?format=jpg&mode=crop&width=48&height=48&q=70`;
  } else if (user.userProfile?.avatar && allMembers) {
    avatar = user.userProfile.avatar;
  }

  const toggleAddressee = () => {
    if (
      onUserAdd &&
      !addressees.some((addressee) => addressee.value === user.id)
    ) {
      onUserAdd(
        `${user.userProfile?.firstName} ${user.userProfile?.lastName}`,
        user.id
      );
    }

    setAddressees((prevAddressees) => {
      let newAddressees = [];

      if (prevAddressees.some((addressee) => addressee.value === user.id)) {
        newAddressees = prevAddressees.filter(
          (addressee) => addressee.value !== user.id
        );
      } else {
        const prevMembers = allMembers
          ? []
          : prevAddressees.filter(
              (addressee) => addressee.value !== 'To all the employee'
            );

        newAddressees = [
          ...prevMembers,
          {
            value: user.id,
            label: `${user.userProfile?.firstName || ''} ${
              user.userProfile?.lastName || ''
            }`,
          },
        ];
      }

      return newAddressees;
    });
  };

  return (
    <li
      className={classNames(classes.User, {
        [classes.allMembers]: allMembers,
      })}
      ref={reference}
      onClick={toggleAddressee}
    >
      <Checkbox isSelected={isActive} />
      {user.userProfile?.avatar ? (
        <img src={avatar} alt="User" />
      ) : (
        <div className={classes.noPhoto}>
          {user.userProfile?.firstName?.[0]}
          {user.userProfile?.lastName?.[0]}
        </div>
      )}
      <Tooltip
        text={user?.userProfile.email || user?.userName}
        direction="top"
        fixed
        disabled={allMembers}
      >
        <span>{`${user.userProfile?.firstName || ''} ${
          user.userProfile?.lastName || ''
        }`}</span>
      </Tooltip>
    </li>
  );
}
