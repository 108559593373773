import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function Stage({ stageName, index, className }) {
  const { t } = useTranslation();

  return (
    <div className={classNames(classes.Stage, classes[className])}>
      <div className={classes.stageNumber}>{index + 1}</div>
      <span className={classes.stageTitle}>
        {t(`miscComponents.Table.Td.HiringStages.Stage.${stageName}`)}
      </span>
    </div>
  );
}
