import React from 'react';

import { useTranslation } from 'react-i18next';

import Attachment from './Attachment';

import classes from './styles.module.scss';

export default function Attachments({ attachments }) {
  const { t } = useTranslation();

  return (
    <div className={classes.Attachments}>
      <h3>{t('dashboardComponents.Discover.Feed.Post.Attachments.files')}</h3>
      <ul>
        {attachments.map((attachment) => (
          <Attachment key={attachment.id} attachment={attachment} />
        ))}
      </ul>
    </div>
  );
}
