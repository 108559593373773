import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import DashboardDropdown from '../../DashboardDropdown';

import classes from './styles.module.scss';
import AdminService from '../../../../../services/AdminService';

export default function DashboardHeader({
  location,
  setLocation,
  month,
  setMonth,
}) {
  const { t } = useTranslation();

  const { data: locations } = useQuery({
    queryKey: ['locations'],
    queryFn: () => AdminService.getRestaurants({ pageSize: 9999 }),
  });

  const locationOptions = locations?.data?.map((loc) => ({
    value: loc.id,
    label: loc.name,
  }));

  const monthOptions = [
    {
      value: '01',
      label: t('common.months.january'),
    },
    {
      value: '02',
      label: t('common.months.february'),
    },
    {
      value: '03',
      label: t('common.months.march'),
    },
    {
      value: '04',
      label: t('common.months.april'),
    },
    {
      value: '05',
      label: t('common.months.may'),
    },
    {
      value: '06',
      label: t('common.months.june'),
    },
    {
      value: '07',
      label: t('common.months.july'),
    },
    {
      value: '08',
      label: t('common.months.august'),
    },
    {
      value: '09',
      label: t('common.months.september'),
    },
    {
      value: '10',
      label: t('common.months.october'),
    },
    {
      value: '11',
      label: t('common.months.november'),
    },
    {
      value: '12',
      label: t('common.months.december'),
    },
  ];

  return (
    <header className={classes.DashboardHeader}>
      <h1>Dashboard</h1>
      <div className={classes.dropdowns}>
        <div className={classes.dropdownContainer}>
          <DashboardDropdown
            currentOption={location}
            setCurrentOption={setLocation}
            placeholder="Location"
            options={locationOptions}
          />
        </div>
        <div className={classes.dropdownContainer}>
          <DashboardDropdown
            currentOption={month}
            setCurrentOption={setMonth}
            placeholder="This month"
            options={monthOptions}
          />
        </div>
      </div>
    </header>
  );
}
