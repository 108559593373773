/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function DropdownOptions({
  currentOption,
  changeOption,
  options,
  width,
  left,
  top,
}) {
  return (
    <ul className={classes.DropdownOptions} style={{ width, left, top }}>
      {options.map((option) => (
        <li
          key={option}
          className={classNames({
            [classes.active]: currentOption === option,
          })}
          onClick={(event) => {
            event.stopPropagation();
            changeOption(option);
          }}
        >
          {option}
        </li>
      ))}
    </ul>
  );
}
