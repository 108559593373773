import React from 'react';

import { IMAGES_URL } from '../../../../constants/main';
import classes from './styles.module.scss';

export default function UserWithAvatar({ user }) {
  return (
    <div className={classes.UserWithAvatar}>
      {user?.avatar && (
        <img
          src={`${IMAGES_URL}/avatar/${user?.avatar}?format=jpg&mode=crop&width=20&height=20&q=80`}
          alt="avatar"
          className={classes.avatar}
        />
      )}
      <div className={classes.name}>
        <span>{user}</span>
      </div>
    </div>
  );
}
