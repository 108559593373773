/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React, { useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import classNames from 'classnames';
import DropdownWithTextInput from '../../../../../../../../Form/DropdownWIthTextInput';
import TextInput from '../../../../../../../../Form/TextInput';
import NumberInput from '../../../../../../../../Form/NumberInput';

import classes from './styles.module.scss';
import AdminService from '../../../../../../../../../services/AdminService';

export default function Question({
  question,
  vrQuestions,
  onDelete,
  value,
  setOption,
  setDuration,
  setQuestion,
  setTitle,
  areVrQuestionsValid,
  isTriedToSubmit,
  isJobPostForm,
}) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  useEffect(() => {
    const setQuestionAndDuration = async () => {
      try {
        if (question.savedQuestion?.value) {
          const existingQuestion = await AdminService.getVrQuestion({
            id: question.savedQuestion.value,
          });

          setQuestion(question.id, existingQuestion?.question);
          setDuration(question.id, existingQuestion?.duration);
          setTitle(question.id, existingQuestion?.title);
        }
      } catch (error) {
        console.log(error);
      }
    };

    setQuestionAndDuration();
  }, [
    //   existingVrQuestions,
    question.id,
    question.savedQuestion,
    setDuration,
    setQuestion,
    setTitle,
  ]);

  let vrQuestionTitleError;
  let vrQuestionTitleWarning;

  if (question?.title?.length < 5) {
    vrQuestionTitleWarning = t(
      'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.Questions.Question.TitleTooShortWarning'
    );
  }

  if (!question?.title?.length /*  && !question?.savedQuestion.label */) {
    vrQuestionTitleError = t(
      'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.Questions.Question.TitleEmptyError'
    );
  }

  let vrDurationError;
  let vrDurationWarning;
  let vrDurationsNoValid;

  if (question?.duration > 90 && question?.duration <= 120) {
    vrDurationWarning = t(
      'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.Questions.Question.DurationNotOptimalWarning'
    );
  }

  if (question?.duration > 120 || question?.duration === 0) {
    vrDurationError = t(
      'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.Questions.Question.DurationTooHighError'
    );
  }

  if (question?.duration === 0) {
    vrDurationError = t(
      'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.Questions.Question.DurationTooSmallError'
    );
  }

  if (vrQuestions.some((question) => question.duration === 0)) {
    vrDurationsNoValid = true;
  } else {
    vrDurationsNoValid = false;
  }

  let vrQuestionError;
  let vrQuestionWarning;

  if (question.question && question.question.length < 5) {
    vrQuestionWarning = t(
      'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.Questions.Question.QuestionTooShortWarning'
    );
  }

  if (!question.question) {
    vrQuestionError = t(
      'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.Questions.Question.QuestionEmptyError'
    );
  }

  if (
    vrQuestionTitleError ||
    vrDurationError ||
    vrDurationWarning ||
    vrQuestionError ||
    vrDurationsNoValid
  ) {
    areVrQuestionsValid.current = false;
  } else {
    areVrQuestionsValid.current = true;
  }

  const durationWithDeleteButton = (
    <div className={classes.durationWithDeleteButtonContainer}>
      <div className={classes.numberInput}>
        <NumberInput
          withBackground
          height={45}
          labelStyle={{
            fontSize: 16,
            marginBottom: 15,
          }}
          note={t(
            'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.Questions.Question.inSeconds'
          )}
          label={t(
            'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.Questions.Question.DurationLabel'
          )}
          value={question.duration}
          setFieldValue={(_name, value) => setDuration(question.id, value)}
          error={vrDurationError}
          touched={isTriedToSubmit}
          warning={vrDurationWarning}
          mode="duration"
        />
      </div>
      <i className={classes.deleteIcon} onClick={() => onDelete(question.id)}>
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.99975 3.99582L8.7878 0.207778C9.06483 -0.0692594 9.514 -0.0692595 9.79104 0.207778C10.0681 0.484816 10.0681 0.933983 9.79104 1.21102L6.00299 4.99906L9.79233 8.7884C10.0694 9.06544 10.0694 9.5146 9.79233 9.79164C9.51529 10.0687 9.06612 10.0687 8.78908 9.79164L4.99975 6.00231L1.21102 9.79104C0.933983 10.0681 0.484816 10.0681 0.207778 9.79104C-0.0692595 9.514 -0.0692594 9.06483 0.207778 8.7878L3.99651 4.99906L0.209068 1.21162C-0.0679701 0.934586 -0.0679701 0.485419 0.209068 0.208381C0.486105 -0.0686568 0.935272 -0.0686568 1.21231 0.208381L4.99975 3.99582Z"
            fill="white"
          />
        </svg>
      </i>
    </div>
  );

  let vrQuestionTitleWidth = isJobPostForm ? 300 : 400;

  if (isTabletOrMobile) {
    vrQuestionTitleWidth = '100%';
  }

  return (
    <div
      className={classNames(classes.Question, {
        [classes.jobPostForm]: isJobPostForm,
      })}
    >
      <div className={classes.row}>
        <div className={classes.titleContainer}>
          <div className={classes.handle}>
            <svg
              width="11"
              height="35"
              viewBox="0 0 11 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2" cy="2" r="2" fill="#C1C5D6" />
              <circle cx="9" cy="2" r="2" fill="#C1C5D6" />
              <circle cx="2" cy="12.3359" r="2" fill="#C1C5D6" />
              <circle cx="9" cy="12.3359" r="2" fill="#C1C5D6" />
              <circle cx="2" cy="22.6641" r="2" fill="#C1C5D6" />
              <circle cx="9" cy="22.6641" r="2" fill="#C1C5D6" />
              <circle cx="2" cy="33" r="2" fill="#C1C5D6" />
              <circle cx="9" cy="33" r="2" fill="#C1C5D6" />
            </svg>
          </div>
          <DropdownWithTextInput
            height={45}
            labelStyle={{
              fontSize: 16,
              marginBottom: 15,
              marginLeft: isTabletOrMobile ? -25 : '',
            }}
            note={`25 ${t('common.characters')}`}
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.Questions.Question.VrQuestionTitleLabel'
            )}
            fetchOptions={{
              dataName: 'vrQuestionsOptionsQuestion',
              getDataHandler: AdminService.getVrQuestions,
              labelName: 'title',
            }}
            value={value}
            width={vrQuestionTitleWidth}
            placeholder={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.Questions.Question.PickOrTypeTitlePlaceholder'
            )}
            setFieldValue={(_name, option) => setOption(question.id, option)}
            textInputValue={question.title}
            setTextInputValue={useCallback(
              (value) => setTitle(question.id, value),
              [question.id, setTitle]
            )}
            touched={isTriedToSubmit}
            error={vrQuestionTitleError}
            warning={vrQuestionTitleWarning}
          />
        </div>

        {!isJobPostForm && durationWithDeleteButton}
      </div>
      <div className={classNames(classes.row, classes.vrQuestionContainer)}>
        <TextInput
          labelStyle={{
            fontSize: 16,
            marginBottom: 15,
          }}
          note={`25 ${t('common.characters')}`}
          label={t(
            'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.Questions.Question.VRQuestionLabel'
          )}
          height={45}
          width="100%"
          placeholder={t(
            'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.Questions.Question.TypeQuestionPlaceholder'
          )}
          onChange={(event) => setQuestion(question.id, event.target.value)}
          value={question.question}
          touched={isTriedToSubmit}
          error={vrQuestionError}
          warning={vrQuestionWarning}
        />
      </div>
      {isJobPostForm && durationWithDeleteButton}
    </div>
  );
}
