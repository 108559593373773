import React from 'react';

import { get } from 'lodash';

import TextInput from '../../../../Form/TextInput';
import DatePicker from '../../../../Form/DatePicker';
import FormDropdown from '../../../../Form/FormDropdown';

export const createInitialValues = (
  detailsMainInputs,
  nameInputs,
  currentCandidate
) => {
  const allInputs = [...detailsMainInputs, ...nameInputs];
  console.log(allInputs);
  if (!currentCandidate) {
    return {
      ...allInputs.reduce((acc, input) => {
        acc[input.name] = input.initialValue || '';
        return acc;
      }, {}),
      userProfileExperiences: [],
      userProfileFileResume: '',
      jobPostId: '',
      applicationStatus: '',
      applicationSubStatus: '',
    };
  }

  const initialValues = {
    ...allInputs.reduce((acc, input) => {
      acc[input.name] = currentCandidate?.userProfile?.[input.name];
      return acc;
    }, {}),
    userProfileExperiences: currentCandidate?.userProfileExperiences,
  };

  if (initialValues.birthDate) {
    initialValues.birthDate = new Date(initialValues.birthDate);
  }
  if (initialValues.userProfileGraduation) {
    initialValues.userProfileGraduation = {
      label: initialValues.userProfileGraduation.name,
      value: initialValues.userProfileGraduation.id,
    };
  }

  if (currentCandidate.userProfile?.userProfileExperiences?.length) {
    initialValues.hasExperience = { label: 'Yes', value: true };
    initialValues.userProfileExperiences =
      currentCandidate.userProfile.userProfileExperiences;
  } else {
    initialValues.hasExperience = { label: 'No', value: false };
    initialValues.userProfileExperiences = [];
  }

  initialValues.sourceName = {
    label: currentCandidate?.sourceName,
    value: currentCandidate?.sourceName,
  };

  initialValues.userProfileFileResume =
    currentCandidate?.userProfile?.userProfileFileResume;

  // Job application values
  if (currentCandidate.jobApplications?.length) {
    initialValues.jobPostId = {
      label: currentCandidate.jobApplications[0].jobPost.jobTitle,
      value: currentCandidate.jobApplications[0].jobPost.id,
    };
    initialValues.applicationStatus = {
      label: currentCandidate.jobApplications[0].status,
      value: currentCandidate.jobApplications[0].status,
    };
    initialValues.applicationSubStatus =
      currentCandidate.jobApplications[0].subStatus;
  }

  return initialValues;
};

export const createExperienceSubFormInitailValues = (experienceInputs) =>
  experienceInputs.reduce((acc, input) => {
    acc[input.name] = '';
    return acc;
  }, {});

export const areAllRequiredFieldsFilled = (formik, validationSchema) => {
  const isFieldRequired = (fieldName) => {
    return validationSchema.fields[fieldName]?.exclusiveTests?.required;
  };

  return Object.keys(validationSchema.fields).every((fieldName) => {
    return !isFieldRequired(fieldName) || !!formik.values[fieldName];
  });
};

export function renderInputs({
  input,
  formik,
  warnings,
  inputWidth = '100%',
  customName,
  touched,
  validatedEmail,
  isReadOnly,
}) {
  const name = customName || input.name;
  const inputHeight = 45;
  const labelStyle = {
    fontSize: 14,
    fontFamily: 'Gilroy-Regular',
    fontWeight: 'normal',
    color: '#C1C5D6',
    lineHeight: '20px',
  };

  let isTouched = touched;

  if (input.name === 'email') {
    isTouched = !validatedEmail?.isValid ? true : touched;
  }

  let error = get(formik.errors, name);

  if (input.name === 'email' && !validatedEmail?.isValid) {
    error = 'Duplicate email. Account already exists.';
  }

  const isDisabled = !validatedEmail?.isValid && input.name !== 'email';

  switch (input.type) {
    case 'text':
      return (
        <TextInput
          key={name}
          placeholder={input.placeholder}
          label={input.label}
          width={inputWidth}
          height={inputHeight}
          labelStyle={labelStyle}
          value={get(formik.values, name)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={error}
          touched={isTouched}
          name={name}
          staticErrorPositon
          readonly={isDisabled || isReadOnly}
        />
      );
    case 'date':
      return (
        <DatePicker
          key={name}
          placeholder={input.placeholder}
          label={input.label}
          width={inputWidth}
          dateFieldWidth={inputWidth}
          height={inputHeight}
          labelStyle={labelStyle}
          calendarPosition="right"
          name={name}
          value={get(formik.values, name)}
          setFieldValue={formik.setFieldValue}
          touched={isTouched}
          error={error}
          setFieldTouched={formik.setFieldTouched}
          onlyPastDates
          warning={warnings?.[name]}
          staticErrorPositon
          readonly={isDisabled || isReadOnly}
          newCalendar
        />
      );
    case 'select':
      return (
        <FormDropdown
          key={name}
          placeholder={input.placeholder}
          label={input.label}
          width={inputWidth}
          height={inputHeight}
          labelStyle={labelStyle}
          fetchOptions={input.fetchOptions}
          options={input.options}
          name={name}
          value={formik.values[name]}
          error={error}
          touched={isTouched}
          setFieldValue={formik.setFieldValue}
          setFieldTouched={formik.setFieldTouched}
          readonly={isDisabled || isReadOnly}
        />
      );
    default:
      return null;
  }
}
