/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import DefaultAvatar from '../../DefaultAvatar';

import { IMAGES_URL } from '../../../../../../constants/main';
import classes from './styles.module.scss';

export default function CandidateCard({
  candidate,
  setIsCandidatePopupVisible,
  setCurrentCandidate,
}) {
  const handleClick = () => {
    setCurrentCandidate(candidate);
    setIsCandidatePopupVisible(true);
  };

  return (
    <li className={classes.CandidateCard} onClick={handleClick}>
      <div className={classes.photContainer}>
        {candidate.userProfile?.avatar ? (
          <img
            src={`${IMAGES_URL}/avatar/${candidate.userProfile.avatar}`}
            alt="Candidate"
          />
        ) : (
          <div className={classes.defaultAvatarContainer}>
            <DefaultAvatar
              firstName={candidate.userProfile?.firstName}
              lastName={candidate.userProfile?.lastName}
            />
          </div>
        )}
      </div>
      <h3 className={classes.name}>
        {candidate.userProfile?.firstName} {candidate.userProfile?.lastName}
      </h3>
      <span className={classes.location}>
        {candidate.userProfile?.city}, {candidate.userProfile?.countryCode}
      </span>
    </li>
  );
}
