import React from 'react';

import { useTranslation } from 'react-i18next';

import Tooltip from '../../../../../../Tooltip';

import classes from './styles.module.scss';
import eyeIcon from '../../../../../../../assets/images/dashboard/discover/eye.svg';

export default function ViewIcon() {
  const { t } = useTranslation();

  return (
    <div className={classes.ViewIcon}>
      <Tooltip
        text={t(
          'dashboardComponents.Discover.Feed.Post.Header.ViewIcon.Viewed'
        )}
        direction="top"
      >
        <img src={eyeIcon} alt="Viewed" />
      </Tooltip>
    </div>
  );
}
