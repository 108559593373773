import React from 'react';

import { useTranslation } from 'react-i18next';

import AdminService from '../../../../../services/AdminService';
import TableView from '../../TableView';

export default function Tags({ setItemCount }) {
  const { t } = useTranslation();

  const columns = [
    {
      name: t('dashboardComponents.Console.RefDataManagement.Tags.tagName'),
      field: 'name',
      sortable: true,
    },
    {
      name: t('common.sortOrder'),
      field: 'sortOrder',
      sortable: true,
    },
    {
      name: t('common.notes'),
      field: 'note',
      sortable: false,
    },
    {
      name: t('common.createdOn'),
      field: 'createdAt',
      sortable: true,
      type: 'date',
    },
  ];

  return (
    <TableView
      setItemCount={setItemCount}
      dataName="Tag"
      getDataHandler={AdminService.getTags}
      deleteDataHandler={AdminService.deleteTags}
      createDataHandler={AdminService.createTag}
      updateDataHandler={AdminService.updateTag}
      viewLeftColumnFields={[
        {
          label: t(
            'dashboardComponents.Console.RefDataManagement.Tags.tagName'
          ),
          field: 'name',
        },
      ]}
      viewRightColumnFields={[
        {
          label: t('common.sortOrder'),
          field: 'sortOrder',
        },
        {
          label: t('common.notes'),
          field: 'note',
          type: 'textarea',
        },
      ]}
      formLeftColumnFields={[
        {
          label: t(
            'dashboardComponents.Console.RefDataManagement.Tags.tagName'
          ),
          field: 'name',
          validation: ['string', 'required'],
          placeholder: t(
            'dashboardComponents.Console.RefDataManagement.Tags.skillNamePlaceholder'
          ),
        },
      ]}
      formRightColumnFields={[
        {
          label: t('common.sortOrder'),
          field: 'sortOrder',
          validation: ['string', 'required', 'numbers'],
          placeholder: t(
            'dashboardComponents.Console.RefDataManagement.Tags.sortOrderPlaceholder'
          ),
          maxLength: 5,
        },
        {
          label: t('common.notes'),
          field: 'note',
          type: 'textarea',
          validation: ['string'],
          placeholder: t(
            'dashboardComponents.Console.RefDataManagement.Tags.notesPlaceholder'
          ),
        },
      ]}
      columns={columns}
    />
  );
}
