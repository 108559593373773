import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function DaysFromCreation({ value }) {
  return (
    <td
      className={classNames(classes.DaysFromCreation, {
        [classes.orange]: value > 30 && value <= 45,
        [classes.red]: value > 45,
      })}
    >
      {value}
    </td>
  );
}
