/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import StepOne from './BasicInfo';
import StepTwo from './VrAndAssessment';

import classes from './styles.module.scss';

const grayCircle = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11.4349 16.6402L18.1016 8.64018L16.5651 7.35982L10.5995 14.5186L7.37377 11.2929L5.95956 12.7071L9.95956 16.7071L10.7339 17.4814L11.4349 16.6402Z"
      fill="#C1C5D6"
    />
  </svg>
);

const arrowDown = (
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 1L7 7L1 1" stroke="#00E4AC" strokeWidth="2" />
  </svg>
);

const arrowUp = (
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00618 8.01561L6.99217 2.00163L13.0061 7.98762"
      stroke="#242833"
      strokeWidth="2"
    />
  </svg>
);

export default function Template({
  formik,
  data,
  isTriedToSubmit,
  isEdit,
  vrQuestions,
  setVrQuestions,
  useDefaultVrQuestions,
  setUseDefaultVrQuestions,
  areVrQuestionsValid,
  readOnly,
  assessments,
  setAssessments,
}) {
  const [isStepOneOpen, setIsStepOneOpen] = useState(false);
  const [isStepTwoOpen, setIsStepTwoOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <div className={classes.Template}>
      <div
        className={classes.toggle}
        onClick={() => setIsStepOneOpen((prevState) => !prevState)}
      >
        <div className={classes.info}>
          <span>
            {t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.StepLabel',
              { step: 1 }
            )}
          </span>
          <h1>
            {t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfoLabel'
            )}
          </h1>
        </div>
        <div className={classes.arrowBlock}>
          {grayCircle}
          {isStepOneOpen ? arrowUp : arrowDown}
        </div>
      </div>
      <div
        className={classes.stepOneContainer}
        style={{ display: isStepOneOpen ? 'block' : 'none' }}
      >
        <StepOne
          formik={formik}
          data={data}
          isTriedToSubmit={isTriedToSubmit}
          isEdit={isEdit}
          readOnly={readOnly}
        />
      </div>
      <div
        className={classNames(classes.toggle, classes.secondOne)}
        onClick={() => setIsStepTwoOpen((prevState) => !prevState)}
      >
        <div className={classes.info}>
          <span>
            {t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.StepLabel',
              { step: 2 }
            )}
          </span>
          <h1>
            {t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessmentLabel'
            )}
          </h1>
        </div>
        <div className={classes.arrowBlock}>
          {grayCircle}
          {isStepTwoOpen ? arrowUp : arrowDown}
        </div>
      </div>
      <div style={{ display: isStepTwoOpen ? 'block' : 'none' }}>
        <StepTwo
          formik={formik}
          vrQuestions={vrQuestions}
          setVrQuestions={setVrQuestions}
          useDefaultVrQuestions={useDefaultVrQuestions}
          setUseDefaultVrQuestions={setUseDefaultVrQuestions}
          isEdit={isEdit}
          data={data}
          isTriedToSubmit={isTriedToSubmit}
          areVrQuestionsValid={areVrQuestionsValid}
          readOnly={readOnly}
          assessments={assessments}
          setAssessments={setAssessments}
        />
      </div>
    </div>
  );
}
