import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';
import Candidate from '../../Candidate';
import NoFavorites from '../NoFavorites';
import CandidateCard from './CandidateCard';

import classes from './styles.module.scss';
import { hasVerticalScrollbar } from '../../../../helpers/misc';

export default function Candidates({ candidates, refetch, favorite, job }) {
  const [isCandidatePopupVisible, setIsCandidatePopupVisible] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState(null);

  const candidatesRef = useRef();

  return (
    <div
      className={classNames(classes.Candidates, {
        [classes.noFavorites]: candidates?.length === 0 && favorite,
      })}
    >
      {candidates?.length === 0 && favorite ? (
        <NoFavorites candidates />
      ) : (
        <div
          className={classNames(classes.candidates, {
            [classes.scroll]: hasVerticalScrollbar(candidatesRef.current),
          })}
          ref={candidatesRef}
        >
          {candidates
            ?.map((favCandidate) => (
              <CandidateCard
                favCandidate={favCandidate}
                key={favCandidate.id}
                refetch={refetch}
                favorite={favorite}
                job={job}
                setIsCandidatePopupVisible={setIsCandidatePopupVisible}
                setCurrentCandidate={setCurrentCandidate}
              />
            ))
            .reverse()}
          {createPortal(
            <Candidate
              isVisible={isCandidatePopupVisible}
              setIsVisible={setIsCandidatePopupVisible}
              refetch={refetch}
              candidate={currentCandidate}
              setCurrentCandidate={setCurrentCandidate}
              candidates={candidates}
            />,
            document.body
          )}
        </div>
      )}
    </div>
  );
}
