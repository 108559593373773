import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function PostAJobBanner() {
  const navigate = useNavigate();

  const { t } = useTranslation();
  return (
    <div className={classes.PostAJobBanner}>
      <h2>{t('dashboardComponents.Jobs.MyJobs.PostAJobBanner.jobs')}</h2>
      <button type="button" onClick={() => navigate('/tools/jobs/post-job')}>
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.07227 1.64062L8.07227 15.6406"
            stroke="#6722D2"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M15.0723 8.64062H1.07227"
            stroke="#6722D2"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
        {t('dashboardComponents.Jobs.MyJobs.PostAJobBanner.postAJob')}
      </button>
    </div>
  );
}
