import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../Button';
import TextEditor from '../TextEditor';

import classes from './styles.module.scss';

export default function MessageEditor({
  setIsEditorVisible,
  isEditorVisible,
  sharedClasses,
  editingPost,
  title,
  setTitle,
  message,
  setMessage,
  addressees,
  setAddressees,
  tags,
  setTags,
  isDraftButtonDisabled,
  files,
  setFiles,
  setUploadedFiles,
  uploadedFiles,
  textBoxRef,
  updateFeedPost,
  createFeedPost,
  cancel,
  contentType,
  noDraft,
  fileNames,
  setFileNames,
  isFetching,
  // savedDraft,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.MessageEditor}>
      {isEditorVisible ? (
        <>
          <TextEditor
            title={title}
            setTitle={setTitle}
            message={message}
            setMessage={setMessage}
            addressees={addressees}
            setAddressees={setAddressees}
            tags={tags}
            setTags={setTags}
            sharedClasses={sharedClasses}
            textBoxRef={textBoxRef}
            files={files}
            setFiles={setFiles}
            setUploadedFiles={setUploadedFiles}
            fileNames={fileNames}
            setFileNames={setFileNames}
            uploadedFiles={uploadedFiles}
          />
          <div className={classes.buttons}>
            <Button
              disabled={
                !textBoxRef.current?.innerText.trim().length ||
                !addressees.length ||
                isFetching
              }
              onClick={() => {
                if (editingPost) {
                  if (editingPost.status === 'Draft') {
                    updateFeedPost(false, 'Published');
                    return;
                  }
                  updateFeedPost();
                } else {
                  createFeedPost('Published');
                }
              }}
            >
              {editingPost && editingPost.status !== 'Draft'
                ? t('common.save')
                : t('common.send')}
            </Button>
            {(!editingPost || editingPost.status === 'Draft') && !noDraft && (
              <Button
                disabled={isDraftButtonDisabled}
                onClick={() => {
                  if (editingPost?.status === 'Draft') {
                    updateFeedPost();
                  } else {
                    createFeedPost('Draft');
                  }
                }}
              >
                {t(
                  'dashboardComponents.Discover.AddContent.MessageEditor.saveAsDraft'
                )}
              </Button>
            )}
            <button
              className={classes.cancelButton}
              type="button"
              onClick={cancel}
            >
              {t('common.cancel')}
            </button>
          </div>
        </>
      ) : (
        <div
          className={classes.welcome}
          onClick={() => {
            setIsEditorVisible(true);
          }}
        >
          {t('common.send')} {contentType || 'message'}...
        </div>
      )}
    </div>
  );
}
