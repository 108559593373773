import React from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import Slider from './Slider';

import AdminService from '../../../../services/AdminService';
import classes from './styles.module.scss';

export default function Ads() {
  const { t } = useTranslation();

  const { data: discoverAds } = useQuery({
    queryKey: ['discoverAds'],
    queryFn: () => AdminService.getDiscoverAds(),
  });

  return (
    <div className={classes.Ads}>
      <h2>{t('dashboardComponents.Discover.Ads.ads')}</h2>
      <div className={classes.content}>
        <Slider data={discoverAds} />
      </div>
    </div>
  );
}
