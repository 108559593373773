/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Tooltip from '../../Tooltip';
import classes from './styles.module.scss';

export default function Textarea({
  name,
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  touched,
  success,
  warning,
  width,
  height,
  readonly,
  maxLength,
  info,
  infoWidth,
  note,
  style,
  labelStyle,
}) {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(classes.Textarea, {
        [classes.error]: error && touched,
        [classes.success]: !error && !warning && touched && value?.length,
        [classes.warning]: !error && warning && touched,
      })}
    >
      <label
        htmlFor={name}
        style={{ ...labelStyle, display: label ? 'flex' : 'none' }}
      >
        {label}{' '}
        {info && (
          <Tooltip text={info} width={infoWidth} direction="top-right">
            <i className={classes.infoIcon}>
              <svg
                width="3"
                height="10"
                viewBox="0 0 3 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.25H1.03125"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 8.75V4.75"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          </Tooltip>
        )}{' '}
        {note && <span className={classes.note}>({note})</span>}
        {maxLength && (
          <span className={classes.maxLength}>
            ({maxLength} {t('formComponents.Textarea.charMax')})
          </span>
        )}
      </label>
      <textarea
        style={{ width, height, ...style }}
        name={name}
        id={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={readonly ? () => {} : onBlur}
        readOnly={readonly}
        maxLength={maxLength}
      />
      {error && touched && <div className={classes.status}>{error}</div>}
      {!error && touched && success && (
        <div className={classes.status}>{success}</div>
      )}
      {!error && touched && warning && (
        <div className={classes.status}>{warning}</div>
      )}
      {maxLength && (
        <div className={classes.charCounter}>
          {maxLength - (value?.length || 0)}{' '}
          {t('formComponents.Textarea.charLeft')}
        </div>
      )}
    </div>
  );
}
