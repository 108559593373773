import React, { useEffect, useState } from 'react';
import classes from './styles.module.scss';

export default function GraphBar({ city, totalAmount }) {
  const [greenBarSize, setGreenBarSize] = useState(0);

  useEffect(() => {
    setGreenBarSize(city.count / (totalAmount / 100));
  }, [city.count, totalAmount]);

  return (
    <div className={classes.GraphBar}>
      <span className={classes.name}>{city.title}</span>
      <div className={classes.bar}>
        <div className={classes.greenBar} style={{ width: `${greenBarSize}%` }}>
          <span className={classes.amount}>{city.count}</span>
        </div>
      </div>
    </div>
  );
}
