import React from 'react';

import classNames from 'classnames';

import { ALPHABET } from '../../../../../../../../constants/main';
import classes from './styles.module.scss';

export default function Answer({
  answer,
  index,
  allowsMultipleAnswers,
  isActive,
  answerQuestion,
  hasVoted,
  votes,
  voteCount,
  isPollClosed,
}) {
  const answerPercentage =
    hasVoted || isPollClosed ? Math.round((votes / voteCount) * 100) : 0;

  return (
    <div className={classes.Answer}>
      <span>{ALPHABET[index].toUpperCase()}.</span>
      <div
        className={classNames(classes.container, {
          [classes.active]: isActive,
          [classes.hasVoted]: hasVoted || isPollClosed,
        })}
        onClick={() => answerQuestion(answer)}
      >
        <div
          className={classNames(classes.input, {
            [classes.multiple]: allowsMultipleAnswers,
          })}
        />
        <span
          className={classNames(classes.title, {
            [classes.white]: answerPercentage > 0,
          })}
        >
          {answer.title}
        </span>
        <div
          className={classes.progress}
          style={{
            width: `${answerPercentage}%`,
          }}
        />
      </div>
      {(hasVoted || isPollClosed) && (
        <div
          className={classNames(classes.voteStats, {
            [classes.noStats]: !answerPercentage,
          })}
        >
          <span className={classes.voteCount}>{votes}</span>
          <span className={classes.percentage}>{answerPercentage || 0}%</span>
        </div>
      )}
    </div>
  );
}
