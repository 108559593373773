import React, { useState, useContext } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Note from './Note';
import ArrowMenu from '../../../../../ArrowMenu';
import Checkbox from '../../../../../Form/Checkbox';

import { IMAGES_URL } from '../../../../../../constants/main';
import { JobsContext } from '../../../../../../context/JobsContext';
import { CandidatesContext } from '../../../../../../context/CandidatesContext';
import classes from './styles.module.scss';
import DefaultAvatar from '../../DefaultAvatar';
import FavStatus from '../../../../Header/Search/FavStatus';

export default function CandidateGridCard({
  reference,
  candidate,
  refetch,
  toggleCandidateSelection,
  selectedRows,
  showCandidateInfo,
}) {
  const [isArrowMenuVisible, setIsArrowMenuVisible] = useState(false);

  const { showShareModal } = useContext(JobsContext);
  const { showAddCandidateNoteModal, showInviteToJobModal } =
    useContext(CandidatesContext);

  const { t } = useTranslation();

  const sendEmailToCandidate = () => {
    if (!candidate.userProfile?.email) {
      return;
    }
    window.location.href = `mailto:${candidate.userProfile?.email}`;
  };

  const options = [
    {
      label: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.CandidateGridCard.options.inviteToJob'
      ),
      onClick: () => showInviteToJobModal(candidate),
    },
    {
      label: candidate?.note
        ? t(
            'dashboardComponents.Candidates.CandidatesList.AllCandidates.CandidateGridCard.options.editNote'
          )
        : t(
            'dashboardComponents.Candidates.CandidatesList.AllCandidates.CandidateGridCard.options.addNote'
          ),
      onClick: () => showAddCandidateNoteModal(candidate),
      withBorder: true,
    },
    {
      label: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.CandidateGridCard.options.emailCandidate'
      ),
      onClick: () => sendEmailToCandidate(),
    },
    {
      label: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.CandidateGridCard.options.share'
      ),
      onClick: () => showShareModal({ candidate }),
    },
  ];

  const isJoined = candidate.jobApplications?.some?.(
    (jobApp) => jobApp.subStatus === 'Joined'
  );

  if (isJoined) {
    options.splice(0, 1);
  }

  return (
    <div className={classes.container} ref={reference}>
      <div className={classes.border} />
      <div
        className={classNames(classes.CandidateGridCard, {
          [classes.joined]: isJoined,
        })}
        onClick={() => {
          showCandidateInfo(candidate.id);
        }}
      >
        {candidate.note && <Note text={candidate.note} />}
        <header className={classes.header}>
          <Checkbox
            isSelected={selectedRows.includes(candidate.id)}
            onChange={(e) => {
              e.stopPropagation();
              toggleCandidateSelection(candidate.id);
            }}
          />
          <div className={classes.buttons}>
            <FavStatus
              candidate={candidate}
              refetch={refetch}
              white={isJoined}
            />
            <ArrowMenu
              options={options}
              isVisible={isArrowMenuVisible}
              setIsVisible={setIsArrowMenuVisible}
            >
              <div className={classes.threeDots} />
            </ArrowMenu>
          </div>
        </header>
        <div className={classes.photoBlock}>
          {candidate.userProfile?.avatar ? (
            <img
              src={`${IMAGES_URL}/avatar/${candidate.userProfile.avatar}`}
              alt="Candidate"
            />
          ) : (
            <div className={classes.defaultAvatarContainer}>
              <DefaultAvatar
                firstName={candidate.userProfile?.firstName}
                lastName={candidate.userProfile?.lastName}
              />
            </div>
          )}
          <h3 className={classes.name}>
            {candidate.userProfile?.firstName} {candidate.userProfile?.lastName}
          </h3>
          <span className={classes.location}>
            {candidate.userProfile?.firstName} {candidate.userProfile?.lastName}
          </span>
        </div>
        <div className={classes.candidateDetails}>
          <div className={classes.jobsApplied}>
            <span className={classes.title}>
              {t(
                'dashboardComponents.Candidates.CandidatesList.AllCandidates.CandidateGridCard.labels.jobsApplied'
              )}
            </span>
            <div className={classes.count}>
              {candidate.jobApplications?.length}
            </div>
          </div>
          <div className={classes.email}>{candidate.userProfile?.email}</div>
          <div className={classes.phone}>{candidate.userProfile?.phone}</div>
        </div>
      </div>
    </div>
  );
}
