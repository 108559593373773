import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import TimeRange from '../../../components/Form/TimeRange';
import DatePicker from '../../../components/Form/DatePicker';
import Textarea from '../../../components/Form/Textarea';
import SelectedUsers from '../../../components/Dashboard/SelectedUsers';
import MultiDropdown from '../../../components/Form/MultiDropdown';
import FormDropdown from '../../../components/Form/FormDropdown';

import AdminService from '../../../services/AdminService';
import classes from './styles.module.scss';

export default function InPerson({
  formik,
  isTriedToSubmit,
  isPopupOpened,
  calendar,
}) {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(classes.InPerson, {
        [classes.calendar]: calendar,
      })}
    >
      <div className={classes.row}>
        <div className={classes.col}>
          <DatePicker
            dateFieldWidth={calendar ? '100%' : 350}
            height={calendar ? 45 : ''}
            labelStyle={{
              fontSize: 14,
            }}
            style={{
              backgroundColor: calendar ? 'white' : '',
            }}
            label={t('modals.SetOnboardingModal.InPerson.onboardingDate')}
            name="onboardingDate"
            value={formik.values.onboardingDate}
            setFieldValue={formik.setFieldValue}
            error={formik.errors.onboardingDate}
            // touched={isDelegatesTriedToSubmit}
            setFieldTouched={formik.setFieldTouched}
            isPopupOpened={isPopupOpened}
          />
          <FormDropdown
            label={t('modals.SetOnboardingModal.InPerson.onboardingLocation')}
            placeholder={t(
              'modals.SetOnboardingModal.InPerson.onboardingLocationPlaceholder'
            )}
            fetchOptions={{
              dataName: 'restaurantsOptionsBasicInfo',
              getDataHandler: AdminService.getRestaurants,
            }}
            value={formik.values.location}
            infoWidth={151}
            height={calendar ? 45 : ''}
            style={{
              backgroundColor: calendar ? 'white' : '',
            }}
            name="location"
            width="100%"
            searchInfo={t(
              'modals.SetOnboardingModal.InPerson.addNewRestaurantInfo'
            )}
            searchInfoWidth={188}
            setFieldValue={formik.setFieldValue}
          />
        </div>
        <div className={classes.col}>
          <TimeRange
            style={{
              backgroundColor: calendar ? 'white' : '',
            }}
            height={calendar ? 45 : ''}
            fromValue={formik.values.startTime}
            toValue={formik.values.endTime}
            setFieldValue={formik.setFieldValue}
            fromName="startTime"
            toName="endTime"
            error={formik.errors.endTime}
            touched={isTriedToSubmit}
          />
          <div
            className={classes.сontact}
            style={{
              position: 'relative',
              top: calendar ? -5 : 'unset',
            }}
          >
            <div className={classes.selectContainer}>
              <MultiDropdown
                style={{
                  backgroundColor: calendar ? 'white' : '',
                }}
                height={calendar ? 45 : ''}
                maxLength={3}
                note={t('modals.SetOnboardingModal.InPerson.selectUpTo3')}
                width={calendar ? '100%' : 350}
                label={t('modals.SetOnboardingModal.InPerson.personToMeet')}
                fetchOptions={{
                  dataName: 'userOptionPostJobSuccess',
                  getDataHandler: AdminService.getUsers,
                  labelName: 'userName',
                  isUserList: true,
                }}
                value={formik.values.userIds}
                name="userIds"
                setFieldValue={formik.setFieldValue}
                placeholder={t(
                  'modals.SetOnboardingModal.InPerson.selectNameAndEmail'
                )}
              />
            </div>
            <div className={classes.selectedUsers}>
              <SelectedUsers
                invitedUsers={formik.values.userIds}
                withFormik
                setInvitedUsers={(users) =>
                  formik.setFieldValue('userIds', users)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(classes.row, classes.textareaContainer)}>
        <Textarea
          label={t('modals.SetOnboardingModal.InPerson.emailMessage')}
          note={t('modals.SetOnboardingModal.InPerson.optional')}
          height={112}
          style={{
            backgroundColor: calendar ? 'white' : '',
          }}
          onChange={formik.handleChange}
          value={formik.values.note}
          name="note"
          placeholder={t('modals.SetOnboardingModal.InPerson.enterNotes')}
          // maxLength={5000}
        />
      </div>
    </div>
  );
}
