import React, { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import DropdownOptions from '../../../DropdownOptions';
import Button from '../../../Button';
import Tab from './Tab';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import sortIcon from '../../../../assets/images/dashboard/sort-icon.svg';
import classes from './styles.module.scss';

export default function Header({
  tabs,
  activeTab,
  setActiveTab,
  readNotifications,
  currentSortOption,
  setCurrentSortOption,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const dropdownRef = useRef();

  const { t } = useTranslation();

  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  const changeSortOption = (option) => {
    if (option === t('dashboardComponents.Notifications.Header.tabUnread')) {
      setCurrentSortOption('Unread');
    } else {
      setCurrentSortOption('Recent');
    }
    setIsOptionsListVisible(false);
  };

  return (
    <div className={classes.Header}>
      <div className={classes.container}>
        <header className={classes.header}>
          <h3>{t('dashboardComponents.Notifications.Header.headerTitle')}</h3>
          <button type="button" onClick={readNotifications}>
            {t('dashboardComponents.Notifications.Header.markAllAsRead')}
          </button>
        </header>
        <div className={classes.options}>
          <ul className={classes.tabs}>
            {tabs.map((tab) => (
              <Tab
                key={tab.title}
                title={tab.title}
                type={tab.type}
                count={tab.count}
                isActive={tab.type === activeTab}
                setActiveTab={setActiveTab}
              />
            ))}
          </ul>
          <div className={classes.dropdown} ref={dropdownRef}>
            <Button
              onClick={() => setIsOptionsListVisible((prevState) => !prevState)}
              width={40}
              height={40}
              variant="secondary"
              style={{ border: '1px solid #C1C5D6' }}
            >
              <img src={sortIcon} alt="Sort" />
            </Button>
            {isOptionsListVisible && (
              <DropdownOptions
                changeOption={changeSortOption}
                options={[
                  t('dashboardComponents.Notifications.Header.tabUnread'),
                  t('dashboardComponents.Notifications.Header.tabRecent'),
                ]}
                currentOption={currentSortOption}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
