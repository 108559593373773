import React from 'react';

import { useTranslation } from 'react-i18next';

import Rating from './Rating';
import Recommendation from './Recommendation';

import classes from './styles.module.scss';

export default function Feedback({ rating, isRecommended }) {
  const { t } = useTranslation();

  return (
    <div className={classes.Feedback}>
      <div className={classes.rating}>
        <h6>
          {t(
            'dashboardComponents.Chat.RightPanel.ChatWindow.MessagesList.Message.Feedback.Rating'
          )}
        </h6>
        <Rating rating={rating} />
      </div>
      <div className={classes.recommend}>
        <h6>
          {t(
            'dashboardComponents.Chat.RightPanel.ChatWindow.MessagesList.Message.Feedback.Will you recommend KFC?'
          )}
        </h6>
        <Recommendation isRecommended={isRecommended} />
      </div>
    </div>
  );
}
