import React from 'react';

import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import AuthLayout from '../../containers/AuthLayout';
import classes from './styles.module.scss';

export default function RequestAccessSuccessPage() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <AuthLayout>
      <div className={classes.RequestAccessSuccessPage}>
        <Helmet>
          <title>{t('pages.RequestAccessSuccessPage.pageTitle')}</title>
        </Helmet>
        <div className={classes.container}>
          <h1>{t('pages.RequestAccessSuccessPage.requestSentTitle')}</h1>
          <p>
            {t('pages.RequestAccessSuccessPage.requestSentText1')} <br />
            <br /> <br />
            {t('pages.RequestAccessSuccessPage.requestSentText2')}
          </p>
          <Button width={400} onClick={() => navigate('/')} height={55}>
            {t('pages.RequestAccessSuccessPage.closeButton')}
          </Button>
        </div>
      </div>
    </AuthLayout>
  );
}
