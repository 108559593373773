/* eslint-disable react/no-danger */
import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Recipient from '../../Appreciation/Recipient';

export default function NewHire({ classes, feedPost }) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const jobApplication = feedPost?.jobApplication;
  const user = {
    userProfile: {
      firstName: jobApplication?.recruiter?.firstName,
      lastName: jobApplication?.recruiter?.lastName,
      avatar: jobApplication?.recruiter?.avatar,
    },
  };

  return (
    <div className={classNames(classes.container, classes.newHire)}>
      <div className={classes.content}>
        <div className={classes.text}>
          <span className={classes.bold}>
            {jobApplication?.jobSeeker?.fullName}
          </span>{' '}
          {t(
            'dashboardComponents.Discover.Feed.Post.Announcement.NewHire.joinedTheTeamAs'
          )}{' '}
          {jobApplication?.jobTitle}.{' '}
          {t(
            'dashboardComponents.Discover.Feed.Post.Announcement.NewHire.letsCongratulate'
          )}{' '}
          <span className={classes.bold}>
            {user?.userProfile?.firstName} {user?.userProfile?.lastName}
          </span>{' '}
          {t(
            'dashboardComponents.Discover.Feed.Post.Announcement.NewHire.forAJobWellDone'
          )}
          .
        </div>
        <div className={classes.recipientContainer}>
          <Recipient user={user} />
          {isTabletOrMobile && <div className={classes.background} />}
        </div>
      </div>
      {!isTabletOrMobile && <div className={classes.background} />}
    </div>
  );
}
