import React, { useState, useRef, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Tooltip from '../../../../../Tooltip';
import UsersPopup from '../UsersPopup';

import classes from './styles.module.scss';
import fileIcon from '../../../../../../assets/images/dashboard/discover/textEditor/file.svg';
import fileActiveIcon from '../../../../../../assets/images/dashboard/discover/textEditor/fileActive.svg';
import fileBlackIcon from '../../../../../../assets/images/dashboard/discover/textEditor/fileBlack.svg';
import mentionIcon from '../../../../../../assets/images/dashboard/discover/textEditor/mention.svg';
import mentionActiveIcon from '../../../../../../assets/images/dashboard/discover/textEditor/mentionActive.svg';
import quoteIcon from '../../../../../../assets/images/dashboard/discover/textEditor/quote.svg';
import quoteActiveIcon from '../../../../../../assets/images/dashboard/discover/textEditor/quoteActive.svg';
import tagIcon from '../../../../../../assets/images/dashboard/discover/textEditor/tag.svg';
import tagActiveIcon from '../../../../../../assets/images/dashboard/discover/textEditor/tagActive.svg';
// import visualIcon from '../../../../../../assets/images/dashboard/discover/textEditor/visual.svg';
import titleIcon from '../../../../../../assets/images/dashboard/discover/textEditor/title.svg';

export default function Toolbar({
  messageLength,
  setIsTitleVisible,
  maxLength,
  mentionUser,
  addressees,
  setAddressees,
  // setIsQuoteInputVisible,
  setIsAddingTag,
  addTagButtonRef,
  files,
  setIsFileUploaderVisible,
  addQuote,
}) {
  const [isUsersPopupVisible, setIsUsersPopupVisible] = useState(false);
  const [mentionButtonCoords, setMentionButtonCoords] = useState({
    x: '',
    y: '',
  });

  const mentionButtonRef = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    const mentionButtonRect = mentionButtonRef.current.getBoundingClientRect();
    setMentionButtonCoords({
      x: mentionButtonRect.x,
      y: mentionButtonRect.y - 281 - 14,
    });
  }, []);

  const hidePopup = useCallback(() => {
    setIsUsersPopupVisible(false);
  }, []);

  return (
    <>
      <div className={classes.Toolbar}>
        <div className={classes.leftTools}>
          <button
            type="button"
            className={classes.leftButton}
            onClick={() => setIsFileUploaderVisible(true)}
          >
            <img
              className={classes.default}
              src={files?.length ? fileBlackIcon : fileIcon}
              alt="file"
            />
            <img
              className={classes.active}
              src={files?.length ? fileBlackIcon : fileActiveIcon}
              alt="file"
            />
            <span
              style={{
                color: files?.length ? '#242833' : '',
              }}
            >
              {t(
                'dashboardComponents.Discover.AddContent.TextEditor.Toolbar.fileButton'
              )}
            </span>{' '}
            {files?.length ? (
              <span className={classes.fileCount}>{files.length}</span>
            ) : null}
          </button>
          <button
            type="button"
            className={classes.leftButton}
            onClick={() => setIsUsersPopupVisible(true)}
            ref={mentionButtonRef}
          >
            <img className={classes.default} src={mentionIcon} alt="Mention" />
            <img
              className={classes.active}
              src={mentionActiveIcon}
              alt="Mention"
            />
            {t(
              'dashboardComponents.Discover.AddContent.TextEditor.Toolbar.mentionButton'
            )}
          </button>
          <button
            type="button"
            className={classes.leftButton}
            onClick={addQuote}
          >
            <img className={classes.default} src={quoteIcon} alt="Quote" />
            <img className={classes.active} src={quoteActiveIcon} alt="Quote" />
            {t(
              'dashboardComponents.Discover.AddContent.TextEditor.Toolbar.quoteButton'
            )}
          </button>
          <button
            ref={addTagButtonRef}
            type="button"
            className={classes.leftButton}
            onClick={() => setIsAddingTag((prevState) => !prevState)}
          >
            <img className={classes.default} src={tagIcon} alt="Tag" />
            <img className={classes.active} src={tagActiveIcon} alt="Tag" />
            {t(
              'dashboardComponents.Discover.AddContent.TextEditor.Toolbar.addTagButton'
            )}
          </button>
        </div>
        <div className={classes.rightTools}>
          <div
            className={classNames(classes.charsLeft, {
              [classes.red]: messageLength >= maxLength,
            })}
          >
            {maxLength - messageLength}{' '}
            {t(
              'dashboardComponents.Discover.AddContent.TextEditor.Toolbar.charsLeft'
            )}
          </div>
          <Tooltip
            text={t(
              'dashboardComponents.Discover.AddContent.TextEditor.Toolbar.titleButton'
            )}
            direction="top-left"
            noWrap
          >
            <button
              type="button"
              className={classes.rightButton}
              onClick={() => setIsTitleVisible((prevState) => !prevState)}
            >
              <img src={titleIcon} alt="Title" />
            </button>
          </Tooltip>
        </div>
      </div>
      <UsersPopup
        onUserAdd={mentionUser}
        show={isUsersPopupVisible}
        addressees={addressees}
        setAddressees={setAddressees}
        onHide={hidePopup}
        top={mentionButtonCoords.y}
        left={mentionButtonCoords.x}
      />
    </>
  );
}
