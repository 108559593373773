import React from 'react';

import classes from './styles.module.scss';

export default function Button({ children, disabled, onClick }) {
  return (
    <button
      type="button"
      className={classes.Button}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
