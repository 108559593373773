import React from 'react';

import { useTranslation } from 'react-i18next';

import EditContent from './EditContent';
import ViewContent from './ViewContent';
import AdminService from '../../../../../services/AdminService';
import TableView from '../../TableView';

export default function PicVideo({ setItemCount }) {
  const { t } = useTranslation();

  const columns = [
    {
      name: t(
        'dashboardComponents.Console.ContentManagement.PicVideo.PageReference'
      ),
      field: 'location',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.ContentManagement.PicVideo.PictureVideo'
      ),
      field: 'url',
      sortable: false,
      type: 'picVideo',
    },
    {
      name: t(
        'dashboardComponents.Console.ContentManagement.PicVideo.Instructions'
      ),
      field: 'instructions',
      sortable: false,
    },
    {
      name: t(
        'dashboardComponents.Console.ContentManagement.PicVideo.UpdatedBy'
      ),
      field: 'reason',
      sortable: false,
    },
    {
      name: t('common.createdOn'),
      field: 'createdAt',
      sortable: true,
      type: 'date',
    },
  ];

  return (
    <TableView
      setItemCount={setItemCount}
      dataName="PicVideo"
      getDataHandler={AdminService.getContentMedia}
      columns={columns}
      viewComponent={ViewContent}
      addOrEditComponent={EditContent}
      availableActions={['Edit']}
      cellHeight={148}
    />
  );
}
