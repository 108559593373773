function tableColsToSortOptions(cols) {
  const sortOptions = [];

  cols.forEach((col) => {
    if (col.sortable) {
      if (col.type === 'hiringPipeline') {
        sortOptions.push({
          label: col.name,
          value: `${col.field}.new`,
        });
      } else {
        sortOptions.push({
          label: col.name,
          value: col.sortField || col.field,
        });
      }
    }
  });

  return sortOptions;
}

export default tableColsToSortOptions;
