import { create } from 'zustand';
import { persist } from 'zustand/middleware';

/* function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
 */

let isEnabled = true;

export const useUserStore = create((set) => ({
  userScopes: [],
  firstAvailableLink: null,

  setUserScopes: (scopes) => set({ userScopes: scopes }),
  setFirstAvailableLink: (link) => set({ firstAvailableLink: link }),
}));

export const useFeedStore = create((set) => ({
  sharedClasses: '',
  editingPostRef: null,
  lastTextBoxRange: null,
  quoteContainers: [],

  setEditingPostRef: (ref) => set({ editingPostRef: ref }),
  setLastTextBoxRange: (range) => set({ lastTextBoxRange: range }),
  setSharedClasses: (classes) => set({ sharedClasses: classes }),
  setQuoteContainers: (containers) => set({ quoteContainers: containers }),
  addQuoteContainer: (container) =>
    set((state) => ({
      quoteContainers: [...state.quoteContainers, container],
    })),
}));

export const usePersistedFeedStore = create(
  persist(
    (set) => ({
      lastVisibleFeedPostId: null,

      setLastVisibleFeedPostId: (id) => set({ lastVisibleFeedPostId: id }),
    }),
    {
      name: 'persistedFeedStorage',
    }
  )
);

export const useMapStore = create((set) => ({
  lastLocation: null,
  selectedMarker: '',
  isSidebarVisible: false,
  isDarkMode: false,
  zoom: 10,
  isOnlyFavoritesMode: false,

  setLastLocation: (location) => set({ lastLocation: location }),
  setInitialLocation: (location) => set({ initialLocation: location }),
  // eslint-disable-next-line no-shadow
  setIsZoomEnabled: (isEnabled) => set({ isZoomEnabled: isEnabled }),
  setCurrentPosition: (position) => set({ currentPosition: position }),
  setSelectedMarker: (marker) => set({ selectedMarker: marker }),
  setIsSidebarVisible: (isVisible) => set({ isSidebarVisible: isVisible }),
  setIsDarkMode: (isDarkMode) => set({ isDarkMode }),
  setZoom: (zoom) => set({ zoom }),

  increaseZoom: () =>
    set((state) => {
      if (state.zoom < 21) {
        return { zoom: state.zoom + 1 };
      }
      return { zoom: 21 };
    }),

  decreaseZoom: () =>
    set((state) => {
      if (state.zoom > 1) {
        return { zoom: state.zoom - 1 };
      }
      return { zoom: 1 };
    }),

  setIsOnlyFavoritesMode: (isOnlyFavoritesMode) => set({ isOnlyFavoritesMode }),

  toggleDarkMode: () => set((state) => ({ isDarkMode: !state.isDarkMode })),
  toggleOnlyFavoritesMode: () =>
    set((state) => ({ isOnlyFavoritesMode: !state.isOnlyFavoritesMode })),

  resetMapStore: () =>
    set({
      isZoomEnabled: true,
      lastLocation: null,
      selectedMarker: '',
      isSidebarVisible: false,
      isDarkMode: false,
      zoom: 10,
      isOnlyFavoritesMode: false,
    }),
}));

export const useUiStore = create((set) => ({
  isMobileSidebarVisible: false,
  isAddCandidatePopupVisible: false,

  openMobileSidebar: () => {
    return set({ isMobileSidebarVisible: true });
  },
  // Debounce is required because mobile drag and drop polyfill (https://bernardo-castilho.github.io/DragDropTouch/DragDropTouch.js) causes double click on sidebar activation
  toggleMobileSidebar: () => {
    return set((state) => {
      if (isEnabled) {
        isEnabled = false;
        setTimeout(() => {
          isEnabled = true;
        }, 500);
      } else {
        return {
          isMobileSidebarVisible: state.isMobileSidebarVisible,
        };
      }

      return {
        isMobileSidebarVisible: !state.isMobileSidebarVisible,
      };
    });
  },
  toggleAddCndidatePopup: () =>
    set((state) => ({
      isAddCandidatePopupVisible: !state.isAddCandidatePopupVisible,
    })),
}));
