import axios from 'axios';

import { JOBS_API_URL, CMS_API_URL } from '../constants/main';
import { getData } from '../helpers/requestData';

const formatFeedPost = (feedPost) => {
  const formattedPost = {
    ...feedPost,
    tags: feedPost?.tags?.map((tag) => tag.value) || [],
    recipientIds: feedPost.recipients.map((recipient) => recipient.id),
    attachments: feedPost.attachments || [],
  };

  return formattedPost;
};

const FeedService = {
  createFeedPost: async ({
    type,
    status,
    title,
    content,
    isPinned,
    recipientId,
    tags, // array of strings
    attachments,
    recipientIds,
    pollIds,
    mentionIds,
  }) => {
    const url = `${JOBS_API_URL}/feedposts`;
    const response = await axios.post(url, {
      type,
      status,
      title,
      content,
      isPinned,
      recipientId,
      tags, // array of strings
      attachments,
      recipientIds,
      appreciatedIds: recipientIds,
      pollIds,
      mentionIds,
    });

    return response.data;
  },

  updateFeedPost: async ({
    feedPostId,
    type,
    status,
    title,
    content,
    isPinned,
    recipientId,
    tags, // array of strings
    attachments,
    recipientIds,
    pollIds,
  }) => {
    const url = `${JOBS_API_URL}/feedposts/${feedPostId}`;
    const response = await axios.put(url, {
      type,
      status,
      title,
      content,
      isPinned,
      recipientId,
      tags, // array of strings
      attachments,
      recipientIds,
      pollIds,
    });

    return response.data;
  },

  toggleFeedPostPin: async (feedPost) => {
    const updatedPost = formatFeedPost(feedPost);

    const url = `${JOBS_API_URL}/feedposts/${feedPost.id}/`;
    const response = await axios.put(url, {
      ...updatedPost,
      isPinned: !updatedPost.isPinned,
    });

    return response.data;
  },

  hideFeedPost: async (feedPostId) => {
    const response = await axios.post(
      `${JOBS_API_URL}/feedposts/${feedPostId}/hides`
    );

    return response.data;
  },

  unhideFeedPost: async (feedPostId) => {
    const response = await axios.delete(
      `${JOBS_API_URL}/feedposts/${feedPostId}/hides`
    );

    return response.data;
  },

  toggleFeedPostVisibility: async (feedPost) => {
    const updatedPost = formatFeedPost(feedPost);

    const url = `${JOBS_API_URL}/feedposts/${feedPost.id}/`;
    const response = await axios.put(url, {
      ...updatedPost,
    });

    return response.data;
  },

  deleteFeedPost: async (feedPostId) => {
    const response = await axios.delete(
      `${JOBS_API_URL}/feedposts/${feedPostId}`
    );

    return response.data;
  },

  getFeedPost: async ({ id }) => {
    const url = `${JOBS_API_URL}/feedposts/${id}`;
    const response = await axios.get(url);

    return response.data;
  },

  getFeedPosts: async ({
    term,
    pageNumber,
    pageSize,
    orderBy,
    sortOrder,
    Term,
    Type, // Available values : Message, Announcement, Appreciation, Poll
    UserFilter, // Available values : MyContent, Draft, Hidden
  }) => {
    const url = `${JOBS_API_URL}/feedposts`;
    return getData({
      term,
      pageNumber,
      pageSize,
      orderBy,
      sortOrder,
      url,
      Term,
      Type,
      UserFilter,
    });
  },

  getPinnedFeedPosts: async () => {
    const response = await axios.get(`${JOBS_API_URL}/feedposts/pins`);

    return response.data;
  },

  uploadFile: async (file, progressHandler) => {
    const formData = new FormData();

    let fileFieldName = 'File';

    let url = `${CMS_API_URL}/uploads/files`;

    if (file.type.includes('image')) {
      url = `${CMS_API_URL}/uploads/images`;
      fileFieldName = 'Image';
      formData.append('Type', 'feed');
    } else if (file.type.includes('video')) {
      url = `${CMS_API_URL}/uploads/videos`;
    }

    formData.append(fileFieldName, file);

    const response = await axios.post(url, formData, {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        progressHandler(percent);
      },
    });
    return response.data;
  },

  addCommentToFeedPost: async ({
    id,
    parentId,
    content,
    attachments,
    mentionIds,
  }) => {
    const url = `${JOBS_API_URL}/feedposts/${id}/comments`;
    const response = await axios.post(url, {
      parentId,
      content,
      attachments,
      mentionIds,
    });

    return response.data;
  },

  updateCommentFeedPost: async ({
    id,
    commentId,
    parentId,
    content,
    attachments,
    mentionIds,
  }) => {
    const url = `${JOBS_API_URL}/feedposts/${id}/comments/${commentId}`;
    const response = await axios.put(url, {
      parentId,
      content,
      attachments,
      mentionIds,
    });
    return response.data;
  },

  deleteCommentFromFeedPost: async ({ id, commentId }) => {
    const url = `${JOBS_API_URL}/feedposts/${id}/comments/${commentId}`;
    const response = await axios.delete(url);

    return response.data;
  },

  addReaction: async ({ id, type }) => {
    const url = `${JOBS_API_URL}/feedposts/${id}/reactions`;
    const response = await axios.post(url, { type });

    return response.data;
  },

  deleteReaction: async ({ id, reactionId }) => {
    const url = `${JOBS_API_URL}/feedposts/${id}/reactions/${reactionId}`;
    const response = await axios.delete(url);

    return response.data;
  },

  addCommentReaction: async ({ id, commentId, type }) => {
    const url = `${JOBS_API_URL}/feedposts/${id}/comments/${commentId}/reactions`;
    const response = await axios.post(url, { type });

    return response.data;
  },

  deleteCommentReaction: async ({ id, commentId, reactionId }) => {
    const url = `${JOBS_API_URL}/feedposts/${id}/comments/${commentId}/reactions/${reactionId}`;
    const response = await axios.delete(url);

    return response.data;
  },

  createPoll: async ({ feedPostId, questions }) => {
    const url = `${JOBS_API_URL}/feedposts/${feedPostId}/polls`;
    const response = await axios.post(url, { questions });

    return response.data;
  },

  vote: async ({ pollId, feedPostId, questions }) => {
    const url = `${JOBS_API_URL}/feedposts/${feedPostId}/polls/${pollId}/votes`;
    const response = await axios.post(url, { questions });

    return response.data;
  },

  startPoll: async ({ pollId, feedPostId }) => {
    const url = `${JOBS_API_URL}/feedposts/${feedPostId}/polls/${pollId}/start`;
    const response = await axios.post(url);

    return response.data;
  },

  deletePoll: async ({ pollId, feedPostId }) => {
    const url = `${JOBS_API_URL}/feedposts/${feedPostId}/polls/${pollId}`;
    const response = await axios.delete(url);

    return response.data;
  },

  stopPoll: async ({ pollId, feedPostId }) => {
    const url = `${JOBS_API_URL}/feedposts/${feedPostId}/polls/${pollId}/stop`;
    const response = await axios.post(url);

    return response.data;
  },

  resumePoll: async ({ pollId, feedPostId }) => {
    const url = `${JOBS_API_URL}/feedposts/${feedPostId}/polls/${pollId}/extend`;
    const response = await axios.post(url);

    return response.data;
  },

  getFeedPostRecipients: async ({ term }) => {
    const url = `${JOBS_API_URL}/feedpostrecipients`;
    const response = await axios.get(url, {
      params: {
        term,
      },
    });

    return response.data;
  },

  async downloadPollResults(pollId) {
    const url = `${JOBS_API_URL}/exports/polls/${pollId}`;
    const response = await axios.get(url, {
      responseType: 'blob',
    });

    return response.data;
  },
};

export default FeedService;
