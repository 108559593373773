import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';

import Button from '../../components/Button';
import MultiDropdown from '../../components/Form/MultiDropdown';
import FavStatus from '../../components/Dashboard/Header/Search/FavStatus';
import instagramIcon from '../../assets/images/dashboard/instagram.svg';
import AdminService from '../../services/AdminService';
import classes from './styles.module.scss';
import SelectedUsers from '../../components/Dashboard/SelectedUsers';
import Badges from './Badges';
import QRCodeGenerator from '../../components/Dashboard/QRCodeGenerator';
import { UiContext } from '../../context/UiContext';
import Tooltip from '../../components/Tooltip';
import { JOBS_PORTAL_URL } from '../../constants/main';

export default function PostJobSuccessModal({
  show,
  handleClose,
  createdJobPost,
}) {
  const [invitedUsers, setInvitedUsers] = useState('');

  const { showNotification } = useContext(UiContext);

  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.PostJobSuccessModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header className={classes.header}>
          <h1>{t('modals.PostJobSuccessModal.modalTitle')}</h1>
          <span className={classes.live}>
            {t('modals.PostJobSuccessModal.liveMessage')}
          </span>
          <i className={classes.closeIcon} onClick={handleClose}>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.91444 5.02566L9.91964 1.02045C10.2125 0.727558 10.6874 0.727558 10.9803 1.02045C11.2732 1.31334 11.2732 1.78822 10.9803 2.08111L6.9751 6.08632L10.9803 10.0915C11.2732 10.3844 11.2732 10.8593 10.9803 11.1522C10.6874 11.4451 10.2125 11.4451 9.91964 11.1522L5.91444 7.14698L1.90924 11.1522C1.61634 11.4451 1.14147 11.4451 0.848576 11.1522C0.555683 10.8593 0.555683 10.3844 0.848576 10.0915L4.85378 6.08632L0.848576 2.08111C0.555683 1.78822 0.555683 1.31334 0.848576 1.02045C1.14147 0.727558 1.61634 0.727558 1.90924 1.02045L5.91444 5.02566Z"
                fill="#242833"
              />
            </svg>
          </i>
        </header>
        <div className={classes.jobDetails}>
          <div className={classes.col}>
            <h2>
              {createdJobPost?.jobTitle}{' '}
              <FavStatus job={createdJobPost} noRefetch big />
            </h2>
            <span className={classes.location}>
              {createdJobPost?.locationCity?.name},{' '}
              {createdJobPost?.locationCityDistrict?.name},{' '}
              {createdJobPost?.locationCity?.country}
            </span>
          </div>
          <div className={classes.col}>
            <h3> {t('modals.PostJobSuccessModal.jobDetails.jobId')}</h3>
            <span className={classes.jobId}>{createdJobPost?.id}</span>
          </div>
        </div>
        <div className={classes.settings}>
          <div className={classes.col}>
            <Badges job={createdJobPost} />
            <div className={classes.share}>
              <div className={classes.selectContainer}>
                <MultiDropdown
                  width={375}
                  label={t('modals.PostJobSuccessModal.settings.shareWithTeam')}
                  fetchOptions={{
                    dataName: 'userOptionPostJobSuccess',
                    getDataHandler: AdminService.getUsers,
                    labelName: 'userName',
                    isUserList: true,
                  }}
                  value={invitedUsers}
                  setFieldValue={(_name, option) => setInvitedUsers(option)}
                  placeholder={t(
                    'modals.PostJobSuccessModal.settings.placeholder'
                  )}
                />
                <Button disabled={!invitedUsers} width={115} height={50}>
                  {t('modals.PostJobSuccessModal.settings.shareButton')}
                </Button>
              </div>
              <SelectedUsers
                invitedUsers={invitedUsers}
                setInvitedUsers={setInvitedUsers}
              />
            </div>
          </div>
          <div className={classes.col}>
            <div className={classes.qrContainer}>
              <div
                className={classes.qrInnerContainer}
                onClick={() => {
                  window.location.href = `mailto:?subject=&body=${`${JOBS_PORTAL_URL}/jobs/${createdJobPost?.id}`}`;
                }}
              >
                <div className={classes.qr}>
                  <QRCodeGenerator
                    value={`${JOBS_PORTAL_URL}/jobs/${createdJobPost?.id}`}
                  />
                </div>
                <h4>{createdJobPost?.jobTitle}</h4>
              </div>
              <span className={classes.tip}>
                {t('modals.PostJobSuccessModal.qrCodeTip')}
              </span>
            </div>
          </div>
        </div>
        <footer className={classes.footer}>
          <div className={classes.socialButtonsContainer}>
            <h4>{t('modals.PostJobSuccessModal.shareOnSocial')}</h4>
            <div className={classes.buttons}>
              <LinkedinShareButton
                title={createdJobPost?.jobTitle}
                url={`${JOBS_PORTAL_URL}/jobs/${createdJobPost?.id}`}
              >
                <i>
                  <svg
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 16.5C0 7.38869 7.38869 0 16.5 0C25.6113 0 33 7.38869 33 16.5C33 25.6113 25.6113 33 16.5 33C7.38869 33 0 25.6113 0 16.5ZM7.68679 24.9434H11.7053V12.8536H7.68679V24.9434ZM9.69617 11.2028H9.66998C8.3215 11.2028 7.44937 10.2745 7.44937 9.11433C7.44937 7.92799 8.34819 7.02539 9.72285 7.02539C11.0975 7.02539 11.9435 7.92799 11.9697 9.11433C11.9697 10.2745 11.0975 11.2028 9.69617 11.2028ZM22.1772 24.9434H26.1952V18.0111C26.1952 14.2978 24.2127 12.5699 21.5689 12.5699C19.4369 12.5699 18.4814 13.7416 17.9474 14.5654V12.8536H13.9292C13.9818 13.9878 13.9292 24.9434 13.9292 24.9434H17.9474V18.1919C17.9474 17.8306 17.9734 17.4696 18.0796 17.2113C18.3701 16.4894 19.0313 15.7417 20.1413 15.7417C21.5953 15.7417 22.1772 16.8502 22.1772 18.4756V24.9434Z"
                      fill="#006FAC"
                    />
                  </svg>
                </i>
              </LinkedinShareButton>
              <i
                onClick={() => {
                  // Open instagram in new tab
                  window.open('https://www.instagram.com/', '_blank');
                }}
              >
                <img src={instagramIcon} alt="Instagram" />
              </i>
              <FacebookShareButton
                url={`${JOBS_PORTAL_URL}/jobs/${createdJobPost?.id}`}
                quote={createdJobPost?.jobTitle}
              >
                <i>
                  <svg
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M33 16.5C33 7.38633 25.6137 0 16.5 0C7.38633 0 0 7.38633 0 16.5C0 25.6137 7.38633 33 16.5 33C16.5967 33 16.6934 33 16.79 32.9936V20.1545H13.2451V16.023H16.79V12.9809C16.79 9.45527 18.9428 7.53457 22.0881 7.53457C23.5963 7.53457 24.8918 7.64414 25.2656 7.6957V11.3824H23.1C21.392 11.3824 21.0568 12.1945 21.0568 13.3869V16.0166H25.1496L24.6146 20.148H21.0568V32.3619C27.9533 30.3832 33 24.0346 33 16.5Z"
                      fill="#0071FE"
                    />
                  </svg>
                </i>
              </FacebookShareButton>
              <TwitterShareButton
                title={createdJobPost?.jobTitle}
                url={`${JOBS_PORTAL_URL}/jobs/${createdJobPost?.id}`}
              >
                <i>
                  <svg
                    width="34"
                    height="33"
                    viewBox="0 0 34 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 16.5C0 7.38869 7.61259 0 17 0C26.3874 0 34 7.38869 34 16.5C34 25.6113 26.3874 33 17 33C7.61259 33 0 25.6113 0 16.5ZM24.7732 13.3544C24.7732 13.1905 24.7695 13.0273 24.762 12.8649C25.5239 12.3304 26.1861 11.6632 26.7083 10.9037C26.0092 11.2053 25.2569 11.4085 24.4676 11.5001C25.2733 11.0311 25.8917 10.2893 26.1835 9.40537C25.4294 9.83917 24.5947 10.1544 23.7057 10.3246C22.9937 9.58841 21.9799 9.12792 20.8575 9.12792C18.7027 9.12792 16.9551 10.8241 16.9551 12.9148C16.9551 13.2119 16.9896 13.5009 17.0565 13.7784C13.8135 13.62 10.9378 12.1129 9.01361 9.8213C8.67847 10.381 8.48521 11.0311 8.48521 11.7249C8.48521 13.0389 9.17392 14.1988 10.2216 14.8773C9.58144 14.8582 8.98015 14.6875 8.45435 14.4038C8.45383 14.4196 8.45383 14.435 8.45383 14.4521C8.45383 16.286 9.79881 17.8175 11.5843 18.1645C11.2564 18.2513 10.9114 18.2979 10.5552 18.2979C10.3044 18.2979 10.0598 18.2737 9.8219 18.2294C10.3189 19.7342 11.7591 20.8292 13.4672 20.8599C12.1316 21.8758 10.4491 22.481 8.62088 22.481C8.30623 22.481 7.99547 22.4637 7.69016 22.4287C9.41698 23.503 11.4675 24.1299 13.6711 24.1299C20.8484 24.1296 24.7732 18.3588 24.7732 13.3544Z"
                      fill="#059FF5"
                    />
                  </svg>
                </i>
              </TwitterShareButton>
            </div>
          </div>
          <div className={classes.copyButtonContainer}>
            <Tooltip
              text={t('modals.PostJobSuccessModal.copyButton.tooltip')}
              width={151}
              direction="top"
            >
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${JOBS_PORTAL_URL}/jobs/${createdJobPost?.id}`
                  );
                  showNotification({
                    text: t(
                      'modals.PostJobSuccessModal.copyButton.copiedMessage'
                    ),
                  });
                }}
              >
                <svg
                  width="27"
                  height="26"
                  viewBox="0 0 27 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="13.5" cy="13" r="13" fill="#7A5BE9" />
                  <mask
                    id="mask0_780_13380"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="7"
                    y="5"
                    width="13"
                    height="15"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7 5.51562H20V19.0131H7V5.51562Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_780_13380)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.5825 13.3311C15.5036 14.4513 13.7784 14.5133 12.637 13.4937C12.4069 13.2911 12.3784 12.933 12.5735 12.6939C12.7687 12.4547 13.1136 12.4251 13.3438 12.6279C13.3451 12.6288 13.3463 12.6299 13.3474 12.6311C14.0658 13.2726 15.1267 13.2423 15.8119 12.5309L18.3806 9.86393C19.0989 9.11826 19.0989 7.94262 18.3806 7.19694C17.6624 6.45127 16.53 6.45127 15.812 7.19694L13.3716 9.7306C13.1618 9.95483 12.8169 9.95992 12.6011 9.74211C12.3853 9.52429 12.3804 9.16616 12.5902 8.94193C12.5936 8.93816 12.5974 8.93439 12.6011 8.93062L15.0414 6.39696C16.1731 5.22188 18.0195 5.22188 19.1512 6.39677C20.283 7.57185 20.283 9.48902 19.1512 10.6641L16.5825 13.3311ZM11.9587 18.1322C10.827 19.3073 8.98045 19.3073 7.84867 18.1324C6.71708 16.9571 6.71708 15.0402 7.84885 13.8651L10.4176 11.1981C11.4967 10.0777 13.2217 10.0158 14.3629 11.0355C14.5921 11.2396 14.6184 11.5977 14.4219 11.8357C14.2257 12.0729 13.8817 12.101 13.6525 11.8981C12.9343 11.2566 11.8732 11.2869 11.1881 11.9981L8.61935 14.6651C7.90117 15.4107 7.90117 16.5864 8.61935 17.3321C9.33754 18.0777 10.4699 18.0777 11.1881 17.3321L13.6283 14.7984C13.8385 14.5747 14.1836 14.57 14.399 14.7882C14.6144 15.0064 14.6188 15.3647 14.4086 15.5884C14.4056 15.5918 14.4023 15.5952 14.399 15.5986L11.9587 18.1322Z"
                      fill="white"
                    />
                  </g>
                </svg>
                {t('modals.PostJobSuccessModal.copyButton.copyLink')}
              </button>
            </Tooltip>
          </div>
        </footer>
      </div>
    </Modal>
  );
}
