/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';

import AdminService from '../../../../../../../../services/AdminService';
import useOnClickOutside from '../../../../../../../../hooks/useOnClickOutside';
import AssessmentDropdown from './AssessmentDropdown';
import InfoIcon from '../../../../../../../InfoIcon';

import classes from './styles.module.scss';
import SelectedAssessments from './SelectedAssessments';

function replaceNullStrings(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === 'null') {
      obj[key] = null;
    }
  });
  return obj;
}

export default function Assessments({
  assessments,
  setAssessments,
  readOnly,
  touched,
  formik,
}) {
  const [isListVisible, setIsListVisible] = useState(false);
  const [fields, setFields] = useState([]);

  const dropdownRef = useRef(null);
  const selectorRef = useRef(null);
  const isSet = useRef(false);

  const { t } = useTranslation();

  useOnClickOutside(
    dropdownRef,
    () => {
      setIsListVisible(false);
    },
    selectorRef
  );

  const { data: flowsProfiles } = useQuery({
    queryKey: ['flowsProfiles'],
    queryFn: () => AdminService.getFlowsProfiles(),
    keepPreviousData: true,
  });

  const { data: assessmentsData } = useQuery({
    queryKey: ['assessments'],
    queryFn: () => AdminService.getAssessment({ term: '', pageSize: 9999 }),
    keepPreviousData: true,
  });

  const { data: tenantAssessmentProviders } = useQuery({
    queryKey: ['tenantAssessmentProviders'],
    queryFn: () =>
      AdminService.getTenantAssessmentProviders({
        term: '',
        pageSize: 9999,
      }),
    keepPreviousData: true,
  });

  useEffect(() => {
    if (fields?.length) {
      return;
    }

    const flowQ = tenantAssessmentProviders?.data?.find(
      (provider) => provider?.assessmentProvider.key === 'flowq'
    );

    const zaphire = tenantAssessmentProviders?.data?.find(
      (provider) => provider?.assessmentProvider.key === 'zaphire'
    );

    setFields([
      {
        name: 'FlowQ',
        tests: flowsProfiles?.map((flow) => {
          const hasEnglishThreshold = flow?.content?.some((test) =>
            test.name.includes('English')
          );
          const hasMentalThreshold = flow?.content?.some((test) =>
            test.name.includes('Mental')
          );

          return {
            assessment: 'FlowQ',
            isSelected: assessments.some(
              (assessment) => assessment.name === flow.name
            ),
            isAssigned: false,
            assessmentId: flow.id,
            name: flow?.name,
            threshold:
              !hasMentalThreshold && !hasEnglishThreshold ? '80' : null,
            englishThreshold: hasEnglishThreshold ? '80' : null,
            mentalThreshold: hasMentalThreshold ? '100' : null,
            limit: 7,
            tenantAssessmentProviderId: flowQ?.id,
            assessmentProviderKey: flowQ?.assessmentProvider.key,
            content: flow?.content,
            type: 'radio',
            isSet: false,
          };
        }),
        info: flowQ?.assessmentProvider.note,
        assessmentProviderKey: flowQ?.assessmentProvider.key,
      },
      {
        name: 'Zaphire',
        tests: assessmentsData?.data?.map((assessment) => ({
          assessment: 'Zaphire',
          isSelected: assessments.some(
            (asssmnt) => asssmnt.name === assessment.name
          ),
          isAssigned: false,
          assessmentId: assessment.id,
          name: assessment?.name,
          questionCount: assessment.questionCount,
          threshold: '80',
          englishThreshold: null,
          mentalThreshold: null,
          limit: 7,
          tenantAssessmentProviderId: zaphire?.id,
          assessmentProviderKey: zaphire?.assessmentProvider.key,
          type: 'checkbox',
          isSet: false,
        })),
        info: zaphire?.assessmentProvider.note,
        assessmentProviderKey: zaphire?.assessmentProvider.key,
      },
    ]);
  }, [flowsProfiles, tenantAssessmentProviders, assessmentsData, fields]);

  // Set selected assessments from saved formik values on edit
  // Do it only once
  useEffect(() => {
    if (
      !formik?.values?.assignedAssessments ||
      !fields.length ||
      assessments.length ||
      isSet.current
    ) {
      return;
    }

    const tests = fields.map((field) => field.tests).flat();

    setAssessments(
      formik?.values?.assignedAssessments.map((assessment) => {
        const testFromFields = tests?.find(
          (test) =>
            test.assessmentId === assessment.assessmentId &&
            test.assessmentProviderKey === assessment.assessmentProviderKey
        );

        return { ...testFromFields, ...replaceNullStrings(assessment) };
      })
    );

    isSet.current = true;
  }, [formik?.values?.assignedAssessments, fields, assessments]);

  const clearSelection = () => {
    if (!fields) {
      return;
    }
    setAssessments([]);
  };

  // Move to SelectedAssessments and preserve the order
  useEffect(() => {
    // Create a set of existing assessment IDs for quick lookup
    const existingAssessmentNames = new Set(
      assessments.map((assessment) => assessment.name)
    );

    const newAssessments = [];
    fields?.forEach((field) => {
      field?.tests?.forEach((test) => {
        if (test?.isSelected && !existingAssessmentNames.has(test.name)) {
          newAssessments.push(test);
        }
      });
    });

    if (newAssessments.length > 0) {
      // Allow only one FlowQ assessment at a time
      const assessmentsToMerge =
        newAssessments?.[0]?.assessment === 'FlowQ'
          ? assessments.filter(
              (assessment) => assessment.assessment !== 'FlowQ'
            )
          : assessments;

      // Merge existing assessments with the new ones, preserving the order
      setAssessments([...assessmentsToMerge, ...newAssessments]);
    }
  }, [fields, assessments]);

  const testsAmount = assessments?.length;

  return (
    <div
      className={classNames(classes.Assessments, {
        [classes.readOnly]: readOnly,
      })}
    >
      <div className={classes.labelContainer}>
        <span className={classes.label}>
          {t(
            'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.Assessments.AssessmentModuleLabel'
          )}
        </span>
        <InfoIcon
          info="Some info"
          infoWidth={140}
          tooltipDirection="top-right"
        />
        <span
          className={classNames(classes.amount, {
            [classes.green]: testsAmount > 0,
          })}
        >
          {testsAmount || 0}
        </span>
      </div>

      <div
        className={classes.selector}
        onClick={() => setIsListVisible(!isListVisible)}
        ref={selectorRef}
      >
        {t(
          'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.Assessments.PickAssessmentModulePlaceholder'
        )}
        <span
          className={classNames(classes.arrow, {
            [classes.arrowUp]: isListVisible,
          })}
          onClick={(event) => {
            event.stopPropagation();
            setIsListVisible(!isListVisible);
          }}
        />
        {isListVisible && (
          <AssessmentDropdown
            assignedAssessments={assessments}
            clear={clearSelection}
            dropdownRef={dropdownRef}
            assessments={assessments}
            fields={fields}
            setFields={setFields}
          />
        )}
      </div>
      {assessments?.length > 0 ? (
        <SelectedAssessments
          fields={fields}
          setFields={setFields}
          assessments={assessments}
          setAssessments={setAssessments}
          touched={touched}
        />
      ) : null}
    </div>
  );
}
