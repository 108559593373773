import React, { useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import CandidateCard from './CandidateCard';

import { moveCandidateToPreviousStage } from '../../../../../../../helpers/candidates';
import AdminService from '../../../../../../../services/AdminService';
import { JobsContext } from '../../../../../../../context/JobsContext';
import { UiContext } from '../../../../../../../context/UiContext';
import classes from './styles.module.scss';

const stages = ['New', 'Screen', 'Interview', 'Offer', 'Onboard'];

export default function CandidatesCol({
  candidates,
  refetch,
  title,
  showCandidatePopup,
  jobStatus,
  hasAssessment,
  candidatesFilter,
}) {
  const [areAllCandidatesVisible, setAreCandidatesVisible] = useState(false);

  const { showModal, showNotification, setIsFetching } = useContext(UiContext);
  const { showChangeStageWithExceptionModal } = useContext(JobsContext);

  const { t } = useTranslation();

  const visibleCandidates = areAllCandidatesVisible
    ? candidates
    : candidates?.slice(0, 2);

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const changeJobApplicationStatus = async (jobApplicationId, status, undo) => {
    try {
      setIsFetching(true);

      await AdminService.changeMyJobApplicationStatus({
        jobApplicationId,
        status: status.status,
        subStatus: status.subStatus,
      });
      await refetch();

      showNotification({
        text: `Candidate successfully moved to ${t(
          `dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.${status.status}`
        )}`,
        undo,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const showJobApplicationChangeStatusModal = (event) => {
    // Change status of candidate should be disabled if job is unpublished or candidate is disqualified
    if (
      jobStatus === 'UnPublished' ||
      candidatesFilter !==
        t('dashboardComponents.Jobs.MyJobs.JobDetails.qualified')
    ) {
      return;
    }

    const jobApplicationId = event.dataTransfer.getData('jobApplicationId');
    const prevStatus = JSON.parse(event.dataTransfer.getData('prevStatus'));
    const statuses = {
      [t('dashboardComponents.Jobs.MyJobs.JobDetails.GridView.New')]: {
        status: 'New',
        subStatus: 'Pending',
      },
      [t('dashboardComponents.Jobs.MyJobs.JobDetails.GridView.Screen')]: {
        status: 'Screen',
        subStatus: 'Requested',
      },
      [t('dashboardComponents.Jobs.MyJobs.JobDetails.GridView.Interview')]: {
        status: 'Interview',
        subStatus: 'Pending',
      },
      [t('dashboardComponents.Jobs.MyJobs.JobDetails.GridView.Offer')]: {
        status: 'Offer',
        subStatus: 'Pending',
      },
      [t('dashboardComponents.Jobs.MyJobs.JobDetails.GridView.Onboard')]: {
        status: 'Onboard',
        subStatus: 'Pending',
      },
    };
    const status = statuses[title];

    if (!status || status.status === prevStatus.status) {
      return;
    }

    if (prevStatus.status === 'Invited' && prevStatus.subStatus === 'Pending') {
      return;
    }

    const currentStageIndex = stages.findIndex(
      (stage) => stage === prevStatus.status
    );
    const newStageIndex = stages.findIndex((stage) => stage === title);

    if (
      (newStageIndex - currentStageIndex > 1 ||
        newStageIndex - currentStageIndex < 0) &&
      !(prevStatus.status === 'New' && title === 'Interview' && !hasAssessment)
    ) {
      showModal({
        title: t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.actionNotAllowed'
        ),
        text: t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.cannotSkipLevel'
        ),
        dismissButtonVariant: 'modal',
        dismissButtonLabel: t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.ok'
        ),
      });
      return;
    }

    if (status.status === 'Screen' && !hasAssessment) {
      return;
    }

    if (status.status === 'Offer') {
      let candidate;
      try {
        candidate = JSON.parse(event.dataTransfer.getData('candidate'));
        // eslint-disable-next-line no-empty
      } catch (error) {}

      if (candidate && prevStatus?.subStatus === 'Passed') {
        changeJobApplicationStatus(
          candidate.id,
          {
            status: 'Offer',
            subStatus: 'Pending',
          },
          () =>
            moveCandidateToPreviousStage(
              candidate?.status,
              candidate?.subStatus,
              candidate,
              refetch,
              setIsFetching
            )
        );
      } else if (candidate) {
        showChangeStageWithExceptionModal({
          candidate,
          refetch,
          stage: 'Offer',
        });
      }

      return;
    }

    if (status.status === 'Interview' && hasAssessment) {
      let candidate;
      try {
        candidate = JSON.parse(event.dataTransfer.getData('candidate'));
        // eslint-disable-next-line no-empty
      } catch (error) {}

      if (candidate && candidate.subStatus !== 'Passed') {
        showChangeStageWithExceptionModal({
          candidate,
          refetch,
          stage: 'Interview',
        });
      }

      return;
    }

    if (status.status === 'Onboard') {
      let candidate;
      try {
        candidate = JSON.parse(event.dataTransfer.getData('candidate'));
        // eslint-disable-next-line no-empty
      } catch (error) {}

      if (candidate && candidate.subStatus !== 'Accepted') {
        showChangeStageWithExceptionModal({
          candidate,
          refetch,
          stage: 'Onboard',
        });
      }

      return;
    }

    const undo = async () => {
      try {
        setIsFetching(true);

        await AdminService.changeMyJobApplicationStatus({
          status: prevStatus.status,
          subStatus: prevStatus.subStatus,
          jobApplicationId,
        });
        await refetch();
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    };

    showModal({
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.confirmation'
      ),
      text: `${t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.moveCandidateTo'
      )} ${title}?`,
      dismissButtonLabel: t('common.no'),
      onConfirm: () =>
        changeJobApplicationStatus(jobApplicationId, status, undo),
    });
  };

  return (
    <section
      className={classes.CandidatesCol}
      onDragOver={allowDrop}
      onDrop={showJobApplicationChangeStatusModal}
    >
      <header className={classes.header}>
        <h2>{title}</h2>
        <span className={classes.count}>{candidates?.length}</span>
      </header>
      <div className={classes.sectionContainer}>
        {visibleCandidates?.map((candidate) => {
          return (
            <CandidateCard
              showCandidatePopup={showCandidatePopup}
              key={candidate.id}
              candidate={candidate}
              refetch={refetch}
              changeJobApplicationStatus={changeJobApplicationStatus}
              jobStatus={jobStatus}
              hasAssessment={hasAssessment}
            />
          );
        })}
        {!areAllCandidatesVisible && candidates?.length > 2 && (
          <button
            className={classes.viewAllButton}
            type="button"
            onClick={() => setAreCandidatesVisible(true)}
          >
            {t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.viewAll'
            )}
          </button>
        )}
      </div>
    </section>
  );
}
