import React from 'react';
import classes from './styles.module.scss';
import DayType from './DayType';
import WeekType from './WeekType';
import MonthType from './MonthType';

export default function CalendarInfo({
  activeType,
  currentDate,
  setCurrentDate,
  setActiveType,
  dateToday,
  events,
  refetch,
  user,
}) {
  return (
    <div className={classes.CalendarInfo}>
      {activeType === 'Day' && (
        <DayType
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          dateToday={dateToday}
          events={events}
          refetch={refetch}
          user={user}
        />
      )}
      {activeType === 'Week' && (
        <WeekType
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          setActiveType={setActiveType}
          dateToday={dateToday}
          events={events}
          refetch={refetch}
          user={user}
        />
      )}
      {activeType === 'Month' && (
        <MonthType
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          setActiveType={setActiveType}
          dateToday={dateToday}
          events={events}
          refetch={refetch}
          user={user}
        />
      )}
    </div>
  );
}
