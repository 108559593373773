import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function Tabs({ currentTab, setCurrentTab }) {
  const { t } = useTranslation();

  const tabs = [
    t('dashboardComponents.Jobs.MyJobs.JobDetails.candidates'),
    t('dashboardComponents.Jobs.MyJobs.JobDetails.jobDetails'),
    t('dashboardComponents.Jobs.MyJobs.JobDetails.insights'),
    t('dashboardComponents.Jobs.MyJobs.JobDetails.settings'),
  ];

  return (
    <div className={classes.Tabs}>
      {tabs.map((tab) => (
        <div
          className={classNames(classes.tab, {
            [classes.active]: currentTab === tab,
          })}
          key={tab}
          onClick={() => setCurrentTab(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
}
