import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import FormDropdown from '../../../../../../../Form/FormDropdown';
import StyleSelector from './StyleSelector';

import classes from './styles.module.scss';

const defaultFontOptions = [
  { value: 'Gilroy-Regular', label: 'Gilroy-Regular' },
  { value: 'Gilroy-Medium', label: 'Gilroy-Medium' },
  { value: 'Gilroy-SemiBold', label: 'Gilroy-SemiBold' },
  { value: 'Gilroy-Bold', label: 'Gilroy-Bold' },
];

const createFontPreviewStyles = (value) => {
  const styles = {};

  if (value.styles.includes('Bold')) {
    styles.fontWeight = 'bold';
  }

  if (value.styles.includes('Italic')) {
    styles.fontStyle = 'italic';
  }

  if (value.styles.includes('Underline')) {
    styles.textDecoration = 'underline';
  }

  return styles;
};

export default function FontSelector({
  value,
  setValue,
  label,
  customFonts = [],
}) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const fontOptions = [
    ...defaultFontOptions,
    ...customFonts.map((font) => ({
      value: font.familyName,
      label: font.familyName,
    })),
  ];

  useEffect(() => {
    const loadFont = async (familyName, url) => {
      try {
        const newFont = new FontFace(familyName, `url(${url})`);
        await newFont.load();
        document.fonts.add(newFont);
      } catch (error) {
        console.log(error);
      }
    };

    if (
      defaultFontOptions.every((font) => font.value !== value.fontFamily?.value)
    ) {
      const customFont = customFonts.find(
        (font) => font.familyName === value.fontFamily?.value
      );

      if (customFont) {
        loadFont(value.fontFamily.value, customFont.url);
      }
    }
  }, [customFonts, value.fontFamily]);

  return (
    <div className={classes.FontSelector}>
      <div className={classes.fontOptions}>
        <FormDropdown
          label={label}
          options={fontOptions}
          placeholder={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Font.selectFont'
          )}
          width={isTabletOrMobile ? '100%' : 237}
          height={isTabletOrMobile ? 45 : 60}
          value={value.fontFamily}
          setFieldValue={(_, val) =>
            setValue((prevValue) => ({ ...prevValue, fontFamily: val }))
          }
        />
        <div className={classes.styleSelectorContainer}>
          <StyleSelector setValue={setValue} value={value} />
        </div>
      </div>
      <div
        className={classes.fontPreview}
        style={{
          fontFamily: value?.fontFamily.value,
          ...createFontPreviewStyles(value),
        }}
      >
        <span>
          {value?.fontFamily.value
            ? 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.'
            : ''}
        </span>
      </div>
    </div>
  );
}
