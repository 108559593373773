/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import SinglePost from './SinglePost';
import Feed from './Feed';
import AddContent from './AddContent';
import UsersOnline from './UsersOnline';
import ProductAnnouncements from './ProductAnnouncements';
import Ads from './Ads';
import RecentlyActivities from './RecentlyActivities';

import { useFeedStore } from '../../../store';
import classes from './styles.module.scss';

export default function Discover() {
  const [isEditorVisible, setIsEditorVisible] = useState(false);
  const [editingPost, setEditingPost] = useState(null);

  const leftColRef = useRef();

  const location = useLocation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const setSharedClasses = useFeedStore((state) => state.setSharedClasses);

  useEffect(() => {
    setSharedClasses(classes);
  }, [setSharedClasses]);

  // add script to handle drag and drop on mobile devices
  useEffect(() => {
    // Check if the script already exists
    const existingScript = document.querySelector(
      "script[src='https://bernardo-castilho.github.io/DragDropTouch/DragDropTouch.js']"
    );

    if (!existingScript) {
      // If the script doesn't exist, create and append it
      const script = document.createElement('script');
      script.src =
        'https://bernardo-castilho.github.io/DragDropTouch/DragDropTouch.js';
      script.async = true;

      document.body.appendChild(script);

      return () => {
        // Remove the script when the component is unmounted
        document.body.removeChild(script);
      };
    }
  }, []);

  const rightCol = (
    <div className={classes.rightCol}>
      <UsersOnline />
      <ProductAnnouncements />
      <Ads />
    </div>
  );

  return (
    <div className={classes.Discover}>
      {location.pathname.includes('tools/discover/posts/') ? (
        <div className={classes.leftCol}>
          <div className={classes.leftColContainer} id="dicoveryLeftCol">
            <SinglePost setEditingPost={setEditingPost} />
          </div>
        </div>
      ) : (
        <>
          {isTabletOrMobile && (
            <>
              <RecentlyActivities />
              {rightCol}
            </>
          )}
          <div
            className={classes.leftCol}
            ref={leftColRef}
            id="dicoveryLeftCol"
          >
            <div className={classes.leftColContainer}>
              {!isTabletOrMobile && <RecentlyActivities />}
              <AddContent
                setIsEditorVisible={setIsEditorVisible}
                isEditorVisible={isEditorVisible}
                sharedClasses={classes}
                editingPost={editingPost}
                setEditingPost={setEditingPost}
              />
            </div>
            <Feed setEditingPost={setEditingPost} leftColRef={leftColRef} />
          </div>
        </>
      )}
      {!isTabletOrMobile && rightCol}
    </div>
  );
}
