import React, { useContext, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { UiContext } from '../../../../../../context/UiContext';
import AdminService from '../../../../../../services/AdminService';
import classes from './styles.module.scss';

export default function Note({
  isAddingNote,
  isEditingNote,
  setIsAddingNote,
  setIsEditingNote,
  favCandidate,
  refetch,
  note,
  handleClose,
}) {
  const [currentValue, setCurrentValue] = useState('');
  const [prevValue, setPrevValue] = useState('');
  const [isViewMode, setIsViewMode] = useState(true);
  const [isTryingToSave, setIsTryingToSave] = useState(false);

  const textareaRef = useRef(null);

  const { t } = useTranslation();

  const { showModal, showUnknownErrorModal, showNotification, setIsFetching } =
    useContext(UiContext);

  const validationSubmit =
    currentValue.length === 0 ||
    currentValue.length > 250 ||
    isTryingToSave ||
    textareaRef?.current?.value === prevValue;

  useEffect(() => {
    setPrevValue(textareaRef.current.value);
    setIsViewMode(true);
  }, [isEditingNote]);

  const handleOnChange = () => {
    setCurrentValue(textareaRef?.current.value);
  };

  const handleCancel = () => {
    setIsAddingNote(false);
    handleClose();
  };

  const handleReset = () => {
    textareaRef.current.value = prevValue;
    setCurrentValue(textareaRef?.current.value);
  };

  const deleteNote = () => {
    showModal({
      icon: 'delete',
      title: t(
        'dashboardComponents.Favorites.Candidates.CandidateCard.Note.confirmDeleteNoteTitle'
      ),
      text: t(
        'dashboardComponents.Favorites.Candidates.CandidateCard.Note.confirmDeleteNoteText'
      ),
      onConfirm: async () => {
        try {
          setIsFetching(true);
          await AdminService.addNoteToCandidate({
            id: favCandidate?.id,
            note: null,
          });
          await refetch();
          showNotification({
            text: t(
              'dashboardComponents.Favorites.Candidates.CandidateCard.Note.noteDeletedSuccessfully'
            ),
          });
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
        } finally {
          setIsFetching(false);
          handleClose();
        }
      },
      onCancel: () => {},
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
    });
  };

  const addNewNote = async () => {
    if (validationSubmit) {
      return;
    }

    try {
      setIsFetching(true);
      await AdminService.addNoteToCandidate({
        id: favCandidate?.id,
        note: currentValue,
      });
      await refetch();
      showNotification({
        text: t(
          'dashboardComponents.Favorites.Candidates.CandidateCard.Note.noteAddedSuccessfully'
        ),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
      setIsAddingNote(false);
      handleClose();
    }
  };

  const saveNote = async () => {
    if (validationSubmit) {
      return;
    }

    try {
      setIsFetching(true);
      await AdminService.addNoteToCandidate({
        id: favCandidate?.id,
        note: currentValue,
      });
      await refetch();
      showNotification({
        text: t(
          'dashboardComponents.Favorites.Candidates.CandidateCard.Note.noteUpdatedSuccessfully'
        ),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
      setIsEditingNote(false);
      setIsTryingToSave(false);
      handleClose();
    }
  };

  return (
    <div className={classes.container}>
      {isAddingNote && (
        <>
          <textarea
            ref={textareaRef}
            className={classes.note}
            value={currentValue}
            placeholder={t(
              'dashboardComponents.Favorites.Candidates.CandidateCard.Note.addNotePlaceholder'
            )}
            onChange={handleOnChange}
            maxLength={250}
            onClick={(e) => e.stopPropagation()}
          />
          <span className={classes.left}>
            {250 - currentValue.length}{' '}
            {t(
              'dashboardComponents.Favorites.Candidates.CandidateCard.Note.charactersLeft'
            )}
          </span>
        </>
      )}

      {isEditingNote && (
        <>
          <textarea
            ref={textareaRef}
            className={classes.note}
            defaultValue={note}
            placeholder={t(
              'dashboardComponents.Favorites.Candidates.CandidateCard.Note.addNotePlaceholder'
            )}
            onChange={handleOnChange}
            maxLength={250}
            disabled={isViewMode}
            onClick={(e) => e.stopPropagation()}
          />
          <span className={classes.left}>
            {250 - textareaRef?.current?.value.length}{' '}
            {t(
              'dashboardComponents.Favorites.Candidates.CandidateCard.Note.charactersLeft'
            )}
          </span>
        </>
      )}

      {isAddingNote && (
        <div className={classes.buttons}>
          <div
            className={classes.button}
            onClick={(e) => {
              e.stopPropagation();
              handleCancel();
            }}
          >
            {t('common.cancel')}
          </div>
          <div
            className={classNames(classes.button, {
              [classes.disabled]: validationSubmit,
              [classes.submit]: !validationSubmit,
            })}
            onClick={(e) => {
              e.stopPropagation();
              addNewNote();
            }}
          >
            {t('common.save')}
          </div>
        </div>
      )}

      {isEditingNote &&
        (isViewMode ? (
          <div className={classes.buttons}>
            <div
              className={[`${classes.button} ${classes.delete}`]}
              onClick={(e) => {
                e.stopPropagation();
                deleteNote();
              }}
            >
              {t('common.delete')}
            </div>
            <div
              className={classNames(classes.button, classes.submit)}
              onClick={(e) => {
                e.stopPropagation();
                setIsViewMode(false);
              }}
            >
              {t('common.edit')}
            </div>
          </div>
        ) : (
          <div className={classes.buttons}>
            <div
              className={classes.button}
              onClick={(e) => {
                e.stopPropagation();
                handleReset();
              }}
            >
              {t('common.reset')}
            </div>
            <div
              className={classNames(classes.button, {
                [classes.disabled]: validationSubmit,
                [classes.submit]: !validationSubmit,
              })}
              onClick={(e) => {
                e.stopPropagation();
                setIsTryingToSave(true);
                saveNote();
              }}
            >
              {t('common.save')}
            </div>
          </div>
        ))}
    </div>
  );
}
