import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';
import interviewFeedbackIcon from '../../../../../../../../../assets/images/dashboard/jobs/inrerview-feedback.svg';

export default function InterviewRating({ feedbackRating }) {
  return (
    <div
      className={classNames(classes.InterviewRating, {
        [classes.red]: feedbackRating < 2.5,
        [classes.orange]: feedbackRating > 2 && feedbackRating < 3.5,
        [classes.green]: feedbackRating >= 3.5 && feedbackRating < 4.5,
        [classes.gradient]: feedbackRating > 4,
      })}
    >
      <img
        className={classes.ratingIcon}
        src={interviewFeedbackIcon}
        alt="Interview feedback"
      />
      <span>{feedbackRating}</span>
    </div>
  );
}
