import React, { useContext, useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Candidate from '../../../Candidate';
import CandidateCard from './CandidateCard';

import { SearchContext } from '../../../../../context/SearchContext';
import classes from './styles.module.scss';

export default function Candidates({
  searchTerm,
  candidates,
  activeTab,
  refetch,
  isCandidatePopupVisible,
  setIsCandidatePopupVisible,
  closeSearchPopup,
}) {
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const {
    setSearchTerm,
    setActiveDropDownOption,
    setIsOpenedSearchScreen,
    setActiveSearchScreenTab,
  } = useContext(SearchContext);

  const { t } = useTranslation();

  return (
    <div className={classes.candidates}>
      {activeTab === t('dashboardComponents.Header.Search.Candidates.All') && (
        <span className={classes.title}>Candidates</span>
      )}

      {candidates?.length === 0 && (
        <span className={classes.noMatch}>
          {t('dashboardComponents.Header.Search.Candidates.noMatch')}
        </span>
      )}

      {activeTab === t('dashboardComponents.Header.Search.Candidates.All') ? (
        <>
          <div className={classes.candidates}>
            {candidates?.slice(0, 2).map((candidate) => (
              <CandidateCard
                candidate={candidate}
                setIsCandidatePopupVisible={setIsCandidatePopupVisible}
                setCurrentCandidate={setCurrentCandidate}
                key={candidate?.userId}
                refetch={refetch}
              />
            ))}
          </div>
          {candidates?.length > 2 && (
            <Link
              to="/tools/search"
              className={classes.seeAll}
              onClick={() => {
                setSearchTerm(searchTerm);
                setActiveDropDownOption('All');
                setActiveSearchScreenTab('Candidates');
                setIsOpenedSearchScreen(true);
                closeSearchPopup();
              }}
            >
              {t(
                'dashboardComponents.Header.Search.Candidates.seeAllCandidates'
              )}
            </Link>
          )}
        </>
      ) : (
        <>
          <div className={classes.candidates}>
            {candidates?.slice(0, 6).map((candidate) => (
              <CandidateCard
                candidate={candidate}
                setIsCandidatePopupVisible={setIsCandidatePopupVisible}
                setCurrentCandidate={setCurrentCandidate}
                key={candidate?.userId}
                refetch={refetch}
              />
            ))}
          </div>
          {candidates?.length > 6 && (
            <Link
              to="/tools/search"
              className={classes.seeAll}
              onClick={() => {
                setSearchTerm(searchTerm);
                setActiveDropDownOption('Candidates');
                setIsOpenedSearchScreen(true);
                closeSearchPopup();
              }}
            >
              {t(
                'dashboardComponents.Header.Search.Candidates.seeAllCandidates'
              )}
            </Link>
          )}
        </>
      )}

      <Candidate
        isVisible={isCandidatePopupVisible}
        setIsVisible={setIsCandidatePopupVisible}
        refetch={refetch}
        candidate={currentCandidate}
        setCurrentCandidate={setCurrentCandidate}
        candidates={candidates}
      />
    </div>
  );
}
