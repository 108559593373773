/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import useOnClickOutside from './useOnClickOutside';

export default function useDropdown({
  currentOption,
  setCurrentOption,
  options,
  classes,
  placeholder,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const dropdownRef = useRef();
  const optionsRef = useRef();

  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  const toggleOptionsList = () => {
    setIsOptionsListVisible((prevState) => !prevState);
  };

  const selectOption = (option) => {
    setCurrentOption?.(option);
    setIsOptionsListVisible(false);
  };

  const listItems = options?.map((option) => (
    <li
      className={classNames(classes.option, {
        [classes.active]: option.value === currentOption?.value,
      })}
      onClick={() => selectOption(option)}
      key={option.value}
    >
      <span>{option.label}</span>
    </li>
  ));

  const selector = (
    <div className={classes.selector} onClick={toggleOptionsList}>
      {currentOption ? (
        <span className={classes.value}>{currentOption.label}</span>
      ) : (
        <span className={classes.placeholder}>{placeholder}</span>
      )}
    </div>
  );

  const dropdown = (
    <div
      className={classNames(classes.Dropdown, {
        [classes.open]: isOptionsListVisible,
      })}
      ref={dropdownRef}
    >
      {selector}
      {isOptionsListVisible && (
        <div className={classes.optionsContainer}>
          <ul className={classes.options} ref={optionsRef}>
            {listItems}
          </ul>
        </div>
      )}
    </div>
  );

  useEffect(() => {
    if (optionsRef.current && isOptionsListVisible) {
      dropdownRef.current.style.setProperty(
        '--optionsHeight',
        `${optionsRef.current.clientHeight}px`
      );
    }
  }, [isOptionsListVisible]);

  return dropdown;
}
