import React from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import InfoIcon from '../../../../../../components/InfoIcon';

import classes from './styles.module.scss';

export default function AssessmentInfo({ test }) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const questionCount =
    test?.content?.reduce((accum, item) => item?.questionCount + accum, 0) ||
    test?.questionCount ||
    '-';

  const questionDifficulty =
    test?.content?.sort((a, b) => b?.level - a?.level)[0]?.level || '-';

  const questionLimit =
    test?.content?.reduce((accum, item) => {
      const durationMinute = String(item?.durationMinute)?.match(/\d+/g);

      return +durationMinute + accum;
    }, 0) || '-';

  const assessmentInfo = (
    <div className={classes.AssessmentInfo}>
      <div className={classes.row}>
        <span>
          {t(
            'modals.AssessmentModal.AvailableAssessments.Assessment.Test.questions'
          )}
        </span>
        <span>{questionCount}</span>
      </div>
      <div className={classes.row}>
        <span>
          {' '}
          {t(
            'modals.AssessmentModal.AvailableAssessments.Assessment.Test.difficulty'
          )}
        </span>
        <span>{questionDifficulty}</span>
      </div>
      <div className={classes.row}>
        <span>
          {' '}
          {t(
            'modals.AssessmentModal.AvailableAssessments.Assessment.Test.duration'
          )}
        </span>
        <span>{questionLimit}</span>
      </div>
    </div>
  );

  return (
    <InfoIcon
      infoWidth={163}
      info={assessmentInfo}
      tooltipDirection={isTabletOrMobile ? 'bottom-left' : 'bottom-right'}
      fixed={!isTabletOrMobile}
    />
  );
}
