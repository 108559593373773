/* eslint-disable react/no-danger */
import React from 'react';

import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import MembersPopup from '../../../../MembersPopup';

export default function Custom({
  classes,
  feedPost,
  isRead,
  setIsViewsPopupVisible,
  isViewsPopupVisible,
}) {
  const { t } = useTranslation();

  return (
    <div className={classNames(classes.container, classes.custom)}>
      <div className={classes.content}>
        <h3 className={classes.title}>
          {t(
            'dashboardComponents.Discover.Feed.Post.Announcement.Custom.announcement'
          )}
        </h3>
        <div
          className={classes.text}
          dangerouslySetInnerHTML={{ __html: feedPost.content }}
        />
        <div
          className={classes.isRead}
          style={{
            visibility: isRead ? 'visible' : 'hidden',
          }}
        >
          <div className={classes.circle} />
          <p>
            {t(
              'dashboardComponents.Discover.Feed.Post.Announcement.Custom.iHaveReadThis'
            )}
            .{' '}
            <span className={classes.black}>
              {t(
                'dashboardComponents.Discover.Feed.Post.Announcement.Custom.readBy'
              )}
            </span>{' '}
            {feedPost.views.length}{' '}
            <span
              className={classes.viewCount}
              onClick={() => setIsViewsPopupVisible(true)}
            >
              {feedPost.views.length > 1
                ? t(
                    'dashboardComponents.Discover.Feed.Post.Announcement.Custom.members'
                  )
                : t(
                    'dashboardComponents.Discover.Feed.Post.Announcement.Custom.member'
                  )}
              {isViewsPopupVisible && (
                <MembersPopup
                  members={feedPost.views?.map((view) => view.author)}
                  views
                  closePopup={() => setIsViewsPopupVisible(false)}
                />
              )}
            </span>
            .
          </p>
        </div>
      </div>
      <div className={classes.background} />
    </div>
  );
}
