import React, { useState, useEffect, useRef, useCallback } from 'react';

import Tab from './Tab';

import classes from './styles.module.scss';

export default function Tabs({ tabs, activeTab, setActiveTab }) {
  const [lineFillWidth, setLineFillWidth] = useState(0);
  const [lineFillLeft, setLineFillLeft] = useState(0);

  const activeTabRef = useRef();
  const tabsContainerRef = useRef();

  const positionLineFill = useCallback(() => {
    if (activeTabRef.current) {
      setLineFillWidth(activeTabRef.current.offsetWidth);

      const tabsContainerRect =
        tabsContainerRef.current.getBoundingClientRect();
      const activeTabRect = activeTabRef.current.getBoundingClientRect();

      setLineFillLeft(activeTabRect.left - tabsContainerRect.left);
    }
  }, [activeTabRef, tabsContainerRef]);

  useEffect(() => {
    positionLineFill();
  }, [activeTab, positionLineFill]);

  useEffect(() => {
    window.addEventListener('resize', positionLineFill);

    return () => {
      window.removeEventListener('resize', positionLineFill);
    };
  }, [positionLineFill]);

  return (
    <div className={classes.Tabs}>
      <div className={classes.tabsContainer} ref={tabsContainerRef}>
        {tabs.map((tab) => (
          <Tab
            reference={activeTab === tab ? activeTabRef : null}
            key={tab}
            tab={tab}
            isActive={activeTab === tab}
            onClick={() => setActiveTab(tab)}
          />
        ))}
      </div>
      <div className={classes.line}>
        <div
          className={classes.lineFill}
          style={{
            width: lineFillWidth,
            left: lineFillLeft,
          }}
        />
      </div>
    </div>
  );
}
