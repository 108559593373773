/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import ReviewJobForm from '../../../PostAJob/ReviewJobForm';

import convertDropdownValues from '../../../../../../helpers/convertDropdownValues';
import createOrUpdateVrQuestions from '../../../../../../helpers/createOrUpdateVrQuestions';
import { UiContext } from '../../../../../../context/UiContext';
import classes from './styles.module.scss';
import Button from '../../../../../Button';
import AdminService from '../../../../../../services/AdminService';

const vrValidationSchema = yup.object({
  isAssessmentNeeded: yup.boolean(),
  applicationType: yup.string().trim(),
});

const convertDataFieldToDropdownValue = (field, labelFieldName = 'name') => {
  if (!field) {
    return '';
  }
  return { label: field[labelFieldName], value: field.id };
};

export default function Details({ job, refetch }) {
  const [isDelegated, setIsDelegated] = useState(true);
  const [vrQuestions, setVrQuestions] = useState([]);
  const [useDefaultVrQuestions, setUseDefaultVrQuestions] = useState(false);
  const [hasDuration, setHasDuration] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);
  const [assessments, setAssessments] = useState([]);

  const areVrQuestionsValid = useRef();
  const areAssessmentsValid = useRef();

  const { showUnknownErrorModal, showModal, setIsFetching } =
    useContext(UiContext);

  const { t } = useTranslation();

  useEffect(() => {
    areVrQuestionsValid.current = true;
    areAssessmentsValid.current = true;
  }, []);

  useEffect(() => {
    setVrQuestions(
      job?.videoResumeQuestions
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((qstn) => ({
          id: qstn.id,
          savedQuestion: {},
          duration: qstn.duration,
          title: qstn.title,
          question: qstn.question,
        }))
    );
  }, [job]);

  const { data: allVrQuestions } = useQuery({
    queryKey: ['vrQuestions'],
    queryFn: () => AdminService.getVrQuestions({ pageSize: 999999 }),
  });

  const topQuestions = useMemo(
    () =>
      allVrQuestions?.data
        ?.filter((question) => question.isDefault)
        ?.sort((prev, next) => prev.sortOrder - next.sortOrder)
        ?.slice(0, 5),
    [allVrQuestions]
  );

  useEffect(() => {
    if (
      vrQuestions.length &&
      vrQuestions.every((quest) =>
        topQuestions.map((q) => q.id).includes(quest.id)
      ) &&
      isEditMode
    ) {
      setUseDefaultVrQuestions(true);
    }
  }, [isEditMode, topQuestions]);

  useEffect(() => {
    if (useDefaultVrQuestions) {
      setVrQuestions?.(
        topQuestions?.map((question) => ({
          id: question.id,
          savedQuestion: {},
          duration: question.duration,
          title: question.title,
          question: question.question,
        }))
      );
    }
  }, [setVrQuestions, useDefaultVrQuestions, topQuestions]);

  const basicInfoValidationSchema = useMemo(
    () =>
      yup.object({
        jobTitle: yup
          .string()
          .trim()
          .required(
            t('dashboardComponents.Jobs.MyJobs.JobDetails.Details.jobTitle')
          ),
        locationCityId: yup
          .object()
          .required(
            t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.Details.locationCityId'
            )
          ),
        locationCityDistrictId: yup.object(),
        jobTypeIds: yup
          .array()
          .required(
            t('dashboardComponents.Jobs.MyJobs.JobDetails.Details.jobTypeIds')
          ),
        education: yup.object(),
        corporateTitle: yup.string().trim().nullable(),
        jobDescription: yup
          .string()
          .trim()
          .nullable()
          .required(
            t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.Details.jobDescription'
            )
          )
          .min(
            50,
            t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.Details.minCharacters'
            )
          ),
        locationIds: yup.array(),
        workExperienceId: yup.object(),
        salaryTo: yup
          .number()
          .nullable()
          .min(
            1000,
            t('dashboardComponents.Jobs.MyJobs.JobDetails.Details.salaryTooLow')
          )
          .moreThan(
            yup.ref('salaryFrom'),
            t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.Details.salaryToMoreThanSalaryFrom'
            )
          ),
        salaryFrom: yup
          .number()
          .nullable()
          .min(
            1000,
            t('dashboardComponents.Jobs.MyJobs.JobDetails.Details.salaryTooLow')
          ),
        note: yup.string().trim().nullable(),
        coverFileName: yup
          .mixed()
          .required(
            t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.Details.coverFileName'
            )
          ),
        jobPostVideoId: yup.mixed(),
        skillIds: yup.array(),
        jobPostAttachmentIds: yup.mixed(),
      }),
    [t]
  );

  const basicInfoFormik = useFormik({
    initialValues: {
      locationCityId: !job?.locationCity
        ? ''
        : convertDataFieldToDropdownValue(job.locationCity),
      locationCityDistrictId: !job?.locationCityDistrict
        ? ''
        : convertDataFieldToDropdownValue(job.locationCityDistrict),
      jobTypeIds: !job?.jobTypes
        ? ''
        : job.jobTypes?.map((jobType) =>
            convertDataFieldToDropdownValue(jobType)
          ),
      graduation: !job?.graduation
        ? ''
        : convertDataFieldToDropdownValue(job.graduation),
      corporateTitle: !job?.corporateTitle ? '' : job.corporateTitle,
      jobDescription: !job?.jobDescription ? '' : job.jobDescription,
      locationIds: !job?.locations
        ? ''
        : job.locations?.map((loc) => convertDataFieldToDropdownValue(loc)),
      workExperienceId: !job?.workExperience
        ? ''
        : convertDataFieldToDropdownValue(job.workExperience),
      salaryTo: !job?.salaryTo ? '' : job.salaryTo,
      salaryFrom: !job?.salaryFrom ? '' : job.salaryFrom,
      note: !job?.note ? '' : job.note,
      coverFileName: !job?.coverFileName ? '' : job.coverFileName,
      jobPostVideoId: !job?.jobPostVideo ? '' : job.jobPostVideo,
      skillIds: !job?.skills
        ? ''
        : job.skills?.map((skill) => convertDataFieldToDropdownValue(skill)),
      jobPostAttachmentIds: !job?.jobPostAttachments
        ? ''
        : job.jobPostAttachments,
      jobTitle: !job?.jobTitle ? '' : job.jobTitle,
    },
    validationSchema: basicInfoValidationSchema,
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const vrFormik = useFormik({
    initialValues: {
      isAssessmentNeeded: !!job?.assignedAssessments.length,
      applicationType: !job?.applicationType
        ? 'DocumentOnly'
        : job.applicationType,
      assignedAssessments: job?.assignedAssessments
        ? job.assignedAssessments
        : [],
    },
    validationSchema: vrValidationSchema,
    onSubmit: () => {},
    enableReinitialize: true,
  });

  if (
    (vrFormik.values.applicationType === 'VideoOnly' ||
      vrFormik.values.applicationType === 'VideoOrDocument' ||
      vrFormik.values.applicationType === 'VideoAndDocument') &&
    !vrQuestions.length
  ) {
    areVrQuestionsValid.current = false;
  } else {
    areVrQuestionsValid.current = true;
  }

  if (
    vrFormik.values.isAssessmentNeeded &&
    (assessments.length || vrFormik.values.assignedAssessments?.length) &&
    assessments.every(
      (assessment) =>
        assessment.limit > 2 &&
        assessment.limit < 10 &&
        (assessment.threshold === null || assessment.threshold > 49) &&
        (assessment.englishThreshold === null ||
          assessment.englishThreshold > 49) &&
        (assessment.mentalThreshold === null || assessment.mentalThreshold > 49)
    )
  ) {
    areAssessmentsValid.current = true;
  } else {
    areAssessmentsValid.current = false;
  }

  if (!assessments.length && !vrFormik.values.isAssessmentNeeded) {
    areAssessmentsValid.current = true;
  }

  const delegateValidationSchema = useMemo(
    () =>
      yup.object({
        isDelegated: yup.boolean(),
        interviewers: yup.array(),
        delegate: yup.object().when('isDelegated', {
          is: true,
          then: yup.object().required(t('common.cannotBeEmpty')),
        }),
        duration: yup.string().trim(),
        startsAt: yup.date().when('isDelegated', {
          is: true,
          then: yup.date().required(t('common.cannotBeEmpty')),
        }),
        endsAt: yup.date().when('isDelegated', {
          is: true,
          then: yup.date().required(t('common.cannotBeEmpty')),
        }),
      }),
    [t]
  );

  const delegatesFormik = useFormik({
    initialValues: {
      isDelegated: !!job?.jobPostDelegates?.length,
      interviewers: !job?.jobPostInterviewers
        ? ''
        : job.jobPostInterviewers?.map((interviewer) =>
            convertDataFieldToDropdownValue(interviewer.user, 'userName')
          ),
      delegate: !job?.jobPostDelegates
        ? ''
        : convertDataFieldToDropdownValue(
            job?.jobPostDelegates?.[0]?.user,
            'userName'
          ),
      duration: '',
      startsAt: '',
      endsAt: '',
    },
    validationSchema: delegateValidationSchema,
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const updateJobPost = async () => {
    setIsTriedToSubmit(true);

    if (
      !areVrQuestionsValid.current ||
      !basicInfoFormik.isValid ||
      !vrFormik.isValid ||
      !delegatesFormik.isValid ||
      !areAssessmentsValid.current
    ) {
      return;
    }

    const uploadCoverImage = async (image) => {
      return AdminService.uploadImage(image, 'jobpostcover');
    };

    const uploadJobPostVideo = async (video) => {
      const uploadedVideo = await AdminService.uploadVideo(video);
      return AdminService.createJobPostVideo(uploadedVideo);
    };

    const uploadJobPostAttachment = async (doc) => {
      const uploadedDoc = await AdminService.uploadFile(doc);
      return AdminService.createJobPostAttachment(uploadedDoc);
    };

    try {
      setIsFetching(true);

      const values = {
        ...basicInfoFormik.values,
        ...vrFormik.values,
      };

      if (values.coverFileName && values.coverFileName instanceof File) {
        const image = await uploadCoverImage(values.coverFileName);
        values.coverFileName = image.fileName;
      }

      if (values.jobPostVideoId && values.jobPostVideoId instanceof File) {
        const jobPostVideo = await uploadJobPostVideo(values.jobPostVideoId);
        values.jobPostVideoId = jobPostVideo?.id;
      } else if (values.jobPostVideoId && values.jobPostVideoId.id) {
        values.jobPostVideoId = values.jobPostVideoId.id;
      }

      if (
        values.jobPostAttachmentIds &&
        values.jobPostAttachmentIds instanceof File
      ) {
        const jobPostAttachment = await uploadJobPostAttachment(
          values.jobPostAttachmentIds
        );
        values.jobPostAttachmentIds = [jobPostAttachment?.id];
      } else if (
        values.jobPostAttachmentIds &&
        values.jobPostAttachmentIds.length
      ) {
        values.jobPostAttachmentIds = [values.jobPostAttachmentIds[0]?.id];
      }

      if (vrQuestions.length) {
        const questions = useDefaultVrQuestions
          ? vrQuestions.map((quest) => quest.id)
          : await createOrUpdateVrQuestions(vrQuestions);
        values.videoResumeQuestionIds = questions;
      }

      await AdminService.updateJobPost({
        ...convertDropdownValues(values),
        graduationId: values.graduation?.value,
        status: job.status,
        id: job.id,
      });

      if (delegatesFormik.values.delegate) {
        const delegate = await AdminService.createJobPostDelegate({
          userId: delegatesFormik.values.delegate?.value,
          startsAt: delegatesFormik.values.startsAt,
          endsAt: delegatesFormik.values.endsAt,
          jobPostId: job.id,
        });

        await AdminService.updateJobPost({
          ...convertDropdownValues(values),

          id: job.id,
          jobPostDelegateIds: [delegate.id],
        });
      }

      if (delegatesFormik.values.interviewers) {
        const promises = delegatesFormik.values.interviewers.map(
          (interviewer) =>
            AdminService.createJobPostInterviewer({
              userId: interviewer.value,
              jobPostId: job.id,
            })
        );

        const interviewers = await Promise.all(promises);

        await AdminService.updateJobPost({
          ...convertDropdownValues(values),
          status: job.status,
          id: job.id,
          jobPostInterviewerIds: interviewers.map(
            (interviewer) => interviewer.id
          ),
          assignedAssessments: assessments.map((assessment, index) => ({
            tenantAssessmentProviderId: assessment.tenantAssessmentProviderId,
            assessmentProviderKey: assessment.assessmentProviderKey,
            assessmentId: assessment.assessmentId,
            threshold: `${assessment.threshold}`,
            englishThreshold: `${assessment.englishThreshold}`,
            mentalThreshold: `${assessment.mentalThreshold}`,
            limit: assessment.limit,
            sortOrder: index + 1,
          })),
        });
      }

      refetch?.();

      showModal({
        title: t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.Details.successTitle'
        ),
        text: t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.Details.successMessage'
        ),
      });
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsFetching(false);
    }
  };

  const handleSubmitClick = () => {
    if (vrFormik.dirty || basicInfoFormik.dirty) {
      showModal({
        title: t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.Details.warningTitle'
        ),
        text: t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.Details.warningMessage'
        ),
        dismissButtonLabel: t('common.no'),
        confirmButtonLabel: t('common.yes'),
        onConfirm: () => {
          updateJobPost();
        },
        onCancel: () => {},
      });

      return;
    }

    updateJobPost();
  };

  return (
    <div className={classes.Details}>
      <ReviewJobForm
        readOnly={!isEditMode}
        isEdit={isEditMode}
        basicInfoFormik={basicInfoFormik}
        vrFormik={vrFormik}
        delegatesFormik={delegatesFormik}
        isDelegated={isDelegated}
        setIsDelegated={setIsDelegated}
        vrQuestions={vrQuestions}
        setVrQuestions={setVrQuestions}
        useDefaultVrQuestions={useDefaultVrQuestions}
        setUseDefaultVrQuestions={setUseDefaultVrQuestions}
        hasDuration={hasDuration}
        setHasDuration={setHasDuration}
        areVrQuestionsValid={areVrQuestionsValid}
        noBackground
        style={{
          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 33,
          paddingBottom: 44,
        }}
        isTriedToSubmit={isTriedToSubmit}
        assessments={assessments}
        setAssessments={setAssessments}
        jobPostDetails
        jobPostId={job?.id}
      />
      <div className={classes.buttons}>
        <div className={classes.container}>
          <Button
            width={250}
            height={55}
            onClick={() => setIsEditMode(true)}
            disabled={isEditMode}
          >
            {t('common.edit')}
          </Button>
          <Button
            width={250}
            height={55}
            onClick={handleSubmitClick}
            disabled={!isEditMode}
          >
            {t('common.submit')}
          </Button>
        </div>
      </div>
    </div>
  );
}
