import React from 'react';

import LeftPanelHeader from './LeftPanelHeader';
import Contacts from './Contacts';

import classes from './styles.module.scss';

export default function LeftPanel({
  chatThreads,
  activeContactId,
  setActiveContactId,
  refetchChatThreads,
  orderBy,
  setOrderBy,
  searchTerm,
  setSearchTerm,
  searchHistory,
}) {
  return (
    <div className={classes.LeftPanel}>
      <LeftPanelHeader
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        searchHistory={searchHistory}
      />
      <Contacts
        activeContactId={activeContactId}
        setActiveContactId={setActiveContactId}
        chatThreads={chatThreads}
        refetchChatThreads={refetchChatThreads}
      />
    </div>
  );
}
