/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import FavStatus from '../../../../Header/Search/FavStatus';
import AdminService from '../../../../../../services/AdminService';
import Button from '../../../../../Button';
import Textarea from '../../../../../Form/Textarea';
import SelectedUsers from '../../../../SelectedUsers';
import MultiDropdown from '../../../../../Form/MultiDropdown';
import TextInput from '../../../../../Form/TextInput';
import FormDropdown from '../../../../../Form/FormDropdown';
import Dropdown from '../../../../../Form/Dropdown';
import TimeRange from '../../../../../Form/TimeRange';
import DatePicker from '../../../../../Form/DatePicker';
import CandidatesDropDown from './CandidatesDropDown';

import { UiContext } from '../../../../../../context/UiContext';
import classes from './styles.module.scss';
import { getTime } from '../../../../../../helpers/getEvents';
import {
  getTimezoneDate,
  getTimezoneTime,
  setTimezoneDate,
  setTimezoneTime,
} from '../../../../../../helpers/timezones';

const validationSchema = yup.object({
  interviewDate: yup.date().required('This field is required'),
  type: yup.object().required('This field is required'),
  level: yup.object().required('This field is required'),
  candidate: yup.object().required('This field is required'),
  job: yup.object().required('This field is required'),
  customLocation: yup.object(),
  connectionDetail: yup.string().trim(),
  notificationType: yup.object(),
  notificationMethod: yup.object(),
  notificationValue: yup.object(),
  userIds: yup.array(),
  note: yup.string().trim(),
  startTime: yup.string().trim().required('Start time is required'),
  endTime: yup
    .string()
    .trim()
    .required('End time is required')
    .test(
      'less-than-start',
      'End Time  cannot be lower than Start Time.',
      (value, ctx) => {
        if (!ctx.parent.startTime) {
          return true;
        }
        const endTime = parseInt(value?.replace(':', ''), 10);
        const startTime = parseInt(ctx.parent.startTime?.replace(':', ''), 10);
        return !(endTime < startTime);
      }
    ),
});

function getEventDate(date) {
  const tempDate = new Date(date);

  return `${tempDate.getDate()} ${tempDate.toLocaleString('en-us', {
    month: 'long',
  })} ${tempDate.getFullYear()}
  `;
}

export default function RecruiterInterview({
  isScheduleInterviewVisible,
  setIsScheduleInterviewVisible,
  candidate,
  interview,
  currentUser,
  refetch,
  setIsCandidateModalVisible,
  setCurrentCandidate,
}) {
  const [isOpened, setIsOpened] = useState(false);
  const [selectedList, setSelectedList] = useState('');
  const [selectedJob, setSelectedJob] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isEventViewed, setIsEventViewed] = useState(false);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { data: job } = useQuery({
    queryKey: ['myJob', candidate?.jobPostId],
    queryFn: () => AdminService.getJobPost({ id: candidate?.jobPostId }),
    enabled: !!candidate?.jobPostId,
  });

  const { data: databaseCandidates } = useQuery({
    queryKey: ['candidates'],
    queryFn: () =>
      AdminService.getCandidates({
        term: '',
      }),
    keepPreviousData: true,
  });

  const { data: jobPages } = useQuery({
    queryKey: ['jobs'],
    queryFn: () => AdminService.getJobPosts({ pageSize: 99999 }),
    keepPreviousData: true,
  });

  const { data: jobApplications } = useQuery({
    queryKey: ['jobApplications'],
    queryFn: AdminService.getJobApplications,
  });

  const jobs = jobPages?.data;

  useEffect(() => {
    if (candidate) {
      AdminService.addCandidateToViewed({ id: candidate.user?.id });
    }
  }, [candidate]);

  useEffect(() => {
    if (isScheduleInterviewVisible) {
      setTimeout(() => {
        setIsOpened(true);
      }, 100);
    }
  }, [isScheduleInterviewVisible]);

  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const { showNotification, showUnknownErrorModal, showModal, setIsFetching } =
    useContext(UiContext);

  const isEdit = candidate && candidate?.jobApplicationInterviews?.length;

  useEffect(() => {
    if (isEdit) {
      setIsEventViewed(true);
    }
  }, [isEdit]);

  const formik = useFormik({
    initialValues: {
      title: isEdit ? interview?.event?.title : '',
      job: '',
      candidate: isEdit
        ? `${candidate?.user?.userProfile?.firstName} ${candidate?.user?.userProfile?.lastName}`
        : '',
      interviewDate: isEdit
        ? getTimezoneDate(
            interview?.event?.suggestedInterviewDate ||
              interview?.event?.interviewDate,
            currentUser?.userProfile?.timezone
          )[0]
        : '',
      type: isEdit
        ? {
            value: interview?.event?.type,
            label: interview?.event?.type.replace(/([a-z])([A-Z])/g, '$1-$2'),
          }
        : '',
      level: isEdit
        ? {
            value: candidate?.jobApplicationInterviews?.[0]?.level,
            label: interview?.event?.level,
          }
        : '',
      customLocation: isEdit
        ? {
            value: interview?.event?.customLocation,
            label: interview?.event?.customLocation,
          }
        : '',
      connectionDetail: isEdit ? interview?.event?.connectionDetail : '',
      notificationType: isEdit
        ? {
            value: interview?.event?.notificationType,
            label: interview?.event?.notificationType,
          }
        : '',
      notificationMethod: isEdit
        ? {
            value: interview?.event?.notificationMethod,
            label: interview?.event?.notificationMethod,
          }
        : '',
      notificationValue: isEdit
        ? {
            value: interview?.event?.notificationValue,
            label: interview?.event?.notificationValue,
          }
        : '',
      userIds: isEdit
        ? interview?.event?.users?.map((user) => ({
            label: user?.userName,
            value: user?.id,
          }))
        : '',
      note: isEdit ? interview?.event?.note : '',
      startTime: isEdit
        ? getTimezoneTime(
            interview?.event?.suggestedStartTime || interview?.event?.startTime,
            currentUser?.userProfile?.timezone
          )
        : '',
      endTime: isEdit
        ? getTimezoneTime(
            interview?.event?.suggestedEndTime || interview?.event?.endTime,
            currentUser?.userProfile?.timezone
          )
        : '',
    },
    validationSchema,
    enableReinitialize: true,
  });

  const clearInterview = () => {
    formik.resetForm();
    setSelectedJob('');
    setSelectedCandidate('');
    setSelectedList('');
  };

  const handleClose = () => {
    setIsOpened(false);
    setTimeout(() => setIsScheduleInterviewVisible(false), 300);
  };

  const closeAndNotify = (message) => {
    handleClose();
    showNotification({
      text: message,
    });
  };

  const scheduleInterview = async () => {
    try {
      setIsFetching(true);

      await AdminService.changeMyJobApplicationStatus({
        jobApplicationId: selectedCandidate?.id,
        status: 'Interview',
        subStatus: 'Scheduled',
      });
      handleClose();
      closeAndNotify(
        t(
          'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.interviewCreated'
        )
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const deleteInterview = async () => {
    try {
      setIsFetching(true);

      await AdminService.deleteJobApplicationInterview({
        jobApplicationId: candidate?.id,
        interviewid: interview.id,
      });
      closeAndNotify(
        t(
          'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.interviewDeleted'
        )
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const cancelInterview = async () => {
    const prevStatus = interview?.event?.status;
    const undo = async () => {
      try {
        setIsFetching(true);

        await AdminService.changeJobApplicationInterview({
          interviewId: interview?.id,
          jobApplicationId: interview?.jobApplicationId,
          interviewDate: interview?.event?.interviewDate,
          type: interview?.event?.type,
          level: interview?.event?.level,
          customLocation: interview?.event?.customLocation,
          connectionDetail: interview?.event?.connectionDetail,
          notificationType: interview?.event?.notificationType,
          notificationMethod: interview?.event?.notificationMethod,
          notificationValue: interview?.event?.notificationValue,
          userIds: interview?.event?.users.map((user) => user.id),
          note: interview?.event?.note,
          startTime: interview?.event?.startTime,
          endTime: interview?.event?.endTime,
          status: prevStatus,
          title: interview?.event?.title,
        });

        if (refetch) {
          await refetch();
        }
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        setIsFetching(false);
      }
    };

    showModal({
      icon: 'infoIcon',
      title: t(
        'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.cancelInterview'
      ),
      text: t(
        'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.confirmCancelInterview'
      ),
      onConfirm: async () => {
        try {
          setIsFetching(true);
          await AdminService.changeJobApplicationInterview({
            interviewId: interview?.id,
            jobApplicationId: interview?.jobApplicationId,
            interviewDate: interview?.event?.interviewDate,
            type: interview?.event?.type,
            level: interview?.event?.level,
            customLocation: interview?.event?.customLocation,
            connectionDetail: interview?.event?.connectionDetail,
            notificationType: interview?.event?.notificationType,
            notificationMethod: interview?.event?.notificationMethod,
            notificationValue: interview?.event?.notificationValue,
            userIds: interview?.event?.users.map((user) => user.id),
            note: interview?.event?.note,
            startTime: interview?.event?.startTime,
            endTime: interview?.event?.endTime,
            status: 'Fail',
            title: interview?.event?.title,
          });

          if (refetch) {
            await refetch();
          }

          showNotification({
            text: t(
              'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.interviewCanceled'
            ),
            undo,
          });
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
        } finally {
          setIsFetching(false);
          handleClose();
        }
      },
      onCancel: () => {},
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
    });
  };

  const updateInterview = async () => {
    try {
      setIsFetching(true);

      await AdminService.changeJobApplicationInterview({
        interviewId: interview?.id,
        jobApplicationId: interview?.jobApplicationId,
        interviewDate: setTimezoneDate(
          formik.values.interviewDate,
          formik.values.startTime,
          currentUser?.userProfile?.timezone
        ),
        type: formik.values.type.value,
        level: formik.values.level.value,
        customLocation: formik.values.customLocation.label,
        connectionDetail: formik.values.connectionDetail,
        notificationType: formik.values.notificationType.value,
        notificationMethod: formik.values.notificationMethod.value,
        notificationValue: formik.values.notificationValue.value,
        userIds: formik.values.userIds.map((user) => user?.value),
        note: formik.values.note,
        startTime: setTimezoneTime(
          formik.values.startTime,
          currentUser?.userProfile?.timezone
        ),
        endTime: setTimezoneTime(
          formik.values.endTime,
          currentUser?.userProfile?.timezone
        ),
        status: interview?.event?.status,
        title: formik?.event?.title,
      });

      if (refetch) {
        await refetch();
      }

      showNotification({
        text: t(
          'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.interviewUpdated'
        ),
      });
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      handleClose();
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (selectedJob === '') {
      return;
    }
    if (selectedJob?.jobPostInterviewers?.length === 0) {
      formik.setFieldValue('userIds', '');
      return;
    }

    formik.setFieldValue(
      'userIds',
      selectedJob?.jobPostInterviewers?.map((user) => ({
        label: user.user.userName,
        value: user.user.id,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob]);

  let isNotificationInvalid = false;

  if (
    formik.values.notificationMethod.value !== 'None' &&
    (!formik.values.notificationType || !formik.values.notificationValue)
  ) {
    isNotificationInvalid = true;
  }

  if (
    formik.values.notificationMethod.value === 'None' ||
    formik.values.notificationMethod === ''
  ) {
    isNotificationInvalid = false;
  }

  let isConnectionDetailsInvalid = false;

  if (
    formik.values.type.value === 'InPerson' &&
    !formik.values.customLocation.value
  ) {
    isConnectionDetailsInvalid = true;
  } else if (
    formik.values.type.value !== 'InPerson' &&
    !formik.values.connectionDetail
  ) {
    isConnectionDetailsInvalid = true;
  }

  const isDisabled =
    !formik.values.interviewDate ||
    !formik.values.startTime ||
    !formik.values.endTime ||
    !formik.values.type ||
    !formik.values.level ||
    isNotificationInvalid ||
    isConnectionDetailsInvalid ||
    !formik.values.candidate ||
    !formik.values.job ||
    !formik.dirty;

  const isEditDisabled =
    !formik.values.interviewDate ||
    !formik.values.startTime ||
    !formik.values.endTime ||
    !formik.values.type ||
    !formik.values.level ||
    !formik.values.notificationMethod ||
    isNotificationInvalid ||
    isConnectionDetailsInvalid ||
    !formik.dirty;

  useEffect(() => {
    if (isEdit) {
      formik.setFieldValue(
        'title',
        `${t(
          'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.interviewFor'
        )} ${candidate?.jobPost?.jobTitle} (${candidate?.jobPost?.id}) ${t(
          'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.role'
        )} (${formik.values.level.label})`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    candidate?.jobPost?.id,
    candidate?.jobPost?.jobTitle,
    formik.values.level,
    isEdit,
    t,
  ]);

  // Auto generate title
  useEffect(() => {
    if (selectedJob) {
      const title = `Interview for '${selectedJob?.jobTitle}' (${
        selectedJob?.id
      }) role ${
        formik.values.level?.label ? `(${formik.values.level?.label})` : ''
      }`;
      formik.setFieldValue('title', title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob, formik.values.level]);

  const status = candidate?.jobApplicationInterviews?.[0].status;
  const suggestedInterviewDate = getEventDate(
    getTimezoneDate(
      candidate?.jobApplicationInterviews?.[0]?.suggestedInterviewDate,
      currentUser?.userProfile?.timezone,
      candidate?.jobApplicationInterviews?.[0]?.suggestedStartTime
    )[0]
  );
  const suggestedStartTime = getTimezoneTime(
    candidate?.jobApplicationInterviews?.[0]?.suggestedStartTime,
    currentUser?.userProfile?.timezone
  );

  return (
    <div
      className={classNames(classes.blur, {
        [classes.blurActivated]: isScheduleInterviewVisible,
      })}
      onClick={handleClose}
    >
      <div
        className={classNames(classes.containerPopup, {
          [classes.visible]: isOpened,
        })}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className={classes.interview}>
          <div className={classes.content}>
            <div>
              <header>
                {candidate ? (
                  <h1>
                    {t(
                      'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.title.default'
                    )}
                  </h1>
                ) : (
                  <h1>
                    {t(
                      'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.title.schedule'
                    )}
                  </h1>
                )}
                {candidate && (
                  <FavStatus
                    calendarEvent={interview}
                    width={30}
                    userId={currentUser?.id}
                    refetch={refetch}
                  />
                )}
              </header>
              <div
                className={classNames(classes.container, {
                  [classes.isViewed]: isEventViewed,
                })}
              >
                {isEdit && (
                  <div
                    style={{
                      display: candidate ? 'none' : 'block',
                    }}
                  >
                    <div className={classes.row}>
                      <div className={classes.col}>
                        <div className={classes.applicationInfo}>
                          <div className={classes.candidate}>
                            <h3>{t('common.candidate')}</h3>
                            <span
                              className={classes.view}
                              onClick={() => {
                                setIsCandidateModalVisible(true);
                                setCurrentCandidate(candidate?.user);
                              }}
                            >
                              {t('common.viewProfile')}
                            </span>
                          </div>
                          <h2>
                            {candidate?.user?.userProfile?.firstName}{' '}
                            {candidate?.user?.userProfile?.lastName}
                          </h2>
                          {(status === 'Accepted' ||
                            status === 'Declined' ||
                            status === 'ChangeRequest') && (
                            <div className={classes.status}>
                              <div
                                className={classNames(classes.statusText, {
                                  [classes.statusDeclined]:
                                    status === 'Declined',
                                  [classes.changeRequest]:
                                    status === 'ChangeRequest',
                                })}
                              >
                                {status.replace(/([A-Z])/g, ' $1').trim()}
                              </div>
                              {status === 'ChangeRequest' && (
                                <span className={classes.statusDate}>
                                  {`${t(
                                    'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.requested'
                                  )}:
                                  ${suggestedInterviewDate} ${getTime(
                                    suggestedStartTime
                                  )}`}
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={classes.headerCol}>
                        <div className={classes.applicationInfo}>
                          <h3>{t('common.position')}</h3>
                          <h2>{job?.jobTitle}</h2>
                          <span className={classes.jobId}>
                            {t('common.jobId')}: {candidate?.jobPostId}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className={classes.row}>
                  <TextInput
                    labelStyle={{ fontSize: 16, fontFamily: 'Gilroy-SemiBold' }}
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    width="100%"
                    height={45}
                    label={t(
                      'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.labels.title'
                    )}
                    note={t('common.optional')}
                    placeholder={t(
                      'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.placeholders.meetingTitle'
                    )}
                  />
                </div>
                {!isEdit && (
                  <div className={classes.row}>
                    <div className={classes.col}>
                      <CandidatesDropDown
                        // width={isTabletOrMobile ? '100%' : 386}
                        height={45}
                        label={t(
                          'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.labels.candidateDropdown'
                        )}
                        placeholder={t(
                          'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.placeholders.jobApplicants'
                        )}
                        value={formik.values.candidates}
                        infoWidth={151}
                        name="job"
                        setFieldValue={formik.setFieldValue}
                        selectedList={selectedList}
                        setSelectedList={setSelectedList}
                        currentUser={currentUser}
                        jobApplications={jobApplications}
                        selectedJob={selectedJob}
                        setSelectedJob={setSelectedJob}
                        setSelectedCandidate={setSelectedCandidate}
                        jobs={jobs}
                        databaseCandidates={databaseCandidates}
                      />
                    </div>
                    <div className={classes.col}>
                      <CandidatesDropDown
                        // width={isTabletOrMobile ? '100%' : 386}
                        height={45}
                        label={t(
                          'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.labels.candidate'
                        )}
                        placeholder={t(
                          'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.placeholders.selectCandidate'
                        )}
                        value={formik.values.candidate}
                        infoWidth={151}
                        name="candidates"
                        candidates
                        setFieldValue={formik.setFieldValue}
                        selectedList={selectedList}
                        setSelectedList={setSelectedList}
                        currentUser={currentUser}
                        jobApplications={jobApplications}
                        selectedJob={selectedJob}
                        setSelectedJob={setSelectedJob}
                        selectedCandidate={selectedCandidate}
                        setSelectedCandidate={setSelectedCandidate}
                        databaseCandidates={databaseCandidates}
                      />
                    </div>
                  </div>
                )}
                <div className={classes.row}>
                  <div className={classes.col}>
                    <DatePicker
                      labelStyle={{
                        fontSize: 16,
                        fontFamily: 'Gilroy-SemiBold',
                      }}
                      dateFieldWidth="100%"
                      height={45}
                      label={t(
                        'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.labels.interviewDate'
                      )}
                      name="interviewDate"
                      value={formik.values.interviewDate}
                      setFieldValue={formik.setFieldValue}
                      error={formik.errors.interviewDate}
                      // touched={isDelegatesTriedToSubmit}
                      setFieldTouched={formik.setFieldTouched}
                      isPopupOpened
                    />
                  </div>
                  <div className={classes.col}>
                    <TimeRange
                      labelStyle={{
                        fontSize: 16,
                        fontFamily: 'Gilroy-SemiBold',
                      }}
                      height={45}
                      fromValue={formik.values.startTime}
                      toValue={formik.values.endTime}
                      setFieldValue={formik.setFieldValue}
                      fromName="startTime"
                      toName="endTime"
                      error={formik.errors.endTime}
                      touched={isTriedToSubmit}
                      showDuration
                      label={t(
                        'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.labels.interviewTime'
                      )}
                    />
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classes.col}>
                    <div className={classes.dropdowns}>
                      <div className={classes.dropdownContainer}>
                        <h4 className={classes.dropdownTitle}>
                          {t(
                            'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.labels.type'
                          )}
                        </h4>
                        <Dropdown
                          style={{
                            backgroundColor: '#F2F4F8',
                            border: 'none',
                          }}
                          width={isTabletOrMobile ? '100%' : 186}
                          height={45}
                          options={[
                            { value: 'InPerson', label: t('common.inPerson') },
                            { value: 'Video', label: t('common.video') },
                            { value: 'Audio', label: t('common.audio') },
                          ]}
                          activeOption={formik.values.type}
                          setActiveOption={(option) =>
                            formik.setFieldValue('type', option)
                          }
                        />
                      </div>
                      <div className={classes.dropdownContainer}>
                        <h4 className={classes.dropdownTitle}>
                          {t(
                            'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.labels.level'
                          )}
                        </h4>
                        <Dropdown
                          style={{
                            backgroundColor: '#F2F4F8',
                            border: 'none',
                          }}
                          width={isTabletOrMobile ? '100%' : 186}
                          height={45}
                          options={[
                            {
                              value: 'Level1',
                              label: t('common.level', { level: 1 }),
                            },
                            {
                              value: 'Level2',
                              label: t('common.level', { level: 2 }),
                            },
                            {
                              value: 'Level3',
                              label: t('common.level', { level: 3 }),
                            },
                          ]}
                          activeOption={formik.values.level}
                          setActiveOption={(option) =>
                            formik.setFieldValue('level', option)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.col}>
                    <div className={classes.dropdownsCol}>
                      <h4 className={classes.dropdownTitle}>
                        {t(
                          'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.labels.notification'
                        )}
                      </h4>
                      <div
                        className={classNames(
                          classes.dropdowns,
                          classes.notification
                        )}
                      >
                        <Dropdown
                          style={{
                            backgroundColor: '#F2F4F8',
                            border: 'none',
                          }}
                          width={isTabletOrMobile ? '100%' : 140}
                          height={45}
                          options={[
                            {
                              value: 'Notification',
                              label: t('common.notification'),
                            },
                            { value: 'Email', label: t('common.email') },
                            { value: 'None', label: t('common.none') },
                          ]}
                          activeOption={formik.values.notificationMethod}
                          setActiveOption={(option) =>
                            formik.setFieldValue('notificationMethod', option)
                          }
                        />
                        <Dropdown
                          style={{
                            backgroundColor: '#F2F4F8',
                            border: 'none',
                          }}
                          width={80}
                          height={45}
                          options={
                            formik.values.notificationType.value === 'Minutes'
                              ? [
                                  { value: '30', label: '30' },
                                  { value: '45', label: '45' },
                                  { value: '60', label: '60' },
                                ]
                              : [
                                  { value: '1', label: '1' },
                                  { value: '2', label: '2' },
                                  { value: '3', label: '3' },
                                  { value: '4', label: '4' },
                                ]
                          }
                          activeOption={formik.values.notificationValue}
                          setActiveOption={(option) =>
                            formik.setFieldValue('notificationValue', option)
                          }
                        />
                        <Dropdown
                          style={{
                            backgroundColor: '#F2F4F8',
                            border: 'none',
                          }}
                          width={isTabletOrMobile ? '100%' : 140}
                          height={45}
                          options={[
                            { value: 'Minutes', label: t('common.minutes') },
                            { value: 'Hours', label: t('common.hours') },
                          ]}
                          activeOption={formik.values.notificationType}
                          setActiveOption={(option) =>
                            formik.setFieldValue('notificationType', option)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {formik.values.type?.value === 'InPerson' && (
                  <div className={classes.row}>
                    <FormDropdown
                      width={isTabletOrMobile ? '100%' : 386}
                      height={45}
                      labelStyle={{
                        fontSize: 16,
                        fontFamily: 'Gilroy-SemiBold',
                      }}
                      label={t('common.location')}
                      placeholder={t(
                        'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.placeholders.location'
                      )}
                      fetchOptions={{
                        dataName: 'restaurantsOptionsBasicInfo',
                        getDataHandler: AdminService.getRestaurants,
                      }}
                      value={formik.values.customLocation}
                      infoWidth={151}
                      name="customLocation"
                      searchInfo={t('common.addNewRestaurant')}
                      searchInfoWidth={188}
                      setFieldValue={formik.setFieldValue}
                    />
                  </div>
                )}
                {(formik.values.type?.value === 'Video' ||
                  formik.values.type?.value === 'Audio') && (
                  <div className={classes.row}>
                    <TextInput
                      name="connectionDetail"
                      value={formik.values.connectionDetail}
                      onChange={formik.handleChange}
                      width="100%"
                      height={45}
                      label={t(
                        'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.labels.connectionDetail'
                      )}
                      placeholder={t(
                        'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.placeholders.teamsOrSkype'
                      )}
                    />
                  </div>
                )}

                <div className={classes.row}>
                  <div className={classes.col}>
                    <div className={classes.selectContainer}>
                      <MultiDropdown
                        maxLength={5}
                        note={t('common.selectUpTo', { count: 5 })}
                        width={isTabletOrMobile ? '100%' : 386}
                        height={45}
                        label={t(
                          'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.labels.userIds'
                        )}
                        labelStyle={{
                          fontSize: 16,
                          fontFamily: 'Gilroy-SemiBold',
                        }}
                        fetchOptions={{
                          dataName: 'userOptionPostJobSuccess',
                          getDataHandler: AdminService.getUsers,
                          labelName: 'userName',
                          isUserList: true,
                        }}
                        value={formik.values.userIds}
                        name="userIds"
                        setFieldValue={formik.setFieldValue}
                        placeholder={t(
                          'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.placeholders.selectNameAndEmail'
                        )}
                      />
                    </div>
                    {formik.values.userIds?.length ? (
                      <div className={classes.selectedUsers}>
                        <SelectedUsers
                          invitedUsers={formik.values.userIds}
                          withFormik
                          setInvitedUsers={(users) =>
                            formik.setFieldValue('userIds', users)
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className={classes.col}>
                    <Textarea
                      labelStyle={{
                        fontSize: 16,
                        fontFamily: 'Gilroy-SemiBold',
                      }}
                      name="note"
                      onChange={formik.handleChange}
                      value={formik.values.note}
                      label={t(
                        'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.labels.note'
                      )}
                      note={t('common.optional')}
                      height={159}
                      placeholder={t(
                        'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.placeholders.addNote'
                      )}
                      // maxLength={5000}
                    />
                  </div>
                </div>
              </div>
            </div>
            <footer>
              {isEventViewed ? (
                <>
                  <Button
                    variant="secondary"
                    width={250}
                    height={55}
                    onClick={cancelInterview}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    width={250}
                    height={55}
                    onClick={() => {
                      setIsEventViewed(false);
                    }}
                  >
                    {t('common.edit')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="secondary"
                    width={250}
                    height={55}
                    onClick={isEdit ? deleteInterview : clearInterview}
                  >
                    {isEdit ? t('common.delete') : t('common.clear')}
                  </Button>
                  <Button
                    width={250}
                    height={55}
                    disabled={isEdit ? isEditDisabled : isDisabled}
                    onClick={() => {
                      if (isEdit) {
                        updateInterview();

                        return;
                      }
                      setIsTriedToSubmit(true);
                      scheduleInterview();
                    }}
                  >
                    {isEdit ? t('common.update') : t('common.schedule')}
                  </Button>
                </>
              )}
            </footer>
          </div>
        </div>
        <div className={classes.closeIconContainer}>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </div>
      </div>
      <div className={classes.bottom} />
    </div>
  );
}
