/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';

import Tooltip from '../../../../Tooltip';

import classes from './styles.module.scss';

export default function TwoWeeksCalendar({ events, today }) {
  const [dates, setDates] = useState([]);
  const [firstDay, setFirstDay] = useState(moment(today).startOf('week'));

  const calendarRef = useRef();

  const { t } = useTranslation();

  const switchToNextWeek = () => {
    setFirstDay(moment(firstDay).add(7, 'days'));
  };

  const switchToPreviousWeek = () => {
    setFirstDay(moment(firstDay).subtract(7, 'days'));
  };

  const resetCalendar = () => {
    setFirstDay(moment(today).startOf('week'));
  };

  useEffect(() => {
    const createDates = (startDay) => {
      const twoWeeksDates = [startDay];

      for (let i = 0; i < 13; i++) {
        twoWeeksDates.push(moment(startDay).add(i + 1, 'd'));
      }

      return twoWeeksDates;
    };

    setDates(createDates(firstDay));
  }, [firstDay]);

  useEffect(() => {
    calendarRef.current.style.setProperty('--todayDate', today.format('d'));
  }, [today]);

  const days = [
    t('common.shortDays.Sun'),
    t('common.shortDays.Mon'),
    t('common.shortDays.Tue'),
    t('common.shortDays.Wed'),
    t('common.shortDays.Thu'),
    t('common.shortDays.Fri'),
    t('common.shortDays.Sat'),
  ];

  return (
    <div className={classes.TwoWeeksCalendar} ref={calendarRef}>
      <h2>{today.format('MMMM DD, YYYY')}</h2>
      <div className={classes.header}>
        <button
          type="button"
          onClick={resetCalendar}
          className={classes.todayButton}
        >
          {t('common.today')}
        </button>
        <div className={classes.monthAndButtonsContainer}>
          <span className={classes.month}>
            {t(`common.months.${firstDay?.format('MMMM')?.toLowerCase()}`)}
          </span>
          <div className={classes.buttons}>
            <i
              className={classNames(classes.button, classes.back)}
              onClick={switchToPreviousWeek}
            >
              {t('common.back')}
            </i>
            <i className={classes.button} onClick={switchToNextWeek}>
              {t('common.forward')}
            </i>
          </div>
        </div>
      </div>
      <div className={classes.calendar}>
        <div className={classes.days}>
          {days.map((day) => {
            return (
              <div key={day} className={classes.day}>
                {day}
              </div>
            );
          })}
        </div>
        <div className={classes.dates}>
          {dates.map((date) => {
            const isDifferentMonth = !firstDay.isSame(date, 'month');
            const isToday = moment(today).isSame(date, 'day');
            const hasEvent = events.some((event) =>
              date.isSame(moment(event.eventDate), 'day')
            );
            return (
              <Tooltip
                key={date.format('DD')}
                text={t(
                  'dashboardComponents.Dashboard.RightPanel.TwoWeeksCalendar.eventPlanned'
                )}
                width={120}
                disabled={!hasEvent}
              >
                <div
                  className={classNames(classes.date, {
                    [classes.differentMonth]: isDifferentMonth,
                    [classes.event]: hasEvent,
                  })}
                >
                  {date.format('D')}
                  {isToday && (
                    <div className={classes.today}>{date.format('D')}</div>
                  )}
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
    </div>
  );
}
