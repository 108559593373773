/* eslint-disable no-unreachable */
import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { useMediaQuery } from 'react-responsive';

import createOrUpdateVrQuestions from '../../../../helpers/createOrUpdateVrQuestions';
import convertDropdownValues from '../../../../helpers/convertDropdownValues';
import { UiContext } from '../../../../context/UiContext';
import { JobsContext } from '../../../../context/JobsContext';
import ReviewJobForm from './ReviewJobForm';
import JobFormSteps from './JobFormSteps';
import JobForm from './JobForm';
import Button from '../../../Button';
import PostOptions from './PostOptions';
import classes from './styles.module.scss';
import AdminService from '../../../../services/AdminService';

const vrValidationSchema = yup.object({
  isAssessmentNeeded: yup.boolean(),
  applicationType: yup.string().trim(),
});

const convertDataFieldToDropdownValue = (field, labelFieldName = 'name') => {
  if (!field) {
    return '';
  }
  return { label: field[labelFieldName], value: field.id };
};

export default function PostAJob() {
  const [currentOption, setCurrentOption] = useState('');
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [currentDraftJobPost, setCurrentDraftJobPost] = useState('');
  const [currentActiveJobPost, setCurrentActiveJobPost] = useState('');
  const [isJobFormVisible, setIsJobFormVisible] = useState('');
  const [currentFormStep, setCurrentFormStep] = useState(1);
  const [vrQuestions, setVrQuestions] = useState([]);
  const [useDefaultVrQuestions, setUseDefaultVrQuestions] = useState(false);
  const [isDelegated, setIsDelegated] = useState(true);
  const [isJobPostModalVisible, setIsJobPostModalVisible] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);
  const [isBasicInfoTriedToSubmit, setIsBasicInfoTriedToSubmit] =
    useState(false);
  const [isDelegatesTriedToSubmit, setIsDelegatesTriedToSubmit] =
    useState(false);
  const [isVrAndAssessmentTriedToSubmit, setIsVrAndAssessmentTriedToSubmit] =
    useState(false);
  const [createdJobPost, setCreatedJobPost] = useState(null);
  const [hasDuration, setHasDuration] = useState(true);
  const [assessments, setAssessments] = useState([]);
  const [fields, setFields] = useState([]); // assessment fields

  const navigate = useNavigate();

  const { showUnknownErrorModal, showModal, setIsFetching } =
    useContext(UiContext);
  const { showPostJobSuccessPopup } = useContext(JobsContext);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const areVrQuestionsValid = useRef();
  const areAssessmentsValid = useRef();

  useEffect(() => {
    areVrQuestionsValid.current = true;
    areAssessmentsValid.current = true;
  }, []);

  const { data: allVrQuestions } = useQuery({
    queryKey: ['vrQuestions'],
    queryFn: () => AdminService.getVrQuestions({ pageSize: 999999 }),
  });

  const topQuestions = useMemo(
    () =>
      allVrQuestions?.data
        ?.filter((question) => question.isDefault)
        ?.sort((prev, next) => prev.sortOrder - next.sortOrder)
        ?.slice(0, 5),
    [allVrQuestions]
  );

  useEffect(() => {
    if (useDefaultVrQuestions) {
      setVrQuestions?.(
        topQuestions?.map((question) => ({
          id: question.id,
          savedQuestion: {},
          duration: question.duration,
          title: question.title,
          question: question.question,
        }))
      );
    }
  }, [setVrQuestions, useDefaultVrQuestions, topQuestions]);

  const { data: jobPost, refetch } = useQuery({
    queryKey: [
      'jobPost',
      currentOption,
      currentActiveJobPost,
      currentDraftJobPost,
    ],
    queryFn: () => {
      let jobPostId;

      if (currentOption === 'edit') {
        jobPostId = currentDraftJobPost.value;
      } else if (currentOption === 'reuse') {
        jobPostId = currentActiveJobPost.value;
      }

      return AdminService.getJobPost({ id: jobPostId });
    },
    enabled:
      (currentOption === 'edit' && !!currentDraftJobPost) ||
      (currentOption === 'reuse' && !!currentActiveJobPost),
  });

  useEffect(() => {
    if (currentOption === 'create') {
      setCurrentForm(currentTemplate);
    } else if (currentOption !== 'create' && jobPost) {
      setCurrentForm(jobPost);
    }
  }, [
    jobPost,
    currentOption,
    currentTemplate,
    currentActiveJobPost,
    currentDraftJobPost,
  ]);

  useEffect(() => {
    if (!currentTemplate) {
      setVrQuestions([]);
    }
  }, [currentTemplate]);

  const basicInfoValidationSchema = useMemo(
    () =>
      yup.object({
        jobTitle: yup
          .string()
          .trim()
          .required(
            t(
              'dashboardComponents.Jobs.PostAJob.content.basicInfoFormik.validationErrors.jobTitle'
            )
          ),
        locationCityId: yup
          .object()
          .required(
            t(
              'dashboardComponents.Jobs.PostAJob.content.basicInfoFormik.validationErrors.locationCityId'
            )
          ),
        locationCityDistrictId: yup.object(),
        jobTypeIds: yup
          .array()
          .required(
            t(
              'dashboardComponents.Jobs.PostAJob.content.basicInfoFormik.validationErrors.jobTypeIds'
            )
          ),
        education: yup.object(),
        corporateTitle: yup.string().trim().nullable(),
        jobDescription: yup
          .string()
          .trim()
          .nullable()
          .required(
            t(
              'dashboardComponents.Jobs.PostAJob.content.basicInfoFormik.validationErrors.jobDescription.required'
            )
          )
          .min(
            50,
            t(
              'dashboardComponents.Jobs.PostAJob.content.basicInfoFormik.validationErrors.jobDescription.minLength'
            )
          ),
        locationIds: yup.array(),
        workExperienceId: yup.object(),
        salaryTo: yup
          .number()
          .nullable()
          .min(
            1000,
            t(
              'dashboardComponents.Jobs.PostAJob.content.basicInfoFormik.validationErrors.salaryTo.minValue'
            )
          )
          .moreThan(
            yup.ref('salaryFrom'),
            t(
              'dashboardComponents.Jobs.PostAJob.content.basicInfoFormik.validationErrors.salaryTo.moreThan'
            )
          ),
        salaryFrom: yup
          .number()
          .nullable()
          .min(
            1000,
            t(
              'dashboardComponents.Jobs.PostAJob.content.basicInfoFormik.validationErrors.salaryFrom'
            )
          ),
        note: yup.string().trim().nullable(),
        coverFileName: yup
          .mixed()
          .required(
            t(
              'dashboardComponents.Jobs.PostAJob.content.basicInfoFormik.validationErrors.coverFileName'
            )
          ),
        jobPostVideoId: yup.mixed(),
        skillIds: yup.array(),
        jobPostAttachmentIds: yup.mixed(),
      }),
    [t]
  );

  const basicInfoFormik = useFormik({
    initialValues: {
      locationCityId: !currentForm?.locationCity
        ? ''
        : convertDataFieldToDropdownValue(currentForm.locationCity),
      locationCityDistrictId: !currentForm?.locationCityDistrict
        ? ''
        : convertDataFieldToDropdownValue(currentForm.locationCityDistrict),
      jobTypeIds: !currentForm?.jobTypes
        ? ''
        : currentForm.jobTypes?.map((jobType) =>
            convertDataFieldToDropdownValue(jobType)
          ),
      graduation: !currentForm?.graduation
        ? ''
        : convertDataFieldToDropdownValue(currentForm.graduation),
      corporateTitle: !currentForm?.corporateTitle
        ? ''
        : currentForm.corporateTitle,
      jobDescription: !currentForm?.jobDescription
        ? ''
        : currentForm.jobDescription,
      locationIds: !currentForm?.locations
        ? ''
        : currentForm.locations?.map((loc) =>
            convertDataFieldToDropdownValue(loc)
          ),
      workExperienceId: !currentForm?.workExperience
        ? ''
        : convertDataFieldToDropdownValue(currentForm.workExperience),
      salaryTo: !currentForm?.salaryTo ? '' : currentForm.salaryTo,
      salaryFrom: !currentForm?.salaryFrom ? '' : currentForm.salaryFrom,
      note: !currentForm?.note ? '' : currentForm.note,
      coverFileName: !currentForm?.coverFileName
        ? ''
        : currentForm.coverFileName,
      jobPostVideoId: !currentForm?.jobPostVideo
        ? ''
        : currentForm.jobPostVideo,
      skillIds: !currentForm?.skills
        ? ''
        : currentForm.skills?.map((skill) =>
            convertDataFieldToDropdownValue(skill)
          ),
      jobPostAttachmentIds: !currentForm?.jobPostAttachments
        ? ''
        : currentForm.jobPostAttachments,
      jobTitle: !currentForm?.jobTitle ? '' : currentForm.jobTitle,
    },
    validationSchema: basicInfoValidationSchema,
    onSubmit: () => setCurrentFormStep(2),
    enableReinitialize: true,
  });

  const vrFormik = useFormik({
    initialValues: {
      isAssessmentNeeded: !!currentForm?.assignedAssessments?.length,
      applicationType: !currentForm?.applicationType
        ? 'DocumentOnly'
        : currentForm.applicationType,
      assignedAssessments: currentForm?.assignedAssessments
        ? currentForm.assignedAssessments
        : [],
    },
    validationSchema: vrValidationSchema,
    onSubmit: () => setCurrentFormStep(3),
    enableReinitialize: true,
  });

  if (
    (vrFormik.values.applicationType === 'VideoOnly' ||
      vrFormik.values.applicationType === 'VideoOrDocument' ||
      vrFormik.values.applicationType === 'VideoAndDocument') &&
    !vrQuestions.length
  ) {
    areVrQuestionsValid.current = false;
  } else {
    areVrQuestionsValid.current = true;
  }

  if (
    vrFormik.values.isAssessmentNeeded &&
    assessments.length &&
    assessments.every(
      (assessment) =>
        assessment.limit > 2 &&
        assessment.limit < 10 &&
        (assessment.threshold > 49 || assessment.threshold === null) &&
        (assessment.englishThreshold > 49 ||
          assessment.englishThreshold === null) &&
        (assessment.mentalThreshold > 49 || assessment.mentalThreshold === null)
    )
  ) {
    areAssessmentsValid.current = true;
  } else {
    areAssessmentsValid.current = false;
  }

  if (!assessments.length && !vrFormik.values.isAssessmentNeeded) {
    areAssessmentsValid.current = true;
  }

  const delegateValidationSchema = useMemo(
    () =>
      yup.object({
        isDelegated: yup.boolean(),
        interviewers: yup.array(),
        delegate: yup.object().when('isDelegated', {
          is: true,
          then: yup.object().required(t('common.cannotBeEmpty')),
        }),
        duration: yup.string().trim(),
        startsAt: yup.date().when('isDelegated', {
          is: true,
          then: yup.date().required(t('common.cannotBeEmpty')),
        }),
        endsAt: yup.date().when('isDelegated', {
          is: true,
          then: yup.date().required(t('common.cannotBeEmpty')),
        }),
      }),
    [t]
  );

  const delegatesFormik = useFormik({
    initialValues: {
      isDelegated: !!currentForm?.jobPostDelegates?.length,
      interviewers: !currentForm?.jobPostInterviewers
        ? ''
        : currentForm.jobPostInterviewers?.map((interviewer) =>
            convertDataFieldToDropdownValue(interviewer.user, 'userName')
          ),
      delegate: !currentForm?.jobPostDelegates
        ? ''
        : convertDataFieldToDropdownValue(
            currentForm?.jobPostDelegates?.[0]?.user,
            'userName'
          ),
      duration: '',
      startsAt: '',
      endsAt: '',
    },
    validationSchema: delegateValidationSchema,
    onSubmit: () => setCurrentFormStep(4),
    enableReinitialize: true,
  });

  const showJobForm = () => {
    setIsJobFormVisible(true);
  };

  const resetForm = () => {
    setIsJobFormVisible(false);
    setCurrentForm(null);
    setCurrentOption('');
    basicInfoFormik.resetForm();
    vrFormik.resetForm();
    setIsBasicInfoTriedToSubmit(false);
    setIsDelegated(false);
    setIsDelegatesTriedToSubmit(false);
    setIsVrAndAssessmentTriedToSubmit(false);
    setVrQuestions([]);
    setUseDefaultVrQuestions(false);
    setCurrentFormStep(1);
    setCurrentActiveJobPost(null);
    setCurrentDraftJobPost(null);
    setAssessments([]);
  };

  const createOrUpdateJobPost = async (status = 'Active') => {
    if (!areVrQuestionsValid.current || !areAssessmentsValid.current) {
      return;
    }

    const uploadCoverImage = async (image) => {
      return AdminService.uploadImage(image, 'jobpostcover');
    };

    const uploadJobPostVideo = async (video) => {
      const uploadedVideo = await AdminService.uploadVideo(video);
      return AdminService.createJobPostVideo(uploadedVideo);
    };

    const uploadJobPostAttachment = async (doc) => {
      const uploadedDoc = await AdminService.uploadFile(doc);
      return AdminService.createJobPostAttachment(uploadedDoc);
    };

    try {
      setIsFetching(true);

      const values = {
        ...basicInfoFormik.values,
        ...vrFormik.values,
      };

      if (values.coverFileName && values.coverFileName instanceof File) {
        const image = await uploadCoverImage(values.coverFileName);
        values.coverFileName = image.fileName;
      }

      if (values.jobPostVideoId && values.jobPostVideoId instanceof File) {
        const jobPostVideo = await uploadJobPostVideo(values.jobPostVideoId);
        values.jobPostVideoId = jobPostVideo?.id;
      } else if (values.jobPostVideoId && values.jobPostVideoId.id) {
        values.jobPostVideoId = values.jobPostVideoId.id;
      }

      if (
        values.jobPostAttachmentIds &&
        values.jobPostAttachmentIds instanceof File
      ) {
        const jobPostAttachment = await uploadJobPostAttachment(
          values.jobPostAttachmentIds
        );
        values.jobPostAttachmentIds = [jobPostAttachment?.id];
      } else if (
        values.jobPostAttachmentIds &&
        values.jobPostAttachmentIds.length
      ) {
        values.jobPostAttachmentIds = [values.jobPostAttachmentIds[0]?.id];
      }

      if (vrQuestions.length) {
        const questions = useDefaultVrQuestions
          ? vrQuestions.map((quest) => quest.id)
          : await createOrUpdateVrQuestions(vrQuestions);
        values.videoResumeQuestionIds = questions;
      }

      const newJobPost =
        currentOption === 'edit'
          ? await AdminService.updateJobPost({
              ...convertDropdownValues(values),
              status: 'Active',
              id: currentDraftJobPost.value,
              graduationId: values.graduation.value,
              assignedAssessments: assessments?.map((assessment, index) => ({
                tenantAssessmentProviderId:
                  assessment.tenantAssessmentProviderId,
                assessmentProviderKey: assessment.assessmentProviderKey,
                assessmentId: assessment.assessmentId,
                threshold: `${assessment.threshold}`,
                englishThreshold: `${assessment.englishThreshold}`,
                mentalThreshold: `${assessment.mentalThreshold}`,
                limit: assessment.limit,
                sortOrder: index + 1,
              })),
            })
          : await AdminService.createJobPost({
              ...convertDropdownValues(values),
              graduationId: values.graduation.value,
              status,
              assignedAssessments: assessments?.map((assessment, index) => ({
                tenantAssessmentProviderId:
                  assessment.tenantAssessmentProviderId,
                assessmentProviderKey: assessment.assessmentProviderKey,
                assessmentId: assessment.assessmentId,
                threshold: `${assessment.threshold}`,
                englishThreshold: `${assessment.englishThreshold}`,
                mentalThreshold: `${assessment.mentalThreshold}`,
                limit: assessment.limit,
                sortOrder: index + 1,
              })),
            });

      setCreatedJobPost(newJobPost);

      if (delegatesFormik.values.delegate) {
        const delegate = await AdminService.createJobPostDelegate({
          userId: delegatesFormik.values.delegate?.value,
          startsAt: delegatesFormik.values.startsAt,
          endsAt: delegatesFormik.values.endsAt,
          jobPostId: newJobPost.id,
        });

        const updatedJobPost = await AdminService.updateJobPost({
          ...convertDropdownValues(values),
          status,
          id: newJobPost.id,
          jobPostDelegateIds: [delegate.id],
        });

        setCreatedJobPost(updatedJobPost);
      }

      if (delegatesFormik.values.interviewers) {
        const promises = delegatesFormik.values.interviewers.map(
          (interviewer) =>
            AdminService.createJobPostInterviewer({
              userId: interviewer.value,
              jobPostId: newJobPost.id,
            })
        );

        const interviewers = await Promise.all(promises);

        const updatedJobPost = await AdminService.updateJobPost({
          ...convertDropdownValues(values),
          status,
          id: newJobPost.id,
          jobPostInterviewerIds: interviewers.map(
            (interviewer) => interviewer.id
          ),
        });

        setCreatedJobPost(updatedJobPost);
        refetch();
      }

      if (status === 'Active') {
        setIsJobPostModalVisible(true);
      } else {
        showModal({
          title: t('dashboardComponents.Jobs.PostAJob.modal.success.title'),
          text: t('dashboardComponents.Jobs.PostAJob.modal.success.text'),
        });
      }
      resetForm();
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsFetching(false);
    }
  };

  const showSaveDraftModal = (shouldCloseForm) => {
    showModal({
      icon: 'error',
      title: t('dashboardComponents.Jobs.PostAJob.modal.exitScreen.title'),
      text: t('dashboardComponents.Jobs.PostAJob.modal.exitScreen.text'),
      dismissButtonLabel: t('common.no'),
      onCancel: resetForm,
      onConfirm: () => {
        createOrUpdateJobPost('Draft');
        if (shouldCloseForm) {
          setIsJobFormVisible(false);
        }
      },
    });
  };

  const handleBackButtonPress = () => {
    if (currentFormStep === 1) {
      showSaveDraftModal();
    } else {
      setCurrentFormStep((prevStep) => prevStep - 1);
    }
  };

  const closeForm = () => {
    showSaveDraftModal(true);
  };

  const isNextButtonDisabled =
    (currentOption === 'create' && currentTemplate === null) ||
    (currentOption === 'edit' && !currentDraftJobPost) ||
    (currentOption === 'reuse' && !currentActiveJobPost) ||
    !currentOption;

  let handleNextButtonClick = showJobForm;

  if (isJobFormVisible) {
    if (currentFormStep === 1) {
      handleNextButtonClick = () => {
        setIsBasicInfoTriedToSubmit(true);
        basicInfoFormik.submitForm();
      };
    } else if (currentFormStep === 2) {
      handleNextButtonClick = () => {
        setIsVrAndAssessmentTriedToSubmit(true);
        if (areVrQuestionsValid.current && areAssessmentsValid.current) {
          vrFormik.submitForm();
        }
      };
    } else if (currentFormStep === 3) {
      handleNextButtonClick = () => {
        setIsDelegatesTriedToSubmit(true);
        delegatesFormik.submitForm();
      };
    } else if (currentFormStep === 4) {
      handleNextButtonClick = () => createOrUpdateJobPost();
    }
  }

  useEffect(() => {
    if (currentForm) {
      setVrQuestions(
        currentForm?.videoResumeQuestions?.map((qstn) => ({
          id: Math.random(),
          savedQuestion: {
            label: qstn.title,
            value: qstn.id,
            duration: qstn.duration,
            question: qstn.question,
          },
        }))
      );
    }
  }, [currentForm]);

  const finishPostingJob = () => {
    showPostJobSuccessPopup(createdJobPost);
    navigate(`/tools/jobs/my-jobs/${createdJobPost?.id}`);
  };

  let content = (
    <PostOptions
      setCurrentActiveJobPost={setCurrentActiveJobPost}
      currentActiveJobPost={currentActiveJobPost}
      setCurrentDraftJobPost={setCurrentDraftJobPost}
      currentDraftJobPost={currentDraftJobPost}
      currentOption={currentOption}
      setCurrentOption={setCurrentOption}
      currentTemplate={currentTemplate}
      setCurrentTemplate={setCurrentTemplate}
    />
  );

  if (isJobFormVisible) {
    if (currentFormStep <= 3) {
      content = (
        <JobForm
          currentFormStep={currentFormStep}
          basicInfoFormik={basicInfoFormik}
          vrFormik={vrFormik}
          delegatesFormik={delegatesFormik}
          vrQuestions={vrQuestions}
          setVrQuestions={setVrQuestions}
          useDefaultVrQuestions={useDefaultVrQuestions}
          setUseDefaultVrQuestions={setUseDefaultVrQuestions}
          isDelegated={isDelegated}
          setIsDelegated={setIsDelegated}
          isBasicInfoTriedToSubmit={isBasicInfoTriedToSubmit}
          isDelegatesTriedToSubmit={isDelegatesTriedToSubmit}
          hasDuration={hasDuration}
          setHasDuration={setHasDuration}
          isVrAndAssessmentTriedToSubmit={isVrAndAssessmentTriedToSubmit}
          areVrQuestionsValid={areVrQuestionsValid}
          assessments={assessments}
          setAssessments={setAssessments}
          fields={fields}
          setFields={setFields}
        />
      );
    } else {
      content = (
        <ReviewJobForm
          basicInfoFormik={basicInfoFormik}
          vrFormik={vrFormik}
          delegatesFormik={delegatesFormik}
          isDelegated={isDelegated}
          setIsDelegated={setIsDelegated}
          vrQuestions={vrQuestions}
          setVrQuestions={setVrQuestions}
          useDefaultVrQuestions={useDefaultVrQuestions}
          setUseDefaultVrQuestions={setUseDefaultVrQuestions}
          hasDuration={hasDuration}
          setHasDuration={setHasDuration}
          areVrQuestionsValid={areVrQuestionsValid}
          assessments={assessments}
          setAssessments={setAssessments}
          fields={fields}
          setFields={setFields}
        />
      );
    }
  }

  return (
    <div
      className={classNames(classes.PostAJob, {
        [classes.formVisible]: isJobFormVisible,
      })}
    >
      <button
        className={classes.backButton}
        type="button"
        onClick={() => navigate('/tools/jobs/all-jobs')}
      >
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.57 6.42969L3.5 12.4997L9.57 18.5697"
            stroke="#292D32"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.4999 12.5H3.66992"
            stroke="#292D32"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Back to Job List
      </button>
      {isJobFormVisible ? (
        <JobFormSteps currentFormStep={currentFormStep} closeForm={closeForm} />
      ) : (
        <header className={classes.header}>
          <div className={classes.container}>
            <h1>{t('dashboardComponents.Jobs.PostAJob.header.title')}</h1>
            <p>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.
            </p>
          </div>
        </header>
      )}
      <div
        className={classNames(classes.content, {
          [classes.lastStep]: currentFormStep === 4,
        })}
      >
        {content}
      </div>
      <footer className={classes.footer}>
        <div className={classes.container}>
          {isJobFormVisible && (
            <Button
              width={isTabletOrMobile ? '100%' : 250}
              height={isTabletOrMobile ? 45 : 55}
              variant="secondary"
              onClick={handleBackButtonPress}
            >
              {t('dashboardComponents.Jobs.PostAJob.footer.goBackButton')}
            </Button>
          )}
          {currentFormStep === 4 && (
            <Button
              variant="black"
              height={isTabletOrMobile ? 45 : 55}
              width={isTabletOrMobile ? '100%' : 250}
              style={{ marginLeft: 'auto', marginRight: 55 }}
              onClick={() => createOrUpdateJobPost('Draft')}
            >
              {t('dashboardComponents.Jobs.PostAJob.footer.saveDraftButton')}
            </Button>
          )}
          <Button
            width={isTabletOrMobile ? '100%' : 250}
            height={isTabletOrMobile ? 45 : 55}
            disabled={isNextButtonDisabled}
            onClick={handleNextButtonClick}
          >
            {currentFormStep === 4
              ? t('dashboardComponents.Jobs.PostAJob.footer.publishButton')
              : t('common.next')}
          </Button>
        </div>
      </footer>
      {isJobPostModalVisible && finishPostingJob()}
    </div>
  );
}
