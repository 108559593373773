import React from 'react';

import { useTranslation } from 'react-i18next';

import noContacts from '../../../../../../../assets/images/chat/no-contacts.svg';
import classes from './styles.module.scss';

export default function NoContacts({ type }) {
  const { t } = useTranslation();

  return (
    <div className={classes.NoContacts}>
      {type === 'pinned' ? (
        <p className={classes.message}>
          {t(
            'dashboardComponents.Chat.LeftPanel.Contacts.ContactsList.NoContacts.message'
          )}
        </p>
      ) : (
        <>
          <p className={classes.message}>
            {t(
              'dashboardComponents.Chat.LeftPanel.Contacts.ContactsList.NoContacts.message'
            )}
          </p>
          <img src={noContacts} alt="No contacts" />
        </>
      )}
    </div>
  );
}
