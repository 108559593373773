import React, { useState } from 'react';
import classNames from 'classnames';
import { mapReport } from '../../../../../../../helpers/mapReports';
import classes from './styles.module.scss';

export default function Column({ column, deleteColumn, isMandatory }) {
  const [isSelected, setIsSelected] = useState(false);
  return (
    <div
      className={classNames(classes.Column, {
        [classes.mandatory]: isMandatory,
      })}
      onClick={() => setIsSelected(!isSelected)}
    >
      <div
        className={classes.drag}
        onClick={(event) => event.stopPropagation()}
      />
      <span>{mapReport(column)}</span>
      <span
        className={classes.delete}
        onClick={(event) => {
          event.stopPropagation();

          if (isMandatory) {
            return;
          }

          deleteColumn();
        }}
      />
    </div>
  );
}
