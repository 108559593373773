/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useContext } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Note from '../Candidates/CandidateCard/Note';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';
import { JobsContext } from '../../../../context/JobsContext';
import { CandidatesContext } from '../../../../context/CandidatesContext';

export default function DotMenu({
  options,
  width,
  favCandidate,
  setIsActive,
  refetch,
  note,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [isEditingNote, setIsEditingNote] = useState(false);

  const { showShareModal } = useContext(JobsContext);
  const { showInviteToJobModal } = useContext(CandidatesContext);

  const dropdownRef = useRef();

  const { t } = useTranslation();

  useOnClickOutside(dropdownRef, () => {
    setIsOptionsListVisible(false);
    setIsAddingNote(false);
    setIsEditingNote(false);
  });

  const openEmail = () => {
    window.open(`mailto:${favCandidate?.user.userName}`);
  };

  if (isOptionsListVisible) {
    setIsActive(true);
  } else {
    setIsActive(false);
  }

  return (
    <div
      ref={dropdownRef}
      className={classNames(classes.dotMenu, {
        [classes.active]: isOptionsListVisible,
      })}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={classes.selector}
        onClick={(e) => {
          e.stopPropagation();
          setIsOptionsListVisible((prevState) => !prevState);
          setIsAddingNote(false);
          setIsEditingNote(false);
        }}
      >
        ...
      </div>
      {isOptionsListVisible && (
        <>
          <div
            className={classNames(classes.options, {
              [classes.smallOptions]: !options.includes(
                t('dashboardComponents.Favorites.DotMenu.inviteToJob')
              ),
            })}
            style={{ width }}
          >
            <ul>
              {options.map((option) => {
                switch (option) {
                  case t('dashboardComponents.Favorites.DotMenu.share'):
                    return (
                      <li
                        key={option}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsOptionsListVisible(false);
                          showShareModal({ favCandidate });
                        }}
                      >
                        {option}
                      </li>
                    );
                  case t(
                    'dashboardComponents.Favorites.DotMenu.emailCandidate'
                  ):
                    return (
                      <li
                        key={option}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsOptionsListVisible(false);
                          openEmail();
                        }}
                      >
                        {option}
                      </li>
                    );
                  case t('dashboardComponents.Favorites.DotMenu.addNote'):
                    return (
                      <li
                        key={option}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsAddingNote(true);
                        }}
                      >
                        {option}
                      </li>
                    );
                  case t('dashboardComponents.Favorites.DotMenu.editNote'):
                    return (
                      <li
                        key={option}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsEditingNote(true);
                        }}
                      >
                        {option}
                      </li>
                    );
                  case t('dashboardComponents.Favorites.DotMenu.inviteToJob'):
                    return (
                      <li
                        key={option}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsOptionsListVisible(false);
                          showInviteToJobModal(favCandidate);
                        }}
                      >
                        {option}
                      </li>
                    );
                  default:
                    return (
                      <li
                        key={option}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsOptionsListVisible(false);
                        }}
                      >
                        {option}
                      </li>
                    );
                }
              })}
            </ul>
          </div>
          {isAddingNote && (
            <Note
              isAddingNote={isAddingNote}
              setIsAddingNote={setIsAddingNote}
              favCandidate={favCandidate}
              refetch={refetch}
              handleClose={() => setIsOptionsListVisible(false)}
            />
          )}
          {isEditingNote && (
            <Note
              isEditingNote={isEditingNote}
              favCandidate={favCandidate}
              setIsEditingNote={setIsEditingNote}
              refetch={refetch}
              note={note}
              handleClose={() => setIsOptionsListVisible(false)}
            />
          )}
        </>
      )}
    </div>
  );
}
