import React from 'react';
import classNames from 'classnames';
import { mapReport } from '../../../../../../helpers/mapReports';
import classes from './styles.module.scss';

export default function Thead({
  reports,
  sortOrder,
  setSortOrder,
  activeSortHeader,
  setActiveSortHeader,
  print,
}) {
  const switchSortOrder = () => {
    if (sortOrder === 'asc') {
      setSortOrder('desc');
    } else {
      setSortOrder('asc');
    }
  };

  const setSortHeader = (sortHeader) => {
    if (activeSortHeader !== sortHeader) {
      setSortOrder('asc');
      setActiveSortHeader(sortHeader);

      return;
    }
    switchSortOrder();
  };

  return (
    <thead className={classes.Thead}>
      <tr>
        {reports?.map((header) => (
          <th
            className={classNames({
              [classes.active]: activeSortHeader === header && !print,
            })}
            onClick={() => setSortHeader(header)}
            key={header}
          >
            <div className={classes.thContainer}>
              {mapReport(header)}
              <div
                className={classes.sortContainer}
                style={{ display: print ? 'none' : '' }}
              >
                <span
                  className={classNames(classes.sortUp, {
                    [classes.sortActive]:
                      activeSortHeader === header && sortOrder === 'asc',
                  })}
                />
                <span
                  className={classNames(classes.sortDown, {
                    [classes.sortActive]:
                      activeSortHeader === header && sortOrder === 'desc',
                  })}
                />
              </div>
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
}
