import React, { useContext } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { CandidatesContext } from '../../../../context/CandidatesContext';
import classes from './styles.module.scss';

export default function InviteCandidateButton({ candidateId }) {
  const { showInviteToJobModal } = useContext(CandidatesContext);

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const data = queryClient.getQueryData(['candidates']);

  const candidate = data?.find((cnd) => cnd.id === candidateId);

  return (
    <button
      className={classes.InviteCandidateButton}
      type="button"
      onClick={(event) => {
        event.stopPropagation();
        showInviteToJobModal(candidate);
      }}
    >
      {t('common.invite')}
    </button>
  );
}
