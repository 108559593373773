import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Partners from './Partners';
import JobTemplates from './JobTemplates';
import VrQuestions from './VrQuestions';
import Assessments from './Assessments';
import Tabs from '../Tabs';

import classes from './styles.module.scss';

export default function JobManagement() {
  const [assessmentCount, setAssessmentCount] = useState(0);
  const [vrQuestionCount, setVrQuestionCount] = useState(0);
  const [jobTemplateCount, setJobTemplateCount] = useState(0);
  const [partnerCount, setPartnerCount] = useState(0);

  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(
    t('dashboardComponents.Console.JobManagement.JobTemplate')
  );

  const tabs = [
    t('dashboardComponents.Console.JobManagement.JobTemplate'),
    t('dashboardComponents.Console.JobManagement.VRQuestions'),
    t('dashboardComponents.Console.JobManagement.partners'),
    t('dashboardComponents.Console.JobManagement.Assessment'),
  ];

  return (
    <div className={classes.JobManagement}>
      <Tabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        name="job"
        tabs={tabs}
        countData={{
          [t('dashboardComponents.Console.JobManagement.JobTemplate')]:
            jobTemplateCount,
          [t('dashboardComponents.Console.JobManagement.VRQuestions')]:
            vrQuestionCount,
          [t('dashboardComponents.Console.JobManagement.Assessment')]:
            assessmentCount,
          [t('dashboardComponents.Console.JobManagement.partners')]:
            partnerCount,
        }}
      />

      <div className={classes.container}>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.JobManagement.Assessment')
                ? 'block'
                : 'none',
          }}
        >
          <Assessments setItemCount={setAssessmentCount} />
        </div>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.JobManagement.VRQuestions')
                ? 'block'
                : 'none',
          }}
        >
          <VrQuestions setItemCount={setVrQuestionCount} />
        </div>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.JobManagement.partners')
                ? 'block'
                : 'none',
          }}
        >
          <Partners setItemCount={setPartnerCount} />
        </div>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.JobManagement.JobTemplate')
                ? 'block'
                : 'none',
          }}
        >
          <JobTemplates setItemCount={setJobTemplateCount} />
        </div>
      </div>
    </div>
  );
}
