/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useContext } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import { JobsContext } from '../../../../../context/JobsContext';
import classes from './styles.module.scss';
import { UiContext } from '../../../../../context/UiContext';

export default function DotMenu({ width, candidate, job, event }) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const { showShareModal } = useContext(JobsContext);

  const dropdownRef = useRef();

  const { t } = useTranslation();

  const options = [
    t('dashboardComponents.Header.Search.DotMenu.View in new tab'),
    t('dashboardComponents.Header.Search.DotMenu.Copy link'),
    t('dashboardComponents.Header.Search.DotMenu.Share'),
  ];

  const { showNotification } = useContext(UiContext);

  useOnClickOutside(dropdownRef, () => {
    setIsOptionsListVisible(false);
  });

  const copyLink = () => {
    let link;
    let text;

    const url = `${window.location.protocol}//${window.location.host}`;

    if (candidate) {
      text = t(
        'dashboardComponents.Header.Search.DotMenu.Candidate link copied to clipboard'
      );
      link = `${url}/tools/candidates?candidate=${candidate?.userId}`;
    }

    if (job) {
      text = t(
        'dashboardComponents.Header.Search.DotMenu.Job post link copied to clipboard'
      );
      link = `${url}/tools/jobs/my-jobs/${job?.jobPostId}`;
    }

    if (event) {
      text = t(
        'dashboardComponents.Header.Search.DotMenu.Calendar event link copied to clipboard'
      );
      link = `${url}/tools/calendar?event=${event?.type}&id=${event?.id}`;
    }

    navigator.clipboard.writeText(link);
    showNotification({
      text,
    });
    setIsOptionsListVisible(false);
  };

  return (
    <div
      ref={dropdownRef}
      className={classNames(classes.dotMenu, {
        [classes.active]: isOptionsListVisible,
      })}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div
        className={classes.selector}
        onClick={() => {
          setIsOptionsListVisible((prevState) => !prevState);
        }}
      >
        ...
      </div>
      {isOptionsListVisible && (
        <>
          <div className={classes.options} style={{ width }}>
            <ul>
              {options.map((option) => {
                switch (option) {
                  case t('dashboardComponents.Header.Search.Share'):
                    return (
                      <li
                        key={option}
                        onClick={() => {
                          setIsOptionsListVisible(false);
                          showShareModal({ candidate, job, event });
                        }}
                      >
                        {option}
                      </li>
                    );

                  case t('dashboardComponents.Header.Search.DotMenu.Copy link'):
                    return (
                      <li key={option} onClick={copyLink}>
                        {option}
                      </li>
                    );
                  default:
                    return (
                      <li
                        key={option}
                        onClick={() => {
                          setIsOptionsListVisible(false);
                        }}
                      >
                        {option}
                      </li>
                    );
                }
              })}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
