import AdminService from '../services/AdminService';

export default async function createOrUpdateVrQuestions(vrQuestions) {
  try {
    const questionsToCreate = [];
    const questionsToUpdate = [];
    const existingQuestions = [];

    vrQuestions.forEach((question, index) => {
      if (question.savedQuestion.value) {
        if (
          question.savedQuestion.question === question.question &&
          question.savedQuestion.duration === question.duration
        ) {
          existingQuestions.push({
            value: question.savedQuestion.value,
            sortOrder: index,
          });
        } else {
          questionsToUpdate.push({ ...question, sortOrder: index });
        }
      } else {
        questionsToCreate.push({ ...question, sortOrder: index });
      }
    });

    const questionsToCreatePromises = questionsToCreate.map((qstn) =>
      AdminService.createVrQuestion({
        title: qstn.title,
        question: qstn.question,
        duration: qstn.duration,
        sortOrder: qstn.sortOrder,
      })
    );

    const questionsToUpdatePromises = questionsToUpdate.map((qstn) =>
      AdminService.updateVrQuestion({
        title: qstn.savedQuestion.label,
        question: qstn.question,
        duration: qstn.duration,
        id: qstn.savedQuestion.value,
        sortOrder: qstn.sortOrder,
      })
    );

    const createdQuestions = await Promise.all(questionsToCreatePromises);
    const updatedQuestions = await Promise.all(questionsToUpdatePromises);

    const questionsIds = [
      ...createdQuestions.map((qstn) => qstn.id),
      ...updatedQuestions.map((qstn) => qstn.id),
      ...existingQuestions.map((qstn) => qstn.value),
    ];

    return questionsIds;
  } catch (error) {
    console.log(error);
    return null;
  }
}
