import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import classes from './styles.module.scss';

export default function AssessmentInput({
  label,
  type,
  assessment,
  setThreshold,
  name,
  value,
  isForm,
  touched,
  jobTemplate,
}) {
  const { t } = useTranslation();

  const changeInputValue = (event) => {
    let val = event.target.value.replace(/\D/g, '');
    val = parseInt(val, 10) || 0;
    if (type === 'percents') {
      if (val > 100) {
        val = 100;
      }
    }

    if (type === 'limit') {
      if (val > 99) {
        val = 99;
      }

      if (val < 1) {
        val = 1;
      }
    }

    setThreshold(assessment.assessmentId, name, val);
  };

  const increaseValue = () => {
    let newValue = +value + 1;

    if (type === 'percents' && newValue > 100) {
      newValue = 100;
    }

    setThreshold(assessment.assessmentId, name, newValue);
  };

  const decreaseValue = () => {
    let newValue = +value - 1;

    if (type === 'limit' && newValue < 1) {
      newValue = 1;
    }

    setThreshold(assessment.assessmentId, name, newValue);
  };

  let warning = '';

  if (type !== 'limit' && +value < 80) {
    warning = t(
      'modals.AssessmentModal.selectedAssessments.assessment.assessmentInput.warningThresholdLow'
    );
  }

  if (type !== 'limit' && value < 80 && value > 49) {
    warning = t(
      'modals.AssessmentModal.selectedAssessments.assessment.assessmentInput.warningThresholdNotOptimal'
    );
  }

  if (type === 'limit' && value <= 2) {
    warning = t(
      'modals.AssessmentModal.selectedAssessments.assessment.assessmentInput.warningLimitLow'
    );
  }

  if (type === 'limit' && value >= 10) {
    warning = t(
      'modals.AssessmentModal.selectedAssessments.assessment.assessmentInput.warningLimitHigh'
    );
  }

  return (
    <div
      className={classNames(classes.AssessmentInput, {
        [classes.isForm]: isForm,
        [classes.jobTemplate]: jobTemplate,
      })}
    >
      <span className={classes.label}>{label}</span>
      <div className={classes.inputContainer}>
        <span className={classes.button} onClick={decreaseValue}>
          -
        </span>
        <span className={classes.value}>
          <input
            type="text"
            name={name}
            className={classNames(
              {
                [classes.amber]:
                  type === 'percents' && value >= 50 && value < 80,
              },
              {
                [classes.red]:
                  (type === 'percents' && value < 50) ||
                  (type === 'limit' && (value >= 10 || value <= 2)),
              }
            )}
            value={type === 'percents' ? `${parseInt(value, 10)}%` : value}
            onChange={changeInputValue}
            maxLength={4}
          />
        </span>
        <span className={classes.button} onClick={increaseValue}>
          +
        </span>
      </div>
      <span
        className={classNames(classes.warning, {
          [classes.red]: value <= 49 && type !== 'limit',
        })}
      >
        {touched && warning}
      </span>
    </div>
  );
}
