import React from 'react';

import { useMediaQuery } from 'react-responsive';

import TypeSwitch from '../../CalendarMenu/TypeSwitch';
import Group from './Group';

import classes from './styles.module.scss';

export default function EventGroups({
  events,
  refetch,
  user,
  timezone,
  setTimezone,
  activeType,
  setActiveType,
}) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const eventGroups = [
    {
      group: 'Today',
      amount: events.eventsToday.length,
      events: [...events.eventsToday],
    },
    {
      group: 'This Week',
      amount: events.eventsInWeek.length,
      events: [...events.eventsInWeek],
    },
    {
      group: 'This Month',
      amount: events.eventsInMonth.length,
      events: [...events.eventsInMonth],
    },
  ];

  return (
    <div className={classes.groups}>
      {eventGroups.map((group) => (
        <Group
          group={group}
          key={group.group}
          events={group.events}
          refetch={refetch}
          user={user}
        />
      ))}
      {isTabletOrMobile && (
        <TypeSwitch
          events={events}
          activeType={activeType}
          setActiveType={setActiveType}
          timezone={timezone}
          setTimezone={setTimezone}
        />
      )}
    </div>
  );
}
