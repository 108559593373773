import React from 'react';

// eslint-disable-next-line import/no-named-default
import { default as CalendarLib } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import './calendar.scss';
import classes from './styles.module.scss';

const tileDisabled = ({ date }) => {
  return date < new Date();
};

const tileDisableCurrentAndFutureDates = ({ date }) => {
  const today = new Date();
  // Set the time to the start of the day (00:00:00.000)
  today.setHours(0, 0, 0, 0);

  // Disable if the date is today or in the future
  return date >= today;
};

export default function Calendar({
  calendarRef,
  value,
  setValue,
  selectRange,
  onlyPastDates,
}) {
  return (
    <div className={classes.Calendar} ref={calendarRef}>
      <CalendarLib
        className="calendarComponent"
        selectRange={selectRange}
        formatShortWeekday={(_locale, date) =>
          ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][date.getDay()]
        }
        value={value}
        onChange={setValue}
        tileDisabled={
          onlyPastDates ? tileDisableCurrentAndFutureDates : tileDisabled
        }
      />
    </div>
  );
}
