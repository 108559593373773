import React, { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import FormDropdown from '../../../../../../../Form/FormDropdown';

import classes from './styles.module.scss';
import AdminService from '../../../../../../../../services/AdminService';
import CandidatesService from '../../../../../../../../services/CandidatesService';

const defaultOptions = [
  {
    label: 'New',
    value: 'New',
  },
  {
    label: 'Interview',
    value: 'Interview',
  },
  {
    label: 'Offer',
    value: 'Offer',
  },
];

const screeningOption = {
  label: 'Screening',
  value: 'Screen',
};

export default function SubmitToJob({ isReadOnly, formik, touched }) {
  const { data: jobPost } = useQuery({
    queryKey: ['jobPost', formik.values.jobPostId?.value],
    queryFn: () => CandidatesService.getJobPost(formik.values.jobPostId?.value),
    enabled: !!formik.values.jobPostId?.value,
  });

  const jobHasScreening = !!jobPost?.assignedAssessments?.length;

  useEffect(() => {
    if (
      !jobHasScreening &&
      formik.values.applicationStatus?.value === 'Screen'
    ) {
      formik.setFieldValue('applicationStatus', defaultOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobHasScreening, formik.values.applicationStatus?.value]);

  // Set applicationSubStatus when jobPostId and applicationStatus are set
  useEffect(() => {
    if (formik.values.jobPostId && formik.values.applicationStatus) {
      formik.setFieldValue('applicationSubStatus', 'Pending');
    } else {
      formik.setFieldValue('applicationSubStatus', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.jobPostId, formik.values.applicationStatus]);

  console.log(formik.errors);

  const options = jobHasScreening
    ? [defaultOptions[0], screeningOption, ...defaultOptions.slice(1)]
    : defaultOptions;

  return (
    <div className={classes.SubmitToJob}>
      <FormDropdown
        style={{
          backgroundColor: 'white',
        }}
        options={[]}
        value={formik.values.jobPostId}
        placeholder="Select a Job to submit this candidate for"
        width=""
        height=""
        label="Submit to Job"
        error={formik.errors.jobPostId}
        touched={touched}
        searchInfo=""
        searchInfoWidth=""
        name="jobPostId"
        setFieldValue={formik.setFieldValue}
        setFieldTouched={formik.setFieldTouched}
        maxOptionsHeight=""
        info=""
        note=""
        infoWidth=""
        readOnly={isReadOnly}
        labelStyle={{}}
        fetchOptions={{
          dataName: 'myJobs',
          getDataHandler: AdminService.getMyJobs,
          labelName: 'jobTitle',
        }}
      />
      <FormDropdown
        style={{
          backgroundColor: 'white',
        }}
        options={options}
        value={formik.values.applicationStatus}
        placeholder="Select the Stage to move the candidate"
        width=""
        height=""
        label="Submit to Stage"
        error={formik.errors.applicationStatus}
        touched={touched}
        searchInfo=""
        searchInfoWidth=""
        name="applicationStatus"
        setFieldValue={formik.setFieldValue}
        setFieldTouched={formik.setFieldTouched}
        maxOptionsHeight=""
        info=""
        note=""
        infoWidth=""
        readOnly={isReadOnly}
      />
    </div>
  );
}
