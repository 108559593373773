import React from 'react';

import striptags from 'striptags';
import { useTranslation } from 'react-i18next';

import PostMenu from '../Header/PostMenu';
import PostStats from '../PostStats';

import classes from './styles.module.scss';

export default function CollapsedPostContent({
  content,
  expandPost,
  feedPost,
  refetch,
  setEditingPost,
  role,
}) {
  const { t } = useTranslation();

  const strippedContent = striptags(content?.replaceAll('&nbsp;', ''));

  const postType = feedPost?.systemAnnouncementType || feedPost?.type;

  let postContent = (
    <p>
      {strippedContent.slice(0, 75)}
      {strippedContent.length > 75 ? '...' : ''}
    </p>
  );

  if (postType === 'Appreciation') {
    postContent = (
      <p>
        <h3>
          {t(
            'dashboardComponents.Discover.Feed.Post.CollapsedPostContent.appreciation'
          )}
        </h3>
      </p>
    );
  } else if (postType === 'NewHire') {
    postContent = (
      <p>
        <span className={classes.bold}>
          {feedPost?.jobApplication?.jobSeeker?.fullName}
        </span>{' '}
        {t(
          'dashboardComponents.Discover.Feed.Post.CollapsedPostContent.newHireContent'
        )}{' '}
        <span className={classes.bold}>
          {feedPost?.jobApplication?.jobTitle}
        </span>
        .
      </p>
    );
  } else if (postType === 'NewRegistration') {
    postContent = (
      <p>
        {t(
          'dashboardComponents.Discover.Feed.Post.CollapsedPostContent.newRegistrationContent'
        )}{' '}
        <span className={classes.bold}>
          {feedPost?.registeredUser?.fullName}
        </span>{' '}
        {t(
          'dashboardComponents.Discover.Feed.Post.CollapsedPostContent.toTheHiringPortal'
        )}
      </p>
    );
  } else if (postType === 'Announcement') {
    postContent = (
      <p>
        <h3>
          {t(
            'dashboardComponents.Discover.Feed.Post.CollapsedPostContent.announcement'
          )}
        </h3>
      </p>
    );
  } else if (postType === 'FiveStartFeedback') {
    postContent = (
      <p>
        <h3>
          {t(
            'dashboardComponents.Discover.Feed.Post.CollapsedPostContent.fiveStarFeedback'
          )}
        </h3>
      </p>
    );
  } else if (postType === 'MilestoneNewHire') {
    postContent = (
      <p>
        <h3>
          {t(
            'dashboardComponents.Discover.Feed.Post.CollapsedPostContent.milestoneNewHire'
          )}
        </h3>
      </p>
    );
  }

  return (
    <div className={classes.CollapsedPostContent}>
      <div className={classes.container}>
        <div className={classes.textContainer}>
          {postContent}
          <button
            type="button"
            className={classes.expandButton}
            onClick={expandPost}
          >
            {t(
              'dashboardComponents.Discover.Feed.Post.CollapsedPostContent.expand'
            )}
          </button>
        </div>
        <PostMenu
          feedPost={feedPost}
          refetch={refetch}
          setEditingPost={setEditingPost}
          role={role}
        />
      </div>
      <PostStats collapsed feedPost={feedPost} />
    </div>
  );
}
