import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { format } from 'timeago.js';

import { JobsContext } from '../../../../../context/JobsContext';
import { getTimezoneDate } from '../../../../../helpers/timezones';

export default function Default({
  classes,
  hideNotifications,
  notification,
  deleteNotification,
  refetch,
  isRead,
  userTimezone,
}) {
  const {
    showScheduleInterviewModal,
    showSetOnboardingModal,
    showSetJoiningModal,
  } = useContext(JobsContext);

  const { t } = useTranslation();

  const showModal = () => {
    hideNotifications();
    let showModalHandler = () => {};

    switch (true) {
      case notification.eventNote.includes('onboarding'):
        showModalHandler = showSetOnboardingModal;
        break;
      case notification.eventNote.includes('interview'):
        showModalHandler = showScheduleInterviewModal;
        break;
      case notification.eventNote.includes('joining'):
        showModalHandler = showSetJoiningModal;
        break;
      default:
    }

    showModalHandler({
      candidate: notification.jobApplication,
      refetch,
    });
  };

  return (
    <div
      className={classNames(classes.Notification, {
        [classes.read]: isRead,
      })}
      onClick={showModal}
    >
      {isRead ? (
        <i
          className={classNames(classes.icon, classes.delete)}
          onClick={deleteNotification}
        >
          Delete
        </i>
      ) : (
        <i className={classes.icon}>Unread</i>
      )}
      <h4>
        {notification.eventNote} ({notification.jobApplication?.jobPostId})
      </h4>
      <div className={classes.content}>
        <span className={classes.text}>
          {notification.jobApplication?.user?.userProfile?.firstName}{' '}
          {notification.jobApplication?.user?.userProfile?.lastName}
          {notification.jobApplication?.user?.userProfile?.firstName ||
          notification.jobApplication?.user?.userProfile?.lastName
            ? '.'
            : null}
        </span>{' '}
        <span className={classes.timeAgo}>
          {format(getTimezoneDate(notification.createdAt, userTimezone)[0])}
        </span>
      </div>
      {(notification.buttonLabel ||
        notification.secondaryButtonLabel ||
        notification.withSnoozeButton) && (
        <div className={classes.buttons}>
          {notification.buttonLabel && (
            <button className={classes.mainButton} type="button">
              {notification.buttonLabel}
            </button>
          )}
          {notification.secondaryButtonLabel && (
            <button className={classes.secondaryButton} type="button">
              {notification.secondaryButtonLabel}
            </button>
          )}
          {notification.withSnoozeButton && (
            <button className={classes.snoozeButton} type="button">
              {t(
                'dashboardComponents.Notifications.Notification.Default.snooze'
              )}
            </button>
          )}
        </div>
      )}
    </div>
  );
}
