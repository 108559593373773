import React, { useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import SaveResetButtons from '../SaveResetButtons';
import BrandName from './BrandName';
import ImageUploader from './ImageUploader';

import { BrandKitContext } from '../../../../../../../context/BrandKitContext';
import classes from './styles.module.scss';
import { hasLogoSettingsChanged } from '../../../../../../../helpers/compareBrandKitSettings';

export default function Logo({
  brandName,
  setBrandName,
  logo,
  setLogo,
  favicon,
  setFavicon,
  savedLogoSettings,
  setSavedLogoSettings,
}) {
  const { t } = useTranslation();

  const {
    saveButtonValidation,
    setSaveButtonValidation,
    setIsBrandNameEditingMode,
  } = useContext(BrandKitContext);

  const saveLogoSettings = () => {
    setSavedLogoSettings({
      logo,
      favicon,
      brandName,
    });
  };

  const resetLogoSettings = () => {
    setBrandName(savedLogoSettings.brandName);
    setLogo(savedLogoSettings.logo);
    setFavicon(savedLogoSettings.favicon);

    if (!savedLogoSettings.brandName) {
      setIsBrandNameEditingMode(true);
    } else {
      setIsBrandNameEditingMode(false);
    }
  };

  useEffect(() => {
    const isDisabled = !hasLogoSettingsChanged(
      {
        brandName,
        logo,
        favicon,
      },
      savedLogoSettings
    );
    setSaveButtonValidation((prevState) => ({
      ...prevState,
      isLogoSaveButtonDisabled: isDisabled,
    }));
  }, [brandName, favicon, logo, savedLogoSettings, setSaveButtonValidation]);

  return (
    <div className={classes.Logo}>
      <div className={classes.header}>
        <BrandName brandName={brandName} setBrandName={setBrandName} />
        <SaveResetButtons
          onSave={saveLogoSettings}
          onReset={resetLogoSettings}
          isSaveDisabled={
            saveButtonValidation.isLogoSaveButtonDisabled || !logo
          }
          isResetDisabled={saveButtonValidation.isLogoSaveButtonDisabled}
        />
      </div>
      <div className={classes.images}>
        <ImageUploader
          title={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Logo.logoTitle'
          )}
          text={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Logo.logoText'
          )}
          maxWidth={444}
          maxHeight={200}
          file={logo}
          setFile={setLogo}
        />
        <ImageUploader
          title={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Logo.faviconTitle'
          )}
          text={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Logo.faviconText'
          )}
          maxWidth={196}
          maxHeight={196}
          file={favicon}
          setFile={setFavicon}
          small
        />
      </div>
    </div>
  );
}
