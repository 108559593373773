import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Announcement from './Announcement';
import ProductAnnouncementsModal from '../../../../modals/ProductAnnouncementsModal';

import AdminService from '../../../../services/AdminService';
import classes from './styles.module.scss';

function compareAnnouncements(a, b) {
  return b?.sortOrder - a?.sortOrder;
}

export default function ProductAnnouncements() {
  const [isProductModalOpened, setIsProductModalOpened] = useState(false);

  const { t } = useTranslation();

  const { data: announcements } = useQuery({
    queryKey: ['announcements'],
    queryFn: () => AdminService.getAnnouncements(),
  });

  return (
    <div className={classes.ProductAnnouncements}>
      <div className={classes.header}>
        {t(
          'dashboardComponents.Discover.ProductAnnouncements.productAnnouncements'
        )}
      </div>
      <div className={classes.content}>
        {!announcements?.length ? (
          <div className={classes.noData}>
            {t(
              'dashboardComponents.Discover.ProductAnnouncements.noAnnouncement'
            )}{' '}
            <br />{' '}
            {t('dashboardComponents.Discover.ProductAnnouncements.toShow')}
          </div>
        ) : (
          <div className={classes.products}>
            {announcements
              ?.sort(compareAnnouncements)
              .slice(0, 2)
              .map((announcement) => (
                <Announcement
                  announcement={announcement}
                  key={announcement.id}
                />
              ))}
            {announcements?.length > 2 && (
              <span
                className={classes.seeAll}
                onClick={() => setIsProductModalOpened(true)}
              >
                {t('common.seeAll')}
              </span>
            )}
          </div>
        )}
      </div>
      <ProductAnnouncementsModal
        show={isProductModalOpened}
        handleClose={() => setIsProductModalOpened(false)}
        announcements={announcements}
      />
    </div>
  );
}
