import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import classes from './styles.module.scss';

export default function JobStatus({ value }) {
  const { t } = useTranslation();

  return (
    <td className={classes.JobStatus}>
      <div
        className={classNames(classes.jobStatus, {
          [classes.green]: value === 'Active',
          [classes.orange]: value === 'OnHold',
        })}
      >
        {t(`common.jobStatuses.${value}`)}
      </div>
    </td>
  );
}
