import React from 'react';
import Group from './Group';

import NoContacts from './NoContacts';

import classes from './styles.module.scss';

export default function ContactsList({
  title,
  activeContactId,
  setActiveContactId,
  type,
  chatThreads,
  refetchChatThreads,
}) {
  return (
    <div className={classes.ContactsList}>
      <div className={classes.header}>
        <h3>{title}</h3>
      </div>
      {!chatThreads || !chatThreads.length ? (
        <NoContacts type={type} />
      ) : (
        <ul className={classes.list}>
          {chatThreads?.map((group) => {
            return (
              <Group
                refetchChatThreads={refetchChatThreads}
                id={group.id}
                group={group}
                setActiveContactId={setActiveContactId}
                activeContactId={activeContactId}
                type={type}
              />
            );
          })}
        </ul>
      )}
    </div>
  );
}
