import React from 'react';
import Badges from './Badges';
import JobDuration from './JobDuration';
import JobNotification from './JobNotification';
import classes from './styles.module.scss';

export default function JobSettings({ job, refetch }) {
  return (
    <div className={classes.Settings}>
      <Badges job={job} refetch={refetch} />
      <JobDuration job={job} refetch={refetch} />
      <JobNotification job={job} />
    </div>
  );
}
