import React, { useState, useRef, useContext } from 'react';

import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Search from './Search';
import TopMenu from './TopMenu';
import UserMenu from './UserMenu';
import CandidatesDropdown from './CandidatesDropdown';

import { SearchContext } from '../../../context/SearchContext';
import { PreventNavigation } from '../../../context/PreventNavigationContext';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { IMAGES_URL } from '../../../constants/main';
import classes from './styles.module.scss';
import UserService from '../../../services/UserService';
import AdminService from '../../../services/AdminService';
import useDebouncedSearchWithHistory from '../../../hooks/useDebouncedSearchWithHistory';
import logo from '../../../assets/images/logo-small.png';
import searchIcon from '../../../assets/images/dashboard/header/search.svg';
import burgerIcon from '../../../assets/images/dashboard/header/burger.svg';
// import { UiContext } from '../../../context/UiContext';
import { useUiStore } from '../../../store';

export default function Header() {
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
  const [language, setLanguage] = useState('English');
  const [online, setOnline] = useState('Online');
  const [searchPopupIsOpened, setSearchPopupIsOpened] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { isNavigationAllowed, setShowModal } = useContext(PreventNavigation);
  // const { setIsMobileSideMenuOpen } = useContext(UiContext);
  const toggleMobileSidebar = useUiStore((state) => state.toggleMobileSidebar);

  const { t } = useTranslation();

  const { activeDropDownOption, setActiveDropDownOption } =
    useContext(SearchContext);
  const { data } = useQuery({ queryKey: ['me'], queryFn: UserService.getMe });

  const { data: tenantSettings } = useQuery({
    queryKey: ['tenantSettings'],
    queryFn: AdminService.getTenantSettings,
  });

  const [, setSearchParams] = useSearchParams();

  const userPicRef = useRef();
  const searchRef = useRef();

  useOnClickOutside(userPicRef, () => setIsUserMenuVisible(false));

  const { searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    'globalSearchHistory'
  );

  const logoUrl = tenantSettings?.brand?.logo
    ? `${IMAGES_URL}/brand/${tenantSettings.brand.logo.split('/').pop()}`
    : logo;

  return (
    <header className={classes.Header}>
      <img src={logoUrl} alt="Logo" className={classes.logo} />
      <div className={classes.organization}>
        <span>{t('dashboardComponents.Header.Your Organization')}</span>
        <div className={classes.dropdown}>
          {t('dashboardComponents.Header.Isthanbul')}
        </div>
      </div>
      <div className={classes.search}>
        <input
          ref={searchRef}
          type="text"
          placeholder={t('dashboardComponents.Header.search')}
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          onFocus={() => {
            if (!isNavigationAllowed) {
              setShowModal(true);
              return;
            }
            setIsFocused(true);
            setSearchPopupIsOpened(true);
            setSearchParams({});
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setSearchPopupIsOpened(true);
            }
          }}
          onBlur={() => {
            setTimeout(() => {
              setIsFocused(false);
            }, 150);
          }}
        />
        {isFocused && !searchTerm.length && searchHistory.length ? (
          <ul className={classes.searchHistory}>
            {searchHistory.map((record) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                key={record}
                onMouseDown={() => {
                  setSearchTerm(record);
                  setSearchPopupIsOpened(true);
                }}
              >
                {record}
              </li>
            ))}
          </ul>
        ) : null}
        <CandidatesDropdown
          activeDropDownOption={activeDropDownOption}
          setActiveDropDownOption={setActiveDropDownOption}
        />
        {searchTerm && (
          <button
            type="button"
            onClick={() => {
              setSearchTerm('');
              setIsFocused(false);
              setIsUserMenuVisible(false);
            }}
            className={classes.button}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" rx="16" fill="#F2F4F8" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9142 15.0252L19.9194 11.02C20.2123 10.7271 20.6872 10.7271 20.9801 11.02C21.273 11.3129 21.273 11.7877 20.9801 12.0806L16.9749 16.0858L20.9801 20.091C21.273 20.3839 21.273 20.8588 20.9801 21.1517C20.6872 21.4446 20.2123 21.4446 19.9194 21.1517L15.9142 17.1465L11.909 21.1517C11.6161 21.4446 11.1412 21.4446 10.8483 21.1517C10.5554 20.8588 10.5554 20.3839 10.8483 20.091L14.8535 16.0858L10.8483 12.0806C10.5554 11.7877 10.5554 11.3129 10.8483 11.02C11.1412 10.7271 11.6161 10.7271 11.909 11.02L15.9142 15.0252Z"
                fill="#242833"
              />
            </svg>
          </button>
        )}

        <Search
          searchTerm={searchTerm}
          currentTab={activeDropDownOption}
          handleClose={() => setSearchPopupIsOpened(false)}
          searchInputRef={searchRef}
          user={data}
          isVisible={searchTerm.length > 0 && searchPopupIsOpened}
        />
      </div>
      <div className={classes.topMenuContainer}>
        <TopMenu />
      </div>
      <div
        className={classNames(classes.userBlock, {
          [classes.openMenu]: isUserMenuVisible,
        })}
      >
        <button className={classes.searchButton} type="button">
          <img src={searchIcon} alt="Search" />
        </button>
        <div className={classes.info}>
          <span className={classes.name}>
            {t('dashboardComponents.Header.Hi')},{' '}
            <span>
              {data?.userProfile?.firstName} {data?.userProfile?.lastName}
            </span>
          </span>
          <span className={classes.role}>{data?.roles?.[0]?.name}</span>
        </div>
        <div
          className={classes.userpic}
          onClick={() => setIsUserMenuVisible((prevState) => !prevState)}
          ref={userPicRef}
        >
          {data?.userProfile?.avatar ? (
            <img
              src={`${IMAGES_URL}/avatar/${data?.userProfile?.avatar}`}
              alt="User"
            />
          ) : (
            <div className={classes.noUserpic}>
              {`${data?.userProfile?.firstName[0] || ''}${
                data?.userProfile?.lastName[0] || ''
              }`}
            </div>
          )}
        </div>
        <button
          className={classes.burgerButton}
          type="button"
          onClick={toggleMobileSidebar}
        >
          <img src={burgerIcon} alt="Burger" />
        </button>
        {isUserMenuVisible && (
          <UserMenu
            language={language}
            setLanguage={setLanguage}
            online={online}
            setOnline={setOnline}
          />
        )}
      </div>
    </header>
  );
}
