import React from 'react';
import CalendarForm from '../../CalendarForm';
import classes from './styles.module.scss';

export default function MonthType({
  currentDate,
  setCurrentDate,
  setActiveType,
  dateToday,
  events,
  user,
  refetch,
}) {
  return (
    <div className={classes.MonthType}>
      <CalendarForm
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        setActiveType={setActiveType}
        isType
        dateToday={dateToday}
        events={events}
        user={user}
        refetch={refetch}
      />
    </div>
  );
}
