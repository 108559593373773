import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function FullScreenButton({
  isFullScreenModeEnabled,
  setIsFullScreenModeEnabled,
}) {
  return (
    <button
      type="button"
      className={classNames(classes.FullScreenButton, {
        [classes.active]: isFullScreenModeEnabled,
      })}
      onClick={() => {
        setIsFullScreenModeEnabled((prevState) => !prevState);
      }}
    >
      FullScreenButton
    </button>
  );
}
