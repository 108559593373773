import React, { useState } from 'react';
import Candidate from '../../../Candidate';
import CandidateCard from '../../../Favorites/Candidates/CandidateCard';
import classes from './styles.module.scss';

export default function CandidatesTab({ candidates, refetch }) {
  const [isCandidatePopupVisible, setIsCandidatePopupVisible] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState(null);

  const visibleCandidates = candidates?.map((candidate) => {
    return {
      createdAt: candidate?.user?.createdAt,
      id: candidate?.userId,
      isFavorite: candidate?.isFavorite,
      jobApplications: candidate?.jobApplications,
      note: candidate?.user?.note,
      userProfile: candidate?.user?.userProfile,
    };
  });

  return (
    <div className={classes.tab}>
      <div className={classes.candidates}>
        {visibleCandidates?.map((candidate) => (
          <CandidateCard
            favCandidate={candidate}
            key={candidate?.id}
            favorite
            setIsCandidatePopupVisible={setIsCandidatePopupVisible}
            setCurrentCandidate={setCurrentCandidate}
            refetch={refetch}
            borderRadius={16}
          />
        ))}
        <Candidate
          isVisible={isCandidatePopupVisible}
          setIsVisible={setIsCandidatePopupVisible}
          refetch={refetch}
          candidate={currentCandidate}
          setCurrentCandidate={setCurrentCandidate}
          candidates={candidates}
        />
      </div>
    </div>
  );
}
