import axios from 'axios';

import {
  ADMIN_API_URL,
  CMS_API_URL,
  JOBS_API_URL,
  CANDIDATES_API_URL,
  PLATFORM_API_URL,
} from '../constants/main';
import { getData, getNonPaginatedData } from '../helpers/requestData';

const AdminService = {
  async getCandidates({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${JOBS_API_URL}/candidates`;
    return getNonPaginatedData({
      term,
      pageNumber,
      pageSize,
      orderBy,
      sortOrder,
      url,
    });
  },

  async getCandidate({ id }) {
    const response = await axios.get(`${JOBS_API_URL}/candidates/${id}`);
    return response.data;
  },

  async addCandidateToFavorites({ id }) {
    const response = await axios.post(
      `${JOBS_API_URL}/candidates/${id}/favorites`
    );
    return response.data;
  },

  async removeCandidateFromFavorites({ id }) {
    const response = await axios.delete(
      `${JOBS_API_URL}/candidates/${id}/favorites`
    );
    return response.data;
  },

  async addCandidateToViewed({ id }) {
    if (!id) {
      throw new Error('Candidate id is required');
    }
    const response = await axios.post(`${JOBS_API_URL}/candidates/${id}/views`);
    return response.data;
  },

  async addNoteToCandidate({ id, note }) {
    const response = await axios.put(`${JOBS_API_URL}/candidates/${id}/note`, {
      note,
    });
    return response.data;
  },

  async inviteCandidateToJob({ candidateId, jobPostId, message }) {
    const response = await axios.post(
      `${JOBS_API_URL}/candidates/${candidateId}/invite`,
      {
        message,
        jobPostId,
      }
    );
    return response.data;
  },

  async getUsers({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${ADMIN_API_URL}/users`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async getUser({ id }) {
    const response = await axios.get(`${ADMIN_API_URL}/users/${id}`);
    return response.data;
  },

  async getUserFavoriteStatus({ id }) {
    const response = await axios.get(
      `${ADMIN_API_URL}/users/${id}/favoritestatus`
    );
    return response.data;
  },

  async deleteUser({ id }) {
    const response = await axios.delete(`${ADMIN_API_URL}/users/${id}`);
    return response.data;
  },

  async getNewUsers({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${ADMIN_API_URL}/accessrequests`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async deleteNewUser({ id }) {
    const response = await axios.delete(
      `${ADMIN_API_URL}/accessrequests/${id}`
    );
    return response.data;
  },

  async approveNewUser({
    id,
    lastName,
    email,
    cityIds,
    locationIds,
    roleIds,
    firstName,
  }) {
    const response = await axios.post(
      `${ADMIN_API_URL}/accessrequests/${id}/grant`,
      {
        lastName,
        email,
        cityIds,
        locationIds,
        roleIds,
        firstName,
      }
    );
    return response.data;
  },

  async createUser({ firstName, lastName, email, cities, restaurants, roles }) {
    const response = await axios.post(`${ADMIN_API_URL}/invitations`, {
      firstName,
      lastName,
      email,
      roleIds: roles,
      cityIds: cities,
      locationIds: restaurants,
    });
    return response.data;
  },

  async updateUser({
    firstName,
    lastName,
    email,
    cityIds,
    locationIds,
    roleIds,
    id,
  }) {
    const response = await axios.put(`${ADMIN_API_URL}/users/${id}`, {
      firstName,
      lastName,
      userName: email,
      roleIds,
      cityIds,
      locationIds,
    });
    return response.data;
  },

  async getRoles({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${ADMIN_API_URL}/roles`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async createRole({ scopes, name, note }) {
    const response = await axios.post(`${ADMIN_API_URL}/roles`, {
      scopes,
      name,
      note,
    });
    return response.data;
  },

  async updateRole({ scopes, name, note, id }) {
    const response = await axios.put(`${ADMIN_API_URL}/roles/${id}`, {
      scopes,
      name,
      note,
    });
    return response.data;
  },

  async deleteRole({ id }) {
    const response = await axios.delete(`${ADMIN_API_URL}/roles/${id}`);
    return response.data;
  },

  async getCities({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${CMS_API_URL}/cities`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async createCity({ name, coverImageName, country, postCode }) {
    const response = await axios.post(`${CMS_API_URL}/cities`, {
      name,
      coverImageName,
      country,
      postCode,
    });
    return response.data;
  },

  async updateCity({ name, coverImageName, country, postCode, id }) {
    const response = await axios.put(`${CMS_API_URL}/cities/${id}`, {
      name,
      coverImageName,
      country,
      postCode,
    });
    return response.data;
  },

  async deleteCity({ id }) {
    const response = await axios.delete(`${CMS_API_URL}/cities/${id}`);
    return response.data;
  },

  async getRestaurants({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${CMS_API_URL}/locations`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async createRestaurant({
    name,
    address,
    locationCityId,
    postCode,
    country,
    latitude,
    longitude,
    managerIds,
    locationCityDiscrictId,
  }) {
    const response = await axios.post(`${CMS_API_URL}/locations`, {
      name,
      address,
      locationCityId,
      postCode,
      country,
      latitude,
      longitude,
      managerIds,
      locationCityDiscrictId,
    });
    return response.data;
  },

  async updateRestaurant({
    name,
    address,
    locationCityId,
    postCode,
    country,
    latitude,
    longitude,
    managerIds,
    locationCityDiscrictId,
    id,
  }) {
    const response = await axios.put(`${CMS_API_URL}/locations/${id}`, {
      name,
      address,
      locationCityId,
      postCode,
      country,
      latitude,
      longitude,
      managerIds,
      locationCityDiscrictId,
    });
    return response.data;
  },

  async deleteRestaurant({ id }) {
    const response = await axios.delete(`${CMS_API_URL}/locations/${id}`);
    return response.data;
  },

  async addRestaurantToFavorites({ id }) {
    const response = await axios.post(
      `${CMS_API_URL}/locations/${id}/favorites`
    );
    return response.data;
  },

  async removeRestaurantFromFavorites({ id }) {
    const response = await axios.delete(
      `${CMS_API_URL}/locations/${id}/favorites`
    );
    return response.data;
  },

  async uploadImage(image, type) {
    const formData = new FormData();

    formData.append('Image', image);
    formData.append('Type', type);

    const response = await axios.post(
      `${CMS_API_URL}/uploads/images`,
      formData
    );
    return response.data;
  },

  async uploadVideo(file) {
    const formData = new FormData();

    formData.append('File', file);

    const response = await axios.post(
      `${CMS_API_URL}/uploads/videos`,
      formData
    );
    return response.data;
  },

  async uploadFile(file) {
    const formData = new FormData();

    formData.append('File', file);

    const response = await axios.post(`${CMS_API_URL}/uploads/files`, formData);
    return response.data;
  },

  async getJobTypes({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${CMS_API_URL}/jobtypes`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async createJobType({ name, note, assessmentThreshold, sortOrder }) {
    const response = await axios.post(`${CMS_API_URL}/jobtypes`, {
      name,
      note,
      assessmentThreshold,
      sortOrder,
    });
    return response.data;
  },

  async updateJobType({ name, note, assessmentThreshold, sortOrder, id }) {
    const response = await axios.put(`${CMS_API_URL}/jobtypes/${id}`, {
      name,
      note,
      assessmentThreshold,
      sortOrder,
    });
    return response.data;
  },

  async deleteJobType({ id }) {
    const response = await axios.delete(`${CMS_API_URL}/jobtypes/${id}`);
    return response.data;
  },

  async getNoticePeriods({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${CMS_API_URL}/noticeperiods`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async createNoticePeriod({ name, note, sortOrder }) {
    const response = await axios.post(`${CMS_API_URL}/noticeperiods`, {
      name,
      note,
      sortOrder,
    });
    return response.data;
  },

  async updateNoticePeriod({ name, note, sortOrder, id }) {
    const response = await axios.put(`${CMS_API_URL}/noticeperiods/${id}`, {
      name,
      note,
      sortOrder,
    });
    return response.data;
  },

  async deleteNoticePeriod({ id }) {
    const response = await axios.delete(`${CMS_API_URL}/noticeperiods/${id}`);
    return response.data;
  },

  async getEducations({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${CMS_API_URL}/graduations`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async createEducation({ name, note, sortOrder }) {
    const response = await axios.post(`${CMS_API_URL}/graduations`, {
      name,
      note,
      sortOrder,
    });
    return response.data;
  },

  async updateEducation({ name, note, sortOrder, id }) {
    const response = await axios.put(`${CMS_API_URL}/graduations/${id}`, {
      name,
      note,
      sortOrder,
    });
    return response.data;
  },

  async deleteEducation({ id }) {
    const response = await axios.delete(`${CMS_API_URL}/graduations/${id}`);
    return response.data;
  },

  async getSkills({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${CMS_API_URL}/skills`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async createSkill({ name, note, sortOrder }) {
    const response = await axios.post(`${CMS_API_URL}/skills`, {
      name,
      note,
      sortOrder,
    });
    return response.data;
  },

  async updateSkill({ name, note, sortOrder, id }) {
    const response = await axios.put(`${CMS_API_URL}/skills/${id}`, {
      name,
      note,
      sortOrder,
    });
    return response.data;
  },

  async deleteSkill({ id }) {
    const response = await axios.delete(`${CMS_API_URL}/skills/${id}`);
    return response.data;
  },

  async getDistricts({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${CMS_API_URL}/districts`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async createDistrict({ name, locationCityId }) {
    const cityId =
      typeof locationCityId === 'object'
        ? locationCityId.value
        : locationCityId;
    const response = await axios.post(`${CMS_API_URL}/districts`, {
      name,
      locationCityId: cityId,
    });
    return response.data;
  },

  async updateDistrict({ name, locationCityId, id }) {
    const cityId =
      typeof locationCityId === 'object'
        ? locationCityId.value
        : locationCityId;
    const response = await axios.put(`${CMS_API_URL}/districts/${id}`, {
      name,
      locationCityId: cityId,
    });
    return response.data;
  },

  async deleteDistrict({ id }) {
    const response = await axios.delete(`${CMS_API_URL}/districts/${id}`);
    return response.data;
  },

  async getAssessment({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${CMS_API_URL}/assessments`;

    const data = getData({
      term,
      pageNumber,
      pageSize,
      orderBy,
      sortOrder,
      url,
    });

    return data;
  },

  async createAssessment({ name, note }) {
    const response = await axios.post(`${CMS_API_URL}/assessments`, {
      name,
      note,
    });
    return response.data;
  },

  async updateAssessment({ name, note, assessmentId }) {
    const response = await axios.put(
      `${CMS_API_URL}/assessments/${assessmentId}`,
      {
        name,
        note,
      }
    );
    return response.data;
  },

  async deleteeAssessments({ id }) {
    const response = await axios.delete(`${CMS_API_URL}/assessments/${id}`);
    return response.data;
  },

  async getAssessmentQuestions({
    term,
    pageNumber,
    pageSize,
    orderBy,
    sortOrder,
    assessmentId,
  }) {
    const url = `${CMS_API_URL}/assessments/${assessmentId}/questions`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async createAssessmentQuestion({
    name,
    sortOrder,
    allowsMultipleAnswers,
    assessmentId,
  }) {
    const response = await axios.post(
      `${CMS_API_URL}/assessments/${assessmentId}/questions`,
      {
        name,
        sortOrder,
        allowsMultipleAnswers,
        assessmentId,
      }
    );
    return response.data;
  },

  async updateAssessmentQuestion({
    name,
    sortOrder,
    allowsMultipleAnswers,
    assessmentId,
    questionId,
  }) {
    const response = await axios.put(
      `${CMS_API_URL}/assessments/${assessmentId}/questions/${questionId}`,
      {
        name,
        sortOrder,
        allowsMultipleAnswers,
        assessmentId,
      }
    );
    return response.data;
  },

  async deleteAssessmentQuestion({ assessmentId, questionId }) {
    const response = await axios.delete(
      `${CMS_API_URL}/assessments/${assessmentId}/questions/${questionId}`
    );
    return response.data;
  },

  async createAssessmentQuestionAnswer({
    answer,
    sortOrder,
    questionId,
    assessmentId,
  }) {
    const response = await axios.post(
      `${CMS_API_URL}/assessments/${assessmentId}/questions/${questionId}/answers`,
      {
        answer,
        sortOrder,
      }
    );
    return response.data;
  },

  async updateAssessmentQuestionAnswer({
    answer,
    sortOrder,
    questionId,
    assessmentId,
    answerId,
  }) {
    const response = await axios.put(
      `${CMS_API_URL}/assessments/${assessmentId}/questions/${questionId}/answers/${answerId}`,
      {
        answer,
        sortOrder,
      }
    );
    return response.data;
  },

  async getVrQuestions({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${CMS_API_URL}/videoresumequestions`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async getVrQuestion({ id }) {
    const response = await axios.get(
      `${CMS_API_URL}/videoresumequestions/${id}`
    );
    return response.data;
  },

  async createVrQuestion({
    question,
    title,
    duration,
    sortOrder,
    note,
    isDefault,
  }) {
    const response = await axios.post(`${CMS_API_URL}/videoresumequestions`, {
      question,
      note,
      title,
      sortOrder,
      duration,
      isDefault,
    });
    return response.data;
  },

  async updateVrQuestion({
    question,
    title,
    duration,
    sortOrder,
    note,
    id,
    isDefault,
  }) {
    const response = await axios.put(
      `${CMS_API_URL}/videoresumequestions/${id}`,
      {
        question,
        title,
        duration,
        sortOrder,
        note,
        isDefault,
      }
    );
    return response.data;
  },

  async deleteVrQuestion({ id }) {
    const response = await axios.delete(
      `${CMS_API_URL}/videoresumequestions/${id}`
    );
    return response.data;
  },

  async getWorkExperience({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${CMS_API_URL}/workexperiences`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async createWorkExperience({ name, sortOrder, note }) {
    const response = await axios.post(`${CMS_API_URL}/workexperiences`, {
      name,
      sortOrder,
      note,
    });
    return response.data;
  },

  async updateWorkExperience({ name, sortOrder, note, id }) {
    const response = await axios.put(`${CMS_API_URL}/workexperiences/${id}`, {
      name,
      sortOrder,
      note,
    });
    return response.data;
  },

  async deleteWorkExperience({ id }) {
    const response = await axios.delete(`${CMS_API_URL}/workexperiences/${id}`);
    return response.data;
  },

  async getJobPostTemplates({
    term,
    pageNumber,
    pageSize,
    orderBy,
    sortOrder,
  }) {
    const url = `${JOBS_API_URL}/jobposttemplates`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async createJobPostTemplate({
    templateName,
    templateSortOrder,
    templateNote,
    jobTitle,
    locationCityId,
    locationCityDistrictId,
    jobTypeIds,
    graduation,
    corporateTitle,
    jobDescription,
    locationIds,
    workExperienceId,
    salaryTo,
    salaryFrom,
    note,
    coverFileName,
    skillIds,
    jobPostVideoId,
    jobPostAttachmentIds,
    videoResumeQuestionIds,
    assessmentId,
    applicationType,
    assessmentThreshold,
    assessmentLimit,
    assignedAssessments,
  }) {
    const response = await axios.post(`${JOBS_API_URL}/jobposttemplates`, {
      templateName,
      templateSortOrder,
      templateNote,
      jobTitle,
      locationCityId,
      locationCityDistrictId,
      jobTypeIds,
      graduationId: graduation,
      corporateTitle,
      jobDescription,
      locationIds,
      workExperienceId,
      salaryTo,
      salaryFrom,
      note,
      coverFileName,
      skillIds,
      jobPostVideoId,
      jobPostAttachmentIds,
      videoResumeQuestionIds,
      assessmentId,
      applicationType,
      assessmentThreshold: `${assessmentThreshold}%`,
      assessmentLimit,
      assignedAssessments,
    });
    return response.data;
  },

  async updateJobPostTemplate({
    templateName,
    templateSortOrder,
    templateNote,
    jobTitle,
    locationCityId,
    locationCityDistrictId,
    jobTypeIds,
    graduation,
    corporateTitle,
    jobDescription,
    locationIds,
    workExperienceId,
    salaryTo,
    salaryFrom,
    note,
    coverFileName,
    skillIds,
    jobPostVideoId,
    jobPostAttachmentIds,
    videoResumeQuestionIds,
    assessmentId,
    applicationType,
    assessmentThreshold,
    assessmentLimit,
    id,
    assignedAssessments,
  }) {
    const response = await axios.put(`${JOBS_API_URL}/jobposttemplates/${id}`, {
      templateName,
      templateSortOrder,
      templateNote,
      jobTitle,
      locationCityId,
      locationCityDistrictId,
      jobTypeIds,
      graduationId: graduation,
      corporateTitle,
      jobDescription,
      locationIds,
      workExperienceId,
      salaryTo,
      salaryFrom,
      note,
      coverFileName,
      skillIds,
      jobPostVideoId,
      jobPostAttachmentIds,
      videoResumeQuestionIds,
      assessmentId,
      applicationType,
      assessmentThreshold: `${assessmentThreshold}%`,
      assessmentLimit,
      assignedAssessments,
    });
    return response.data;
  },

  async deleteJobPostTemplate({ id }) {
    const response = await axios.delete(
      `${JOBS_API_URL}/jobposttemplates/${id}`
    );
    return response.data;
  },

  async createJobPostVideo({ fileName, uri, mimeType, displayName }) {
    const response = await axios.post(`${JOBS_API_URL}/jobpostvideos`, {
      fileName,
      url: uri,
      mimeType,
      displayName,
    });
    return response.data;
  },

  async createJobPostAttachment({ fileName, uri, mimeType, displayName }) {
    const response = await axios.post(`${JOBS_API_URL}/jobpostattachments`, {
      fileName,
      url: uri,
      mimeType,
      displayName,
    });
    return response.data;
  },

  async getTags({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${CMS_API_URL}/jobposttags`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async createTag({ name, note, sortOrder }) {
    const response = await axios.post(`${CMS_API_URL}/jobposttags`, {
      name,
      note,
      sortOrder,
    });
    return response.data;
  },

  async updateTag({ name, note, sortOrder, id }) {
    const response = await axios.put(`${CMS_API_URL}/jobposttags/${id}`, {
      name,
      note,
      sortOrder,
    });
    return response.data;
  },

  async deleteTag({ id }) {
    const response = await axios.delete(`${CMS_API_URL}/jobposttags/${id}`);
    return response.data;
  },

  async addTagToJobPost({ id, jobPostTagIds }) {
    const response = await axios.put(`${JOBS_API_URL}/jobposts/${id}/tags`, {
      jobPostTagIds,
    });

    return response.data;
  },

  async getJobPosts({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${JOBS_API_URL}/jobposts`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async getDraftJobPosts({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${JOBS_API_URL}/jobposts/drafts`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async getJobPost({ id }) {
    const response = await axios.get(`${JOBS_API_URL}/jobposts/${id}`);
    return response.data;
  },

  async getJobPostApplications({ id }) {
    const response = await axios.get(
      `${JOBS_API_URL}/jobposts/${id}/jobapplications`
    );
    return response.data;
  },

  async getJobPostsInterviewers({
    term,
    pageNumber,
    pageSize,
    orderBy,
    sortOrder,
  }) {
    const url = `${JOBS_API_URL}/jobpostinterviewers`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async createJobPost({
    jobTitle,
    locationCityId,
    locationCityDistrictId,
    jobTypeIds,
    graduationId,
    corporateTitle,
    jobDescription,
    locationIds,
    workExperienceId,
    salaryTo,
    salaryFrom,
    note,
    coverFileName,
    skillIds,
    jobPostVideoId,
    jobPostAttachmentIds,
    videoResumeQuestionIds,
    assessmentId,
    applicationType,
    assessmentThreshold,
    assessmentLimit,
    status,
    expiresAt,
    assignedAssessments,
  }) {
    const response = await axios.post(`${JOBS_API_URL}/jobposts`, {
      jobTitle,
      locationCityId,
      locationCityDistrictId,
      jobTypeIds,
      graduationId,
      corporateTitle,
      jobDescription,
      locationIds,
      workExperienceId,
      salaryTo,
      salaryFrom,
      note,
      coverFileName,
      skillIds,
      jobPostVideoId,
      jobPostAttachmentIds,
      videoResumeQuestionIds,
      assessmentId,
      applicationType,
      assessmentThreshold: `${assessmentThreshold}%`,
      assessmentLimit,
      status,
      expiresAt,
      assignedAssessments,
    });
    return response.data;
  },

  async updateJobPost({
    jobTitle,
    locationCityId,
    locationCityDistrictId,
    jobTypeIds,
    graduationId,
    corporateTitle,
    jobDescription,
    locationIds,
    workExperienceId,
    salaryTo,
    salaryFrom,
    note,
    coverFileName,
    skillIds,
    jobPostVideoId,
    jobPostAttachmentIds,
    videoResumeQuestionIds,
    assessmentId,
    applicationType,
    assessmentThreshold,
    assessmentLimit,
    status,
    jobPostDelegateIds,
    id,
    jobPostInterviewerIds,
    assignedAssessments,
  }) {
    const response = await axios.put(`${JOBS_API_URL}/jobposts/${id}`, {
      jobTitle,
      locationCityId,
      locationCityDistrictId,
      jobTypeIds,
      graduationId,
      corporateTitle,
      jobDescription,
      locationIds,
      workExperienceId,
      salaryTo,
      salaryFrom,
      note,
      coverFileName,
      skillIds,
      jobPostVideoId,
      jobPostAttachmentIds,
      videoResumeQuestionIds,
      assessmentId,
      applicationType,
      assessmentThreshold: `${assessmentThreshold}%`,
      assessmentLimit,
      status,
      jobPostDelegateIds,
      jobPostInterviewerIds,
      assignedAssessments,
    });
    return response.data;
  },

  async createJobPostDelegate({ userId, startsAt, endsAt, jobPostId }) {
    const response = await axios.post(`${JOBS_API_URL}/jobpostdelegates`, {
      userId,
      startsAt,
      endsAt,
      jobPostId,
    });
    return response.data;
  },

  async updateJobPostExpiration({ id, expiresAt }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobposts/${id}/expiration`,
      {
        expiresAt,
      }
    );
    return response.data;
  },

  async createJobPostInterviewer({ userId, jobPostId }) {
    const response = await axios.post(`${JOBS_API_URL}/jobpostinterviewers`, {
      userId,
      jobPostId,
    });
    return response.data;
  },

  async createUserReport({ userId, reason, type }) {
    const response = await axios.post(`${CANDIDATES_API_URL}/userreports`, {
      userId,
      reason,
      type,
    });
    return response.data;
  },

  async deleteUserReport({ id }) {
    const response = await axios.delete(
      `${CANDIDATES_API_URL}/userreports/${id}`
    );
    return response.data;
  },

  async getUserReports({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${CANDIDATES_API_URL}/userreports`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async clearUserReport({ id, reportData }) {
    const response = await axios.put(
      `${CANDIDATES_API_URL}/userreports/${id}`,
      {
        ...reportData,
        isCleared: true,
      }
    );
    return response.data;
  },

  async blockUser({ userId }) {
    const url = `${CANDIDATES_API_URL}/users/${userId}/block`;
    const response = await axios.post(url);

    return response.data;
  },

  async getContentMedia({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${CMS_API_URL}/contentmedias`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async updateContentMedia({ fileName, url, mimeType, location, type, id }) {
    const response = await axios.put(`${CMS_API_URL}/contentmedias/${id}`, {
      fileName,
      url,
      mimeType,
      location,
      type,
    });
    return response.data;
  },

  async getMyJobs({
    term,
    pageNumber,
    pageSize,
    orderBy,
    isFavorite,
    sortOrder,
    locationId,
    RecruiterId,
  }) {
    const url = `${JOBS_API_URL}/jobposts/list`;
    return getData({
      term,
      pageNumber,
      pageSize,
      orderBy,
      isFavorite,
      sortOrder,
      url,
      locationId,
      RecruiterId,
    });
  },

  async getMyJobsStats() {
    const response = await axios.get(`${JOBS_API_URL}/jobposts/stats`);
    return response.data;
  },

  async addMyJobToFavorites({ jobId }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobposts/${jobId}/favorites`
    );
    return response.data;
  },

  async removeMyJobFromFavorites({ jobId }) {
    const response = await axios.delete(
      `${JOBS_API_URL}/jobposts/${jobId}/favorites`
    );
    return response.data;
  },

  async changeMyJobStatus({ jobId, status }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobposts/${jobId}/status`,
      {
        status,
      }
    );
    return response.data;
  },

  async getJobPostStats({ jobId }) {
    const response = await axios.get(`${JOBS_API_URL}/jobposts/${jobId}/stats`);
    return response.data;
  },

  async getJobPostTimeline({ jobId }) {
    const response = await axios.get(
      `${JOBS_API_URL}/jobposts/${jobId}/timeline`
    );
    return response.data;
  },

  async getMyJobApplications({ term, orderBy, sortOrder, jobId }) {
    const url = `${JOBS_API_URL}/jobposts/${jobId}/jobapplications`;
    return getNonPaginatedData({ term, orderBy, sortOrder, url });
  },

  async getJobApplicationTimeline({ id }) {
    const response = await axios.get(
      `${JOBS_API_URL}/jobapplications/${id}/timeline`
    );
    return response.data;
  },

  async addMyJobApplicationToFavorites({ jobApplicationId }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/favorites`
    );
    return response.data;
  },

  async removeMyJobApplicationFromFavorites({ jobApplicationId }) {
    const response = await axios.delete(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/favorites`
    );
    return response.data;
  },

  async changeMyJobApplicationStatus({ jobApplicationId, status, subStatus }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/status`,
      {
        status,
        subStatus,
      }
    );
    return response.data;
  },

  async addFeedbackToInterview({
    jobApplicationId,
    interviewId,
    feedbackNote,
    feedbackRating,
    isRecommended,
  }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/interviews/${interviewId}/feedbacks`,
      { feedbackNote, feedbackRating, isRecommended }
    );
    return response.data;
  },

  async createJobApplicationOnboarding({
    jobApplicationId,
    type,
    onboardingDate,
    completeDate,
    startTime,
    endTime,
    note,
    locationName,
    locationId,
    userIds,
    connectionDetail,
  }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/onboardings`,
      {
        type,
        onboardingDate,
        completeDate,
        startTime,
        endTime,
        note,
        locationName,
        locationId,
        userIds,
        connectionDetail,
      }
    );
    return response.data;
  },

  async changeJobApplicationOnboarding({
    onboardingId,
    jobApplicationId,
    type,
    onboardingDate,
    completeDate,
    startTime,
    endTime,
    note,
    location,
    userIds,
    status,
  }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/onboardings/${onboardingId}`,
      {
        type,
        onboardingDate,
        completeDate,
        startTime,
        endTime,
        note,
        location,
        userIds,
        status,
      }
    );
    return response.data;
  },

  async cancelJobApplicationOnboarding({ onboardingId, jobApplicationId }) {
    await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/onboardings/${onboardingId}`,
      {
        status: 'Canceled',
      }
    );

    await AdminService.deleteJobApplicationOnboarding({
      jobApplicationId,
      onboardingId,
    });

    const response = await this.changeMyJobApplicationStatus({
      jobApplicationId,
      status: 'Onboard',
      subStatus: 'Canceled',
    });

    return response.data;
  },

  async changeJobApplicationOnboardingInvite({
    onboardingId,
    jobApplicationId,
    invitationid,
    note,
    status,
  }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/onboardings/${onboardingId}/invitations/${invitationid}`,
      {
        note,
        status,
      }
    );
    return response.data;
  },

  async addJobApplicationOnboardingToFavorites({
    jobApplicationId,
    onboardingId,
  }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/onboardings/${onboardingId}/favorites`
    );

    return response.data;
  },

  async deleteJobApplicationOnboardingFromFavorites({
    jobApplicationId,
    onboardingId,
  }) {
    const response = await axios.delete(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/onboardings/${onboardingId}/favorites`
    );

    return response.data;
  },

  async deleteFeedbackFromInterview({
    jobApplicationId,
    interviewId,
    feedbackId,
  }) {
    const response = await axios.delete(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/interviews/${interviewId}/feedbacks/${feedbackId}`
    );
    return response.data;
  },

  async deleteJobApplicationOnboarding({ jobApplicationId, onboardingId }) {
    const response = await axios.delete(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/onboardings/${onboardingId}`
    );
    return response.data;
  },

  async saveFeedbackToInterview({
    jobApplicationId,
    interviewId,
    feedbackId,
    feedbackNote,
    feedbackRating,
    isRecommended,
  }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/interviews/${interviewId}/feedbacks/${feedbackId}`,
      { feedbackNote, feedbackRating, isRecommended }
    );
    return response.data;
  },

  async createJobApplicationInterview({
    jobApplicationId,
    type,
    interviewDate,
    level,
    startTime,
    endTime,
    note,
    customLocation,
    userIds,
    connectionDetail,
    notificationType,
    notificationMethod,
    notificationValue,
    title,
    status = 'Scheduled',
  }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/interviews`,
      {
        type,
        interviewDate,
        level,
        startTime,
        endTime,
        note,
        customLocation,
        userIds,
        connectionDetail,
        notificationType,
        notificationMethod,
        notificationValue,
        title,
        status,
      }
    );
    return response.data;
  },

  async changeJobApplicationInterview({
    interviewId,
    jobApplicationId,
    type,
    interviewDate,
    level,
    startTime,
    endTime,
    note,
    customLocation,
    userIds,
    connectionDetail,
    notificationType,
    notificationMethod,
    notificationValue,
    status,
    title,
  }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/interviews/${interviewId}`,
      {
        type,
        interviewDate,
        level,
        startTime,
        endTime,
        note,
        customLocation,
        userIds,
        connectionDetail,
        notificationType,
        notificationMethod,
        notificationValue,
        status,
        title,
      }
    );
    return response.data;
  },

  async deleteJobApplicationInterview({ jobApplicationId, interviewid }) {
    const response = await axios.delete(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/interviews/${interviewid}`
    );
    return response.data;
  },

  async cancelJobApplicationInterview({ interviewId, jobApplicationId }) {
    await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/interviews/${interviewId}`,
      {
        status: 'Fail',
      }
    );

    await AdminService.deleteJobApplicationInterview({
      jobApplicationId,
      interviewid: interviewId,
    });

    const response = await this.changeMyJobApplicationStatus({
      jobApplicationId,
      status: 'Interview',
      subStatus: 'Canceled',
    });

    return response.data;
  },

  async changeJobApplicationInterviewInvite({
    interviewId,
    jobApplicationId,
    invitationid,
    note,
    notificationType,
    notificationMethod,
    notificationValue,
    status,
  }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/interviews/${interviewId}/invitations/${invitationid}`,
      {
        note,
        notificationType,
        notificationMethod,
        notificationValue,
        status,
      }
    );
    return response.data;
  },

  async addNewNote({ jobApplicationId, note }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/notes`,
      { note }
    );
    return response.data;
  },

  async deleteNote({ jobApplicationId, noteId }) {
    const response = await axios.delete(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/notes/${noteId}`
    );
    return response.data;
  },

  async addJobApplicationInterviewToFavorites({
    jobApplicationId,
    interviewId,
  }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/interviews/${interviewId}/favorites`
    );

    return response.data;
  },

  async deleteJobApplicationInterviewFromFavorites({
    jobApplicationId,
    interviewId,
  }) {
    const response = await axios.delete(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/interviews/${interviewId}/favorites`
    );

    return response.data;
  },

  async createJobApplicationStartDate({
    jobApplicationId,
    joiningDate,
    note,
    startTime,
    endTime,
    userIds,
    locationName,
    locationId,
  }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/joinings`,
      {
        joiningDate,
        note,
        startTime,
        endTime,
        userIds,
        locationName,
        locationId,
      }
    );
    return response.data;
  },

  async changeJobApplicationStartDate({
    joiningId,
    jobApplicationId,
    joiningDate,
    note,
    startTime,
    endTime,
    userIds,
    location,
    status,
  }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/joinings/${joiningId}`,
      {
        joiningDate,
        note,
        startTime,
        endTime,
        userIds,
        location,
        status,
      }
    );
    return response.data;
  },

  async cancelStartDate({ joiningId, jobApplicationId }) {
    await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/joinings/${joiningId}`,
      {
        status: 'Canceled',
      }
    );

    await AdminService.deleteJobApplicationStartDate({
      jobApplicationId,
      joiningid: joiningId,
    });

    const response = await this.changeMyJobApplicationStatus({
      jobApplicationId,
      status: 'Onboard',
      subStatus: 'Canceled',
    });
    return response.data;
  },

  async changeJobApplicationStartDateInvite({
    joiningId,
    jobApplicationId,
    invitationid,
    note,
    status,
  }) {
    const response = await axios.put(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/joinings/${joiningId}/invitations/${invitationid}`,
      {
        note,
        status,
      }
    );
    return response.data;
  },

  async deleteJobApplicationStartDate({ jobApplicationId, joiningid }) {
    const response = await axios.delete(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/joinings/${joiningid}`
    );
    return response.data;
  },

  async addJobApplicationStartDateToFavorites({ jobApplicationId, joiningId }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/joinings/${joiningId}/favorites`
    );

    return response.data;
  },

  async deleteJobApplicationStartDateFromFavorites({
    jobApplicationId,
    joiningId,
  }) {
    const response = await axios.delete(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/joinings/${joiningId}/favorites`
    );

    return response.data;
  },

  async createJobApplicationOffer({
    jobApplicationId,
    currency,
    note,
    amount,
    locationName,
    locationId,
    customJobTitle,
    jobApplicationOfferAttachments,
  }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${jobApplicationId}/offers`,
      {
        jobApplicationId,
        currency,
        note,
        amount,
        locationName,
        locationId,
        customJobTitle,
        jobApplicationOfferAttachments,
      }
    );
    return response.data;
  },

  async getRestaurantsOnMap() {
    const url = `${CMS_API_URL}/locations/map`;
    return getNonPaginatedData({
      url,
    });
  },

  async getJobApplications() {
    const response = await axios.get(`${JOBS_API_URL}/jobapplications`, {
      params: {
        PageSize: 99999,
      },
    });
    return response.data;
  },

  async getJobApplication({ id }) {
    const response = await axios.get(`${JOBS_API_URL}/jobapplications/${id}`);
    return response.data;
  },

  async inviteCandidate({ id, jobPostId, message }) {
    const response = await axios.post(
      `${JOBS_API_URL}/candidates/${id}/invite`,
      {
        jobPostId,
        message,
      }
    );
    return response.data;
  },

  async getRecipients({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${JOBS_API_URL}/recipients`;
    return getData({ term, pageNumber, pageSize, orderBy, sortOrder, url });
  },

  async recommendCandidate({ id, message, userId }) {
    const response = await axios.post(
      `${JOBS_API_URL}/candidates/${id}/recommend`,
      {
        message,
        userId,
      }
    );
    return response.data;
  },

  async addJobToViewed({ id }) {
    const response = await axios.post(`${JOBS_API_URL}/jobposts/${id}/views`);
    return response.data;
  },

  async getDashboardStats({
    locationIds,
    cityIds,
    startDate,
    endDate,
    periodType,
  }) {
    const response = await axios.get(`${JOBS_API_URL}/dashboard`, {
      params: {
        LocationIds: locationIds,
        CityIds: cityIds,
        StartDate: startDate,
        EndDate: endDate,
        PeriodType: periodType,
      },
    });
    return response.data;
  },

  async getRedAlerts({ locationIds, cityIds }) {
    const response = await axios.get(`${JOBS_API_URL}/redalerts`, {
      params: {
        LocationIds: locationIds,
        CityIds: cityIds,
      },
    });
    return response.data;
  },

  async getInvitationJobsByCandidateId({ candidateId }) {
    const response = await axios.get(
      `${JOBS_API_URL}/candidates/${candidateId}/invitationjobs`
    );
    return response.data;
  },

  async getSearch({ term }) {
    const response = await axios.get(`${JOBS_API_URL}/search`, {
      params: {
        term,
      },
    });
    return response.data;
  },

  async getJobApplicationsSchedule() {
    const response = await axios.get(
      `${JOBS_API_URL}/jobapplications/schedule`
    );
    return response.data;
  },

  async getAnnouncements() {
    const response = await axios.get(`${PLATFORM_API_URL}/announcements`);

    return response.data;
  },

  async getDiscoverAds() {
    const response = await axios.get(`${PLATFORM_API_URL}/discoverads`);

    return response.data;
  },

  async getOnlineUsers() {
    const response = await axios.get(`${JOBS_API_URL}/onlineusers`);

    return response.data;
  },

  async getTenantSettings() {
    const response = await axios.get(`${CMS_API_URL}/tenantsettings`);

    return response.data;
  },

  async updateTenantSettings({
    sodialMediaLinks,
    escalationReceivers,
    brand,
    customFonts,
    brandFonts,
    brandColors,
  }) {
    const response = await axios.put(`${CMS_API_URL}/tenantsettings`, {
      sodialMediaLinks,
      escalationReceivers,
      brand,
      customFonts,
      brandFonts,
      brandColors,
    });

    return response.data;
  },

  async getAssessmentProviders({
    term,
    pageNumber,
    pageSize,
    orderBy,
    sortOrder,
  }) {
    const url = `${CMS_API_URL}/assessmentproviders`;
    return getData({
      term,
      pageNumber,
      pageSize,
      orderBy,
      sortOrder,
      url,
    });
  },

  async getTenantAssessmentProviders({
    term,
    pageNumber,
    pageSize,
    orderBy,
    sortOrder,
  }) {
    const url = `${CMS_API_URL}/tenantassessmentproviders`;
    return getData({
      term,
      pageNumber,
      pageSize,
      orderBy,
      sortOrder,
      url,
    });
  },

  async createTenantAssessmentProvider({
    name,
    authorizationKey,
    authorizationCompanyId,
    assessmentProvider,
  }) {
    const response = await axios.post(
      `${CMS_API_URL}/tenantassessmentproviders`,
      {
        name,
        authorizationKey: authorizationKey || null,
        authorizationCompanyId: authorizationCompanyId || null,
        assessmentProviderId: assessmentProvider?.value,
      }
    );
    return response.data;
  },

  async updateTenantAssessmentProvider({
    name,
    authorizationKey,
    authorizationCompanyId,
    id,
  }) {
    const response = await axios.put(
      `${CMS_API_URL}/tenantassessmentproviders/${id}`,
      {
        name,
        authorizationKey: authorizationKey || null,
        authorizationCompanyId: authorizationCompanyId || null,
      }
    );
    return response.data;
  },

  async deleteTenantAssessmentProvider({ id }) {
    const response = await axios.delete(
      `${CMS_API_URL}/tenantassessmentproviders/${id}`
    );
    return response.data;
  },

  async enableTenantAssessmentProvider({ id }) {
    const response = await axios.post(
      `${CMS_API_URL}/tenantassessmentproviders/${id}/enable`
    );
    return response.data;
  },

  async disableTenantAssessmentProvider({ id }) {
    const response = await axios.post(
      `${CMS_API_URL}/tenantassessmentproviders/${id}/disable`
    );
    return response.data;
  },

  async getFlowsProfiles() {
    const response = await axios.get(`${JOBS_API_URL}/flowqprofiles`);
    return response.data;
  },

  async createAssignedAssessment({ id, assignedAssessments }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${id}/assignedassessments`,
      {
        assignedAssessments,
      }
    );
    return response.data;
  },

  async retakeAssessments({ id, assignedAssessments }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${id}/retakeassessments`,
      {
        assignedAssessments,
      }
    );
    return response.data;
  },

  async remindAssessment({ id }) {
    const response = await axios.post(
      `${JOBS_API_URL}/jobapplications/${id}/remindassessments`
    );
    return response.data;
  },

  async checkFlowqAssessment(id) {
    const response = await axios.post(`${JOBS_API_URL}/checkflowq/${id}`);
    return response.data;
  },
};

export default AdminService;
