import React from 'react';

import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';

import classes from './styles.module.scss';

export default function Tabs({ tabs }) {
  const navigate = useNavigate();
  const { subpage } = useParams();
  return (
    <div className={classes.Tabs}>
      {tabs.map((tab) => (
        <div
          className={classNames(classes.tab, {
            [classes.activeTab]: subpage === tab.path,
          })}
          key={tab.title}
          onClick={() => navigate(`/tools/settings/${tab.path}`)}
        >
          {tab.title}
        </div>
      ))}
    </div>
  );
}
