import React, { useState, useRef, useEffect } from 'react';

import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import classes from './styles.module.scss';

export default function Tooltip({
  children,
  text,
  width,
  direction,
  disabled,
  noWrap,
  fixed,
  timeout = 2000,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [isLeftOriented, setIsLeftOriented] = useState(false);
  const [isTopOriented, setIsTopOriented] = useState(false);
  const [isTipVisible, setIsTipVisible] = useState(false);
  const [isPositionFixed, setIsPositionFixed] = useState(false);
  const [coords, setCoords] = useState({ x: '', y: '' });

  const tipRef = useRef();
  const timer = useRef();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  useEffect(() => {
    if (isHovered) {
      if (direction?.includes('top')) {
        setIsTopOriented(true);
      } else {
        setIsTopOriented(false);
      }

      if (direction?.includes('left')) {
        setIsLeftOriented(true);
        return;
      }

      const { right } = tipRef.current.getBoundingClientRect();

      if (
        window.innerWidth - right < tipRef.current.clientWidth &&
        !isTabletOrMobile
      ) {
        setIsLeftOriented(true);
      }
    } else {
      setIsLeftOriented(false);
    }
  }, [direction, isHovered, isTabletOrMobile]);

  useEffect(() => {
    clearTimeout(timer.current);
    if (isHovered) {
      timer.current = setTimeout(() => {
        setIsTipVisible(true);
      }, timeout);
    } else {
      setIsTipVisible(false);
    }
  }, [isHovered, timeout]);

  useEffect(() => {
    if (isTipVisible && fixed) {
      const tipRect = tipRef.current.getBoundingClientRect();
      setTimeout(() => {
        setCoords({
          x: tipRect.x,
          y: tipRect.y,
        });
        setIsPositionFixed(true);
      }, 0);
    }

    return () => {
      setIsPositionFixed(false);
      setCoords({ x: '', y: '' });
    };
  }, [fixed, isTipVisible]);

  return (
    <div
      className={classes.Tooltip}
      onMouseEnter={() => {
        if (!disabled) {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (!disabled) {
          setIsHovered(false);
        }
      }}
    >
      {children}
      {isHovered && (
        <div
          className={classNames(classes.tip, {
            [classes.left]: isLeftOriented,
            [classes.top]: isTopOriented,
            [classes.visible]: isTipVisible,
          })}
          style={{
            width,
            whiteSpace: noWrap ? 'nowrap' : '',
            position: isPositionFixed ? 'fixed' : '',
            left: isPositionFixed ? coords.x : '',
            top: isPositionFixed ? coords.y : '',
          }}
          ref={tipRef}
        >
          {text}
        </div>
      )}
    </div>
  );
}
