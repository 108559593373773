/* eslint-disable react/no-danger */
import React from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Milestone from './Milestone';
import FiveStarFeedback from './FiveStarFeedback';
import Recipient from './Recipient';

import classes from './styles.module.scss';

export default function Appreciation({ feedPost }) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  let content = (
    <div className={classes.container}>
      <div className={classes.content}>
        <h3 className={classes.title}>
          {t(
            'dashboardComponents.Discover.Feed.Post.Appreciation.appreciation'
          )}
        </h3>
        <div
          className={classes.text}
          dangerouslySetInnerHTML={{ __html: feedPost?.text }}
        />
        <div className={classes.recipients}>
          {feedPost?.appreciated?.map((recipient) => (
            <Recipient
              key={recipient.id}
              user={{
                userProfile: {
                  firstName: recipient.firstName,
                  lastName: recipient.lastName,
                  avatar: recipient.avatar,
                },
              }}
            />
          ))}
        </div>
      </div>
      {!isTabletOrMobile && <div className={classes.background} />}
    </div>
  );

  if (feedPost?.systemAnnouncementType === 'FiveStartFeedback') {
    content = <FiveStarFeedback feedPost={feedPost} classes={classes} />;
  } else if (feedPost?.systemAnnouncementType === 'MilestoneNewHire') {
    content = <Milestone feedPost={feedPost} classes={classes} />;
  }

  return <div className={classes.Appreciation}>{content}</div>;
}
