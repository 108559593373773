/* eslint-disable no-plusplus */
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import DayView from '../DayView';
import MoveButton from '../MoveButton';
import classes from './styles.module.scss';

const getCurrentWeek = (date) => {
  const week = [];
  const current = new Date(date);

  current.setDate(current.getDate() - current.getDay());
  for (let i = 0; i < 7; i++) {
    week.push(new Date(current));
    current.setDate(current.getDate() + 1);
  }
  return week;
};

export default function WeekType({
  currentDate,
  setCurrentDate,
  setActiveType,
  dateToday,
  events,
  refetch,
  user,
}) {
  const [currentWeek, setCurrentWeek] = useState(getCurrentWeek(currentDate));

  useEffect(() => {
    setCurrentWeek(getCurrentWeek(currentDate));
  }, [currentDate]);

  const getNextWeek = () => {
    setCurrentWeek(
      getCurrentWeek(
        new Date(
          currentWeek[currentWeek.length - 1].setDate(
            currentWeek[currentWeek.length - 1].getDate() + 7
          )
        )
      )
    );
  };

  const getPrevWeek = () => {
    setCurrentWeek(
      getCurrentWeek(
        new Date(
          currentWeek[currentWeek.length - 1].setDate(
            currentWeek[currentWeek.length - 1].getDate() - 7
          )
        )
      )
    );
  };

  return (
    <>
      <div className={classes.WeekMode}>
        <MoveButton
          prev
          handleClick={getPrevWeek}
          style={{
            backgroundColor: 'white',
          }}
        />
        <div className={classes.weekDays}>
          {currentWeek.map((day) => {
            const dayNum = day.getDate();

            return (
              <div
                className={classNames(classes.day)}
                key={day}
                onClick={() => setCurrentDate(day)}
              >
                <span className={classes.currentMongth}>
                  {`${day.toLocaleString('en-US', {
                    month: 'long',
                  })}, ${day.getFullYear()}`.slice(0, 3)}
                </span>

                <span
                  className={classNames(classes.dayNum, {
                    [classes.selected]:
                      day.getDate() === currentDate.getDate() &&
                      day.getMonth() === currentDate.getMonth() &&
                      day.getFullYear() === currentDate.getFullYear(),
                    [classes.today]:
                      dayNum === dateToday.getDate() &&
                      day.getMonth() === dateToday.getMonth() &&
                      day.getFullYear() === dateToday.getFullYear(),
                  })}
                >
                  {dayNum}
                </span>

                <span className={classes.dayOfWeek}>
                  {day
                    .toLocaleString('en-US', {
                      weekday: 'long',
                    })
                    .slice(0, 3)}
                </span>
              </div>
            );
          })}
        </div>
        <MoveButton
          next
          handleClick={getNextWeek}
          style={{
            backgroundColor: 'white',
          }}
        />
      </div>
      <DayView
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        currentWeek={currentWeek}
        weekMode
        setActiveType={setActiveType}
        dateToday={dateToday}
        events={events}
        refetch={refetch}
        user={user}
      />
    </>
  );
}
