/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef } from 'react';

import classes from './styles.module.scss';

export default function QuoteInput({ setIsEditable, quote }) {
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (quote) {
      inputRef.current.innerHTML = quote;
    }
  }, [quote]);

  return (
    <div className={classes.QuoteInput}>
      <blockquote
        onBlur={() => {
          setIsEditable(true);
        }}
        className={classes.input}
        ref={inputRef}
        contentEditable
      />
    </div>
  );
}
