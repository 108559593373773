import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Dot({ active, onClick }) {
  return (
    <div
      onClick={onClick}
      className={classNames(classes.Dot, {
        [classes.active]: active,
      })}
    />
  );
}
