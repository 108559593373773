import React from 'react';

import { JOBS_PORTAL_URL } from '../../../../../constants/main';
import classes from './styles.module.scss';

export default function UrlPreview({ iframeRef }) {
  return (
    <div className={classes.UrlPreview}>
      {/*  {activeLink ? (
        <iframe
          src={links.find((link) => link.type === activeLink)?.url}
          frameBorder="0"
          title="Preview"
        />
      ) : (
        <div className={classes.noLink}>
          Select a social media channel from above to check.
        </div>
      )} */}
      <iframe
        src={JOBS_PORTAL_URL}
        frameBorder="0"
        title="Preview"
        ref={iframeRef}
      />
    </div>
  );
}
