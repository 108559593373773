import React from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import FormDropdown from '../../../../Form/FormDropdown';
import NewPostOptions from './NewPostOptions';
import RadioButton from '../../../../Form/RadioButton';

import classes from './styles.module.scss';
import AdminService from '../../../../../services/AdminService';

export default function PostOptions({
  currentOption,
  setCurrentOption,
  currentTemplate,
  setCurrentTemplate,
  currentDraftJobPost,
  setCurrentDraftJobPost,
  currentActiveJobPost,
  setCurrentActiveJobPost,
}) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const radioButtonLabelStyle = {
    fontFamily: 'Gilroy-SemiBold',
    color: '#C1C5D6',
    fontSize: isTabletOrMobile ? 14 : '',
  };

  return (
    <div className={classes.PostOptions}>
      <h2>{t('dashboardComponents.Jobs.PostAJob.PostOptions.title')}</h2>
      <div className={classes.radios}>
        <RadioButton
          bigAndBold
          label={t(
            'dashboardComponents.Jobs.PostAJob.PostOptions.createNewJobPost'
          )}
          isSelected={currentOption === 'create'}
          onClick={() => setCurrentOption('create')}
          labelStyle={radioButtonLabelStyle}
        />
        {currentOption === 'create' && (
          <NewPostOptions
            currentTemplate={currentTemplate}
            setCurrentTemplate={setCurrentTemplate}
          />
        )}
        <RadioButton
          bigAndBold
          label={t(
            'dashboardComponents.Jobs.PostAJob.PostOptions.editDraftJobPost'
          )}
          isSelected={currentOption === 'edit'}
          onClick={() => setCurrentOption('edit')}
          labelStyle={radioButtonLabelStyle}
        />
        {currentOption === 'edit' && (
          <FormDropdown
            fetchOptions={{
              dataName: 'jobPosDrafttOptionsNewPostOptions',
              getDataHandler: AdminService.getDraftJobPosts,
              labelName: 'jobTitle',
            }}
            width={isTabletOrMobile ? '100%' : 470}
            value={currentDraftJobPost}
            placeholder={t(
              'dashboardComponents.Jobs.PostAJob.PostOptions.selectDraftJobPost'
            )}
            height={45}
            setFieldValue={(_name, option) => setCurrentDraftJobPost(option)}
          />
        )}
        <RadioButton
          bigAndBold
          label={t(
            'dashboardComponents.Jobs.PostAJob.PostOptions.reusePreviousJobPost'
          )}
          isSelected={currentOption === 'reuse'}
          onClick={() => setCurrentOption('reuse')}
          labelStyle={radioButtonLabelStyle}
        />
        {currentOption === 'reuse' && (
          <FormDropdown
            fetchOptions={{
              dataName: 'jobPostOptionsNewPostOptions',
              getDataHandler: AdminService.getJobPosts,
              labelName: 'jobTitle',
            }}
            width={isTabletOrMobile ? '100%' : 470}
            value={currentActiveJobPost}
            placeholder={t(
              'dashboardComponents.Jobs.PostAJob.PostOptions.selectPreviousJobPost'
            )}
            height={45}
            setFieldValue={(_name, option) => setCurrentActiveJobPost(option)}
          />
        )}
      </div>
    </div>
  );
}
