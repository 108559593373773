import React, { useRef, useEffect } from 'react';

import classNames from 'classnames';

import InfoIcon from '../InfoIcon';
import classes from './styles.module.scss';

export default function Switch({
  label,
  isEnabled,
  onClick,
  backgroundColor,
  info,
  infoWidth,
  tooltipDirection,
  readOnly,
  labelStyle,
}) {
  const switchRef = useRef();

  useEffect(() => {
    const color = backgroundColor || '#00E4AC';

    switchRef.current.style.setProperty('--backgroundColor', color);
  }, [backgroundColor]);

  return (
    <div
      role="switch"
      tabIndex={0}
      ref={switchRef}
      aria-checked={isEnabled}
      className={classNames(classes.Switch, {
        [classes.enabled]: isEnabled,
      })}
      onKeyDown={(event) => {
        if (readOnly) {
          return;
        }
        if (event.key === 'Enter') {
          onClick();
        }
      }}
      onClick={() => {
        if (readOnly) {
          return;
        }
        onClick();
      }}
    >
      <span className={classes.label} style={labelStyle}>
        {label}{' '}
        {info && (
          <InfoIcon
            info={info}
            infoWidth={infoWidth}
            tooltipDirection={tooltipDirection}
          />
        )}
      </span>
      <div className={classes.switch}>
        <div className={classes.toggle} />
      </div>
    </div>
  );
}
