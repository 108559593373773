import React from 'react';

import classes from './styles.module.scss';

export default function Tag({ tag, setTags }) {
  return (
    <div className={classes.Tag}>
      <span>{tag}</span>
      <i
        className={classes.removeIcon}
        onClick={() => {
          setTags((prevAddressees) =>
            prevAddressees.filter((tg) => tg !== tag)
          );
        }}
      >
        Remove
      </i>
    </div>
  );
}
