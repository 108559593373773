import React, { useState, useContext, useMemo } from 'react';

import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UiContext } from '../../context/UiContext';
import TextInput from '../../components/Form/TextInput';
import AuthLayout from '../../containers/AuthLayout';
import classes from './styles.module.scss';
import Button from '../../components/Button';
import UserService from '../../services/UserService';

export default function RequestNewPasswordPage() {
  const { showUnknownErrorModal } = useContext(UiContext);
  const navigate = useNavigate();
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .trim()
          .email(t('pages.RequestNewPasswordPage.emailValidationError'))
          .required(t('pages.RequestNewPasswordPage.requiredFieldError')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await UserService.resetPassword({ email: values.email });
        navigate('/request-password/success');
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      }
    },
  });

  return (
    <AuthLayout>
      <div className={classes.RequestNewPasswordPage}>
        <Helmet>
          <title>{t('pages.RequestNewPasswordPage.pageTitle')}</title>
        </Helmet>
        <div className={classes.container}>
          <h1>{t('pages.RequestNewPasswordPage.pageHeader')}</h1>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.inputContainer}>
              <TextInput
                error={formik.errors.email}
                touched={isTriedToSubmit}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t('pages.RequestNewPasswordPage.emailLabel')}
                name="email"
                placeholder={t('pages.RequestNewPasswordPage.emailPlaceholder')}
                height={55}
              />
            </div>
            <Button
              disabled={!formik.values.email}
              width={400}
              onClick={() => {
                setIsTriedToSubmit(true);
                formik.handleSubmit();
              }}
            >
              {t('pages.RequestNewPasswordPage.submitButton')}
            </Button>
          </form>
          <p className={classes.helper}>
            {t('pages.RequestNewPasswordPage.successMessage')}
          </p>
        </div>
      </div>
    </AuthLayout>
  );
}
