import React from 'react';

import { useMediaQuery } from 'react-responsive';

import Media from './Media';
import MobileGallery from './MobileGallery';

import classes from './styles.module.scss';

export default function Gallery({ media, feedPost, isRead }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  if (!media || !media.length) {
    return null;
  }

  if (isTabletOrMobile) {
    return <MobileGallery media={media} />;
  }

  let flexFirstCol = 1;
  let flexSecondCol = 1;
  let flexThirdCol;

  if (media.length === 1) {
    flexFirstCol = 1;
  } else if (media.length > 1) {
    flexFirstCol = 0.46;
    flexSecondCol = 0.56;
  } else if (media.length > 2) {
    flexFirstCol = 0.46;
    flexSecondCol = 0.25;
    flexThirdCol = 0.29;
  }

  return (
    <div className={classes.Gallery}>
      <div className={classes.container}>
        {media.length ? (
          <div
            className={classes.firstCol}
            style={{
              flex: flexFirstCol,
            }}
          >
            <Media
              isRead={isRead}
              feedPost={feedPost}
              media={media[0]}
              index={0}
              allMedia={media}
            />
          </div>
        ) : null}
        {media.length > 1 ? (
          <div
            className={classes.secondCol}
            style={{
              flex: flexSecondCol,
            }}
          >
            <div>
              <Media
                isRead={isRead}
                feedPost={feedPost}
                media={media[1]}
                index={1}
                allMedia={media}
              />
            </div>
            {media.length > 3 && (
              <div>
                <Media
                  isRead={isRead}
                  feedPost={feedPost}
                  media={media[2]}
                  index={2}
                  allMedia={media}
                />
              </div>
            )}
          </div>
        ) : null}
        {media.length > 2 ? (
          <div
            className={classes.thirdCol}
            style={{
              flex: flexThirdCol,
            }}
          >
            <Media
              isRead={isRead}
              feedPost={feedPost}
              media={media.length > 3 ? media[3] : media[2]}
              mediaLeft={Math.max(0, media.length - 4)}
              index={media.length > 3 ? 3 : 2}
              allMedia={media}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
