import React from 'react';

import { useTranslation } from 'react-i18next';

import AddOrEditAssessment from './AddOrEditAssessment';
import ViewAssessment from './ViewAssessment';
import AdminService from '../../../../../services/AdminService';
import TableView from '../../TableView';

export default function Assessments({ setItemCount }) {
  const { t } = useTranslation();

  const columns = [
    {
      name: t(
        'dashboardComponents.Console.JobManagement.Assessments.Assessment Name'
      ),
      field: 'name',
      sortable: true,
    },
    {
      name: t('dashboardComponents.Console.JobManagement.Assessments.Notes'),
      field: 'note',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.JobManagement.Assessments.No. of Questions'
      ),
      field: 'questionCount',
      sortable: true,
    },
    {
      name: t('common.createdOn'),
      field: 'createdAt',
      sortable: true,
      type: 'date',
    },
  ];

  return (
    <TableView
      setItemCount={setItemCount}
      dataName={t(
        'dashboardComponents.Console.JobManagement.Assessments.Assessment'
      )}
      getDataHandler={AdminService.getAssessment}
      deleteDataHandler={AdminService.deleteeAssessments}
      createDataHandler={AdminService.createAssessment}
      updateDataHandler={AdminService.updateAssessment}
      viewLeftColumnFields={[]}
      viewRightColumnFields={[]}
      formLeftColumnFields={[]}
      formRightColumnFields={[]}
      columns={columns}
      viewComponent={ViewAssessment}
      addOrEditComponent={AddOrEditAssessment}
    />
  );
}
