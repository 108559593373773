import React, { useState, useEffect, useRef } from 'react';

import { createPortal } from 'react-dom';
import classNames from 'classnames';

import Button from '../../../Button';
import classes from './styles.module.scss';

export default function SidePopup({
  children,
  isVisible,
  handleClose,
  title,
  isOnTop,
  hasNoAnimation,
  isOverlayed,
  leftButtonLabel,
  rightButtonLabel,
  onLeftButtonClick,
  onRightButtonClick,
  isRightButtonDisabled,
  switchPrev,
  switchNext,
  noButtons,
  rightButtonComponent,
  leftButtonVariant,
  isLeftButtonDisabled,
}) {
  const [isBackdropVisible, setIsBacdropVisible] = useState(false);
  const [isContainerVisible, setIsContainerVisible] = useState(false);

  const sidePopupRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    if (isVisible) {
      setIsBacdropVisible(true);
      setIsContainerVisible(true);
    } else {
      setIsContainerVisible(false);
      setTimeout(() => {
        setIsBacdropVisible(false);
      }, 200);
    }
  }, [isVisible]);

  useEffect(() => {
    const maxContentHeight =
      sidePopupRef.current.clientHeight - 47 - 58 - 22 - 158;

    sidePopupRef.current.style.setProperty(
      '--maxContentHeight',
      `${maxContentHeight}px`
    );
  }, [isVisible]);

  const RightButtonComponent = rightButtonComponent;

  let RightButton = (
    <Button
      width={250}
      height={52}
      onClick={onRightButtonClick}
      disabled={isRightButtonDisabled}
      variant="secondary"
    >
      {rightButtonLabel}
    </Button>
  );

  if (RightButtonComponent) {
    RightButton = (
      <RightButtonComponent
        width={250}
        height={52}
        onClick={onRightButtonClick}
        disabled={isRightButtonDisabled}
      >
        {rightButtonLabel}
      </RightButtonComponent>
    );
  }

  return createPortal(
    <div
      ref={sidePopupRef}
      className={classNames(classes.SidePopup, {
        [classes.visible]: isBackdropVisible,
        [classes.onTop]: isOnTop,
        [classes.noAnimation]: hasNoAnimation,
        [classes.overlayd]: isOverlayed,
        [classes.noButtons]: noButtons,
      })}
    >
      <div
        className={classNames(classes.container, {
          [classes.visible]: isContainerVisible,
        })}
      >
        <div className={classes.closeIconContainer}>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </div>
        <header className={classes.header}>
          <h1>{title}</h1>
        </header>
        {isVisible && (
          <div className={classes.content} ref={contentRef}>
            {children}
          </div>
        )}
        {isVisible && (
          <footer className={classes.footer}>
            <>
              {switchPrev && (
                <i className={classes.prevIcon} onClick={switchPrev}>
                  Previous
                </i>
              )}
              <Button
                width={250}
                height={52}
                variant={leftButtonVariant || 'secondary'}
                onClick={onLeftButtonClick}
                disabled={isLeftButtonDisabled}
              >
                {leftButtonLabel}
              </Button>
              {RightButton}
              {switchNext && (
                <i className={classes.nextIcon} onClick={switchNext}>
                  Next
                </i>
              )}
            </>
          </footer>
        )}
      </div>
    </div>,
    document.getElementById('dashboardPageContainer') || document.body
  );
}
