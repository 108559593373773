/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Districts from './Districts';
import Restaurants from './Restaurants';
import Tabs from '../Tabs';

import classes from './styles.module.scss';
import Cities from './Cities';

export default function RestaurantManagement() {
  const [restaurantCount, setRestaurantCount] = useState(0);
  const [cityCount, setCityCount] = useState(0);
  const [districtCount, setDistrictCount] = useState(0);

  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(
    t('dashboardComponents.Console.RestaurantManagement.Restaurant')
  );
  const tabs = [
    t('dashboardComponents.Console.RestaurantManagement.Restaurant'),
    t('dashboardComponents.Console.RestaurantManagement.City'),
    t('dashboardComponents.Console.RestaurantManagement.District'),
  ];

  return (
    <div className={classes.RestaurantManagement}>
      <Tabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        name="restaurant"
        tabs={tabs}
        countData={{
          [t('dashboardComponents.Console.RestaurantManagement.Restaurant')]:
            restaurantCount,
          [t('dashboardComponents.Console.RestaurantManagement.City')]:
            cityCount,
          [t('dashboardComponents.Console.RestaurantManagement.District')]:
            districtCount,
        }}
      />

      <div className={classes.container}>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.RestaurantManagement.Restaurant')
                ? 'block'
                : 'none',
          }}
        >
          <Restaurants setItemCount={setRestaurantCount} />
        </div>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.RestaurantManagement.City')
                ? 'block'
                : 'none',
          }}
        >
          <Cities setItemCount={setCityCount} />
        </div>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.RestaurantManagement.District')
                ? 'block'
                : 'none',
          }}
        >
          <Districts setItemCount={setDistrictCount} />
        </div>
      </div>
    </div>
  );
}
