/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import AdminService from '../../../../../services/AdminService';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import { UiContext } from '../../../../../context/UiContext';
import { JobsContext } from '../../../../../context/JobsContext';

import classes from './styles.module.scss';

export default function DotMenu({
  options,
  isTransparent,
  width,
  handleEdit,
  hide,
  note,
  candidate,
  refetch,
  candidateId,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const { showModal, showUnknownErrorModal, showNotification, setIsFetching } =
    useContext(UiContext);

  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  const { t } = useTranslation();

  const { showReportCandidateModal, showShareModal } = useContext(JobsContext);

  const deleteNote = () => {
    showModal({
      icon: 'delete',
      title: t(
        'dashboardComponents.Candidate.CandidateHeader.DotMenu.Delete Note'
      ),
      text: t(
        'dashboardComponents.Candidate.CandidateHeader.DotMenu.Are you sure you want to delete?'
      ),
      onConfirm: async () => {
        try {
          setIsFetching(true);
          await AdminService.deleteNote({
            jobApplicationId: candidateId,
            noteId: note.id,
          });
          if (refetch) {
            await refetch();
          }
          showNotification({
            text: t(
              'dashboardComponents.Candidate.CandidateHeader.DotMenu.Note deleted successfully'
            ),
          });
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
        } finally {
          setIsFetching(false);
        }
      },
      onCancel: () => {},
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
    });
  };

  const openEmail = () => {
    window.open(`mailto:${candidate?.userProfile.email}`);
  };

  return (
    <div
      ref={dropdownRef}
      className={classNames(classes.dotMenu, {
        [classes.active]: isOptionsListVisible,
        [classes.transparent]: isTransparent,
        [classes.hide]: hide,
      })}
    >
      <div
        className={classes.selector}
        onClick={() => setIsOptionsListVisible((prevState) => !prevState)}
      >
        ...
      </div>
      {isOptionsListVisible && (
        <div className={classes.options} style={{ width }}>
          <ul>
            {options.map((option) => {
              switch (option) {
                case 'Chat(coming soon)':
                  return (
                    <li key={option} className={classes.disabled}>
                      {t(
                        `dashboardComponents.Candidate.CandidateHeader.DotMenu.${option}`
                      )}
                    </li>
                  );
                case 'Share':
                  return (
                    <li
                      key={option}
                      onClick={() => {
                        setIsOptionsListVisible(false);
                        showShareModal({ candidate });
                      }}
                    >
                      {t(
                        `dashboardComponents.Candidate.CandidateHeader.DotMenu.${option}`
                      )}
                    </li>
                  );
                case 'Email Candidate':
                  return (
                    <li
                      key={option}
                      onClick={() => {
                        setIsOptionsListVisible(false);
                        openEmail();
                      }}
                    >
                      {t(
                        `dashboardComponents.Candidate.CandidateHeader.DotMenu.${option}`
                      )}
                    </li>
                  );
                case 'Edit':
                  return (
                    <li
                      key={option}
                      onClick={() => {
                        setIsOptionsListVisible(false);
                        handleEdit((prev) => !prev);
                      }}
                    >
                      {t(
                        `dashboardComponents.Candidate.CandidateHeader.DotMenu.${option}`
                      )}
                    </li>
                  );
                case 'Block & Report':
                  return (
                    <li
                      key={option}
                      onClick={() => {
                        setIsOptionsListVisible(false);
                        showReportCandidateModal({ candidate, refetch });
                      }}
                    >
                      {t(
                        `dashboardComponents.Candidate.CandidateHeader.DotMenu.${option}`
                      )}
                    </li>
                  );
                case 'Delete':
                  return (
                    <li
                      key={option}
                      onClick={() => {
                        setIsOptionsListVisible(false);
                        deleteNote();
                      }}
                    >
                      {t(
                        `dashboardComponents.Candidate.CandidateHeader.DotMenu.${option}`
                      )}
                    </li>
                  );
                default:
                  return (
                    <li
                      key={option}
                      onClick={() => {
                        setIsOptionsListVisible(false);
                      }}
                    >
                      {t(
                        `dashboardComponents.Candidate.CandidateHeader.DotMenu.${option}`
                      )}
                    </li>
                  );
              }
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
