import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function QualifiedSwitch({
  candidatesFilter,
  setCandidatesFilter,
  qualifiedCount,
  disqualifiedCount,
  top,
  left,
  right,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.QualifiedSwitch} style={{ top, left, right }}>
      <div
        role="tab"
        tabIndex={0}
        className={classNames(classes.option, {
          [classes.active]:
            candidatesFilter ===
            t('dashboardComponents.Jobs.MyJobs.JobDetails.qualified'),
        })}
        onClick={() =>
          setCandidatesFilter(
            t('dashboardComponents.Jobs.MyJobs.JobDetails.qualified')
          )
        }
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setCandidatesFilter(
              t('dashboardComponents.Jobs.MyJobs.JobDetails.qualified')
            );
          }
        }}
      >
        {t('dashboardComponents.Jobs.MyJobs.JobDetails.qualified')}{' '}
        <span className={classes.count}>{qualifiedCount || 0}</span>
      </div>
      <div
        role="tab"
        tabIndex={0}
        className={classNames(classes.option, {
          [classes.active]:
            candidatesFilter ===
            t('dashboardComponents.Jobs.MyJobs.JobDetails.disqualified'),
        })}
        onClick={() =>
          setCandidatesFilter(
            t('dashboardComponents.Jobs.MyJobs.JobDetails.disqualified')
          )
        }
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setCandidatesFilter(
              t('dashboardComponents.Jobs.MyJobs.JobDetails.disqualified')
            );
          }
        }}
      >
        {t('dashboardComponents.Jobs.MyJobs.JobDetails.disqualified')}{' '}
        <span className={classes.count}>{disqualifiedCount || 0}</span>
      </div>
    </div>
  );
}
