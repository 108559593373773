import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { useQuery } from '@tanstack/react-query';

import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';

import classes from './styles.module.scss';
import AdminService from '../../../services/AdminService';

const currentYear = new Date().getFullYear();
const currentMonth = moment().format('MMMM');
const currentMonthNum = moment().format('MM');

export default function Dashboard() {
  const [month, setMonth] = useState({
    label: currentMonth,
    value: currentMonthNum,
  });
  const [location, setLocation] = useState('');

  const { data } = useQuery({
    queryKey: ['dashboardStats', month, location],
    queryFn: () =>
      AdminService.getDashboardStats({
        startDate: moment().format(
          `${new Date().getFullYear()}-${month?.value}-01`
        ),
        endDate: moment().format(
          `${currentYear}-${month?.value}-${moment(
            `${currentYear}-${month.value}`,
            'YYYY-MM'
          ).daysInMonth()}`
        ),
        periodType: 'Month',
        locationIds: location.value,
      }),
    keepPreviousData: true,
  });

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = 'unset';
    };
  }, []);

  return (
    <div className={classes.Dashboard}>
      <LeftPanel
        location={location}
        setLocation={setLocation}
        month={month}
        setMonth={setMonth}
        data={data}
      />
      <RightPanel leftPanelData={data} />
    </div>
  );
}
