import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import useDebouncedSearchWithHistory from '../../../../../hooks/useDebouncedSearchWithHistory';
import { UiContext } from '../../../../../context/UiContext';
import ViewRestaurant from './ViewRestaurant';
import tableColsToSortOptions from '../../../../../helpers/tableColsToSortOptions';
import AdminService from '../../../../../services/AdminService';
import TableMenu from '../../TableMenu';
import Table from '../../../../Table';
import AddOrEditRestaurant from './AddOrEditRestaurant';

export default function Restaurants({ setItemCount }) {
  const [activeAction, setActiveAction] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedRows, setSelectedRows] = useState([]);
  const [isViewRestaurantVisible, setIsViewRestaurantVisible] = useState(false);
  const [currentRestaurantId, setCurrentRestaurantId] = useState(null);
  const [isAddRestaurantVisible, setIsAddRestaurantVisible] = useState(false);
  const [isEditRestaurantVisible, setIsEditRestaurantVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();
  const [activeSortOption, setActiveSortOption] = useState({
    label: t('common.createdOn'),
    value: 'createdAt',
  });

  const {
    showModal,
    showUnknownErrorModal,
    showSelectionNeededModal,
    setIsFetching,
  } = useContext(UiContext);

  const { debouncedSearchTerm, searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    'restaurantsSearchHistory'
  );

  const { data: restaurantsPages, refetch } = useQuery({
    queryKey: [
      'restaurants',
      sortOrder,
      activeSortOption.value,
      debouncedSearchTerm,
      currentPage,
    ],
    queryFn: () =>
      AdminService.getRestaurants({
        orderBy: activeSortOption.value || 'id',
        term: debouncedSearchTerm,
        sortOrder,
        pageNumber: currentPage,
      }),
    keepPreviousData: true,
  });

  useEffect(() => {
    if (restaurantsPages) {
      setItemCount(restaurantsPages.totalCount);
    }
  }, [restaurantsPages, setItemCount]);

  const rows = restaurantsPages?.data || [];

  useEffect(() => {
    refetch();
  }, [activeSortOption, debouncedSearchTerm, refetch, sortOrder]);

  const showViewData = (id) => {
    setCurrentRestaurantId(id);
    setIsViewRestaurantVisible(true);
  };

  const showEditRestaurant = (id) => {
    if (id) {
      setSelectedRows([id]);
    }

    setIsEditRestaurantVisible(true);
  };

  const deleteRestaurants = async () => {
    try {
      setIsFetching(true);
      const promises = selectedRows.map((row) =>
        AdminService.deleteRestaurant({ id: row })
      );
      await Promise.all(promises);
      showModal({
        title: t(
          'dashboardComponents.Console.RestaurantManagement.Restaurants.viewRestaurant.successTitle'
        ),
        text: t(
          'dashboardComponents.Console.RestaurantManagement.Restaurants.viewRestaurant.successText'
        ),
        dismissButtonLabel: t('common.gotIt'),
        dismissButtonVariant: '',
      });
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      await refetch();
      setIsFetching(false);
    }
  };

  const actions = [
    {
      label: t('common.add'),
      value: 'Add',
      onClick: () => {
        setIsAddRestaurantVisible(true);
      },
    },
    {
      label: t('common.edit'),
      value: 'Edit',
      onClick: () => {
        if (selectedRows.length) {
          showEditRestaurant();
        } else {
          showSelectionNeededModal();
        }
      },
    },
    {
      label: t('common.delete'),
      value: 'Delete',
      onClick: () => {
        if (selectedRows.length) {
          showModal({
            icon: 'delete',
            title: t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.viewRestaurant.deleteConfirmationTitle'
            ),
            text: t(
              'dashboardComponents.Console.RestaurantManagement.Restaurants.viewRestaurant.deleteConfirmationText'
            ),
            dismissButtonLabel: t('common.no'),
            confirmButtonLabel: t('common.yes'),
            onConfirm: deleteRestaurants,
          });
        } else {
          showSelectionNeededModal();
        }
      },
    },
  ];

  const columns = [
    {
      name: t(
        'dashboardComponents.Console.RestaurantManagement.Restaurants.columns.Name'
      ),
      field: 'name',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.RestaurantManagement.Restaurants.columns.Address'
      ),
      field: 'address',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.RestaurantManagement.Restaurants.columns.District'
      ),
      field: 'district.name',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.RestaurantManagement.Restaurants.columns.City'
      ),
      field: 'city.name',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.RestaurantManagement.Restaurants.columns.Country'
      ),
      field: 'country',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.RestaurantManagement.Restaurants.columns.Zipcode'
      ),
      field: 'postCode',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.RestaurantManagement.Restaurants.columns.RGM'
      ),
      field: 'managers[0].userName',
      sortable: false,
    },
    {
      name: t(
        'dashboardComponents.Console.RestaurantManagement.Restaurants.columns.Lat'
      ),
      field: 'latitude',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.RestaurantManagement.Restaurants.columns.Long'
      ),
      field: 'longitude',
      sortable: true,
    },
    {
      name: t('common.createdOn'),
      field: 'createdAt',
      sortable: true,
      type: 'date',
    },
  ];

  const sortOptions = tableColsToSortOptions(columns);

  return (
    <div>
      <TableMenu
        actions={actions}
        activeAction={activeAction}
        setActiveAction={setActiveAction}
        dangerAction="Delete"
        sortOptions={sortOptions}
        activeSortOption={activeSortOption}
        setActiveSortOption={setActiveSortOption}
        selectedCount={selectedRows.length}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        searchHistory={searchHistory}
        setSortOrder={setSortOrder}
        currentPage={currentPage}
        totalPages={restaurantsPages?.totalPages || 1}
        setCurrentPage={setCurrentPage}
      />
      <Table
        name="Restaurants"
        rows={rows}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        sortOptions={sortOptions}
        setActiveSortOption={setActiveSortOption}
        activeSortOption={activeSortOption}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        columns={columns}
        onRowClick={showViewData}
      />
      <ViewRestaurant
        currentRestaurantId={currentRestaurantId}
        isVisible={isViewRestaurantVisible}
        handleClose={() => setIsViewRestaurantVisible(false)}
        restaurants={rows}
        refetch={refetch}
        isOverlayed={isEditRestaurantVisible}
        showEditRestaurant={showEditRestaurant}
      />

      <AddOrEditRestaurant
        isVisible={isAddRestaurantVisible}
        handleClose={() => setIsAddRestaurantVisible(false)}
        refetch={refetch}
        showAgain={() => setIsAddRestaurantVisible(true)}
        isAdd
        restaurants={rows}
      />

      <AddOrEditRestaurant
        isVisible={isEditRestaurantVisible}
        handleClose={() => setIsEditRestaurantVisible(false)}
        refetch={refetch}
        isEdit
        selectedRows={selectedRows}
        restaurants={rows}
        hasNoAnimation={isViewRestaurantVisible}
      />
    </div>
  );
}
