import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import EventPopup from '../EventPopup';
import classes from './styles.module.scss';

const getEventDurationInMinutes = (startTime, endTime) => {
  const [hours1, minutes1] = startTime.split(':').map(Number);
  const [hours2, minutes2] = endTime.split(':').map(Number);

  const hoursDiff = hours2 - hours1;
  const minutesDiff = minutes2 - minutes1;

  const totalDiffInMinutes = hoursDiff * 60 + minutesDiff;

  return totalDiffInMinutes;
};

export default function Week({
  events,
  setActiveType,
  setCurrentDate,
  selectedEvent,
  setSelectedEvent,
  isEventDetailsVisible,
  setIsEventDetailsVisible,
  setIsContextMenuOpened,
  setContextMenuClientX,
  setContextMenuClientY,
  isContextMenuOpened,
}) {
  const { t } = useTranslation();

  const handleClick = () => {
    setActiveType('Day');

    setCurrentDate(new Date(events[0].eventDate));
  };

  const handleRightClick = (e, event) => {
    e.preventDefault();
    setSelectedEvent(event);
    setIsContextMenuOpened(!isContextMenuOpened);
    setContextMenuClientX(e.clientX);
    setContextMenuClientY(e.clientY);
  };

  return (
    <div
      className={classNames(classes.week, {
        [classes.oneCard]: events.length === 1,
        [classes.twoCard]: events.length === 2,
        [classes.threeCard]: events.length === 3,
        [classes.fourCard]: events.length === 4,
        [classes.noCard]: events.length > 4,
      })}
    >
      {events.length > 4 ? (
        <div className={classes.noCard}>
          <span className={classes.amount}>
            4+ {t('dashboardComponents.Calendar.EventCard.Week.events')}
          </span>
          <div className={classes.seeAll} onClick={handleClick}>
            {t('dashboardComponents.Calendar.EventCard.Week.clickToSeeAll')}
          </div>
        </div>
      ) : (
        events.map((event) => {
          const {
            id,
            type,
            userFirstName,
            userLastName,
            time,
            jobPost,
            startTime,
            endTime,
          } = event;
          const text = `${type} for ${jobPost.jobTitle}`;
          const person = `${userFirstName} ${userLastName}`;
          const eventTopPosition = Math.ceil(
            (95 / 60) * startTime.split(':')[1]
          );
          const eventHeight = `${Math.max(
            Math.round(
              (95 / 60) * getEventDurationInMinutes(startTime, endTime)
            ),
            48
          )}px`;

          return (
            <div
              key={`${id}${type}`}
              className={classNames(classes.cardContainer, {
                [classes.selected]:
                  (isEventDetailsVisible || isContextMenuOpened) &&
                  selectedEvent === event,
              })}
              style={{
                top: eventTopPosition,
                height: eventHeight,
              }}
            >
              <div
                className={classNames(classes.card, {
                  [classes.joining]: type === 'Joining',
                  [classes.onboarding]: type === 'Onboarding',
                  [classes.accepted]: event?.event?.status === 'Accepted',
                  [classes.canceled]:
                    event?.event?.status === 'Canceled' ||
                    event?.event?.status === 'Fail',
                  [classes.completed]: event?.event?.status === 'Pass',
                  [classes.declined]: event?.event?.status === 'Declined',
                  [classes.pending]:
                    event?.event?.status === 'Scheduled' ||
                    event?.event?.status === 'ChangeRequest',
                })}
                onClick={() => {
                  setSelectedEvent(event);
                  setIsEventDetailsVisible(true);
                }}
                onContextMenu={(e) => handleRightClick(e, event)}
              >
                <div className={classes.cardInfo}>
                  <div>
                    <h2 className={classes.title}>{text}</h2>
                    <p className={classes.person}>{person}</p>
                  </div>
                  <p className={classes.date}>{time}</p>
                </div>
              </div>
              <div className={classes.popup}>
                <EventPopup event={event} week />
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}
