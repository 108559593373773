import axios from 'axios';

export async function getNonPaginatedData({
  orderBy = 'createdAt',
  sortOrder = 'asc',
  term,
  url,
}) {
  const order = `${orderBy}:${sortOrder}`;
  const response = await axios.get(url, {
    params: {
      Term: term,
      OrderBy: order,
    },
  });

  return response.data;
}

export async function getData({
  term,
  pageNumber,
  pageSize = 10,
  orderBy = 'createdAt',
  sortOrder = 'asc',
  url,
  ...rest
}) {
  const order = `${orderBy}:${sortOrder}`;
  const response = await axios.get(url, {
    params: {
      Term: term,
      PageNumber: pageNumber,
      PageSize: pageSize,
      OrderBy: order,
      ...rest,
    },
  });

  let nextPage;
  let totalCount = response.data.length;
  let totalPages;

  if (response.headers['x-pagination']) {
    try {
      const xPagination = JSON.parse(response.headers['x-pagination']);
      if (xPagination.HasNext) {
        nextPage = xPagination.CurrentPage + 1;
      }
      totalCount = xPagination.TotalCount;
      totalPages = xPagination.TotalPages;
    } catch (error) {
      console.log(error);
    }
  }
  // return response.data;

  return { nextPage, totalCount, data: response.data, totalPages };
}
