/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';

import classNames from 'classnames';

import useOnClickOutside from '../../../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

function camelCaseToSpace(str) {
  const result = str?.replace(/([a-z])([A-Z])/g, '$1 $2');
  return result[0].toUpperCase() + result.slice(1);
}

export default function Dropdown({
  options,
  activeOption,
  setActiveOption,
  placeholder,
  width,
  height,
  style,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  return (
    <div
      ref={dropdownRef}
      className={classNames(classes.Dropdown, {
        [classes.open]: isOptionsListVisible,
      })}
      tabIndex={0}
      role="listbox"
    >
      <div
        style={{ width, height, ...style }}
        className={classes.selector}
        onClick={() => setIsOptionsListVisible((prevState) => !prevState)}
      >
        {activeOption ? (
          <span className={classes.hasOption}>
            {camelCaseToSpace(activeOption)}
          </span>
        ) : (
          <span>{placeholder}</span>
        )}
        <div className={classes.arrow} />
      </div>
      {isOptionsListVisible && (
        <div className={classes.optionsContainer}>
          <div className={classes.options}>
            <ul>
              {options?.map((option) => (
                <li
                  key={option}
                  onClick={() => {
                    setActiveOption(option);
                    setIsOptionsListVisible(false);

                    if (option.onClick) {
                      option.onClick();
                    }
                  }}
                  className={classNames({
                    [classes.active]: activeOption === option,
                    [classes.title]: option,
                  })}
                >
                  {camelCaseToSpace(option)}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
