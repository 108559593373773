/* eslint-disable no-plusplus */
import React, { useContext, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import PasswordInput from '../PasswordInput';
import Button from '../../Button';

import { UiContext } from '../../../../../context/UiContext';
import UserService from '../../../../../services/UserService';
import classes from './styles.module.scss';

function checkConsecutiveChars(str, number) {
  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    let counter = 1;

    for (let j = i + 1; j < str.length; j++) {
      if (str[j] !== char) {
        break;
      }
      if (str[j] === char) {
        counter++;
      }
      if (counter === number) {
        return true;
      }
    }
  }

  for (let i = 0; i < str.length; i++) {
    let counter = 1;

    for (let j = i + 1; j < str.length; j++) {
      if (str[j].charCodeAt() - str[j - 1].charCodeAt() !== 1) {
        break;
      }
      if (str[j].charCodeAt() - str[j - 1].charCodeAt() === 1) {
        counter++;
      }

      if (counter === number) {
        return true;
      }
    }
  }

  return false;
}

export default function SetNewPassword({ handleCancel, isEditing }) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const { showNotification, showUnknownErrorModal, setIsFetching } =
    useContext(UiContext);

  const { t } = useTranslation();

  const isPasswordHasEnoughLength = newPassword.length >= 8;
  const isPasswordIncludesLetter = /[a-zA-Z]/.test(newPassword);
  const isPasswordIncludesNumber = /[0-9]/.test(newPassword);
  const isPasswordsMatch =
    newPassword === confirmNewPassword && newPassword.length !== 0;

  const isPasswordHasntConsecutiveChars =
    !checkConsecutiveChars(newPassword, 4) && newPassword.length !== 0;

  const passwordValidation =
    isPasswordHasEnoughLength &&
    isPasswordIncludesLetter &&
    isPasswordIncludesNumber &&
    isPasswordsMatch &&
    isPasswordHasntConsecutiveChars;

  const handleSave = async () => {
    try {
      setIsFetching(true);
      await UserService.updatePassword({ newPassword, oldPassword });
      showNotification({
        text: t(
          'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordChangedNotification'
        ),
      });
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      handleCancel();
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.SetNewPassword}>
      <h3 className={classes.title}>
        {t('dashboardComponents.ProfileSettings.Password.SetNewPassword.title')}
      </h3>
      <div className={classes.container}>
        <div className={classes.column}>
          <PasswordInput
            readOnly={!isEditing}
            value={oldPassword}
            name="oldPassword"
            label={t(
              'dashboardComponents.ProfileSettings.Password.SetNewPassword.oldPasswordLabel'
            )}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </div>
        <div className={classes.column}>
          <PasswordInput
            readOnly={!isEditing}
            value={newPassword}
            name="newPassword"
            label={t(
              'dashboardComponents.ProfileSettings.Password.SetNewPassword.newPasswordLabel'
            )}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <PasswordInput
            readOnly={!isEditing}
            value={confirmNewPassword}
            name="confirmNewPassword"
            label={t(
              'dashboardComponents.ProfileSettings.Password.SetNewPassword.confirmNewPasswordLabel'
            )}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </div>
      </div>

      <div className={classes.validations}>
        <div className={classes.row}>
          <span className={classes.text}>
            {t(
              'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordsMust'
            )}
          </span>
          <ul className={classes.list}>
            <li
              className={classNames(classes.rule, {
                [classes.activeRule]: isPasswordHasEnoughLength,
              })}
            >
              {t(
                'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordRequirements.length'
              )}
            </li>
            <li
              className={classNames(classes.rule, {
                [classes.activeRule]: isPasswordIncludesLetter,
              })}
            >
              {t(
                'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordRequirements.letters'
              )}
            </li>
            <li
              className={classNames(classes.rule, {
                [classes.activeRule]: isPasswordIncludesNumber,
              })}
            >
              {t(
                'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordRequirements.numbers'
              )}
            </li>
            <li
              className={classNames(classes.rule, {
                [classes.activeRule]: isPasswordsMatch,
              })}
            >
              {t(
                'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordRequirements.match'
              )}
            </li>
          </ul>
        </div>
        <div className={classes.row}>
          <span className={classes.text}>
            {t(
              'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordsMustNot'
            )}
          </span>
          <ul className={classes.list}>
            <li
              className={classNames(classes.rule, {
                [classes.activeRule]: isPasswordHasntConsecutiveChars,
              })}
            >
              {t(
                'dashboardComponents.ProfileSettings.Password.SetNewPassword.passwordRequirements.consecutiveChars'
              )}
            </li>
          </ul>
        </div>
      </div>
      {isEditing && (
        <div className={classes.buttons}>
          <Button onClick={() => handleCancel()}>{t('common.cancel')}</Button>
          <Button
            type="save"
            disabled={!passwordValidation}
            onClick={handleSave}
          >
            {t('common.save')}
          </Button>
        </div>
      )}
    </div>
  );
}
