import classNames from 'classnames';
import React from 'react';
import DayView from '../DayView';
import MoveButton from '../MoveButton';
import classes from './styles.module.scss';

export default function DayType({
  currentDate,
  setCurrentDate,
  dateToday,
  events,
  refetch,
  user,
}) {
  const getPrevDay = () => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 1)));
  };

  const getNextDay = () => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 1)));
  };

  return (
    <>
      <div className={classes.DayMode}>
        <div className={classes.date}>
          <span className={classes.month}>
            {`${currentDate.toLocaleString('en-US', {
              month: 'long',
            })}`}
          </span>
          <span
            className={classNames(classes.day, {
              [classes.today]:
                currentDate.getDate() === dateToday.getDate() &&
                currentDate.getMonth() === dateToday.getMonth() &&
                currentDate.getFullYear() === dateToday.getFullYear(),
            })}
          >
            {currentDate.getDate()}
          </span>
        </div>
        <div className={classes.buttons}>
          <MoveButton prev handleClick={getPrevDay} />
          <MoveButton next handleClick={getNextDay} />
        </div>
      </div>
      <DayView
        currentDate={currentDate}
        dateToday={dateToday}
        events={events}
        refetch={refetch}
        user={user}
      />
    </>
  );
}
