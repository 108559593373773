import React, { createContext, useState, useEffect } from 'react';

export const PreventNavigation = createContext();

export default function PreventNavigationProvider({ children }) {
  const [isNavigationAllowed, setIsNavigationAllowed] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [navigationLink, setNavigationLink] = useState('');

  useEffect(() => {
    if (isNavigationAllowed) {
      setShowModal(false);
      setNavigationLink('');
    }
  }, [isNavigationAllowed]);

  const value = {
    isNavigationAllowed,
    setIsNavigationAllowed,
    showModal,
    setShowModal,
    navigationLink,
    setNavigationLink,
  };

  return (
    <PreventNavigation.Provider value={value}>
      {children}
    </PreventNavigation.Provider>
  );
}
