/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';

import { useMediaQuery } from 'react-responsive';

import ArrowMenu from '../../../../../../ArrowMenu';

import threeDotsIcon from '../../../../../../../assets/images/dashboard/discover/textEditor/files/threeDots.svg';
import fileIcons from '../../../../../../../constants/fileIcons';
import classes from './styles.module.scss';

export default function File({
  file,
  setFiles,
  progress,
  setUploadedFiles,
  fileName,
  index,
  setFileNames,
  insertFile,
}) {
  const [isArrowMenuVisible, setIsArrowMenuVisible] = useState(false);
  const [newFileName, setNewFileName] = useState(file.name);
  const [isEditing, setIsEditing] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const arrowMenuOptions = [
    {
      label: 'Insert into text',
      onClick: () => {
        insertFile(file, fileName);
      },
    },
    {
      label: 'Rename',
      onClick: () => {
        setIsEditing(true);
      },
    },
    {
      label: 'Delete',
      onClick: () => {
        setFiles((prevFiles) => prevFiles.filter((f) => f.name !== file.name));
        setUploadedFiles((prevFiles) =>
          prevFiles.filter((f) => f.displayName !== file.name)
        );
        setFileNames((prevState) => [
          ...prevState.slice(0, index),
          ...prevState.slice(index + 1),
        ]);
      },
    },
  ];

  const renameFile = () => {
    setFileNames((prevFileNames) => {
      const newFileNames = [...prevFileNames];
      newFileNames[index] = newFileName;
      return newFileNames;
    });

    setIsEditing(false);
  };

  return (
    <div className={classes.File}>
      <div className={classes.header}>
        {progress < 100 ? (
          <div className={classes.uploadProgress}>
            <span>{progress}%</span>
            <div
              className={classes.progressBar}
              style={{
                width: `${progress}%`,
              }}
            />
          </div>
        ) : (
          <div className={classes.menuContainer}>
            <ArrowMenu
              width={132}
              options={arrowMenuOptions}
              isVisible={isArrowMenuVisible}
              setIsVisible={setIsArrowMenuVisible}
              fixed={isTabletOrMobile}
            >
              <button type="button">
                <img src={threeDotsIcon} alt="Three dots" />
              </button>
            </ArrowMenu>
          </div>
        )}
      </div>
      <div className={classes.body}>
        <img src={fileIcons[file.type?.split('/').pop()]} alt="File" />
        {isEditing ? (
          <input
            className={classes.nameInput}
            type="text"
            value={newFileName}
            onChange={(event) => setNewFileName(event.target.value)}
            onBlur={renameFile}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                renameFile();
                setIsEditing(false);
              }
            }}
            autoFocus
            maxLength={25}
            title={newFileName}
          />
        ) : (
          <p className={classes.name}>{fileName}</p>
        )}
      </div>
    </div>
  );
}
