import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function ResumeCell({ row }) {
  const { t } = useTranslation();

  const isVideo = row?.length > 0;

  return (
    <div className={classes.ResumeCell}>
      {isVideo ? (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5195 2V7L12.5195 7.05441C12.5194 7.47848 12.5194 7.8906 12.5651 8.23052C12.6166 8.61372 12.7421 9.051 13.1053 9.41421C13.4685 9.77743 13.9058 9.90295 14.289 9.95447C14.6289 10.0002 15.041 10.0001 15.4651 10H15.4651L15.5195 10H20.5195V16C20.5195 18.8284 20.5195 20.2426 19.6409 21.1213C18.7622 22 17.348 22 14.5195 22H10.5195C7.6911 22 6.27689 22 5.39821 21.1213C4.51953 20.2426 4.51953 18.8284 4.51953 16V8C4.51953 5.17157 4.51953 3.75736 5.39821 2.87868C6.27689 2 7.6911 2 10.5195 2H12.5195ZM14.5195 2.00462V7C14.5195 7.49967 14.5217 7.77383 14.5472 7.96402L14.5482 7.97131L14.5555 7.97231C14.7457 7.99788 15.0199 8 15.5195 8H20.5149C20.5047 7.58836 20.472 7.31595 20.3673 7.06306C20.215 6.69552 19.926 6.40649 19.348 5.82843L16.6911 3.17157C16.113 2.59351 15.824 2.30448 15.4565 2.15224C15.2036 2.04749 14.9312 2.01481 14.5195 2.00462Z"
            fill="url(#paint0_linear_1260_55746)"
          />
          <path
            d="M15.0609 14.6864C15.3742 14.4905 15.3742 14.0342 15.0609 13.8384L11.2845 11.4781C10.9515 11.27 10.5195 11.5094 10.5195 11.9021V16.6226C10.5195 17.0153 10.9515 17.2547 11.2845 17.0466L15.0609 14.6864Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1260_55746"
              x1="12.5195"
              y1="-9.26621"
              x2="-8.90526"
              y2="5.94727"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00E4AC" />
              <stop offset="0.504968" stopColor="#10C5E9" />
              <stop offset="1" stopColor="#9240DC" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5195 2V7L12.5195 7.05441C12.5194 7.47848 12.5194 7.8906 12.5651 8.23052C12.6166 8.61372 12.7421 9.051 13.1053 9.41421C13.4685 9.77743 13.9058 9.90295 14.289 9.95447C14.6289 10.0002 15.041 10.0001 15.4651 10H15.4651L15.5195 10H20.5195V16C20.5195 18.8284 20.5195 20.2426 19.6409 21.1213C18.7622 22 17.348 22 14.5195 22H10.5195C7.6911 22 6.27689 22 5.39821 21.1213C4.51953 20.2426 4.51953 18.8284 4.51953 16V8C4.51953 5.17157 4.51953 3.75736 5.39821 2.87868C6.27689 2 7.6911 2 10.5195 2H12.5195ZM14.5195 2.00462V7C14.5195 7.49967 14.5217 7.77383 14.5472 7.96402L14.5482 7.97131L14.5555 7.97231C14.7457 7.99788 15.0199 8 15.5195 8H20.5149C20.5047 7.58836 20.472 7.31595 20.3673 7.06306C20.215 6.69552 19.926 6.40649 19.348 5.82843L16.6911 3.17157C16.113 2.59351 15.824 2.30448 15.4565 2.15224C15.2036 2.04749 14.9312 2.01481 14.5195 2.00462Z"
            fill="#C1C5D6"
          />
          <path
            d="M9.51953 14L15.5195 14"
            stroke="#F2F4F8"
            strokeLinecap="round"
          />
          <path
            d="M9.51953 17L14.5195 17"
            stroke="#F2F4F8"
            strokeLinecap="round"
          />
        </svg>
      )}
      <span>{isVideo ? t('common.video') : 'Word'}</span>
    </div>
  );
}
