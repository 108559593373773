/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function ArrowMenu({
  children,
  isVisible,
  setIsVisible,
  options,
  bottomArrow,
  width,
  fixed,
}) {
  const [coords, setCoords] = useState({ top: '', left: '' });
  const [isBottomPositioned, setIsBottomPositioned] = useState(false);
  const [isMirrored, setIsMirrored] = useState(false);

  const arrowMenuRef = useRef();
  const optionsRef = useRef();

  useEffect(() => {
    if (isVisible) {
      const optionsRect = optionsRef.current.getBoundingClientRect();
      const isEnoughSpace =
        optionsRect.top + optionsRect.height < window.innerHeight;

      if (fixed) {
        const isEnoughSpaceOnLeft = optionsRect.right - optionsRect.width > 0;

        if (!isEnoughSpaceOnLeft) {
          setIsMirrored(true);
        } else {
          setIsMirrored(false);
        }

        setCoords({
          top: '',
          left:
            -optionsRect.width +
            arrowMenuRef.current.getBoundingClientRect().width / 2,
        });

        return;
      }

      if (!isEnoughSpace) {
        setIsBottomPositioned(true);
      } else {
        setIsBottomPositioned(false);
      }

      setCoords({
        top: isEnoughSpace
          ? optionsRect.top
          : optionsRect.top - optionsRect.height - 60,
        left: optionsRect.left,
      });
    } else {
      setCoords({ top: '', left: '' });
    }
  }, [fixed, isVisible]);

  useOnClickOutside(arrowMenuRef, () => setIsVisible(false));

  useEffect(() => {
    if (!isVisible) {
      setIsMirrored(false);
    }
  }, [isVisible]);

  return (
    <div
      className={classNames(classes.ArrowMenu, {
        [classes.bottomArrow]: bottomArrow || isBottomPositioned,
        [classes.mirrored]: isMirrored,
      })}
      ref={arrowMenuRef}
    >
      <div
        className={classes.trigger}
        onClick={(e) => {
          e.stopPropagation();
          setIsVisible((prevState) => !prevState);
        }}
      >
        {children}
      </div>
      {isVisible && (
        <ul
          className={classes.options}
          style={{
            width,
            position: coords.top && !fixed ? 'fixed' : 'absolute',
            top: coords.top,
            left: coords.left,
          }}
          ref={optionsRef}
        >
          {options.map((option) => (
            <li
              key={option.label}
              onClick={(e) => {
                e.stopPropagation();
                option.onClick?.();
                setIsVisible(false);
              }}
              className={classNames({
                [classes.withBorder]: option.withBorder,
              })}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
