import React, { useRef, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import GraphPoints from './GraphPoints';

import useResizeObserver from '../../../../../../../../hooks/useResizeObserver';
import classes from './styles.module.scss';

export default function Graph({ activeOption, graphData }) {
  const graphContainerRef = useRef();

  const { t } = useTranslation();

  const { width } = useResizeObserver(graphContainerRef);

  // Calculate graph width scaling factor for mobile devices
  useEffect(() => {
    const desktopGraphWidth = 664;
    const mobileGraphContainerWidth = width;

    const xScalingFactor = mobileGraphContainerWidth / desktopGraphWidth;

    graphContainerRef.current.style.setProperty(
      '--x-scaling-factor',
      xScalingFactor
    );

    document.documentElement.style.setProperty(
      '--x-revers-scaling-factor',
      1 / xScalingFactor
    );
  }, [width]);

  let noData;

  if (
    activeOption ===
    t('dashboardComponents.Jobs.MyJobs.JobDetails.Insights.ViewsGraph.monthly')
  ) {
    noData =
      graphData?.monthApplicants.every((item) => item === 0) &&
      graphData?.monthViews.every((item) => item === 0);
  } else {
    noData =
      graphData?.daysApplicants.every((item) => item === 0) &&
      graphData?.daysViews.every((item) => item === 0);
  }

  return (
    <div className={classes.Graph}>
      <div className={classes.percents}>
        {['0', '20', '40', '60', '80', '100'].map((percent) => (
          <span className={classes.percent} key={percent}>
            {percent}
          </span>
        ))}
      </div>
      <div className={classes.bottom}>
        {activeOption === 'Daily' && (
          <div className={classes.days}>
            {[
              t('common.days.monday'),
              t('common.days.tuesday'),
              t('common.days.wednesday'),
              t('common.days.thursday'),
              t('common.days.friday'),
              t('common.days.saturday'),
              t('common.days.sunday'),
            ].map((day) => (
              <span className={classes.day} key={day}>
                {day}
              </span>
            ))}
          </div>
        )}
        {activeOption === 'Monthly' && (
          <div className={classes.months}>
            {[
              t('common.shortMonths.Jan'),
              t('common.shortMonths.Feb'),
              t('common.shortMonths.Mar'),
              t('common.shortMonths.Apr'),
              t('common.shortMonths.May'),
              t('common.shortMonths.Jun'),
              t('common.shortMonths.Jul'),
              t('common.shortMonths.Aug'),
              t('common.shortMonths.Sep'),
              t('common.shortMonths.Oct'),
              t('common.shortMonths.Nov'),
              t('common.shortMonths.Dec'),
            ].map((month) => (
              <span className={classes.month} key={month}>
                {month}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className={classes.graphContainer} ref={graphContainerRef}>
        <div
          className={classNames(classes.graph, {
            [classes.empty]: noData,
          })}
        >
          {noData ? (
            <div className={classes.noData}>
              <div className={classes.text}>
                {t(
                  'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.ViewsGraph.Graph.insufficientData'
                )}
              </div>
            </div>
          ) : (
            <GraphPoints
              data={
                activeOption ===
                t(
                  'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.ViewsGraph.daily'
                )
                  ? [graphData?.daysApplicants, graphData?.daysViews]
                  : [graphData?.monthApplicants, graphData?.monthViews]
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
