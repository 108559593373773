import i18next from '../i18n';

const t = i18next.t.bind(i18next);

function camelCaseToSpace(str) {
  const result = str?.replace(/([a-z])([A-Z])/g, '$1 $2');
  return result?.[0]?.toUpperCase() + result?.slice(1);
}

function formatDate(date) {
  const tempDate = new Date(date);

  const month = tempDate.toLocaleString('eng', { month: 'short' });
  const day = tempDate.getDate();
  const year = tempDate.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}

export function mapReportValue(report) {
  const [reportName, reportValue] = report;

  switch (reportName) {
    case 'jobAppliedOn':
    case 'createdon':
    case 'jobExpirationDate':
    case 'assessmentSentOn':
    case 'assessmentViewedOn':
    case 'assessmentTakenOn':
    case 'assessmentExpired':
    case 'interviewDare':
    case 'interviewScheduledon':
    case 'inviteViewedon':
    case 'offerDate':
    case 'offerViewedon':
    case 'onboardingDate':
    case 'startDate':
      return formatDate(reportValue);
    default:
      return reportValue;
  }
}

export function mapReport(report) {
  switch (report) {
    case 'firstName':
      return t('dashboardComponents.Reports.columnNames.Candidate First Name');
    case 'lastName':
      return t('dashboardComponents.Reports.columnNames.Candidate Last Name');
    case 'age':
      return t('dashboardComponents.Reports.columnNames.Candidate Age');
    case 'location':
      return t('dashboardComponents.Reports.columnNames.Candidate Location');
    case 'email':
      return t('dashboardComponents.Reports.columnNames.Candidate Email');
    case 'phone':
      return t('dashboardComponents.Reports.columnNames.Candidate Phone');
    case 'resumeType':
      return t('dashboardComponents.Reports.columnNames.Resume Type');
    case 'wordResumePresent':
      return t('dashboardComponents.Reports.columnNames.Word Resume Present');
    case 'education':
      return t('dashboardComponents.Reports.columnNames.Education');
    case 'experience':
      return t('dashboardComponents.Reports.columnNames.Experience');
    case 'yearsOfExperience':
      return t('dashboardComponents.Reports.columnNames.Years Of Experience');
    case 'jobId':
      return t('dashboardComponents.Reports.columnNames.Job Id');
    case 'jobApplied':
      return t('dashboardComponents.Reports.columnNames.Job Applied');
    case 'jobStatus':
      return t('dashboardComponents.Reports.columnNames.Job Status');
    case 'jobCity':
      return t('dashboardComponents.Reports.columnNames.Job City');
    case 'locationName':
      return t('dashboardComponents.Reports.columnNames.Restaurant Name');
    case 'jobAppliedOn':
      return t('dashboardComponents.Reports.columnNames.Job Applied On');
    case 'daysSinceApplied':
      return t('dashboardComponents.Reports.columnNames.Days Since Applied');
    case 'candidateJobStatus':
      return t('dashboardComponents.Reports.columnNames.Candidate Job Status');
    case 'recruiterName':
      return t('dashboardComponents.Reports.columnNames.Recruiter Name');
    case 'recruiterEmail':
      return t('dashboardComponents.Reports.columnNames.Recruiter Email');
    case 'totalJobApplied':
      return t('dashboardComponents.Reports.columnNames.Total Jobs Applied');
    case 'createdon':
      return t('dashboardComponents.Reports.columnNames.Created On');
    case 'candidatesinScreen':
      return t('dashboardComponents.Reports.columnNames.Candidates In Screen');
    case 'candidatesinInterview':
      return t(
        'dashboardComponents.Reports.columnNames.Candidates In Interview'
      );
    case 'candidatesinOffer':
      return t('dashboardComponents.Reports.columnNames.Candidates In Offer');
    case 'candidatesinOnboarding':
      return t(
        'dashboardComponents.Reports.columnNames.Candidates In Onboarding'
      );
    case 'applicantsinNew':
      return t('dashboardComponents.Reports.columnNames.Applicants In New');
    case 'candidateswithStartdate':
      return t(
        'dashboardComponents.Reports.columnNames.Candidate with Start Date'
      );
    case 'daysinNewStatus':
      return t('dashboardComponents.Reports.columnNames.Days In New Status');
    case 'averageTimetoApply':
      return t('dashboardComponents.Reports.columnNames.Average Time To Apply');
    case 'dropoffRate':
      return t('dashboardComponents.Reports.columnNames.Drop Off Rate');
    case 'timetoOffer':
      return t('dashboardComponents.Reports.columnNames.Time To Offer');
    case 'timetoFill':
      return t('dashboardComponents.Reports.columnNames.Time To Fill');
    case 'timetoHire':
      return t('dashboardComponents.Reports.columnNames.Time To Hire');
    case 'interviewScheduledon':
      return t(
        'dashboardComponents.Reports.columnNames.Interview Scheduled On'
      );
    case 'inviteViewedon':
      return t('dashboardComponents.Reports.columnNames.Invite Viewed On');
    case 'rescheduled':
      return t('dashboardComponents.Reports.columnNames.Re-scheduled');
    case 'offerViewedon':
      return t('dashboardComponents.Reports.columnNames.Offer Viewed On');
    case 'applicantYearsofExperience':
      return t(
        'dashboardComponents.Reports.columnNames.Applicant Years Of Experience'
      );
    case 'daysinOfferStatus':
      return t('dashboardComponents.Reports.columnNames.Days In Offer Status');
    case 'daysLefttoRespond':
      return t('dashboardComponents.Reports.columnNames.Days Left To Respond');
    case 'daysinOnboardingStatus':
      return t(
        'dashboardComponents.Reports.columnNames.Days In Onboarding Status'
      );
    case 'pointofContact':
      return t('dashboardComponents.Reports.columnNames.Point Of Contact');
    case 'daysinJoiningStatus':
      return t(
        'dashboardComponents.Reports.columnNames.Days In Joining Status'
      );
    default:
      return camelCaseToSpace(report);
  }
}

export function mapReports(reports) {
  return reports?.map((report) => mapReport(report));
}
