import React, { useState, useEffect, useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import {
  unstable_useBlocker as useBlocker,
  useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BrandKitContext } from '../../../../context/BrandKitContext';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';

import useUiContext from '../../../../hooks/useUiContext';
import {
  hasColorSettingsChanged,
  hasLogoSettingsChanged,
  hasFontSettingsChanged,
} from '../../../../helpers/compareBrandKitSettings';
import AdminService from '../../../../services/AdminService';
import classes from './styles.module.scss';

const tenantBrandFontToFontSelectorFont = (type, tenantSettings) => {
  const tenantBrandFont = tenantSettings.brandFonts.find(
    (font) => font.type === type
  );

  if (!tenantBrandFont) {
    return {
      fontFamily: {},
      styles: [],
    };
  }

  return {
    fontFamily: {
      value: tenantBrandFont.familyName,
      label: tenantBrandFont.familyName,
    },
    styles: tenantBrandFont.customStyles
      ? tenantBrandFont.customStyles.split(',')
      : [],
  };
};

export default function BrandKit() {
  const [isBlocking, setIsBlocking] = useState(false);
  const [savedLogoSettings, setSavedLogoSettings] = useState({
    logo: null, // could be null, File or url string
    favicon: null, // could be null, File or url string
    brandName: '',
  });
  const [savedColorSettings, setSavedColorSettings] = useState([
    '#D52D34',
    '#242833',
    '#FDF2E4',
    '#FFFFFF',
    '#C1C5D6',
  ]);
  const [initialSettings, setInitialSettings] = useState({
    logo: null, // could be null, File or url string
    favicon: null, // could be null, File or url string
    brandName: '',
    brandColors: [],
    titleFont: {
      fontFamily: {},
      styles: [],
    },
    subTitleFont: {
      fontFamily: {},
      styles: [],
    },
    bodyTextFont: {
      fontFamily: {},
      styles: [],
    },
    bodySubTextFont: {
      fontFamily: {},
      styles: [],
    },
  });

  const [savedFontSettings, setSavedFontSettings] = useState({
    titleFont: {},
    subTitleFont: {},
    bodyTextFont: {},
    bodySubTextFont: {},
  });
  const [activeTab, setActiveTab] = useState('Logo');
  const [brandName, setBrandName] = useState(null);
  const [logo, setLogo] = useState(null);
  const [favicon, setFavicon] = useState(null);
  const [brandColors, setBrandColors] = useState([]);
  const [titleFont, setTitleFont] = useState({
    fontFamily: { value: 'Gilroy-SemiBold', label: 'Gilroy-SemiBold' },
    styles: [],
  });
  const [subTitleFont, setSubTitleFont] = useState({
    fontFamily: { value: 'Gilroy-Medium', label: 'Gilroy-Medium' },
    styles: [],
  });
  const [bodyTextFont, setBodyTextFont] = useState({
    fontFamily: { value: 'Gilroy-Regular', label: 'Gilroy-Regular' },
    styles: [],
  });
  const [bodySubTextFont, setBodySubTextFont] = useState({
    fontFamily: { value: 'Gilroy-Regular', label: 'Gilroy-Regular' },
    styles: [],
  });

  const { showModal } = useUiContext();
  const {
    setIsPublishButtonDisabled,
    isPublishButtonDisabled,
    saveButtonValidation,
  } = useContext(BrandKitContext);

  const { t } = useTranslation();

  const tabs = [
    t('dashboardComponents.ProfileSettings.BrandKit.tabs.logo'),
    t('dashboardComponents.ProfileSettings.BrandKit.tabs.color'),
    t('dashboardComponents.ProfileSettings.BrandKit.tabs.font'),
  ];

  const navigate = useNavigate();

  const { data: tenantSettings, refetch: refetchTenantSettings } = useQuery({
    queryKey: ['tenantSettings'],
    queryFn: AdminService.getTenantSettings,
  });

  // Prevent user from leaving the page if there are unsaved changes
  useEffect(() => {
    const preventClosingTab = (event) => {
      if (!isBlocking) {
        return;
      }

      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', preventClosingTab);

    return () => {
      window.removeEventListener('beforeunload', preventClosingTab);
    };
  }, [isBlocking]);

  useBlocker((params) => {
    if (!isBlocking) {
      return false;
    }

    showModal({
      title: t('dashboardComponents.ProfileSettings.BrandKit.exitScreen.title'),
      text: t(
        'dashboardComponents.ProfileSettings.BrandKit.exitScreen.message'
      ),
      dismissButtonLabel: t('common.no'),
      dismissButtonVariant: ' ',
      confirmButtonVariant: 'modal',
      confirmButtonLabel: t('common.yes'),
      onConfirm: () => {
        setIsBlocking(false);
        setTimeout(() => {
          navigate(params.nextLocation.pathname);
        }, 100);
      },
      onCancel: () => {},
    });

    return true;
  });

  useEffect(() => {
    const shouldNotLeave =
      !isPublishButtonDisabled ||
      !saveButtonValidation.isColorSaveButtonDisabled ||
      !saveButtonValidation.isFontSaveButtonDisabled ||
      !saveButtonValidation.isLogoSaveButtonDisabled;

    if (shouldNotLeave) {
      setIsBlocking(true);
    } else {
      setIsBlocking(false);
    }
  }, [isPublishButtonDisabled, saveButtonValidation]);

  useEffect(() => {
    if (tenantSettings) {
      setBrandColors(tenantSettings.brandColors?.map((color) => color.value));
      setSavedColorSettings(tenantSettings.brandColors);

      const titleTenantFont = tenantBrandFontToFontSelectorFont(
        'Title',
        tenantSettings
      );
      const subTitleTenantFont = tenantBrandFontToFontSelectorFont(
        'Subtitle',
        tenantSettings
      );
      const bodyTextTenantFont = tenantBrandFontToFontSelectorFont(
        'Body',
        tenantSettings
      );
      const bodySubTextTenantFont = tenantBrandFontToFontSelectorFont(
        'Subbody',
        tenantSettings
      );

      setTitleFont(titleTenantFont);
      setSubTitleFont(subTitleTenantFont);
      setBodyTextFont(bodyTextTenantFont);
      setBodySubTextFont(bodySubTextTenantFont);
      setSavedFontSettings({
        titleFont: titleTenantFont,
        subTitleFont: subTitleTenantFont,
        bodyTextFont: bodyTextTenantFont,
        bodySubTextFont: bodySubTextTenantFont,
      });

      setLogo(tenantSettings.brand?.logo);
      setFavicon(tenantSettings.brand?.favicon);
      setBrandName(tenantSettings.brand?.name);

      setSavedLogoSettings({
        logo: tenantSettings.brand?.logo,
        favicon: tenantSettings.brand?.favicon,
        brandName: tenantSettings.brand?.name,
      });

      setInitialSettings({
        brandColors: tenantSettings.brandColors,
        titleFont: titleTenantFont,
        subTitleFont: subTitleTenantFont,
        bodyTextFont: bodyTextTenantFont,
        bodySubTextFont: bodySubTextTenantFont,
        logo: tenantSettings.brand?.logo,
        favicon: tenantSettings.brand?.favicon,
        brandName: tenantSettings.brand?.name,
      });
    }
  }, [tenantSettings]);

  // Disable publish button if no changes have been made
  useEffect(() => {
    const isDisabled =
      !hasColorSettingsChanged(
        savedColorSettings,
        initialSettings.brandColors
      ) &&
      !hasLogoSettingsChanged(savedLogoSettings, {
        logo: initialSettings.logo,
        favicon: initialSettings.favicon,
        brandName: initialSettings.brandName,
      }) &&
      !hasFontSettingsChanged(savedFontSettings, {
        titleFont: initialSettings.titleFont,
        subTitleFont: initialSettings.subTitleFont,
        bodyTextFont: initialSettings.bodyTextFont,
        bodySubTextFont: initialSettings.bodySubTextFont,
      });

    setIsPublishButtonDisabled(isDisabled);
  }, [
    initialSettings,
    savedColorSettings,
    savedFontSettings,
    savedLogoSettings,
    setIsPublishButtonDisabled,
  ]);

  return (
    <div className={classes.BrandKit}>
      <LeftPanel
        tenantSettings={tenantSettings}
        refetchTenantSettings={refetchTenantSettings}
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        brandName={brandName}
        setBrandName={setBrandName}
        logo={logo}
        setLogo={setLogo}
        favicon={favicon}
        setFavicon={setFavicon}
        brandColors={brandColors}
        setBrandColors={setBrandColors}
        titleFont={titleFont}
        setTitleFont={setTitleFont}
        subTitleFont={subTitleFont}
        setSubTitleFont={setSubTitleFont}
        bodyTextFont={bodyTextFont}
        setBodyTextFont={setBodyTextFont}
        bodySubTextFont={bodySubTextFont}
        setBodySubTextFont={setBodySubTextFont}
        savedLogoSettings={savedLogoSettings}
        setSavedLogoSettings={setSavedLogoSettings}
        savedColorSettings={savedColorSettings}
        setSavedColorSettings={setSavedColorSettings}
        savedFontSettings={savedFontSettings}
        setSavedFontSettings={setSavedFontSettings}
      />
      <RightPanel
        colors={brandColors}
        fonts={{
          titleFont,
          subTitleFont,
          bodyTextFont,
          bodySubTextFont,
        }}
        logoSettings={{
          logo,
          favicon,
          brandName,
        }}
        tenantSettings={tenantSettings}
        refetchTenantSettings={refetchTenantSettings}
        activeTab={activeTab}
      />
    </div>
  );
}
