import React, { useState } from 'react';

import { useMediaQuery } from 'react-responsive';

import MediaModal from '../../../../../../../../modals/MediaModal';

import classes from './styles.module.scss';

export default function Image({
  allMedia,
  index,
  mediaLeft,
  feedPost,
  isRead,
}) {
  const [isMediaModalVisible, setIsMediaModalVisible] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div
      className={classes.Image}
      onClick={() => {
        setIsMediaModalVisible(true);
      }}
    >
      <img src={allMedia[index]?.url} alt="" />
      {mediaLeft && (
        <>
          <div className={classes.overlay} />
          <div className={classes.mediaLeft}>{mediaLeft}+</div>
        </>
      )}
      {!isTabletOrMobile && (
        <MediaModal
          feedPost={feedPost}
          show={isMediaModalVisible}
          handleClose={(event) => {
            event.stopPropagation();
            setIsMediaModalVisible(false);
          }}
          allMedia={allMedia}
          index={index}
          isRead={isRead}
        />
      )}
    </div>
  );
}
