import React from 'react';

import classes from './styles.module.scss';

export default function CloseIcon({ onClick }) {
  return (
    <div className={classes.CloseIcon}>
      <i className={classes.icon} onClick={onClick}>
        Close
      </i>
    </div>
  );
}
