import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import classNames from 'classnames';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { UiContext } from '../../../../../context/UiContext';
import AdminService from '../../../../../services/AdminService';
import RadioButton from '../../../../Form/RadioButton';
import Textarea from '../../../../Form/Textarea';
import TextInput from '../../../../Form/TextInput';
import SidePopup from '../../SidePopup';
import classes from './styles.module.scss';
import Checkbox from '../../../../Form/Checkbox';

export default function AddRole({
  isVisible,
  handleClose,
  refetch,
  showAddRole,
}) {
  const [roleAccess, setRoleAccess] = useState('Full Access');
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const { t } = useTranslation();

  const [customRoleOptions, setCustomRoleOptions] = useState({
    [t(
      'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.manageJobsLabel'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.manageCandidatesLabel'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.manageUsersLabel'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.manageRefDataLabel'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.accessLabel'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.viewOnlyAccessLabel'
    )]: false,
  });

  const roleOptionsToScopesMap = {
    [t('dashboardComponents.Console.UserManagement.AddRole.fullAccessLabel')]:
      'api:full',
    [t('dashboardComponents.Console.UserManagement.AddRole.manageJobsLabel')]:
      'api:admin:jobs',
    [t(
      'dashboardComponents.Console.UserManagement.AddRole.manageCandidatesLabel'
    )]: 'api:admin:candidates',
    [t('dashboardComponents.Console.UserManagement.AddRole.manageUsersLabel')]:
      'api:admin:auth',
    [t(
      'dashboardComponents.Console.UserManagement.AddRole.manageRefDataLabel'
    )]: 'api:admin:reference',
    [t('dashboardComponents.Console.UserManagement.AddRole.accessLabel')]:
      'api:app',
    [t(
      'dashboardComponents.Console.UserManagement.AddRole.viewOnlyAccessLabel'
    )]: 'api:admin:read',
  };
  const {
    showUnknownErrorModal,
    showModal,
    setIsFetching,
    isCreatingOrUpdating,
    setIsCreatingOrUpdating,
  } = useContext(UiContext);

  const createRole = async (values, formikBag) => {
    function getScopes() {
      const scopes = [];
      Object.keys(customRoleOptions).forEach((key) => {
        if (customRoleOptions[key]) {
          scopes.push(roleOptionsToScopesMap[key]);
        }
      });
      return scopes;
    }

    try {
      setIsFetching(true);
      setIsCreatingOrUpdating(true);

      const scopes =
        roleAccess ===
        t(
          'dashboardComponents.Console.UserManagement.AddRole.buildYourOwnLabel'
        )
          ? getScopes()
          : [roleOptionsToScopesMap[roleAccess]];

      await AdminService.createRole({
        scopes,
        note: values.notes,
        name: values.name,
      });
      await refetch();
      showModal({
        title: t(
          'dashboardComponents.Console.UserManagement.AddRole.successModal.title'
        ),
        text: t(
          'dashboardComponents.Console.UserManagement.AddRole.successModal.text'
        ),
        dismissButtonLabel: t('common.addMore'),
        confirmButtonLabel: t('common.gotIt'),
        onCancel: showAddRole,
        onConfirm: () => {},
      });
      handleClose();
      formikBag.resetForm();
    } catch (error) {
      if (
        error.response.data?.message ===
        'Name and derived key needs to be unique'
      ) {
        showModal({
          title: t(
            'dashboardComponents.Console.UserManagement.AddRole.duplicateRoleModal.title'
          ),
          text: t(
            'dashboardComponents.Console.UserManagement.AddRole.duplicateRoleModal.text'
          ),
          dismissButtonLabel: t(
            'dashboardComponents.Console.UserManagement.AddRole.duplicateRoleModal.dismissButtonLabel'
          ),
        });
      } else {
        showUnknownErrorModal();
      }
    } finally {
      setIsFetching(false);
      setIsCreatingOrUpdating(false);
    }
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().trim().required(t('common.requiredField')),
        notes: yup.string().trim(),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      notes:
        /* addOrEditRoleMode === 'add' ? '' : roles?.[currentRoleIndex]?.note */ '',
    },
    validationSchema,
    onSubmit: (values, formikBag) => {
      createRole(values, formikBag);
    },
    enableReinitialize: true,
  });

  const changeCustomRoleOptions = (option) => {
    setCustomRoleOptions((prevOptions) => {
      if (prevOptions[option]) {
        return { ...prevOptions, [option]: false };
      }
      return { ...prevOptions, [option]: true };
    });
  };

  const changeRoleAccess = (option) => {
    setRoleAccess(option);
  };

  const clear = useCallback(() => {
    formik.resetForm();

    setRoleAccess(
      t('dashboardComponents.Console.UserManagement.AddRole.fullAccessLabel')
    );
    setCustomRoleOptions({
      [t(
        'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.manageJobsLabel'
      )]: false,
      [t(
        'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.manageCandidatesLabel'
      )]: false,
      [t(
        'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.manageUsersLabel'
      )]: false,
      [t(
        'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.manageRefDataLabel'
      )]: false,
      [t(
        'dashboardComponents.Console.UserManagement.AddRole.customRoleOptions.accessLabel'
      )]: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
    if (!isVisible) {
      formik.resetForm();
      clear();
      setIsTriedToSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, clear]);

  return (
    <SidePopup
      isVisible={isVisible}
      handleClose={handleClose}
      title={t('dashboardComponents.Console.UserManagement.AddRole.title')}
      leftButtonLabel={t('common.clear')}
      onLeftButtonClick={clear}
      rightButtonLabel={t('common.add')}
      isRightButtonDisabled={
        isCreatingOrUpdating ||
        !formik.values.name ||
        (roleAccess ===
          t(
            'dashboardComponents.Console.UserManagement.AddRole.buildYourOwnLabel'
          ) &&
          Object.values(customRoleOptions).every((val) => !val))
      }
      onRightButtonClick={() => {
        setIsTriedToSubmit(true);
        formik.handleSubmit();
      }}
    >
      <div className={classes.AddRole}>
        <div className={classes.row}>
          <div className={classes.col}>
            <TextInput
              label={t(
                'dashboardComponents.Console.UserManagement.AddRole.roleNameLabel'
              )}
              height={50}
              placeholder={t(
                'dashboardComponents.Console.UserManagement.AddRole.roleNamePlaceholder'
              )}
              error={formik.errors.name}
              touched={isTriedToSubmit}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="name"
            />
            <div className={classes.innerRow}>
              <div className={classes.innerCol}>
                <RadioButton
                  label={t(
                    'dashboardComponents.Console.UserManagement.AddRole.fullAccessLabel'
                  )}
                  isSelected={
                    roleAccess ===
                    t(
                      'dashboardComponents.Console.UserManagement.AddRole.fullAccessLabel'
                    )
                  }
                  onClick={() =>
                    changeRoleAccess(
                      t(
                        'dashboardComponents.Console.UserManagement.AddRole.fullAccessLabel'
                      )
                    )
                  }
                />
                <RadioButton
                  isSelected={
                    roleAccess ===
                    t(
                      'dashboardComponents.Console.UserManagement.AddRole.viewOnlyAccessLabel'
                    )
                  }
                  label={t(
                    'dashboardComponents.Console.UserManagement.AddRole.viewOnlyAccessLabel'
                  )}
                  onClick={() =>
                    changeRoleAccess(
                      t(
                        'dashboardComponents.Console.UserManagement.AddRole.viewOnlyAccessLabel'
                      )
                    )
                  }
                />
              </div>
              <div className={classes.innerCol}>
                <RadioButton
                  isSelected={
                    roleAccess ===
                    t(
                      'dashboardComponents.Console.UserManagement.AddRole.buildYourOwnLabel'
                    )
                  }
                  label={t(
                    'dashboardComponents.Console.UserManagement.AddRole.buildYourOwnLabel'
                  )}
                  onClick={() =>
                    changeRoleAccess(
                      t(
                        'dashboardComponents.Console.UserManagement.AddRole.buildYourOwnLabel'
                      )
                    )
                  }
                />
                <div
                  className={classNames(classes.checkboxes, {
                    [classes.visible]:
                      roleAccess ===
                      t(
                        'dashboardComponents.Console.UserManagement.AddRole.buildYourOwnLabel'
                      ),
                  })}
                >
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.AddRole.manageJobsLabel'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.AddRole.manageJobsLabel'
                        )
                      ]
                    }
                    onChange={() =>
                      changeCustomRoleOptions(
                        t(
                          'dashboardComponents.Console.UserManagement.AddRole.manageJobsLabel'
                        )
                      )
                    }
                  />
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.AddRole.manageCandidatesLabel'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.AddRole.manageCandidatesLabel'
                        )
                      ]
                    }
                    onChange={() =>
                      changeCustomRoleOptions(
                        t(
                          'dashboardComponents.Console.UserManagement.AddRole.manageCandidatesLabel'
                        )
                      )
                    }
                  />
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.AddRole.manageUsersLabel'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.AddRole.manageUsersLabel'
                        )
                      ]
                    }
                    onChange={() =>
                      changeCustomRoleOptions(
                        t(
                          'dashboardComponents.Console.UserManagement.AddRole.manageUsersLabel'
                        )
                      )
                    }
                  />
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.AddRole.manageRefDataLabel'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.AddRole.manageRefDataLabel'
                        )
                      ]
                    }
                    onChange={() =>
                      changeCustomRoleOptions(
                        t(
                          'dashboardComponents.Console.UserManagement.AddRole.manageRefDataLabel'
                        )
                      )
                    }
                  />
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.AddRole.accessLabel'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.AddRole.accessLabel'
                        )
                      ]
                    }
                    onChange={() =>
                      changeCustomRoleOptions(
                        t(
                          'dashboardComponents.Console.UserManagement.AddRole.accessLabel'
                        )
                      )
                    }
                  />
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.AddRole.viewOnlyAccessLabel'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.AddRole.viewOnlyAccessLabel'
                        )
                      ]
                    }
                    onChange={() =>
                      changeCustomRoleOptions(
                        t(
                          'dashboardComponents.Console.UserManagement.AddRole.viewOnlyAccessLabel'
                        )
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.col}>
            <Textarea
              label={t(
                'dashboardComponents.Console.UserManagement.AddRole.notesLabel'
              )}
              height={100}
              placeholder={t(
                'dashboardComponents.Console.UserManagement.AddRole.notesPlaceholder'
              )}
              error={formik.errors.notes}
              touched={isTriedToSubmit}
              value={formik.values.notes}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="notes"
            />
          </div>
        </div>
      </div>
    </SidePopup>
  );
}
