import axios from 'axios';

import { JOBS_API_URL } from '../constants/main';

const NotificationsService = {
  async getNotifications() {
    const response = await axios.get(`${JOBS_API_URL}/notifications`);
    return response.data;
  },

  async readNotification({ notificationId }) {
    const response = await axios.post(
      `${JOBS_API_URL}/notifications/${notificationId}/read`
    );
    return response.data;
  },

  async deleteNotification({ notificationId }) {
    const response = await axios.delete(
      `${JOBS_API_URL}/notifications/${notificationId}`
    );
    return response.data;
  },

  async readAllNotifications() {
    const response = await axios.post(`${JOBS_API_URL}/notifications/read`);
    return response.data;
  },
};

export default NotificationsService;
