import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function GradientButton({ children, disabled, active }) {
  const buttonRef = useRef();

  useEffect(() => {
    const buttonTextContent = buttonRef.current?.textContent;

    if (buttonTextContent) {
      buttonRef.current.style.setProperty('--text', `"${buttonTextContent}"`);
    }
  }, []);

  return (
    <button
      type="button"
      disabled={disabled}
      className={classNames(classes.GradientButton, {
        [classes.active]: active,
      })}
      ref={buttonRef}
    >
      {children}
    </button>
  );
}
