import React, { useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import InfoIcon from '../../../../InfoIcon';
import Stat from './Stat';

import classes from './styles.module.scss';

const STEP = 63;

export default function AverageTimeToFill({ data }) {
  const [scrollPosition, setScrollPosition] = useState(0);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const stepCount = data?.length - 6;
  const canScrollLeft = scrollPosition !== 0;
  const canScrollRight = !(Math.abs(scrollPosition) >= stepCount * STEP);

  const scrollLeft = () => {
    setScrollPosition((prevState) => {
      if (prevState === 0) {
        return 0;
      }
      return prevState + STEP;
    });
  };

  const scrollRight = () => {
    setScrollPosition((prevState) => {
      if (Math.abs(prevState) >= stepCount * STEP) {
        return prevState;
      }
      return prevState - STEP;
    });
  };

  let dataToDisplay = data;

  if (isTabletOrMobile) {
    dataToDisplay = data?.slice(0, 6);
  }

  return (
    <div className={classes.AverageTimeToFill}>
      <h3>
        {t(
          'dashboardComponents.Dashboard.LeftPanel.AverageTimeToFill.Average Time to Fill by Role'
        )}{' '}
        <InfoIcon infoWidth={120} info="Some useful information" />
      </h3>
      <div className={classes.graph}>
        {!data ? (
          <div className={classes.noData}>
            <p>
              {t(
                'dashboardComponents.Dashboard.LeftPanel.AverageTimeToFill.No data available for the selected timeline'
              )}
            </p>
          </div>
        ) : (
          <>
            {canScrollLeft && (
              <button
                className={classNames(classes.scrollButton, classes.left)}
                type="button"
                onClick={scrollLeft}
              >
                Scroll Left
              </button>
            )}
            <div className={classes.yAxis}>
              <h4>
                {t(
                  'dashboardComponents.Dashboard.LeftPanel.AverageTimeToFill.Days'
                )}
              </h4>
              <ul className={classes.days}>
                <li>45</li>
                <li>30</li>
                <li>15</li>
                <li>0</li>
              </ul>
              <h4>
                {t(
                  'dashboardComponents.Dashboard.LeftPanel.AverageTimeToFill.Roles'
                )}
              </h4>
            </div>
            <div className={classes.col}>
              <div className={classes.data}>
                <ul style={{ left: scrollPosition }}>
                  {dataToDisplay?.map((stat) => {
                    return (
                      <Stat
                        label={stat.title}
                        value={Math.min((100 / 45) * stat.timeToHire, 100)}
                        key={stat.title}
                      />
                    );
                  })}
                </ul>
              </div>
            </div>
            {canScrollRight && (
              <button
                className={classes.scrollButton}
                type="button"
                onClick={scrollRight}
              >
                Scroll Right
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
