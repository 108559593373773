/* eslint-disable no-shadow */
import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Template from '../Template';

import UserService from '../../../../../services/UserService';

export default function Job({
  deleteNotification,
  isRead,
  notification,
  classes,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const createNotificationContent = (
    notification
    /*   navigate,
    hideNotifications */
  ) => {
    /*   const goToCalendar = () => {
      navigate(`/tools/calendar`);
      hideNotifications();
    }; */

    const content = {
      'Job post did expired': {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Job.jobExpired'
        )} ${notification.jobPost?.jobTitle} (${notification.jobPost?.id})`,
        buttonLabel: '',
        onClick: () => {},
      },
      'External candidated added to job': {
        title: `${notification.jobApplication?.user?.userProfile?.fullName} ${t(
          'dashboardComponents.Notifications.Notification.Job.insertedTo'
        )}  ${notification.onEventJobApplicationStatus?.toUpperCase()}: ${
          notification.jobPost?.jobTitle
        } (${notification.jobPost?.id})`,
        text: user?.userProfile?.fullName,
        buttonLabel: '',
        onClick: () => {},
      },
      'notitification.job.candidate_added_no_job': {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Job.addedTo'
        )}: ${notification.eventNote}`,
        text: user?.userProfile?.fullName,
        buttonLabel: '',
        onClick: () => {},
      },
    };

    return (
      content[notification.eventNote] ||
      content[notification.eventKey] || {
        title: notification.eventNote,
        buttonLabel: '',
        onClick: () => {},
      }
    );
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
