/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import classNames from 'classnames';
import {
  getTimezoneDate,
  getTimezoneTime,
  setTimezoneDate,
  setTimezoneTime,
} from '../../helpers/timezones';
import UserService from '../../services/UserService';
import AdminService from '../../services/AdminService';
import { UiContext } from '../../context/UiContext';
import { getTime } from '../../helpers/getEvents';
import { hasAuthData } from '../../helpers/authStorage';

import TextInput from '../../components/Form/TextInput';
import Dropdown from '../../components/Form/Dropdown';
import TimeRange from '../../components/Form/TimeRange';
import SelectedUsers from '../../components/Dashboard/SelectedUsers';
import Textarea from '../../components/Form/Textarea';
import MultiDropdown from '../../components/Form/MultiDropdown';
import FormDropdown from '../../components/Form/FormDropdown';
import DatePicker from '../../components/Form/DatePicker';
import Button from '../../components/Button';

import classes from './styles.module.scss';

function getEventDate(date) {
  const tempDate = new Date(date);

  return `${tempDate.getDate()} ${tempDate.toLocaleString('en-us', {
    month: 'long',
  })} ${tempDate.getFullYear()}
  `;
}

export default function ScheduleInterviewModal({
  show,
  handleClose,
  candidate,
  refetch,
}) {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const { showNotification, setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const isAuthorized = hasAuthData();

  const isEdit = candidate && candidate.jobApplicationInterviews?.length;

  const { data: job } = useQuery({
    queryKey: ['myJob', candidate?.jobPostId],
    queryFn: () => AdminService.getJobPost({ id: candidate?.jobPostId }),
    enabled: !!candidate?.jobPostId,
  });

  const { data: me } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
    enabled: isAuthorized,
  });

  const closeAndNotify = (message) => {
    handleClose();
    refetch();
    showNotification({
      text: message,
    });
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        interviewDate: yup
          .date()
          .required(t('modals.ScheduleInterviewModal.requiredField')),
        type: yup
          .object()
          .required(t('modals.ScheduleInterviewModal.requiredField')),
        level: yup
          .object()
          .required(t('modals.ScheduleInterviewModal.requiredField')),
        customLocation: yup.object(),
        connectionDetail: yup.string().trim(),
        notificationType: yup.object(),
        notificationMethod: yup.object(),
        notificationValue: yup.object(),
        userIds: yup.array(),
        note: yup.string().trim(),
        startTime: yup.string().trim().required('Start time is required'),
        endTime: yup
          .string()
          .trim()
          .required(t('modals.ScheduleInterviewModal.endTimeRequired'))
          .test(
            'less-than-start',
            t('modals.ScheduleInterviewModal.endTimeGreaterThanStartTime'),
            (value, ctx) => {
              if (!ctx.parent.startTime) {
                return true;
              }
              const endTime = parseInt(value?.replace(':', ''), 10);
              const startTime = parseInt(
                ctx.parent.startTime?.replace(':', ''),
                10
              );
              return !(endTime <= startTime);
            }
          ),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      interviewDate: isEdit
        ? getTimezoneDate(
            candidate?.jobApplicationInterviews?.[0]?.interviewDate,
            me?.userProfile?.timezone
          )[0]
        : '',
      type: isEdit
        ? {
            value: candidate?.jobApplicationInterviews?.[0]?.type,
            label: candidate?.jobApplicationInterviews?.[0]?.type.replace(
              /([a-z])([A-Z])/g,
              '$1-$2'
            ),
          }
        : '',
      level: isEdit
        ? {
            value: candidate?.jobApplicationInterviews?.[0]?.level,
            label: candidate?.jobApplicationInterviews?.[0]?.level,
          }
        : '',
      customLocation: isEdit
        ? {
            value: candidate?.jobApplicationInterviews?.[0]?.customLocation,
            label: candidate?.jobApplicationInterviews?.[0]?.customLocation,
          }
        : '',
      connectionDetail: isEdit
        ? candidate?.jobApplicationInterviews?.[0]?.connectionDetail
        : '',
      notificationType: isEdit
        ? {
            value: candidate?.jobApplicationInterviews?.[0]?.notificationType,
            label: candidate?.jobApplicationInterviews?.[0]?.notificationType,
          }
        : '',
      notificationMethod: isEdit
        ? {
            value: candidate?.jobApplicationInterviews?.[0]?.notificationMethod,
            label: candidate?.jobApplicationInterviews?.[0]?.notificationMethod,
          }
        : '',
      notificationValue: isEdit
        ? {
            value: candidate?.jobApplicationInterviews?.[0]?.notificationValue,
            label: candidate?.jobApplicationInterviews?.[0]?.notificationValue,
          }
        : '',
      userIds: isEdit
        ? candidate?.jobApplicationInterviews?.[0]?.users?.map((user) => ({
            label: user.userName,
            value: user.id,
          }))
        : job?.jobPostInterviewers?.length
        ? job?.jobPostInterviewers?.map((user) => ({
            label: user.user.userName,
            value: user.user.id,
          }))
        : '',
      note: isEdit ? candidate?.jobApplicationInterviews?.[0]?.note : '',
      startTime: isEdit
        ? getTimezoneTime(
            candidate?.jobApplicationInterviews?.[0]?.startTime,
            me?.userProfile?.timezone
          )
        : '',
      endTime: isEdit
        ? getTimezoneTime(
            candidate?.jobApplicationInterviews?.[0]?.endTime,
            me?.userProfile?.timezone
          )
        : '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFetching(true);

        if (isEdit) {
          await AdminService.deleteJobApplicationInterview({
            jobApplicationId: candidate.id,
            interviewid: candidate.jobApplicationInterviews[0].id,
          });
        }

        await AdminService.createJobApplicationInterview({
          interviewDate: setTimezoneDate(
            values.interviewDate,
            values.startTime,
            me?.userProfile?.timezone
          ),
          type: values.type.value,
          level: values.level.value,
          customLocation: values.customLocation.label,
          connectionDetail: values.connectionDetail,
          notificationType: values.notificationType.value,
          notificationMethod: values.notificationMethod.value,
          notificationValue: values.notificationValue.value,
          userIds: values?.userIds.length
            ? values?.userIds?.map((user) => user?.value)
            : [],
          note: values.note,
          startTime: setTimezoneTime(
            values.startTime,
            me?.userProfile.timezone
          ),
          endTime: setTimezoneTime(values.endTime, me?.userProfile.timezone),
          jobApplicationId: candidate.id,
        });

        await AdminService.changeMyJobApplicationStatus({
          jobApplicationId: candidate.id,
          status: 'Interview',
          subStatus: isEdit ? 'Rescheduled' : 'Scheduled',
        });

        handleClose();
        refetch();
        closeAndNotify(t('modals.ScheduleInterviewModal.interviewCreated'));
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    },
    enableReinitialize: true,
  });

  const deleteInterview = async () => {
    try {
      setIsFetching(true);
      await AdminService.deleteJobApplicationInterview({
        jobApplicationId: candidate.id,
        interviewid: candidate.jobApplicationInterviews[0].id,
      });
      closeAndNotify(t('modals.ScheduleInterviewModal.interviewDeleted'));
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    return () => {
      formik.resetForm();
      setIsTriedToSubmit(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  let isNotificationInvalid = false;

  if (
    formik.values.notificationMethod.value !== 'None' &&
    (!formik.values.notificationType || !formik.values.notificationValue)
  ) {
    isNotificationInvalid = true;
  }

  if (
    formik.values.notificationMethod.value === 'None' ||
    formik.values.notificationMethod === ''
  ) {
    isNotificationInvalid = false;
  }

  let isConnectionDetailsInvalid = false;

  if (
    formik.values.type.value === 'InPerson' &&
    !formik.values.customLocation.value
  ) {
    isConnectionDetailsInvalid = true;
  } else if (
    formik.values.type.value !== 'InPerson' &&
    !formik.values.connectionDetail
  ) {
    isConnectionDetailsInvalid = true;
  }

  const isDisabled =
    !formik.values.interviewDate ||
    !formik.values.startTime ||
    !formik.values.endTime ||
    !formik.values.type ||
    !formik.values.level ||
    isNotificationInvalid ||
    isConnectionDetailsInvalid;

  const status = candidate?.jobApplicationInterviews?.[0]?.status;

  const suggestedInterviewDate = getEventDate(
    getTimezoneDate(
      candidate?.jobApplicationInterviews?.[0]?.suggestedInterviewDate,
      me?.userProfile?.timezone,
      candidate?.jobApplicationInterviews?.[0]?.suggestedStartTime
    )[0]
  );
  const suggestedStartTime = getTimezoneTime(
    candidate?.jobApplicationInterviews?.[0]?.suggestedStartTime,
    me?.userProfile?.timezone
  );

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.ScheduleInterviewModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header>
          <h1>
            {isEdit
              ? t('modals.ScheduleInterviewModal.editInterview')
              : t('modals.ScheduleInterviewModal.scheduleInterview')}
          </h1>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <div className={classes.container}>
          <div className={classes.row}>
            <div className={classes.col}>
              <div className={classes.applicationInfo}>
                <h3>{t('modals.ScheduleInterviewModal.candidate')}</h3>
                <h2>
                  {candidate.user?.userProfile?.firstName}{' '}
                  {candidate.user?.userProfile?.lastName}
                </h2>
                {(status === 'Accepted' ||
                  status === 'Declined' ||
                  status === 'ChangeRequest') && (
                  <div className={classes.status}>
                    <div
                      className={classNames(classes.statusText, {
                        [classes.statusDeclined]: status === 'Declined',
                        [classes.changeRequest]: status === 'ChangeRequest',
                      })}
                    >
                      {status.replace(/([A-Z])/g, ' $1').trim()}
                    </div>
                    {status === 'ChangeRequest' && (
                      <span className={classes.statusDate}>
                        {`${t('modals.ScheduleInterviewModal.requested')}:
                                  ${suggestedInterviewDate} ${getTime(
                          suggestedStartTime
                        )}`}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={classNames(classes.col, classes.positionCol)}>
              <div
                className={classNames(
                  classes.applicationInfo,
                  classes.position
                )}
              >
                <h3>{t('modals.ScheduleInterviewModal.position')}</h3>
                <h2>{job?.jobTitle}</h2>
                <span className={classes.jobId}>
                  {t('modals.ScheduleInterviewModal.jobId')}:{' '}
                  {candidate?.jobPostId}
                </span>
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <DatePicker
                dateFieldWidth={350}
                label={t('modals.ScheduleInterviewModal.interviewDate')}
                name="interviewDate"
                value={formik.values.interviewDate}
                setFieldValue={formik.setFieldValue}
                error={formik.errors.interviewDate}
                // touched={isDelegatesTriedToSubmit}
                setFieldTouched={formik.setFieldTouched}
              />
            </div>
            <div className={classes.col}>
              <TimeRange
                fromValue={formik.values.startTime}
                toValue={formik.values.endTime}
                setFieldValue={formik.setFieldValue}
                fromName="startTime"
                toName="endTime"
                error={formik.errors.endTime}
                touched={isTriedToSubmit}
                showDuration
                label={t('modals.ScheduleInterviewModal.interviewTime')}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <div className={classes.dropdowns}>
                <div className={classes.dropdownContainer}>
                  <h4 className={classes.dropdownTitle}>
                    {t('modals.ScheduleInterviewModal.interviewMode')}
                  </h4>
                  <Dropdown
                    width={170}
                    options={[
                      {
                        value: 'InPerson',
                        label: t('modals.ScheduleInterviewModal.inPerson'),
                      },
                      {
                        value: 'Video',
                        label: t('modals.ScheduleInterviewModal.video'),
                      },
                      {
                        value: 'Audio',
                        label: t('modals.ScheduleInterviewModal.audio'),
                      },
                    ]}
                    activeOption={formik.values.type}
                    setActiveOption={(option) =>
                      formik.setFieldValue('type', option)
                    }
                  />
                </div>
                <div className={classes.dropdownContainer}>
                  <h4 className={classes.dropdownTitle}>
                    {t('modals.ScheduleInterviewModal.interviewLevel')}
                  </h4>
                  <Dropdown
                    width={170}
                    options={[
                      {
                        value: 'Level1',
                        label: t('modals.ScheduleInterviewModal.level1'),
                      },
                      {
                        value: 'Level2',
                        label: t('modals.ScheduleInterviewModal.level2'),
                      },
                      {
                        value: 'Level3',
                        label: t('modals.ScheduleInterviewModal.level3'),
                      },
                    ]}
                    activeOption={formik.values.level}
                    setActiveOption={(option) =>
                      formik.setFieldValue('level', option)
                    }
                  />
                </div>
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.dropdownsCol}>
                <div className={classes.dropdownTitle}>
                  {t('modals.ScheduleInterviewModal.addNotification')}
                  <span className={classes.note}>
                    ({t('modals.ScheduleInterviewModal.optional')})
                  </span>
                </div>
                <div className={classes.dropdowns}>
                  <Dropdown
                    width={139}
                    options={[
                      {
                        value: 'Notification',
                        label: t('modals.ScheduleInterviewModal.notification'),
                      },
                      {
                        value: 'Email',
                        label: t('modals.ScheduleInterviewModal.email'),
                      },
                      {
                        value: 'None',
                        label: t('modals.ScheduleInterviewModal.none'),
                      },
                    ]}
                    activeOption={formik.values.notificationMethod}
                    setActiveOption={(option) =>
                      formik.setFieldValue('notificationMethod', option)
                    }
                  />
                  <Dropdown
                    width={77}
                    options={
                      formik.values.notificationType.value === 'Minutes'
                        ? [
                            { value: '30', label: '30' },
                            { value: '45', label: '45' },
                            { value: '60', label: '60' },
                          ]
                        : [
                            { value: '1', label: '1' },
                            { value: '2', label: '2' },
                            { value: '3', label: '3' },
                            { value: '4', label: '4' },
                          ]
                    }
                    activeOption={formik.values.notificationValue}
                    setActiveOption={(option) =>
                      formik.setFieldValue('notificationValue', option)
                    }
                  />
                  <Dropdown
                    width={116}
                    options={[
                      {
                        value: 'Minutes',
                        label: t('modals.ScheduleInterviewModal.minutes'),
                      },
                      {
                        value: 'Hours',
                        label: t('modals.ScheduleInterviewModal.hours'),
                      },
                    ]}
                    activeOption={formik.values.notificationType}
                    setActiveOption={(option) =>
                      formik.setFieldValue('notificationType', option)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {formik.values.type?.value === 'InPerson' && (
            <div className={classes.row}>
              <FormDropdown
                width={350}
                label={t('modals.ScheduleInterviewModal.location')}
                placeholder={t('modals.ScheduleInterviewModal.selectLocation')}
                fetchOptions={{
                  dataName: 'restaurantsOptionsBasicInfo',
                  getDataHandler: AdminService.getRestaurants,
                }}
                value={formik.values.customLocation}
                infoWidth={151}
                name="customLocation"
                searchInfo={t(
                  'modals.ScheduleInterviewModal.locationSearchInfo'
                )}
                searchInfoWidth={188}
                setFieldValue={formik.setFieldValue}
              />
            </div>
          )}
          {(formik.values.type?.value === 'Video' ||
            formik.values.type?.value === 'Audio') && (
            <div className={classes.row}>
              <TextInput
                name="connectionDetail"
                value={formik.values.connectionDetail}
                onChange={formik.handleChange}
                width="100%"
                height={50}
                label={t('modals.ScheduleInterviewModal.connectionDetails')}
                placeholder={t(
                  'modals.ScheduleInterviewModal.connectionPlaceholder'
                )}
              />
            </div>
          )}

          <div className={classes.row}>
            <div className={classes.col}>
              <div className={classes.selectContainer}>
                <MultiDropdown
                  maxLength={5}
                  note={
                    formik.values.userIds?.length
                      ? t('modals.ScheduleInterviewModal.selectUpTo5')
                      : t('modals.ScheduleInterviewModal.optional')
                  }
                  width={350}
                  label={t('modals.ScheduleInterviewModal.interviewers')}
                  fetchOptions={{
                    dataName: 'userOptionPostJobSuccess',
                    getDataHandler: AdminService.getUsers,
                    labelName: 'userName',
                    isUserList: true,
                  }}
                  value={formik.values.userIds}
                  name="userIds"
                  setFieldValue={formik.setFieldValue}
                  placeholder={t(
                    'modals.ScheduleInterviewModal.interviewersPlaceholder'
                  )}
                />
              </div>
              <div className={classes.selectedUsers}>
                <SelectedUsers
                  invitedUsers={formik.values.userIds}
                  withFormik
                  setInvitedUsers={(users) =>
                    formik.setFieldValue('userIds', users)
                  }
                />
              </div>
            </div>
            <div className={classes.col}>
              <Textarea
                name="note"
                onChange={formik.handleChange}
                value={formik.values.note}
                label={t('modals.ScheduleInterviewModal.emailMessage')}
                note={t('modals.ScheduleInterviewModal.optional')}
                height={159}
                placeholder={t('modals.ScheduleInterviewModal.enterNotes')}
                // maxLength={5000}
              />
            </div>
          </div>
        </div>
        <footer>
          <Button
            variant="secondary"
            width={250}
            height={52}
            onClick={isEdit ? deleteInterview : formik.resetForm}
          >
            {isEdit
              ? t('modals.ScheduleInterviewModal.delete')
              : t('modals.ScheduleInterviewModal.clear')}
          </Button>
          <Button
            width={250}
            height={52}
            disabled={isDisabled}
            onClick={() => {
              setIsTriedToSubmit(true);
              formik.handleSubmit();
            }}
          >
            {isEdit
              ? t('modals.ScheduleInterviewModal.update')
              : t('modals.ScheduleInterviewModal.schedule')}
          </Button>
        </footer>
      </div>
    </Modal>
  );
}
