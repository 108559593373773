import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../Button';
import TextEditor from '../TextEditor';
import PollForm from './PollForm';

import classes from './styles.module.scss';

const validatePollQuestionAnswers = (answers) => {
  return answers.every((answer) => answer.name.trim());
};

const validatePollQuestion = (question) => {
  if (!question.name.trim()) {
    return false;
  }
  if (question.answers.length < 2) {
    return false;
  }

  if (!validatePollQuestionAnswers(question.answers)) {
    return false;
  }

  return true;
};

const isPollValid = (addressees, questions) => {
  /*  if (!addressees.length) {
    return false;
  } */

  if (!questions.length) {
    return false;
  }

  if (!questions.every(validatePollQuestion)) {
    return false;
  }

  return true;
};

export default function PollEditor({
  setIsEditorVisible,
  isEditorVisible,
  sharedClasses,
  editingPost,
  title,
  setTitle,
  message,
  setMessage,
  addressees,
  setAddressees,
  tags,
  setTags,
  files,
  setFiles,
  setUploadedFiles,
  uploadedFiles,
  textBoxRef,
  updatePoll,
  createPoll,
  cancel,
  questions,
  setQuestions,
  isDraftButtonDisabled,
  fileNames,
  setFileNames,
  isFetching,
  noDraft,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.PollEditor}>
      {isEditorVisible ? (
        <>
          <TextEditor
            title={title}
            setTitle={setTitle}
            message={message}
            setMessage={setMessage}
            addressees={addressees}
            setAddressees={setAddressees}
            tags={tags}
            setTags={setTags}
            sharedClasses={sharedClasses}
            textBoxRef={textBoxRef}
            files={files}
            setFiles={setFiles}
            setUploadedFiles={setUploadedFiles}
            fileNames={fileNames}
            setFileNames={setFileNames}
            uploadedFiles={uploadedFiles}
          />
          <PollForm questions={questions} setQuestions={setQuestions} />
          <div className={classes.buttons}>
            <Button
              disabled={
                !isPollValid(addressees, questions) ||
                !addressees.length ||
                isFetching
              }
              onClick={() => {
                if (editingPost) {
                  if (editingPost?.status === 'Draft') {
                    updatePoll('Published');
                    return;
                  }
                  updatePoll();
                } else {
                  createPoll('Published');
                }
              }}
            >
              {editingPost && editingPost.status !== 'Draft'
                ? t('common.save')
                : t('common.send')}
            </Button>
            {(!editingPost || editingPost.status === 'Draft') && !noDraft && (
              <Button
                disabled={isDraftButtonDisabled}
                onClick={() => {
                  if (editingPost?.status === 'Draft') {
                    updatePoll();
                  } else {
                    createPoll('Draft');
                  }
                }}
              >
                {t(
                  'dashboardComponents.Discover.AddContent.PollEditor.saveAsDraft'
                )}
              </Button>
            )}
            <button
              className={classes.cancelButton}
              type="button"
              onClick={cancel}
            >
              {t('common.cancel')}
            </button>
          </div>
        </>
      ) : (
        <div
          className={classes.welcome}
          onClick={() => {
            setIsEditorVisible(true);
          }}
        >
          {t('dashboardComponents.Discover.AddContent.PollEditor.createPoll')}
        </div>
      )}
    </div>
  );
}
