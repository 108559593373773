import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Color({ hexCode, isActive }) {
  return (
    <div
      className={classNames(classes.Color, {
        [classes.active]: isActive,
      })}
    >
      <div className={classes.overlay} />
      <div className={classes.overlay2} />
      <div
        className={classes.color}
        style={{
          backgroundColor: hexCode,
        }}
      />
      <div className={classes.code}>
        <h4>{hexCode?.toUpperCase?.()}</h4>
      </div>
    </div>
  );
}
