import axios from 'axios';

import { JOBS_API_URL } from '../constants/main';

const ReportsService = {
  async getReports({
    url,
    term,
    pageNumber,
    pageSize,
    orderBy,
    fields,
    sortOrder,
  }) {
    if (url.includes('assessments')) {
      return null;
    }

    const response = await axios.get(
      `${JOBS_API_URL}${url}?${
        fields?.map((field) => `&fields=${field}`)?.join('') || ''
      }`,
      {
        params: {
          term,
          pageNumber,
          pageSize,
          orderBy,
          sortOrder,
        },
      }
    );
    return response.data;
  },

  async getStoredReports({ term, pageNumber, pageSize, orderBy }) {
    const response = await axios.get(`${JOBS_API_URL}/storedreports`, {
      params: {
        term,
        pageNumber,
        pageSize,
        orderBy,
      },
    });
    return response.data;
  },

  async addReportToStored({ title, type, term, orderBy, fields }) {
    const response = await axios.post(`${JOBS_API_URL}/storedreports`, {
      title,
      type,
      term,
      orderBy,
      fields,
    });
    return response.data;
  },

  async deleteReportFromStored({ id }) {
    const response = await axios.delete(`${JOBS_API_URL}/storedreports/${id}`);
    return response.data;
  },

  async updateStoredReport({ id, title, type, term, orderBy, fields }) {
    const response = await axios.put(`${JOBS_API_URL}/storedreports/${id}`, {
      title,
      type,
      term,
      orderBy,
      fields,
    });
    return response.data;
  },
};

export default ReportsService;
