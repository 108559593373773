/* eslint-disable no-plusplus */
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import DotMenu from '../../DotMenu';
import FavStatus from '../../FavStatus';
import classes from './styles.module.scss';

function formatStr(text) {
  let result = text[0];

  for (let i = 1; i < text.length; i++) {
    if (text[i] === text[i].toUpperCase()) {
      result += ' ';
    }

    result += text[i];
  }

  return result;
}

export default function JobCard({ job, handleClosePopup, refetch }) {
  return (
    <Link
      to={`/tools/jobs/my-jobs/${job?.jobPostId}`}
      style={{ textDecoration: 'none', color: 'black' }}
      onClick={handleClosePopup}
    >
      <div className={classes.jobCard}>
        <div className={classes.container}>
          <span className={classes.name}>{job?.title}</span>
          <span className={classes.jobId}>({job?.jobPostId})</span>
          <span
            className={classNames(classes.jobStatus, {
              [classes.inactive]: job?.status === 'Draft',
              [classes.onHold]: job?.status === 'OnHold',
            })}
          >
            {formatStr(job?.status)}
          </span>
        </div>

        <div className={classes.container}>
          <span
            className={classes.location}
          >{`${job?.city}, ${job?.country}`}</span>
          <span className={classes.person}>{job?.recruiterName}</span>
        </div>
        <div className={classes.settings}>
          <FavStatus job={job} refetch={refetch} />
          <DotMenu job={job} />
        </div>
      </div>
    </Link>
  );
}
