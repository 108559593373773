/* eslint-disable react/jsx-handler-names */
import React, { useState, useEffect, useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';

import useSwitchableRowData from '../../../../../../hooks/useSwitchableRowData';
import AdminService from '../../../../../../services/AdminService';
import { UiContext } from '../../../../../../context/UiContext';
import AssessmentQuestions from './AssessmentQuestions';
import SidePopup from '../../../SidePopup';
import classes from './styles.module.scss';
import TextInput from '../../../../../Form/TextInput';
import Textarea from '../../../../../Form/Textarea';
import QuestionsHeader from './QuestionsHeader';
import flattenPaginatedData from '../../../../../../helpers/flattenPaginatedData';

const areQuestionsValid = (quetions) => {
  if (!quetions.length) {
    return false;
  }

  const isQuestionsValid = quetions.every((question) => {
    const isQuestionNameValid = question.name.length > 0;
    const isAnswersValid = question.allowsMultipleAnswers
      ? question.answers.length > 1 &&
        question.answers.every((answer) => {
          return answer.name.length > 0;
        })
      : true;

    return isQuestionNameValid && isAnswersValid;
  });

  return isQuestionsValid;
};

export default function AddOrEditAssessment({
  isVisible,
  handleClose,
  createDataHandler,
  refetch,
  setIsAddDataVisible,
  isAdd,
  isEdit,
  data,
  selectedRows,
  hasNoAnimation,
  updateDataHandler,
}) {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);
  const [isQuestionsBlockVisible, setIsQuestionsBlockVisible] = useState(false);
  const [questions, setQuestions] = useState([
    {
      id: Math.random(),
      name: '',
      answers: [],
      allowsMultipleAnswers: false,
    },
  ]);
  const [deletedQuestionIds, setDeletedQuestionIds] = useState([]);

  const { t } = useTranslation();

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableRowData(data, selectedRows);

  const {
    showUnknownErrorModal,
    showModal,
    setIsFetching,
    isCreatingOrUpdating,
    setIsCreatingOrUpdating,
  } = useContext(UiContext);

  const { data: fetchedQuestionsData } = useInfiniteQuery({
    queryKey: ['questions', currentDataIndex, data],
    queryFn: ({ pageParam = 1 }) =>
      AdminService.getAssessmentQuestions({
        assessmentId: data?.[currentDataIndex]?.id,
        pageNumber: pageParam,
        pageSize: 50,
      }),
    enabled: isEdit && isVisible && !!data?.[currentDataIndex],
  });

  const fetchedQuestions = useMemo(
    () => flattenPaginatedData(fetchedQuestionsData),
    [fetchedQuestionsData]
  );

  const createAssessmentQuestionAnswer = async ({
    answer,
    sortOrder,
    assessmentId,
    questionId,
  }) => {
    const response = await AdminService.createAssessmentQuestionAnswer({
      answer,
      sortOrder,
      questionId,
      assessmentId,
    });

    return response;
  };

  const updateAssessmentQuestionAnswer = async ({
    answer,
    sortOrder,
    assessmentId,
    questionId,
    answerId,
  }) => {
    const response = await AdminService.updateAssessmentQuestionAnswer({
      answer,
      sortOrder,
      questionId,
      assessmentId,
      answerId,
    });

    return response;
  };

  const createAssessmentQuestionWithAnswers = async (
    question,
    sortOrder,
    assessmentId
  ) => {
    const response = await AdminService.createAssessmentQuestion({
      name: question.name,
      sortOrder,
      allowsMultipleAnswers: question.allowsMultipleAnswers,
      assessmentId,
    });

    const promises = question.answers.map((answer, index) =>
      createAssessmentQuestionAnswer({
        answer: answer.name,
        sortOrder: index,
        questionId: response.id,
        assessmentId,
      })
    );

    return Promise.all(promises);
  };

  const updateAssessmentQuestionWithAnswers = async (
    question,
    sortOrder,
    assessmentId
  ) => {
    if (question.id.toString().startsWith('0.')) {
      return createAssessmentQuestionWithAnswers(
        question,
        sortOrder,
        assessmentId
      );
    }
    const response = await AdminService.updateAssessmentQuestion({
      name: question.name,
      sortOrder,
      allowsMultipleAnswers: question.allowsMultipleAnswers,
      assessmentId,
      questionId: question.id,
    });

    const promises = question.answers.map((answer, index) =>
      answer.id.toString().startsWith('0.')
        ? createAssessmentQuestionAnswer({
            answer: answer.name,
            sortOrder: index,
            questionId: response.id,
            assessmentId,
          })
        : updateAssessmentQuestionAnswer({
            answer: answer.name,
            sortOrder: index,
            questionId: response.id,
            assessmentId,
            answerId: answer.id,
          })
    );

    return Promise.all(promises);
  };

  const createAssessment = async (values) => {
    try {
      setIsFetching(true);
      setIsCreatingOrUpdating(true);

      const assessment = await createDataHandler(values);
      const promises = questions?.map?.((question, index) =>
        createAssessmentQuestionWithAnswers(question, index, assessment.id)
      );
      await Promise.all(promises);
      await refetch();
      showModal({
        title: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.success'
        ),
        text: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.assessmentAddedSuccessfully'
        ),
        dismissButtonLabel: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.addMore'
        ),
        confirmButtonLabel: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.gotIt'
        ),
        onConfirm: () => {},
        onCancel: () => setIsAddDataVisible(true),
      });
      handleClose();
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsFetching(false);
      setIsTriedToSubmit(false);
      setIsCreatingOrUpdating(false);
    }
  };

  const updateAssessment = async (values) => {
    try {
      setIsFetching(true);
      setIsCreatingOrUpdating(true);

      const assessment = await updateDataHandler({
        ...values,
        assessmentId: data?.[currentDataIndex].id,
      });
      const updateQuestionsPromises = questions.map((question, index) =>
        updateAssessmentQuestionWithAnswers(question, index, assessment.id)
      );
      await Promise.all(updateQuestionsPromises);

      if (deletedQuestionIds.length) {
        const deleteQuestionsPromises = deletedQuestionIds.map((questionId) =>
          AdminService.deleteAssessmentQuestion({
            questionId,
            assessmentId: assessment.id,
          })
        );
        await Promise.all(deleteQuestionsPromises);
      }

      await refetch();
      showModal({
        title: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.success'
        ),
        text: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.changesSavedSuccessfully'
        ),
        dismissButtonLabel: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.gotIt'
        ),
        dismissButtonVariant: '',
      });
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsFetching(false);
      setIsTriedToSubmit(false);
      setIsCreatingOrUpdating(false);
    }
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .trim()
          .required(
            t(
              'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.cannotBeEmpty'
            )
          ),
        note: yup.string().trim(),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      name: isAdd ? '' : data?.[currentDataIndex]?.name,
      note: isAdd ? '' : data?.[currentDataIndex]?.note,
    },
    validationSchema,
    onSubmit: isAdd ? createAssessment : updateAssessment,
    enableReinitialize: true,
  });

  const closeAddFormWithWarning = () => {
    if (Object.values(formik.values).some((val) => !!val)) {
      showModal({
        title: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.exitForm'
        ),
        text: t(
          'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.exitFormWarning'
        ),
        dismissButtonLabel: t('common.yes'),
        confirmButtonLabel: t('common.no'),
        onConfirm: () => {},
        onCancel: handleClose,
      });
    } else {
      handleClose();
    }
  };

  const resetForm = () => {
    formik.resetForm();
    setIsTriedToSubmit(false);
    setIsQuestionsBlockVisible(false);
    setQuestions([
      {
        id: Math.random(),
        name: '',
        answers: [],
        allowsMultipleAnswers: false,
      },
    ]);
  };

  useEffect(() => {
    if (!isVisible) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (isEdit && fetchedQuestions) {
      setQuestions(
        fetchedQuestions
          ?.sort((a, b) => a.sortOrder - b.sortOrder)
          ?.map((question) => ({
            name: question.name,
            id: question.id,
            allowsMultipleAnswers: question.allowsMultipleAnswers,
            answers: question.assessmentQuestionAnswers.map((answer) => ({
              id: answer.id,
              name: answer.answer,
            })),
          }))
      );
    }
  }, [isEdit, fetchedQuestions]);

  return (
    <SidePopup
      isVisible={isVisible}
      handleClose={isAdd ? closeAddFormWithWarning : handleClose}
      title={
        isAdd
          ? t(
              'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.addAssessment'
            )
          : t(
              'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.editAssessment'
            )
      }
      leftButtonLabel={isAdd ? t('common.clear') : t('common.reset')}
      rightButtonLabel={t('common.save')}
      onRightButtonClick={() => {
        setIsTriedToSubmit(true);
        formik.handleSubmit();
      }}
      isRightButtonDisabled={
        !formik.values.name ||
        !areQuestionsValid(questions) ||
        isTriedToSubmit ||
        isCreatingOrUpdating
      }
      onLeftButtonClick={resetForm}
      switchNext={selectedRows.length > 1 && isEdit && switchToNextDataElement}
      switchPrev={selectedRows.length > 1 && isEdit && switchToPrevDataElement}
      hasNoAnimation={hasNoAnimation}
    >
      <div className={classes.AddOrEditAssessment}>
        {isQuestionsBlockVisible && (
          <QuestionsHeader
            title={formik.values.name}
            onEditIconClick={() => setIsQuestionsBlockVisible(false)}
            questionCount={questions?.length}
          />
        )}
        {isQuestionsBlockVisible ? (
          <AssessmentQuestions
            questions={questions}
            setQuestions={setQuestions}
            setDeletedQuestionIds={setDeletedQuestionIds}
            isEdit={isEdit}
          />
        ) : (
          <>
            <div className={classes.col}>
              <TextInput
                value={formik.values.name}
                height={50}
                label={t(
                  'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.assessmentName'
                )}
                placeholder={t(
                  'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.assessmentNamePlaceholder'
                )}
                onChange={formik.handleChange}
                name="name"
                touched={isTriedToSubmit}
                onBlur={formik.handleBlur}
                error={formik.errors.name}
              />
            </div>
            <div className={classes.col}>
              <Textarea
                value={formik.values.note}
                height={100}
                label={t(
                  'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.notes'
                )}
                placeholder={t(
                  'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.notesPlaceholder'
                )}
                onChange={formik.handleChange}
                name="note"
                touched={isTriedToSubmit}
                onBlur={formik.handleBlur}
                error={formik.errors.note}
              />
            </div>
            <div className={classes.buttonContainer}>
              <button
                type="button"
                onClick={() => setIsQuestionsBlockVisible(true)}
              >
                {isAdd
                  ? t(
                      'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.addQuestions'
                    )
                  : t(
                      'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.viewQuestions'
                    )}
              </button>
            </div>
          </>
        )}
      </div>
    </SidePopup>
  );
}
