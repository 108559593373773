import React from 'react';

import {
  // BrowserRouter,
  Route,
  // Routes,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

// import Loader from '../components/Loader';
import PrivateRoute from '../containers/PrivateRoute';
import LoginPage from '../pages/LoginPage';
import OtpSignInPage from '../pages/OtpSignInPage';
import PasswordSignInPage from '../pages/PasswordSignInPage';
import SignInMethodPage from '../pages/SignInMethodPage';
import RequestAccessPage from '../pages/RequestAccessPage';
import DashboardPage from '../pages/DashboardPage';
import RequestAccessSuccessPage from '../pages/RequestAccessSuccessPage';
import RequestNewPasswordPage from '../pages/RequestNewPasswordPage';
import RequestNewPasswordSuccessPage from '../pages/RequestNewPasswordSuccessPage';
import SetNewPasswordPage from '../pages/SetNewPasswordPage';

import CandidatesContextProvider from '../context/CandidatesContext';
import JobsContextProvider from '../context/JobsContext';
import AuthContextProvider from '../context/AuthContext';
import UiContextProvider from '../context/UiContext';
import SearchContextProvider from '../context/SearchContext';
import PreventNavigationProvider from '../context/PreventNavigationContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
  key: 'reactQueryOffilneCache',
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route
        path="*"
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
      />

      <Route path="/request-access" element={<RequestAccessPage />} />
      <Route
        path="/request-access/success"
        element={<RequestAccessSuccessPage />}
      />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/login/method" element={<SignInMethodPage />} />
      <Route path="/login/method/otp" element={<OtpSignInPage />} />
      <Route path="/login/method/password" element={<PasswordSignInPage />} />
      <Route path="/request-password" element={<RequestNewPasswordPage />} />
      <Route
        path="/request-password/success"
        element={<RequestNewPasswordSuccessPage />}
      />
      <Route path="/set-password" element={<SetNewPasswordPage />} />
      <Route
        path="/tools"
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/tools/:page"
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/tools/:page/:subpage/*"
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
      />
      <Route path="/" element={<Navigate to="/tools" />} />
    </Route>
  )
);

const doNotPersistQueries = ['newPosts', 'feedPosts'];

const RoutesComponent = () => {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
      shouldDehydrateQuery={({ queryKey }) => {
        return !doNotPersistQueries.includes(queryKey);
      }}
    >
      <UiContextProvider>
        <AuthContextProvider>
          <PreventNavigationProvider>
            <JobsContextProvider>
              <CandidatesContextProvider>
                <SearchContextProvider>
                  <RouterProvider router={router} />
                </SearchContextProvider>
              </CandidatesContextProvider>
            </JobsContextProvider>
          </PreventNavigationProvider>
        </AuthContextProvider>
      </UiContextProvider>
    </PersistQueryClientProvider>
  );
};
export default RoutesComponent;
