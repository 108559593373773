import React from 'react';

import { useTranslation } from 'react-i18next';

import GraphBar from './GraphBar';

import classes from './styles.module.scss';

export default function ApplicantsGraph({ data, name, totalAmount }) {
  const { t } = useTranslation();

  return (
    <div className={classes.ApplicantsGraph}>
      <span className={classes.title}>
        {t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.ApplicantsGraph.applicantsBy'
        )}{' '}
        {name}
      </span>
      {data?.length === 0 ? (
        <div className={classes.noData}>
          {t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.ApplicantsGraph.noData'
          )}
        </div>
      ) : (
        <>
          <div className={classes.graphs}>
            {data
              ?.sort((prevItem, nextItem) => nextItem.count - prevItem.count)
              .slice(0, 4)
              .map((city) => (
                <GraphBar
                  city={city}
                  key={city.title}
                  totalAmount={totalAmount}
                />
              ))}
          </div>
          <div className={classes.percents}>
            {[
              '0',
              '10',
              '20',
              '30',
              '40',
              '50%',
              '60',
              '70',
              '80',
              '90',
              '100%',
            ].map((percent) => (
              <span className={classes.percent} key={percent}>
                {percent}
              </span>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
