/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useCallback } from 'react';

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import flattenPaginatedData from '../../../../helpers/flattenPaginatedData';
import { UiContext } from '../../../../context/UiContext';
import ViewUser from './ViewUser';
import ViewNewUserRequest from './ViewNewUserRequest';
import AddUserForm from './AddUserForm';
import TableMenu from '../TableMenu';
import Tabs from '../Tabs';
import classes from './styles.module.scss';
import AdminService from '../../../../services/AdminService';
import ReviewNewUserRequest from './ReviewNewUserRequest';
import EditUser from './EditUser';
import AddRole from './AddRole';
import EditRole from './EditRole';
import ViewRole from './ViewRole';
import Table from '../../../Table';

let timer;

export default function UserManagement() {
  const [activeAction, setActiveAction] = useState('');
  const [activeUsersSortOption, setActiveUsersSortOption] = useState({
    label: 'Created On',
    value: 'createdAt',
  });
  const [activeNewUsersSortOption, setActiveNewUsersSortOption] = useState({
    label: 'Created On',
    value: 'createdAt',
  });
  const [activeRolesSortOption, setActiveRolesSortOption] = useState({
    label: 'Created On',
    value: 'createdAt',
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [usersSearchTerm, setUsersSearchTerm] = useState('');
  const [debouncedUsersSearchTerm, setDebouncedUsersSearchTerm] = useState('');
  const [newUsersSearchTerm, setNewUsersSearchTerm] = useState('');
  const [debouncedNewUsersSearchTerm, setDebouncedNewUsersSearchTerm] =
    useState('');
  const [rolesSearchTerm, setRolesSearchTerm] = useState('');
  const [debouncedRolesSearchTerm, setDebouncedRolesSearchTerm] = useState('');
  const [isAddUserFormVisible, setIsAddUserFormVisible] = useState(false);
  const [isViewNewUserRequestVisible, setIsViewNewUserRequestVisible] =
    useState(false);
  const [currentNewUserId, setCurrentNewUserId] = useState(null);
  const [isReviewNewUserRequestVisible, setIsReviewNewUserRequestVisible] =
    useState(false);
  const [isViewUserVisible, setIsViewUserVisible] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [isEditUserVisible, setIsEditUserVisible] = useState(false);
  const [isAddRoleVisible, setIsAddRoleVisible] = useState(false);
  const [isEditRoleVisible, setIsEditRoleVisible] = useState(false);
  const [isViewRoleVisible, setIsViewRoleVisible] = useState(false);
  const [currentRoleId, setCurrentRoleId] = useState(null);
  const [usersSortOrder, setUsersSortOrder] = useState('desc');
  const [newUsersSortOrder, setNewUsersSortOrder] = useState('desc');
  const [rolesSortOrder, setRolesSortOrder] = useState('desc');
  const [searchHistory, setSearchHistory] = useState([]);

  // Pagination
  const [usersPage, setUsersPage] = useState(1);
  const [newUsersPage, setNewUsersPage] = useState(1);
  const [rolesPage, setRolesPage] = useState(1);

  const { t } = useTranslation();

  const tabs = [
    t('dashboardComponents.Console.UserManagement.tabs.newUsers'),
    t('dashboardComponents.Console.UserManagement.tabs.users'),
    t('dashboardComponents.Console.UserManagement.tabs.roles'),
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const sortNewUsersOptions = [
    {
      label: t(
        'dashboardComponents.Console.UserManagement.sortOptions.newUsers.firstName'
      ),
      value: 'firstName',
    },
    {
      label: t(
        'dashboardComponents.Console.UserManagement.sortOptions.newUsers.lastName'
      ),
      value: 'lastName',
    },
    {
      label: t(
        'dashboardComponents.Console.UserManagement.sortOptions.newUsers.email'
      ),
      value: 'email',
    },
    {
      label: t(
        'dashboardComponents.Console.UserManagement.sortOptions.newUsers.city'
      ),
      value: 'cityIds',
    },
    {
      label: t(
        'dashboardComponents.Console.UserManagement.sortOptions.newUsers.restaurant'
      ),
      value: 'loctaionIds',
    },
    {
      label: t('common.createdOn'),
      value: 'createdAt',
    },
  ];

  const sortUsersOptions = [
    {
      label: t(
        'dashboardComponents.Console.UserManagement.sortOptions.users.firstName'
      ),
      value: 'userProfile.firstName',
    },
    {
      label: t(
        'dashboardComponents.Console.UserManagement.sortOptions.users.lastName'
      ),
      value: 'userProfile.lastName',
    },
    {
      label: t(
        'dashboardComponents.Console.UserManagement.sortOptions.users.email'
      ),
      value: 'userProfile.email',
    },
    {
      label: t(
        'dashboardComponents.Console.UserManagement.sortOptions.users.phone'
      ),
      value: 'userProfile.phone',
    },
    {
      label: t(
        'dashboardComponents.Console.UserManagement.sortOptions.users.city'
      ),
      value: 'cities',
    },
    {
      label: t(
        'dashboardComponents.Console.UserManagement.sortOptions.users.restaurant'
      ),
      value: 'locations',
    },
    {
      label: t(
        'dashboardComponents.Console.UserManagement.sortOptions.users.role'
      ),
      value: 'roles',
    },
    { label: t('common.createdOn'), value: 'createdAt' },
  ];

  const sortRolesOptions = [
    {
      label: t(
        'dashboardComponents.Console.UserManagement.sortOptions.roles.roleName'
      ),
      value: 'name',
    },
    {
      label: t(
        'dashboardComponents.Console.UserManagement.sortOptions.roles.notes'
      ),
      value: 'note',
    },
    { label: t('common.createdOn'), value: 'createdAt' },
  ];

  const {
    showModal,
    showUnknownErrorModal,
    showSelectionNeededModal,
    setIsFetching,
    showConflictModal,
  } = useContext(UiContext);

  /*   const {
    data: usersPages,
    refetch: refetchUsers,
    isFetching: areUsersFetching,
    fetchNextPage: fetchNextUsersPage,
  } = useInfiniteQuery({
    queryKey: ['users'],
    queryFn: ({ pageParam = 1 }) =>
      AdminService.getUsers({
        orderBy: activeUsersSortOption.value || 'id',
        term: debouncedUsersSearchTerm,
        sortOrder: usersSortOrder,
        pageNumber: pageParam,
      }),
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      usersCount = lastPage.totalCount;
      return lastPage.nextPage;
    },
  }); */

  const {
    data: users,
    refetch: refetchUsers,
    isFetching: areUsersFetching,
  } = useQuery({
    queryKey: ['users', usersPage],
    queryFn: async () => {
      const response = await AdminService.getUsers({
        orderBy: activeUsersSortOption.value || 'id',
        term: debouncedUsersSearchTerm,
        sortOrder: usersSortOrder,
        pageNumber: usersPage,
      });

      return response;
    },
    keepPreviousData: true,
  });

  useEffect(() => {
    refetchUsers();
  }, [
    activeUsersSortOption,
    debouncedUsersSearchTerm,
    refetchUsers,
    usersSortOrder,
  ]);

  const {
    data: newUsers,
    refetch: refetchNewUsers,
    isFetching: areNewUsersFetching,
    fetchNextPage,
  } = useQuery({
    queryKey: [
      'newUsers',
      activeNewUsersSortOption,
      debouncedNewUsersSearchTerm,
      newUsersSortOrder,
      newUsersPage,
    ],
    queryFn: () =>
      AdminService.getNewUsers({
        orderBy: activeNewUsersSortOption.value || 'id',
        term: debouncedNewUsersSearchTerm,
        sortOrder: newUsersSortOrder,
        pageNumber: newUsersPage,
      }),
    keepPreviousData: true,
  });

  const {
    isFetching: areRolesFetching,
    data: roles,
    refetch: refetchRoles,
    fetchNextPage: fetchNextRolesPage,
  } = useQuery({
    queryKey: [
      'roles',
      activeRolesSortOption,
      debouncedRolesSearchTerm,
      rolesSortOrder,
      rolesPage,
    ],
    queryFn: () =>
      AdminService.getRoles({
        orderBy: activeRolesSortOption.value || 'name',
        term: debouncedRolesSearchTerm,
        sortOrder: rolesSortOrder,
        pageNumber: rolesPage,
      }),
    keepPreviousData: true,
  });

  const deleteNewUsers = () => {
    showModal({
      icon: 'delete',
      title: t(
        'dashboardComponents.Console.UserManagement.modals.deleteNewUser.title'
      ),
      text: t(
        'dashboardComponents.Console.UserManagement.modals.deleteNewUser.confirmationText'
      ),
      onConfirm: async () => {
        try {
          setIsFetching(true);
          const promises = selectedRows.map((row) =>
            AdminService.deleteNewUser({ id: row })
          );
          await Promise.all(promises);
          refetchNewUsers();
        } catch (error) {
          console.log(error);
          if (error.response.status === 409) {
            showConflictModal(error.response.data.message);
          } else {
            showUnknownErrorModal();
          }
        } finally {
          setIsFetching(false);
        }
      },
      onCancel: () => {},
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
    });
  };

  const deleteUsers = () => {
    showModal({
      icon: 'delete',
      title: t(
        'dashboardComponents.Console.UserManagement.modals.deleteUser.title'
      ),
      text: t(
        'dashboardComponents.Console.UserManagement.modals.deleteUser.confirmationText'
      ),
      onConfirm: async () => {
        try {
          setIsFetching(true);
          const promises = selectedRows.map((row) =>
            AdminService.deleteUser({ id: row })
          );
          await Promise.all(promises);
          await refetchNewUsers();
        } catch (error) {
          console.log(error);

          if (error.response.status === 409) {
            showConflictModal(error.response.data.message);
          } else {
            showUnknownErrorModal();
          }
        } finally {
          setIsFetching(false);
        }
      },
      onCancel: () => {},
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
    });
  };

  const deleteRoles = () => {
    showModal({
      icon: 'delete',
      title: t(
        'dashboardComponents.Console.UserManagement.modals.deleteRole.title'
      ),
      text: t(
        'dashboardComponents.Console.UserManagement.modals.deleteRole.confirmationText'
      ),
      onConfirm: async () => {
        try {
          setIsFetching(true);
          const promises = selectedRows.map((row) =>
            AdminService.deleteRole({ id: row })
          );
          await Promise.all(promises);
          await refetchRoles();
        } catch (error) {
          console.log(error);
          if (error.response.status === 409) {
            showConflictModal(error.response.data.message);
          } else {
            showUnknownErrorModal();
          }
        } finally {
          setIsFetching(false);
        }
      },
      onCancel: () => {},
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
    });
  };

  const showViewNewUserRequest = (id) => {
    setCurrentNewUserId(id);
    setIsViewNewUserRequestVisible(true);
  };

  const showViewRole = (roleId) => {
    setCurrentRoleId(roleId);
    setIsViewRoleVisible(true);
  };

  const showViewUser = (roleId) => {
    setCurrentUserId(roleId);
    setIsViewUserVisible(true);
  };

  const showAddRole = () => {
    setIsAddRoleVisible(true);
  };

  const showEditRoleSingle = (roleId) => {
    setSelectedRows([roleId]);
    setIsEditRoleVisible(true);
  };

  const getSearchHistory = useCallback(() => {
    const savedSearchHistory = localStorage.getItem('searchHistory');

    if (!savedSearchHistory) {
      return [];
    }

    try {
      return JSON.parse(savedSearchHistory);
    } catch (error) {
      return [];
    }
  }, []);

  const saveSearchHistory = useCallback(
    (term) => {
      const history = getSearchHistory();

      if (history.includes(term)) {
        return;
      }

      if (history.length === 4) {
        history.pop();
      }

      history.unshift(term);
      localStorage.setItem('searchHistory', JSON.stringify(history));
      setSearchHistory(history);
    },
    [getSearchHistory]
  );

  useEffect(() => {
    const history = getSearchHistory();
    setSearchHistory(history);
  }, [getSearchHistory]);

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setDebouncedUsersSearchTerm(usersSearchTerm);
      if (usersSearchTerm.trim()) {
        saveSearchHistory(usersSearchTerm);
      }
    }, 500);
  }, [saveSearchHistory, usersSearchTerm]);

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setDebouncedNewUsersSearchTerm(newUsersSearchTerm);
      if (newUsersSearchTerm.trim()) {
        saveSearchHistory(newUsersSearchTerm);
      }
    }, 500);
  }, [saveSearchHistory, newUsersSearchTerm]);

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setDebouncedRolesSearchTerm(rolesSearchTerm);
      if (rolesSearchTerm.trim()) {
        saveSearchHistory(rolesSearchTerm);
      }
    }, 500);
  }, [saveSearchHistory, rolesSearchTerm]);

  useEffect(() => {
    setSelectedRows([]);
    setActiveAction('');
  }, [currentTab]);

  const usersActions = [
    {
      label: t('common.invite'),
      value: 'Invite',
      onClick: () => {
        setIsAddUserFormVisible(true);
      },
    },
    {
      label: t('common.edit'),
      value: 'Edit',
      onClick: () => {
        if (selectedRows.length) {
          setIsEditUserVisible(true);
        } else {
          showSelectionNeededModal();
        }
      },
    },
    {
      label: t('common.delete'),
      value: 'Delete',
      onClick: () => {
        if (selectedRows.length) {
          deleteUsers();
        } else {
          showSelectionNeededModal();
        }
      },
    },
  ];

  const newUsersActions = [
    {
      label: t('common.review'),
      value: 'Review',
      onClick: () => {
        if (selectedRows.length) {
          setIsReviewNewUserRequestVisible(true);
        } else {
          showSelectionNeededModal();
        }
      },
    },
    {
      label: t('common.delete'),
      value: 'Delete',
      onClick: () => {
        if (selectedRows.length) {
          deleteNewUsers();
        } else {
          showSelectionNeededModal();
        }
      },
    },
  ];

  const roleActions = [
    {
      label: t('common.add'),
      value: 'Add',
      onClick: showAddRole,
    },
    {
      label: t('common.edit'),
      value: 'Edit',
      onClick: () => {
        if (selectedRows.length) {
          setIsEditRoleVisible(true);
        } else {
          showSelectionNeededModal();
        }
      },
    },
    {
      label: t('common.delete'),
      value: 'Delete',
      onClick: () => {
        if (selectedRows.length) {
          deleteRoles();
        } else {
          showSelectionNeededModal();
        }
      },
    },
  ];

  let actions;
  let sortOptions;
  let activeSortOption;
  let setActiveSortOption;
  let sortOrder;
  let setSortOrder;
  let searchTerm;
  let setSearchTerm;

  if (
    currentTab === t('dashboardComponents.Console.UserManagement.tabs.users')
  ) {
    actions = usersActions;
    sortOptions = sortUsersOptions;
    activeSortOption = activeUsersSortOption;
    setActiveSortOption = setActiveUsersSortOption;
    sortOrder = usersSortOrder;
    setSortOrder = setUsersSortOrder;
    searchTerm = usersSearchTerm;
    setSearchTerm = setUsersSearchTerm;
  } else if (
    currentTab === t('dashboardComponents.Console.UserManagement.tabs.newUsers')
  ) {
    actions = newUsersActions;
    sortOptions = sortNewUsersOptions;
    activeSortOption = activeNewUsersSortOption;
    setActiveSortOption = setActiveNewUsersSortOption;
    sortOrder = newUsersSortOrder;
    setSortOrder = setNewUsersSortOrder;
    searchTerm = newUsersSearchTerm;
    setSearchTerm = setNewUsersSearchTerm;
  } else if (
    currentTab === t('dashboardComponents.Console.UserManagement.tabs.roles')
  ) {
    actions = roleActions;
    sortOptions = sortRolesOptions;
    activeSortOption = activeRolesSortOption;
    setActiveSortOption = setActiveRolesSortOption;
    sortOrder = rolesSortOrder;
    setSortOrder = setRolesSortOrder;
    searchTerm = rolesSearchTerm;
    setSearchTerm = setRolesSearchTerm;
  }

  // Pagination variables
  let currentPage = 0;
  let setCurrentPage = () => {};
  let totalPages = 0;

  if (
    currentTab === t('dashboardComponents.Console.UserManagement.tabs.users')
  ) {
    currentPage = usersPage;
    totalPages = users?.totalPages || 0;
    setCurrentPage = setUsersPage;
  } else if (
    currentTab === t('dashboardComponents.Console.UserManagement.tabs.newUsers')
  ) {
    currentPage = newUsersPage;
    totalPages = newUsers?.totalPages || 0;
    setCurrentPage = setNewUsersPage;
  } else if (
    currentTab === t('dashboardComponents.Console.UserManagement.tabs.roles')
  ) {
    currentPage = rolesPage;
    totalPages = roles?.totalPages || 0;
    setCurrentPage = setRolesPage;
  }

  return (
    <div className={classes.UserManagement}>
      <Tabs
        name="user"
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        tabs={tabs}
        countData={{
          [t('dashboardComponents.Console.UserManagement.tabs.users')]:
            users?.totalCount || 0,
          [t('dashboardComponents.Console.UserManagement.tabs.roles')]:
            roles?.totalCount || 0,
          [t('dashboardComponents.Console.UserManagement.tabs.newUsers')]:
            newUsers?.totalCount || 0,
        }}
      />
      <div className={classes.tableContainer}>
        <TableMenu
          actions={actions}
          activeAction={activeAction}
          setActiveAction={setActiveAction}
          dangerAction="Delete"
          sortOptions={sortOptions}
          activeSortOption={activeSortOption}
          setActiveSortOption={setActiveSortOption}
          selectedCount={selectedRows.length}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          searchHistory={searchHistory}
          desktopLayoutOnMobile
          setSortOrder={setSortOrder}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />

        {currentTab ===
          t('dashboardComponents.Console.UserManagement.tabs.users') && (
          <Table
            name={t('dashboardComponents.Console.UserManagement.tabs.users')}
            isFetching={areUsersFetching}
            onRowClick={showViewUser}
            rows={users?.data || []}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            sortOptions={sortOptions}
            setActiveSortOption={setActiveUsersSortOption}
            activeSortOption={activeSortOption}
            setSortOrder={setSortOrder}
            sortOrder={sortOrder}
            columns={[
              {
                field: 'userProfile.firstName',
                name: t(
                  'dashboardComponents.Console.UserManagement.columns.users.firstName'
                ),
                sortable: true,
              },
              {
                field: 'userProfile.lastName',
                name: t(
                  'dashboardComponents.Console.UserManagement.columns.users.lastName'
                ),
                sortable: true,
              },
              {
                field: 'userName',
                name: t(
                  'dashboardComponents.Console.UserManagement.columns.users.email'
                ),
                sortable: true,
              },
              {
                field: 'userProfile.phone',
                name: t(
                  'dashboardComponents.Console.UserManagement.columns.users.phone'
                ),
                sortable: true,
              },
              {
                field: 'cities',
                name: t(
                  'dashboardComponents.Console.UserManagement.columns.users.city'
                ),
                type: 'array',
              },
              {
                field: 'locations',
                name: t(
                  'dashboardComponents.Console.UserManagement.columns.users.restaurant'
                ),
                type: 'array',
              },
              {
                field: 'roles',
                name: t(
                  'dashboardComponents.Console.UserManagement.columns.users.role'
                ),
                type: 'role',
              },
              {
                field: 'createdAt',
                name: t('common.createdOn'),
                sortable: true,
                type: 'date',
              },
            ]}
          />
        )}

        {currentTab === 'New Users' && (
          <Table
            name="New Users"
            fetchNextPage={fetchNextPage}
            isFetching={areNewUsersFetching}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            sortOptions={sortOptions}
            activeSortOption={activeSortOption}
            setActiveSortOption={setActiveNewUsersSortOption}
            setSortOrder={setSortOrder}
            sortOrder={sortOrder}
            onRowClick={showViewNewUserRequest}
            columns={[
              {
                field: 'firstName',
                name: t(
                  'dashboardComponents.Console.UserManagement.columns.users.firstName'
                ),
                sortable: true,
              },
              {
                field: 'lastName',
                name: t(
                  'dashboardComponents.Console.UserManagement.columns.users.lastName'
                ),
                sortable: true,
              },
              {
                field: 'email',
                name: t(
                  'dashboardComponents.Console.UserManagement.columns.users.email'
                ),
                sortable: true,
              },
              {
                field: 'cities',
                name: t(
                  'dashboardComponents.Console.UserManagement.columns.users.city'
                ),
                type: 'array',
              },
              {
                field: 'locations',
                name: t(
                  'dashboardComponents.Console.UserManagement.columns.users.restaurant'
                ),
                type: 'array',
              },
              {
                field: 'createdAt',
                name: t('common.createdOn'),
                sortable: true,
                type: 'date',
              },
            ]}
            rows={newUsers?.data || []}
            noDataMessage={
              <>
                Everything&apos;s in order! <br /> There are no new user
                requests to approve at the moment.
              </>
            }
          />
        )}
        {currentTab === 'Roles' && (
          <Table
            noSort
            name="Roles"
            fetchNextPage={fetchNextRolesPage}
            isFetching={areRolesFetching}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            sortOptions={sortOptions}
            activeSortOption={activeSortOption}
            setActiveSortOption={setActiveSortOption}
            setIsAddOrEditRoleVisible={setIsAddRoleVisible}
            setSortOrder={setSortOrder}
            sortOrder={sortOrder}
            onRowClick={showViewRole}
            columns={[
              {
                field: 'name',
                name: t(
                  'dashboardComponents.Console.UserManagement.columns.roles.roleName'
                ),
                sortable: true,
              },
              {
                field: 'scopes',
                name: t(
                  'dashboardComponents.Console.UserManagement.columns.roles.rights'
                ),
                type: 'scope',
              },
              {
                field: 'note',
                name: t('common.notes'),
                sortable: true,
              },
              {
                field: 'createdAt',
                name: t('common.createdOn'),
                sortable: true,
                type: 'date',
              },
            ]}
            rows={roles?.data || []}
          />
        )}

        <AddUserForm
          isVisible={isAddUserFormVisible}
          handleClose={() => setIsAddUserFormVisible(false)}
          refetch={refetchNewUsers}
          setIsAddUserFormVisible={setIsAddUserFormVisible}
          roles={roles?.data || []}
        />

        <ViewNewUserRequest
          isVisible={isViewNewUserRequestVisible}
          newUsers={newUsers?.data || []}
          currentNewUserId={currentNewUserId}
          handleClose={() => setIsViewNewUserRequestVisible(false)}
          refetch={refetchNewUsers}
          setIsReviewNewUserRequestVisible={setIsReviewNewUserRequestVisible}
          setSelectedRows={setSelectedRows}
          isOverlayed={isReviewNewUserRequestVisible}
        />

        <ReviewNewUserRequest
          isVisible={isReviewNewUserRequestVisible}
          newUsers={newUsers?.data || []}
          selectedRows={selectedRows}
          handleClose={() => setIsReviewNewUserRequestVisible(false)}
          refetch={refetchNewUsers}
          roles={roles?.data || []}
          noAnimation={isViewNewUserRequestVisible}
        />

        <ViewUser
          isVisible={isViewUserVisible}
          handleClose={() => setIsViewUserVisible(false)}
          users={users?.data || []}
          currentUserId={currentUserId}
          refetch={refetchUsers}
          setIsEditUserVisible={setIsEditUserVisible}
          setSelectedRows={setSelectedRows}
          isOverlayed={isEditUserVisible}
        />

        <EditUser
          isVisible={isEditUserVisible}
          users={users?.data || []}
          selectedRows={selectedRows}
          handleClose={() => setIsEditUserVisible(false)}
          refetch={refetchUsers}
          noAnimation={isViewUserVisible}
        />

        <AddRole
          isVisible={isAddRoleVisible}
          handleClose={() => setIsAddRoleVisible(false)}
          addOrEditRoleMode="add"
          currentRoleId={currentRoleId}
          refetch={refetchRoles}
          showAddRole={showAddRole}
        />

        <EditRole
          isVisible={isEditRoleVisible}
          handleClose={() => setIsEditRoleVisible(false)}
          roles={roles?.data || []}
          currentRoleId={currentRoleId}
          refetch={refetchRoles}
          selectedRows={selectedRows}
          noAnimation={isViewRoleVisible}
        />

        <ViewRole
          isVisible={isViewRoleVisible}
          handleClose={() => setIsViewRoleVisible(false)}
          roles={roles?.data || []}
          currentRoleId={currentRoleId}
          refetch={refetchRoles}
          showAddRole={showAddRole}
          showEditRoleSingle={showEditRoleSingle}
          isOverlayed={isEditRoleVisible}
        />
      </div>
    </div>
  );
}
