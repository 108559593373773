import React, { useState, useEffect, useRef, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import TextInput from '../../components/Form/TextInput';
import Dropdown from '../../components/Form/Dropdown';
import infoIcon from '../../assets/images/modals/info.svg';
import Button from '../../components/Button';

import { rejectCandidate } from '../../helpers/candidates';
import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';

export default function RejectCandidateWithReasonModal({
  show,
  handleClose,
  candidate,
  refetch,
}) {
  const [rejectionReasonOption, setRejectionReasonOption] = useState('');
  const [rejectionReason, setRejectionReason] = useState('');

  const textInputRef = useRef();

  const { showNotification, setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setRejectionReasonOption('');
    };
  }, [show]);

  useEffect(() => {
    if (rejectionReasonOption?.value === 'Type a reason (25 chars only)') {
      textInputRef.current?.focus?.();
    }
  }, [rejectionReasonOption]);

  let isSubmitButtonDisabled = false;

  if (!rejectionReasonOption) {
    isSubmitButtonDisabled = true;
  }

  if (
    rejectionReasonOption?.value === 'Type a reason (25 chars only)' &&
    !rejectionReason
  ) {
    isSubmitButtonDisabled = true;
  }

  const options = [
    {
      label: t(
        'modals.RejectCandidateWithReasonModal.options.candidateNotResponsive'
      ),
      value: 'Candidate not responsive',
    },
    {
      label: t(
        'modals.RejectCandidateWithReasonModal.options.candidateWithdrew'
      ),
      value: 'Candidate withdrew',
    },
    {
      label: t(
        'modals.RejectCandidateWithReasonModal.options.candidateMisrepresented'
      ),
      value: 'Candidate misrepresented',
    },
    {
      label: t(
        'modals.RejectCandidateWithReasonModal.options.candidateCultureFit'
      ),
      value: 'Candidate culture fit',
    },
    {
      label: t(
        'modals.RejectCandidateWithReasonModal.options.referenceCheckFailure'
      ),
      value: 'Reference check failure',
    },
    {
      label: t('modals.RejectCandidateWithReasonModal.options.typeReason'),
      value: 'Type a reason (25 chars only)',
    },
  ];

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.Modal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <img
          width={32}
          height={32}
          className={classes.icon}
          src={infoIcon}
          alt="Icon"
        />
        <h1>{t('modals.RejectCandidateWithReasonModal.rejectionReason')}</h1>
        <p>
          {t(
            'modals.RejectCandidateWithReasonModal.exceptionReasonDescription'
          )}
        </p>
        <div>
          {rejectionReasonOption?.value === 'Type a reason (25 chars only)' ? (
            <TextInput
              maxLength={25}
              inputRef={textInputRef}
              width={295}
              height={56}
              placeholder={t(
                'modals.RejectCandidateWithReasonModal.typeReasonPlaceholder'
              )}
              value={rejectionReason}
              onChange={(event) => setRejectionReason(event.target.value)}
            />
          ) : (
            <Dropdown
              width={295}
              height={56}
              options={options}
              activeOption={rejectionReasonOption}
              setActiveOption={setRejectionReasonOption}
              placeholder={t(
                'modals.RejectCandidateWithReasonModal.selectReasonPlaceholder'
              )}
            />
          )}
        </div>
        <div className={classes.buttons}>
          <Button
            disabled={isSubmitButtonDisabled}
            width={144}
            onClick={() => {
              rejectCandidate(
                candidate,
                refetch,
                showNotification,
                null,
                setIsFetching
              );
              handleClose();
            }}
          >
            {t('common.submit')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
