import React from 'react';

import classes from './styles.module.scss';

export default function HiringPipelineValue({ children, backgroundColor }) {
  return (
    <div
      className={classes.HiringPipelineValue}
      style={{ background: backgroundColor }}
    >
      {children}
    </div>
  );
}
