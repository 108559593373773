import React, { useContext } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Checkbox from '../../../../../Form/Checkbox';

import { UiContext } from '../../../../../../context/UiContext';
import AdminService from '../../../../../../services/AdminService';
import classes from './styles.module.scss';
import { IMAGES_URL } from '../../../../../../constants/main';

const favoriteIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="url(#paint0_linear_1247_43675)" />
    <path
      d="M7.84479 13.8138C7.63031 13.9266 7.37964 13.7444 7.4206 13.5056L7.8322 11.1058L6.08861 9.40618C5.91509 9.23704 6.01084 8.94235 6.25064 8.9075L8.66023 8.55737L9.73783 6.37391C9.84507 6.15661 10.1549 6.15661 10.2622 6.37391L11.3398 8.55737L13.7494 8.9075C13.9892 8.94235 14.0849 9.23704 13.9114 9.40618L12.1678 11.1058L12.5794 13.5056C12.6204 13.7444 12.3697 13.9266 12.1552 13.8138L10 12.6808L7.84479 13.8138Z"
      fill="white"
      stroke="white"
      strokeWidth="1.5"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1247_43675"
        x1="10"
        y1="-11.2662"
        x2="-12.5324"
        y2="8.73379"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00E4AC" />
        <stop offset="0.504968" stopColor="#10C5E9" />
        <stop offset="1" stopColor="#9240DC" />
      </linearGradient>
    </defs>
  </svg>
);

const nonFavoriteIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="#242833" fillOpacity="0.5" />
    <path
      d="M11.7672 20.7207C11.4454 20.8899 11.0694 20.6167 11.1309 20.2584L11.7483 16.6586L9.13288 14.1093C8.8726 13.8556 9.01623 13.4135 9.37592 13.3613L12.9903 12.8361L14.6067 9.56086C14.7676 9.23492 15.2324 9.23492 15.3932 9.56086L17.0096 12.8361L20.624 13.3613C20.9837 13.4135 21.1273 13.8556 20.8671 14.1093L18.2517 16.6586L18.8691 20.2584C18.9305 20.6167 18.5545 20.8899 18.2328 20.7207L15 19.0211L11.7672 20.7207Z"
      stroke="white"
      strokeWidth="1.5"
    />
  </svg>
);

export default function JobCard({
  reference,
  job,
  refetch,
  setSelectedRows,
  selectedRows,
  backgroundColor,
  noShadow,
  onClick,
  long,
  favorites,
}) {
  const { setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const userAvatar = job?.recruiter?.avatar
    ? `${IMAGES_URL}/avatar/${job?.recruiter?.avatar}`
    : '';

  const addJobToFavorites = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    try {
      setIsFetching(true);

      await AdminService.addMyJobToFavorites({ jobId: job.id });
      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const removeJobFromFavorites = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    try {
      setIsFetching(true);
      await AdminService.removeMyJobFromFavorites({ jobId: job.id });
      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const toggleRowSelection = (id) => {
    setSelectedRows((prevRows) => {
      if (prevRows.includes(id)) {
        const newRows = prevRows.filter((row) => row !== id);
        return newRows;
      }
      const newRows = [...prevRows, id];
      return newRows;
    });
  };

  const daysFromCreation = moment(new Date()).diff(
    moment(job.createdAt),
    'days'
  );

  let timeIconFill = '#C1C5D6';

  if (daysFromCreation > 30) {
    timeIconFill = '#FFBF00';
  }

  if (daysFromCreation > 45) {
    timeIconFill = '#FF2D55';
  }

  return (
    <Link
      className={classNames(classes.JobCard, {
        [classes.green]: job?.status === 'Active',
        [classes.orange]: job?.status === 'OnHold',
        [classes.noShadow]: noShadow,
        [classes.long]: long,
        [classes.favorites]: favorites,
      })}
      ref={reference}
      to={`/tools/jobs/my-jobs/${job.id}`}
      onClick={onClick}
      style={{
        // maxWidth,
        width: long ? '499px' : '',
        height: long && '163px',
        backgroundColor,
        boxShadow: long && '0px 4px 20px rgb(0, 0, 0, 10%)',
      }}
    >
      <header className={classes.header}>
        <div className={classes.leftBlock}>
          {selectedRows && (
            <Checkbox
              isSelected={selectedRows.includes(job.id)}
              onChange={(event) => {
                event.preventDefault();
                event.stopPropagation();
                toggleRowSelection(job.id);
              }}
            />
          )}
          <div className={classes.code}>#{job.id}</div>
        </div>
        <div className={classes.rightBlock}>
          <span className={classes.status}>
            {job?.status?.match(/[A-Z][a-z]+|[0-9]+/g).join(' ')}
          </span>
          {userAvatar ? (
            <img src={userAvatar} className={classes.userImage} alt="" />
          ) : (
            <div className={classes.userImage}>
              {job?.recruiter?.firstName[0]}
              {job?.recruiter?.lastName[0]}
            </div>
          )}
          {job.isFavorite ? (
            <button
              type="button"
              className={classes.favoritesButton}
              onClick={removeJobFromFavorites}
            >
              {favoriteIcon}
            </button>
          ) : (
            <button
              type="button"
              className={classes.favoritesButton}
              onClick={addJobToFavorites}
            >
              {nonFavoriteIcon}
            </button>
          )}
        </div>
      </header>
      <h2>{job.jobTitle}</h2>
      <div className={classes.details}>
        <div className={classes.info}>
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 15.2008C6.86667 15.2008 6.66667 15.2008 6.53334 15.1341C6.4 15.0674 6.26667 15.0008 6.13333 14.8674L2.66667 11.3341C1.53334 10.2008 0.866669 8.60078 0.866669 7.00078C0.866669 5.33411 1.53334 3.80078 2.66667 2.66745C3.8 1.53411 5.33333 0.800781 7 0.800781C8.66667 0.800781 10.2 1.46745 11.3333 2.60078C12.4667 3.73411 13.1333 5.26745 13.1333 6.93411C13.1333 8.60078 12.4667 10.1341 11.3333 11.2674L7.8 14.8008C7.66667 14.9341 7.6 15.0008 7.4 15.0674C7.26667 15.1341 7.13333 15.2008 7 15.2008ZM7 1.80078C5.6 1.80078 4.33334 2.33411 3.4 3.33411C2.4 4.33411 1.86667 5.60078 1.86667 7.00078C1.86667 8.40078 2.4 9.66745 3.4 10.6674L6.93333 14.2008C6.93333 14.2008 7 14.2008 7 14.2674C7.06667 14.2674 7.06667 14.2674 7.13333 14.2674C7.13333 14.2674 7.2 14.2674 7.2 14.2008L10.7333 10.6674C11.7333 9.66745 12.2 8.40078 12.2 7.06745C12.2 5.73411 11.6667 4.40078 10.7333 3.46745C9.66667 2.33411 8.4 1.80078 7 1.80078Z"
              fill="#C1C5D6"
            />
            <path
              d="M7.06667 10.3349C6.86667 10.3349 6.60001 10.3349 6.40001 10.2682C5.73334 10.1349 5.13334 9.80156 4.66667 9.3349C4.00001 8.66823 3.66667 7.86823 3.66667 6.9349C3.66667 6.00156 4.00001 5.20156 4.66667 4.5349C5.00001 4.26823 5.33334 4.00156 5.73334 3.86823C6.13334 3.7349 6.53334 3.60156 7 3.60156C7.46667 3.60156 7.86667 3.66823 8.26667 3.86823C8.66667 4.06823 9.06667 4.26823 9.33334 4.60156C9.66667 4.9349 9.86667 5.26823 10.0667 5.66823C10.2667 6.06823 10.3333 6.5349 10.3333 6.9349C10.3333 7.60156 10.1333 8.26823 9.73334 8.80156C9.33334 9.3349 8.86667 9.80156 8.20001 10.0016C7.93334 10.2682 7.46667 10.3349 7.06667 10.3349ZM5.33334 5.26823C4.86667 5.7349 4.66667 6.3349 4.66667 6.9349C4.66667 7.5349 4.93334 8.1349 5.33334 8.60156C5.66667 8.9349 6.06667 9.1349 6.53334 9.26823C7.00001 9.3349 7.46667 9.3349 7.86667 9.13489C8.33334 8.9349 8.66667 8.66823 8.93334 8.26823C9.2 7.86823 9.33334 7.40156 9.33334 6.9349C9.33334 6.60156 9.26667 6.3349 9.13334 6.00156C9 5.7349 8.86667 5.46823 8.6 5.26823C8.4 5.06823 8.13334 4.86823 7.8 4.7349C7.66667 4.66823 7.33334 4.60156 7.06667 4.60156C6.73334 4.60156 6.46667 4.66823 6.13334 4.80156C5.86667 4.86823 5.60001 5.06823 5.33334 5.26823Z"
              fill="#C1C5D6"
            />
          </svg>
          <span>
            <span className={classes.value}>{job.locationCity?.name}</span>
          </span>
        </div>
        <div className={classes.info}>
          <svg
            width="13"
            height="16"
            viewBox="0 0 13 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.40001 15.4023C4.93334 15.4023 0.53334 15.4023 0.53334 12.4023C0.53334 9.40234 4.80001 9.40234 6.40001 9.40234C7.86667 9.40234 12.2 9.40234 12.2 12.4023C12.2667 15.4023 8.00001 15.4023 6.40001 15.4023ZM6.40001 10.4023C3.13334 10.4023 1.53334 11.0023 1.53334 12.4023C1.53334 13.7357 3.13334 14.4023 6.40001 14.4023C9.66667 14.4023 11.2 13.8023 11.2 12.4023C11.2667 11.069 9.66667 10.4023 6.40001 10.4023Z"
              fill="#C1C5D6"
            />
            <path
              d="M6.40001 8.4013C4.26667 8.4013 2.53334 6.66797 2.53334 4.53464C2.53334 2.4013 4.26667 0.667969 6.40001 0.667969C8.53334 0.667969 10.2667 2.4013 10.2667 4.53464C10.2667 6.66797 8.60001 8.4013 6.40001 8.4013ZM6.40001 1.6013C4.80001 1.6013 3.53334 2.86797 3.53334 4.46797C3.53334 6.06797 4.80001 7.33464 6.40001 7.33464C8.00001 7.33464 9.33334 6.06797 9.33334 4.46797C9.33334 2.86797 8.00001 1.6013 6.40001 1.6013Z"
              fill="#C1C5D6"
            />
          </svg>
          <span>
            <span className={classes.value}>{job?.applicantCount}</span>{' '}
            {t('dashboardComponents.Jobs.MyJobs.GridView.JobCard.applicants')}
          </span>
        </div>
        {!favorites && (
          <div className={classes.info}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.95804 7.99898C1.95804 4.66211 4.66311 1.95703 7.99999 1.95703C11.3369 1.95703 14.0419 4.66211 14.0419 7.99898C14.0419 11.3359 11.3369 14.0409 7.99999 14.0409C4.66311 14.0409 1.95804 11.3359 1.95804 7.99898ZM7.99999 0.957031C4.11083 0.957031 0.958038 4.10982 0.958038 7.99898C0.958038 11.8881 4.11083 15.0409 7.99999 15.0409C11.8892 15.0409 15.0419 11.8881 15.0419 7.99898C15.0419 4.10982 11.8892 0.957031 7.99999 0.957031ZM8.49999 4.07381C8.49999 3.79767 8.27613 3.57381 7.99999 3.57381C7.72385 3.57381 7.49999 3.79767 7.49999 4.07381V7.99898C7.49999 8.18837 7.60699 8.3615 7.77638 8.4462L10.3932 9.75459C10.6402 9.87808 10.9405 9.77797 11.064 9.53098C11.1875 9.28399 11.0874 8.98365 10.8404 8.86016L8.49999 7.68997V4.07381Z"
                fill={timeIconFill}
              />
            </svg>
            <span>
              <span className={classes.value}>{daysFromCreation}</span>{' '}
              {daysFromCreation < 2
                ? t('dashboardComponents.Jobs.MyJobs.GridView.JobCard.day')
                : t('dashboardComponents.Jobs.MyJobs.GridView.JobCard.days')}
            </span>
          </div>
        )}
      </div>
      {!long && !favorites && (
        <div className={classes.hiringPipeline}>
          <table>
            <thead>
              <tr>
                <th className={classes.new}>
                  {t('dashboardComponents.Jobs.MyJobs.GridView.JobCard.new')}
                </th>
                <th>
                  {t('dashboardComponents.Jobs.MyJobs.GridView.JobCard.screen')}
                </th>
                <th>
                  {t(
                    'dashboardComponents.Jobs.MyJobs.GridView.JobCard.interview'
                  )}
                </th>
                <th>
                  {t('dashboardComponents.Jobs.MyJobs.GridView.JobCard.offer')}
                </th>
                <th>
                  {t(
                    'dashboardComponents.Jobs.MyJobs.GridView.JobCard.onboard'
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={classes.new}>{job?.summary.new}</td>
                <td>{job?.summary.screen}</td>
                <td>{job?.summary.interview}</td>
                <td>{job?.summary.offer}</td>
                <td>{job?.summary.onboard}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </Link>
  );
}
