import React from 'react';

import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { useMediaQuery } from 'react-responsive';

import TwoWeeksCalendar from './TwoWeeksCalendar';
import LatestNotifications from './LatestNotifications';
import MessagesAndFeedback from './MessagesAndFeedback';
import UpcomingEvents from './UpcomingEvents';
import RecruitmentFunnel from '../LeftPanel/RecruitmentFunnel';
import RedAlertJobs from '../LeftPanel/RedAlertJobs';

import { getAuthData } from '../../../../helpers/authStorage';
import { getEvents } from '../../../../helpers/getEvents';
import classes from './styles.module.scss';
import AdminService from '../../../../services/AdminService';

const today = moment();

export default function RightPanel({ leftPanelData }) {
  const { userId } = getAuthData();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { data: jobApplications } = useQuery({
    queryKey: ['events'],
    queryFn: AdminService.getJobApplications,
  });

  const events = getEvents(jobApplications, +userId);

  return (
    <div className={classes.RightPanel}>
      <div className={classes.container}>
        <div className={classes.calendarContainer}>
          <TwoWeeksCalendar today={today} events={events} />
        </div>
        <div className={classes.upcomingEventsContainer}>
          <UpcomingEvents today={today} events={events} />
        </div>
        <div className={classes.messagesAndFeedbackContainer}>
          <MessagesAndFeedback />
        </div>
        <LatestNotifications />
      </div>
      {isTabletOrMobile && (
        <div className={classes.funnelAndJobsContainer}>
          <RedAlertJobs />
          <RecruitmentFunnel data={leftPanelData} />
        </div>
      )}
    </div>
  );
}
