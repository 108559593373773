import React from 'react';

import classes from './styles.module.scss';

import Tooltip from '../Tooltip';

export default function InfoIcon({ info, infoWidth, tooltipDirection, fixed }) {
  return (
    <Tooltip
      text={info}
      width={infoWidth}
      direction={tooltipDirection}
      fixed={fixed}
    >
      <i className={classes.InfoIcon}>
        <svg
          width="3"
          height="10"
          viewBox="0 0 3 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1.25H1.03125"
            stroke="#242833"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 8.75V4.75"
            stroke="#242833"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </svg>
      </i>
    </Tooltip>
  );
}
