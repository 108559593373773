import React from 'react';

import JobCard from './JobCard';

import useIntersectionObserverPagination from '../../../../../hooks/useIntersectionObserverPagination';
import classes from './styles.module.scss';

export default function GridView({
  data,
  isFetching,
  fetchNextPage,
  dataCount,
  refetch,
  selectedRows,
  setSelectedRows,
  containerRef,
  height,
}) {
  const { lastListElementRef } = useIntersectionObserverPagination({
    isFetching,
    fetchNextPage,
  });

  return (
    <div className={classes.GridView} style={{ height }}>
      {data?.map((job, index) => (
        <JobCard
          job={job}
          key={job.id}
          reference={index === dataCount - 1 ? lastListElementRef : null}
          refetch={refetch}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          containerRef={containerRef}
          jobCount={data.length}
        />
      ))}
    </div>
  );
}
