import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';

export default function PageInput({
  resetSelectorValueOnBlur,
  pageSelectorValue,
  setPageSelectorValue,
  isInvalid,
  classes,
  switchToPageOnEnter,
}) {
  const inputRef = useRef();

  // Focus on mount
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <input
      onKeyDown={switchToPageOnEnter}
      ref={inputRef}
      onBlur={resetSelectorValueOnBlur}
      className={classNames(classes.currentPage, classes.page, {
        [classes.invalid]: isInvalid,
      })}
      value={pageSelectorValue}
      onChange={(event) => {
        const currentValue = event.target.value;

        if (/^\d*$/.test(currentValue)) {
          setPageSelectorValue(currentValue);
        }
      }}
    />
  );
}
