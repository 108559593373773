import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Scheme({ scheme, isActive, onClick }) {
  return (
    <div
      className={classNames(classes.Scheme, {
        [classes.active]: isActive,
      })}
      onClick={onClick}
      tabIndex={0}
      role="button"
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onClick();
        }
      }}
    >
      {scheme.map((color) => {
        return (
          <div
            key={color}
            className={classes.color}
            style={{ backgroundColor: color }}
          />
        );
      })}
    </div>
  );
}
