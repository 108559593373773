import { useState, useEffect, useRef } from 'react';

export default function useSwitchableRowData(data, selectedRows) {
  const [currentDataIndex, setCurrentDataIndex] = useState(null);

  const currentRowIndex = useRef();

  const switchToPrevDataElement = () => {
    let newRowIndex;
    if (currentRowIndex.current === 0) {
      newRowIndex = selectedRows.length - 1;
    } else {
      newRowIndex = currentRowIndex.current - 1;
    }

    const dataIndex = data?.findIndex(
      (elem) => elem.id === selectedRows[newRowIndex]
    );

    if (dataIndex !== -1) {
      setCurrentDataIndex(dataIndex);
      currentRowIndex.current = newRowIndex;
    }
  };

  const switchToNextDataElement = () => {
    let newRowIndex;

    if (currentRowIndex.current === selectedRows.length - 1) {
      newRowIndex = 0;
    } else {
      newRowIndex = currentRowIndex.current + 1;
    }

    const dataIndex = data?.findIndex(
      (elem) => elem.id === selectedRows[newRowIndex]
    );

    if (dataIndex !== -1) {
      setCurrentDataIndex(dataIndex);
      currentRowIndex.current = newRowIndex;
    }
  };

  useEffect(() => {
    const dataIndex = data?.findIndex?.(
      (role) => role.id === selectedRows?.[0]
    );

    if (dataIndex !== -1) {
      setCurrentDataIndex(dataIndex);
    }

    currentRowIndex.current = 0;
  }, [selectedRows, data]);

  return { currentDataIndex, switchToPrevDataElement, switchToNextDataElement };
}
