import React, { useState, useRef, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Tag from './Tag';

import addIcon from '../../../../../../assets/images/dashboard/discover/textEditor/add.svg';
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function Tags({
  tags,
  setTags,
  isAddingTag,
  setIsAddingTag,
  addTagButtonRef,
}) {
  const [newTag, setNewTag] = useState('');

  const { t } = useTranslation();

  const closeTagInput = () => {
    setIsAddingTag(false);
    setNewTag('');
  };

  const tagInputRef = useRef();
  useOnClickOutside(tagInputRef, closeTagInput, addTagButtonRef);

  useEffect(() => {
    if (isAddingTag) {
      tagInputRef.current.focus();
    }
  }, [isAddingTag]);

  return (
    <div className={classes.Tags}>
      <div className={classes.title}>
        {t('dashboardComponents.Discover.AddContent.TextEditor.Tags.title')}
      </div>
      <div className={classes.tagsContainer}>
        {tags.map((tag) => (
          <Tag key={tag} tag={tag} setTags={setTags} />
        ))}
        {isAddingTag && (
          <input
            value={newTag}
            onChange={(event) => setNewTag(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setTags((prevTags) => [...prevTags, newTag]);
                setNewTag('');
                setIsAddingTag(false);
              }
            }}
            ref={tagInputRef}
            type="text"
            className={classes.tagInput}
            placeholder={t(
              'dashboardComponents.Discover.AddContent.TextEditor.Tags.addTagPlaceholder'
            )}
          />
        )}
        {tags?.length ? (
          <button
            type="button"
            className={classes.addMoreButton}
            onClick={() => {
              setIsAddingTag(true);
            }}
          >
            <img src={addIcon} alt="Add more" />{' '}
            {t(
              'dashboardComponents.Discover.AddContent.TextEditor.Tags.addMoreButton'
            )}
          </button>
        ) : null}
      </div>
    </div>
  );
}
