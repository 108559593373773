import React from 'react';

import AuthCarousel from '../../components/Auth/AuthCarousel';
import AuthHeader from '../../components/Auth/AuthHeader';

import classes from './styles.module.scss';

export default function AuthLayout({ children, noButton, previousRoute }) {
  return (
    <main className={classes.AuthLayout}>
      <div className={classes.leftColumn}>
        <AuthHeader noButton={noButton} previousRoute={previousRoute} />
        {children}
      </div>
      <div className={classes.rightColumn}>
        <AuthCarousel />
      </div>
    </main>
  );
}
