import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Tab({ title, count, setActiveTab, isActive, type }) {
  return (
    <div
      className={classNames(classes.Tab, {
        [classes.active]: isActive,
      })}
      onClick={() => setActiveTab(type)}
    >
      <h4>{title}</h4> <div className={classes.count}>{count}</div>
    </div>
  );
}
