/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import { useTranslation } from 'react-i18next';

import Textarea from '../../../../../Form/Textarea';
import TextInput from '../../../../../Form/TextInput';
import SidePopup from '../../../SidePopup';

import useSwitchableData from '../../../../../../hooks/useSwitchableData';
import classes from './styles.module.scss';

const RightButton = () => <></>;

export default function ViewContent({
  isVisible,
  handleClose,
  currentDataId,
  data,
  showEditDataSingle,
  isOverlayed,
}) {
  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableData(data, currentDataId);

  const { t } = useTranslation();

  return (
    <SidePopup
      isOverlayed={isOverlayed}
      isVisible={isVisible}
      handleClose={handleClose}
      switchNext={data?.length > 1 && switchToNextDataElement}
      switchPrev={data?.length > 1 && switchToPrevDataElement}
      title={t(
        'dashboardComponents.Console.ContentManagement.PicVideo.ViewContent.Title'
      )}
      rightButtonComponent={RightButton}
      leftButtonLabel={t('common.update')}
      onLeftButtonClick={() => {
        showEditDataSingle(data?.[currentDataIndex]?.id);
      }}
    >
      <div className={classes.ViewContent}>
        <div className={classes.row}>
          <div className={classes.col}>
            <TextInput
              height={50}
              readonly
              label={t(
                'dashboardComponents.Console.ContentManagement.PicVideo.ViewContent.PageReference'
              )}
              value={data?.[currentDataIndex]?.location}
            />
          </div>
          <div className={classes.col}>
            <Textarea
              readonly
              height={150}
              label={t(
                'dashboardComponents.Console.ContentManagement.PicVideo.ViewContent.Instructions'
              )}
              value={data?.[currentDataIndex]?.instructions}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.mediaContainer}>
            <h1>Picture/video</h1>
            {data?.[currentDataIndex]?.type === 'Video' ? (
              <video
                src={data?.[currentDataIndex]?.url}
                className={classes.media}
              />
            ) : (
              <img
                src={data?.[currentDataIndex]?.url}
                className={classes.media}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
    </SidePopup>
  );
}
