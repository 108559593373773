import React from 'react';

import classes from './styes.module.scss';

export default function Container({ children, style }) {
  return (
    <div className={classes.Container} style={style}>
      {children}
    </div>
  );
}
