import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Button from '../../../Button';
import Dropdown from '../../../Form/Dropdown';
import SearchInput from '../../../Form/SearchInput';
import FullScreenButton from '../../FullScreenButton';
import Pagination from './Pagination';

import classes from './styles.module.scss';
import ViewSwitch from './ViewSwitch';
import QualifiedSwitch from './QualifiedSwitch';

export default function TableMenu({
  actions,
  activeAction,
  setActiveAction,
  dangerAction,
  sortOptions,
  activeSortOption,
  setActiveSortOption,
  selectedCount,
  searchTerm,
  setSearchTerm,
  searchHistory,
  style,
  view,
  setView,
  setSortOrder,
  isQualifiedSwitchVisible,
  candidatesFilter,
  setCandidatesFilter,
  qualifiedCount,
  disqualifiedCount,
  withFullScreenButton,
  isFullScreenModeEnabled,
  setIsFullScreenModeEnabled,
  desktopLayoutOnMobile,
  currentPage,
  totalPages,
  setCurrentPage,
}) {
  const { t } = useTranslation();

  const changeSortOrder = () => {
    setSortOrder((prevOrder) => {
      if (prevOrder === 'asc') {
        return 'desc';
      }
      return 'asc';
    });
  };

  return (
    <div
      className={classNames(classes.TableMenu, {
        [classes.desktopLayoutOnMobile]: desktopLayoutOnMobile,
      })}
      style={style}
    >
      <div className={classes.leftButtonGroup}>
        {actions.length ? (
          <div
            className={classNames(
              classes.dropdownContainer,
              classes.shadowContainer
            )}
          >
            <Dropdown
              options={actions}
              placeholder={t('dashboardComponents.Console.TableMenu.action')}
              activeOption={activeAction}
              setActiveOption={setActiveAction}
              dangerOption={dangerAction}
              width={150}
            />
          </div>
        ) : null}

        <div
          className={classes.selectionInfo}
          style={{
            visibility: selectedCount > 0 ? 'visible' : 'hidden',
            display:
              !desktopLayoutOnMobile && selectedCount < 1 ? 'none' : 'block',
          }}
        >
          {selectedCount}{' '}
          {selectedCount > 1
            ? t('dashboardComponents.Console.TableMenu.selectionInfoPlural')
            : t('dashboardComponents.Console.TableMenu.selectionInfoSingular')}
        </div>

        {isQualifiedSwitchVisible && (
          <QualifiedSwitch
            candidatesFilter={candidatesFilter}
            setCandidatesFilter={setCandidatesFilter}
            qualifiedCount={qualifiedCount}
            disqualifiedCount={disqualifiedCount}
          />
        )}
      </div>
      <div className={classes.paginationContainer}>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div className={classes.rightButtonGroup}>
        <div className={classes.shadowContainer}>
          <SearchInput
            value={searchTerm}
            setValue={setSearchTerm}
            searchHistory={searchHistory}
          />
        </div>
        <div className={classes.shadowContainer}>
          <Dropdown
            options={sortOptions}
            placeholder={t(
              'dashboardComponents.Console.TableMenu.sortOptions.sort'
            )}
            activeOption={activeSortOption}
            setActiveOption={setActiveSortOption}
            dangerOption={setActiveSortOption}
            width={150}
          />
        </div>
        <div className={classes.shadowContainer} style={{ display: 'none' }}>
          <Button
            className={classes.sortButton}
            variant="secondary"
            width={48}
            height={48}
            style={{ border: '1px solid #C1C5D6' }}
            onClick={changeSortOrder}
          />
        </div>
        {view && (
          <div className={classes.shadowContainer}>
            <ViewSwitch view={view} setView={setView} />
          </div>
        )}
        {withFullScreenButton && (
          <div className={classes.shadowContainer}>
            <FullScreenButton
              isFullScreenModeEnabled={isFullScreenModeEnabled}
              setIsFullScreenModeEnabled={setIsFullScreenModeEnabled}
            />
          </div>
        )}
      </div>
    </div>
  );
}
