import React from 'react';

import classes from './styles.module.scss';

export default function DefaultAvatar({ firstName, lastName }) {
  return (
    <div className={classes.DefaultAvatar}>
      {firstName && firstName[0]} {lastName && lastName[0]}
    </div>
  );
}
