import React from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import JobInfo from '../../Favorites/Candidates/CandidateCard/JobInfo';
import classes from './styles.module.scss';

export default function Applications({ jobApplications, isModal, style }) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div
      className={classes.Applications}
      style={{
        marginTop: isModal && '30px',
        height: isModal && '460px',
        ...style,
      }}
    >
      {!jobApplications?.length ? (
        <span className={classes.noData}>
          {t('dashboardComponents.Candidate.Applications.noData')}
        </span>
      ) : (
        jobApplications
          ?.map((application) => (
            <JobInfo
              application={application}
              key={application?.id}
              width={isTabletOrMobile ? '' : '100%'}
            />
          ))
          .reverse()
      )}
    </div>
  );
}
