import React, { useState, useContext, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import RICIBs from 'react-individual-character-input-boxes';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { saveAuthData } from '../../helpers/authStorage';
import { AuthContext } from '../../context/AuthContext';
import { UiContext } from '../../context/UiContext';
import UserService from '../../services/UserService';
import Button from '../../components/Button';
import AuthLayout from '../../containers/AuthLayout';
import classes from './styles.module.scss';

const inputProps = {
  className: classes.input,
  placeholder: 'x',
};

export default function OtpSignInPage() {
  const [otp, setOtp] = useState('');

  const { email } = useContext(AuthContext);
  const { showUnknownErrorModal, showModal } = useContext(UiContext);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleOutput = (string) => {
    setOtp(string);
  };

  const signIn = async () => {
    try {
      const response = await UserService.loginByOtp({ email, code: otp });
      saveAuthData({
        accessToken: response.token,
        userName: response.userName,
        userId: response.id,
      });
      navigate('/tools/discover');
    } catch (error) {
      if (error.response.status === 401) {
        showModal({
          title: t('pages.OtpSignInPage.incorrectOtpTitle'),
          text: t('pages.OtpSignInPage.incorrectOtpText'),
        });
      } else {
        showUnknownErrorModal();
      }
    }
  };

  const requestNewOtp = async () => {
    try {
      await UserService.requestOtp({ email });
      showModal({
        title: t('pages.OtpSignInPage.codeSentTitle'),
        text: t('pages.OtpSignInPage.codeSentText'),
      });
    } catch (error) {
      showUnknownErrorModal();
    }
  };

  useEffect(() => {
    const requestOtp = async () => {
      try {
        const response = await UserService.requestOtp({ email });
        console.log(response);
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      }
    };

    requestOtp();
  }, [email, showUnknownErrorModal]);

  return (
    <AuthLayout previousRoute="/login/method">
      <div className={classes.OtpSignInPage}>
        <Helmet>
          <title>{t('pages.OtpSignInPage.pageTitle')}</title>
        </Helmet>
        <div className={classes.container}>
          <h1>{t('pages.OtpSignInPage.enterOtpCode')}</h1>
          <div className={classes.inputs}>
            <RICIBs
              amount={6}
              handleOutputString={handleOutput}
              inputProps={Array.from(Array(10).keys()).map(() => inputProps)}
              // inputRegExp={/^[a-zA-Z0-9_]+$/}
            />
          </div>
          <div className={classes.helper}>
            <p>
              {t('pages.OtpSignInPage.didNotReceiveCode')}{' '}
              <span onClick={requestNewOtp}>
                {t('pages.OtpSignInPage.resendCode')}
              </span>
            </p>
            <p>{t('pages.OtpSignInPage.checkSpamFolder')}</p>
          </div>
          <Button disabled={otp.length !== 6} onClick={signIn} width="100%">
            {t('common.signIn')}
          </Button>
        </div>
      </div>
    </AuthLayout>
  );
}
