import React from 'react';

import { useTranslation } from 'react-i18next';

import SearchInput from '../../../../Form/SearchInput';
import FilterDropdown from './FilterDropdown';

import classes from './styles.module.scss';

export default function LeftPanelHeader({
  orderBy,
  setOrderBy,
  searchTerm,
  setSearchTerm,
  searchHistory,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.LeftPanelHeader}>
      <div className={classes.row}>
        <h2>{t('dashboardComponents.Chat.LeftPanel.LeftPanelHeader.title')}</h2>
        <FilterDropdown currentOption={orderBy} setCurrentOption={setOrderBy} />
        <button type="button" className={classes.sortButton}>
          Sort
        </button>
      </div>
      <div className={classes.row}>
        <SearchInput
          searchHistory={searchHistory}
          width="100%"
          value={searchTerm}
          setValue={setSearchTerm}
        />
      </div>
    </div>
  );
}
