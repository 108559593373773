/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';

import classNames from 'classnames';

import Slide from './Slide';
import Dot from './Dot';

import carouselImage1 from '../../../assets/images/carousel/image-1.png';
import carouselImage2 from '../../../assets/images/carousel/image-2.png';
import carouselImage3 from '../../../assets/images/carousel/image-3.png';
import classes from './styles.module.scss';

let timer;
let timer2;
let timer3;
const slidesSwitchInterval = 5000;

const slidesData = [
  {
    id: 0,
    text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
    image: carouselImage1,
  },
  {
    id: 1,
    text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
    image: carouselImage2,
  },
  {
    id: 2,
    text: 'Amet minim mollit non deserunt ullamco est sit',
    image: carouselImage3,
  },
  {
    id: 3,
    text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
    image: carouselImage2,
  },
  {
    id: 4,
    text: 'Amet minim mollit non deserunt ullamco est sit',
    image: carouselImage3,
  },
];

// const offsets = [65, -531, -1127, -1723, -2319];

export default function AuthCarousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimationDisabled, setIsAnimationDisabled] = useState(false);
  const [activeDot, setActiveDot] = useState(0);

  /* const scrollToNextSlide = () => {
    setCurrentSlide((prevSide) => prevSide + 1);
  }; */

  const switchSlidesOnTimer = useCallback(() => {
    setActiveDot((prevDot) => {
      if (prevDot + 1 < slidesData.length) {
        return prevDot + 1;
      }
      return 0;
    });
    setCurrentSlide((prevSlide) => {
      if (prevSlide + 1 <= slidesData.length + 1) {
        return prevSlide + 1;
      }
      return prevSlide;
    });
  }, []);

  useEffect(() => {
    timer = setInterval(switchSlidesOnTimer, slidesSwitchInterval);
  }, [switchSlidesOnTimer]);

  useEffect(() => {
    if (currentSlide === slidesData.length) {
      timer2 = setTimeout(() => {
        setIsAnimationDisabled(true);
        setCurrentSlide(0);
      }, 300);

      timer3 = setTimeout(() => {
        setIsAnimationDisabled(false);
      }, 310);
    }
  }, [currentSlide]);

  const switchToSlide = (slideIndex) => {
    clearInterval(timer);
    clearTimeout(timer2);
    clearTimeout(timer3);
    setActiveDot(slideIndex);
    setCurrentSlide(slideIndex);

    setTimeout(() => {
      timer = setInterval(switchSlidesOnTimer, slidesSwitchInterval);
    }, 300);
  };

  return (
    <div
      className={classNames(classes.AuthCarousel, {
        [classes.noAnimation]: isAnimationDisabled,
      })}
    >
      <div className={classes.background}>
        <div
          className={classes.carouselSlideContainer}
          style={{ left: currentSlide * -531 - (currentSlide - 1) * 65 }}
        >
          {slidesData.map((slide) => (
            <Slide key={slide.id} text={slide.text} image={slide.image} />
          ))}
          <Slide text={slidesData[0].text} image={slidesData[0].image} />
        </div>
        <div className={classes.dots}>
          {Array(5)
            .fill(null)
            .map((_, i) => i)
            .map((dot, index) => (
              <Dot
                key={dot}
                active={index === activeDot}
                onClick={() => switchToSlide(index)}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
