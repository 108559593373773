import React from 'react';

import { useTranslation } from 'react-i18next';

import TextInput from '../../../../../../Form/TextInput';
import Switch from '../../../../../../Switch';

import { ALPHABET } from '../../../../../../../constants/main';
import classes from './styles.module.scss';

export default function AssessmentQuestions({ questions }) {
  const { t } = useTranslation();

  return (
    <div className={classes.AssessmentQuestions}>
      {questions?.map?.((question, index) => (
        <div key={question.id} className={classes.question}>
          <header className={classes.header}>
            <h1>
              {t(
                'dashboardComponents.Console.JobManagement.Assessments.ViewAssessment.AssessmentQuestions.question'
              )}{' '}
              {index + 1}
            </h1>
            <Switch
              label={t(
                'dashboardComponents.Console.JobManagement.Assessments.ViewAssessment.AssessmentQuestions.multiChoice'
              )}
              isEnabled={question.allowsMultipleAnswers}
            />
          </header>
          <div className={classes.content}>
            <TextInput readonly value={question.name} />
            <div className={classes.answers}>
              {question.assessmentQuestionAnswers?.map(
                (answer, answerIndex) => {
                  return (
                    <div key={answer.id} className={classes.answer}>
                      <span className={classes.label}>
                        {ALPHABET[answerIndex].toUpperCase()}
                        <span>.</span>
                      </span>
                      <TextInput readonly value={answer.answer} />
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
