/* eslint-disable react/jsx-handler-names */
import React, { useState, useEffect, useContext, useMemo } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import useSwitchableRowData from '../../../../../hooks/useSwitchableRowData';
import { UiContext } from '../../../../../context/UiContext';
import AdminService from '../../../../../services/AdminService';
import MultiDropdown from '../../../../Form/MultiDropdown';
import TextInput from '../../../../Form/TextInput';
import SidePopup from '../../SidePopup';
import classes from './styles.module.scss';

export default function EditUser({
  isVisible,
  handleClose,
  users,
  selectedRows,
  refetch,
  noAnimation,
}) {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const { t } = useTranslation();

  const {
    showModal,
    showUnknownErrorModal,
    setIsFetching,
    isCreatingOrUpdating,
    setIsCreatingOrUpdating,
  } = useContext(UiContext);

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableRowData(users, selectedRows);

  const validationSchema = useMemo(
    () =>
      yup.object({
        firstName: yup
          .string()
          .trim()
          .required(t('common.requiredField'))
          .matches(
            /^[aA-zZ\s]+$/,
            t(
              'dashboardComponents.Console.UserManagement.EditUser.errorMessages.invalidCharacters'
            )
          ),
        lastName: yup
          .string()
          .trim()
          .required(t('common.requiredField'))
          .matches(
            /^[aA-zZ\s]+$/,
            t(
              'dashboardComponents.Console.UserManagement.EditUser.errorMessages.invalidCharacters'
            )
          ),
        email: yup
          .string()
          .trim()
          .email(
            t(
              'dashboardComponents.Console.UserManagement.EditUser.errorMessages.validEmail'
            )
          )
          .required(t('common.requiredField')),
        city: yup
          .array()
          .required(
            t(
              'dashboardComponents.Console.UserManagement.EditUser.errorMessages.cityRequired'
            )
          ),
        restaurant: yup
          .array()
          .required(
            t(
              'dashboardComponents.Console.UserManagement.EditUser.errorMessages.restaurantRequired'
            )
          ),
        role: yup
          .array()
          .required(
            t(
              'dashboardComponents.Console.UserManagement.EditUser.errorMessages.roleRequired'
            )
          ),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      email: users?.[currentDataIndex]?.userName,
      firstName: users?.[currentDataIndex]?.userProfile.firstName,
      lastName: users?.[currentDataIndex]?.userProfile.lastName,
      city: users?.[currentDataIndex]?.cities
        ? users?.[currentDataIndex]?.cities.map((city) => ({
            label: city.name,
            value: city.id,
          }))
        : '',
      restaurant: users?.[currentDataIndex]?.locations.length
        ? users?.[currentDataIndex]?.locations.map((location) => ({
            label: location.name,
            value: location.id,
          }))
        : '',
      role: users?.[currentDataIndex]?.roles.length
        ? users?.[currentDataIndex]?.roles.map?.((role) => ({
            label: role.name,
            value: role.id,
          }))
        : '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFetching(true);
        setIsCreatingOrUpdating(true);
        await AdminService.updateUser({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          roleIds: values.role.map((role) => role.value),
          cityIds: values.city.map((city) => city.value),
          locationIds: values.restaurant.map((rest) => rest.value),
          id: users[currentDataIndex].id,
        });
        await refetch();
        showModal({
          title: t(
            'dashboardComponents.Console.UserManagement.EditUser.successModal.title'
          ),
          text: t(
            'dashboardComponents.Console.UserManagement.EditUser.successModal.text'
          ),
          onCancel: handleClose,
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        setIsFetching(false);
        setIsCreatingOrUpdating(false);
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!isVisible) {
      formik.resetForm();
      setIsTriedToSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <SidePopup
      isVisible={isVisible}
      handleClose={handleClose}
      title={t('dashboardComponents.Console.UserManagement.EditUser.title')}
      hasNoAnimation={noAnimation}
      leftButtonLabel={t('common.reset')}
      onLeftButtonClick={formik.resetForm}
      rightButtonLabel={t('common.save')}
      onRightButtonClick={() => {
        setIsTriedToSubmit(true);
        formik.handleSubmit();
      }}
      isRightButtonDisabled={
        Object.values(formik.values).some((val) => !val) || isCreatingOrUpdating
      }
      switchNext={selectedRows.length > 1 && switchToNextDataElement}
      switchPrev={selectedRows.length > 1 && switchToPrevDataElement}
    >
      <div className={classes.EditUser}>
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.col}>
            <TextInput
              error={formik.errors.firstName}
              touched={isTriedToSubmit}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t(
                'dashboardComponents.Console.UserManagement.EditUser.labels.firstName'
              )}
              name="firstName"
              placeholder={t(
                'dashboardComponents.Console.UserManagement.EditUser.placeholders.firstName'
              )}
              height={50}
            />
            <TextInput
              error={formik.errors.email}
              touched={isTriedToSubmit}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t(
                'dashboardComponents.Console.UserManagement.EditUser.labels.email'
              )}
              name="email"
              placeholder={t(
                'dashboardComponents.Console.UserManagement.EditUser.placeholders.email'
              )}
              height={50}
            />
            <MultiDropdown
              error={formik.errors.city}
              touched={isTriedToSubmit}
              value={formik.values.city}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              label={t(
                'dashboardComponents.Console.UserManagement.EditUser.labels.city'
              )}
              name="city"
              placeholder={t(
                'dashboardComponents.Console.UserManagement.EditUser.placeholders.city'
              )}
              height={50}
              width="100%"
              fetchOptions={{
                dataName: 'citiesOptionsUser',
                getDataHandler: AdminService.getCities,
              }}
              searchInfo={t(
                'dashboardComponents.Console.UserManagement.EditUser.searchInfo.city'
              )}
              searchInfoWidth={173}
              setFieldTouched={formik.setFieldTouched}
            />
            <MultiDropdown
              error={formik.errors.role}
              touched={isTriedToSubmit}
              value={formik.values.role}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              label={t(
                'dashboardComponents.Console.UserManagement.EditUser.labels.role'
              )}
              name="role"
              placeholder={t(
                'dashboardComponents.Console.UserManagement.EditUser.placeholders.role'
              )}
              height={50}
              width="100%"
              fetchOptions={{
                dataName: 'rolesOptionsUser',
                getDataHandler: AdminService.getRoles,
              }}
              searchInfo={t(
                'dashboardComponents.Console.UserManagement.EditUser.searchInfo.role'
              )}
              searchInfoWidth={173}
              setFieldTouched={formik.setFieldTouched}
            />
          </div>
          <div className={classes.col}>
            <TextInput
              error={formik.errors.lastName}
              touched={isTriedToSubmit}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t(
                'dashboardComponents.Console.UserManagement.EditUser.labels.lastName'
              )}
              name="lastName"
              placeholder={t(
                'dashboardComponents.Console.UserManagement.EditUser.placeholders.lastName'
              )}
              height={50}
            />
            <MultiDropdown
              error={formik.errors.restaurant}
              touched={isTriedToSubmit}
              value={formik.values.restaurant}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              label={t(
                'dashboardComponents.Console.UserManagement.EditUser.labels.restaurant'
              )}
              name="restaurant"
              fetchOptions={{
                dataName: 'restaurantsOptionsUser',
                getDataHandler: AdminService.getRestaurants,
              }}
              height={50}
              width="100%"
              searchInfo={t(
                'dashboardComponents.Console.UserManagement.EditUser.searchInfo.restaurant'
              )}
              searchInfoWidth={133}
              setFieldTouched={formik.setFieldTouched}
            />
          </div>
        </form>
      </div>
    </SidePopup>
  );
}
