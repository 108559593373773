import React, { useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Tooltip from '../../Tooltip';
import TextInput from '../TextInput';
import classes from './styles.module.scss';

export default function NumberRange({
  valueTo,
  valueFrom,
  setFieldValue,
  nameFrom,
  nameTo,
  info,
  infoWidth,
  error,
  touched,
  warning,
}) {
  const [isToInputFocused, setIsToInputFocused] = useState(false);
  const [isFromInputFocused, setIsFromInputFocused] = useState(false);

  const { t } = useTranslation();

  const changeFieldValue = (fieldName) => {
    return (event) => {
      setFieldValue(fieldName, event.target.value.replace(/\D/g, ''));
    };
  };

  return (
    <div className={classes.NumberRange}>
      <div className={classes.info}>
        {info && (
          <Tooltip text={info} width={infoWidth}>
            <i>
              <svg
                width="3"
                height="10"
                viewBox="0 0 3 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.25H1.03125"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 8.75V4.75"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          </Tooltip>
        )}
        <span>(Optional)</span>
      </div>
      <div className={classes.fromContainer}>
        <TextInput
          label={t('formComponents.NumberRange.fromContainer.label')}
          height={45}
          placeholder={t(
            'formComponents.NumberRange.fromContainer.placeholder'
          )}
          name="from"
          value={isFromInputFocused ? valueFrom : `${valueFrom}.00`}
          onChange={changeFieldValue(nameFrom)}
          width="100%"
          onBlur={() => setIsFromInputFocused(false)}
          onFocus={() => setIsFromInputFocused(true)}
        />
      </div>
      <div className={classes.split}>
        {t('formComponents.NumberRange.split')}
      </div>
      <div className={classes.toContainer}>
        <TextInput
          value={isToInputFocused ? valueTo : `${valueTo}.00`}
          height={45}
          placeholder={t('formComponents.NumberRange.toContainer.placeholder')}
          onChange={changeFieldValue(nameTo)}
          width="100%"
          onBlur={() => setIsToInputFocused(false)}
          onFocus={() => setIsToInputFocused(true)}
        />
      </div>
      {touched && error && (
        <div
          className={classNames(classes.error, {
            [classes.warning]: error === warning,
          })}
        >
          {error}
        </div>
      )}
    </div>
  );
}
