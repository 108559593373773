import React from 'react';

import { useTranslation } from 'react-i18next';

import noMessages from '../../../../../assets/images/chat/no-messages.svg';
import classes from './styles.module.scss';

export default function NoMessages() {
  const { t } = useTranslation();

  return (
    <div className={classes.NoMessages}>
      <div className={classes.container}>
        <img src={noMessages} alt="No messages" />
        <p>
          {t('dashboardComponents.Chat.RightPanel.NoMessages.message1')} <br />{' '}
          {t('dashboardComponents.Chat.RightPanel.NoMessages.message2')}
        </p>
      </div>
    </div>
  );
}
