import React from 'react';

import classes from './styles.module.scss';

function getNumberSuffix(day) {
  if (day >= 11 && day <= 13) {
    return 'th';
  }

  const lastDigit = day % 10;

  switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}
function formatDate(date) {
  const options = { month: 'short', year: 'numeric' };
  const formattedDate = new Date(date).toLocaleDateString('en-US', options);
  const day = new Date(date).getDate();
  const suffix = getNumberSuffix(day);

  return `${day}${suffix} ${formattedDate}`;
}

export default function Announcement({ announcement, noLimit }) {
  return (
    <a
      className={classes.Announcement}
      href={announcement.link}
      target="_blank"
      rel="noreferrer"
      tabIndex={-1}
      draggable="false"
    >
      <span className={classes.title}>
        {' '}
        {noLimit
          ? announcement.title
          : `${announcement?.title.slice(0, 30)}${
              announcement?.title?.length > 30 ? '...' : ''
            }`}
      </span>
      <span className={classes.about}>
        {noLimit
          ? announcement.note
          : `${announcement?.note.slice(0, 100)}${
              announcement?.note?.length > 100 ? '...' : ''
            }`}
      </span>
      <span className={classes.date}>
        {formatDate(new Date(announcement?.createdAt))}
      </span>
    </a>
  );
}
