/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';
import SearchInput from './SearchInput';
import Assessment from '../../../../../../../../../modals/AssessmentModal/AvailableAssessments/Assessment';

export default function AssessmentDropdown({
  clear,
  dropdownRef,
  fields,
  setFields,
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const { t } = useTranslation();

  return (
    <div
      className={classes.AssessmentDropdown}
      onClick={(event) => event.stopPropagation()}
      ref={dropdownRef}
    >
      <SearchInput value={searchTerm} setValue={setSearchTerm} />
      <div className={classes.content}>
        <div className={classes.assessments}>
          {fields
            ?.filter(
              (assessment) =>
                assessment?.name
                  ?.toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                assessment.tests
                  ?.map((test) => test.name?.toLowerCase())
                  .some((test) => test?.includes(searchTerm?.toLowerCase()))
            )
            ?.map((assessment) => (
              <Assessment
                key={assessment.name}
                assessment={assessment}
                isSelected={assessment.tests?.some((test) => test.isSelected)}
                searchTerm={searchTerm}
                fields={fields}
                setFields={setFields}
                inDropdown
              />
            ))}
        </div>
        <span className={classes.clear} onClick={() => clear()}>
          {t('common.clear')}
        </span>
      </div>
    </div>
  );
}
