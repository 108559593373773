/* eslint-disable react/no-danger */
import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import Recipient from '../../Appreciation/Recipient';

export default function NewRegistration({ classes, feedPost }) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const user = {
    userProfile: {
      firstName: feedPost?.registeredUser?.firstName,
      lastName: feedPost?.registeredUser?.lastName,
      avatar: feedPost?.registeredUser?.avatar,
    },
  };

  return (
    <div className={classNames(classes.container, classes.newRegistration)}>
      <div className={classes.content}>
        <div className={classes.text}>
          {t(
            'dashboardComponents.Discover.Feed.Post.Announcement.NewRegistration.welcomeColleague'
          )}{' '}
          <span className={classes.bold}>
            {user?.userProfile?.firstName} {user?.userProfile?.lastName}
          </span>{' '}
          {t(
            'dashboardComponents.Discover.Feed.Post.Announcement.NewRegistration.toTheHiringPortal'
          )}
        </div>
        <div className={classes.recipientContainer}>
          <Recipient user={user} />
          {isTabletOrMobile && <div className={classes.background} />}
        </div>
      </div>
      {!isTabletOrMobile && <div className={classes.background} />}
    </div>
  );
}
