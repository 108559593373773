import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export default function Milestone({ classes, feedPost }) {
  const { t } = useTranslation();

  return (
    <div className={classNames(classes.container, classes.milestone)}>
      <div className={classes.content}>
        <h3 className={classes.title}>
          {t(
            'dashboardComponents.Discover.Feed.Post.Appreciation.Milestone.majorMilestone'
          )}
        </h3>
        <div className={classes.milestoneCount}>{feedPost?.milestoneCount}</div>
        <div className={classes.message}>
          {t(
            'dashboardComponents.Discover.Feed.Post.Appreciation.Milestone.exceptional'
          )}{' '}
          {feedPost?.milestoneCount > 1
            ? t(
                'dashboardComponents.Discover.Feed.Post.Appreciation.Milestone.candidates'
              )
            : t(
                'dashboardComponents.Discover.Feed.Post.Appreciation.Milestone.candidate'
              )}{' '}
          {t(
            'dashboardComponents.Discover.Feed.Post.Appreciation.Milestone.successfullyOnboardedTillDate'
          )}
        </div>
      </div>
      <div className={classes.background} />
    </div>
  );
}
