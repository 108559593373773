import { useEffect } from 'react';

export default function useMutationObserver(
  target,
  callback,
  options = {
    childList: true,
    characterData: true,
    attributes: true,
    subtree: true,
  }
) {
  useEffect(() => {
    const observer = new MutationObserver(callback);
    observer.observe(target.current, options);
    return () => observer.disconnect();
  }, [callback, options, target]);
}
