import React, { useRef } from 'react';

import classNames from 'classnames';

import JobCard from '../../Jobs/MyJobs/GridView/JobCard';
import NoFavorites from '../NoFavorites';

import { hasVerticalScrollbar } from '../../../../helpers/misc';
import classes from './styles.module.scss';

export default function Jobs({ favJobs, refetch, handleClose }) {
  const jobsRef = useRef();

  return (
    <div
      className={classNames(classes.Jobs, {
        [classes.scroll]: hasVerticalScrollbar(jobsRef.current),
        [classes.noFavorites]: favJobs?.length === 0,
      })}
      ref={jobsRef}
    >
      {favJobs?.length === 0 ? (
        <NoFavorites jobs />
      ) : (
        <>
          {favJobs
            ?.map((favJob) => (
              <JobCard
                favorites
                job={favJob}
                key={favJob.id}
                refetch={refetch}
                onClick={handleClose}
              />
            ))
            .reverse()}
        </>
      )}
    </div>
  );
}
