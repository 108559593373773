/* eslint-disable no-plusplus */
import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

function getEventDate(date) {
  const tempDate = new Date(date);

  return `${tempDate.toLocaleString('en-us', {
    weekday: 'short',
  })}, ${tempDate.toLocaleString('en-us', {
    month: 'short',
  })} ${tempDate.getDate()}, ${tempDate.getFullYear()}
  `;
}

export default function JoiningDetails({ event }) {
  const { t } = useTranslation();

  return (
    <div className={classes.InterviewDetails}>
      <div className={classes.form}>
        <span className={classes.title}>{t('common.when')}</span>
        <div className={classes.input}>{`${getEventDate(event.eventDate)} ${
          event?.time
        }`}</div>
      </div>
      <div className={`${classes.form} ${classes.location}`}>
        <span className={classes.title}>{t('common.location')}</span>
        <div className={classes.input}>{event?.event?.locationName}</div>
      </div>
    </div>
  );
}
