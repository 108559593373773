import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import classes from './styles.module.scss';
import EventDetails from '../../../Calendar/EventDetails';
import UserService from '../../../../../services/UserService';
import FavStatus from '../../../Header/Search/FavStatus';
import { getTimezoneTime } from '../../../../../helpers/timezones';

function getTime(hour) {
  const time = `${new Date(
    2023,
    0,
    1,
    hour?.slice(0, 2),
    hour?.slice(3)
  ).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })}`;

  return time;
}

export default function CalendarEventCard({
  favCalendarEvent,
  currentDate,
  refetch,
}) {
  const [isEventDetailsVisible, setIsEventDetailsVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const date = new Date(favCalendarEvent.eventDate).getDate();
  const month = new Date(favCalendarEvent.eventDate).getMonth();
  const monthStr = new Date(favCalendarEvent.eventDate).toLocaleString(
    'en-US',
    {
      month: 'short',
    }
  );
  const year = new Date(favCalendarEvent.eventDate).getFullYear();

  const todayDate = currentDate.getDate();
  const todayMonth = currentDate.getMonth();
  const todayYear = currentDate.getFullYear();

  const isToday =
    date === todayDate && month === todayMonth && year === todayYear;
  const isPast = new Date(favCalendarEvent.eventDate) < currentDate;
  const isFuture = new Date(favCalendarEvent.eventDate) > currentDate;

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  useEffect(() => {
    if (favCalendarEvent?.id === selectedEvent?.id) {
      setSelectedEvent(favCalendarEvent);
    }
  }, [favCalendarEvent, selectedEvent?.id]);

  return (
    <>
      <div
        className={classNames(classes.CalendarEventCard, {
          [classes.joining]: favCalendarEvent.type === 'Joining',
          [classes.onboarding]: favCalendarEvent.type === 'Onboarding',
          [classes.selected]: isEventDetailsVisible,
        })}
        onClick={() => {
          setSelectedEvent(favCalendarEvent);
          setIsEventDetailsVisible(true);
        }}
      >
        <div
          className={classNames(classes.iconBox, {
            [classes.isPast]: isPast,
            [classes.isFuture]: isFuture,
            [classes.isToday]: isToday,
          })}
        >
          <span className={classes.eventDay}>{monthStr.toUpperCase()}</span>
          <span className={classes.eventDate}>{date}</span>
        </div>
        <div className={classes.info}>
          <span className={classes.date}>
            {isToday ? 'Today' : `${date} ${monthStr} ${year}`}
          </span>
          <span className={classes.time}>
            {`${getTime(
              getTimezoneTime(
                favCalendarEvent?.startTime,
                user?.userProfile?.timezone
              )
            )} till ${getTime(
              getTimezoneTime(
                favCalendarEvent?.endTime,
                user?.userProfile?.timezone
              )
            )}`}
          </span>
          <span
            className={classes.title}
          >{`${favCalendarEvent?.type} for ${favCalendarEvent?.jobPost?.jobTitle}`}</span>
          <span
            className={classes.person}
          >{`${favCalendarEvent?.userFirstName} ${favCalendarEvent?.userLastName}`}</span>
        </div>
        <div className={classes.favIcon}>
          <FavStatus
            calendarEvent={favCalendarEvent}
            refetch={refetch}
            userId={user?.id}
          />
        </div>
      </div>
      {isEventDetailsVisible && (
        <EventDetails
          isEventDetailsVisible={isEventDetailsVisible}
          handleClose={() => {
            setIsEventDetailsVisible(false);
            setSelectedEvent(null);
          }}
          event={selectedEvent}
          // currentUser={user}
          refetch={refetch}
        />
      )}
    </>
  );
}
