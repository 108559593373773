import React, { useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import AdminService from '../../../../services/AdminService';
import MembersPopup from '../MembersPopup';
import classes from './styles.module.scss';

import { IMAGES_URL } from '../../../../constants/main';

function compareOnlineUsers(a, b) {
  return new Date(a.lastLoginAt) - new Date(b.lastLoginAt);
}

export default function UsersOnline() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const { data: onlineUsers } = useQuery({
    queryKey: ['onlineUsers'],
    queryFn: () => AdminService.getOnlineUsers(),
  });

  const containerRef = useRef(null);

  return (
    <div className={classes.UsersOnline}>
      <div className={classes.content}>
        <span className={classes.onlineBox}>Online</span>
        <div className={classes.flexContainer}>
          <div className={classes.container}>
            <div className={classes.totalNumberAtTime}>
              {onlineUsers?.length}
            </div>
          </div>
          <div className={classes.users}>
            {onlineUsers
              ?.sort(compareOnlineUsers)
              .slice(0, 5)
              ?.map((user) => (
                <div className={classes.user} key={user?.id}>
                  {user?.userProfile?.avatar ? (
                    <img
                      src={`${IMAGES_URL}/avatar/${user?.userProfile?.avatar}`}
                      alt="member"
                    />
                  ) : (
                    <div className={classes.noPhoto}>
                      {`${user?.userProfile?.firstName?.[0] || ''}${
                        user?.userProfile?.lastName?.[0] || ''
                      }`}
                    </div>
                  )}
                  <div className={classes.userName}>
                    {user?.userProfile?.fullName}
                  </div>
                </div>
              ))}
          </div>
        </div>
        {onlineUsers?.length > 5 && (
          <span
            className={classes.others}
            onClick={() => setIsPopupVisible(!isPopupVisible)}
            ref={containerRef}
          >{`+${onlineUsers?.length - 5}`}</span>
        )}
      </div>
      {isPopupVisible && (
        <MembersPopup
          members={onlineUsers
            ?.sort(compareOnlineUsers)
            .map((user) => user.userProfile)}
          online
          closePopup={() => setIsPopupVisible(false)}
          noSort
          containerRef={containerRef}
        />
      )}
    </div>
  );
}
