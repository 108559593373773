/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import RecruiterInterview from '../../../Calendar/EventDetails/Interview/RecruiterInterview';
import Event from './Event';

import classes from './styles.module.scss';
import UserService from '../../../../../services/UserService';
import { getTimezoneTime } from '../../../../../helpers/timezones';

export default function UpcomingEvents({ events, today }) {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [isScheduleInterviewVisible, setIsScheduleInterviewVisible] =
    useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  useEffect(() => {
    if (!events) {
      return;
    }

    const eventsSortedByDate = events.sort(
      (a, b) => new Date(a.eventDate) - new Date(b.eventDate)
    );

    const todayEvents = eventsSortedByDate.filter((event) =>
      moment(event.eventDate).isSame(today, 'day')
    );

    const futureEvents = eventsSortedByDate.filter((event) =>
      moment(event.eventDate).isAfter(today, 'day')
    );

    let eventsToShow = [];

    if (todayEvents.length) {
      eventsToShow =
        todayEvents.length > 1
          ? [todayEvents[0], todayEvents[1]]
          : [todayEvents[0]];

      if (eventsToShow.length < 2 && futureEvents.length)
        eventsToShow[1] = futureEvents[0];
    } else {
      eventsToShow = futureEvents.slice(0, 2);
    }

    setUpcomingEvents(eventsToShow);
  }, [events, today]);

  return (
    <div className={classes.UpcomingEvents}>
      <div className={classes.header}>
        <h3>
          {t(
            'dashboardComponents.Dashboard.RightPanel.UpcomingEvents.header.title'
          )}
        </h3>
        <button
          type="button"
          className={classes.addButton}
          onClick={() => setIsScheduleInterviewVisible(true)}
        >
          {t('common.add')}
        </button>
      </div>
      <div className={classes.events}>
        {!upcomingEvents?.length ? (
          <div className={classes.noData}>
            <p>
              {t(
                'dashboardComponents.Dashboard.RightPanel.UpcomingEvents.noDataMessage'
              )}
            </p>
          </div>
        ) : (
          <>
            {upcomingEvents.map((event) => {
              return (
                <Event
                  today={today}
                  key={event.id + event.type}
                  eventDate={event.eventDate}
                  startTime={getTimezoneTime(
                    event.startTime,
                    user?.userProfile?.timezone
                  )}
                  endTime={getTimezoneTime(
                    event.endTime,
                    user?.userProfile?.timezone
                  )}
                  type={event.type}
                  jobTitle={event.jobPost?.jobTitle}
                  userName={`${event.userFirstName} ${event.userLastName}`}
                  event={event}
                  user={user}
                />
              );
            })}
            <button
              type="button"
              onClick={() => {
                navigate('/tools/calendar', {
                  state: { calendarActiveType: 'Month' },
                });
              }}
              className={classes.seeAll}
            >
              {t(
                'dashboardComponents.Dashboard.RightPanel.UpcomingEvents.seeAllButtonLabel'
              )}
            </button>
          </>
        )}
      </div>
      <RecruiterInterview
        isScheduleInterviewVisible={isScheduleInterviewVisible}
        setIsScheduleInterviewVisible={setIsScheduleInterviewVisible}
      />
    </div>
  );
}
