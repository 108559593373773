import React, { useState, useContext, useMemo } from 'react';

import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Reactions from '../Reactions';
import MembersPopup from '../../../MembersPopup';
import ReactionPicker from '../ReactionPicker';

import { UiContext } from '../../../../../../context/UiContext';
import classes from './styles.module.scss';
import reactionsIcon from '../../../../../../assets/images/dashboard/discover/reactions.png';
import commentsIcon from '../../../../../../assets/images/dashboard/discover/comments.svg';
import viewsIcon from '../../../../../../assets/images/dashboard/discover/views.svg';
import FeedService from '../../../../../../services/FeedService';
import UserService from '../../../../../../services/UserService';

export default function PostStats({ modal, collapsed, feedPost, refetch }) {
  const [isViewsPopupVisible, setIsViewsPopupVisible] = useState(false);
  const [isCommentsPopupVisible, setIsCommentsPopupVisible] = useState(false);
  const [isReactionAdded, setIsReactionAdded] = useState(false);

  const { setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  function getLastTwoUser(reactions) {
    const users = reactions.slice(-2).map((reaction) => reaction.author);

    let result = users
      // eslint-disable-next-line no-shadow
      .map((user) => `${user?.firstName} ${user?.lastName}`)
      .join(', ');

    const moreThanTwoUser = reactions.length > 2;

    if (moreThanTwoUser) {
      result += ` ${t(
        'dashboardComponents.Discover.Feed.Post.PostStats.and more',
        {
          count: reactions.length - 2,
        }
      )}`;
    }

    return result;
  }

  const deleteReaction = async (reactionId) => {
    try {
      setIsFetching(true);

      await FeedService.deleteReaction({
        id: feedPost.id,
        reactionId,
      });
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const addReaction = async (type) => {
    try {
      setIsReactionAdded(true);
      setIsFetching(true);

      const userReaction = feedPost.reactions.find(
        (reaction) => reaction.author.id === user.id
      );

      if (userReaction) {
        await deleteReaction(userReaction.id);
      }

      await FeedService.addReaction({
        id: feedPost.id,
        type,
      });
      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
      setIsReactionAdded(false);
    }
  };

  const uniqueCommentsMembers = useMemo(
    () =>
      Array.from(
        new Set(
          feedPost.comments.map((comment) => comment.author).map(JSON.stringify)
        )
      ).map(JSON.parse),
    [feedPost]
  );

  return (
    <div
      className={classNames(classes.PostStats, {
        [classes.insideModal]: modal,
        [classes.collapsed]: collapsed,
      })}
    >
      <div className={classes.container}>
        <ReactionPicker addReaction={addReaction} disabled={isReactionAdded}>
          <div className={classes.stat}>
            <img
              className={classes.statImg}
              src={reactionsIcon}
              alt="Reactions"
              style={{
                position: 'relative',
                top: -2,
              }}
            />
            <span>{feedPost.reactions.length}</span>
          </div>
        </ReactionPicker>
        <div
          className={classes.stat}
          onClick={() => setIsCommentsPopupVisible(true)}
        >
          <img src={commentsIcon} className={classes.statImg} alt="Comments" />
          <span>{feedPost.comments.length}</span>
          {isCommentsPopupVisible && (
            <MembersPopup
              closePopup={() => setIsCommentsPopupVisible(false)}
              comments
              members={uniqueCommentsMembers}
              style={{
                left: '200%',
              }}
            />
          )}
        </div>
        <div
          className={classes.stat}
          onClick={() => setIsViewsPopupVisible(true)}
        >
          <img src={viewsIcon} className={classes.statImg} alt="Views" />
          <span>{feedPost.views.length}</span>
          {isViewsPopupVisible && (
            <MembersPopup
              closePopup={() => setIsViewsPopupVisible(false)}
              views
              members={feedPost.views.map((view) => view.author)}
            />
          )}
        </div>
        <div className={classes.reactions}>
          <Reactions data={feedPost} isLeft>
            <span className={classes.child}>
              {getLastTwoUser(feedPost?.reactions)}
            </span>
          </Reactions>
        </div>
      </div>
    </div>
  );
}
