import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function Notification({ isVisible, text, undo, handleClose }) {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(classes.Notification, {
        [classes.visible]: isVisible,
      })}
    >
      {text}
      {undo && (
        <button
          className={classes.undoButton}
          type="button"
          onClick={() => {
            undo();
            handleClose();
          }}
        >
          {t('common.undo')}
        </button>
      )}
    </div>
  );
}
