import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import Textarea from '../../components/Form/Textarea';
import Button from '../../components/Button';

import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';
import AdminService from '../../services/AdminService';

export default function AddCandidateNoteModal({
  show,
  handleClose,
  currentCandidate,
  refetch,
}) {
  const [note, setNote] = useState('');
  const [isEditingEnabled, setIsEditingEnabled] = useState(false);

  const { setIsFetching } = useContext(UiContext);

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const enableEditing = () => {
    setIsEditingEnabled(true);
  };

  const resetNote = () => {
    setNote(currentCandidate?.note);
  };

  const addNote = async () => {
    try {
      setIsFetching(true);
      await AdminService.addNoteToCandidate({ id: currentCandidate.id, note });
      await queryClient.refetchQueries({ queryKey: ['candidates'] });
      if (refetch) {
        await refetch();
      }
      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const deleteNote = async () => {
    try {
      setIsFetching(true);
      await AdminService.addNoteToCandidate({
        id: currentCandidate.id,
        note: '',
      });
      await queryClient.refetchQueries({ queryKey: ['candidates'] });
      if (refetch) {
        await refetch();
      }
      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    setNote(currentCandidate?.note);

    return () => {
      setNote('');
      setIsEditingEnabled(false);
    };
  }, [show, currentCandidate]);

  const isEdit = currentCandidate?.note;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.AddCandidateNoteModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <div className={classes.textareaContainer}>
          <Textarea
            readonly={isEdit && !isEditingEnabled}
            note={t('modals.AddCandidateNoteModal.optionalNote')}
            width={455}
            height={145}
            placeholder={t('modals.AddCandidateNoteModal.enterNotesHere')}
            maxLength={250}
            value={note}
            onChange={(event) => setNote(event.target.value)}
          />
        </div>
        {isEdit ? (
          <div className={classes.buttons}>
            {isEditingEnabled ? (
              <>
                <Button
                  width={150}
                  height={50}
                  onClick={resetNote}
                  variant="delete"
                >
                  {t('modals.AddCandidateNoteModal.reset')}
                </Button>
                <Button
                  width={150}
                  variant="secondary"
                  height={50}
                  disabled={!note}
                  onClick={addNote}
                >
                  {t('modals.AddCandidateNoteModal.save')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  width={150}
                  height={50}
                  onClick={deleteNote}
                  variant="delete"
                >
                  {t('modals.AddCandidateNoteModal.delete')}
                </Button>
                <Button
                  width={150}
                  variant="secondary"
                  height={50}
                  disabled={!note}
                  onClick={enableEditing}
                >
                  {t('modals.AddCandidateNoteModal.edit')}
                </Button>
              </>
            )}
          </div>
        ) : (
          <div className={classes.buttons}>
            <Button
              width={150}
              height={50}
              onClick={handleClose}
              variant="secondary"
            >
              {t('modals.AddCandidateNoteModal.cancel')}
            </Button>
            <Button width={150} height={50} disabled={!note} onClick={addNote}>
              {t('modals.AddCandidateNoteModal.save')}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}
