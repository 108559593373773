import React from 'react';

import { useTranslation } from 'react-i18next';

import AdminService from '../../../../../services/AdminService';
import TableView from '../../TableView';

export default function Districts({ setItemCount }) {
  const { t } = useTranslation();

  const columns = [
    {
      name: t(
        'dashboardComponents.Console.RestaurantManagement.Districts.columns.District Name'
      ),
      field: 'name',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.RestaurantManagement.Districts.columns.City'
      ),
      field: 'city.name',
      sortable: true,
    },
    {
      name: t('common.createdOn'),
      field: 'createdAt',
      sortable: true,
      type: 'date',
    },
  ];

  return (
    <TableView
      setItemCount={setItemCount}
      dataName="District"
      getDataHandler={AdminService.getDistricts}
      deleteDataHandler={AdminService.deleteDistrict}
      createDataHandler={AdminService.createDistrict}
      updateDataHandler={AdminService.updateDistrict}
      viewLeftColumnFields={[
        {
          label: t(
            'dashboardComponents.Console.RestaurantManagement.Districts.viewLeftColumnFields.District Name'
          ),
          field: 'name',
        },
        {
          label: t(
            'dashboardComponents.Console.RestaurantManagement.Districts.viewLeftColumnFields.City Name'
          ),
          field: 'city.name',
        },
      ]}
      viewRightColumnFields={[]}
      formLeftColumnFields={[
        {
          label: t(
            'dashboardComponents.Console.RestaurantManagement.Districts.formLeftColumnFields.District Name'
          ),
          field: 'name',
          validation: ['string', 'required'],
          placeholder: t(
            'dashboardComponents.Console.RestaurantManagement.Districts.formLeftColumnFields.District name here'
          ),
        },
        {
          label: t(
            'dashboardComponents.Console.RestaurantManagement.Districts.formLeftColumnFields.City'
          ),
          field: 'locationCityId',
          dataFieldName: 'city',
          submitField: 'locationCityId',
          validation: ['object', 'required'],
          placeholder: t(
            'dashboardComponents.Console.RestaurantManagement.Districts.formLeftColumnFields.Pick a City from the list'
          ),
          type: 'dropdown',
          fetchOptions: {
            dataName: 'cityOptionsDistricts',
            getDataHandler: AdminService.getCities,
          },
        },
      ]}
      formRightColumnFields={[]}
      columns={columns}
    />
  );
}
