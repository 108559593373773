import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import Textarea from '../../components/Form/Textarea';
import SelectedUsers from '../../components/Dashboard/SelectedUsers';
import MultiDropdown from '../../components/Form/MultiDropdown';
import Button from '../../components/Button';

import { UiContext } from '../../context/UiContext';
import AdminService from '../../services/AdminService';
import classes from './styles.module.scss';

export default function ShareJobModal({ show, handleClose }) {
  const [users, setUsers] = useState('');

  const { showNotification } = useContext(UiContext);

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setUsers('');
    };
  }, [show]);

  const shareJob = () => {
    handleClose();
    showNotification({ text: t('modals.ShareJobModal.jobSuccessfullyShared') });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.ShareJobModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header>
          <h1>{t('modals.ShareJobModal.share')}</h1>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <div className={classes.container}>
          <div className={classes.col}>
            <div className={classes.share}>
              <div className={classes.selectContainer}>
                <MultiDropdown
                  note={t('modals.ShareJobModal.selectUpTo12')}
                  width={375}
                  label={t('modals.ShareJobModal.shareWithTeamViaEmail')}
                  fetchOptions={{
                    dataName: 'userOptionPostJobSuccess',
                    getDataHandler: AdminService.getUsers,
                    labelName: 'userName',
                    isUserList: true,
                  }}
                  value={users}
                  setFieldValue={(_name, option) => setUsers(option)}
                  placeholder={t('modals.ShareJobModal.selectName')}
                  maxLength={12}
                />
              </div>
              <SelectedUsers invitedUsers={users} setInvitedUsers={setUsers} />
            </div>
          </div>
          <div className={classes.col}>
            <Textarea
              label={t('modals.ShareJobModal.emailMessage')}
              note={t('common.optional')}
              height={154}
              placeholder={t('modals.ShareJobModal.enterNotes')}
            />
          </div>
        </div>
        <footer>
          <Button onClick={shareJob} width={301} height={52} disabled={!users}>
            {t('modals.ShareJobModal.shareButton')}
          </Button>
        </footer>
      </div>
    </Modal>
  );
}
