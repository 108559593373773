import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import NotFound from '../../NotFound';
import JobDetails from './MyJobs/JobDetails';
import MyJobs from './MyJobs';
import PostAJob from './PostAJob';
import classes from './styles.module.scss';

export default function Jobs() {
  const [isMyJobsTableExpanded, setIsMyJobsTableExpanded] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/tools/jobs') {
      navigate('/tools/jobs/all-jobs', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let currentView;

  switch (true) {
    case location.pathname.includes('jobs/post-job'):
      currentView = <PostAJob />;
      break;
    case location.pathname.includes('jobs/my-jobs/'):
      currentView = <JobDetails />;
      break;
    case location.pathname.includes('jobs/my-jobs'):
      currentView = (
        <MyJobs
          isViewExpanded={isMyJobsTableExpanded}
          setIsViewExpanded={setIsMyJobsTableExpanded}
          my
        />
      );
      break;
    case location.pathname.includes('jobs/all-jobs'):
      currentView = (
        <MyJobs
          isViewExpanded={isMyJobsTableExpanded}
          setIsViewExpanded={setIsMyJobsTableExpanded}
        />
      );
      break;

    default:
      currentView = <NotFound />;
  }

  return (
    <div
      className={classNames(classes.Jobs, {
        [classes.nominheight]: isMyJobsTableExpanded,
        [classes.postJob]: location.pathname.includes('jobs/post-job'),
      })}
    >
      {currentView}
    </div>
  );
}
