import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function CandidatesListHeader({
  totalCount,
  showAddCandidatePopup,
}) {
  const { t } = useTranslation();

  return (
    <header className={classes.CandidatesListHeader}>
      <div>
        <h1>
          {t(
            'dashboardComponents.Candidates.CandidatesList.CandidatesListHeader.candidates'
          )}
        </h1>
        <div className={classes.totalCount}>
          <h6 className={classes.heading}>
            {' '}
            {t(
              'dashboardComponents.Candidates.CandidatesList.CandidatesListHeader.totalCandidates'
            )}
          </h6>
          <span>{totalCount || 0}</span>
        </div>
      </div>
      <button
        type="button"
        className={classes.addCandidateButton}
        onClick={() => showAddCandidatePopup(false)}
      >
        Add Candidate
      </button>
    </header>
  );
}
