import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import PageInput from './PageInput';

import classes from './styles.module.scss';
import chevronLeft from '../../../../../assets/images/dashboard/chevron-left-green.svg';
import chevronLeftDisabled from '../../../../../assets/images/dashboard/chevron-left-gray.svg';

export default function Pagination({
  currentPage = 1,
  totalPages = 1,
  setCurrentPage,
}) {
  const [isEditingCurrentPage, setIsEditingCurrentPage] = useState(false);
  const [pageSelectorValue, setPageSelectorValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  const timer = useRef();
  const inputRef = useRef();

  useEffect(() => {
    setPageSelectorValue(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (pageSelectorValue < 1 || pageSelectorValue > totalPages) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }
  }, [pageSelectorValue, totalPages]);

  // Update current page after 1 second of inactivity
  useEffect(() => {
    clearTimeout(timer.current);
    if (!isInvalid && pageSelectorValue !== currentPage) {
      timer.current = setTimeout(() => {
        setCurrentPage(pageSelectorValue);
      }, 1000);
    }
  }, [
    currentPage,
    isEditingCurrentPage,
    isInvalid,
    pageSelectorValue,
    setCurrentPage,
  ]);

  const switchToPageOnEnter = (event) => {
    if (event.key === 'Enter') {
      if (!isInvalid) {
        setCurrentPage(pageSelectorValue);
        setIsEditingCurrentPage(false);
        clearTimeout(timer.current);
      }
    }
  };

  const resetSelectorValueOnBlur = () => {
    setIsEditingCurrentPage(false);

    if (isInvalid) {
      setPageSelectorValue(currentPage);
    }
  };

  const switchToPrevPage = () => {
    if (currentPage > 1) {
      clearTimeout(timer.current);
      setCurrentPage(+currentPage - 1);
    }
  };

  const switchToNextPage = () => {
    if (currentPage < totalPages) {
      clearTimeout(timer.current);
      setCurrentPage(+currentPage + 1);
    }
  };

  const isPrevSwitchDisabled = currentPage === 1;
  const isNextSwitchDisabled = currentPage === totalPages;

  return (
    <div className={classes.Pagination}>
      <button
        onClick={switchToPrevPage}
        className={classes.switch}
        type="button"
        disabled={isPrevSwitchDisabled}
      >
        <img className={classes.default} src={chevronLeft} alt="Previous" />
        <img
          className={classes.disabled}
          src={chevronLeftDisabled}
          alt="Previous"
        />
      </button>
      {isEditingCurrentPage ? (
        <PageInput
          resetSelectorValueOnBlur={resetSelectorValueOnBlur}
          pageSelectorValue={pageSelectorValue}
          setPageSelectorValue={setPageSelectorValue}
          isInvalid={isInvalid}
          classes={classes}
          switchToPageOnEnter={switchToPageOnEnter}
        />
      ) : (
        <div
          className={classNames(classes.currentPage, classes.page)}
          onClick={() => {
            setIsEditingCurrentPage(true);
            inputRef.current.focus();
          }}
        >
          {pageSelectorValue}
        </div>
      )}
      <span>Of</span>
      <div className={classNames(classes.totalPages, classes.page)}>
        {totalPages}
      </div>
      <button
        onClick={switchToNextPage}
        className={classes.switch}
        type="button"
        disabled={isNextSwitchDisabled}
      >
        <img className={classes.default} src={chevronLeft} alt="Next" />
        <img
          className={classes.disabled}
          src={chevronLeftDisabled}
          alt="Next"
        />
      </button>
    </div>
  );
}
