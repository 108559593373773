import React, { useRef, useContext } from 'react';

import FontName from 'fontname';
import { useTranslation } from 'react-i18next';

import AdminService from '../../../../../../../../services/AdminService';
import { UiContext } from '../../../../../../../../context/UiContext';
import classes from './styles.module.scss';
import addIcon from '../../../../../../../../assets/images/dashboard/brand-kit/add.svg';

export default function FontUploader({
  tenantSettings,
  refetchTenantSettings,
}) {
  const fileInputRef = useRef();

  const { showNotification, setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const uploadFont = async (event) => {
    try {
      setIsFetching(true);

      // Get font details from file
      const fontFile = event.target.files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const { fontFamily, postScriptName } = FontName.parse(
          e.target.result
        )[0];

        // Upload font file to the server
        const { uri } = await AdminService.uploadFile(event.target.files[0]);

        await AdminService.updateTenantSettings({
          ...tenantSettings,
          customFonts: [
            ...tenantSettings.customFonts,
            {
              familyName: fontFamily,
              postScriptName,
              url: uri,
            },
          ],
        });

        await refetchTenantSettings();

        setIsFetching(false);
        showNotification({
          text: t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Font.FontUploader.fontUploadedSuccessfully'
          ),
        });
      };
      reader.readAsArrayBuffer(fontFile);
    } catch (error) {
      showNotification({
        text: error.response.data.message,
        type: 'error',
      });
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div
      className={classes.FontUploader}
      onClick={() => fileInputRef.current.click()}
      role="button"
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          fileInputRef.current.click();
        }
      }}
    >
      <h4>
        {t(
          'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Font.FontUploader.uploadFonts'
        )}
      </h4>
      <img src={addIcon} alt="Add" />
      <input
        onChange={uploadFont}
        accept=".ttf, .otf"
        ref={fileInputRef}
        type="file"
        style={{
          display: 'none',
        }}
      />
    </div>
  );
}
