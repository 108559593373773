/* eslint-disable no-plusplus */
import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

function getEventDate(date) {
  const tempDate = new Date(date);

  return `${tempDate.toLocaleString('en-us', {
    weekday: 'short',
  })}, ${tempDate.toLocaleString('en-us', {
    month: 'short',
  })} ${tempDate.getDate()}, ${tempDate.getFullYear()}
  `;
}

function formatStr(text) {
  let result = text[0];

  for (let i = 1; i < text.length; i++) {
    // eslint-disable-next-line no-restricted-globals
    if (text[i] === text[i].toUpperCase()) {
      result += ' ';
    }

    result += text[i];
  }

  return result;
}

export default function OnboardingDetails({ event }) {
  const { t } = useTranslation();

  return (
    <div className={classes.InterviewDetails}>
      {event?.event?.type === 'InPerson' && (
        <div className={classes.form}>
          <span className={classes.title}>
            {t(
              'dashboardComponents.Calendar.EventDetails.Onboarding.OnboardingDetails.When'
            )}
          </span>
          <div className={classes.input}>{`${getEventDate(event.eventDate)} ${
            event?.time
          }`}</div>
        </div>
      )}
      {event?.event?.type === 'Remote' && (
        <div className={classes.form}>
          <span className={classes.title}>
            {t(
              'dashboardComponents.Calendar.EventDetails.Onboarding.OnboardingDetails.When'
            )}
          </span>
          <div className={classes.input}>{`${getEventDate(
            event?.eventDate
          )}`}</div>
        </div>
      )}
      <div className={classes.form}>
        <span className={classes.title}>
          {t(
            'dashboardComponents.Calendar.EventDetails.Onboarding.OnboardingDetails.Onboarding Mode'
          )}
        </span>
        <div className={classes.input}>{formatStr(event?.event?.type)}</div>
      </div>
      {event?.event?.type === 'InPerson' && (
        <div className={classes.form}>
          <span className={classes.title}>
            {t(
              'dashboardComponents.Calendar.EventDetails.Onboarding.OnboardingDetails.Location'
            )}
          </span>
          <div className={classes.input}>{event?.event?.locationName}</div>
        </div>
      )}
      {event?.event?.type === 'Remote' && (
        <div className={classes.form}>
          <span className={classes.title}>
            {t(
              'dashboardComponents.Calendar.EventDetails.Onboarding.OnboardingDetails.Complete by'
            )}
          </span>
          <div className={classes.input}>{`${getEventDate(
            event?.event?.completeDate
          )}`}</div>
        </div>
      )}
    </div>
  );
}
