import React from 'react';

import Applications from '../../../../../Candidate/Applications';
import SubmitToJob from './SubmitToJob';

import classes from './styles.module.scss';

export default function CandidateApplications({
  currentCandidate,
  isPossibleToEdit,
  formik,
  isReadOnly,
  touched,
}) {
  return (
    <div className={classes.CandidateApplications}>
      {isPossibleToEdit || !currentCandidate ? (
        <SubmitToJob
          formik={formik}
          isReadOnly={isReadOnly}
          touched={touched}
        />
      ) : (
        <Applications
          jobApplications={currentCandidate?.jobApplications || []}
          style={{
            marginRight: 0,
            paddingRight: 0,
            height: 'auto',
          }}
        />
      )}
    </div>
  );
}
