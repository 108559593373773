import React from 'react';

// import axios from 'axios';

import fileIcons from '../../../../../../../constants/fileIcons';
import classes from './styles.module.scss';

export default function Attachment({ attachment }) {
  const fileExtension = attachment.fileName.split('.').pop().toLowerCase();

  /*   const getFileInfo = useCallback(async () => {
    try {
      const response = await axios.head(attachment.url);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [attachment.url]);

  useEffect(() => {
    getFileInfo();
  }, [getFileInfo]); */

  return (
    <li className={classes.Attachment}>
      <img src={fileIcons[fileExtension]} alt="File" />
      <h4>{attachment.displayName}</h4>
      <div className={classes.links}>
        <span>{(attachment.fileSize / 1024).toFixed(2)}kb</span>
        <a href={attachment.url} target="_blank" rel="noreferrer">
          View
        </a>
        <a href={attachment.url} target="_blank" rel="noreferrer">
          Download
        </a>
      </div>
    </li>
  );
}
