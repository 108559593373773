import React from 'react';

import Tooltip from '../../Tooltip';

import classes from './styles.module.scss';

export default function ChipsReadonly({ label, info, infoWidth, values }) {
  return (
    <div className={classes.ChipsReadonly}>
      <header className={classes.header}>
        <span className={classes.label}>{label}</span>
        {info && (
          <Tooltip text={info} direction="top-right" width={infoWidth}>
            <i>
              <svg
                width="3"
                height="10"
                viewBox="0 0 3 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.25H1.03125"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 8.75V4.75"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          </Tooltip>
        )}
      </header>
      <div className={classes.field}>
        {values?.map?.((value) => (
          <div className={classes.chip} key={value}>
            {value.label}
          </div>
        ))}
      </div>
    </div>
  );
}
