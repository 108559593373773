import React from 'react';

import Video from './Video';
import Image from './Image';

export default function Media({
  mediaLeft,
  allMedia,
  index,
  feedPost,
  isRead,
}) {
  return allMedia[index]?.type === 'video' ? (
    <Video
      index={index}
      allMedia={allMedia}
      feedPost={feedPost}
      isRead={isRead}
    />
  ) : (
    <Image
      index={index}
      allMedia={allMedia}
      mediaLeft={mediaLeft}
      feedPost={feedPost}
      isRead={isRead}
    />
  );
}
