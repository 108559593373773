import React from 'react';
import CalendarForm from '../CalendarForm';
import EventGroups from './EventGroups';
import classes from './styles.module.scss';

export default function SideCalendar({
  currentDate,
  setCurrentDate,
  setActiveType,
  dateToday,
  events,
  refetch,
  user,
  activeType,
  timezone,
  setTimezone,
}) {
  return (
    <div className={classes.SideCalendar}>
      <CalendarForm
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        setActiveType={setActiveType}
        dateToday={dateToday}
        events={events.events}
      />
      <EventGroups
        events={events}
        dateToday={dateToday}
        refetch={refetch}
        user={user}
        activeType={activeType}
        setActiveType={setActiveType}
        timezone={timezone}
        setTimezone={setTimezone}
      />
    </div>
  );
}
