import React, { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Notification from './Notification';

import { UiContext } from '../../../../../context/UiContext';
import NotificationsService from '../../../../../services/NotificationsService';
import classes from './styles.module.scss';

export default function LatestNotifications() {
  const { t } = useTranslation();

  const { data: notifications } = useQuery({
    queryKey: ['notifications'],
    queryFn: NotificationsService.getNotifications,
  });

  const { setTopMenuActiveButton } = useContext(UiContext);

  const showNotificationsPopup = () => {
    setTopMenuActiveButton('Notifications');
  };

  return (
    <div className={classes.LatestNotifications}>
      <div className={classes.header}>
        <h3>
          {t(
            'dashboardComponents.Dashboard.RightPanel.LatestNotifications.header.title'
          )}
        </h3>
        <button type="button" onClick={showNotificationsPopup}>
          {t(
            'dashboardComponents.Dashboard.RightPanel.LatestNotifications.header.seeAllButtonLabel'
          )}
        </button>
      </div>
      {!notifications || !notifications?.length ? (
        <div className={classes.noData}>
          <p>
            {t(
              'dashboardComponents.Dashboard.RightPanel.LatestNotifications.noDataMessage'
            )}
          </p>
        </div>
      ) : (
        <div className={classes.notifications}>
          {notifications.map((notification) => (
            <Notification key={notification.id} notification={notification} />
          ))}
        </div>
      )}
    </div>
  );
}
