import React from 'react';

import { useTranslation } from 'react-i18next';

import Addressee from '../../TextEditor/Addressees/Addressee';
import UsersPopup from '../../TextEditor/UsersPopup';

import addIcon from '../../../../../../assets/images/dashboard/discover/textEditor/add.svg';
import classes from './styles.module.scss';

export default function Recipients({
  recipients,
  setRecipients,
  setAddressees,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.Recipients}>
      <h4>
        {t(
          'dashboardComponents.Discover.AddContent.AppreciationEditor.Recipients.recipient'
        )}
      </h4>
      <div className={classes.container}>
        <UsersPopup
          addressees={recipients}
          setAddressees={setRecipients}
          disableAll
          onUserAdd={(userName, userId) =>
            setAddressees((prevState) => {
              if (prevState.find((user) => user.value === userId)) {
                return prevState;
              }

              return [...prevState, { label: userName, value: userId }];
            })
          }
        >
          <button type="button" className={classes.addMoreButton}>
            <img src={addIcon} alt="Add more" />{' '}
            {t(
              'dashboardComponents.Discover.AddContent.AppreciationEditor.Recipients.addMore'
            )}
          </button>
        </UsersPopup>
        {recipients.map((recipient) => (
          <Addressee
            key={recipient.value}
            addressee={recipient}
            setAddressees={setRecipients}
          />
        ))}
      </div>
    </div>
  );
}
