/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-children-prop */
import React, { useContext, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import { UiContext } from '../../context/UiContext';
import 'bootstrap/dist/css/bootstrap.min.css';

import AdminService from '../../services/AdminService';
import Button from '../../components/Button';
import AvailableAssessments from './AvailableAssessments';
import SelectedAssessments from './SelectedAssessments';

import classes from './styles.module.scss';
import { hasAuthData } from '../../helpers/authStorage';

function areObjectsEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1?.length !== keys2?.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

function arraysOfObjectsAreSimilar(arr1, arr2) {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  for (let i = 0; i < arr1?.length; i++) {
    if (!areObjectsEqual(arr1[i], arr2[i])) {
      return false;
    }
  }

  return true;
}

function mapAssessments(assessments) {
  return assessments.map((assessment) => ({
    assessmentProviderKey: assessment.assessmentProviderKey,
    assessmentId: assessment.assessmentId,
    limit: assessment.limit,
    mentalThreshold: assessment.mentalThreshold,
    sortOrder: `${assessment.sortOrder}`,
    threshold: `${assessment.threshold}`,
    englishThreshold: `${assessment.englishThreshold}`,
  }));
}

export default function AssessmentModal({
  show,
  candidate,
  retake,
  refetch,
  handleClose,
}) {
  const [assignedAssessments, setAssignedAssessments] = useState([]);
  const [fields, setFields] = useState([]);

  const { t } = useTranslation();

  const isAuthorized = hasAuthData();

  const { data: flowsProfiles } = useQuery({
    queryKey: ['flowsProfiles'],
    queryFn: () => AdminService.getFlowsProfiles(),
    keepPreviousData: true,
    enabled: isAuthorized,
  });

  const { data: assessments } = useQuery({
    queryKey: ['assessments'],
    queryFn: () => AdminService.getAssessment({ term: '', pageSize: 9999 }),
    keepPreviousData: true,
    enabled: isAuthorized,
    pageSize: 9999,
  });

  const { data: tenantAssessmentProviders } = useQuery({
    queryKey: ['tenantAssessmentProviders'],
    queryFn: () =>
      AdminService.getTenantAssessmentProviders({
        term: '',
        pageSize: 9999,
      }),
    keepPreviousData: true,
    enabled: isAuthorized,
  });

  const { showModal, setIsFetching } = useContext(UiContext);

  const resetFields = () => {
    setAssignedAssessments([]);
  };

  useEffect(() => {
    const flowQ = tenantAssessmentProviders?.data?.find(
      (provider) => provider?.assessmentProvider.key === 'flowq'
    );

    const zaphire = tenantAssessmentProviders?.data?.find(
      (provider) => provider?.assessmentProvider.key === 'zaphire'
    );

    setFields([
      {
        name: 'FlowQ',
        tests: flowsProfiles?.map((flow) => {
          const hasEnglishThreshold = flow?.content?.some((test) =>
            test.name.includes('English')
          );
          const hasMentalThreshold = flow?.content?.some((test) =>
            test.name.includes('Mental')
          );

          return {
            assessment: 'FlowQ',
            isSelected: false,
            isAssigned: false,
            assessmentId: flow.id,
            name: flow?.name,
            threshold:
              !hasMentalThreshold && !hasEnglishThreshold ? '80' : null,
            englishThreshold: hasEnglishThreshold ? '80' : null,
            mentalThreshold: hasMentalThreshold ? '100' : null,
            limit: 7,
            tenantAssessmentProviderId: flowQ?.id,
            assessmentProviderKey: flowQ?.assessmentProvider.key,
            content: flow?.content,
            type: 'radio',
          };
        }),
        info: flowQ?.assessmentProvider.note,
        assessmentProviderKey: flowQ?.assessmentProvider.key,
      },
      {
        name: 'Zaphire',
        tests: assessments?.data?.map((assessment) => ({
          assessment: 'Zaphire',
          isSelected: false,
          isAssigned: false,
          assessmentId: assessment.id,
          name: assessment?.name,
          questionCount: assessment.questionCount,
          threshold: '80',
          englishThreshold: null,
          mentalThreshold: null,
          limit: 7,
          tenantAssessmentProviderId: zaphire?.id,
          assessmentProviderKey: zaphire?.assessmentProvider.key,
          type: 'checkbox',
        })),
        info: zaphire?.assessmentProvider.note,
        assessmentProviderKey: zaphire?.assessmentProvider.key,
      },
    ]);
  }, [flowsProfiles, tenantAssessmentProviders, show, assessments]);

  const retakeAssessment = async () => {
    try {
      setIsFetching(true);
      await AdminService.retakeAssessments({
        id: candidate.id,
        assignedAssessments: assignedAssessments.map((assessment, index) => ({
          tenantAssessmentProviderId: assessment.tenantAssessmentProviderId,
          assessmentProviderKey: assessment.assessmentProviderKey,
          assessmentId: assessment.assessmentId,
          threshold: `${assessment.threshold}`,
          englishThreshold: `${assessment.englishThreshold}`,
          mentalThreshold: `${assessment.mentalThreshold}`,
          limit: assessment.limit,
          sortOrder: index + 1,
        })),
      });
    } catch (error) {
      if (error.response.status === 400) {
        retakeAssessment();
      } else {
        console.log(error);
      }
    } finally {
      setIsFetching(false);
      resetFields();
      handleClose();
      refetch();
    }
  };

  const assignAssessment = async () => {
    try {
      setIsFetching(true);
      await AdminService.createAssignedAssessment({
        id: candidate.id,
        assignedAssessments: assignedAssessments.map((assessment, index) => ({
          tenantAssessmentProviderId: assessment.tenantAssessmentProviderId,
          assessmentProviderKey: assessment.assessmentProviderKey,
          assessmentId: assessment.assessmentId,
          threshold: `${assessment.threshold}`,
          englishThreshold: `${assessment.englishThreshold}`,
          mentalThreshold: `${assessment.mentalThreshold}`,
          limit: assessment.limit,
          sortOrder: index + 1,
        })),
      });
    } catch (error) {
      if (error.response.status === 400) {
        assignAssessment();
      } else {
        console.log(error);
      }
    } finally {
      setIsFetching(false);
      resetFields();
      handleClose();
      refetch();
    }
  };

  const showSaveModal = () => {
    if (!fields?.length) {
      handleClose();
      return;
    }

    showModal({
      title: t('modals.AssessmentModal.saveChangesTitle'),
      text: t('modals.AssessmentModal.saveChangesText'),
      dismissButtonLabel: t('modals.AssessmentModal.saveChangesCancel'),
      confirmButtonLabel: t('modals.AssessmentModal.saveChangesConfirm'),
      icon: 'saveChanges',
      onConfirm: () => {
        if (retake) {
          retakeAssessment();
        } else assignAssessment();

        setTimeout(() => {
          resetFields();
          handleClose();
        }, 200);
      },
      onCancel: () => {
        setTimeout(() => {
          resetFields();
          handleClose();
        }, 200);
      },
    });
  };

  const showExitModal = () => {
    if (!fields?.length) {
      handleClose();
      return;
    }

    showModal({
      title: t('modals.AssessmentModal.exitScreenTitle'),
      text: t('modals.AssessmentModal.exitScreenText'),
      dismissButtonLabel: 'Yes',
      confirmButtonLabel: 'No',
      onConfirm: () => {},
      onCancel: () => {
        setTimeout(() => {
          resetFields();
          handleClose();
        }, 200);
      },
    });
  };

  const closeModal = () => {
    if (
      (candidate?.assignedAssessments?.length &&
        !arraysOfObjectsAreSimilar(
          mapAssessments(candidate?.assignedAssessments),
          mapAssessments(assignedAssessments)
        )) ||
      (assignedAssessments.length && !candidate?.assignedAssessments?.length)
    ) {
      showExitModal();
    } else {
      resetFields();
      handleClose();
    }
  };

  const clearSelection = () => {
    setFields((prevFields) => {
      return prevFields.map((field) => {
        return {
          ...field,
          tests: field.tests.map((test) => {
            return {
              ...test,
              isSelected: false,
              isAssigned: false,
              threshold: null,
              mentalThreshold: null,
              englishThreshold: null,
            };
          }),
        };
      });
    });
  };

  useEffect(() => {
    if (!candidate || !candidate?.assignedAssessments?.length) {
      return;
    }

    setFields((prevFields) => {
      return prevFields.map((field) => {
        return {
          ...field,
          tests: field.tests.map((test) => {
            const savedTest = candidate?.assignedAssessments?.find(
              (assignedTest) =>
                assignedTest.assessmentProviderKey ===
                  field.assessmentProviderKey &&
                assignedTest.assessmentId === test.assessmentId
            );

            return savedTest
              ? { ...test, isSelected: true, isAssigned: true }
              : test;
          }),
        };
      });
    });
  }, [candidate]);

  const moveAssessmentsToAssigned = () => {
    setFields((prevFields) => {
      return prevFields.map((field) => {
        return {
          ...field,
          tests: field.tests.map((test) => {
            return {
              ...test,
              isSelected: test.isSelected,
              isAssigned: test.isSelected,
            };
          }),
        };
      });
    });
  };

  const moveSelectedButton = (
    <span className={classes.moveSelected}>
      <span>{t('modals.AssessmentModal.moveSelected')}</span>
      <span className={classes.arrow}>{'>'}</span>
    </span>
  );

  const isAssignButtonValid = assignedAssessments?.every(
    (assessment) =>
      assessment.limit > 2 &&
      assessment.limit < 10 &&
      (assessment.threshold > 49 || assessment.threshold === null) &&
      (assessment.englishThreshold > 49 ||
        assessment.englishThreshold === null) &&
      (assessment.mentalThreshold > 49 || assessment.mentalThreshold === null)
  );

  return (
    <Modal
      show={show}
      centered
      className={classes.AssessmentModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.header}>
        <div>
          <h1>{t('modals.AssessmentModal.selectAssessmentToAssign')}</h1>
          <span>{t('modals.AssessmentModal.selectAssessmentsToAssign')}</span>
        </div>
        <span
          className={classes.closeIcon}
          onClick={() => {
            if (
              (candidate?.assignedAssessments?.length &&
                !arraysOfObjectsAreSimilar(
                  mapAssessments(candidate?.assignedAssessments),
                  mapAssessments(assignedAssessments)
                )) ||
              (assignedAssessments.length &&
                !candidate?.assignedAssessments?.length)
            ) {
              showSaveModal();
              return;
            }
            setAssignedAssessments([]);
            handleClose();
          }}
        />
      </div>

      <div className={classes.container}>
        <AvailableAssessments fields={fields} setFields={setFields} />
        <SelectedAssessments
          fields={fields}
          setFields={setFields}
          assignedAssessments={assignedAssessments}
          setAssignedAssessments={setAssignedAssessments}
        />
      </div>

      <div className={classes.footer}>
        <div className={classes.leftPanel}>
          <span
            className={classNames(classes.reset, {
              [classes.disabled]: !assignedAssessments?.length,
            })}
            onClick={clearSelection}
          >
            {t('modals.AssessmentModal.clearSelection')}
          </span>
          <Button
            width={171}
            height={45}
            style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '16px' }}
            disabled={
              !fields.some((field) =>
                field?.tests?.some((test) => test.isSelected)
              )
            }
            onClick={moveAssessmentsToAssigned}
          >
            {moveSelectedButton}
          </Button>
        </div>
        <div className={classes.rightPanel}>
          <Button
            width={120}
            height={45}
            style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '16px' }}
            disabled={!assignedAssessments.length || !isAssignButtonValid}
            onClick={retake ? retakeAssessment : assignAssessment}
          >
            {t('modals.AssessmentModal.assign')}
          </Button>
          <Button
            width={120}
            height={45}
            variant="delete"
            style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '16px' }}
            onClick={closeModal}
          >
            {t('modals.AssessmentModal.cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
