import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';

import InactiveOverlay from '../InactiveOverlay';

import useResizeObserver from '../../../../../../../hooks/useResizeObserver';
import { JOBS_PORTAL_URL } from '../../../../../../../constants/main';
import classes from './styles.module.scss';

export default function PagePreview({
  width,
  innerWidth,
  height,
  brandColors,
  logoSettings,
  path,
  cut,
  isActive,
  noActiveBorder,
}) {
  const pagePreviewRef = useRef();
  const iframeRef = useRef();

  const { width: previewWidth, height: previewHeight } =
    useResizeObserver(pagePreviewRef);

  useEffect(() => {
    const scale = previewWidth / innerWidth;
    pagePreviewRef.current.style.setProperty('--scale', scale);
    pagePreviewRef.current.style.setProperty(
      '--height',
      `${previewHeight / scale}px`
    );
  }, [previewHeight, innerWidth, previewWidth]);

  useEffect(() => {
    iframeRef.current.addEventListener('load', () => {
      iframeRef.current.contentWindow.postMessage(
        {
          type: 'setPreviewMode',
        },
        '*'
      );
    });
  }, []);

  useEffect(() => {
    if (brandColors.length) {
      iframeRef.current.contentWindow.postMessage(
        {
          type: 'setColors',
          payload: brandColors,
        },
        '*'
      );
    }
  }, [brandColors]);

  useEffect(() => {
    if (logoSettings) {
      if (logoSettings.logo instanceof File) {
        const reader = new FileReader();
        reader.onloadend = () => {
          iframeRef.current.contentWindow.postMessage(
            {
              type: 'setLogo',
              payload: reader.result,
            },
            '*'
          );
        };
        reader.readAsDataURL(logoSettings.logo);
      } else {
        iframeRef.current.contentWindow.postMessage(
          {
            type: 'setLogo',
            payload: logoSettings.logo,
          },
          '*'
        );
      }
    }
  }, [logoSettings]);

  return (
    <div
      ref={pagePreviewRef}
      className={classNames(classes.PagePreview, {
        [classes.cut]: cut,
        [classes.active]: isActive && !noActiveBorder,
      })}
      style={{
        maxWidth: cut ? width + 80 : width,
        minWidth: cut ? width + 80 : width,
        height,
        maxHeight: height,
      }}
    >
      <InactiveOverlay isActive={isActive || noActiveBorder} />
      <iframe
        ref={iframeRef}
        src={`${JOBS_PORTAL_URL}${path}`}
        title="Landing"
        frameBorder="0"
        style={{
          width: innerWidth,
        }}
      />
    </div>
  );
}
