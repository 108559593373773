import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import classes from './styles.module.scss';

export default function EventPopup({ event, month, day, week }) {
  const { t } = useTranslation();

  const person = `${event.userFirstName} ${event.userLastName}`;

  return (
    <div
      className={classNames(classes.popup, {
        [classes.popupLeft]:
          [4, 5, 6].includes(new Date(event?.eventDate).getDay()) && week,
        [classes.month]: month,
        [classes.monthPopupLeft]:
          [4, 5, 6].includes(new Date(event?.eventDate).getDay()) && month,
        [classes.day]: day,
        [classes.dayPopupLeft]:
          [4, 5, 6].includes(new Date(event?.eventDate).getDay()) && day,
      })}
    >
      <div>
        <p className={classes.bold}>{`${event?.type} ${t(
          'dashboardComponents.Calendar.EventCard.EventPopup.for'
        )} ${event?.jobPost.jobTitle}`}</p>
        <p className={classes.regular}>{person}</p>
      </div>

      <div>
        <p className={classes.bold}>
          {t('dashboardComponents.Calendar.EventCard.EventPopup.you')}{' '}
          {event?.users.length > 1 ? `+${event?.users.length - 1}` : ``}
        </p>
        <div className={classNames(classes.regular, classes.spaceBetween)}>
          <span>{`${new Date(event?.eventDate).getDate()}th ${new Date(
            event?.eventDate
          ).toLocaleString('en-US', {
            month: 'long',
          })}`}</span>
          <span>{event?.time}</span>
        </div>
      </div>
    </div>
  );
}
