import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function InterviewFeedback({ interview }) {
  const feedback = interview?.jobApplicationInterviewFeedbacks[0];
  let feedbackStatus;

  if (feedback) {
    feedbackStatus = feedback?.isRecommended ? 'Passed' : 'Rejected';
  }

  return (
    <div
      className={classNames(classes.InterviewInfo, {
        [classes.green]:
          interview?.status === 'Passed' || feedbackStatus === 'Passed',
        [classes.red]:
          interview?.status === 'Rejected' || feedbackStatus === 'Rejected',
        [classes.orange]: interview?.status === 'Declined',
      })}
    >
      <span>
        {interview?.jobApplicationInterviewFeedbacks[0]?.feedbackRating}
      </span>
      <span>{feedback ? feedbackStatus : interview?.status}</span>
    </div>
  );
}
