import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import Textarea from '../../components/Form/Textarea';
import SelectedUsers from '../../components/Dashboard/SelectedUsers';
import MultiDropdown from '../../components/Form/MultiDropdown';
import Button from '../../components/Button';

import { UiContext } from '../../context/UiContext';
import AdminService from '../../services/AdminService';
import classes from './styles.module.scss';

export default function ShareModal({
  show,
  handleClose,
  candidate,
  job,
  event,
}) {
  const [users, setUsers] = useState('');
  const [message, setMessage] = useState('');

  const { showNotification, setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setUsers('');
      setMessage('');
    };
  }, [show]);

  let shareContent;

  if (candidate) {
    shareContent = {
      title: candidate?.user?.userProfile
        ? `${candidate.user?.userProfile?.firstName} ${candidate.user?.userProfile?.lastName}`
        : `${candidate?.userProfile?.firstName} ${candidate?.userProfile?.lastName}`,
      subtitle:
        candidate?.applicantLocation ||
        candidate?.userProfile?.city ||
        candidate?.jobApplications?.find(
          (application) => application?.applicantLocation?.length
        )?.applicantLocation,
      link: t('modals.ShareModal.candidateLink'),
      shareSuccessWindow: t('modals.ShareModal.candidateSuccessfullyShared'),
    };
  }

  if (job) {
    shareContent = {
      title: `${job?.title}`,
      subtitle: `${t('modals.ShareModal.jobId')}: ${job?.jobPostId}`,
      link: t('modals.ShareModal.jobLink'),
      shareSuccessWindow: t('modals.ShareModal.jobSuccessfullyShared'),
    };
  }

  if (event) {
    shareContent = {
      title: `${event?.type} ${t('modals.ShareModal.for')} ${
        event?.jobPost.jobTitle
      }`,
      subtitle: `${t('modals.ShareModal.candidateName')}: ${
        event?.userFirstName
      } ${event?.userLastName}`,
      link: t('modals.ShareModal.eventLink'),
      shareSuccessWindow: t('modals.ShareModal.eventSuccessfullyShared'),
    };
  }

  const shareCandidate = async (userId) => {
    try {
      await AdminService.recommendCandidate({
        id: candidate?.user?.id || candidate?.id,
        userId,
        message,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const share = async () => {
    try {
      setIsFetching(true);

      users.forEach((user) => {
        if (candidate) {
          shareCandidate(user.value);
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
      handleClose();
      showNotification({ text: shareContent.shareSuccessWindow });
    }
  };

  const copyLink = () => {
    let link;
    let type;

    const url = `${window.location.protocol}//${window.location.host}`;

    if (candidate) {
      type = 'Candidate';
      link = `${url}/tools/candidates?candidate=${
        candidate?.userId || candidate?.id
      }`;
    }

    if (job) {
      type = 'Job post';
      link = `${url}/tools/jobs/my-jobs/${job?.jobPostId}`;
    }

    if (event) {
      type = 'Calendar event';
      link = `${url}/tools/calendar?event=${event?.type}&id=${event?.id}`;
    }

    navigator.clipboard.writeText(link);
    handleClose();
    showNotification({
      text: `${type} ${t('modals.ShareModal.linkCopiedToClipboard')}`,
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.ShareModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header>
          <h1>{t('modals.ShareModal.share')}</h1>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <div className={classes.container}>
          <div className={classes.col}>
            <div className={classes.shareDetails}>
              <h2>{shareContent?.title}</h2>
              <span className={classes.location}>{shareContent?.subtitle}</span>
            </div>
            <div className={classes.share}>
              <div className={classes.selectContainer}>
                <MultiDropdown
                  note={t('modals.ShareModal.selectUpTo12')}
                  width={375}
                  label={t('modals.ShareModal.shareWithTeamViaEmail')}
                  fetchOptions={{
                    dataName: 'userOptionPostJobSuccess',
                    getDataHandler: AdminService.getRecipients,
                    labelName: 'userName',
                    isUserList: true,
                  }}
                  value={users}
                  setFieldValue={(_name, option) => setUsers(option)}
                  placeholder={t('modals.ShareModal.selectName')}
                  maxLength={12}
                />
              </div>
              <SelectedUsers invitedUsers={users} setInvitedUsers={setUsers} />
            </div>
          </div>
          <div className={classes.col}>
            <button
              className={classes.linkButton}
              type="button"
              onClick={copyLink}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="#7A5BE9" />
                <mask
                  id="mask0_1420_44385"
                  maskUnits="userSpaceOnUse"
                  x="6"
                  y="5"
                  width="14"
                  height="15"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 5.51562H19.5V19.0131H6.5V5.51562Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_1420_44385)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.0825 13.3311C15.0036 14.4513 13.2784 14.5133 12.137 13.4937C11.9069 13.2911 11.8784 12.933 12.0735 12.6939C12.2687 12.4547 12.6136 12.4251 12.8438 12.6279C12.8451 12.6288 12.8463 12.6299 12.8474 12.6311C13.5658 13.2726 14.6267 13.2423 15.3119 12.5309L17.8806 9.86393C18.5989 9.11826 18.5989 7.94262 17.8806 7.19694C17.1624 6.45127 16.03 6.45127 15.312 7.19694L12.8716 9.7306C12.6618 9.95483 12.3169 9.95992 12.1011 9.74211C11.8853 9.52429 11.8804 9.16616 12.0902 8.94193C12.0936 8.93816 12.0974 8.93439 12.1011 8.93062L14.5414 6.39696C15.6731 5.22188 17.5195 5.22188 18.6512 6.39677C19.783 7.57185 19.783 9.48902 18.6512 10.6641L16.0825 13.3311ZM11.4587 18.1322C10.327 19.3073 8.48045 19.3073 7.34867 18.1324C6.21708 16.9571 6.21708 15.0402 7.34885 13.8651L9.91755 11.1981C10.9967 10.0777 12.7217 10.0158 13.8629 11.0355C14.0921 11.2396 14.1184 11.5977 13.9219 11.8357C13.7257 12.0729 13.3817 12.101 13.1525 11.8981C12.4343 11.2566 11.3732 11.2869 10.6881 11.9981L8.11935 14.6651C7.40117 15.4107 7.40117 16.5864 8.11935 17.3321C8.83754 18.0777 9.96986 18.0777 10.6881 17.3321L13.1283 14.7984C13.3385 14.5747 13.6836 14.57 13.899 14.7882C14.1144 15.0064 14.1188 15.3647 13.9086 15.5884C13.9056 15.5918 13.9023 15.5952 13.899 15.5986L11.4587 18.1322Z"
                    fill="white"
                  />
                </g>
              </svg>
              {shareContent?.link}
            </button>
            <Textarea
              label={t('modals.ShareModal.emailMessage')}
              note={t('common.optional')}
              height={154}
              placeholder={t('modals.ShareModal.enterNotes')}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
        <footer>
          <Button onClick={share} width={301} height={52} disabled={!users}>
            {t('modals.ShareModal.shareButton')}
          </Button>
        </footer>
      </div>
    </Modal>
  );
}
