import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

const getTextNodeHeight = (node) => {
  const range = document.createRange();
  range.selectNodeContents(node);
  const { height } = range.getBoundingClientRect();
  return height;
};

export default function Text({ text, modal }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTextClamped, setIsTextClamped] = useState(true);
  const [textHeight, setTextHeight] = useState(48);
  const [initialHeight, setInitialHeight] = useState(48);

  const textRef = useRef();

  const { t } = useTranslation();

  useLayoutEffect(() => {
    const textNode = textRef.current.childNodes[0];
    const textNodeHeight = getTextNodeHeight(textNode);
    textRef.current.style.setProperty(
      '--text-height',
      `${textNodeHeight + 20}px`
    );
    setTextHeight(textNodeHeight);
  }, [text, isTextClamped]);

  useEffect(() => {
    if (!isExpanded) {
      setTimeout(() => {
        setIsTextClamped(true);
      }, 300);
    } else {
      setIsTextClamped(false);
    }
  }, [isExpanded, text]);

  // increase initial height if content starts with blockquote
  useEffect(() => {
    if (text.startsWith('<blockquote')) {
      textRef.current.style.setProperty('--initial-height', '61px');
      setInitialHeight(61);
    }
  }, [text]);

  return (
    <div
      className={classNames(classes.Text, {
        [classes.insideModal]: modal,
      })}
      ref={textRef}
    >
      <div
        className={classNames(classes.container, {
          [classes.notClamped]: !isTextClamped,
          [classes.expanded]: isExpanded,
        })}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: text }}
      />
      {textHeight > initialHeight && (
        <div className={classes.buttonContainer}>
          <button
            className={classes.seeMoreButton}
            type="button"
            onClick={() => {
              setIsExpanded((prevState) => !prevState);
            }}
          >
            {isExpanded
              ? t('dashboardComponents.Discover.Feed.Post.Text.See Less')
              : t('dashboardComponents.Discover.Feed.Post.Text.See More')}
          </button>
        </div>
      )}
    </div>
  );
}
