/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import AdminService from '../../services/AdminService';
import { UiContext } from '../../context/UiContext';
import infoIcon from '../../assets/images/modals/info.svg';
import sadFaceIcon from '../../assets/images/dashboard/report-candidate/sad-face.svg';
import eyeOffIcon from '../../assets/images/dashboard/report-candidate/eye-off.svg';
import messageIcon from '../../assets/images/dashboard/report-candidate/message.svg';
import alertIcon from '../../assets/images/dashboard/report-candidate/alert.svg';
import thumbsDownIcon from '../../assets/images/dashboard/report-candidate/thumbs-down.svg';
import userXIcon from '../../assets/images/dashboard/report-candidate/user-x.svg';
import flagIcon from '../../assets/images/dashboard/report-candidate/flag.svg';
import octagonIcon from '../../assets/images/dashboard/report-candidate/ocatagon.svg';
import classes from './styles.module.scss';
import Button from '../../components/Button';

export default function ReportCandidateModal({
  show,
  handleClose,
  reportingCandidate,
  refetch,
}) {
  const [selectedReasons, setSelectedReasons] = useState([]);

  const { showNotification, setIsFetching, isFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const toggleReason = (reason) => {
    setSelectedReasons((prevReasons) => {
      if (prevReasons.includes(reason)) {
        return prevReasons.filter((rsn) => rsn !== reason);
      }
      return [...prevReasons, reason];
    });
  };

  const userProfile =
    reportingCandidate?.userProfile || reportingCandidate?.user?.userProfile;

  const userName = userProfile ? userProfile.fullName : 'Candidate';

  const submitReport = async () => {
    try {
      setIsFetching(true);

      const report = await AdminService.createUserReport({
        userId: reportingCandidate.user?.id || reportingCandidate.id,
        reason: selectedReasons.join(', '),
      });

      const prevStatus = reportingCandidate.status;
      const prevSubStatus = reportingCandidate.subStatus;

      await AdminService.changeMyJobApplicationStatus({
        jobApplicationId: reportingCandidate.id,
        status: reportingCandidate.status,
        subStatus: 'Rejected',
      });

      await refetch();

      handleClose();
      showNotification({
        text: `${userName} blocked and reported.`,
        undo: async () => {
          try {
            setIsFetching(true);
            await AdminService.changeMyJobApplicationStatus({
              jobApplicationId: reportingCandidate.id,
              status: prevStatus,
              subStatus: prevSubStatus,
            });

            await AdminService.deleteUserReport({ id: report.id });

            await refetch();
          } catch (error) {
            console.log(error);
          } finally {
            setIsFetching(false);
          }
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    return () => {
      setSelectedReasons([]);
    };
  }, [show]);

  const reasons = [
    {
      title: t('modals.ReportCandidateModal.madeMeUncomfortable'),
      icon: sadFaceIcon,
    },
    {
      title: t('modals.ReportCandidateModal.offensiveSignsGestures'),
      icon: eyeOffIcon,
    },
    {
      title: t('modals.ReportCandidateModal.abusiveThreatening'),
      icon: messageIcon,
    },
    {
      title: t('modals.ReportCandidateModal.inappropriateLanguage'),
      icon: alertIcon,
    },
    {
      title: t('modals.ReportCandidateModal.weaponsAlcoholDrugs'),
      icon: thumbsDownIcon,
    },
    { title: t('modals.ReportCandidateModal.nudity'), icon: userXIcon },
    { title: t('modals.ReportCandidateModal.spamScamFake'), icon: flagIcon },
    {
      title: t('modals.ReportCandidateModal.candidateUnder18'),
      icon: octagonIcon,
    },
  ];

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.ReportCandidateModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <i className={classes.closeIcon} onClick={handleClose}>
          Close
        </i>
        <img
          width={32}
          height={32}
          className={classes.icon}
          src={infoIcon}
          alt="Icon"
        />
        <div className={classes.reportingReasons}>
          <h1>{t('modals.ReportCandidateModal.reportingReason')}</h1>
          <ul>
            {reasons.map((reason) => {
              return (
                <li
                  className={classNames({
                    [classes.active]: selectedReasons.includes(reason.title),
                  })}
                  key={reason.title}
                  onClick={() => toggleReason(reason.title)}
                >
                  {reason.title} <img src={reason.icon} alt="Icon" />
                </li>
              );
            })}
          </ul>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            width={144}
            height={60}
            disabled={!selectedReasons.length || isFetching}
            onClick={submitReport}
          >
            {t('common.submit')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
