/* eslint-disable consistent-return */
import React, { useState, useEffect, useContext } from 'react';

import moment from 'moment';

import Question from './Question';
import PollButtons from './PollButtons';

import { UiContext } from '../../../../../../context/UiContext';
import { getAuthData } from '../../../../../../helpers/authStorage';
import FeedService from '../../../../../../services/FeedService';
import classes from './styles.module.scss';

const countVoters = (poll) => {
  const voterIds = new Set();

  poll.questions.forEach((question) => {
    question.options.forEach((option) => {
      option.votes.forEach((vote) => {
        voterIds.add(vote.author.id);
      });
    });
  });

  return voterIds.size;
};

export default function Poll({ poll, feedPostId, refetch, authorId }) {
  const [questionsAnswers, setQuestionsAnswers] = useState({});
  const [hasVoted, setHasVoted] = useState(false);
  const [votes, setVotes] = useState({});
  const [totalVotes, setTotalVotes] = useState(0);

  const { userId } = getAuthData();

  const { setIsFetching } = useContext(UiContext);

  const isAuthor = +userId === authorId;
  const isPollClosed = !poll.isActive || moment().isAfter(poll.endDate);

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const hasVoted = poll.questions[0].options.some((option) => {
      return option.votes.some((vote) => vote.author.id === +userId);
    });

    setHasVoted(hasVoted);
  }, [poll, userId]);

  useEffect(() => {
    if (isPollClosed || hasVoted) {
      setTotalVotes(countVoters(poll));
    }
  }, [hasVoted, isPollClosed, poll]);

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const votes = {};
    // eslint-disable-next-line no-shadow

    poll.questions.forEach((question) => {
      const votesPerAnswer = {};

      question.options.forEach((option) => {
        votesPerAnswer[option.id] = option.votes.length;
      });

      votes[question.id] = votesPerAnswer;
    });

    setVotes(votes);
  }, [poll]);

  const clearAnswers = () => {
    setQuestionsAnswers({});
  };

  const isPollValid = () => {
    const questions = Object.keys(questionsAnswers);
    if (questions.length !== poll.questions.length) {
      return false;
    }

    return Object.keys(questionsAnswers).every(
      (questionId) => questionsAnswers[questionId].optionIds.length > 0
    );
  };

  const vote = async () => {
    try {
      setIsFetching(true);
      await FeedService.vote({
        pollId: poll.id,
        feedPostId,
        questions: Object.keys(questionsAnswers).map((questionId) => {
          return {
            questionId,
            optionIds: questionsAnswers[questionId].optionIds,
          };
        }),
      });
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const enableRevote = () => {
    setHasVoted(false);
    clearAnswers();
  };

  const stopPoll = async () => {
    try {
      setIsFetching(true);
      await FeedService.stopPoll({ pollId: poll.id, feedPostId });
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const resumePoll = async () => {
    try {
      setIsFetching(true);
      await FeedService.resumePoll({ pollId: poll.id, feedPostId });
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.Poll}>
      <div className={classes.container}>
        {poll.questions
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((question, index) => (
            <Question
              votes={votes[question.id]}
              hasVoted={hasVoted}
              isPollClosed={isPollClosed}
              key={question.id}
              question={question}
              index={index}
              questionsAnswers={questionsAnswers}
              setQuestionsAnswers={setQuestionsAnswers}
            />
          ))}
      </div>
      <div className={classes.footer}>
        <PollButtons
          enableRevote={enableRevote}
          hasVoted={hasVoted}
          vote={vote}
          isPollValid={isPollValid}
          clearAnswers={clearAnswers}
          isAuthor={isAuthor}
          stopPoll={stopPoll}
          isPollClosed={isPollClosed}
          createdAt={poll.createdAt}
          resumePoll={resumePoll}
          pollId={poll?.id}
        />
        {isPollClosed || hasVoted ? (
          <div className={classes.totalVotes}>Total Votes: {totalVotes}</div>
        ) : null}
      </div>
    </div>
  );
}
