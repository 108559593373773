import classNames from 'classnames';
import React from 'react';

import classes from './styles.module.scss';

export default function Badge({
  badge,
  areBadgesEnabled,
  enabledBadges,
  addTagToJobPost,
}) {
  const handleClick = () => {
    if (!areBadgesEnabled) {
      return;
    }

    if (enabledBadges.find(({ id }) => badge.id === id)) {
      const tags = enabledBadges;
      const currentBadgeIndex = tags.findIndex((tag) => tag.id === badge.id);

      tags.splice(currentBadgeIndex, 1);

      addTagToJobPost([...tags]);
      return;
    }

    addTagToJobPost([...enabledBadges, badge]);
  };

  return (
    <div
      onClick={handleClick}
      className={classNames(classes.Badge, {
        [classes.active]: enabledBadges.find(({ id }) => badge.id === id),
      })}
    >
      {badge.name}
    </div>
  );
}
