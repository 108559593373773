import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function InactiveOverlay({ isActive }) {
  return (
    <div
      className={classNames(classes.InactiveOverlay, {
        [classes.visible]: !isActive,
      })}
    >
      <div className={classes.inner} />
    </div>
  );
}
