import React from 'react';

import { useTranslation } from 'react-i18next';

import PopupButton from '../../../../../PopupButton';
import Button from '../../../../../Button';

import { useUiContext } from '../../../../../../context/UiContext';
import chevron from '../../../../../../assets/images/dashboard/candidates/chevron.svg';
import classes from './styles.module.scss';

export default function Buttons({
  submitForm,
  canSwitchCandidates,
  switchCandidate,
  isEdit,
  isDisabled,
  resetForm,
  isPossibleToEdit,
  enableEditing,
  isReadOnly,
  deleteCandidate,
}) {
  const { isFetching } = useUiContext();

  const { t } = useTranslation();

  let buttons = (
    <>
      <PopupButton width={250} onClick={resetForm}>
        {t('common.clear')}
      </PopupButton>
      <Button
        width={250}
        height={45}
        onClick={submitForm}
        disabled={isDisabled || isFetching}
        style={{
          fontSize: 16,
        }}
      >
        {t('common.add')}
      </Button>
    </>
  );

  if (isEdit) {
    buttons = (
      <>
        <PopupButton
          disabled={!isPossibleToEdit || isFetching}
          width={250}
          height={45}
          variant="dark"
          onClick={deleteCandidate}
          //  onClick={onLeftButtonClick}
          //  disabled={isLeftButtonDisabled}
        >
          {t('common.delete')}
        </PopupButton>
        {isReadOnly ? (
          <PopupButton
            disabled={!isPossibleToEdit}
            width={250}
            height={45}
            onClick={enableEditing}
            //   onClick={onRightButtonClick}
            //   disabled={isRightButtonDisabled}
          >
            {isPossibleToEdit ? t('common.edit') : t('common.notEditable')}
          </PopupButton>
        ) : (
          <Button
            width={250}
            height={45}
            onClick={submitForm}
            disabled={isDisabled || isFetching}
            style={{
              fontSize: 16,
            }}
          >
            {t('common.save')}
          </Button>
        )}
      </>
    );
  }

  return (
    <div className={classes.Buttons}>
      {canSwitchCandidates && (
        <button
          type="button"
          className={classes.switch}
          onClick={() => switchCandidate('previous')}
        >
          <img src={chevron} alt="Previous" />
        </button>
      )}
      <div className={classes.mainButtons}>{buttons}</div>
      {canSwitchCandidates && (
        <button
          type="button"
          className={classes.switch}
          onClick={() => switchCandidate('next')}
        >
          <img src={chevron} alt="Next" />
        </button>
      )}
    </div>
  );
}
