import React, { useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import SearchInput from '../SearchInput';
import Assessment from './Assessment';

import classes from './styles.module.scss';

export default function AvailableAssessments({ fields, setFields }) {
  const [searchTerm, setSearchTerm] = useState('');

  const { t } = useTranslation();

  const testsAmount = useMemo(
    () =>
      fields?.reduce(
        (accum, assessment) => accum + assessment.tests?.length,
        0
      ),
    [fields]
  );

  return (
    <div className={classes.AvailableAssessments}>
      <div className={classes.assessmentHeader}>
        <div className={classes.container}>
          <h2>
            {t(
              'modals.AssessmentModal.AvailableAssessments.availableAssessment'
            )}
          </h2>
          <span
            className={classNames(classes.amount, {
              [classes.green]: testsAmount > 0,
            })}
          >
            {testsAmount || 0}
          </span>
        </div>
      </div>
      <div className={classes.search}>
        <SearchInput value={searchTerm} setValue={setSearchTerm} />
      </div>
      <div className={classes.content}>
        {fields.map((assessment) => (
          <Assessment
            key={assessment.name}
            assessment={assessment}
            isSelected={assessment.tests?.some((test) => test.isSelected)}
            searchTerm={searchTerm}
            fields={fields}
            setFields={setFields}
          />
        ))}
      </div>
    </div>
  );
}
