import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import AuthLayout from '../../containers/AuthLayout';
import classes from './styles.module.scss';

export default function SignInMethodPage() {
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [info, setInfo] = useState('Use your password to login');

  const navigate = useNavigate();

  const { t } = useTranslation();

  const showPasswordInfo = () => {
    setInfo(t('pages.SignInMethodPage.passwordInfo'));
    setIsInfoVisible(true);
  };

  const showOtpInfo = () => {
    setInfo(t('pages.SignInMethodPage.otpInfo'));
    setIsInfoVisible(true);
  };

  const hideInfo = () => {
    setIsInfoVisible(false);
  };

  return (
    <AuthLayout previousRoute="/login" noButton>
      <div className={classes.SignInMethodPage}>
        <Helmet>
          <title>{t('pages.SignInMethodPage.pageTitle')}</title>
        </Helmet>
        <div className={classes.container}>
          <h1>
            {t('pages.SignInMethodPage.chooseYour')} <br />{' '}
            {t('pages.SignInMethodPage.signInMethod')}
          </h1>
          <div className={classes.buttons}>
            <Button
              width={150}
              height={150}
              onMouseEnter={showPasswordInfo}
              onMouseLeave={hideInfo}
              onClick={() => navigate('/login/method/password')}
            >
              {t('pages.SignInMethodPage.passwordButton')}
            </Button>
            <Button
              width={150}
              height={150}
              onMouseEnter={showOtpInfo}
              onMouseLeave={hideInfo}
              onClick={() => navigate('/login/method/otp')}
            >
              {t('pages.SignInMethodPage.otpButton')}
            </Button>
          </div>
          <p
            className={classes.info}
            style={{ visibility: isInfoVisible ? 'visible' : 'hidden' }}
          >
            {info}
          </p>
        </div>
      </div>
    </AuthLayout>
  );
}
