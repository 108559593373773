import React from 'react';

import classes from './styles.module.scss';

export default function GraphHoverInfo({ label, value, coords }) {
  return (
    <div
      className={classes.GraphHoverInfo}
      style={{ left: coords?.x, top: coords?.y + 25 }}
    >
      <h4 className={classes.title}>{label}</h4>
      <span className={classes.value}>{value}</span>
    </div>
  );
}
