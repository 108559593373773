import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';

import BrandKit from './BrandKit';
import SocialMedia from './SocialMedia';
import Tabs from './Tabs';
import Profile from './Profile';
import Password from './Password';
import Notification from './Notification';

import BrandKitContextProvider from '../../../context/BrandKitContext';
import { useUserStore } from '../../../store';
import UserService from '../../../services/UserService';
import classes from './styles.module.scss';

export default function ProfileSettings() {
  const [isProfileEditing, setIsProfileEditing] = useState(false);

  const { subpage } = useParams();

  const { t } = useTranslation();

  const userTabs = [
    {
      title: t('dashboardComponents.ProfileSettings.profileTabTitle'),
      path: 'profile',
    },
    {
      title: t('dashboardComponents.ProfileSettings.passwordTabTitle'),
      path: 'password',
    },
    {
      title: t('dashboardComponents.ProfileSettings.notificationTabTitle'),
      path: 'notification',
    },
  ];

  const adminTabs = [
    ...userTabs,
    {
      title: t('dashboardComponents.ProfileSettings.socialMediaTabTitle'),
      path: 'social-media',
    },
    {
      title: t('dashboardComponents.ProfileSettings.brandKitTabTitle'),
      path: 'brand-kit',
    },
  ];

  const userScopes = useUserStore((state) => state.userScopes);

  const tabs = userScopes.includes('api:full') ? adminTabs : userTabs;

  const { data: user, refetch } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  return (
    <div
      className={classNames(classes.ProfileSettings, {
        [classes.noPaddings]:
          subpage === 'social-media' || subpage === 'brand-kit',
      })}
    >
      <h1 className={classes.title}>Settings</h1>
      <div className={classes.container}>
        <Tabs tabs={tabs} />
        {subpage === 'profile' && (
          <Profile
            user={user}
            refetch={refetch}
            isEditing={isProfileEditing}
            setIsEditing={setIsProfileEditing}
          />
        )}
        {subpage === 'password' && <Password />}
        {subpage === 'notification' && (
          <Notification user={user} refetch={refetch} />
        )}
        {/* Prevent the following components from rendering if the user is not an
        admin */}
        {subpage === 'social-media' && userScopes.includes('api:full') && (
          <SocialMedia />
        )}
        {subpage === 'brand-kit' && userScopes.includes('api:full') && (
          <BrandKitContextProvider>
            <BrandKit />
          </BrandKitContextProvider>
        )}
      </div>
    </div>
  );
}
