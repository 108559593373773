/* eslint-disable consistent-return */
import React, { useEffect } from 'react';

import { arrayMoveImmutable } from 'array-move';

import SocialLink from './SocialLink';

import classes from './styles.module.scss';

export default function Links({
  links,
  setLinks,
  linksValidity,
  setLinksValidity,
}) {
  // add script to handle drag and drop on mobile devices
  useEffect(() => {
    // Check if the script already exists
    const existingScript = document.querySelector(
      "script[src='https://bernardo-castilho.github.io/DragDropTouch/DragDropTouch.js']"
    );

    if (!existingScript) {
      // If the script doesn't exist, create and append it
      const script = document.createElement('script');
      script.src =
        'https://bernardo-castilho.github.io/DragDropTouch/DragDropTouch.js';
      script.async = true;

      document.body.appendChild(script);

      return () => {
        // Remove the script when the component is unmounted
        document.body.removeChild(script);
      };
    }
  }, []);

  const changeLinkPositionOnDrop = (event) => {
    event.preventDefault();

    const oldLinkIndex = event.dataTransfer.getData('linkIndex');

    const coords = event.currentTarget.getBoundingClientRect();

    const yPosition = event.clientY - coords.top;

    const newLinkIndex = Math.max(Math.floor(yPosition / (100 + 20)), 0);

    const newLinksArray = arrayMoveImmutable(links, oldLinkIndex, newLinkIndex);

    setLinks(newLinksArray);
  };

  return (
    <div
      className={classes.Links}
      onDragOver={(event) => event.preventDefault()}
      onDrop={changeLinkPositionOnDrop}
    >
      {links.map((link, index) => (
        <SocialLink
          isValid={linksValidity[link.title]}
          setLinksValidity={setLinksValidity}
          key={link.title}
          link={link}
          setLinks={setLinks}
          title={link.title}
          icon={link.icon}
          url={link.url}
          isEnabled={link.isEnabled}
          linkIndex={index}
        />
      ))}
    </div>
  );
}
