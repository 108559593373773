import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useSwitchableData from '../../../../../hooks/useSwitchableData';
import AdminService from '../../../../../services/AdminService';
import { UiContext } from '../../../../../context/UiContext';
import RadioButton from '../../../../Form/RadioButton';
import Textarea from '../../../../Form/Textarea';
import TextInput from '../../../../Form/TextInput';
import SidePopup from '../../SidePopup';
import classes from './styles.module.scss';
import Checkbox from '../../../../Form/Checkbox';

export default function ViewRole({
  isVisible,
  handleClose,
  roles,
  refetch,
  currentRoleId,
  showEditRoleSingle,
  isOverlayed,
}) {
  const [roleAccess, setRoleAccess] = useState('Full Access');

  const { t } = useTranslation();
  const [customRoleOptions, setCustomRoleOptions] = useState({
    [t(
      'dashboardComponents.Console.UserManagement.ViewRole.labels.manageJobs'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.ViewRole.labels.manageCandidates'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.ViewRole.labels.manageUsers'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.ViewRole.labels.manageRefData'
    )]: false,
    [t(
      'dashboardComponents.Console.UserManagement.ViewRole.labels.access'
    )]: false,
  });

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableData(roles, currentRoleId);

  const { showModal, showUnknownErrorModal, setIsFetching } =
    useContext(UiContext);

  const deleteRole = async () => {
    try {
      setIsFetching(true);
      await AdminService.deleteRole({ id: roles?.[currentDataIndex].id });
      showModal({
        title: 'Success!',
        text: 'Role deleted successfully',
      });
      await refetch();
      handleClose();
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsFetching(false);
    }
  };

  const showDeleteRoleModal = () => {
    showModal({
      icon: 'delete',
      title: 'Delete Role',
      text: 'Are you sure you want to delete?',
      onConfirm: deleteRole,
      dismissButtonLabel: 'No',
    });
  };

  const roleOptionsToScopesMap = useMemo(
    () => ({
      [t(
        'dashboardComponents.Console.UserManagement.ViewRole.labels.fullAccess'
      )]: 'api:full',
      [t(
        'dashboardComponents.Console.UserManagement.ViewRole.labels.viewOnlyAccess'
      )]: 'api:app',
      [t(
        'dashboardComponents.Console.UserManagement.ViewRole.labels.manageJobs'
      )]: 'api:admin:jobs',
      [t(
        'dashboardComponents.Console.UserManagement.ViewRole.labels.manageCandidates'
      )]: 'api:admin:candidates',
      [t(
        'dashboardComponents.Console.UserManagement.ViewRole.labels.manageUsers'
      )]: 'api:admin:auth',
      [t(
        'dashboardComponents.Console.UserManagement.ViewRole.labels.manageRefData'
      )]: 'api:admin:reference',
      [t('dashboardComponents.Console.UserManagement.ViewRole.labels.access')]:
        'api:app',
    }),
    [t]
  );

  const setRadioButtons = useCallback(() => {
    const role = roles?.[currentDataIndex];

    if (!role) {
      return;
    }

    if (role.scopes === 'api:full') {
      setRoleAccess(
        t(
          'dashboardComponents.Console.UserManagement.ViewRole.labels.fullAccess'
        )
      );
    } else if (role.scopes === 'api:app') {
      setRoleAccess(
        t(
          'dashboardComponents.Console.UserManagement.ViewRole.labels.viewOnlyAccess'
        )
      );
    } else {
      setRoleAccess(
        t(
          'dashboardComponents.Console.UserManagement.ViewRole.labels.buildYourOwn'
        )
      );

      const currentOptions = {};
      Object.keys(roleOptionsToScopesMap).forEach((key) => {
        if (role.scopes.includes(roleOptionsToScopesMap[key])) {
          currentOptions[key] = true;
        } else {
          currentOptions[key] = false;
        }
      });

      setCustomRoleOptions(currentOptions);
    }
  }, [currentDataIndex, roleOptionsToScopesMap, roles, t]);

  useEffect(() => {
    setRadioButtons();
  }, [roles, currentDataIndex, setRadioButtons, isVisible]);

  return (
    <SidePopup
      isVisible={isVisible}
      handleClose={handleClose}
      title={t('dashboardComponents.Console.UserManagement.ViewRole.title')}
      isOverlayed={isOverlayed}
      leftButtonLabel={t('common.delete')}
      leftButtonVariant="delete"
      rightButtonLabel={t('common.edit')}
      onRightButtonClick={() => {
        showEditRoleSingle(roles[currentDataIndex]?.id);
      }}
      onLeftButtonClick={showDeleteRoleModal}
      switchNext={roles?.length > 1 && switchToNextDataElement}
      switchPrev={roles?.length > 1 && switchToPrevDataElement}
    >
      <div className={classes.ViewRole}>
        <div className={classes.row}>
          <div className={classes.col}>
            <TextInput
              label={t(
                'dashboardComponents.Console.UserManagement.ViewRole.labels.roleName'
              )}
              height={50}
              value={roles?.[currentDataIndex]?.name}
              name="name"
              readonly
            />
            <div className={classes.innerRow}>
              <div className={classes.innerCol}>
                <RadioButton
                  label={t(
                    'dashboardComponents.Console.UserManagement.ViewRole.labels.fullAccess'
                  )}
                  isSelected={
                    roleAccess ===
                    t(
                      'dashboardComponents.Console.UserManagement.ViewRole.labels.fullAccess'
                    )
                  }
                />
                <RadioButton
                  isSelected={
                    roleAccess ===
                    t(
                      'dashboardComponents.Console.UserManagement.ViewRole.labels.viewOnlyAccess'
                    )
                  }
                  label={t(
                    'dashboardComponents.Console.UserManagement.ViewRole.labels.viewOnlyAccess'
                  )}
                />
              </div>
              <div className={classes.innerCol}>
                <RadioButton
                  isSelected={
                    roleAccess ===
                    t(
                      'dashboardComponents.Console.UserManagement.ViewRole.labels.buildYourOwn'
                    )
                  }
                  label={t(
                    'dashboardComponents.Console.UserManagement.ViewRole.labels.buildYourOwn'
                  )}
                />
                <div
                  className={classNames(classes.checkboxes, {
                    [classes.visible]:
                      roleAccess ===
                      t(
                        'dashboardComponents.Console.UserManagement.ViewRole.labels.buildYourOwn'
                      ),
                  })}
                >
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.ViewRole.labels.manageJobs'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.ViewRole.labels.manageJobs'
                        )
                      ]
                    }
                  />
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.ViewRole.labels.manageCandidates'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.ViewRole.labels.manageCandidates'
                        )
                      ]
                    }
                  />
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.ViewRole.labels.manageUsers'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.ViewRole.labels.manageUsers'
                        )
                      ]
                    }
                  />
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.ViewRole.labels.manageRefData'
                    )}
                    isSelected={
                      customRoleOptions[
                        t(
                          'dashboardComponents.Console.UserManagement.ViewRole.labels.manageRefData'
                        )
                      ]
                    }
                  />
                  <Checkbox
                    label={t(
                      'dashboardComponents.Console.UserManagement.ViewRole.labels.access'
                    )}
                    isSelected={customRoleOptions.Access}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.col}>
            <Textarea
              label={t('common.notes')}
              height={100}
              value={roles?.[currentDataIndex]?.note}
              name="notes"
              readonly
            />
          </div>
        </div>
      </div>
    </SidePopup>
  );
}
