import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function NoData() {
  const { t } = useTranslation();

  return (
    <div className={classes.NoData}>
      <div className={classes.icon} />
      <p>{t('dashboardComponents.Notifications.NoData.message')}</p>
    </div>
  );
}
