import React, { useState } from 'react';

import classNames from 'classnames';
import moment from 'moment';

import classes from './styles.module.scss';
import EventDetails from '../../../../Calendar/EventDetails';

export default function Event({
  eventDate,
  today,
  startTime,
  endTime,
  type,
  jobTitle,
  userName,
  event,
  user,
}) {
  const [isEventDetailsVisible, setIsEventDetailsVisible] = useState(false);
  const momentEventDate = moment(eventDate);

  const date = momentEventDate.isSame(today, 'day')
    ? 'Today'
    : momentEventDate.format('DD MMM YYYY');

  return (
    <div
      className={classNames(classes.Event, {
        [classes.today]: date === 'Today',
      })}
      onClick={() => setIsEventDetailsVisible(true)}
    >
      <div className={classes.leftCol}>
        <div className={classes.dateCard}>
          <h5>{momentEventDate.format('dddd').substring(0, 3)}</h5>
          <div className={classes.date}>{momentEventDate.format('D')}</div>
        </div>
      </div>
      <div className={classes.rightCol}>
        <h4>{date}</h4>
        <div
          className={classes.time}
          style={{ visibility: startTime || endTime ? 'visible' : 'hidden' }}
        >
          {startTime} till {endTime}
        </div>
        <div className={classes.interviewDetails}>
          {type} for {jobTitle}
        </div>
        <div
          style={{
            visibility: !userName.includes('undefined') ? 'visible' : 'hidden',
          }}
          className={classes.userName}
        >
          {userName}
        </div>
      </div>
      {isEventDetailsVisible && (
        <EventDetails
          isEventDetailsVisible={isEventDetailsVisible}
          handleClose={() => setIsEventDetailsVisible(false)}
          event={event}
          currentUser={user}
        />
      )}
    </div>
  );
}
