import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Dropdown from '../../../Form/Dropdown';
import Button from '../../../Button';
import CandidatesTab from './CandidatesTab';
import JobsTab from './JobsTab';
import CalendarTab from './CalendarTab';

import { getEventFromSearch } from '../../../../helpers/getEvents';
import AdminService from '../../../../services/AdminService';
import classes from './styles.module.scss';
import UserService from '../../../../services/UserService';

export default function Tabs({
  searchTerm,
  activeDropDownOption,
  currentTab,
  setCurrentTab,
}) {
  const [activeAction, setActiveAction] = useState();

  const { t } = useTranslation();

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const {
    data: searchData,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['search', searchTerm],
    queryFn: () =>
      AdminService.getSearch({
        term: searchTerm,
      }),
  });

  const events = getEventFromSearch(
    searchData?.calendarEvents,
    user?.id,
    user?.userProfile?.timezone
  );

  let actions = [];

  if (currentTab === 'Candidates') {
    actions = [
      {
        label: t('dashboardComponents.Search.Tabs.Recent'),
        value: 'recent',
      },
      {
        label: t('dashboardComponents.Search.Tabs.Oldest'),
        value: 'oldest',
      },
      {
        label: t('dashboardComponents.Search.Tabs.CandidateAZ'),
        value: 'candidate: a-z',
      },
      {
        label: t('dashboardComponents.Search.Tabs.CandidateZA'),
        value: 'candidate: z-a',
      },
    ];
  }

  if (currentTab === 'Jobs') {
    actions = [
      {
        label: t('dashboardComponents.Search.Tabs.Recent'),
        value: 'recent',
      },
      {
        label: t('dashboardComponents.Search.Tabs.Oldest'),
        value: 'oldest',
      },
      {
        label: t('dashboardComponents.Search.Tabs.JobNameAZ'),
        value: 'Job Name: a-z',
      },
      {
        label: t('dashboardComponents.Search.Tabs.JobNameZA'),
        value: 'Job Name: z-a',
      },
    ];
  }

  if (currentTab === 'Calendar') {
    actions = [
      {
        label: t('dashboardComponents.Search.Tabs.Upcoming'),
        value: 'upcoming',
      },
      {
        label: t('dashboardComponents.Search.Tabs.Past'),
        value: 'past',
      },
      {
        label: t('dashboardComponents.Search.Tabs.CandidateAZ'),
        value: 'candidate: a-z',
      },
      {
        label: t('dashboardComponents.Search.Tabs.CandidateZA'),
        value: 'candidate: z-a',
      },
    ];
  }

  useEffect(() => {
    setActiveAction(actions[0]);
  }, [currentTab]);

  const sortList = {
    candidates: (prevCandidate, nextCandidate) => {
      switch (activeAction?.label) {
        case t('dashboardComponents.Search.Tabs.Recent'):
          return (
            new Date(nextCandidate?.user?.createdAt) -
            new Date(prevCandidate?.user?.createdAt)
          );
        case t('dashboardComponents.Search.Tabs.Oldest'):
          return (
            new Date(prevCandidate?.user?.createdAt) -
            new Date(nextCandidate?.user?.createdAt)
          );
        case t('dashboardComponents.Search.Tabs.CandidateAZ'):
          return `${prevCandidate?.candidateName}${prevCandidate?.candidateName}`?.localeCompare(
            `${nextCandidate?.candidateName}${nextCandidate?.candidateName}`
          );
        case t('dashboardComponents.Search.Tabs.CandidateZA'):
          return `${nextCandidate?.candidateName}${nextCandidate?.candidateName}`?.localeCompare(
            `${prevCandidate?.userProfile?.firstName}${prevCandidate?.candidateName}`
          );

        default:
          return 0;
      }
    },
    jobs: (prevJob, nextJob) => {
      switch (activeAction?.label) {
        case t('dashboardComponents.Search.Tabs.Recent'):
          return new Date(nextJob?.createdAt) - new Date(prevJob?.createdAt);
        case t('dashboardComponents.Search.Tabs.Oldest'):
          return new Date(prevJob?.createdAt) - new Date(nextJob?.createdAt);
        case t('dashboardComponents.Search.Tabs.JobNameAZ'):
          return prevJob?.jobTitle?.localeCompare(nextJob?.jobTitle);
        case t('dashboardComponents.Search.Tabs.JobNameZA'):
          return nextJob?.jobTitle?.localeCompare(prevJob?.jobTitle);

        default:
          return 0;
      }
    },
    calendar: (prevEvent, nextEvent) => {
      switch (activeAction?.label) {
        case t('dashboardComponents.Search.Tabs.Upcoming'):
          return (
            new Date(nextEvent?.eventDate) - new Date(prevEvent?.eventDate)
          );
        case t('dashboardComponents.Search.Tabs.Past'):
          return (
            new Date(prevEvent?.eventDate) - new Date(nextEvent?.eventDate)
          );
        case t('dashboardComponents.Search.Tabs.CandidateAZ'):
          return `${nextEvent?.userFirstName}${nextEvent?.userLastName}`?.localeCompare(
            `${prevEvent?.userFirstName}${prevEvent?.userLastName}`
          );
        case t('dashboardComponents.Search.Tabs.CandidateZA'):
          return `${prevEvent?.userFirstName}${prevEvent?.userLastName}`?.localeCompare(
            `${nextEvent?.userFirstName}${nextEvent?.userLastName}`
          );

        default:
          return 0;
      }
    },
  };

  const visibleCandidates = searchData?.candidates?.sort(sortList.candidates);
  const visibleJobs = searchData?.jobs?.sort(sortList.jobs);
  let visibleCalendarEvents = events?.sort(sortList.calendar);

  if (['Past', 'Upcoming'].includes(activeAction?.label)) {
    const dateToday = new Date(
      new Date().toLocaleString('en-US', {
        timeZone: `${user?.userProfile?.timezone || 'Turkey'}`,
      })
    );
    const day = dateToday.getDate();
    const month = dateToday.getMonth();
    const year = dateToday.getFullYear();

    visibleCalendarEvents = visibleCalendarEvents?.filter((event) => {
      const eventDate = new Date(event?.eventDate);
      const isToday =
        day === eventDate.getDate() &&
        month === eventDate.getMonth() &&
        year === eventDate.getFullYear();

      if (activeAction?.label === 'Upcoming') {
        return dateToday < eventDate || isToday;
      }

      if (activeAction?.label === 'Past') {
        return dateToday > eventDate && !isToday;
      }

      return false;
    });
  }

  const tabs = [
    { text: 'Candidates', amount: visibleCandidates?.length || 0 },
    { text: 'Jobs', amount: visibleJobs?.length || 0 },
    { text: 'Calendar', amount: visibleCalendarEvents?.length || 0 },
  ];

  const handleClick = (tab) => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    if (activeDropDownOption === 'All') {
      return;
    }

    setCurrentTab(activeDropDownOption);
  }, [activeDropDownOption]);

  let matches;

  const noData =
    !visibleCandidates?.length &&
    !visibleJobs?.length &&
    !visibleCalendarEvents?.length;

  if (!isLoading) {
    if (activeDropDownOption === 'All') {
      matches = !noData
        ? t('dashboardComponents.Search.Tabs.searchInfo')
        : t('dashboardComponents.Search.Tabs.noMatch');
    }

    if (currentTab === 'Candidates' && activeDropDownOption !== 'All') {
      matches = visibleCandidates?.length
        ? `${visibleCandidates?.length} ${t(
            'dashboardComponents.Search.Tabs.matches'
          )}`
        : t('dashboardComponents.Search.Tabs.noMatch');
    }

    if (currentTab === 'Jobs' && activeDropDownOption !== 'All') {
      matches = visibleJobs?.length
        ? `${visibleJobs?.length} ${t(
            'dashboardComponents.Search.Tabs.matches'
          )}`
        : t('dashboardComponents.Search.Tabs.noMatch');
    }

    if (currentTab === 'Calendar' && activeDropDownOption !== 'All') {
      matches = visibleCalendarEvents?.length
        ? `${visibleCalendarEvents?.length} ${t(
            'dashboardComponents.Search.Tabs.matches'
          )}`
        : t('dashboardComponents.Search.Tabs.noMatch');
    }
  }

  return (
    <>
      <span className={classes.info}>
        {searchTerm.length
          ? matches
          : t('dashboardComponents.Search.Tabs.searchInfo')}
      </span>
      {!noData && (
        <div className={classes.menu}>
          {activeDropDownOption === 'All' && (
            <div className={classes.tabs}>
              {tabs.map(({ text, amount }) => (
                <div
                  className={classNames(classes.tab, {
                    [classes.tabActive]: currentTab === text,
                  })}
                  key={text}
                  onClick={() => handleClick(text)}
                >
                  <h2 className={classes.text}>{text}</h2>
                  <span className={classes.amount}>{amount}</span>
                </div>
              ))}
            </div>
          )}

          {matches !== t('dashboardComponents.Search.Tabs.noMatch') && (
            <div className={classes.form}>
              <Dropdown
                options={actions}
                activeOption={activeAction}
                setActiveOption={setActiveAction}
                width={180}
                style={{
                  border: 'none',
                }}
              />
              <Button
                variant="secondary"
                width={48}
                height={48}
                style={{
                  backgroundColor: 'white',
                }}
              />
            </div>
          )}
        </div>
      )}
      <div className={classes.tabInfo}>
        {currentTab === 'Candidates' && (
          <CandidatesTab candidates={visibleCandidates} refetch={refetch} />
        )}
        {currentTab === 'Jobs' && (
          <JobsTab jobs={visibleJobs} refetch={refetch} />
        )}
        {currentTab === 'Calendar' && (
          <CalendarTab events={visibleCalendarEvents} refetch={refetch} />
        )}
      </div>
    </>
  );
}
