import React from 'react';

import Delegates from './Delegates';
import VrAndAssessment from '../../../Console/JobManagement/JobTemplates/Template/VrAndAssessment';
import BasicInfo from '../../../Console/JobManagement/JobTemplates/Template/BasicInfo';
import classes from './styles.module.scss';

export default function JobForm({
  basicInfoFormik,
  vrFormik,
  currentFormStep,
  vrQuestions,
  setVrQuestions,
  useDefaultVrQuestions,
  setUseDefaultVrQuestions,
  isDelegated,
  setIsDelegated,
  delegatesFormik,
  isBasicInfoTriedToSubmit,
  setHasDuration,
  hasDuration,
  isDelegatesTriedToSubmit,
  isVrAndAssessmentTriedToSubmit,
  areVrQuestionsValid,
  assessments,
  setAssessments,
}) {
  return (
    <div className={classes.JobForm}>
      {currentFormStep === 1 && (
        <BasicInfo
          formik={basicInfoFormik}
          isEdit
          isTriedToSubmit={isBasicInfoTriedToSubmit}
        />
      )}
      {currentFormStep === 2 && (
        <VrAndAssessment
          formik={vrFormik}
          vrQuestions={vrQuestions}
          setVrQuestions={setVrQuestions}
          useDefaultVrQuestions={useDefaultVrQuestions}
          setUseDefaultVrQuestions={setUseDefaultVrQuestions}
          isJobPostForm
          isTriedToSubmit={isVrAndAssessmentTriedToSubmit}
          areVrQuestionsValid={areVrQuestionsValid}
          assessments={assessments}
          setAssessments={setAssessments}
        />
      )}
      {currentFormStep === 3 && (
        <Delegates
          isDelegated={isDelegated}
          setIsDelegated={setIsDelegated}
          delegatesFormik={delegatesFormik}
          hasDuration={hasDuration}
          setHasDuration={setHasDuration}
          isDelegatesTriedToSubmit={isDelegatesTriedToSubmit}
        />
      )}
    </div>
  );
}
