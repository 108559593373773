import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Tags from './Tags';
import Experience from './Experience';
import Skills from './Skills';
import Educations from './Education';
import NoticePeriods from './NoticePeriods';
import JobTypes from './JobTypes';
import Tabs from '../Tabs';

import classes from './styles.module.scss';

export default function RefDataManagement() {
  const [jobTypesCount, setJobCountTypes] = useState(0);
  const [noticePeriodCount, setNoticePeriodCount] = useState(0);
  const [educationCount, setEducationCount] = useState(0);
  const [skillCount, setSkillCount] = useState(0);
  const [experienceCount, setExperienceCount] = useState(0);
  const [tagCount, setTagCount] = useState(0);

  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(
    t('dashboardComponents.Console.RefDataManagement.jobType')
  );
  const tabs = [
    t('dashboardComponents.Console.RefDataManagement.jobType'),
    t('dashboardComponents.Console.RefDataManagement.experience'),
    t('dashboardComponents.Console.RefDataManagement.noticePeriod'),
    t('dashboardComponents.Console.RefDataManagement.education'),
    t('dashboardComponents.Console.RefDataManagement.skills'),
    t('dashboardComponents.Console.RefDataManagement.tags'),
  ];

  return (
    <div className={classes.RefDataManagement}>
      <Tabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        name="refData"
        tabs={tabs}
        countData={{
          [t('dashboardComponents.Console.RefDataManagement.jobType')]:
            jobTypesCount,
          [t('dashboardComponents.Console.RefDataManagement.experience')]:
            experienceCount,
          [t('dashboardComponents.Console.RefDataManagement.noticePeriod')]:
            noticePeriodCount,
          [t('dashboardComponents.Console.RefDataManagement.education')]:
            educationCount,
          [t('dashboardComponents.Console.RefDataManagement.skills')]:
            skillCount,
          [t('dashboardComponents.Console.RefDataManagement.tags')]: tagCount,
        }}
      />
      <div className={classes.container}>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.RefDataManagement.jobType')
                ? 'block'
                : 'none',
          }}
        >
          <JobTypes setItemCount={setJobCountTypes} />
        </div>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.RefDataManagement.experience')
                ? 'block'
                : 'none',
          }}
        >
          <Experience setItemCount={setExperienceCount} />
        </div>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.RefDataManagement.noticePeriod')
                ? 'block'
                : 'none',
          }}
        >
          <NoticePeriods setItemCount={setNoticePeriodCount} />
        </div>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.RefDataManagement.education')
                ? 'block'
                : 'none',
          }}
        >
          <Educations setItemCount={setEducationCount} />
        </div>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.RefDataManagement.skills')
                ? 'block'
                : 'none',
          }}
        >
          <Skills setItemCount={setSkillCount} />
        </div>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.RefDataManagement.tags')
                ? 'block'
                : 'none',
          }}
        >
          <Tags setItemCount={setTagCount} />
        </div>
      </div>
    </div>
  );
}
