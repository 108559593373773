export default function convertDropdownValues(values) {
  const newValues = { ...values };

  Object.keys(newValues).forEach((key) => {
    if (Array.isArray(newValues[key]) && newValues[key]?.[0]?.value) {
      newValues[key] = newValues[key].map((val) => val.value);
    } else if (typeof newValues[key] === 'object' && newValues[key]?.value) {
      newValues[key] = newValues[key]?.value;
    }
  });

  Object.keys(newValues).forEach((key) => {
    if (newValues[key] === '') {
      delete newValues[key];
    }
  });

  return newValues;
}
