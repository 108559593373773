import React, { useState, useEffect } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import AssessmentQuestions from './AssessmentQuestions';
import TextInput from '../../../../../Form/TextInput';
import Textarea from '../../../../../Form/Textarea';
import useSwitchableData from '../../../../../../hooks/useSwitchableData';
import SidePopup from '../../../SidePopup';
import classes from './styles.module.scss';
import AdminService from '../../../../../../services/AdminService';
import flattenPaginatedData from '../../../../../../helpers/flattenPaginatedData';

export default function ViewAssessment({
  isVisible,
  handleClose,
  currentDataId,
  data,
  showEditDataSingle,
  isOverlayed,
  deleteData,
}) {
  const [isAssessmentQuestionsVisible, setIsAssessmentQuestionsVisible] =
    useState(false);

  const { t } = useTranslation();

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableData(data, currentDataId);

  const { data: questionsData } = useInfiniteQuery({
    queryKey: ['assessmentQuestions', data, currentDataIndex, isOverlayed],
    queryFn: ({ pageParam = 1 }) =>
      AdminService.getAssessmentQuestions({
        assessmentId: data?.[currentDataIndex]?.id,
        pageNumber: pageParam,
      }),
    enabled: !!data?.[currentDataIndex]?.id,
  });

  const questions = flattenPaginatedData(questionsData)?.sort(
    (a, b) => a.sortOrder - b.sortOrder
  );

  useEffect(() => {
    if (!isVisible) {
      setIsAssessmentQuestionsVisible(false);
    }
  }, [isVisible]);

  const questionCount = `${data?.[currentDataIndex]?.questionCount}` || '0';

  return (
    <SidePopup
      leftButtonVariant="delete"
      isVisible={isVisible}
      handleClose={handleClose}
      title={
        isAssessmentQuestionsVisible
          ? data?.[currentDataIndex]?.name
          : t(
              'dashboardComponents.Console.JobManagement.Assessments.ViewAssessment.viewAssessment'
            )
      }
      switchNext={data?.length > 1 && switchToNextDataElement}
      switchPrev={data?.length > 1 && switchToPrevDataElement}
      leftButtonLabel={t('common.delete')}
      rightButtonLabel={t('common.edit')}
      onRightButtonClick={() => {
        showEditDataSingle(data?.[currentDataIndex]?.id);
        setIsAssessmentQuestionsVisible(false);
      }}
      isOverlayed={isOverlayed}
      onLeftButtonClick={() => deleteData(data?.[currentDataIndex]?.id)}
    >
      <div className={classes.ViewAssessment}>
        <div className={classes.questionCount}>
          <span>
            {t(
              'dashboardComponents.Console.JobManagement.Assessments.ViewAssessment.numberOfQuestions'
            )}
          </span>
          <div className={classes.counter}>
            {questionCount.length === 1 ? `0${questionCount}` : questionCount}
          </div>
        </div>
        {isAssessmentQuestionsVisible ? (
          <AssessmentQuestions questions={questions} />
        ) : (
          <>
            <div className={classes.row}>
              <div className={classes.col}>
                <TextInput
                  height={50}
                  readonly
                  label={t(
                    'dashboardComponents.Console.JobManagement.Assessments.ViewAssessment.assessmentName'
                  )}
                  value={data?.[currentDataIndex]?.name}
                />
              </div>
              <div className={classes.col}>
                <Textarea
                  height={100}
                  readonly
                  label={t(
                    'dashboardComponents.Console.JobManagement.Assessments.ViewAssessment.notes'
                  )}
                  value={data?.[currentDataIndex]?.note}
                />
              </div>
            </div>
            {questionCount > 0 && (
              <div className={classes.buttonContainer}>
                <button
                  type="button"
                  onClick={() => setIsAssessmentQuestionsVisible(true)}
                >
                  {t(
                    'dashboardComponents.Console.JobManagement.Assessments.ViewAssessment.viewQuestions'
                  )}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </SidePopup>
  );
}
