import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import DateRange from '../../../../../Form/DateRange';
import FormDropdown from '../../../../../Form/FormDropdown';
import SectionTitle from '../../../../Console/JobManagement/JobTemplates/Template/VrAndAssessment/SectionTitle';
import RadioButton from '../../../../../Form/RadioButton';
import ChipsDropdown from '../../../../../Form/ChipsDropdown';
import classes from './styles.module.scss';
import AdminService from '../../../../../../services/AdminService';
import ChipsReadonly from '../../../../../Form/ChipsReadonly';

export default function Delegates({
  delegatesFormik,
  isDelegatesTriedToSubmit,
  readOnly,
}) {
  const { t } = useTranslation();

  const toggleDelegate = () => {
    if (delegatesFormik.values.isDelegated) {
      delegatesFormik.setFieldValue('delegate', '');
      delegatesFormik.setFieldValue('endsAt', '');
      delegatesFormik.setFieldValue('startsAt', '');
      delegatesFormik.setFieldValue('isDelegated', false);
    } else {
      delegatesFormik.setFieldValue('isDelegated', true);
    }
  };

  return (
    <div className={classes.Delegates}>
      <div className={classes.col}>
        {readOnly ? (
          <ChipsReadonly
            values={delegatesFormik?.values?.interviewers}
            height={180}
            label={t(
              'dashboardComponents.Jobs.PostAJob.JobForm.Delegates.interviewers.label'
            )}
            maxLength={5}
            info={t(
              'dashboardComponents.Jobs.PostAJob.JobForm.Delegates.interviewers.info'
            )}
            infoWidth={230}
            direction="bottom-right"
            note="Optional"
            name="interviewers"
            setFieldValue={delegatesFormik.setFieldValue}
          />
        ) : (
          <ChipsDropdown
            value={delegatesFormik.values.interviewers}
            height={180}
            label={t(
              'dashboardComponents.Jobs.PostAJob.JobForm.Delegates.interviewers.label'
            )}
            maxLength={5}
            info={t(
              'dashboardComponents.Jobs.PostAJob.JobForm.Delegates.interviewers.info'
            )}
            infoWidth={230}
            direction="bottom-right"
            note={t('common.optional')}
            fetchOptions={{
              dataName: 'usersOptionsDelegates',
              getDataHandler: AdminService.getUsers,
              labelName: 'userName',
              isUserList: true,
            }}
            name="interviewers"
            setFieldValue={delegatesFormik.setFieldValue}
          />
        )}
      </div>
      <div className={classes.col}>
        <div className={classes.header}>
          <SectionTitle
            style={{
              fontSize: 14,
            }}
            title={t(
              'dashboardComponents.Jobs.PostAJob.JobForm.Delegates.delegate.title'
            )}
            info={t(
              'dashboardComponents.Jobs.PostAJob.JobForm.Delegates.delegate.info'
            )}
            infoWidth={230}
          />
          <span className={classes.optional}>({t('common.optional')})</span>
        </div>
        <div className={classNames(classes.radios, classes.delegateRadios)}>
          <RadioButton
            labelStyle={{
              fontSize: 14,
            }}
            bigAndBold
            label={t('common.yes')}
            isSelected={delegatesFormik.values.isDelegated}
            onClick={toggleDelegate}
            readOnly={readOnly}
          />
          <RadioButton
            labelStyle={{
              fontSize: 14,
            }}
            bigAndBold
            label={t('common.no')}
            isSelected={!delegatesFormik.values.isDelegated}
            onClick={toggleDelegate}
            readOnly={readOnly}
          />
        </div>
        {delegatesFormik.values.isDelegated && (
          <div className={classes.delegate}>
            <FormDropdown
              readOnly={readOnly}
              label={t(
                'dashboardComponents.Jobs.PostAJob.JobForm.Delegates.formDropdown.label'
              )}
              fetchOptions={{
                dataName: 'usersOptionsDelegates',
                getDataHandler: AdminService.getUsers,
                labelName: 'userName',
                isUserList: true,
              }}
              value={delegatesFormik.values.delegate}
              setFieldValue={delegatesFormik.setFieldValue}
              name="delegate"
              width={368}
              height={45}
              placeholder={t(
                'dashboardComponents.Jobs.PostAJob.JobForm.Delegates.formDropdown.placeholder'
              )}
              touched={isDelegatesTriedToSubmit}
              error={delegatesFormik.errors.delegate}
            />
          </div>
        )}

        {delegatesFormik.values.isDelegated &&
          (!readOnly || delegatesFormik?.values.startsAt) && (
            <div className={classes.duration}>
              <DateRange
                height={45}
                readOnly={readOnly}
                toName="endsAt"
                fromName="startsAt"
                toValue={delegatesFormik?.values.endsAt}
                fromValue={delegatesFormik?.values.startsAt}
                setFieldValue={delegatesFormik?.setFieldValue}
                error={delegatesFormik?.errors.startsAt}
                touched={isDelegatesTriedToSubmit}
                setFieldTouched={delegatesFormik?.setFieldTouched}
              />
            </div>
          )}
      </div>
    </div>
  );
}
