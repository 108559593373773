/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useRef } from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import useDebouncedSearchWithHistory from '../../../hooks/useDebouncedSearchWithHistory';
import AdminService from '../../../services/AdminService';
import { getEvents } from '../../../helpers/getEvents';

import Dropdown from '../../Form/Dropdown';
import SearchInput from '../../Form/SearchInput';
import Calendar from './Calendar';
import Candidates from './Candidates';
import Jobs from './Jobs';

import classes from './styles.module.scss';
import UserService from '../../../services/UserService';

export default function Favorites({
  hidePopup,
  invite,
  favorite,
  job,
  refetchJob,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeAction, setActiveAction] = useState('');
  const [currentTab, setCurrentTab] = useState('');
  const [favCandidates, setFavCandidates] = useState([]);
  const [invitedCandidates, setInvitedCandidates] = useState([]);
  const [favEvents, setFavEvents] = useState([]);

  const componentRef = useRef(null);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const { data: candidates, refetch: refetchCandidates } = useQuery({
    queryKey: ['candidates'],
    queryFn: () =>
      AdminService.getCandidates({
        term: '',
      }),
    keepPreviousData: true,
  });

  const { data: jobPages, refetch: refetchJobs } = useQuery({
    queryKey: ['favJobs'],
    queryFn: () =>
      AdminService.getMyJobs({ pageSize: 99999, isFavorite: true }),
    keepPreviousData: true,
  });

  const favJobs = jobPages?.data;

  const { data: jobApplications, refetch: refetchEvents } = useQuery({
    queryKey: ['events'],
    queryFn: AdminService.getJobApplicationsSchedule,
  });

  const allEvents = useMemo(
    () =>
      getEvents(jobApplications, user?.id, null, user?.userProfile?.timezone),
    [jobApplications, user]
  );

  const { searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    'favoritesSearchHistory'
  );

  let tabs;

  if (invite) {
    tabs = [
      {
        tab: t('dashboardComponents.Favorites.allCandidates'),
        amount: candidates?.length,
      },
      {
        tab: t('dashboardComponents.Favorites.favorites'),
        amount: favCandidates?.length,
      },
      {
        tab: t('dashboardComponents.Favorites.invited'),
        amount: invitedCandidates?.length,
      },
    ];
  }

  if (favorite) {
    tabs = [
      {
        tab: t('dashboardComponents.Favorites.candidates'),
        amount: favCandidates?.length,
      },
      {
        tab: t('dashboardComponents.Favorites.jobs'),
        amount: favJobs?.length,
      },
      {
        tab: t('dashboardComponents.Favorites.calendar'),
        amount: favEvents?.length,
      },
    ];
  }

  useEffect(() => {
    if (favorite) {
      setCurrentTab(t('dashboardComponents.Favorites.candidates'));

      return;
    }

    setCurrentTab(t('dashboardComponents.Favorites.allCandidates'));
  }, [favorite]);

  let actions = [];

  if (
    currentTab === t('dashboardComponents.Favorites.candidates') ||
    currentTab === t('dashboardComponents.Favorites.favorites') ||
    currentTab === t('dashboardComponents.Favorites.allCandidates') ||
    currentTab === t('dashboardComponents.Favorites.invited')
  ) {
    actions = [
      {
        label: t('dashboardComponents.Favorites.recent'),
        value: 'recent',
      },
      {
        label: t('dashboardComponents.Favorites.oldest'),
        value: 'oldest',
      },
      {
        label: t('dashboardComponents.Favorites.candidateAZ'),
        value: 'candidate: a-z',
      },
      {
        label: t('dashboardComponents.Favorites.candidateZA'),
        value: 'candidate: z-a',
      },
    ];
  }

  if (currentTab === t('dashboardComponents.Favorites.jobs')) {
    actions = [
      {
        label: t('dashboardComponents.Favorites.recent'),
        value: 'recent',
      },
      {
        label: t('dashboardComponents.Favorites.oldest'),
        value: 'oldest',
      },
      {
        label: t('dashboardComponents.Favorites.jobNameAZ'),
        value: 'Job Name: a-z',
      },
      {
        label: t('dashboardComponents.Favorites.jobNameZA'),
        value: 'Job Name: z-a',
      },
    ];
  }

  if (currentTab === t('dashboardComponents.Favorites.calendar')) {
    actions = [
      {
        label: t('dashboardComponents.Favorites.upcoming'),
        value: 'upcoming',
      },
      {
        label: t('dashboardComponents.Favorites.past'),
        value: 'past',
      },
      {
        label: t('dashboardComponents.Favorites.candidateAZ'),
        value: 'candidate: a-z',
      },
      {
        label: t('dashboardComponents.Favorites.candidateZA'),
        value: 'candidate: z-a',
      },
    ];
  }

  useEffect(() => {
    setActiveAction(actions[0]);
  }, [currentTab]);

  const sortList = {
    candidates: (prevCandidate, nextCandidate) => {
      switch (activeAction?.label) {
        case t('dashboardComponents.Favorites.recent'):
          return (
            new Date(prevCandidate?.createdAt) -
            new Date(nextCandidate?.createdAt)
          );
        case t('dashboardComponents.Favorites.oldest'):
          return (
            new Date(nextCandidate?.createdAt) -
            new Date(prevCandidate?.createdAt)
          );
        case t('dashboardComponents.Favorites.candidateAZ'):
          return `${nextCandidate?.userProfile?.firstName}${nextCandidate?.userProfile?.lastName}`?.localeCompare(
            `${prevCandidate?.userProfile?.firstName}${prevCandidate?.userProfile?.lastName}`
          );
        case t('dashboardComponents.Favorites.candidateZA'):
          return `${prevCandidate?.userProfile?.firstName}${prevCandidate?.userProfile?.lastName}`?.localeCompare(
            `${nextCandidate?.userProfile?.firstName}${nextCandidate?.userProfile?.lastName}`
          );

        default:
          return 0;
      }
    },
    jobs: (prevJob, nextJob) => {
      switch (activeAction?.label) {
        case t('dashboardComponents.Favorites.recent'):
          return new Date(prevJob?.createdAt) - new Date(nextJob?.createdAt);
        case t('dashboardComponents.Favorites.oldest'):
          return new Date(nextJob?.createdAt) - new Date(prevJob?.createdAt);
        case t('dashboardComponents.Favorites.jobNameAZ'):
          return nextJob?.jobTitle.localeCompare(prevJob?.jobTitle);
        case t('dashboardComponents.Favorites.jobNameZA'):
          return prevJob?.jobTitle.localeCompare(nextJob?.jobTitle);

        default:
          return 0;
      }
    },
    calendar: (prevEvent, nextEvent) => {
      switch (activeAction?.label) {
        case t('dashboardComponents.Favorites.upcoming'):
          return (
            new Date(nextEvent?.eventDate) - new Date(prevEvent?.eventDate)
          );
        case t('dashboardComponents.Favorites.past'):
          return (
            new Date(prevEvent?.eventDate) - new Date(nextEvent?.eventDate)
          );
        case t('dashboardComponents.Favorites.candidateAZ'):
          return `${nextEvent?.userFirstName}${nextEvent?.userLastName}`?.localeCompare(
            `${prevEvent?.userFirstName}${prevEvent?.userLastName}`
          );
        case t('dashboardComponents.Favorites.candidateZA'):
          return `${prevEvent?.userFirstName}${prevEvent?.userLastName}`?.localeCompare(
            `${nextEvent?.userFirstName}${nextEvent?.userLastName}`
          );

        default:
          return 0;
      }
    },
  };

  useEffect(() => {
    setFavCandidates(candidates?.filter((candidate) => candidate.isFavorite));
    setInvitedCandidates(
      candidates?.filter((candidate) =>
        candidate.jobApplications.find(
          (application) =>
            application.jobPost?.id === job?.id &&
            application.status === 'Invited'
        )
      )
    );
  }, [candidates, job?.id]);

  useEffect(() => {
    setFavEvents(allEvents?.filter((event) => event?.isFavorite));
  }, [allEvents]);

  const visibleFavCandidates = favCandidates
    ?.filter(
      (favCandidate) =>
        favCandidate?.userProfile?.firstName
          .concat(' ', favCandidate?.userProfile?.lastName)
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase()) ||
        !favCandidate?.userProfile
    )
    .sort(sortList?.candidates);

  const visibleAllCandidates = candidates
    ?.filter(
      (candidate) =>
        candidate?.userProfile?.firstName
          .concat(' ', candidate?.userProfile?.lastName)
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase()) || !candidate?.userProfile
    )
    .sort(sortList?.candidates);

  const visibleInvitedCandidates = invitedCandidates
    ?.filter(
      (candidate) =>
        candidate?.userProfile?.firstName
          .concat(' ', candidate?.userProfile?.lastName)
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase()) || !candidate?.userProfile
    )
    .sort(sortList?.candidates);

  let visibleFavEvents = favEvents
    ?.filter((calendarEvent) => {
      return `${calendarEvent.type} for ${calendarEvent.jobPost.jobTitle}`
        .toLowerCase()
        .includes(searchTerm.trim().toLowerCase());
    })
    .sort(sortList?.calendar);

  if (
    [
      t('dashboardComponents.Favorites.past'),
      t('dashboardComponents.Favorites.upcoming'),
    ].includes(activeAction?.label)
  ) {
    const dateToday = new Date(
      new Date().toLocaleString('en-US', {
        timeZone: `${user?.userProfile?.timezone || 'Turkey'}`,
      })
    );
    const day = dateToday.getDate();
    const month = dateToday.getMonth();
    const year = dateToday.getFullYear();

    visibleFavEvents = visibleFavEvents?.filter((event) => {
      const eventDate = new Date(event?.eventDate);
      const isToday =
        day === eventDate.getDate() &&
        month === eventDate.getMonth() &&
        year === eventDate.getFullYear();

      if (activeAction?.label === 'Upcoming') {
        return dateToday < eventDate || isToday;
      }

      if (activeAction?.label === 'Past') {
        return dateToday > eventDate && !isToday;
      }

      return false;
    });
  }

  const visibleFavJobs = favJobs
    ?.filter((favJob) =>
      favJob.jobTitle.toLowerCase().includes(searchTerm.trim().toLowerCase())
    )
    .sort(sortList?.jobs);

  useEffect(() => {
    setSearchTerm('');
  }, [currentTab]);

  const handleClose = () => {
    hidePopup();
  };

  const handleClick = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <>
      <div className={classes.overlay} onClick={handleClose} />
      <div className={classes.Favorites}>
        <div className={classes.container} ref={componentRef}>
          <div className={classes.header}>
            <div className={classes.close} onClick={handleClose} />
            <h1 className={classes.title}>
              {favorite
                ? t('dashboardComponents.Favorites.favorites')
                : t('dashboardComponents.Favorites.inviteCandidates')}
            </h1>
            <div className={classes.menu}>
              <div className={classes.tabs}>
                {tabs.map(({ tab, amount }) => (
                  <div
                    className={classNames(classes.tab, {
                      [classes.tabActive]: currentTab === tab,
                    })}
                    key={tab}
                    onClick={() => handleClick(tab)}
                  >
                    <h2 className={classes.text}>{tab}</h2>
                    <span className={classes.amount}>{amount}</span>
                  </div>
                ))}
              </div>

              <div className={classes.form}>
                <SearchInput
                  value={searchTerm}
                  setValue={setSearchTerm}
                  searchHistory={searchHistory}
                  width={279}
                  height={45}
                  backgroundColor="#F2F4F8"
                  style={{
                    border: 'none',
                  }}
                />

                <Dropdown
                  options={actions}
                  activeOption={activeAction}
                  setActiveOption={setActiveAction}
                  width={isTabletOrMobile ? 139 : 180}
                  height={45}
                  style={{
                    backgroundColor: '#F2F4F8',
                    border: 'none',
                  }}
                />
              </div>
            </div>
          </div>

          {(currentTab === t('dashboardComponents.Favorites.candidates') ||
            currentTab === t('dashboardComponents.Favorites.favorites')) && (
            <Candidates
              candidates={visibleFavCandidates}
              refetch={() => {
                refetchCandidates();
                refetchJob?.();
              }}
              favorite={
                currentTab === t('dashboardComponents.Favorites.candidates')
              }
              job={job}
            />
          )}
          {currentTab === t('dashboardComponents.Favorites.allCandidates') && (
            <Candidates
              candidates={visibleAllCandidates}
              refetch={() => {
                refetchCandidates();
                refetchJob?.();
              }}
              job={job}
            />
          )}
          {currentTab === t('dashboardComponents.Favorites.invited') && (
            <Candidates
              candidates={visibleInvitedCandidates}
              refetch={() => {
                refetchCandidates();
                refetchJob?.();
              }}
              job={job}
            />
          )}
          {currentTab === t('dashboardComponents.Favorites.jobs') && (
            <Jobs
              favJobs={visibleFavJobs}
              refetch={refetchJobs}
              handleClose={handleClose}
            />
          )}
          {currentTab === t('dashboardComponents.Favorites.calendar') && (
            <Calendar
              events={visibleFavEvents}
              handleClose={handleClose}
              refetch={refetchEvents}
            />
          )}
        </div>
      </div>
    </>
  );
}
