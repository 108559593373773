import React, { useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function SearchInput({
  value,
  setValue,
  searchHistory,
  backgroundColor,
  width,
  style,
  height,
  noBorder,
}) {
  const [isFocused, setIsFocused] = useState(false);

  const { t } = useTranslation();

  return (
    <div
      className={classNames(classes.SearchInput, {
        [classes.focused]: isFocused,
      })}
      style={{
        backgroundColor,
        width,
        height,
        border: noBorder ? 'none' : '',
        ...style,
      }}
    >
      <input
        style={style}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setTimeout(() => {
            setIsFocused(false);
          }, 150);
        }}
        type="text"
        placeholder={t('common.search')}
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />

      {isFocused && (
        <i className={classes.clearIcon} onMouseDown={() => setValue('')}>
          Clear
        </i>
      )}
      {isFocused && searchHistory.length ? (
        <ul className={classes.searchHistory}>
          {searchHistory.map((record) => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li key={record} onMouseDown={() => setValue(record)}>
              {record}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}
