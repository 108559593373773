import React from 'react';

import { useTranslation } from 'react-i18next';

import TextInput from '../../../../../../../Form/TextInput';

import classes from './styles.module.scss';

export default function ReadonlyQuestions({ questions }) {
  const { t } = useTranslation();

  return (
    <div className={classes.ReadonlyQuestions}>
      {questions.map((question) => {
        return (
          <div key={question.id} className={classes.question}>
            <div className={classes.row}>
              <div className={classes.col}>
                <TextInput
                  height={50}
                  readonly
                  label={t(
                    'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.ReadonlyQuestions.VRQuestionTitleLabel'
                  )}
                  value={question.title}
                />
              </div>
              <div className={classes.col}>
                <TextInput
                  width={139}
                  height={50}
                  readonly
                  label={t(
                    'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.ReadonlyQuestions.DurationLabel'
                  )}
                  value={question.duration}
                  centerText
                />
              </div>
            </div>
            <div className={classes.row}>
              <TextInput
                width="100%"
                height={50}
                readonly
                label={t(
                  'dashboardComponents.Console.JobManagement.JobTemplates.Template.VrAndAssessment.ReadonlyQuestions.VRQuestionLabel'
                )}
                value={question.question}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
