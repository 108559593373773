import React, { useContext, useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import EventDetails from '../../../Calendar/EventDetails';
import CalendarEventCard from './CalendarEventCard';

import { SearchContext } from '../../../../../context/SearchContext';
import classes from './styles.module.scss';
import { getEventFromSearch } from '../../../../../helpers/getEvents';

export default function Calendar({
  searchTerm,
  calendarEvents,
  activeTab,
  user,
  refetch,
  closeSearchPopup,
}) {
  const [isEventDetailsVisible, setIsEventDetailsVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const {
    setSearchTerm,
    setActiveDropDownOption,
    setIsOpenedSearchScreen,
    setActiveSearchScreenTab,
  } = useContext(SearchContext);

  const { t } = useTranslation();

  const events = getEventFromSearch(
    calendarEvents,
    user?.id,
    user?.userProfile?.timezone
  );

  return (
    <div
      className={classNames(classes.Calendar, {
        [classes.noBorderTop]:
          activeTab !== t('dashboardComponents.Header.Search.Calendar.All'),
      })}
    >
      {activeTab === t('dashboardComponents.Header.Search.Calendar.All') && (
        <span className={classes.title}>
          {t('dashboardComponents.Header.Search.Calendar.title')}
        </span>
      )}
      {events?.length === 0 && (
        <span className={classes.noMatch}>
          {t('dashboardComponents.Header.Search.Calendar.noMatch')}
        </span>
      )}
      {activeTab === t('dashboardComponents.Header.Search.Calendar.All') ? (
        <>
          <div className={classes.events}>
            {events?.slice(0, 2).map((calendarEvent) => (
              <CalendarEventCard
                calendarEvent={calendarEvent}
                setIsEventDetailsVisible={setIsEventDetailsVisible}
                setSelectedEvent={setSelectedEvent}
                key={`${calendarEvent?.type}${calendarEvent?.id}`}
                userId={user?.id}
                refetch={refetch}
              />
            ))}
          </div>
          {events?.length > 2 && (
            <Link
              to="/tools/search"
              className={classes.seeAll}
              onClick={() => {
                setActiveDropDownOption('All');
                setSearchTerm(searchTerm);
                setActiveSearchScreenTab('Calendar');
                setIsOpenedSearchScreen(true);
                closeSearchPopup();
              }}
            >
              {t('dashboardComponents.Header.Search.Calendar.seeAllEvents')}
            </Link>
          )}
        </>
      ) : (
        <>
          <div className={classNames(classes.events)}>
            {events?.slice(0, 6).map((calendarEvent) => (
              <CalendarEventCard
                calendarEvent={calendarEvent}
                setIsEventDetailsVisible={setIsEventDetailsVisible}
                setSelectedEvent={setSelectedEvent}
                key={`${calendarEvent?.type}${calendarEvent?.id}`}
                userId={user?.id}
                refetch={refetch}
              />
            ))}
          </div>
          {events?.length > 6 && (
            <Link
              to="/tools/search"
              className={classes.seeAll}
              onClick={() => {
                setActiveDropDownOption('Calendar');
                setSearchTerm(searchTerm);
                setIsOpenedSearchScreen(true);
                closeSearchPopup();
              }}
            >
              {t('dashboardComponents.Header.Search.Calendar.seeAllEvents')}
            </Link>
          )}
        </>
      )}

      {isEventDetailsVisible && (
        <EventDetails
          isEventDetailsVisible={isEventDetailsVisible}
          handleClose={() => setIsEventDetailsVisible(false)}
          event={selectedEvent}
          // currentUser={user}
          refetch={refetch}
        />
      )}
    </div>
  );
}
