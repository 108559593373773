import React, { useRef, useState, useEffect } from 'react';

import classNames from 'classnames';
import classes from './styles.module.scss';

export default function Slider({ data }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const sliderContainerRef = useRef(null);
  const dataContainerRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const width = sliderContainerRef.current.clientWidth;

      dataContainerRef.current.scrollBy({
        left: width,
        behavior: 'smooth',
      });

      if (
        dataContainerRef.current.scrollLeft + width >=
        data.length * width - 70
      ) {
        setCurrentIndex(0);
        setTimeout(() => {
          dataContainerRef.current.scrollBy({
            left: -data.length * width,
            behavior: 'instant',
          });
        }, 600);
        return;
      }

      setCurrentIndex(
        Math.round(dataContainerRef?.current?.scrollLeft / width) + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex, data]);

  const setItem = (index) => {
    const width = sliderContainerRef.current.clientWidth;
    dataContainerRef.current.scrollBy({
      left:
        index > currentIndex
          ? Math.abs(index - currentIndex) * width
          : Math.abs(index - currentIndex) * -width,
      behavior: 'smooth',
    });
    setCurrentIndex(index);
  };

  let items;
  if (data) {
    items = [...data, data[0]];
  }

  return (
    <div className={classes.Slider} ref={sliderContainerRef}>
      <div className={classes.container} ref={dataContainerRef}>
        {items?.map((item) => (
          <a
            draggable="false"
            href={item.link}
            target="_blank"
            rel="noreferrer"
            key={`${item.id} ${Math.random()}`}
          >
            <img src={item.imageUrl} alt="ad" draggable="false" />
          </a>
        ))}
      </div>
      <div className={classes.dots}>
        {data?.map((item, index) => (
          <span
            className={classNames(classes.dot, {
              [classes.current]: currentIndex === index,
            })}
            onClick={() => setItem(index)}
            key={item.id}
          />
        ))}
      </div>
    </div>
  );
}
