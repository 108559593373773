/* eslint-disable no-shadow */
import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Template from '../Template';

export default function Feed({
  classes,
  isRead,
  deleteNotification,
  notification,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const createNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const navigateToFeedPost = () => {
      navigate(`/tools/discover/posts/${notification?.feedPost?.id}`);
      hideNotifications();
    };

    const navigateToFeedPostComment = () => {
      navigate(
        `/tools/discover/posts/${notification?.feedPost?.id}?commentId=${notification?.feedPostCommentId}`
      );
      hideNotifications();
    };

    const content = {
      'notitification.feed.new_comment': {
        title: `${notification.eventNote} ${t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedNewComment.title'
        )}${
          notification?.feedPost?.title
            ? ` "${notification?.feedPost?.title}"`
            : ''
        }`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedNewComment.buttonLabel'
        ),
        onClick: navigateToFeedPostComment,
      },
      'notitification.feed.new_comment_reply': {
        title: `${notification.eventNote} ${t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedNewCommentReply.title'
        )}${
          notification?.feedPost?.title
            ? ` "${notification?.feedPost?.title}"`
            : ''
        }`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedNewCommentReply.buttonLabel'
        ),
        onClick: navigateToFeedPostComment,
      },
      'notitification.feed.mention.comment': {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedMentionComment.title'
        )}${
          notification?.feedPost?.title
            ? `: "${notification?.feedPost?.title}"`
            : ''
        }`,
        text: `${notification?.feedPost?.author?.userProfile?.firstName} ${notification?.feedPost?.author?.userProfile?.lastName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedMentionComment.buttonLabel'
        ),
        onClick: navigateToFeedPostComment,
      },
      'notitification.feed.new_post': {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedNewPost.title'
        )}${
          notification?.feedPost?.title
            ? `: "${notification?.feedPost?.title}"`
            : ''
        }`,
        text: `${notification?.feedPost?.author?.userProfile?.firstName} ${notification?.feedPost?.author?.userProfile?.lastName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedNewPost.buttonLabel'
        ),
        onClick: navigateToFeedPost,
      },
      'notitification.feed.mention': {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedMention.title'
        )}${
          notification?.feedPost?.title
            ? `: "${notification?.feedPost?.title}"`
            : ''
        }`,
        details: notification.feedPost?.content,
        text: `${notification?.feedPost?.author?.userProfile?.firstName} ${notification?.feedPost?.author?.userProfile?.lastName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedMention.buttonLabel'
        ),
        onClick: navigateToFeedPost,
      },
      'notitification.feed.new_post_appreciation_others': {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedNewPostAppreciationOthers.title'
        )} ${notification.eventNote.split(' ')[0]} ${
          notification.eventNote.split(' ')[1]
        } ${t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedNewPostAppreciationOthers.title2'
        )}`,
        text: `${notification.eventNote.split(' ')[0]} ${
          notification.eventNote.split(' ')[1]
        }`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedNewPostAppreciationOthers.buttonLabel'
        ),
        onClick: navigateToFeedPost,
      },
      'notitification.feed.new_post_announcement': {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedNewPostAnnouncement.title'
        )}${
          notification?.feedPost?.title
            ? `: "${notification?.feedPost?.title}"`
            : ''
        }`,
        text: `${notification?.feedPost?.author?.userProfile?.firstName} ${notification?.feedPost?.author?.userProfile?.lastName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedNewPostAnnouncement.buttonLabel'
        ),
        onClick: navigateToFeedPost,
      },
      'notitification.feed.post_poll_started': {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedPostPollStarted.title'
        )}${
          notification?.feedPost?.title
            ? `: "${notification?.feedPost?.title}"`
            : ''
        }`,
        text: `${notification?.feedPost?.author?.userProfile?.firstName} ${notification?.feedPost?.author?.userProfile?.lastName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedPostPollStarted.buttonLabel'
        ),
        onClick: navigateToFeedPost,
      },
      'notitification.feed.post_poll_voted': {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedPostPollVoted.title'
        )}${
          notification?.feedPost?.title
            ? `: "${notification?.feedPost?.title}"`
            : ''
        }`,
        text: `${notification?.feedPost?.author?.userProfile?.firstName} ${notification?.feedPost?.author?.userProfile?.lastName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedPostPollVoted.buttonLabel'
        ),
        onClick: navigateToFeedPost,
      },
      'notitification.feed.post_poll_stopped': {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedPostPollStopped.title'
        )}${
          notification?.feedPost?.title
            ? `: "${notification?.feedPost?.title}"`
            : ''
        }`,
        text: `${notification?.feedPost?.author?.userProfile?.firstName} ${notification?.feedPost?.author?.userProfile?.lastName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedPostPollStopped.buttonLabel'
        ),
        onClick: navigateToFeedPost,
      },
      'notitification.feed.post_poll_resumed': {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedPostPollResumed.title'
        )}${
          notification?.feedPost?.title
            ? `: "${notification?.feedPost?.title}"`
            : ''
        }`,
        text: `${notification?.feedPost?.author?.userProfile?.firstName} ${notification?.feedPost?.author?.userProfile?.lastName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedPostPollResumed.buttonLabel'
        ),
        onClick: navigateToFeedPost,
      },
      'notitification.feed.new_post_appreciation_recipient': {
        title: t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedNewPostAppreciationRecipient.title'
        ),
        text: `${notification?.feedPost?.author?.userProfile?.firstName} ${notification?.feedPost?.author?.userProfile?.lastName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedNewPostAppreciationRecipient.buttonLabel'
        ),
        onClick: navigateToFeedPost,
      },
      'notitification.feed.new_post_appreciation_others_milestone': {
        title: t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedNewPostAppreciationOthersMilestone.title',
          {
            milestoneCount: notification?.feedPost?.milestoneCount,
          }
        ),
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Feed.notificationFeedNewPostAppreciationOthersMilestone.buttonLabel'
        ),
        onClick: navigateToFeedPost,
      },
    };

    return content[notification.eventKey];
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
