import moment from 'moment';

import i18next from '../i18n';

const t = i18next.t.bind(i18next);

export default function getTimeAgo(createdAt) {
  const today = new Date();

  const daysPassed = moment(today).diff(moment(createdAt), 'days');

  switch (true) {
    case daysPassed < 3:
      return 'New';
    case daysPassed >= 3 && daysPassed <= 7:
      return `${daysPassed} ${
        daysPassed > 1
          ? t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.days'
            )
          : t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.day'
            )
      }`;
    case daysPassed > 7 && daysPassed <= 28:
      return `${Math.floor(daysPassed / 7)} ${
        Math.floor(daysPassed / 7) > 1
          ? t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.weeks'
            )
          : t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.week'
            )
      }`;
    case daysPassed > 28 && daysPassed < 365:
      return `${Math.floor(daysPassed / 29)} ${
        Math.floor(daysPassed / 29) > 1
          ? t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.months'
            )
          : t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.month'
            )
      }`;
    default:
      return `${Math.floor(daysPassed / 365)} ${
        Math.floor(daysPassed / 365) > 1
          ? t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.years'
            )
          : t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.year'
            )
      }`;
  }
}
