import React from 'react';

import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Recommendation({ isRecommended }) {
  const { t } = useTranslation();

  return (
    <div className={classes.Recommendation}>
      {isRecommended ? (
        <div className={classNames(classes.container, classes.yes)}>
          <div className={classes.thumb} />
          <span>{t('common.yes')}</span>
        </div>
      ) : (
        <div className={classNames(classes.container, classes.no)}>
          <div className={classes.thumb} />
          <span>{t('common.no')}</span>
        </div>
      )}
    </div>
  );
}
