import React from 'react';

import classNames from 'classnames';

import NoFavorites from '../NoFavorites';
import CalendarEventCard from './CalendarEventCard';

import classes from './styles.module.scss';

export default function Calendar({ events, refetch }) {
  const currentDate = new Date();

  return (
    <div
      className={classNames(classes.Calendar, {
        [classes.noFavorites]: events?.length === 0,
      })}
    >
      {events?.length === 0 ? (
        <NoFavorites calendar />
      ) : (
        <>
          {events
            ?.map((favCalendarEvent) => (
              <CalendarEventCard
                favCalendarEvent={favCalendarEvent}
                currentDate={currentDate}
                key={`${favCalendarEvent.type}${favCalendarEvent?.id}`}
                refetch={refetch}
              />
            ))
            .reverse()}
        </>
      )}
    </div>
  );
}
