/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-handler-names */
import React, { useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import classes from './styles.module.scss';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import { useUiStore } from '../../../../../store';

export default function AddOptionsDropdown({ handleClose }) {
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, handleClose);

  const navigate = useNavigate();

  const toggleAddCndidatePopup = useUiStore(
    (state) => state.toggleAddCndidatePopup
  );

  const options = [
    {
      title: 'Post a Job',
      onClick: () => navigate('/tools/jobs/post-job'),
    },
    {
      title: 'Add Candidate',
      onClick: () => {
        navigate('/tools/candidates');
        toggleAddCndidatePopup();
      },
    },
  ];

  return (
    <ul className={classes.AddOptionsDropdown} ref={dropdownRef}>
      {options.map((option) => (
        <li
          key={option.title}
          onClick={() => {
            option?.onClick?.();
            handleClose();
          }}
        >
          {option.title}
        </li>
      ))}
    </ul>
  );
}
