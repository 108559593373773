import React, { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { toSvg } from 'html-to-image';
import { useReactToPrint } from 'react-to-print';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from '../../components/Button';
import classes from './styles.module.scss';
import QRCodeGenerator from '../../components/Dashboard/QRCodeGenerator';

export default function QRQModal({ show, handleClose, job }) {
  const [qrcodeImage, setQrcodeImage] = useState();
  const qrcodeRef = useRef(null);

  const { t } = useTranslation();

  const handlePrint = useReactToPrint({
    content: () => qrcodeImage,
    pageStyle: `@media print {
      @page {
        size: A4;
        margin: 0;
      }

      img {
        width: 600px !important;
        margin: 250px 0 0 90px !important;
      }
    }`,
  });

  const handleClick = () => {
    toSvg(qrcodeRef.current, { cacheBust: true }).then((dataUrl) => {
      const link = document.createElement('img');
      link.src = dataUrl;

      setQrcodeImage(link);
      handlePrint();
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.ShowQRQModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header>
          <h1>ZapKey</h1>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <div className={classes.container} ref={qrcodeRef}>
          <div className={classes.col}>
            <div className={classes.QRQContainer}>
              <div className={classes.QRQ}>
                <QRCodeGenerator
                  value={`https://kfc-jobs-portal.devmaxup.com/jobs/${job?.id}`}
                />
              </div>
              <span className={classes.jobTitle}>{job?.jobTitle}</span>
            </div>
          </div>
        </div>
        <footer>
          <Button
            onClick={() => {
              window.location.href = `mailto:?subject=&body=${`https://kfc-jobs-portal.devmaxup.com/jobs/${job?.id}`}`;
            }}
            width={295}
            height={52}
          >
            {t('modals.QRQModal.email')}
          </Button>
          <Button onClick={handleClick} width={295} height={52} variant="print">
            {t('modals.QRQModal.print')}
          </Button>
        </footer>
      </div>
    </Modal>
  );
}
