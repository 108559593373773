import { useCallback, useRef } from 'react';

export default function useIntersectionObserverPagination({
  isFetching,
  fetchNextPage,
}) {
  const observer = useRef();

  const lastListElementRef = useCallback(
    (node) => {
      if (isFetching) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchNextPage?.();
        }
      });
      if (node) observer.current.observe(node);
    },
    [fetchNextPage, isFetching]
  );

  return { lastListElementRef };
}
