import React from 'react';

import { useTranslation } from 'react-i18next';

import underMaintenanceIcon from '../../assets/images/underMaintenance.svg';
import classes from './styles.module.scss';

export default function UnderMaintenance() {
  const { t } = useTranslation();

  return (
    <div className={classes.UnderMaintenance}>
      <div className={classes.container}>
        <div className={classes.illustration}>
          <div className={classes.header}>
            <div className={classes.circle} />
            <div className={classes.circle} />
            <div className={classes.circle} />
          </div>
          <div className={classes.body}>
            <img
              src={underMaintenanceIcon}
              alt={t('miscComponents.UnderMaintenance.message')}
            />
          </div>
        </div>
        <div className={classes.message}>
          <h1>{t('miscComponents.UnderMaintenance.message')}</h1>
        </div>
      </div>
    </div>
  );
}
