import axios from 'axios';

import { AUTH_API_URL, ANONYMOUS_API_URL } from '../constants/main';

const UserService = {
  async checkAccount({ userName }) {
    const response = await axios.post(`${AUTH_API_URL}/checkaccess`, {
      userName,
    });
    return response.data;
  },

  async loginByPassword({ email, password }) {
    const response = await axios.post(`${AUTH_API_URL}/passwordlogin`, {
      username: email,
      password,
    });
    return response.data;
  },

  async requestOtp({ email }) {
    const response = await axios.post(`${AUTH_API_URL}/emailsignin`, {
      email,
    });
    return response.data;
  },

  async loginByOtp({ email, code }) {
    const response = await axios.post(`${AUTH_API_URL}/otplogin`, {
      userName: email,
      code,
    });
    return response.data;
  },

  async getMe() {
    const response = await axios.get(`${AUTH_API_URL}/userinfo`);
    return response.data;
  },

  async requestAccess({ email, firstName, lastName, cities, locations }) {
    const response = await axios.post(`${AUTH_API_URL}/accessrequests`, {
      firstName,
      lastName,
      email,
      cityIds: cities,
      locationIds: locations,
    });
    return response.data;
  },

  async resetPassword({ email }) {
    const response = await axios.post(`${AUTH_API_URL}/resetpassword`, {
      email,
    });
    return response.data;
  },

  async setPassword({ token, password }) {
    const response = await axios.post(`${AUTH_API_URL}/setpassword`, {
      token,
      password,
    });
    return response.data;
  },

  async updatePassword({ oldPassword, newPassword }) {
    const response = await axios.post(`${AUTH_API_URL}/updatepassword `, {
      oldPassword,
      newPassword,
    });
    return response.data;
  },

  async updateProfile({
    firstName,
    middleName,
    lastName,
    email,
    phone,
    address,
    city,
    postalCode,
    countryCode,
    birdthDate,
    avatar,
    languageCode,
    timezone,
    jobTitle,
    customLocation,
    isPrivate,
  }) {
    const response = await axios.put(`${AUTH_API_URL}/profile`, {
      firstName,
      middleName,
      lastName,
      email,
      phone,
      address,
      city,
      postalCode,
      countryCode,
      birdthDate,
      avatar,
      languageCode,
      timezone,
      jobTitle,
      customLocation,
      isPrivate,
    });
    return response.data;
  },

  // get list of cities and restaurants for the sign up page
  async getReferences() {
    const response = await axios.get(`${ANONYMOUS_API_URL}/references`);
    return response.data;
  },

  async changeNotificationSettings({ id, userNotificationSettings }) {
    const response = await axios.put(
      `${AUTH_API_URL}/users/${id}/notificationsettings`,
      {
        userNotificationSettings,
      }
    );
    return response.data;
  },
};

export default UserService;
