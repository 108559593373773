import React, { useState } from 'react';

import Interview from './Interview';
import Onboarding from './Onboarding';
import Joining from './Joining';
import CandidateModal from '../../Discover/CandidateModal';
import classes from './styles.module.scss';

export default function EventDetails({
  isEventDetailsVisible,
  handleClose,
  event,
  refetch,
  currentUser,
}) {
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const [isCandidateModalVisible, setIsCandidateModalVisible] = useState(false);

  return (
    <div className={classes.EventDetails}>
      {event?.type === 'Interview' && (
        <Interview
          isEventDetailsVisible={isEventDetailsVisible}
          handleClose={handleClose}
          event={event}
          refetch={refetch}
          currentUser={currentUser}
          setCurrentCandidate={setCurrentCandidate}
          setIsCandidateModalVisible={setIsCandidateModalVisible}
        />
      )}
      {event?.type === 'Onboarding' && (
        <Onboarding
          isEventDetailsVisible={isEventDetailsVisible}
          handleClose={handleClose}
          event={event}
          refetch={refetch}
          currentUser={currentUser}
          setCurrentCandidate={setCurrentCandidate}
          setIsCandidateModalVisible={setIsCandidateModalVisible}
        />
      )}
      {event?.type === 'Joining' && (
        <Joining
          isEventDetailsVisible={isEventDetailsVisible}
          handleClose={handleClose}
          event={event}
          refetch={refetch}
          currentUser={currentUser}
          setCurrentCandidate={setCurrentCandidate}
          setIsCandidateModalVisible={setIsCandidateModalVisible}
        />
      )}
      <CandidateModal
        currentCandidate={currentCandidate}
        show={isCandidateModalVisible}
        handleClose={() => {
          setIsCandidateModalVisible(false);
          setCurrentCandidate(null);
        }}
        refetch={refetch}
      />
    </div>
  );
}
