/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import classes from './styles.module.scss';
import { mapReportValue } from '../../../../../../../../helpers/mapReports';

export default function Cell({ col }) {
  const [isSelected, setIsSelected] = useState(false);

  return (
    <td
      colSpan={1}
      className={classes.Cell}
      style={{ background: isSelected && '#EBFFFA' }}
      onClick={() => setIsSelected(!isSelected)}
    >
      {mapReportValue(col)}
    </td>
  );
}
