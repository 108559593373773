import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import AdminService from '../../../../services/AdminService';
import classes from './styles.module.scss';
import { IMAGES_URL } from '../../../../constants/main';

export default function User({ user, removeUser, setCurrentUser }) {
  const [isDeleteIconVisible, setIsDeleteIconVisible] = useState(false);

  const { data: userData } = useQuery({
    queryKey: ['userData', user.value],
    queryFn: () => AdminService.getUser({ id: user.value }),
  });

  const hasPhoto = userData?.userProfile?.avatar?.length;

  return (
    <div
      className={classes.User}
      onMouseEnter={() => {
        setIsDeleteIconVisible(true);
        setCurrentUser(userData);
      }}
      onMouseLeave={() => {
        setIsDeleteIconVisible(false);
        setCurrentUser(null);
      }}
    >
      {hasPhoto ? (
        <img
          src={`${IMAGES_URL}/avatar/${userData?.userProfile?.avatar}`}
          alt="canditatePhoto"
          className={classes.photo}
        />
      ) : (
        userData?.userProfile?.firstName?.[0]?.toUpperCase() +
        userData?.userProfile?.firstName?.[1]?.toUpperCase()
      )}
      {isDeleteIconVisible && (
        <i onClick={removeUser}>
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.00114 3.33185L6.5265 0.806488C6.71119 0.621796 7.01064 0.621796 7.19533 0.806488C7.38002 0.991179 7.38002 1.29062 7.19533 1.47532L4.66996 4.00068L7.19619 6.5269C7.38088 6.71159 7.38088 7.01104 7.19619 7.19573C7.01149 7.38042 6.71205 7.38042 6.52736 7.19573L4.00114 4.66951L1.47532 7.19533C1.29062 7.38002 0.991179 7.38002 0.806488 7.19533C0.621796 7.01064 0.621796 6.71119 0.806488 6.5265L3.33231 4.00068L0.807347 1.47572C0.622655 1.29103 0.622655 0.991581 0.807347 0.806889C0.992039 0.622197 1.29148 0.622197 1.47618 0.806889L4.00114 3.33185Z"
              fill="white"
            />
          </svg>
        </i>
      )}
    </div>
  );
}
