import React, { useState, useContext, useMemo } from 'react';

import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { saveAuthData } from '../../helpers/authStorage';
import { AuthContext } from '../../context/AuthContext';
import { UiContext } from '../../context/UiContext';
import UserService from '../../services/UserService';
import TextInput from '../../components/Form/TextInput';
import AuthLayout from '../../containers/AuthLayout';
import classes from './styles.module.scss';
import Button from '../../components/Button';

export default function PasswordSignInPage() {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const { email } = useContext(AuthContext);
  const { showModal } = useContext(UiContext);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object({
        password: yup
          .string()
          .trim()
          .required(t('pages.PasswordSignInPage.requiredField')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await UserService.loginByPassword({
          email,
          password: values.password,
        });
        saveAuthData({
          accessToken: response.token,
          userName: response.userName,
          userId: response.id,
        });
        navigate('/tools/discover');
      } catch (error) {
        showModal({
          icon: 'error',
          title: t('pages.PasswordSignInPage.incorrectPasswordTitle'),
          text: t('pages.PasswordSignInPage.incorrectPasswordText'),
          dismissButtonLabel: 'OK',
        });
      }
    },
  });

  return (
    <AuthLayout previousRoute="/login/method">
      <div className={classes.PasswordSignInPage}>
        <Helmet>
          <title>{t('pages.PasswordSignInPage.pageTitle')}</title>
        </Helmet>
        <div className={classes.container}>
          <h1>
            {t('pages.PasswordSignInPage.enterYour')} <br />
            {t('pages.PasswordSignInPage.password')}
          </h1>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.inputContainer}>
              <TextInput
                error={
                  formik.errors.password &&
                  formik.errors.password !== 'Must be 8 digits or more'
                    ? formik.errors.password
                    : null
                }
                warning={
                  formik.errors.password &&
                  formik.errors.password === 'Must be 8 digits or more'
                    ? formik.errors.password
                    : null
                }
                touched={isTriedToSubmit}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t('pages.PasswordSignInPage.passwordLabel')}
                name="password"
                placeholder={t('pages.PasswordSignInPage.passwordPlaceholder')}
                type="password"
                height={55}
              />
            </div>
            <Button
              width={400}
              disabled={!formik.values.password}
              onClick={() => {
                setIsTriedToSubmit(true);
                formik.handleSubmit();
              }}
            >
              {t('common.signIn')}
            </Button>
          </form>
          <p className={classes.helper}>
            {t('pages.PasswordSignInPage.forgotPassword')}{' '}
            <Link to="/request-password">
              <span>{t('pages.PasswordSignInPage.requestNewPassword')}</span>
            </Link>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
}
