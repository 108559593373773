import React from 'react';
import CalendarEventCard from '../../../Favorites/Calendar/CalendarEventCard';
import classes from './styles.module.scss';

export default function CalendarTab({ events, refetch }) {
  const currentDate = new Date();

  return (
    <div className={classes.tab}>
      <div className={classes.events}>
        {events
          ?.map((event) => (
            <CalendarEventCard
              favCalendarEvent={event}
              key={`${event.id} ${event.eventDate}`}
              currentDate={currentDate}
              refetch={refetch}
            />
          ))
          .reverse()}
      </div>
    </div>
  );
}
