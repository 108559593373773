import React from 'react';

import CandidatesList from './CandidatesList';

import classes from './styles.module.scss';

export default function Candidates() {
  return (
    <div className={classes.Candidates}>
      <CandidatesList />
    </div>
  );
}
