import React from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';

export default function PaginateCandidates({
  candidate,
  candidates,
  setCurrentCandidate,
}) {
  const currentCandidateIndex = candidates?.findIndex(
    (cand) => cand === candidate
  );

  const getPrevCandidate = () => {
    if (!candidates[currentCandidateIndex - 1]) {
      return;
    }

    setCurrentCandidate(candidates[currentCandidateIndex - 1]);
  };

  const getNextCandidate = () => {
    if (!candidates[currentCandidateIndex + 1]) {
      return;
    }

    setCurrentCandidate(candidates[currentCandidateIndex + 1]);
  };

  return (
    <div className={classes.pagination}>
      <div
        className={classNames(classes.prev, {
          [classes.active]: candidates?.[currentCandidateIndex - 1],
        })}
        onClick={getPrevCandidate}
      />
      <div
        className={classNames(classes.next, {
          [classes.active]: candidates?.[currentCandidateIndex + 1],
        })}
        onClick={getNextCandidate}
      />
    </div>
  );
}
