import React from 'react';

import { useTranslation } from 'react-i18next';

import ViewBlocking from './ViewBlocking';
import AdminService from '../../../../../services/AdminService';
import TableView from '../../TableView';

export default function Blockings({ setItemCount }) {
  const { t } = useTranslation();

  const columns = [
    {
      name: t('dashboardComponents.Console.ContentManagement.Blockings.UserID'),
      field: 'user.id',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.ContentManagement.Blockings.UserName'
      ),
      field: 'user.userProfile',
      type: 'user',
      sortable: true,
    },
    {
      name: t('dashboardComponents.Console.ContentManagement.Blockings.Type'),
      type: 'text',
      value: 'Reported',
      sortable: false,
    },
    {
      name: t('dashboardComponents.Console.ContentManagement.Blockings.Reason'),
      field: 'reason',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.ContentManagement.Blockings.ReportedBy'
      ),
      field: 'createdBy.userProfile',
      type: 'user',
      sortable: true,
    },
    {
      name: t('common.createdOn'),
      field: 'createdAt',
      sortable: true,
      type: 'date',
    },
  ];

  return (
    <TableView
      setItemCount={setItemCount}
      dataName="UserReports"
      getDataHandler={AdminService.getUserReports}
      columns={columns}
      viewComponent={ViewBlocking}
      availableActions={[]}
    />
  );
}
