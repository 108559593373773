import React from 'react';
import classes from './styles.module.scss';
import MemberCard from './MemberCard';

function sortMembers(a, b) {
  return a?.fullName?.localeCompare(b?.fullName);
}

export default function Members({ members, noSort }) {
  return (
    <div className={classes.Members}>
      {noSort
        ? members.map((member) => (
            <MemberCard member={member} key={member?.id} />
          ))
        : members
            ?.sort(sortMembers)
            .map((member) => <MemberCard member={member} key={member?.id} />)}
    </div>
  );
}
