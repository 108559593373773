import React, { useContext, useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { UiContext } from '../../../../../context/UiContext';
import AdminService from '../../../../../services/AdminService';
import UserService from '../../../../../services/UserService';
import DotMenu from '../../CandidateHeader/DotMenu';
import classes from './styles.module.scss';

function getDate(date) {
  const temp = new Date(date);

  return `${temp.getDate()}th ${temp
    .toLocaleString('en-US', { month: 'long' })
    .slice(0, 3)} ${temp.getFullYear()} ${temp.getHours()}:${
    temp.getMinutes() < 10 ? '0' : ''
  }${temp.getUTCMinutes()}`;
}

export default function Note({
  note,
  isAdding,
  setIsAdding,
  candidateId,
  refetch,
  isVisible,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState('');
  const [prevValue, setPrevValue] = useState('');
  // const [readonlyTextareaHeight, setReadonlyTextareaHeight] = useState(0);

  const textareaRef = useRef(null);

  const { t } = useTranslation();

  const { data } = useQuery({ queryKey: ['me'], queryFn: UserService.getMe });

  const { showNotification, setIsFetching } = useContext(UiContext);

  const validationButton = prevValue === currentValue || currentValue === '';

  useEffect(() => {
    setPrevValue(textareaRef.current.value);
  }, [isEditing]);

  useEffect(() => {
    setTimeout(() => {
      textareaRef.current.focus();
    }, 200);
  }, [isAdding]);

  const handleOnChange = (event) => {
    textareaRef.current.style.height = `${event.target.scrollHeight}px`;
    setCurrentValue(event.target.value);
  };

  const handleCancel = () => {
    textareaRef.current.value = prevValue;
    setIsEditing(false);
  };

  const addNewNote = async () => {
    if (validationButton) {
      return;
    }

    try {
      setIsFetching(true);
      await AdminService.addNewNote({
        jobApplicationId: candidateId,
        note: currentValue,
      });
      if (refetch) {
        await refetch();
      }
      showNotification({
        text: t(
          'dashboardComponents.Candidate.Notes.Note.noteAddedSuccessfully'
        ),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsAdding(false);
      setIsFetching(false);
    }
  };

  const saveNote = async () => {
    if (validationButton) {
      return;
    }

    try {
      setIsFetching(true);

      await AdminService.deleteNote({
        jobApplicationId: candidateId,
        noteId: note?.id,
      });
      await AdminService.addNewNote({
        jobApplicationId: candidateId,
        note: currentValue,
      });
      if (refetch) {
        await refetch();
      }
      showNotification({
        text: t(
          'dashboardComponents.Candidate.Notes.Note.noteUpdatedSuccessfully'
        ),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsEditing(false);
      setIsFetching(false);
    }
  };

  // Quick fix until refactoring
  // It sets height of textarea to full height of text in read mode
  useEffect(() => {
    let timer;
    if (note?.note && isVisible) {
      timer = setInterval(() => {
        const textareaFullHeight = textareaRef.current.scrollHeight;

        if (!textareaFullHeight) {
          return;
        }

        textareaRef.current.style.height = `${textareaFullHeight}px`;
        clearInterval(timer);
      }, 200);
    }
    return () => {
      clearInterval(timer);
    };
  }, [note, candidateId, isAdding, isVisible]);

  return isAdding ? (
    <div
      className={classNames(classes.note, {
        [classes.editing]: isAdding || isEditing,
      })}
    >
      <textarea
        ref={textareaRef}
        type="text"
        className={classNames(classes.text, {
          [classes.isAddingText]: isAdding,
        })}
        value={currentValue}
        onChange={(event) => handleOnChange(event)}
        maxLength={5000}
        rows={1}
        placeholder={t(
          'dashboardComponents.Candidate.Notes.Note.enterYourNotesHere'
        )}
      />
      <div
        className={classNames(classes.info, {
          [classes.isAdding]: isAdding,
        })}
      >
        <div
          className={classNames(classes.buttons, {
            [classes.isAdding]: isAdding,
          })}
        >
          <button
            className={classes.cancel}
            onClick={() => setIsAdding(false)}
            type="button"
          >
            Cancel
          </button>
          <button
            type="button"
            className={classNames(classes.save, {
              [classes.disabled]: !currentValue.length,
            })}
            onClick={addNewNote}
            disabled={!currentValue.length}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={classNames(classes.note, {
        [classes.editing]: isAdding || isEditing,
      })}
    >
      <textarea
        ref={textareaRef}
        className={classNames(classes.text, {
          [classes.isEditingText]: isEditing,
        })}
        defaultValue={note.note}
        onChange={(event) => handleOnChange(event)}
        maxLength={5000}
        placeholder={t(
          'dashboardComponents.Candidate.Notes.Note.enterYourNotesHere'
        )}
        disabled={!isEditing}
        rows={1}
      />
      <div className={classes.info}>
        <h2
          className={classes.author}
        >{`${note?.user.userProfile.firstName} ${note.user.userProfile.lastName}`}</h2>
        <span className={classes.date}>{getDate(note.createdAt)}</span>
        <div
          className={classNames(classes.buttons, {
            [classes.hide]: !isEditing,
          })}
        >
          <button
            type="button"
            className={classes.cancel}
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className={classNames(classes.save, {
              [classes.disabled]: validationButton,
            })}
            onClick={saveNote}
          >
            Save
          </button>
        </div>
        {data.id === note.user.id ? (
          <div className={classes.dotMenu}>
            <DotMenu
              options={['Edit', 'Delete']}
              isTransparent
              width="100px"
              handleEdit={setIsEditing}
              note={note}
              hide={isEditing}
              refetch={refetch}
              candidateId={candidateId}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
