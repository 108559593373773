import React, { useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Graph from './Graph';

import classes from './styles.module.scss';

export default function ViewsGraph({ graphData }) {
  const [activeOption, setActiveOption] = useState('Monthly');
  const [isDropdownMenuVisible, setIsDropdownMenuVisible] = useState(false);

  const { t } = useTranslation();

  const options = [
    t('dashboardComponents.Jobs.MyJobs.JobDetails.Insights.ViewsGraph.daily'),
    t('dashboardComponents.Jobs.MyJobs.JobDetails.Insights.ViewsGraph.monthly'),
  ];

  return (
    <div className={classes.ViewsGraph}>
      <div className={classes.header}>
        <span className={classes.title}>
          {t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.ViewsGraph.viewsApplicants'
          )}
        </span>
        <div className={classes.info}>
          <span className={classes.view}>
            {t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.ViewsGraph.views'
            )}
          </span>
          <span className={classes.applicant}>
            {t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.ViewsGraph.applicant'
            )}
          </span>
          <div
            className={classNames(classes.type, {
              [classes.selected]: isDropdownMenuVisible,
            })}
            onClick={() => setIsDropdownMenuVisible(!isDropdownMenuVisible)}
          >
            {activeOption}
            {isDropdownMenuVisible && (
              <div className={classes.dropdown}>
                {options.map((option) => (
                  <span
                    className={classNames(classes.option, {
                      [classes.activeOption]: activeOption === option,
                    })}
                    onClick={() => {
                      setActiveOption(option);
                      setIsDropdownMenuVisible(false);
                    }}
                    key={option}
                  >
                    {option}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Graph activeOption={activeOption} graphData={graphData} />
    </div>
  );
}
