import React from 'react';

import MessageInput from './MessageInput';
import MessagesList from './MessagesList';

import classes from './styles.module.scss';

export default function ChatWindow({
  chatMessages,
  activeContact,
  refetchMessages,
  jobApplicationUserFeedbacks,
  messageListHeight,
}) {
  return (
    <div className={classes.ChatWindow}>
      <MessagesList
        chatMessages={chatMessages}
        jobApplicationUserFeedbacks={jobApplicationUserFeedbacks}
        activeContact={activeContact}
        refetchMessages={refetchMessages}
        messageListHeight={messageListHeight}
      />
      <MessageInput
        activeContact={activeContact}
        refetchMessages={refetchMessages}
      />
    </div>
  );
}
