import React, { useEffect, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useLocation, useSearchParams } from 'react-router-dom';
import AdminService from '../../../services/AdminService';
import { getEvents } from '../../../helpers/getEvents';
import CalendarInfo from './CalendarInfo';
import CalendarMenu from './CalendarMenu';
import SideCalendar from './SideCalendar';

import { useUserStore } from '../../../store';
import classes from './styles.module.scss';
import UserService from '../../../services/UserService';
import EventDetails from './EventDetails';

function calculateCurrentWeekNumber(currentDate) {
  const startDate = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

  const weekNumber = Math.ceil(days / 7);

  return weekNumber;
}

export default function Calendar() {
  const [activeType, setActiveType] = useState('Day');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [timezone, setTimezone] = useState('Turkey');
  const [dateToday, setDateToday] = useState(new Date());
  const [isEventDetailsVisible, setIsEventDetailsVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const initialDate = searchParams.get('date');
  const event = searchParams.get('event');
  const eventId = searchParams.get('id');

  const userScopes = useUserStore((state) => state.userScopes);
  const isReadOnly = useMemo(() => {
    return userScopes.includes('api:admin:read');
  }, [userScopes]);

  useEffect(() => {
    setActiveType(location?.state?.calendarActiveType || 'Day');
  }, [location?.state?.calendarActiveType]);

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  useEffect(() => {
    if (initialDate) {
      const dateString = initialDate?.split('GMT')?.[0];
      const date = new Date(dateString);

      if (!Number.isNaN(date.getTime())) {
        setCurrentDate(date);
      }
    }
  }, [initialDate]);

  useEffect(() => {
    setTimezone(user?.userProfile?.timezone || 'Turkey');
  }, [user]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDateToday(
        new Date(
          new Date().toLocaleString('en-US', {
            timeZone: `${timezone}`,
          })
        )
      );
    }, 10000);

    const timeout = setTimeout(() => {
      setDateToday(
        new Date(
          new Date().toLocaleString('en-US', {
            timeZone: `${timezone}`,
          })
        )
      );
    }, 100);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [timezone]);

  const { data: jobApplications, refetch } = useQuery({
    queryKey: ['events'],
    queryFn: AdminService.getJobApplicationsSchedule,
  });

  const allEvents = useMemo(
    () => getEvents(jobApplications, user?.id, null, timezone),
    [jobApplications, user?.id, timezone]
  );

  const getMonthEvents = useMemo(
    () => (date, events) => {
      const eventsToday = [];
      const eventsInWeek = [];
      const eventsInMonth = [];

      const tempDate = date.getDate();
      const tempMonth = date.getMonth();
      const tempYear = date.getFullYear();

      // eslint-disable-next-line no-shadow
      events?.forEach((event) => {
        const tempEventDate = new Date(event.eventDate);
        const eventDate = tempEventDate.getDate();
        const eventMonth = tempEventDate.getMonth();
        const eventYear = tempEventDate.getFullYear();

        if (
          eventDate === tempDate &&
          eventMonth === tempMonth &&
          eventYear === tempYear
        ) {
          eventsToday.push(event);
        }

        if (eventMonth === tempMonth && eventYear === tempYear) {
          eventsInMonth.push(event);
        }

        if (
          eventYear === tempYear &&
          calculateCurrentWeekNumber(tempEventDate) ===
            calculateCurrentWeekNumber(date)
        ) {
          eventsInWeek.push(event);
        }
      });

      return {
        events,
        eventsToday,
        eventsInWeek,
        eventsInMonth,
      };
    },
    []
  );

  useEffect(() => {
    if (eventId && event) {
      const calendarEvent = allEvents?.find(
        ({ type, id }) => type === event && id === +eventId
      );

      if (calendarEvent) {
        setSelectedEvent(calendarEvent);
        setIsEventDetailsVisible(true);
      }
    }
  }, [allEvents, event, eventId]);

  return (
    <div className={classes.Calendar}>
      <CalendarMenu
        activeType={activeType}
        setActiveType={setActiveType}
        setCurrentDate={setCurrentDate}
        events={getMonthEvents(currentDate, allEvents)}
        user={user}
        timezone={timezone}
        setTimezone={setTimezone}
        dateToday={dateToday}
        isReadOnly={isReadOnly}
      />
      <div className={classes.container}>
        <SideCalendar
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          setActiveType={setActiveType}
          dateToday={dateToday}
          refetch={refetch}
          user={user}
          events={getMonthEvents(currentDate, allEvents)}
          activeType={activeType}
          timezone={timezone}
          setTimezone={setTimezone}
        />
        <CalendarInfo
          activeType={activeType}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          setActiveType={setActiveType}
          dateToday={dateToday}
          events={allEvents}
          refetch={refetch}
          user={user}
        />
      </div>
      {isEventDetailsVisible && (
        <EventDetails
          isEventDetailsVisible={isEventDetailsVisible}
          handleClose={() => {
            setIsEventDetailsVisible(false);
            setSelectedEvent(null);
            setSearchParams({});
          }}
          event={selectedEvent}
          refetch={refetch}
          currentUser={user}
        />
      )}
    </div>
  );
}
