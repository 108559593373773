/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside';

const rowsNumber = [10, 25, 50, 100];

export default function TableHeader({
  rowsPerPage,
  setRowsPerPage,
  data,
  currentPage,
  setCurrentPage,
  pageCount,
}) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const dropdownContainerRef = useRef(null);
  const dropdownRef = useRef(null);

  useOnClickOutside(
    dropdownRef,
    () => setIsDropdownVisible(false),
    dropdownContainerRef
  );

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const getPrevTab = () => {
    if (currentPage === 0) {
      return;
    }

    setCurrentPage(currentPage - 1);
  };

  const getNextTab = () => {
    if (currentPage === pageCount - 1) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  let dataInfo;

  if (data?.length) {
    dataInfo = `${rowsPerPage * currentPage + 1}-${
      rowsPerPage * (currentPage + 1) >= data.length
        ? data.length
        : rowsPerPage * (currentPage + 1)
    }`;
  } else {
    dataInfo = 0;
  }

  return (
    <div className={classes.TableHeader}>
      <div
        className={classes.rowsPerPage}
        ref={dropdownContainerRef}
        onClick={toggleDropdown}
      >
        Rows per page:
        <span> {rowsPerPage}</span>
        <div
          className={classNames(classes.arrow, {
            [classes.arrowUp]: isDropdownVisible,
          })}
        />
        {isDropdownVisible && (
          <ul className={classes.dropdown} ref={dropdownRef}>
            {rowsNumber.map((number) => (
              <li
                className={classNames({
                  [classes.active]: rowsPerPage === number,
                })}
                onClick={() => setRowsPerPage(number)}
                key={number}
              >
                {number}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className={classes.paginationContainer}>
        <div className={classes.itemsNumber}>
          {dataInfo}
          <span> of </span>
          {data?.length}
        </div>
        <div className={classes.pagination}>
          <div
            className={classNames(classes.prev, {
              [classes.active]: currentPage !== 0 && data?.length,
            })}
            onClick={getPrevTab}
          />
          <div
            className={classNames(classes.next, {
              [classes.active]: currentPage !== pageCount - 1 && data?.length,
            })}
            onClick={getNextTab}
          />
        </div>
      </div>
    </div>
  );
}
