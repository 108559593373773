import React, { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classes from './styles.module.scss';
import Assessment from '../../../../../../../../../modals/AssessmentModal/SelectedAssessments/Assessment';

export default function SelectedAssessments({
  assessments,
  setAssessments,
  touched,
  setFields,
}) {
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const newArray = Array.from(assessments);
    const [removed] = newArray.splice(sourceIndex, 1);
    newArray.splice(destinationIndex, 0, removed);

    setAssessments(newArray);
  };

  const deleteAssessment = (field) => {
    setFields((prevFields) => {
      return prevFields.map((prevField) => {
        if (prevField.fieldId !== field.fieldId) {
          return prevField;
        }
        return {
          ...prevField,
          tests: prevField.tests?.map((test) => {
            if (test.assessmentId !== field.assessmentId) {
              return test;
            }
            return {
              ...test,
              isAssigned: false,
              isSelected: false,
            };
          }),
        };
      });
    });
    setAssessments((prevAssessments) => {
      return prevAssessments.filter(
        (assessment) => assessment.assessmentId !== field.assessmentId
      );
    });
  };

  const setThreshold = useCallback(
    (id, name, value) => {
      setAssessments((prevAssessments) => {
        return prevAssessments.map((asssmnt) => {
          if (asssmnt.assessmentId !== id) {
            return asssmnt;
          }
          return {
            ...asssmnt,
            [name]: value,
          };
        });
      });
    },
    [setAssessments]
  );

  return (
    <div className={classes.SelectedAssessments}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="assessments">
          {(provided) => (
            <div
              className={classes.content}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {assessments?.map((assessment, index) => (
                <Draggable
                  key={`${assessment.assessmentId}${assessment.name}`}
                  draggableId={`item-$${assessment.assessmentId}${assessment.name}`}
                  index={index}
                >
                  {(provid) => (
                    <div
                      {...provid.draggableProps}
                      {...provid.dragHandleProps}
                      ref={provid.innerRef}
                    >
                      <Assessment
                        key={`${assessment.assessmentId}${assessment.name}`}
                        assessment={assessment}
                        deleteAssessment={() => deleteAssessment(assessment)}
                        setThreshold={setThreshold}
                        touched={touched}
                        jobTemplate
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
