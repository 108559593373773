/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function MediaUploader({
  file,
  setFile,
  initialFileType,
  initialFile,
  hasChanged,
  setHasChanged,
}) {
  const fileInputRef = useRef();

  const { t } = useTranslation();

  const openFileBrowser = () => {
    fileInputRef.current.click();
  };

  const changeFile = (event) => {
    if (
      !event.target.files[0].type.includes('image') &&
      !event.target.files[0].type.includes('video')
    ) {
      return;
    }

    setFile(event.target.files[0]);
    setHasChanged(true);
  };

  const removeFile = () => {
    setFile(null);
    setHasChanged(true);
  };

  let filePreview;

  let fileUrl;

  try {
    fileUrl = hasChanged ? URL.createObjectURL(file) : initialFile;
  } catch (error) {
    //
  }

  if (file?.type.includes('image') || initialFileType === 'Image') {
    filePreview = <img className={classes.mediaPreview} alt="" src={fileUrl} />;
  } else if (file?.type.includes('video') || initialFileType === 'Video') {
    filePreview = <video className={classes.mediaPreview} src={fileUrl} />;
  }

  if (!file && hasChanged) {
    filePreview = null;
  }

  return (
    <div className={classes.MediaUploader}>
      <h1>
        {t(
          'dashboardComponents.Console.ContentManagement.PicVideo.EditContent.MediaUploader.PictureVideo'
        )}
      </h1>
      <div className={classes.mediaContainer}>
        {!filePreview ? (
          <i className={classes.addIcon} onClick={openFileBrowser}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.99902 1.16406V12.8307"
                stroke="white"
                strokeWidth="1.64103"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.16528 7H12.832"
                stroke="white"
                strokeWidth="1.64103"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </i>
        ) : (
          <i className={classes.removeIcon} onClick={removeFile}>
            <svg
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.0017 3.99582L8.78975 0.207778C9.06679 -0.0692594 9.51595 -0.0692595 9.79299 0.207778C10.07 0.484816 10.07 0.933983 9.79299 1.21102L6.00495 4.99906L9.79428 8.7884C10.0713 9.06544 10.0713 9.5146 9.79428 9.79164C9.51724 10.0687 9.06808 10.0687 8.79104 9.79164L5.0017 6.00231L1.21297 9.79104C0.935936 10.0681 0.486769 10.0681 0.209731 9.79104C-0.0673063 9.514 -0.0673063 9.06483 0.209731 8.7878L3.99846 4.99906L0.211021 1.21162C-0.0660172 0.934586 -0.0660169 0.485419 0.211021 0.208381C0.488059 -0.0686567 0.937225 -0.068657 1.21426 0.208381L5.0017 3.99582Z"
                fill="white"
              />
            </svg>
          </i>
        )}
        {filePreview || (
          <div className={classes.uploader} onClick={openFileBrowser}>
            <input type="file" ref={fileInputRef} onChange={changeFile} />
            <span>
              {t(
                'dashboardComponents.Console.ContentManagement.PicVideo.EditContent.MediaUploader.AddPictureVideo'
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
