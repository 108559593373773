import React from 'react';

import { useTranslation } from 'react-i18next';

import Tooltip from '../../../../Tooltip';

import classes from './styles.module.scss';

export default function CandidateSourceTag({ sourceType }) {
  const { t } = useTranslation();

  const sourceText =
    sourceType === 'External'
      ? t('dashboardComponents.Candidate.CandidateHeader.sources.Sourced')
      : t('dashboardComponents.Candidate.CandidateHeader.sources.Direct');

  const tooltipText =
    sourceType === 'External'
      ? t(
          'dashboardComponents.Candidate.CandidateHeader.sources.SourcedTooltip'
        )
      : t(
          'dashboardComponents.Candidate.CandidateHeader.sources.DirectTooltip'
        );

  return (
    <Tooltip text={tooltipText} noWrap>
      <div className={classes.CandidateSourceTag}>{sourceText}</div>
    </Tooltip>
  );
}
