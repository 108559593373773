/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import PostStats from '../../components/Dashboard/Discover/Feed/Post/PostStats';
import Text from '../../components/Dashboard/Discover/Feed/Post/Text';
import Header from '../../components/Dashboard/Discover/Feed/Post/Header';
import Slider from './Slider';

import classes from './styles.module.scss';
import Comments from '../../components/Dashboard/Discover/Feed/Post/Comments';

export default function MediaModal({
  show,
  handleClose,
  index,
  allMedia,
  feedPost,
  refetch,
  isRead,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex(index);

    return () => {
      setCurrentIndex(0);
    };
  }, [index]);

  return (
    <Modal
      show={show}
      centered
      className={classes.MediaModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <i className={classes.closeIcon} onClick={handleClose}>
          Close
        </i>
        <div>
          <Slider
            allMedia={allMedia}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
        </div>
        <div className={classes.post}>
          <Header feedPost={feedPost} isRead={isRead} modal />
          <div className={classes.textContainer}>
            <Text text={feedPost.content} modal />
          </div>
          <div className={classes.postStatsContainer}>
            <PostStats modal feedPost={feedPost} />
          </div>
          <div className={classes.commentsContainer}>
            <Comments
              commnts={feedPost.comments}
              feedPost={feedPost}
              refetch={refetch}
              scrollable
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
