import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Tooltip from '../../../../../../../../Tooltip';

import classes from './styles.module.scss';

export default function StyleSelector({ setValue, value }) {
  const { t } = useTranslation();

  const styleButtons = [
    {
      style: {
        fontWeight: 'bold',
      },
      text: 'B',
      value: 'Bold',
      label: t(
        'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Font.FontSelector.StyleSelector.Bold'
      ),
    },
    {
      /*  style: {
        fontStyle: 'italic',
      }, */
      text: 'I',
      value: 'Italic',
      label: t(
        'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Font.FontSelector.StyleSelector.Italic'
      ),
    },
    {
      style: {
        textDecoration: 'underline',
      },
      text: 'U',
      value: 'Underline',
      label: t(
        'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Font.FontSelector.StyleSelector.Underline'
      ),
    },
  ];

  return (
    <div className={classes.StyleSelector}>
      {styleButtons.map((button) => (
        <Tooltip text={button.label}>
          <button
            className={classNames(classes.button, {
              [classes.active]: value.styles?.includes(button.value),
            })}
            type="button"
            style={button.style}
            onClick={() => {
              setValue((prevValue) => ({
                ...prevValue,
                styles: prevValue.styles.includes(button.value)
                  ? prevValue.styles.filter((style) => style !== button.value)
                  : [...prevValue.styles, button.value],
              }));
            }}
          >
            {button.text}
          </button>
        </Tooltip>
      ))}
    </div>
  );
}
