function replaceQuotes(container, sharedClasses) {
  const quotes = container.querySelectorAll(`.${sharedClasses.quote}`);

  const quotesData = [];

  if (quotes.length) {
    quotes.forEach((quote) => {
      const quoteContainer = document.createElement('div');

      const containerClass = `c${Math.random().toString(36).slice(2, 7)}`;

      quoteContainer.classList.add('quoteContainer');
      quoteContainer.classList.add(containerClass);

      quote.replaceWith(quoteContainer);

      quotesData.push({
        quote: quote.innerHTML,
        containerClass,
      });
    });
  }

  return quotesData;
}

export default function processEditingPostContent(postContent, sharedClasses) {
  const container = document.createElement('div');
  container.innerHTML = postContent;
  const quotes = replaceQuotes(container, sharedClasses);

  return {
    content: container.innerHTML,
    quotes,
  };
}

export const addQuotesToQuoteContainers = (
  container,
  sharedClasses,
  quoteContainers
) => {
  const domQuoteContainers = container.querySelectorAll('.quoteContainer');

  if (domQuoteContainers.length) {
    domQuoteContainers.forEach((domQuoteContainer, index) => {
      const quoteContainer = quoteContainers[index];

      const quote = document.createElement('blockquote');
      quote.innerHTML = quoteContainer.quote;
      quote.classList.add(sharedClasses.quote);

      domQuoteContainer.replaceWith(quote);
    });
  }

  return container.innerHTML;
};
