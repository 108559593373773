import React from 'react';

import classes from './styles.module.scss';

export default function Stat({ label, value }) {
  return (
    <li className={classes.Stat}>
      <div className={classes.graph}>
        <div className={classes.fill} style={{ height: `${value}%` }} />
      </div>
      <h5>{label}</h5>
    </li>
  );
}
