import React, { useEffect, useRef } from 'react';

import { arrayMoveImmutable } from 'array-move';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import NumberInput from '../../../../../../Form/NumberInput';
import Switch from '../../../../../../Switch';
import TextInput from '../../../../../../Form/TextInput';
import { ALPHABET } from '../../../../../../../constants/main';
import classes from './styles.module.scss';
import Answer from './Answer';

let timer;

export default function Question({
  question,
  order,
  onToggleMultiple,
  changeQuestion,
  addAnswer,
  deleteAnswer,
  changeAnswer,
  deleteQuestion,
  changeAnswersPositions,
  dragQuestion,
  setIsAnswerBeingDragged,
  isDuplicate,
  setIsQuestionBeingDragged,
  addQuestion,
  changeQuestionLimit,
}) {
  const answersContainerRef = useRef();

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const dragAnswer = (event, index) => {
    event.dataTransfer.setData('answerIndex', index);
    setIsAnswerBeingDragged(true);
  };

  const handleAnswerDrop = (event) => {
    event.preventDefault();
    const oldAnswerIndex = event.dataTransfer.getData('answerIndex');

    const coords = answersContainerRef.current.getBoundingClientRect();

    const yPosition = event.clientY - coords.top;

    const newAnswerIndex = Math.max(Math.floor(yPosition / (50 + 20)), 0);

    const newAnswersArray = arrayMoveImmutable(
      question.answers,
      oldAnswerIndex,
      newAnswerIndex
    );

    changeAnswersPositions(question.id, newAnswersArray);

    // setIsAnswerBeingDragged(false);
  };

  const createNewAnswer = () => {
    if (question.answers?.length >= question.limit) {
      return;
    }

    addAnswer(question.id);
  };

  useEffect(() => {
    if (
      question.answers?.length > question.limit ||
      question.answers?.length > 1
    ) {
      const maxQuestions = question.limit;
      const answerToDeleteCount = question.answers?.length - maxQuestions;

      if (answerToDeleteCount <= 0) {
        return;
      }

      const answersToDelete = question.answers?.slice?.(-answerToDeleteCount);

      clearTimeout(timer);
      timer = setTimeout(() => {
        answersToDelete.forEach((answer) =>
          deleteAnswer(question.id, answer.id)
        );
      }, 1000);
    }
  }, [deleteAnswer, question]);

  return (
    <div
      className={classes.Question}
      draggable={!isTabletOrMobile}
      onDragStart={dragQuestion}
      onDragEnd={() => setIsQuestionBeingDragged(false)}
      data-index={order - 1}
    >
      <header className={classes.header}>
        <h1>Q.{order}</h1>
        <div className={classes.switches}>
          <Switch
            backgroundColor="linear-gradient(228.41deg, #00E4AC 0%, #10C5E9 53.88%, #9240DC 106.71%)"
            label={t(
              'dashboardComponents.Discover.AddContent.PollEditor.PollForm.Question.multiChoice'
            )}
            onClick={onToggleMultiple}
            isEnabled={question.allowsMultipleAnswers}
          />
          <span>
            {t(
              'dashboardComponents.Discover.AddContent.PollEditor.PollForm.Question.limit'
            )}
          </span>
          <div>
            <NumberInput
              minValue={2}
              value={question.limit}
              setFieldValue={(_name, value) =>
                changeQuestionLimit(question.id, value)
              }
            />
          </div>
        </div>
      </header>
      <div className={classes.questionContent}>
        {!isTabletOrMobile && (
          <svg
            width="11"
            height="20"
            viewBox="0 0 11 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="#C1C5D6" />
            <circle cx="9" cy="2" r="2" fill="#C1C5D6" />
            <circle cx="2" cy="10" r="2" fill="#C1C5D6" />
            <circle cx="9" cy="10" r="2" fill="#C1C5D6" />
            <circle cx="2" cy="18" r="2" fill="#C1C5D6" />
            <circle cx="9" cy="18" r="2" fill="#C1C5D6" />
          </svg>
        )}
        <TextInput
          style={{
            fontFamily: isTabletOrMobile ? 'Gilroy-Bold' : '',
            borderRadius: isTabletOrMobile ? 8 : '',
          }}
          value={question.name}
          height={50}
          onChange={(event) => changeQuestion(question.id, event.target.value)}
          placeholder={t(
            'dashboardComponents.Discover.AddContent.PollEditor.PollForm.Question.enterQuestionHere'
          )}
          error={
            isDuplicate &&
            t(
              'dashboardComponents.Discover.AddContent.PollEditor.PollForm.Question.duplicate'
            )
          }
          touched={isDuplicate}
        />
        <i
          className={classes.deleteIcon}
          onClick={() => deleteQuestion(question.id)}
        >
          Delete
        </i>
      </div>
      <div
        className={classes.answers}
        onDragOver={(event) => event.preventDefault()}
        onDrop={handleAnswerDrop}
        ref={answersContainerRef}
      >
        {question.answers?.map((answer, index) => {
          const changeHandler = (name) => {
            changeAnswer(question.id, answer.id, name);
          };
          return (
            <Answer
              index={index}
              setIsAnswerBeingDragged={setIsAnswerBeingDragged}
              key={answer.id}
              deleteAnswer={() => deleteAnswer(question.id, answer.id)}
              alphabet={ALPHABET[index].toUpperCase()}
              changeAnswer={changeHandler}
              value={question.answers[index].name}
              dragAnswer={(event) => dragAnswer(event, index)}
              isDuplicate={question.answers.some(
                (answr) =>
                  answr.id !== answer.id &&
                  answr.name &&
                  answr.name === answer.name
              )}
              onEnterPress={
                index === question.answers?.length - 1 ? createNewAnswer : null
              }
            />
          );
        })}
      </div>
      <div className={classes.addMore}>
        <span onClick={addQuestion}>
          {t(
            'dashboardComponents.Discover.AddContent.PollEditor.PollForm.Question.addQuestion'
          )}
        </span>
        <span onClick={createNewAnswer}>
          {t(
            'dashboardComponents.Discover.AddContent.PollEditor.PollForm.Question.addMore'
          )}{' '}
          +
        </span>
      </div>
    </div>
  );
}
