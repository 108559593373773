import React, { useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import JobManagement from './JobManagement';
import ContentManagement from './ContentManagement';
import RefDataManagement from './RefDataManagement';
import RestaurantManagement from './RestaurantManagement';
import UserManagement from './UserManagement';
import ManagementMenu from './ManagementMenu';

import { useUserStore } from '../../../store';
import classes from './styles.module.scss';

const managementViews = [
  {
    label: 'User Management',
    path: '/tools/admin/user-management',
    scopes: ['api:full', 'api:admin:auth'],
  },
  {
    label: 'Restaurant Management',
    path: '/tools/admin/restaurant-management',
    scopes: ['api:full', 'api:admin:reference'],
  },
  {
    label: 'Job Management',
    path: '/tools/admin/job-management',
    scopes: ['api:full', 'api:admin:reference'],
  },
  {
    label: 'Reference Data Management',
    path: '/tools/admin/ref-data-management',
    scopes: ['api:full', 'api:admin:reference'],
  },
  {
    label: 'Content Management',
    path: '/tools/admin/content-management',
    scopes: ['api:full', 'api:admin:reference'],
  },
];

export default function Console() {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const userScopes = useUserStore((state) => state.userScopes);

  const availableManagementViews = useMemo(() => {
    if (!userScopes) {
      return [];
    }
    return managementViews.filter((view) =>
      view.scopes.some((scope) => userScopes.includes(scope))
    );
  }, [userScopes]);

  const renderPage = () => {
    switch (true) {
      case location.pathname.includes('admin/user-management'):
        return <UserManagement />;
      case location.pathname.includes('admin/restaurant-management'):
        return <RestaurantManagement />;
      case location.pathname.includes('admin/job-management'):
        return <JobManagement />;
      case location.pathname.includes('admin/ref-data-management'):
        return <RefDataManagement />;
      case location.pathname.includes('admin/content-management'):
        return <ContentManagement />;
      default:
        navigate('/404');
        return null;
    }
  };

  return (
    <div className={classes.Console} id="console">
      <h3 className={classes.title}>{t('common.dashboardSections.admin')}</h3>
      <div className={classes.managementMenuContainer}>
        <ManagementMenu options={availableManagementViews} />
      </div>
      {renderPage()}
    </div>
  );
}
