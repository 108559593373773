import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import SetNewPassword from './SetNewPassword';
import Button from '../Button';

import classes from './styles.module.scss';

export default function Password() {
  const [isEditing, setIsEditing] = useState(false);

  const { t } = useTranslation();

  return (
    <div className={classes.Password}>
      <SetNewPassword
        handleCancel={() => setIsEditing(false)}
        isEditing={isEditing}
      />
      {!isEditing && (
        <Button onClick={() => setIsEditing(true)}>
          {t(
            'dashboardComponents.ProfileSettings.Password.passwordChangeButton'
          )}
        </Button>
      )}
    </div>
  );
}
