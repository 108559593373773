import React, { useState, useEffect, useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import classNames from 'classnames';
import {
  getTimezoneDate,
  getTimezoneTime,
  setTimezoneDate,
  setTimezoneTime,
} from '../../helpers/timezones';
import UserService from '../../services/UserService';
import AdminService from '../../services/AdminService';
import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';
import { hasAuthData } from '../../helpers/authStorage';

import InPerson from './InPerson';
import Remote from './Remote';
import RadioButton from '../../components/Form/RadioButton';
import Button from '../../components/Button';

export default function SetOnboardingModal({
  show,
  handleClose,
  candidate,
  refetch,
}) {
  const [onboardingMode, setOnboardingMode] = useState('');
  const [isTriedToSubmitInPersonFormik, setIsTriedToSubmitInPersonFormik] =
    useState(false);

  const { showNotification, setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const isAuthorized = hasAuthData();

  const isEdit = !!(candidate && candidate.jobApplicationOnboardings.length);

  const { data: me } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
    enabled: isAuthorized,
  });

  const closeAndNotify = (message) => {
    handleClose();
    refetch();
    showNotification({
      text: message,
    });
  };

  const remoteValidationSchema = useMemo(
    () =>
      yup.object({
        connectionDetail: yup
          .string()
          .trim()
          .required(t('common.requiredField')),
        onboardingDate: yup.date().required(t('common.requiredField')),
        completeDate: yup.date().required(t('common.requiredField')),
        userIds: yup.array().required(t('common.requiredField')),
        note: yup.string().trim(),
        startTime: yup
          .string()
          .trim()
          .required(t('modals.SetOnboardingModal.startTimeRequired')),
        endTime: yup
          .string()
          .trim()
          .required(t('modals.SetOnboardingModal.endTimeRequired'))
          .test(
            'less-than-start',
            t('modals.SetOnboardingModal.endTimeGreaterThanStartTime'),
            (value, ctx) => {
              if (!ctx.parent.startTime) {
                return true;
              }
              const endTime = parseInt(value?.replace(':', ''), 10);
              const startTime = parseInt(
                ctx.parent.startTime?.replace(':', ''),
                10
              );
              return !(endTime <= startTime);
            }
          ),
      }),
    [t]
  );

  const inPersonValidationSchema = useMemo(
    () =>
      yup.object({
        onboardingDate: yup.date().required(t('common.requiredField')),
        userIds: yup.array().required(t('common.requiredField')),
        note: yup.string().trim(),
        location: yup.object().required(t('common.requiredField')),
        startTime: yup
          .string()
          .trim()
          .required(t('modals.SetOnboardingModal.startTimeRequired')),
        endTime: yup
          .string()
          .trim()
          .required(t('modals.SetOnboardingModal.endTimeRequired'))
          .test(
            'less-than-start',
            t('modals.SetOnboardingModal.endTimeGreaterThanStartTime'),
            (value, ctx) => {
              if (!ctx.parent.startTime) {
                return true;
              }
              const endTime = parseInt(value?.replace(':', ''), 10);
              const startTime = parseInt(
                ctx.parent.startTime?.replace(':', ''),
                10
              );
              return !(endTime <= startTime);
            }
          ),
      }),
    [t]
  );

  const inPersonFormik = useFormik({
    initialValues: {
      onboardingDate:
        isEdit && candidate?.jobApplicationOnboardings?.[0]?.onboardingDate
          ? getTimezoneDate(
              candidate?.jobApplicationOnboardings?.[0]?.onboardingDate,
              me?.userProfile?.timezone,
              candidate?.jobApplicationOnboardings?.[0]?.startTime
            )[0]
          : '',
      location: isEdit
        ? {
            label: candidate?.jobApplicationOnboardings?.[0]?.location?.name,
            value: candidate?.jobApplicationOnboardings?.[0]?.location?.id,
          }
        : '',
      note:
        isEdit && candidate?.jobApplicationOnboardings?.[0]?.note
          ? candidate?.jobApplicationOnboardings?.[0]?.note
          : '',
      startTime:
        isEdit && candidate?.jobApplicationOnboardings?.[0]?.startTime
          ? getTimezoneTime(
              candidate?.jobApplicationOnboardings?.[0]?.startTime,
              me?.userProfile?.timezone
            )
          : '',
      endTime:
        isEdit && candidate?.jobApplicationOnboardings?.[0]?.endTime
          ? getTimezoneTime(
              candidate?.jobApplicationOnboardings?.[0]?.endTime,
              me?.userProfile?.timezone
            )
          : '',
      userIds: isEdit
        ? candidate?.jobApplicationOnboardings?.[0]?.users?.map((user) => ({
            label: user.userName,
            value: user.id,
          }))
        : '',
    },
    validationSchema: inPersonValidationSchema,
    onSubmit: async (values) => {
      try {
        setIsFetching(true);
        if (isEdit) {
          await AdminService.deleteJobApplicationOnboarding({
            jobApplicationId: candidate.id,
            onboardingId: candidate.jobApplicationOnboardings[0].id,
          });
        }

        await AdminService.createJobApplicationOnboarding({
          jobApplicationId: candidate.id,
          onboardingDate: setTimezoneDate(
            values?.onboardingDate,
            values.endTime,
            me?.userProfile?.timezone
          ),
          startTime: setTimezoneTime(
            values.startTime,
            me?.userProfile?.timezone
          ),
          endTime: setTimezoneTime(values.endTime, me?.userProfile?.timezone),
          locationName: values.location.label,
          locationId: values.location.value,
          note: values.note,
          userIds: values.userIds.map((user) => user.value),
          type: 'InPerson',
        });

        await AdminService.changeMyJobApplicationStatus({
          jobApplicationId: candidate.id,
          status: 'Onboard',
          subStatus: 'Scheduled',
        });

        handleClose();
        await refetch();
        closeAndNotify(t('modals.SetOnboardingModal.onboardingRequestSent'));
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    },
    enableReinitialize: true,
  });

  const remoteFormik = useFormik({
    initialValues: {
      startTime:
        isEdit && candidate?.jobApplicationOnboardings?.[0]?.startTime
          ? getTimezoneTime(
              candidate?.jobApplicationOnboardings?.[0]?.startTime,
              me?.userProfile?.timezone
            )
          : '',
      endTime:
        isEdit && candidate?.jobApplicationOnboardings?.[0]?.endTime
          ? getTimezoneTime(
              candidate?.jobApplicationOnboardings?.[0]?.endTime,
              me?.userProfile?.timezone
            )
          : '',
      connectionDetail: isEdit
        ? candidate?.jobApplicationOnboardings?.[0]?.connectionDetail
        : '',
      onboardingDate: isEdit
        ? new Date(candidate?.jobApplicationOnboardings?.[0]?.onboardingDate)
        : '',
      completeDate: isEdit
        ? new Date(candidate?.jobApplicationOnboardings?.[0]?.completeDate)
        : '',
      note: isEdit ? candidate?.jobApplicationOnboardings?.[0]?.note : '',
      userIds: isEdit
        ? candidate?.jobApplicationOnboardings?.[0]?.users?.map((user) => ({
            label: user.userName,
            value: user.id,
          }))
        : '',
    },
    validationSchema: remoteValidationSchema,
    onSubmit: async (values) => {
      try {
        setIsFetching(true);

        await AdminService.createJobApplicationOnboarding({
          jobApplicationId: candidate.id,
          onboardingDate: values.onboardingDate,
          completeDate: values.completeDate,
          note: values.note,
          userIds: values.userIds.map((user) => user.value),
          type: 'Remote',
          connectionDetail: values.connectionDetail,
          startTime: setTimezoneTime(
            values.startTime,
            me?.userProfile?.timezone
          ),
          endTime: setTimezoneTime(values.endTime, me?.userProfile?.timezone),
        });

        await AdminService.changeMyJobApplicationStatus({
          jobApplicationId: candidate.id,
          status: 'Onboard',
          subStatus: 'Scheduled',
        });

        await refetch();
        handleClose();
        closeAndNotify(t('modals.SetOnboardingModal.onboardingRequestSent'));
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    },
    enableReinitialize: true,
  });

  const deleteOnboarding = async () => {
    try {
      setIsFetching(true);
      await AdminService.deleteJobApplicationOnboarding({
        jobApplicationId: candidate.id,
        onboardingId: candidate.jobApplicationOnboardings[0].id,
      });
      closeAndNotify(t('modals.SetOnboardingModal.onboardingRequestCanceled'));
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setOnboardingMode(candidate.jobApplicationOnboardings[0].type);
    }
  }, [candidate, isEdit, show]);

  useEffect(() => {
    return () => {
      setOnboardingMode('');
      remoteFormik.resetForm();
      inPersonFormik.resetForm();
      setIsTriedToSubmitInPersonFormik(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  let isDisabled;

  if (onboardingMode === 'Remote') {
    isDisabled =
      !remoteFormik.values.onboardingDate ||
      !remoteFormik.values.userIds ||
      !remoteFormik.values.startTime ||
      !remoteFormik.values.endTime ||
      !remoteFormik.values.connectionDetail;
  } else {
    isDisabled =
      !inPersonFormik.values.onboardingDate ||
      !inPersonFormik.values.userIds ||
      !inPersonFormik.values.startTime ||
      !inPersonFormik.values.endTime ||
      !inPersonFormik.values.location.label;
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classNames(classes.SetOnboardingModal, {
        [classes.inPerson]: onboardingMode === 'InPerson',
        [classes.remote]: onboardingMode === 'Remote',
      })}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header>
          <h1>
            {t('modals.SetOnboardingModal.setOnboardingDate')}{' '}
            {(candidate?.jobApplicationOnboardings?.[0]?.status ===
              'Accepted' ||
              candidate?.jobApplicationOnboardings?.[0]?.status ===
                'Declined') && (
              <div
                className={classNames(classes.status, {
                  [classes.statusDeclined]:
                    candidate?.jobApplicationOnboardings?.[0]?.status ===
                    'Declined',
                })}
              >
                {candidate?.jobApplicationOnboardings?.[0]?.status}
              </div>
            )}
          </h1>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <div className={classes.container}>
          <div className={classes.row}>
            <div className={classes.onboardingModeSelector}>
              <h2>{t('modals.SetOnboardingModal.chooseOnboardingMode')}</h2>
              <div className={classes.radios}>
                <RadioButton
                  isSelected={onboardingMode === 'InPerson'}
                  label={t('modals.SetOnboardingModal.inPerson')}
                  onClick={() => setOnboardingMode('InPerson')}
                />
                <RadioButton
                  isSelected={onboardingMode === 'Remote'}
                  label={t('modals.SetOnboardingModal.remote')}
                  onClick={() => setOnboardingMode('Remote')}
                />
              </div>
            </div>
          </div>
          {onboardingMode === 'Remote' && (
            <div className={classes.remote}>
              <Remote
                formik={remoteFormik}
                isTriedToSubmit={isTriedToSubmitInPersonFormik}
              />
            </div>
          )}
          {onboardingMode === 'InPerson' && (
            <div className={classes.inperson}>
              <InPerson
                formik={inPersonFormik}
                isTriedToSubmit={isTriedToSubmitInPersonFormik}
              />
            </div>
          )}
        </div>
        <footer>
          <Button
            variant="secondary"
            width={250}
            height={52}
            onClick={
              isEdit
                ? deleteOnboarding
                : () => {
                    remoteFormik.resetForm();
                    inPersonFormik.resetForm();
                  }
            }
          >
            {isEdit ? t('common.delete') : t('common.clear')}
          </Button>
          <Button
            onClick={
              onboardingMode === 'Remote'
                ? remoteFormik.handleSubmit
                : () => {
                    setIsTriedToSubmitInPersonFormik(true);
                    inPersonFormik.handleSubmit();
                  }
            }
            width={250}
            height={52}
            disabled={isDisabled}
          >
            {t('common.submit')}
          </Button>
        </footer>
      </div>
    </Modal>
  );
}
