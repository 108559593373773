/* eslint-disable no-shadow */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Template from '../Template';

export default function Chat({
  deleteNotification,
  isRead,
  notification,
  classes,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const createFeedbackNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const goToChat = () => {
      navigate(
        `/tools/chat?jobApplicationId=${notification.jobApplication.id}`
      );
      hideNotifications();
    };

    const feedbackRating =
      notification.jobApplication?.jobApplicationUserFeedbacks?.[0]
        ?.feedbackRating;

    return feedbackRating > 3
      ? {
          title: `${t(
            'dashboardComponents.Notifications.Notification.Chat.feedbackReceivedTitle'
          )} ${notification.jobApplication?.user?.userProfile?.fullName}`,
          text: `${notification.jobApplication?.jobPost?.jobTitle} (${notification.jobApplication?.jobPost?.id})`,
          buttonLabel: t(
            'dashboardComponents.Notifications.Notification.Chat.review'
          ),
          onClick: goToChat,
        }
      : {
          title: `${t(
            'dashboardComponents.Notifications.Notification.Chat.escalationTitle',
            {
              feedbackRating,
            }
          )} ${notification.jobApplication?.user?.userProfile?.fullName}`,
          text: `${notification.jobApplication?.jobPost?.jobTitle} (${notification.jobApplication?.jobPost?.id})`,
          buttonLabel: t(
            'dashboardComponents.Notifications.Notification.Chat.review'
          ),
          onClick: goToChat,
        };
  };

  const createNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const content = {
      NewFeedback: createFeedbackNotificationContent(
        notification,
        navigate,
        hideNotifications
      ),
    };

    return (
      content[notification.eventNote] ||
      content[notification.name] || {
        title: notification.eventNote,
        buttonLabel: '',
        onClick: () => {},
      }
    );
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
