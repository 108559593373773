import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Stat({ value, label, note, hasNoData }) {
  return (
    <div className={classes.Stat}>
      <div
        className={classNames(classes.value, {
          [classes.hasData]: !hasNoData,
        })}
      >
        {hasNoData ? '-' : value}
      </div>
      <div className={classes.label}>
        {label} {note && <span className={classes.note}>({note})</span>}
      </div>
    </div>
  );
}
