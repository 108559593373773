import i18n from '../i18n';

const t = i18n.t.bind(i18n);

export function getDate(date) {
  return `${date.getDate()}th ${date.toLocaleString('en-us', {
    month: 'short',
  })} ${date.getFullYear()}, ${date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })}`;
}

export function getTimelineTitle(event) {
  const user = `${event?.user?.userProfile?.firstName} ${event?.user?.userProfile?.lastName}`;
  switch (event?.type) {
    case 'JobApplicationExternalAdded':
      return `${user} ${t('helpers.getTimeline.JobApplicationExternalAdded')} ${
        event.jobApplication?.status
      }`;
    case 'JobPostExternalCandidateAdded':
      return `${user} ${t(
        'helpers.getTimeline.JobPostExternalCandidateAdded'
      )} ${event.jobApplication?.status}`;
    case 'JobPostCreated':
      return t('helpers.getTimeline.jobPostCreated');
    case 'JobPostUpdated':
      return 'Job details updated.';
    case 'JobPostStatusChanged':
      return `${t('helpers.getTimeline.jobPostStatusChanged')} ${
        event?.status
      }.`;
    case 'JobPostViewed':
      return `${event?.user ? user : t('helpers.getTimeline.newJobSeeker')} ${t(
        'helpers.getTimeline.jobPostViewed'
      )}`;
    case 'JobPostApplied':
      return `${user} ${t('helpers.getTimeline.jobPostApplied')}`;
    case 'JobApplicationInvitatioCreated':
      return 'Applicant invited to apply.';
    case 'JobPostExpirationExtended':
      return 'Job expiration updated';

    case 'JobApplicationCreated':
      return t('helpers.getTimeline.jobApplicationCreated');
    case 'JobApplicationUpdated':
      return t('helpers.getTimeline.jobApplicationUpdated');
    case 'JobApplicationStatusChanged':
      if (event?.user?.id === event?.jobApplication?.user?.id) {
        return `${t('helpers.getTimeline.jobApplicationStatusChanged')} ${
          event?.status
        } / ${event?.subStatus}`;
      }
      return event?.user
        ? `${t('helpers.getTimeline.jobApplicationStatusChanged')} ${
            event?.status
          } / ${event?.subStatus} ${t('helpers.getTimeline.by')} ${user}.`
        : `${t('helpers.getTimeline.jobApplicationStatusChanged')} ${
            event?.status
          } / ${event?.subStatus}`;
    case 'JobApplicationInvitationCreated':
      return 'Applicant invited to apply.';
    case 'JobApplicationMessageSent':
      return `${user} ${t('helpers.getTimeline.jobApplicationMessageSent')}`;
    case 'JobApplicationFeedbackSent':
      return t('helpers.getTimeline.jobApplicationFeedbackSent');
    case 'JobApplicationViewed':
      return t('helpers.getTimeline.jobApplicationViewed');
    case 'JobApplicatonInterviewFeedbackAdded':
      return t('helpers.getTimeline.jobApplicatonInterviewFeedbackAdded');
    case 'JobApplicationOfferUpdated':
      return t('helpers.getTimeline.jobApplicationOfferUpdated');
    case 'JobApplicationOfferCreated':
      return t('helpers.getTimeline.jobApplicationOfferCreated');
    case 'JobApplicationOnboardingUpdated':
      return t('helpers.getTimeline.jobApplicationOnboardingUpdated');
    case 'JobApplicationOnboardingCreated':
      return t('helpers.getTimeline.jobApplicationOnboardingCreated');
    case 'JobApplicationJoiningUpdated':
      return t('helpers.getTimeline.jobApplicationJoiningUpdated');
    case 'JobApplicationJoiningCreated':
      return t('helpers.getTimeline.jobApplicationJoiningCreated');
    case 'JobApplicationInterviewScheduled':
      return t('helpers.getTimeline.jobApplicationInterviewScheduled');
    case 'JobApplicationInterviewUpdated':
      return t('helpers.getTimeline.jobApplicationInterviewUpdated');
    case 'JobApplicationMessageAddressed':
      return `${user} ${t(
        'helpers.getTimeline.jobApplicationMessageAddressed'
      )}`;

    default:
      return '';
  }
}
