import React from 'react';

import CandidateCard from './CandidateCard';

import classes from './styles.module.scss';

export default function RecentlyViewed({
  candidates,
  setIsCandidatePopupVisible,
  setCurrentCandidate,
}) {
  return (
    <div className={classes.RecentlyViewed}>
      <h2>Recently Viewed</h2>
      <div className={classes.list}>
        <ul>
          {candidates?.map((candidate) => (
            <CandidateCard
              key={candidate.id}
              candidate={candidate}
              setIsCandidatePopupVisible={setIsCandidatePopupVisible}
              setCurrentCandidate={setCurrentCandidate}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
