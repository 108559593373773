import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../../../../../../Button';

import classes from './styles.module.scss';

export default function SaveResetButtons({
  onSave,
  onReset,
  isSaveDisabled,
  isResetDisabled,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.SaveResetButtons}>
      <Button width={92} height={45} onClick={onSave} disabled={isSaveDisabled}>
        {t('common.save')}
      </Button>
      <Button
        disabled={isResetDisabled}
        variant="secondary"
        className={classes.resetButton}
        type="button"
        onClick={onReset}
        width={92}
        height={45}
      >
        {t('common.reset')}
      </Button>
    </div>
  );
}
