import classNames from 'classnames';
import React, { useState, useEffect } from 'react';

import classes from './styles.module.scss';

export default function Badge({
  badge,
  areBadgesEnabled,
  enabledBadges,
  setEnabledBadges,
}) {
  const [isBadgeActive, setIsBadgeActive] = useState(false);

  useEffect(() => {
    setIsBadgeActive(enabledBadges.some(({ id }) => id === badge.id));
  }, [areBadgesEnabled, badge.id, enabledBadges]);

  useEffect(() => {
    if (isBadgeActive) {
      setEnabledBadges((prev) => [...prev, badge]);

      return;
    }

    setEnabledBadges((prev) => {
      const currentBadgeIndex = prev.findIndex((tag) => tag === badge);
      prev.splice(currentBadgeIndex, 1);
      return [...prev];
    });
  }, [badge, isBadgeActive, setEnabledBadges]);

  useEffect(() => {
    if (!areBadgesEnabled) {
      setIsBadgeActive(false);
    }
  }, [areBadgesEnabled]);

  return (
    <div
      onClick={() => {
        if (!areBadgesEnabled) {
          return;
        }
        setIsBadgeActive((prevState) => !prevState);
      }}
      className={classNames(classes.Badge, {
        [classes.active]: isBadgeActive,
      })}
    >
      {badge.name}
    </div>
  );
}
