import React from 'react';

import useDropdown from '../../../../hooks/useDropdown';
import classes from './styles.module.scss';

export default function DashboardDropdown({
  currentOption,
  setCurrentOption,
  options,
  placeholder,
}) {
  const dropdown = useDropdown({
    currentOption,
    setCurrentOption,
    options,
    classes,
    placeholder,
  });

  return <>{dropdown}</>;
}
