import React, { useEffect, useRef } from 'react';

import Message from './Message';

import classes from './styles.module.scss';

export default function MessagesList({
  chatMessages,
  jobApplicationUserFeedbacks,
  activeContact,
  refetchMessages,
  messageListHeight,
}) {
  const messageListRef = useRef();

  useEffect(() => {
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  }, [chatMessages]);

  return (
    <div
      className={classes.MessagesList}
      ref={messageListRef}
      style={{ height: messageListHeight }}
    >
      {chatMessages
        ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        .map((message) => (
          <Message
            key={message.id}
            message={message}
            jobApplicationUserFeedbacks={jobApplicationUserFeedbacks}
            jobApplicationId={
              activeContact?.jobApplicationId || activeContact?.id
            }
            refetchMessages={refetchMessages}
          />
        ))}
    </div>
  );
}
