import * as signalR from '@microsoft/signalr';

import { getAuthData } from './authStorage';
import { PRESENCE_HUB_URL } from '../constants/main';

export default function onlineConnection() {
  const { accessToken } = getAuthData();
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(PRESENCE_HUB_URL, {
      accessTokenFactory: () => {
        return accessToken;
      },
    })
    .configureLogging(signalR.LogLevel.Information)
    .build();

  const startConnection = async () => {
    try {
      await connection.start();
    } catch (error) {
      console.error(error);
    }
  };

  const endConnection = async () => {
    try {
      await connection.stop();
    } catch (error) {
      console.error(error);
    }
  };

  return {
    connection,
    startConnection,
    endConnection,
  };
}
