import React, { useState, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PinIcon from '../PinIcon';
import ArrowMenu from '../../../../../../ArrowMenu';

import processEditingPostContent from '../../../../../../../helpers/processEditingPostContent';
import { useFeedStore } from '../../../../../../../store';
import FeedService from '../../../../../../../services/FeedService';
import { UiContext } from '../../../../../../../context/UiContext';
import classes from './styles.module.scss';

export default function PostMenu({
  feedPost,
  refetch,
  setEditingPost,
  role,
  postRef,
  isHiddenTabActive,
}) {
  const [isArrowMenuVisible, setIsArrowMenuVisible] = useState(false);

  const { setIsFetching, showModal, showNotification } = useContext(UiContext);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const setEditingPostRef = useFeedStore((state) => state.setEditingPostRef);
  const sharedClasses = useFeedStore((state) => state.sharedClasses);
  const setQuoteContainers = useFeedStore((state) => state.setQuoteContainers);

  const togglePin = async () => {
    try {
      setIsFetching(true);
      await FeedService.toggleFeedPostPin(feedPost);
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const unhidePost = async () => {
    try {
      setIsFetching(true);
      await FeedService.unhideFeedPost(feedPost.id);
      refetch();
      // showNotification({ text: 'Content will be shown in your main feed' });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const hidePost = async () => {
    try {
      setIsFetching(true);
      await FeedService.hideFeedPost(feedPost.id);
      refetch();
      showNotification({
        text: t(
          'dashboardComponents.Discover.Feed.Post.Header.PostMenu.Content will be removed from your main feed'
        ),
        undo: unhidePost,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const deletePost = () => {
    showModal({
      type: 'confirm',
      title: t(
        'dashboardComponents.Discover.Feed.Post.Header.PostMenu.Delete post'
      ),
      text: t(
        'dashboardComponents.Discover.Feed.Post.Header.PostMenu.Are you sure you want to delete?'
      ),
      dismissButtonLabel: t('common.no'),
      onConfirm: async () => {
        try {
          setIsFetching(true);
          await FeedService.deleteFeedPost(feedPost.id);
          await refetch();
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      },
    });
  };

  const editPost = () => {
    const { content, quotes } = processEditingPostContent(
      feedPost.content,
      sharedClasses
    );

    const editingPost = { ...feedPost, content };

    setQuoteContainers(quotes);

    navigate('/tools/discover');
    setEditingPost(editingPost);
    setEditingPostRef(postRef);
  };

  useEffect(() => {
    const hideOnScroll = () => {
      setIsArrowMenuVisible(false);
    };

    window.addEventListener('wheel', hideOnScroll);

    return () => {
      window.removeEventListener('wheel', hideOnScroll);
    };
  }, []);

  const adminOptions = [
    {
      label: feedPost.isPinned
        ? t('dashboardComponents.Discover.Feed.Post.Header.PostMenu.Unpin')
        : t('dashboardComponents.Discover.Feed.Post.Header.PostMenu.Pin'),
      onClick: togglePin,
    },
    {
      label: feedPost.isPinned
        ? t(
            'dashboardComponents.Discover.Feed.Post.Header.PostMenu.Unpin for all'
          )
        : t(
            'dashboardComponents.Discover.Feed.Post.Header.PostMenu.Pin for all'
          ),
      onClick: togglePin,
    },
    {
      label: t(
        'dashboardComponents.Discover.Feed.Post.Header.PostMenu.Copy link'
      ),
      onClick: () => {
        navigator.clipboard.writeText(
          `${window.location.origin}/tools/discover/posts/${feedPost.id}`
        );
      },
    },
    {
      label: t('common.edit'),
      onClick: editPost,
    },
    {
      label: isHiddenTabActive
        ? t('dashboardComponents.Discover.Feed.Post.Header.PostMenu.Unhide')
        : t('dashboardComponents.Discover.Feed.Post.Header.PostMenu.Hide'),
      onClick: isHiddenTabActive ? unhidePost : hidePost,
    },
    {
      label: t('common.delete'),
      onClick: deletePost,
    },
  ];

  if (feedPost.systemAnnouncementType) {
    adminOptions.splice(3, 1);
  }

  const authorOptions = [
    {
      label: feedPost.isPinned
        ? t('dashboardComponents.Discover.Feed.Post.Header.PostMenu.Unpin')
        : t('dashboardComponents.Discover.Feed.Post.Header.PostMenu.Pin'),
      onClick: togglePin,
    },
    {
      label: t(
        'dashboardComponents.Discover.Feed.Post.Header.PostMenu.Copy link'
      ),
      onClick: () => {},
    },
    {
      label: t('common.edit'),
      onClick: editPost,
    },
    {
      label: isHiddenTabActive
        ? t('dashboardComponents.Discover.Feed.Post.Header.PostMenu.Unhide')
        : t('dashboardComponents.Discover.Feed.Post.Header.PostMenu.Hide'),
      onClick: isHiddenTabActive ? unhidePost : hidePost,
    },
    {
      label: t('common.delete'),
      onClick: deletePost,
    },
  ];

  const viewerOptions = [
    {
      label: feedPost.isPinned
        ? t('dashboardComponents.Discover.Feed.Post.Header.PostMenu.Unpin')
        : t('dashboardComponents.Discover.Feed.Post.Header.PostMenu.Pin'),
      onClick: togglePin,
    },
    {
      label: t(
        'dashboardComponents.Discover.Feed.Post.Header.PostMenu.Copy link'
      ),
      onClick: () => {},
    },
    {
      label: isHiddenTabActive
        ? t('dashboardComponents.Discover.Feed.Post.Header.PostMenu.Unhide')
        : t('dashboardComponents.Discover.Feed.Post.Header.PostMenu.Hide'),
      onClick: isHiddenTabActive ? unhidePost : hidePost,
    },
  ];

  let optionsToShow = viewerOptions;

  if (role === 'admin') {
    optionsToShow = adminOptions;
  } else if (role === 'author') {
    optionsToShow = authorOptions;
  }

  return (
    <div className={classes.PostMenu}>
      <ArrowMenu
        isVisible={isArrowMenuVisible}
        setIsVisible={setIsArrowMenuVisible}
        options={optionsToShow}
        width={109}
      >
        <button type="button" className={classes.dotsButton}>
          <svg
            width="20"
            height="5"
            viewBox="0 0 20 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2.00781" r="2" fill="#D9D9D9" />
            <circle cx="10" cy="2.00781" r="2" fill="#D9D9D9" />
            <circle cx="18" cy="2.00781" r="2" fill="#D9D9D9" />
          </svg>
        </button>
      </ArrowMenu>
      <PinIcon isPinned={feedPost.isPinned} togglePin={togglePin} />
    </div>
  );
}
