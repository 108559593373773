import React from 'react';
import classNames from 'classnames';
import { mapReport } from '../../../../../../../helpers/mapReports';
import classes from './styles.module.scss';

export default function Column({ column, toggleSelection, isSelected }) {
  return (
    <div className={classes.Column} onClick={toggleSelection}>
      <div
        className={classNames(classes.checkbox, {
          [classes.active]: isSelected,
        })}
      />
      <span>{mapReport(column)}</span>
    </div>
  );
}
