import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function SearchInput({ value, setValue }) {
  const { t } = useTranslation();

  return (
    <div className={classes.SearchInput}>
      <input
        type="text"
        placeholder={t('modals.AssessmentModal.searchInput.searchByName')}
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />
    </div>
  );
}
