import React from 'react';

export default function FavoritesIcon({ size }) {
  return (
    <svg
      style={{ width: size, height: size }}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="url(#paint0_linear_1284_42733)" />
      <path
        d="M11.7672 20.7207C11.4455 20.8899 11.0695 20.6167 11.1309 20.2584L11.7483 16.6586L9.13291 14.1093C8.87263 13.8556 9.01626 13.4135 9.37596 13.3613L12.9903 12.8361L14.6067 9.56086C14.7676 9.23492 15.2324 9.23492 15.3932 9.56086L17.0097 12.8361L20.624 13.3613C20.9837 13.4135 21.1274 13.8556 20.8671 14.1093L18.2517 16.6586L18.8691 20.2584C18.9305 20.6167 18.5545 20.8899 18.2328 20.7207L15 19.0211L11.7672 20.7207Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1284_42733"
          x1="15"
          y1="-16.8993"
          x2="-18.7986"
          y2="13.1007"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E4AC" />
          <stop offset="0.504968" stopColor="#10C5E9" />
          <stop offset="1" stopColor="#9240DC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
