/* eslint-disable no-restricted-globals */
import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import classes from './styles.module.scss';

function formatValue(value) {
  // Check if the value is a number or can be converted to a number
  if (!isNaN(parseFloat(value)) && isFinite(value)) {
    // Convert to number in case it's a numeric string
    const numericValue = Number(value);

    // Check if it's an integer (no decimal places)
    if (Number.isInteger(numericValue)) {
      return numericValue;
    }
    // If it's a float, use toFixed(2)
    return numericValue.toFixed(1);
  }
  // If it's not a number or can't be converted to a number, return a meaningful message
  return 0;
}

export default function Stat({ metric }) {
  const { t } = useTranslation();

  return (
    <div className={classes.Stat}>
      <span className={classes.title}>{metric.title}</span>
      <div className={classes.container}>
        {metric.amount ? (
          <>
            <div
              className={classNames(classes.amount, {
                [classes.percentage]: metric.percentage,
                [classes.minutes]: metric.type === 'minutes',
              })}
            >
              {formatValue(metric.amount)}
            </div>
            <span
              className={classNames(classes.last, {
                [classes.zero]: metric.last === 0,
                [classes.percentageLast]: metric.percentage,
              })}
            >
              {formatValue(metric.last)}
            </span>
          </>
        ) : (
          <>
            {[
              t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.Stat.jobViews'
              ),
              t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.Stat.dropOffRate'
              ),
            ].includes(metric.title) ? (
              <div className={classNames(classes.amount, classes.zero)}>0</div>
            ) : (
              <span className={classes.noData}>
                {t(
                  'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.Stat.noData'
                )}
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
}
