import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Blockings from './Blockings';
import PicVideo from './PicVideo';
import Copies from './Copies';
import Tabs from '../Tabs';

import classes from './styles.module.scss';

export default function ContentManagement() {
  const [copyCount, setCopyCount] = useState(0);
  const [picVideoCount, setPicVideoCount] = useState(0);
  const [blockingCount, setBlockingCount] = useState(0);

  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(
    t('dashboardComponents.Console.ContentManagement.Copy')
  );

  const tabs = [
    t('dashboardComponents.Console.ContentManagement.Copy'),
    t('dashboardComponents.Console.ContentManagement.Pic/Video'),
    t('dashboardComponents.Console.ContentManagement.Blocking'),
  ];

  return (
    <div className={classes.ContentManagement}>
      <Tabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        name="content"
        tabs={tabs}
        countData={{
          [t('dashboardComponents.Console.ContentManagement.Copy')]: copyCount,
          [t('dashboardComponents.Console.ContentManagement.Pic/Video')]:
            picVideoCount,
          [t('dashboardComponents.Console.ContentManagement.Blocking')]:
            blockingCount,
        }}
      />
      <div className={classes.container}>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.ContentManagement.Copy')
                ? 'block'
                : 'none',
          }}
        >
          <Copies setItemCount={setCopyCount} />
        </div>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.ContentManagement.Pic/Video')
                ? 'block'
                : 'none',
          }}
        >
          <PicVideo setItemCount={setPicVideoCount} />
        </div>
        <div
          style={{
            display:
              currentTab ===
              t('dashboardComponents.Console.ContentManagement.Blocking')
                ? 'block'
                : 'none',
          }}
        >
          <Blockings setItemCount={setBlockingCount} />
        </div>
      </div>
    </div>
  );
}
