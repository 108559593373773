import React, { useState, useEffect, useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import classNames from 'classnames';
import {
  getTimezoneDate,
  getTimezoneTime,
  setTimezoneDate,
  setTimezoneTime,
} from '../../helpers/timezones';
import UserService from '../../services/UserService';
import AdminService from '../../services/AdminService';
import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';
import { hasAuthData } from '../../helpers/authStorage';

import TimeRange from '../../components/Form/TimeRange';
import SelectedUsers from '../../components/Dashboard/SelectedUsers';
import Textarea from '../../components/Form/Textarea';
import MultiDropdown from '../../components/Form/MultiDropdown';
import FormDropdown from '../../components/Form/FormDropdown';
import DatePicker from '../../components/Form/DatePicker';
import Button from '../../components/Button';

export default function SetJoiningModal({
  show,
  handleClose,
  candidate,
  refetch,
}) {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const { showNotification, setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const isAuthorized = hasAuthData();

  const isEdit = candidate && candidate.jobApplicationJoinings?.length;

  const { data: me } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
    enabled: isAuthorized,
  });

  const closeAndNotify = (message) => {
    handleClose();
    refetch();
    showNotification({
      text: message,
    });
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        joiningDate: yup
          .date()
          .required(t('modals.SetJoiningModal.requiredField')),
        location: yup.object(),
        userIds: yup
          .array()
          .required(t('modals.SetJoiningModal.requiredField')),
        note: yup.string().trim(),
        startTime: yup.string().trim().required(t('common.startTimeRequired')),
        endTime: yup
          .string()
          .trim()
          .required(t('common.endTimeRequired'))
          .test(
            'less-than-start',
            t('common.endTimeGreaterThanStartTime'),
            (value, ctx) => {
              if (!ctx.parent.startTime) {
                return true;
              }
              const endTime = parseInt(value?.replace(':', ''), 10);
              const startTime = parseInt(
                ctx.parent.startTime?.replace(':', ''),
                10
              );
              return !(endTime <= startTime);
            }
          ),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      joiningDate: isEdit
        ? getTimezoneDate(
            candidate?.jobApplicationJoinings?.[0]?.joiningDate,
            me?.userProfile?.timezone,
            candidate?.jobApplicationJoinings?.[0]?.startTime
          )[0]
        : '',
      location: isEdit
        ? {
            value: candidate?.jobApplicationJoinings?.[0]?.location?.id,
            label: candidate?.jobApplicationJoinings?.[0]?.location?.name,
          }
        : '',
      userIds: isEdit
        ? candidate?.jobApplicationJoinings?.[0]?.users?.map((user) => ({
            label: user.userName,
            value: user.id,
          }))
        : '',
      note: isEdit ? candidate?.jobApplicationJoinings?.[0]?.note : '',
      startTime: isEdit
        ? getTimezoneTime(
            candidate?.jobApplicationJoinings?.[0]?.startTime,
            me?.userProfile?.timezone
          )
        : '',
      endTime: isEdit
        ? getTimezoneTime(
            candidate?.jobApplicationJoinings?.[0]?.endTime,
            me?.userProfile?.timezone
          )
        : '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFetching(true);

        if (isEdit) {
          await AdminService.deleteJobApplicationStartDate({
            jobApplicationId: candidate.id,
            joiningid: candidate.jobApplicationJoinings[0].id,
          });
        }

        await AdminService.createJobApplicationStartDate({
          joiningDate: setTimezoneDate(
            values.joiningDate,
            values.startTime,
            me?.userProfile?.timezone
          ),
          locationName: values.location.label,
          locationId: values.location.value,
          userIds: values.userIds.map((user) => user.value),
          note: values.note,
          startTime: setTimezoneTime(
            values.startTime,
            me?.userProfile?.timezone
          ),
          endTime: setTimezoneTime(values.endTime, me?.userProfile?.timezone),
          jobApplicationId: candidate.id,
        });

        handleClose();
        await refetch();
        closeAndNotify(
          isEdit
            ? t('modals.SetJoiningModal.startDateUpdated')
            : t('modals.SetJoiningModal.startDateSent')
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    },
    enableReinitialize: true,
  });

  const deleteStartDate = async () => {
    try {
      setIsFetching(true);
      await AdminService.deleteJobApplicationStartDate({
        jobApplicationId: candidate.id,
        joiningid: candidate.jobApplicationJoinings[0].id,
      });
      closeAndNotify(t('modals.SetJoiningModal.canceled'));
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    return () => {
      formik.resetForm();
      setIsTriedToSubmit(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const isDisabled = Object.keys(formik.values).some(
    (key) => !formik.values[key] && key !== 'note'
  );

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.SetJoiningModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header>
          <h1>
            {t('modals.SetJoiningModal.setStartDate')}
            {(candidate?.jobApplicationJoinings?.[0]?.status === 'Accepted' ||
              candidate?.jobApplicationJoinings?.[0]?.status ===
                'Declined') && (
              <div
                className={classNames(classes.status, {
                  [classes.statusDeclined]:
                    candidate?.jobApplicationJoinings?.[0]?.status ===
                    'Declined',
                })}
              >
                {candidate?.jobApplicationJoinings?.[0]?.status}
              </div>
            )}
          </h1>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <div className={classes.container}>
          <div className={classes.row}>
            <div className={classes.col}>
              <DatePicker
                dateFieldWidth={350}
                label={t('modals.SetJoiningModal.startDate')}
                name="joiningDate"
                value={formik.values.joiningDate}
                setFieldValue={formik.setFieldValue}
                error={formik.errors.joiningDate}
                setFieldTouched={formik.setFieldTouched}
              />
            </div>
            <div className={classes.col}>
              <TimeRange
                fromValue={formik.values.startTime}
                toValue={formik.values.endTime}
                setFieldValue={formik.setFieldValue}
                fromName="startTime"
                toName="endTime"
                error={formik.errors.endTime}
                touched={isTriedToSubmit}
                label={t('modals.SetJoiningModal.time')}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <FormDropdown
                height={50}
                width={350}
                label={t('modals.SetJoiningModal.location')}
                placeholder={t('modals.SetJoiningModal.selectLocation')}
                fetchOptions={{
                  dataName: 'restaurantsOptionsBasicInfo',
                  getDataHandler: AdminService.getRestaurants,
                }}
                value={formik.values.location}
                infoWidth={151}
                name="location"
                searchInfo={t('modals.SetJoiningModal.locationSearchInfo')}
                searchInfoWidth={188}
                setFieldValue={formik.setFieldValue}
              />
            </div>
            <div className={classes.col}>
              <MultiDropdown
                height={50}
                maxLength={3}
                note={t('modals.SetJoiningModal.selectUpTo3')}
                width={350}
                label={t('modals.SetJoiningModal.personToMeet')}
                fetchOptions={{
                  dataName: 'userOptionPostJobSuccess',
                  getDataHandler: AdminService.getUsers,
                  labelName: 'userName',
                  isUserList: true,
                }}
                value={formik.values.userIds}
                name="userIds"
                setFieldValue={formik.setFieldValue}
                placeholder={t('modals.SetJoiningModal.selectName')}
              />

              <div className={classes.selectedUsers}>
                <SelectedUsers
                  invitedUsers={formik.values.userIds}
                  withFormik
                  setInvitedUsers={(users) =>
                    formik.setFieldValue('userIds', users)
                  }
                />
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <Textarea
                name="note"
                onChange={formik.handleChange}
                value={formik.values.note}
                label={t('modals.SetJoiningModal.emailMessage')}
                note={t('modals.SetJoiningModal.optional')}
                height={145}
                placeholder={t('modals.SetJoiningModal.enterNotes')}
                // maxLength={5000}
              />
            </div>
          </div>
        </div>
        <footer>
          <Button
            variant="secondary"
            width={250}
            height={52}
            onClick={isEdit ? deleteStartDate : formik.resetForm}
          >
            {isEdit
              ? t('modals.SetJoiningModal.delete')
              : t('modals.SetJoiningModal.clear')}
          </Button>
          <Button
            width={250}
            height={52}
            disabled={isDisabled}
            onClick={() => {
              setIsTriedToSubmit(true);
              formik.handleSubmit();
            }}
          >
            {t('common.submit')}
          </Button>
        </footer>
      </div>
    </Modal>
  );
}
