/* eslint-disable react/button-has-type */
import classNames from 'classnames';
import React from 'react';

import classes from './styles.module.scss';

export default function Button({
  children,
  variant,
  width,
  height,
  onClick,
  disabled,
  type,
  onMouseEnter,
  onMouseLeave,
  style,
  className,
}) {
  return (
    <button
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      style={{ ...style, width, height, minHeight: height }}
      className={classNames(classes.Button, className, {
        [classes.secondary]: variant === 'secondary',
        [classes.delete]: variant === 'delete',
        [classes.modal]: variant === 'modal',
        [classes.report]: variant === 'report',
        [classes.print]: variant === 'print',
        [classes.black]: variant === 'black',
        [classes.setWidth]: !!width,
      })}
      type={type || 'button'}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
