import axios from 'axios';

import { JOBS_API_URL_V2, JOBS_API_URL, CMS_API_URL } from '../constants/main';

import { getNonPaginatedData } from '../helpers/requestData';

const CandidatesService = {
  async getCandidates({ term, pageNumber, pageSize, orderBy, sortOrder }) {
    const url = `${JOBS_API_URL_V2}/candidates`;
    return getNonPaginatedData({
      term,
      pageNumber,
      pageSize,
      orderBy,
      sortOrder,
      url,
    });
  },

  async checkCandidateEmail(email) {
    const response = await axios.post(
      `${JOBS_API_URL_V2}/candidates/validate`,
      { email }
    );
    return response.data;
  },

  async createCandidate({
    firstName,
    lastName,
    email,
    phone,
    birthDate,
    customLocation,
    userProfileGraduation,
    sourceName,
    userProfileExperiences,
    userProfileFileResume,
    jobPostId,
    applicationStatus,
    applicationSubStatus,
  }) {
    const sourceNameValue = sourceName.value;
    const userProfileGraduationValue = { id: userProfileGraduation.value };

    const data = {
      firstName,
      lastName,
      email,
      phone,
      birthDate,
      customLocation,
      userProfileGraduation: userProfileGraduationValue,
      sourceType: 'External',
      sourceName: sourceNameValue,
      userProfileExperiences,
      userProfileFileResume,
      jobPostId: jobPostId?.value,
      applicationStatus: applicationStatus?.value,
      applicationSubStatu: applicationSubStatus || '',
    };

    if (!data.jobPostId) {
      delete data.applicationSubStatus;
    }

    const response = await axios.post(`${JOBS_API_URL_V2}/candidates`, data);
    return response.data;
  },

  async updateCandidate({
    id,
    firstName,
    lastName,
    email,
    phone,
    birthDate,
    customLocation,
    userProfileGraduation,
    sourceName,
    userProfileExperiences,
    userProfileFileResume,
    jobPostId,
    applicationStatus,
    applicationSubStatus,
  }) {
    const sourceNameValue = sourceName.value;
    const userProfileGraduationValue = { id: userProfileGraduation.value };

    const data = {
      firstName,
      lastName,
      email,
      phone,
      birthDate,
      customLocation,
      userProfileGraduation: userProfileGraduationValue,
      sourceName: sourceNameValue,
      userProfileExperiences,
      userProfileFileResume,
      sourceType: 'External',
      jobPostId: jobPostId?.value,
      applicationStatus: applicationStatus?.value,
      applicationSubStatus,
    };

    if (!data.jobPostId) {
      delete data.applicationSubStatus;
    }

    const response = await axios.put(`${JOBS_API_URL}/candidates/${id}`, data);
    return response.data;
  },

  async uploadResume(file) {
    const form = new FormData();
    form.append('File', file);
    const response = await axios.post(`${CMS_API_URL}/uploads/files`, form);

    return response.data;
  },

  async deleteCandidate(id) {
    const response = await axios.delete(`${JOBS_API_URL}/candidates/${id}`);
    return response.data;
  },

  async getJobPost(id) {
    const response = await axios.get(`${JOBS_API_URL}/jobposts/${id}`);
    return response.data;
  },
};

export default CandidatesService;
