import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Tab({ tab, isActive, onClick, reference }) {
  return (
    <div
      ref={reference}
      onClick={onClick}
      role="tab"
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onClick();
        }
      }}
      className={classNames(classes.Tab, {
        [classes.active]: isActive,
      })}
    >
      {tab}
    </div>
  );
}
