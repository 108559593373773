import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import TimeDropdown from './TimeDropdown';

import classes from './styles.module.scss';

export default function TimeRange({
  label,
  fromValue,
  toValue,
  setFieldValue,
  fromName,
  toName,
  error,
  touched,
  showDuration,
  height,
  labelStyle,
  style,
}) {
  const { t } = useTranslation();

  let hoursDuration = 0;
  let minutesDuration = 0;
  let duration = 0;
  if (fromValue && toValue) {
    const [startHours, startMinutes] = fromValue.split(':');
    const [endHours, endMinutes] = toValue.split(':');
    const startTime =
      parseInt(startHours, 10) * 60 + parseInt(startMinutes, 10);
    const endTime = parseInt(endHours, 10) * 60 + parseInt(endMinutes, 10);

    duration = endTime - startTime;

    if (duration < 60) {
      hoursDuration = 0;
    } else {
      hoursDuration = Math.floor(duration / 60);
    }

    minutesDuration = Math.max(duration % 60, 0);
  }

  return (
    <div className={classes.TimeRange}>
      <div className={classes.title}>
        <h2 style={labelStyle}>
          {label || t('formComponents.TimeRange.title')}
        </h2>
        {showDuration ? (
          <div
            className={classNames(classes.duration, {
              [classes.gray]: duration < 30,
              [classes.gradient]: duration > 0 && duration <= 60,
              [classes.green]: duration === 90,
              [classes.orange]: duration === 120,
              [classes.red]: duration > 120,
            })}
          >
            <span className={classes.bold}>{hoursDuration}</span>{' '}
            {t('formComponents.TimeRange.hr')}
            <span className={classes.bold}>{minutesDuration}</span>
            {t('formComponents.TimeRange.mins')}
          </div>
        ) : (
          <span>(Your local time)</span>
        )}
      </div>
      <div className={classes.dropdowns}>
        <TimeDropdown
          name={fromName}
          setFieldValue={setFieldValue}
          value={fromValue}
          height={height}
          style={style}
        />
        <TimeDropdown
          name={toName}
          setFieldValue={setFieldValue}
          value={toValue}
          error={touched && error}
          fromValue={fromValue}
          height={height}
          style={style}
        />
      </div>
      {touched && error && <div className={classes.error}>{error}</div>}
    </div>
  );
}
