/* eslint-disable react/jsx-handler-names */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, useRef, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  rejectCandidate,
  moveCandidateToPreviousStage,
  sendReminder,
} from '../../../../../../../../../helpers/candidates';
import { UiContext } from '../../../../../../../../../context/UiContext';
import { JobsContext } from '../../../../../../../../../context/JobsContext';
import useOnClickOutside from '../../../../../../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';
import AdminService from '../../../../../../../../../services/AdminService';

export default function CandidateMenu({
  candidate,
  changeJobApplicationStatus,
  refetch,
  jobStatus,
  hasAssessment,
  isCandidatePopup,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const { t } = useTranslation();

  const hideOptionsOnClick = (event, action) => {
    event.nativeEvent?.stopImmediatePropagation();
    event.stopPropagation();
    if (action) {
      action();
    }
    setIsOptionsListVisible(false);
  };

  const selectorRef = useRef();

  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, hideOptionsOnClick);

  const {
    showReportCandidateModal,
    showShareModal,
    showSetOnboardingModal,
    showScheduleInterviewModal,
    showSetJoiningModal,
    showOfferModal,
    showRejectCandidateWithReasonModal,
    showChangeStageWithExceptionModal,
    showNextStepsModal,
  } = useContext(JobsContext);

  const { showModal, showNotification, setIsFetching } = useContext(UiContext);
  const { showAssessmentModal } = useContext(JobsContext);

  const hasOnboarding = candidate?.jobApplicationOnboardings?.length;
  const hasJoining = candidate?.jobApplicationJoinings?.length;
  const jobApplicationHasAssessment = candidate?.assignedAssessments?.length;

  const requestNoShowReject = () => {
    showModal({
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rejectCandidateModalTitle'
      ),
      text: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rejectCandidateModalText'
      ),
      onConfirm: () =>
        rejectCandidate(
          candidate,
          refetch,
          showNotification,
          true,
          setIsFetching
        ),
      dismissButtonLabel: t('common.no'),
    });
  };

  const cancelInterview = async () => {
    try {
      setIsFetching(true);

      await AdminService.cancelJobApplicationInterview({
        jobApplicationId: candidate.id,
        interviewId: candidate.jobApplicationInterviews[0].id,
      });
      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const setJobApplicationStatus = async (jobApplicationId, status) => {
    try {
      setIsFetching(true);

      await AdminService.changeMyJobApplicationStatus({
        jobApplicationId,
        status: status.status,
        subStatus: status.subStatus,
      });
      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const rejectOption = {
    title: t(
      'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rejectTitle'
    ),
    onClick: (event) =>
      hideOptionsOnClick(event, () => {
        showRejectCandidateWithReasonModal({ candidate, refetch });
      }),
  };

  const standardOptions = isCandidatePopup
    ? []
    : [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.chatComingSoonTitle'
          ),
          disabled: true,
          onClick: (event) => hideOptionsOnClick(event, () => {}),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.emailCandidateTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              window.location.href = `mailto:${candidate.user?.userName}`;
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.shareTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => showShareModal({ candidate })),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.blockAndReportTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () =>
              showReportCandidateModal({ candidate, refetch })
            ),
        },
      ];

  const assessmentOption = jobApplicationHasAssessment
    ? [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.sendReminderTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () =>
              sendReminder(candidate, refetch, showNotification, setIsFetching)
            ),
        },
      ]
    : [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.assignAssessmentTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () =>
              showAssessmentModal({ candidate, refetch })
            ),
        },
      ];

  const newOptions = [
    {
      title: hasAssessment
        ? t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToScreenTitle'
          )
        : t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToInterviewTitle'
          ),
      onClick: (event) =>
        hideOptionsOnClick(event, () => {
          if (jobStatus === 'UnPublished') {
            return;
          }
          changeJobApplicationStatus(
            candidate.id,
            hasAssessment
              ? { status: 'Screen', subStatus: 'Requested' }
              : { status: 'Interview', subStatus: 'Pending' },
            () =>
              moveCandidateToPreviousStage(
                candidate?.status,
                candidate?.subStatus,
                candidate,
                refetch,
                setIsFetching
              )
          );
        }),
    },
    {
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rejectTitle'
      ),
      onClick: (event) =>
        hideOptionsOnClick(event, () => {
          showModal({
            title: t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rejectCandidateWithTitle'
            ),
            text: t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rejectCandidateText'
            ),
            onConfirm: () =>
              rejectCandidate(
                candidate,
                refetch,
                showNotification,
                false,
                setIsFetching
              ),
            dismissButtonLabel: t('common.no'),
          });
        }),
    },
    {
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.assignAssessmentTitle'
      ),
      onClick: (event) =>
        hideOptionsOnClick(event, () =>
          showAssessmentModal({ candidate, refetch })
        ),
    },
    ...standardOptions,
  ];

  const interviewAdvancedStagesOptions = [
    {
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.editInterviewTitle'
      ),
      onClick: (event) =>
        hideOptionsOnClick(event, () => {
          showScheduleInterviewModal({ candidate, refetch });
        }),
    },
    {
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToOfferTitle'
      ),
      onClick: (event) =>
        hideOptionsOnClick(event, () => {
          if (jobStatus === 'UnPublished') {
            return;
          }
          showChangeStageWithExceptionModal({
            candidate,
            refetch,
            stage: 'Offer',
          });
        }),
    },
    rejectOption,
    ...assessmentOption,
    ...standardOptions,
  ];

  const pendingOrRejectedOfferOptions = [
    {
      title: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.sendOfferTitle'
      ),
      onClick: (event) =>
        hideOptionsOnClick(event, () => {
          showOfferModal({ candidate, refetch });
        }),
    },
    rejectOption,
    ...assessmentOption,
    ...standardOptions,
  ];

  const options = {
    Invited: {
      Pending: [rejectOption, ...standardOptions],
      Rejected: standardOptions,
      Withdrawn: standardOptions,
    },
    New: {
      Pending: newOptions,
      Rejected: standardOptions,
      Accepted: newOptions,
      Withdrawn: standardOptions,
    },
    Screen: {
      Requested: [rejectOption, ...assessmentOption, ...standardOptions],
      Passed: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToInterviewTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              changeJobApplicationStatus(
                candidate.id,
                { status: 'Interview', subStatus: 'Pending' },
                () =>
                  moveCandidateToPreviousStage(
                    candidate?.status,
                    candidate?.subStatus,
                    candidate,
                    refetch,
                    setIsFetching
                  )
              );
            }),
        },
        ...assessmentOption,
        ...standardOptions,
      ],
      Rejected: [
        // {
        //   title: 'Re-take Assessment',
        //   onClick: (event) =>
        //     hideOptionsOnClick(event, () => {
        //       showModal({
        //         title: 'Re-take Assessment',
        //         text: 'Are you sure you want the candidate to take the assessment again?',
        //         onConfirm: () =>
        //           retakeAssessment(
        //             candidate,
        //             refetch,
        //             showNotification,
        //             setIsFetching
        //           ),
        //         dismissButtonLabel: 'No',
        //       });
        //     }),
        // },
        ...standardOptions,
      ],
      Incomplete: [
        {
          title: 'Re-take Assessment',
          onClick: (event) =>
            hideOptionsOnClick(event, () =>
              showAssessmentModal({ candidate, refetch, retake: true })
            ),
        },
        ...standardOptions,
      ],
      Failed: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.reTakeAssessmentTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () =>
              showAssessmentModal({ candidate, refetch, retake: true })
            ),
        },
        ...standardOptions,
      ],
      Retake: [...assessmentOption, ...standardOptions],
      Withdrawn: standardOptions,
    },
    Interview: {
      Pending: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.scheduleInterviewTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showScheduleInterviewModal({ candidate, refetch });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToOfferTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              showChangeStageWithExceptionModal({
                candidate,
                refetch,
                stage: 'Offer',
              });
            }),
        },
        rejectOption,
        ...assessmentOption,
        ...standardOptions,
      ],
      Scheduled: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.cancelInterviewTitle'
          ),
          onClick: (event) => hideOptionsOnClick(event, cancelInterview),
        },
        ...interviewAdvancedStagesOptions,
      ],
      Rescheduled: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.cancelInterviewTitle'
          ),
          onClick: (event) => hideOptionsOnClick(event, cancelInterview),
        },
        ...interviewAdvancedStagesOptions,
      ],
      ChangeRequested: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.cancelInterviewTitle'
          ),
          onClick: (event) => hideOptionsOnClick(event, cancelInterview),
        },
        ...interviewAdvancedStagesOptions,
      ],
      Accepted: interviewAdvancedStagesOptions,
      Declined: standardOptions,
      Fail: interviewAdvancedStagesOptions,
      Rejected: standardOptions,
      Canceled: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.scheduleInterviewTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showScheduleInterviewModal({ candidate, refetch });
            }),
        },
        rejectOption,
        ...assessmentOption,
        ...standardOptions,
      ],
      Passed: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToOfferTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              changeJobApplicationStatus(
                candidate.id,
                { status: 'Offer', subStatus: 'Pending' },
                () =>
                  moveCandidateToPreviousStage(
                    candidate.status,
                    candidate.subStatus,
                    candidate,
                    refetch,
                    setIsFetching
                  )
              );
            }),
        },
        rejectOption,
        ...assessmentOption,
        ...standardOptions,
      ],
      Withdrawn: standardOptions,
    },
    Offer: {
      Pending: pendingOrRejectedOfferOptions,
      Rejected: pendingOrRejectedOfferOptions,
      Declined: standardOptions,
      Offered: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.sendReminderTitle'
          ),
          onClick: (event) => hideOptionsOnClick(event, () => {}),
        },
        {
          title: 'Reject',
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showRejectCandidateWithReasonModal({ candidate, refetch });
            }),
        },
        ...assessmentOption,
        ...standardOptions,
      ],
      Countered: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.sendReminderTitle'
          ),
          onClick: (event) => hideOptionsOnClick(event, () => {}),
        },
        rejectOption,
        ...assessmentOption,
        ...standardOptions,
      ],
      Accepted: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.moveToOnboardTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              changeJobApplicationStatus(
                candidate.id,
                { status: 'Onboard', subStatus: 'Pending' },
                () =>
                  moveCandidateToPreviousStage(
                    candidate?.status,
                    candidate?.subStatus,
                    candidate,
                    refetch,
                    setIsFetching
                  )
              );
            }),
        },
        rejectOption,
        ...assessmentOption,
        ...standardOptions,
      ],
      Withdrawn: standardOptions,
    },
    Onboard: {
      Pending: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.setOnboardingTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showSetOnboardingModal({ candidate, refetch });
            }),
        },
        rejectOption,
        ...assessmentOption,
        ...standardOptions,
      ],
      Scheduled: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.onboardedTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              setJobApplicationStatus(candidate.id, {
                status: 'Onboard',
                subStatus: 'Onboarded',
              });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.setStartDateTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showSetJoiningModal({ candidate, refetch });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.noShowTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              requestNoShowReject();
            }),
        },
        rejectOption,
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.cancelOnboardingTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, async () => {
              try {
                await AdminService.cancelJobApplicationOnboarding({
                  jobApplicationId: candidate.id,
                  onboardingId: candidate.jobApplicationOnboardings[0].id,
                });
                refetch();
              } catch (error) {
                console.log(error);
              }
            }),
        },
        ...assessmentOption,
        ...standardOptions,
      ],
      ChangeRequested: hasJoining
        ? [
            {
              title: t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.rescheduleTitle'
              ),
              onClick: (event) =>
                hideOptionsOnClick(event, () => {
                  showSetJoiningModal({ candidate, refetch });
                }),
            },
            rejectOption,
            ...assessmentOption,
            ...standardOptions,
          ]
        : [
            {
              title: t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.onboardedTitle'
              ),
              onClick: (event) =>
                hideOptionsOnClick(event, () => {
                  if (jobStatus === 'UnPublished') {
                    return;
                  }
                  setJobApplicationStatus(candidate.id, {
                    status: 'Onboard',
                    subStatus: 'Onboarded',
                  });
                }),
            },
            {
              title: t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.setStartDateTitle'
              ),
              onClick: (event) =>
                hideOptionsOnClick(event, () => {
                  showSetJoiningModal({ candidate, refetch });
                }),
            },
            {
              title: t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.noShowTitle'
              ),
              onClick: (event) =>
                hideOptionsOnClick(event, () => {
                  requestNoShowReject();
                }),
            },
            rejectOption,
            {
              title: t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.cancelOnboardingTitle'
              ),
              onClick: (event) =>
                hideOptionsOnClick(event, async () => {
                  try {
                    await AdminService.cancelJobApplicationOnboarding({
                      jobApplicationId: candidate.id,
                      onboardingId: candidate.jobApplicationOnboardings[0].id,
                    });
                    refetch();
                  } catch (error) {
                    console.log(error);
                  }
                }),
            },
            ...assessmentOption,
            ...standardOptions,
          ],

      StartConfirmed: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.joinedTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              showNextStepsModal({
                candidate,
                refetch,
              });
              /*  setJobApplicationStatus(candidate.id, {
                status: 'Onboard',
                subStatus: 'Joined',
              }); */
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.noShowTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              requestNoShowReject();
            }),
        },
        rejectOption,
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.cancelStartDateTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, async () => {
              try {
                setIsFetching(true);
                await AdminService.cancelStartDate({
                  jobApplicationId: candidate.id,
                  joiningId: candidate.jobApplicationJoinings[0].id,
                });
                await refetch();
              } catch (error) {
                console.log(error);
              } finally {
                setIsFetching(false);
              }
            }),
        },
        ...assessmentOption,
        ...standardOptions,
      ],
      Accepted: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.onboardedTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              setJobApplicationStatus(candidate.id, {
                status: 'Onboard',
                subStatus: 'Onboarded',
              });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.setStartDateTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showSetJoiningModal({ candidate, refetch });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.noShowTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              requestNoShowReject();
            }),
        },
        rejectOption,
        ...assessmentOption,
        ...standardOptions,
      ],
      Onboarded: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.setStartDateTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              showSetJoiningModal({ candidate, refetch });
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.noShowTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              requestNoShowReject();
            }),
        },
        rejectOption,
        ...assessmentOption,
        ...standardOptions,
      ],
      StartDateSet: [
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.joinedTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              if (jobStatus === 'UnPublished') {
                return;
              }
              showNextStepsModal({
                candidate,
                refetch,
              });
              /*  setJobApplicationStatus(candidate.id, {
                status: 'Onboard',
                subStatus: 'Joined',
              }); */
            }),
        },
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.noShowTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, () => {
              requestNoShowReject();
            }),
        },
        rejectOption,
        {
          title: t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.cancelStartDateTitle'
          ),
          onClick: (event) =>
            hideOptionsOnClick(event, async () => {
              try {
                setIsFetching(true);
                await AdminService.cancelStartDate({
                  jobApplicationId: candidate.id,
                  joiningId: candidate.jobApplicationJoinings[0].id,
                });
                await refetch();
              } catch (error) {
                console.log(error);
              } finally {
                setIsFetching(false);
              }
            }),
        },
        ...assessmentOption,
        ...standardOptions,
      ],
      NoShow: standardOptions,
      Rejected: standardOptions,
      Declined: standardOptions,
      StartDeclined: standardOptions,
      Canceled: hasOnboarding
        ? [
            {
              title: t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.setStartDateTitle'
              ),
              onClick: (event) =>
                hideOptionsOnClick(event, () => {
                  showSetJoiningModal({ candidate, refetch });
                }),
            },
            rejectOption,
            ...assessmentOption,
            ...standardOptions,
          ]
        : [
            {
              title: t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.CandidateMenu.setOnboardingTitle'
              ),
              onClick: (event) =>
                hideOptionsOnClick(event, () => {
                  showSetOnboardingModal({ candidate, refetch });
                }),
            },
            rejectOption,
            ...assessmentOption,
            ...standardOptions,
          ],
      Joined: standardOptions,
      Withdrawn: standardOptions,
    },
  };

  let optionsToShow = options[candidate?.status]?.[candidate?.subStatus];

  const hasOnlyStandardOptions = optionsToShow?.every((option) =>
    standardOptions.some((sOpt) => option.title === sOpt.title)
  );

  if (candidate?.subStatus === 'Rejected') {
    optionsToShow = optionsToShow.filter((option) => option.title !== 'Reject');
  }

  return (
    <div
      className={classNames({
        [classes.CandidateMenu]: !isCandidatePopup,
        [classes.candidatePopup]: isCandidatePopup,
      })}
      ref={dropdownRef}
    >
      <div
        ref={selectorRef}
        className={classes.selector}
        onClick={(event) => {
          event.nativeEvent.stopImmediatePropagation();
          event.stopPropagation();
          setIsOptionsListVisible((prevState) => !prevState);
        }}
      >
        {!isCandidatePopup && (
          <svg
            width="16"
            height="5"
            viewBox="0 0 16 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2.57812" r="2" fill="#C1C5D6" />
            <circle cx="8" cy="2.57812" r="2" fill="#C1C5D6" />
            <circle cx="14" cy="2.57812" r="2" fill="#C1C5D6" />
          </svg>
        )}
      </div>
      {isOptionsListVisible && (
        <div className={classes.optionsContainer}>
          <ul
            className={classNames(classes.options, {
              [classes.onlyStandardOptions]: hasOnlyStandardOptions,
            })}
          >
            {optionsToShow?.map((option) => {
              const hasUpperLine =
                option.title === 'Assign Assessment' ||
                option.title === 'Send Reminder' ||
                option.title === 'Re-take Assessment';

              return (
                <li
                  key={option.title}
                  onClick={option.onClick}
                  className={classNames({
                    [classes.disabled]: option.disabled,
                    [classes.lastItemLine]: hasUpperLine,
                  })}
                >
                  {option.title}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
