import React from 'react';

import JobCard from '../../../Jobs/MyJobs/GridView/JobCard';
import classes from './styles.module.scss';

export default function JobsTab({ jobs, refetch }) {
  const visibleJobs = jobs?.map((job) => {
    return {
      applicantCount: job?.applicantCount,
      createdAt: job?.createdAt,
      id: job?.jobPostId,
      isFavorite: job?.isFavorite,
      jobTitle: job?.title,
      status: job?.status,
      locationCity: {
        name: job?.city,
      },
      recruiter: {
        firstName: job?.recruiterName.split(' ')[0],
        lastName:
          job?.recruiterName.split(' ')[
            job?.recruiterName.split(' ').length - 1
          ],
        avatar: job?.recruiterAvatar,
      },
    };
  });
  return (
    <div className={classes.tab}>
      <div className={classes.jobs}>
        {visibleJobs?.map((job) => (
          <JobCard job={job} key={job.id} favorites refetch={refetch} />
        ))}
      </div>
    </div>
  );
}
