import React from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Stat from './Stat';

import classes from './styles.module.scss';

function calculateDynamic(currentValue, prevValue) {
  if (prevValue === currentValue) {
    return 0;
  }

  return -(100 - (currentValue / prevValue) * 100).toFixed(2);
}
export default function MainStats({ data }) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  let stats = [
    {
      label: t('dashboardComponents.Dashboard.LeftPanel.MainStats.Total Jobs'),
      dynamic:
        data?.newJobs.previous === 0
          ? 0
          : calculateDynamic(data?.newJobs.current, data?.newJobs.previous),
      value: data?.newJobs.current,
      noPreviousData: data?.newJobs.previous === 0,
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.MainStats.Total Applicants'
      ),
      dynamic:
        data?.newApplicants.previous === 0
          ? 0
          : calculateDynamic(
              data?.newApplicants.current,
              data?.newApplicants.previous
            ),
      value: data?.newApplicants.current,
      noPreviousData: data?.newApplicants.previous === 0,
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.MainStats.Total Closed'
      ),
      dynamic:
        data?.closedJobs.previous === 0
          ? 0
          : calculateDynamic(
              data?.closedJobs.current,
              data?.closedJobs.previous
            ),
      value: data?.closedJobs.current,
      noPreviousData: data?.closedJobs.previous === 0,
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.MainStats.Total New Joiners'
      ),
      dynamic:
        data?.joinedApplicants.previous === 0
          ? 0
          : calculateDynamic(
              data?.joinedApplicants.current,
              data?.joinedApplicants.previous
            ),
      value: data?.joinedApplicants.current,
      noPreviousData: data?.joinedApplicants.previous === 0,
    },
  ];

  if (isTabletOrMobile) {
    stats = stats.slice(0, 2);
  }

  return (
    <div className={classes.MainStats}>
      {stats.map((stat) => {
        return (
          <Stat
            key={stat.label}
            label={stat.label}
            value={stat.value}
            dynamic={stat.dynamic}
            hasNoData={!data}
            noPreviousData={stat.noPreviousData}
          />
        );
      })}
    </div>
  );
}
