import { useContext } from 'react';

import i18n from '../i18n';
import { UiContext } from '../context/UiContext';

const t = i18n.t.bind(i18n);

export default function useUpdateData(updateHandler, refetch) {
  const {
    showModal,
    showUnknownErrorModal,
    setIsFetching,
    setIsCreatingOrUpdating,
  } = useContext(UiContext);

  return {
    updateData: async (values) => {
      try {
        setIsCreatingOrUpdating(true);
        setIsFetching(true);
        await updateHandler(values);
        await refetch();
        showModal({
          title: t('hooks.useUpdateData.successTitle'),
          text: t('hooks.useUpdateData.successMessage'),
          confirmButtonLabel: t('common.gotIt'),
          confirmButtonVariant: '',
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        setIsFetching(false);
        setIsCreatingOrUpdating(false);
      }
    },
  };
}
