/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { UiContext } from '../../../../../../../context/UiContext';
import AdminService from '../../../../../../../services/AdminService';
import useOnClickOutside from '../../../../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function JobStatusDropdown({ status, jobId, refetchJob }) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  const { showModal, showNotification, setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const statuses = {
    Draft: t('common.exactJobStatuses.Draft'),
    Active: t('common.exactJobStatuses.Active'),
    OnHold: t('common.exactJobStatuses.OnHold'),
    UnPublished: t('common.exactJobStatuses.UnPublished'),
    Closed: t('common.exactJobStatuses.Closed'),
  };

  const changeJobStatus = async (status, notification) => {
    try {
      setIsFetching(true);

      setIsOptionsListVisible(false);
      await AdminService.changeMyJobStatus({ jobId, status });
      showNotification({ text: notification });
      await refetchJob();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const showConfirmModal = (status) => {
    const titles = {
      Active: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.JobHeader.JobStatusDropdown.publish'
      ),
      UnPublished: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.JobHeader.JobStatusDropdown.unpublish'
      ),
      OnHold: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.JobHeader.JobStatusDropdown.putOnHold'
      ),
    };

    const texts = {
      Active: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.JobHeader.JobStatusDropdown.areYouSureActivate'
      ),
      UnPublished: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.JobHeader.JobStatusDropdown.jobWillBeClosed'
      ),
      OnHold: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.JobHeader.JobStatusDropdown.areYouSureHold'
      ),
    };

    const notifications = {
      Active: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.JobHeader.JobStatusDropdown.jobPublishedSuccessfully'
      ),
      UnPublished: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.JobHeader.JobStatusDropdown.jobUnpublishedSuccessfully'
      ),
      OnHold: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.JobHeader.JobStatusDropdown.jobPutOnHoldSuccessfully'
      ),
    };

    showModal({
      title: titles[status],
      text: texts[status],
      onConfirm: () => changeJobStatus(status, notifications[status]),
      dismissButtonLabel: t('common.no'),
    });
  };

  return (
    <div className={classes.JobStatusDropdown} ref={dropdownRef}>
      <div
        className={classNames(classes.selector, {
          [classes.notActive]: status !== 'Active',
        })}
        onClick={() => {
          if (status === 'UnPublished') {
            return;
          }

          setIsOptionsListVisible((prevState) => !prevState);
        }}
      >
        {statuses[status]}
        <svg
          width="13"
          height="8"
          viewBox="0 0 13 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.85921 0.839891C1.56702 0.546302 1.09214 0.545172 0.798556 0.837368C0.504966 1.12956 0.503837 1.60444 0.796033 1.89803L5.78412 6.9099C6.07632 7.20349 6.55119 7.20462 6.84478 6.91243L11.8567 1.92434C12.1503 1.63214 12.1514 1.15727 11.8592 0.863678C11.567 0.570089 11.0921 0.568959 10.7985 0.861155L6.31824 5.32018L1.85921 0.839891Z"
            fill="black"
          />
        </svg>
      </div>
      {isOptionsListVisible && (
        <ul className={classes.options}>
          {status !== 'Active' && (
            <li onClick={() => showConfirmModal('Active')}>
              {t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.JobHeader.JobStatusDropdown.publish'
              )}
            </li>
          )}
          {status !== 'UnPublished' && (
            <li onClick={() => showConfirmModal('UnPublished')}>
              {t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.JobHeader.JobStatusDropdown.unpublish'
              )}
            </li>
          )}
          {status !== 'OnHold' && (
            <li onClick={() => showConfirmModal('OnHold')}>
              {t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.JobHeader.JobStatusDropdown.putOnHold'
              )}
            </li>
          )}
        </ul>
      )}
    </div>
  );
}
