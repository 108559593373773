/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function DropDown({
  options,
  value,
  width,
  setValue,
  handleClose,
  labelRef,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const dropdownRef = useRef();
  const optionsRef = useRef();

  const { t } = useTranslation();

  useOnClickOutside(
    dropdownRef,
    () => {
      setIsOptionsListVisible(false);
      handleClose();
    },
    labelRef
  );

  const setOption = (option) => {
    setValue(option.value);
    setIsOptionsListVisible(false);
    handleClose();
  };

  useEffect(() => {
    if (!isOptionsListVisible) {
      setSearchTerm('');
    }
  }, [isOptionsListVisible]);

  const optionsToDisplay = options?.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      style={{ width }}
      tabIndex={0}
      role="listbox"
      ref={dropdownRef}
      className={classNames(classes.FormDropdown, {
        [classes.open]: isOptionsListVisible,
      })}
      onClick={(e) => e.stopPropagation()}
    >
      {isOptionsListVisible && (
        <div className={classes.searchAndOptions} ref={optionsRef}>
          <div className={classes.search}>
            <input
              type="text"
              placeholder={t('common.search')}
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          </div>
          <div className={classes.options}>
            <ul>
              {optionsToDisplay?.map((option) => {
                return (
                  <li
                    key={option.value}
                    onClick={() => {
                      setOption(option);
                    }}
                    className={classNames({
                      [classes.active]: value === option.value,
                    })}
                  >
                    {option.label}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
