import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Stat({ title, subtitle, value, dynamic }) {
  const { t } = useTranslation();

  return (
    <div className={classNames(classes.Stat, classes.active)}>
      <header className={classes.header}>
        <div className={classes.title}>
          <h2>{title}</h2>
          <span>{subtitle}</span>
        </div>
      </header>
      <div className={classes.details}>
        <div className={classes.value}>{value}</div>
        <div className={classes.dynamic}>
          <span className={classes.dynamicValue}>{dynamic}</span>
          <span className={classes.info}>
            {t('dashboardComponents.Jobs.MyJobs.Stat.last30Days')}
          </span>
        </div>
      </div>
    </div>
  );
}
