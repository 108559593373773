import React from 'react';

import { useTranslation } from 'react-i18next';

import Recipients from './Recipients';
import Button from '../Button';
import TextEditor from '../TextEditor';

import classes from './styles.module.scss';

export default function AppreciationEditor({
  editingPost,
  setIsEditorVisible,
  isEditorVisible,
  sharedClasses,
  title,
  setTitle,
  message,
  setMessage,
  addressees,
  setAddressees,
  tags,
  setTags,
  files,
  setFiles,
  uploadedFiles,
  setUploadedFiles,
  textBoxRef,
  updateAppreciation,
  cancel,
  recipients,
  setRecipients,
  createAppreciation,
  fileNames,
  setFileNames,
  isFetching,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.AppreciationEditor}>
      {isEditorVisible ? (
        <>
          <TextEditor
            title={title}
            setTitle={setTitle}
            message={message}
            setMessage={setMessage}
            addressees={addressees}
            setAddressees={setAddressees}
            tags={tags}
            setTags={setTags}
            sharedClasses={sharedClasses}
            textBoxRef={textBoxRef}
            files={files}
            setFiles={setFiles}
            setUploadedFiles={setUploadedFiles}
            fileNames={fileNames}
            setFileNames={setFileNames}
            uploadedFiles={uploadedFiles}
          />
          <Recipients
            recipients={recipients}
            setRecipients={setRecipients}
            setAddressees={setAddressees}
          />
          <div className={classes.buttons}>
            <Button
              disabled={!addressees.length || isFetching}
              onClick={() => {
                if (editingPost) {
                  updateAppreciation();
                } else {
                  createAppreciation();
                }
              }}
            >
              {editingPost ? t('common.save') : t('common.send')}
            </Button>
            <button
              className={classes.cancelButton}
              type="button"
              onClick={cancel}
            >
              {t('common.cancel')}
            </button>
          </div>
        </>
      ) : (
        <div
          className={classes.welcome}
          onClick={() => {
            setIsEditorVisible(true);
          }}
        >
          {t(
            'dashboardComponents.Discover.AddContent.AppreciationEditor.sendAppreciation'
          )}
        </div>
      )}
    </div>
  );
}
