import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import SearchInput from '../../../../Form/SearchInput';
import Dropdown from './Dropdown';
import InfoIcon from '../../../../InfoIcon';
import Button from '../../../../Button';
import Tooltip from '../../../../Tooltip';
import SaveChangesModal from '../SaveChangesModal';

import classes from './styles.module.scss';
import { UiContext } from '../../../../../context/UiContext';
import { mapReports } from '../../../../../helpers/mapReports';
import ReportsService from '../../../../../services/ReportsService';
import useDebouncedSearchWithHistory from '../../../../../hooks/useDebouncedSearchWithHistory';

import editIcon from '../../../../../assets/images/dashboard/reports/computing.svg';
import sortIcon from '../../../../../assets/images/dashboard/reports/sort.svg';
import saveIcon from '../../../../../assets/images/dashboard/reports/save.svg';
import downloadIcon from '../../../../../assets/images/dashboard/reports/download.svg';
import printIcon from '../../../../../assets/images/dashboard/reports/print.svg';
import mailIcon from '../../../../../assets/images/dashboard/reports/mail.svg';

import showIcon from '../../../../../assets/images/dashboard/reports/show.svg';

export default function ReportHeader({
  reports,
  activeReport,
  savedReports,
  refetch,
  searchTerm,
  setSearchTerm,
  sortOrder,
  setSortOrder,
  activeSortHeader,
  setActiveSortHeader,
  setIsModalVisible,
  isLeftPanelOpened,
  setIsLeftPanelOpened,
  downloadReport,
  printReport,
  reportTypes,
  setIsReportAlreadyAdded,
}) {
  const [isSavedReportModalVisible, setIsSavedReportModalVisible] =
    useState(false);
  const { searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    'reportSearchHistory'
  );

  const { setIsFetching, showNotification } = useContext(UiContext);

  const { t } = useTranslation();

  const handleEmailClick = () => {
    const email = 'mailto:';
    const subject = activeReport.title;
    const body = '';

    const data = `?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    const emailUrl = email + data;

    window.location.href = emailUrl;
  };

  const updateReport = async () => {
    try {
      setIsFetching(true);
      await ReportsService.updateStoredReport({
        id: activeReport.id,
        title: activeReport.title,
        type: activeReport.type,
        term: searchTerm,
        orderBy: activeSortHeader,
        fields: activeReport.fields || reports.selectedFields,
      });

      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
      showNotification({
        text: t(
          'dashboardComponents.Reports.RightPanel.ReportHeader.changesSavedSuccessfully'
        ),
      });
    }
  };

  const sortOptions = mapReports(reports?.selectedFields);
  const isConstantReport = reportTypes
    ?.map((rep) => rep.split('.').pop())
    .includes(activeReport?.title);

  return (
    <div className={classes.ReportHeader}>
      <div className={classes.container}>
        {!isLeftPanelOpened && (
          <Tooltip
            text={t(
              'dashboardComponents.Reports.RightPanel.ReportHeader.showSidebar'
            )}
            direction="bottom-right"
            width={120}
          >
            <button
              type="button"
              style={{
                backgroundImage: `url(${showIcon})`,
              }}
              onClick={() => setIsLeftPanelOpened(true)}
            />
          </Tooltip>
        )}
        <h1 className={classes.title}>{activeReport?.title || activeReport}</h1>
        {activeReport?.reportInfo && (
          <span style={{ marginTop: '4px' }}>
            <InfoIcon
              infoWidth={280}
              info={activeReport?.reportInfo}
              tooltipDirection="bottom-right"
            />
          </span>
        )}
      </div>
      <div className={classes.menu}>
        <SearchInput
          height={45}
          value={searchTerm}
          setValue={setSearchTerm}
          searchHistory={searchHistory}
          backgroundColor="white"
          style={{
            border: 'none',
          }}
        />
        <Dropdown
          style={{
            border: 'none',
          }}
          height={45}
          options={sortOptions}
          placeholder={t(
            'dashboardComponents.Reports.RightPanel.ReportHeader.sort'
          )}
          activeOption={activeSortHeader}
          setActiveOption={(option) => {
            const optionIndex = sortOptions.indexOf(option);

            setActiveSortHeader(reports?.selectedFields[optionIndex]);
          }}
          width={150}
        />
        <Tooltip
          text={t(
            'dashboardComponents.Reports.RightPanel.ReportHeader.columnPicker'
          )}
          direction="bottom-left"
          width={120}
        >
          <Button
            variant="report"
            width={45}
            height={45}
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            <img src={editIcon} alt="edit" />
          </Button>
        </Tooltip>
        <Tooltip
          text={t('dashboardComponents.Reports.RightPanel.ReportHeader.filter')}
          direction="bottom-left"
          width={60}
        >
          <Button
            variant="report"
            width={45}
            height={45}
            onClick={() => {
              if (!activeSortHeader) {
                return;
              }

              if (sortOrder === 'asc') {
                setSortOrder('desc');
              } else {
                setSortOrder('asc');
              }
            }}
          >
            <img src={sortIcon} alt="sort" />
          </Button>
        </Tooltip>
        <Tooltip
          text={t('dashboardComponents.Reports.RightPanel.ReportHeader.save')}
          direction="bottom-left"
          width={60}
        >
          <Button
            variant="report"
            width={45}
            height={45}
            onClick={() =>
              !isConstantReport
                ? updateReport()
                : setIsSavedReportModalVisible(true)
            }
          >
            <img src={saveIcon} alt="save" />
          </Button>
        </Tooltip>
        <div style={{ display: 'none' }}>
          <Tooltip
            text={t('dashboardComponents.Reports.RightPanel.ReportHeader.mail')}
            direction="bottom-left"
            width={100}
          >
            <Button
              variant="report"
              width={45}
              height={45}
              onClick={handleEmailClick}
            >
              <img src={mailIcon} alt="mail" />
            </Button>
          </Tooltip>
        </div>
        <Tooltip
          text={t(
            'dashboardComponents.Reports.RightPanel.ReportHeader.download'
          )}
          direction="bottom-left"
          width={100}
        >
          <Button
            variant="report"
            width={45}
            height={45}
            onClick={() => downloadReport(activeReport)}
            disabled={!reports?.mandatoryFields?.length}
          >
            <img src={downloadIcon} alt="download" />
          </Button>
        </Tooltip>
        <Tooltip
          text={t('dashboardComponents.Reports.RightPanel.ReportHeader.print')}
          direction="bottom-left"
          width={60}
        >
          <Button
            variant="report"
            width={45}
            height={45}
            onClick={() => printReport()}
          >
            <img src={printIcon} alt="print" />
          </Button>
        </Tooltip>
      </div>
      {isSavedReportModalVisible && (
        <SaveChangesModal
          text={t(
            'dashboardComponents.Reports.RightPanel.ReportHeader.confirmMessage'
          )}
          show={isSavedReportModalVisible}
          handleClose={() => {
            setIsSavedReportModalVisible(false);
            setIsReportAlreadyAdded(true);
          }}
          reports={reports}
          activeReport={activeReport}
          searchTerm={searchTerm}
          activeSortHeader={activeSortHeader}
          sortOrder={sortOrder}
          refetch={refetch}
          savedReports={savedReports}
          leftButtonLabel={t('common.no')}
          rightButtonLabel={t('common.yes')}
          reportTypes={reportTypes}
        />
      )}
    </div>
  );
}
