import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';
import logo from '../../../assets/images/logo-small.png';

export default function AuthHeading() {
  const { t } = useTranslation();

  return (
    <h1 className={classes.AuthHeading}>
      {t('pages.LoginPage.welcomeTo')} <br /> {t('pages.LoginPage.yourAwesome')}{' '}
      <br /> <img src={logo} alt="Logo" />{' '}
      {t('pages.LoginPage.recruitmentPortal')}
      <br /> <span> {t('common.poweredByZaphire')}</span>
    </h1>
  );
}
