import React, { createContext, useState, useMemo, useCallback } from 'react';

import InviteToJobModal from '../modals/InviteToJobModal';
import AddCandidateNoteModal from '../modals/AddCandidateNoteModal';

export const CandidatesContext = createContext({
  showAddCandidateNoteModal: () => {},
  showInviteToJobModal: () => {},
});

export default function CandidatesContextProvider({ children }) {
  const [isAddCandidateNoteModalVisible, setIsAddCandidateNoteModalVisible] =
    useState(false);
  const [isInviteToJobModalVisible, setIsInviteToJobModalVisible] =
    useState(false);
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const [refetchCandidate, setRefetchCandidate] = useState(null);

  const showAddCandidateNoteModal = useCallback((candidate, refetch) => {
    setCurrentCandidate(candidate);
    setIsAddCandidateNoteModalVisible(true);
    setRefetchCandidate(() => refetch);
  }, []);

  const showInviteToJobModal = useCallback((candidate, refetch) => {
    setCurrentCandidate(candidate);
    setIsInviteToJobModalVisible(true);
    setRefetchCandidate(() => refetch);
  }, []);

  const value = useMemo(
    () => ({
      showAddCandidateNoteModal,
      showInviteToJobModal,
    }),
    [showAddCandidateNoteModal, showInviteToJobModal]
  );

  return (
    <CandidatesContext.Provider value={value}>
      {children}
      <AddCandidateNoteModal
        show={isAddCandidateNoteModalVisible}
        handleClose={() => {
          setIsAddCandidateNoteModalVisible(false);
          setCurrentCandidate(null);
        }}
        currentCandidate={currentCandidate}
        refetch={refetchCandidate}
      />
      <InviteToJobModal
        currentCandidate={currentCandidate}
        show={isInviteToJobModalVisible}
        handleClose={() => {
          setIsInviteToJobModalVisible(false);
          setCurrentCandidate(null);
        }}
        refetch={refetchCandidate}
      />
    </CandidatesContext.Provider>
  );
}
