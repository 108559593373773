/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef, useContext } from 'react';

import classNames from 'classnames';

import { UiContext } from '../../../../context/UiContext';
import fullScreenButton from '../../../../assets/images/dashboard/discover/fullScreenButton.svg';
import playIcon from '../../../../assets/images/dashboard/discover/playButton.svg';
import classes from './styles.module.scss';

function formatVideoDuration(time) {
  const roundedTime = Math.round(time);
  if (roundedTime < 60) {
    return `0.${
      roundedTime.toString().length === 1 ? `0${roundedTime}` : roundedTime
    }`;
  }
  const minutes = Math.trunc(roundedTime / 60);
  const seconds = roundedTime - minutes * 60;

  return `${minutes}.${
    seconds.toString().length === 1 ? `0${seconds}` : seconds
  }`;
}

export default function Video({ url }) {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState('0.00');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isVideoReady, setIsVideoReady] = useState(false);

  const { setIsFetching } = useContext(UiContext);

  const videoRef = useRef();

  const playPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const openVideoFullscreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      /* Safari */
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      /* IE11 */
      videoRef.current.msRequestFullscreen();
    }
  };

  const rewindVideoOnClick = (event) => {
    const { clientX } = event;

    const { left } = event.target.getBoundingClientRect();

    const rewindTime = ((clientX - left) / 479) * videoDuration;

    videoRef.current.currentTime = rewindTime;

    setProgress((rewindTime / videoDuration) * 100);
  };

  useEffect(() => {
    const onPlay = () => {
      setIsVideoPlaying(true);
    };

    const onPause = () => {
      setIsVideoPlaying(false);
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('play', onPlay);
      videoRef.current.addEventListener('pause', onPause);
      videoRef.current.addEventListener('ended', onPause);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('play', onPlay);
        videoRef.current.removeEventListener('pause', onPause);
        videoRef.current.removeEventListener('ended', onPause);
      }
    };
  }, []);

  useEffect(() => {
    const onFullScreenChange = () => {
      setIsFullScreen((prevState) => !prevState);
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('fullscreenchange', onFullScreenChange);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener(
          'fullscreenchange',
          onFullScreenChange
        );
      }
    };
  }, []);

  useEffect(() => {
    const updateDuration = () => {
      setVideoDuration(videoRef.current?.duration);
    };

    const updateCurrentTime = () => {
      setCurrentTime(
        formatVideoDuration(
          videoRef.current?.duration - videoRef.current.currentTime
        )
      );
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('canplay', updateDuration);
      videoRef.current.addEventListener('timeupdate', updateCurrentTime);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('canplay', updateDuration);
        videoRef.current.removeEventListener('timeupdate', updateCurrentTime);
      }
    };
  }, []);

  useEffect(() => {
    const onLoadedData = () => {
      setIsVideoReady(true);
      setIsFetching(false);
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('loadeddata', onLoadedData);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('loadeddata', onLoadedData);
      }
    };
  }, []);

  useEffect(() => {
    let animationId;

    const animateProgressBar = () => {
      setProgress(
        (videoRef.current?.currentTime / videoRef.current?.duration) * 100
      );

      animationId = requestAnimationFrame(animateProgressBar);
    };

    animationId = requestAnimationFrame(animateProgressBar);

    return () => {
      cancelAnimationFrame(animationId);
    };
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setProgress(0);
    }
  }, [progress]);

  useEffect(() => {
    setIsFetching(true);
    setIsVideoPlaying(false);
    setIsVideoReady(false);
  }, [url]);

  useEffect(() => {
    return () => {
      setIsFetching(false);
    };
  }, []);

  return (
    <div className={classes.Video}>
      <video src={url} ref={videoRef} controls={isFullScreen} />
      {isVideoReady && (
        <div className={classes.controls}>
          <div
            className={classes.progressContainer}
            onClick={rewindVideoOnClick}
          >
            <div className={classes.progress}>
              <div
                className={classes.fill}
                style={{
                  width: `${progress < 100 ? progress : 0}%`,
                }}
              />
            </div>
          </div>
          <div className={classes.buttons}>
            <span className={classes.timeLeft}>
              -
              {parseFloat(currentTime)
                ? currentTime
                : formatVideoDuration(videoRef.current?.duration)}
            </span>
            <button
              type="button"
              className={classNames(classes.playPauseButton, {
                [classes.pause]: isVideoPlaying,
              })}
              onClick={playPause}
            >
              <img src={playIcon} alt={isVideoPlaying ? 'Pause' : 'Play'} />
            </button>
            <button
              type="button"
              className={classes.fullScreenButton}
              onClick={openVideoFullscreen}
            >
              <img src={fullScreenButton} alt="Fullscreen" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
