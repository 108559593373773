import React from 'react';

import { useTranslation } from 'react-i18next';

import Experience from './Experience';

import classes from './styles.module.scss';
import { renderInputs } from '../../helpers';

export default function Details({
  inputs,
  formik,
  warnings,
  experienceInputs,
  addExperienceSubForm,
  validatedEmail,
  touched,
  isReadOnly,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.Details}>
      <div className={classes.header}>
        <h2 className={classes.title}>
          {t(
            'dashboardComponents.Candidates.CandidatesList.AllCandidates.AddCandidate.Basic Information'
          )}
        </h2>
        <div
          className={classes.warning}
          style={{
            visibility: validatedEmail?.isValid ? 'hidden' : 'visible',
          }}
        >
          Duplicate Found
        </div>
      </div>
      <div className={classes.inputs}>
        {inputs.map((input) =>
          renderInputs({
            input,
            formik,
            warnings,
            touched,
            validatedEmail,
            isReadOnly,
          })
        )}
      </div>
      <div className={classes.experienceSubForms}>
        {formik.values.userProfileExperiences?.map((_, index) => (
          <Experience
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            index={index}
            formik={formik}
            inputs={experienceInputs}
            renderInputs={renderInputs}
            warnings={warnings}
            addExperienceSubForm={addExperienceSubForm}
            touched={touched}
            validatedEmail={validatedEmail}
            isReadOnly={isReadOnly}
          />
        ))}
      </div>
    </div>
  );
}
