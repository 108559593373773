import pdfIcon from '../assets/images/dashboard/discover/textEditor/files/pdf.svg';
import jpgIcon from '../assets/images/dashboard/discover/textEditor/files/jpg.svg';
import pngIcon from '../assets/images/dashboard/discover/textEditor/files/png.svg';
import docIcon from '../assets/images/dashboard/discover/textEditor/files/doc.svg';
import zipIcon from '../assets/images/dashboard/discover/textEditor/files/zip.svg';
import pptIcon from '../assets/images/dashboard/discover/textEditor/files/ppt.svg';
import mp3Icon from '../assets/images/dashboard/discover/textEditor/files/mp3.svg';
import xlsIcon from '../assets/images/dashboard/discover/textEditor/files/xls.svg';
import mp4Icon from '../assets/images/dashboard/discover/textEditor/files/mp4.svg';
import aviIcon from '../assets/images/dashboard/discover/textEditor/files/avi.svg';

const fileIcons = {
  pdf: pdfIcon,
  jpg: jpgIcon,
  jpeg: jpgIcon,
  png: pngIcon,
  doc: docIcon,
  docx: docIcon,
  'vnd.openxmlformats-officedocument.wordprocessingml.document': docIcon,
  msword: docIcon,
  zip: zipIcon,
  ppt: pptIcon,
  'vnd.openxmlformats-officedocument.presentationml.presentation': pptIcon,
  'vnd.ms-powerpoint': pptIcon,
  mp3: mp3Icon,
  mpeg: mp3Icon,
  xls: xlsIcon,
  xlsx: xlsIcon,
  'vnd.ms-excel': xlsIcon,
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet': xlsIcon,
  mp4: mp4Icon,
  avi: aviIcon,
};

export default fileIcons;
