import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useMediaQuery } from 'react-responsive';

import Announcement from '../../components/Dashboard/Discover/ProductAnnouncements/Announcement';
import SearchInput from '../../components/Form/SearchInput';

import classes from './styles.module.scss';
import useDebouncedSearchWithHistory from '../../hooks/useDebouncedSearchWithHistory';

export default function ProductAnnouncementsModal({
  show,
  handleClose,
  announcements,
}) {
  const [filteredAnnouncements, setFilteredAnnouncements] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const { searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    'reportSearchHistory'
  );

  useEffect(() => {
    setFilteredAnnouncements(
      announcements?.filter(
        (announcement) =>
          announcement.note
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase()) ||
          announcement.title
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase()) ||
          announcement.createdAt.slice(0, 10).includes(searchTerm.trim())
      )
    );
  }, [announcements, searchTerm]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.ProductAnnouncementsModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header>
          <h1>{t('modals.ProductAnnouncementsModal.productAnnouncements')}</h1>

          <div className={classes.col}>
            <SearchInput
              value={searchTerm}
              setValue={setSearchTerm}
              searchHistory={searchHistory}
              width={isMobile ? '100%' : 358}
              style={{
                backgroundColor: '#F2F4F8',
                border: 'none',
              }}
            />
            <i className={classes.closeIcon} onClick={handleClose}>
              Close
            </i>
          </div>
        </header>
        <div className={classes.container}>
          {filteredAnnouncements?.length ? (
            <div className={classes.announcements}>
              {filteredAnnouncements?.map((announcement) => (
                <Announcement
                  announcement={announcement}
                  key={announcement.id}
                  noLimit
                />
              ))}
            </div>
          ) : (
            <span className={classes.noData}>
              {t('modals.ProductAnnouncementsModal.noMatch')}
            </span>
          )}
        </div>
      </div>
    </Modal>
  );
}
