import React, { useEffect, useCallback } from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Tabs({
  tabs,
  currentTab,
  setCurrentTab,
  countData,
  name,
}) {
  const saveTabChoice = (tabName) => {
    localStorage.setItem(`tab${name}`, tabName);
  };

  const restoreTab = useCallback(() => {
    const tab = localStorage.getItem(`tab${name}`);
    if (tab) {
      setCurrentTab(tab);
    }
  }, [name, setCurrentTab]);

  const switchTab = (tabName) => {
    setCurrentTab(tabName);
    saveTabChoice(tabName);
  };

  useEffect(() => {
    restoreTab();
  }, [restoreTab]);

  return (
    <div className={classes.Tabs}>
      {tabs.map((tab) => (
        <div
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              switchTab(tab);
            }
          }}
          onClick={() => switchTab(tab)}
          className={classNames(classes.tab, {
            [classes.active]: currentTab === tab,
          })}
          key={tab}
          tabIndex={0}
          role="button"
        >
          <span>{tab}</span>
          <div className={classes.count}>{countData[tab]}</div>
        </div>
      ))}
    </div>
  );
}
