import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function Addressee({ addressee, setAddressees }) {
  const { t } = useTranslation();

  const label =
    addressee.label ===
    t(
      'dashboardComponents.Discover.AddContent.TextEditor.Addressees.Addressee.allMembers'
    )
      ? t(
          'dashboardComponents.Discover.AddContent.TextEditor.Addressees.Addressee.toAllMembers'
        )
      : addressee.label;

  return (
    <div className={classes.Addressee}>
      <span>{label}</span>
      <i
        className={classes.removeIcon}
        onClick={() => {
          setAddressees((prevAddressees) =>
            prevAddressees.filter((addr) => addr.value !== addressee.value)
          );
        }}
      >
        Remove
      </i>
    </div>
  );
}
