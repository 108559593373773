import React, { useRef, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import TitleWithTooltip from '../TitleWithTooltip';
import ImagePreview from './ImagePreview';

import { UiContext } from '../../../../../../../../context/UiContext';
import classes from './styles.module.scss';

export default function ImageUploader({
  title,
  text,
  file,
  setFile,
  maxWidth,
  maxHeight,
  small,
}) {
  const fileInputRef = useRef();

  const { showModal } = useContext(UiContext);

  const { t } = useTranslation();

  const setImage = (event) => {
    const url = URL.createObjectURL(event.target.files[0]);
    const image = new Image();

    image.onload = () => {
      if (image.width > maxWidth || image.height > maxHeight) {
        showModal({
          title: t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Logo.ImageUploader.imageTooBigTitle'
          ),
          text: t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Logo.ImageUploader.imageTooBigText',
            {
              maxSize: `${maxWidth}px x ${maxHeight}px`,
              actualSize: `${image.width}x${image.height}`,
            }
          ),
        });

        return;
      }

      setFile(event.target.files[0]);

      URL.revokeObjectURL(image.src);
    };

    image.src = url;
  };

  return (
    <div className={classes.ImageUploader}>
      <TitleWithTooltip title={title} text={text} tooltipWidth={276} />
      <div className={classes.info}>
        <p>
          {t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Logo.ImageUploader.logoMustBePNG'
          )}
        </p>
        <p>
          {t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Logo.ImageUploader.maximumDimensions'
          )}{' '}
          {maxWidth}px x {maxHeight}px.
        </p>
      </div>
      {file ? (
        <ImagePreview
          small={small}
          file={file}
          deleteFile={() => setFile(null)}
        />
      ) : (
        <button
          className={classes.uploadButton}
          type="button"
          onClick={() => fileInputRef.current.click()}
        >
          {t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Logo.ImageUploader.uploadButton'
          )}
        </button>
      )}
      <input
        onChange={setImage}
        accept="image/png"
        type="file"
        ref={fileInputRef}
        style={{
          display: 'none',
        }}
      />
    </div>
  );
}
