import React from 'react';

import classes from './styles.module.scss';

export default function Slide({ text, image }) {
  return (
    <div className={classes.Slide} style={{ backgroundImage: `url(${image})` }}>
      <h1>{text}</h1>
    </div>
  );
}
