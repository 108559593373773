import React, { useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import SaveResetButtons from '../SaveResetButtons';
import FontSelector from './FontSelector';
import FontUploader from './FontUploader';

import { BrandKitContext } from '../../../../../../../context/BrandKitContext';
import { hasFontSettingsChanged } from '../../../../../../../helpers/compareBrandKitSettings';
import classes from './styles.module.scss';

export default function Font({
  tenantSettings,
  refetchTenantSettings,
  titleFont,
  setTitleFont,
  subTitleFont,
  setSubTitleFont,
  bodyTextFont,
  setBodyTextFont,
  bodySubTextFont,
  setBodySubTextFont,
  savedFontSettings,
  setSavedFontSettings,
}) {
  const { saveButtonValidation, setSaveButtonValidation } =
    useContext(BrandKitContext);

  const { t } = useTranslation();

  const saveFontSettings = () => {
    setSavedFontSettings({
      titleFont,
      subTitleFont,
      bodyTextFont,
      bodySubTextFont,
    });
  };

  useEffect(() => {
    const isDisabled = !hasFontSettingsChanged(
      {
        titleFont,
        subTitleFont,
        bodyTextFont,
        bodySubTextFont,
      },
      savedFontSettings
    );

    setSaveButtonValidation((prevState) => ({
      ...prevState,
      isFontSaveButtonDisabled: isDisabled,
    }));
  }, [
    bodySubTextFont,
    bodyTextFont,
    savedFontSettings,
    setSaveButtonValidation,
    subTitleFont,
    titleFont,
  ]);

  const resetFontSettings = () => {
    setTitleFont(savedFontSettings.titleFont);
    setSubTitleFont(savedFontSettings.subTitleFont);
    setBodyTextFont(savedFontSettings.bodyTextFont);
    setBodySubTextFont(savedFontSettings.bodySubTextFont);
  };

  return (
    <div className={classes.Font}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <h2 className={classes.title}>
            {t(
              'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Font.sectionTitle'
            )}
          </h2>
          <div className={classes.gilroy}>
            <span>Gilroy</span>
            <span>A B C D E F G {'  '} a b c d e f g</span>
          </div>
        </div>
        <div className={classes.saveResetButtonsContainer}>
          <SaveResetButtons
            onSave={saveFontSettings}
            onReset={resetFontSettings}
            isSaveDisabled={saveButtonValidation.isFontSaveButtonDisabled}
            isResetDisabled={saveButtonValidation.isFontSaveButtonDisabled}
          />
        </div>
      </div>
      <div className={classes.fonts}>
        <FontSelector
          value={titleFont}
          setValue={setTitleFont}
          label={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Font.title'
          )}
          customFonts={tenantSettings?.customFonts}
        />
        <FontSelector
          value={subTitleFont}
          setValue={setSubTitleFont}
          label={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Font.subTitle'
          )}
          customFonts={tenantSettings?.customFonts}
        />
        <FontSelector
          value={bodyTextFont}
          setValue={setBodyTextFont}
          label={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Font.bodyText'
          )}
          customFonts={tenantSettings?.customFonts}
        />
        <FontSelector
          value={bodySubTextFont}
          setValue={setBodySubTextFont}
          label={t(
            'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Font.bodySubText'
          )}
          customFonts={tenantSettings?.customFonts}
        />
        <FontUploader
          tenantSettings={tenantSettings}
          refetchTenantSettings={refetchTenantSettings}
        />
      </div>
    </div>
  );
}
