import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../../../Button';

import { UiContext } from '../../../../context/UiContext';
import AdminService from '../../../../services/AdminService';
import classes from './styles.module.scss';

export default function TenantAssessmentAction({
  tenantAssessmentProvider,
  refetch,
}) {
  const {
    setIsFetching,
    showUnknownErrorModal,
    showNotification,
    showConflictModal,
  } = useContext(UiContext);

  const { t } = useTranslation();

  const isActive = tenantAssessmentProvider.status === 'Active';

  const setTenantAssessmentProviderStatus = (status) => {
    if (status === 'Active') {
      return AdminService.enableTenantAssessmentProvider({
        id: tenantAssessmentProvider.id,
      });
    }
    return AdminService.disableTenantAssessmentProvider({
      id: tenantAssessmentProvider.id,
    });
  };

  const toggleTenantAssessmentProviderStatus = async () => {
    try {
      setIsFetching(true);

      if (!isActive) {
        await setTenantAssessmentProviderStatus('Active');
      } else {
        await setTenantAssessmentProviderStatus('Inactive');
      }

      await refetch();

      showNotification({
        text: isActive
          ? t('miscComponents.Table.Td.TenantAssessmentAction.inactive')
          : t('miscComponents.Table.Td.TenantAssessmentAction.active'),
        undo: async () => {
          try {
            setIsFetching(true);
            await setTenantAssessmentProviderStatus(
              isActive ? 'Active' : 'Inactive'
            );
            await refetch();
          } catch (error) {
            console.log(error);
            showUnknownErrorModal();
          } finally {
            setIsFetching(false);
          }
        },
      });
    } catch (error) {
      console.log(error);
      if (error.response.status === 409) {
        showConflictModal(t('miscComponents.Table.Td.conflictMessage'));
      } else {
        showUnknownErrorModal();
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.TenantAssessmentAction}>
      <Button
        onClick={(event) => {
          event.stopPropagation();
          toggleTenantAssessmentProviderStatus();
        }}
        width={92}
        height={40}
        variant={isActive ? '' : 'delete'}
        style={{
          fontFamily: 'Gilroy-Regular',
          fontSize: 14,
        }}
      >
        {tenantAssessmentProvider.status === 'Active'
          ? t('miscComponents.Table.Td.TenantAssessmentAction.active')
          : t('miscComponents.Table.Td.TenantAssessmentAction.inactive')}
      </Button>
    </div>
  );
}
