import moment from 'moment';
import { getTimezoneDate } from './timezones';

export function updateSuggestedEndTime(start, end, suggestedStartTime) {
  const startTime = moment(start, 'HH:mm');
  const endTime = moment(end, 'HH:mm');

  const differenceInMinutes = endTime.diff(startTime, 'minutes');

  const baseTime = moment(suggestedStartTime, 'HH:mm');

  const newTime = baseTime.clone().add(differenceInMinutes, 'minutes');

  return newTime.format('HH:mm');
}

export function getTime(startTime, endTime) {
  if (!endTime) {
    return `${new Date(
      2023,
      0,
      1,
      startTime?.slice(0, 2),
      startTime?.slice(3)
    ).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })}`;
  }

  const time = `${new Date(
    2023,
    0,
    1,
    startTime?.slice(0, 2),
    startTime?.slice(3)
  ).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })} - ${new Date(
    2023,
    0,
    1,
    endTime?.slice(0, 2),
    endTime?.slice(3)
  ).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })}`;

  return time;
}

export function getEvents(jobApplications, userId, candidateId, timezone) {
  const events = [];

  if (!timezone) {
    return getEvents(jobApplications, userId, candidateId, 'Turkey');
  }

  jobApplications?.forEach((application) => {
    const interviews = application?.jobApplicationInterviews?.map(
      (interview) => {
        const {
          id,
          startTime,
          endTime,
          interviewDate,
          suggestedInterviewDate,
          suggestedStartTime,
          status,
          users,
          jobApplicationInterviewInvites,
        } = interview;

        let [timezoneDate, timezoneStartTime, timezoneEndTime] =
          getTimezoneDate(interviewDate, timezone, startTime, endTime);

        if (suggestedInterviewDate) {
          const suggestedEndTime = updateSuggestedEndTime(
            startTime,
            endTime,
            suggestedStartTime
          );

          [timezoneDate, timezoneStartTime, timezoneEndTime] = getTimezoneDate(
            suggestedInterviewDate,
            timezone,
            suggestedStartTime,
            suggestedEndTime
          );
        }

        return {
          id,
          event: interview,
          jobApplicationId: application.id,
          jobPost: application.jobPost,
          type: 'Interview',
          startTime: timezoneStartTime,
          endTime: timezoneEndTime,
          time: getTime(timezoneStartTime, timezoneEndTime),
          eventDate: timezoneDate,
          status,
          users,
          userId: application.user.id,
          candidate: application,
          userFirstName: application.user.userProfile?.firstName,
          userLastName: application.user.userProfile?.lastName,
          invites: jobApplicationInterviewInvites,
          jobApplications,
          isFavorite: interview?.eventFavorites?.some(
            (fav) => fav.userId === userId
          ),
        };
      }
    );
    const joinings = application?.jobApplicationJoinings?.map((joining) => {
      const {
        id,
        startTime,
        endTime,
        joiningDate,
        suggestedJoiningDate,
        suggestedStartTime,

        users,
        jobApplicationJoiningInvites,
      } = joining;

      let [timezoneDate, timezoneStartTime, timezoneEndTime] = getTimezoneDate(
        joiningDate,
        timezone,
        startTime,
        endTime
      );

      if (suggestedJoiningDate) {
        const suggestedEndTime = updateSuggestedEndTime(
          startTime,
          endTime,
          suggestedStartTime
        );

        [timezoneDate, timezoneStartTime, timezoneEndTime] = getTimezoneDate(
          suggestedJoiningDate,
          timezone,
          suggestedStartTime,
          suggestedEndTime
        );
      }

      return {
        id,
        event: joining,
        jobApplicationId: application.id,
        jobPost: application.jobPost,
        type: 'Joining',
        startTime: timezoneStartTime,
        endTime: timezoneEndTime,
        time: getTime(timezoneStartTime, timezoneEndTime),
        eventDate: timezoneDate,
        users,
        userId: application.user.id,
        candidate: application,
        userFirstName: application.user.userProfile?.firstName,
        userLastName: application.user.userProfile?.lastName,
        invites: jobApplicationJoiningInvites,
        jobApplications,
        isFavorite: joining?.eventFavorites?.some(
          (fav) => fav.userId === userId
        ),
      };
    });
    const onboardings = application?.jobApplicationOnboardings?.map(
      (onboarding) => {
        const {
          id,
          startTime,
          endTime,
          onboardingDate,
          suggestedOnboardingDate,
          suggestedStartTime,

          users,
          jobApplicationOnboardingInvites,
        } = onboarding;

        let [timezoneDate, timezoneStartTime, timezoneEndTime] =
          getTimezoneDate(onboardingDate, timezone, startTime, endTime);

        if (suggestedOnboardingDate) {
          const suggestedEndTime = updateSuggestedEndTime(
            startTime,
            endTime,
            suggestedStartTime
          );

          [timezoneDate, timezoneStartTime, timezoneEndTime] = getTimezoneDate(
            suggestedOnboardingDate,
            timezone,
            suggestedStartTime,
            suggestedEndTime
          );
        }

        return {
          id,
          event: onboarding,
          jobApplicationId: application.id,
          jobPost: application.jobPost,
          type: 'Onboarding',
          startTime: timezoneStartTime,
          endTime: timezoneEndTime,
          time: getTime(timezoneStartTime, timezoneEndTime),
          eventDate: timezoneDate,
          users,
          userId: application.user.id,
          candidate: application,
          userFirstName: application.user.userProfile?.firstName,
          userLastName: application.user.userProfile?.lastName,
          invites: jobApplicationOnboardingInvites,
          jobApplications,
          isFavorite: onboarding?.eventFavorites?.some(
            (fav) => fav.userId === userId
          ),
        };
      }
    );
    if (interviews?.length) {
      interviews.forEach((interview) => events.push({ ...interview }));
    }

    if (joinings?.length) {
      joinings.forEach((joining) => events.push({ ...joining }));
    }

    if (onboardings?.length) {
      onboardings.forEach((onboarding) => events.push({ ...onboarding }));
    }
  });

  if (candidateId) {
    return events?.filter((event) => event.id === candidateId);
  }

  return events?.filter(
    (event) =>
      event.users.find(({ id }) => id === userId) ||
      event?.event?.organizer?.id === userId
  );
}

export function getEventFromSearch(calendarEvents, userId, timezone) {
  if (!timezone) {
    return getEventFromSearch(calendarEvents, userId, 'Turkey');
  }

  return calendarEvents?.map((calendarEvent) => {
    const event =
      calendarEvent?.jobApplicationInterview ||
      calendarEvent?.jobApplicationJoining ||
      calendarEvent?.jobApplicationOnboarding;

    const eventDate =
      event?.onboardingDate || event?.joiningDate || event?.interviewDate;

    const [timezoneDate, timezoneStartTime, timezoneEndTime] = getTimezoneDate(
      eventDate,
      timezone,
      event?.startTime,
      event?.endTime
    );

    return {
      id: event?.id,
      event,
      jobApplicationId: calendarEvent.jobApplicationId,
      jobPost: calendarEvent?.jobApplication?.jobPost,
      type: calendarEvent?.type,
      startTime: timezoneStartTime,
      endTime: timezoneEndTime,
      time: getTime(timezoneStartTime, timezoneEndTime),
      eventDate: timezoneDate,
      users: event?.users,
      // userId: application.user.id,
      // candidate: application,
      userFirstName: calendarEvent?.candidateName?.split(' ')[0],
      userLastName:
        calendarEvent?.candidateName?.split(' ')[
          calendarEvent?.candidateName?.split(' ').length - 1
        ],
      invites:
        event?.jobApplicationInterviewInvites ||
        event?.jobApplicationOnboardingInvites ||
        event?.jobApplicationJoiningInvites,
      // jobApplications,
      isFavorite: event?.eventFavorites?.some((fav) => fav.userId === userId),
    };
  });
}
