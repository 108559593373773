import React, { useState, useEffect } from 'react';

import Tooltip from '../../../../Tooltip';

import goTopButtonIcon from '../../../../../assets/images/dashboard/discover/goTopButton.svg';
import goTopButtonActiveIcon from '../../../../../assets/images/dashboard/discover/goTopButtonActive.svg';
import classes from './styles.module.scss';

export default function GoTopButton({ scrollableContainerRef }) {
  const [isHovered, setIsHovered] = useState(false);
  const [containerRightPosition, setContainerRightPosition] = useState(0);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const scrollToTop = () => {
    scrollableContainerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (scrollableContainerRef?.current) {
      const rect = scrollableContainerRef.current.getBoundingClientRect();
      setContainerRightPosition(rect.right);
    }
  }, [scrollableContainerRef]);

  useEffect(() => {
    const handleResize = () => {
      const rect = scrollableContainerRef.current.getBoundingClientRect();
      setContainerRightPosition(rect.right);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [scrollableContainerRef]);

  useEffect(() => {
    const showAndHideButton = () => {
      const { scrollTop } = scrollableContainerRef.current;

      if (scrollTop > scrollableContainerRef.current.clientHeight) {
        setIsButtonVisible(true);
      } else {
        setIsButtonVisible(false);
      }
    };

    scrollableContainerRef.current.addEventListener(
      'scroll',
      showAndHideButton
    );

    return () => {
      scrollableContainerRef.current.removeEventListener(
        'scroll',
        showAndHideButton
      );
    };
  }, [scrollableContainerRef]);

  return (
    <div
      className={classes.GoTopButton}
      style={{
        left: containerRightPosition - 46 - 16,
        display: isButtonVisible ? 'block' : 'none',
      }}
    >
      <Tooltip text="Scroll up" width={83} direction="top-right" timeout={3000}>
        <button
          type="button"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={scrollToTop}
        >
          <img
            src={isHovered ? goTopButtonActiveIcon : goTopButtonIcon}
            alt="Go top"
          />
        </button>
      </Tooltip>
    </div>
  );
}
