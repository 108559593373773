import React from 'react';

import Video from './Video';
import Image from './Image';
import Controls from './Controls';

import arrowRightIcon from '../../../assets/images/dashboard/discover/arrowRight.svg';
import classes from './styles.module.scss';

export default function Slider({ currentIndex, allMedia, setCurrentIndex }) {
  const switchPrev = () => {
    setCurrentIndex((prevState) => {
      if (prevState === 0) {
        return prevState;
      }
      return prevState - 1;
    });
  };

  const switchNext = () => {
    setCurrentIndex((prevState) => {
      if (prevState === allMedia.length - 1) {
        return prevState;
      }
      return prevState + 1;
    });
  };

  return (
    <div className={classes.Slider}>
      <button
        type="button"
        className={classes.switchButton}
        onClick={switchPrev}
        disabled={currentIndex === 0}
      >
        <img src={arrowRightIcon} alt="Previous" />
      </button>
      <button
        className={classes.switchButton}
        type="button"
        onClick={switchNext}
        disabled={currentIndex === allMedia.length - 1}
      >
        <img src={arrowRightIcon} alt="Next" />
      </button>
      <div className={classes.mediaContainer}>
        {allMedia[currentIndex].type === 'image' ? (
          <Image url={allMedia[currentIndex]?.url} />
        ) : (
          <Video url={allMedia[currentIndex]?.url} />
        )}
        <Controls
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          length={allMedia.length}
        />
      </div>
    </div>
  );
}
