import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function ViewSwitch({ view, setView }) {
  const toggleView = () =>
    setView((prevState) => {
      return prevState === 'list' ? 'grid' : 'list';
    });

  return (
    <div
      className={classes.ViewSwitch}
      onClick={toggleView}
      role="button"
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          toggleView();
        }
      }}
    >
      <div
        className={classNames(classes.list, {
          [classes.active]: view === 'list',
        })}
      />
      <div
        className={classNames(classes.grid, {
          [classes.active]: view === 'grid',
        })}
      />
    </div>
  );
}
