import React, { useState, useEffect, useRef } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import User from './User';

import logo from '../../../../../../assets/images/logo-small.png';
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside';
import FeedService from '../../../../../../services/FeedService';
import classes from './styles.module.scss';

let timer;

export default function UsersPopup({
  children,
  addressees,
  setAddressees,
  show,
  top,
  left,
  onHide,
  onUserAdd,
  disableAll,
}) {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [isFixedPostion, setIsFixedPostion] = useState(false);
  const [coords, setCoords] = useState({ x: '', y: '' });

  const containerRef = useRef();
  const popupRef = useRef();

  const { t } = useTranslation();

  const hidePopup = () => {
    setIsPopupVisible(false);
    setCoords({ x: '', y: '' });
    setSearchTerm('');
    onHide?.();
  };

  useOnClickOutside(popupRef, hidePopup);

  useEffect(() => {
    if (show) {
      setIsPopupVisible(true);
    }
  }, [show]);

  useEffect(() => {
    setCoords({
      x: left,
      y: top,
    });
  }, [top, left]);

  useEffect(() => {
    timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (isPopupVisible) {
      const containerRect = containerRef.current.getBoundingClientRect();
      setCoords({
        x: left || containerRect.x,
        y: top || containerRect.y,
      });

      setTimeout(() => {
        setIsFixedPostion(true);
      }, 0);
    } else {
      setIsFixedPostion(false);
    }
  }, [isPopupVisible, left, top]);

  // Hide on parent scroll
  useEffect(() => {
    const parent = document.getElementById('dicoveryLeftCol');

    const handleScroll = () => {
      hidePopup();
    };

    parent.addEventListener('scroll', handleScroll);

    return () => {
      parent.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: users } = useQuery({
    queryKey: ['feedpostrecipients', debouncedSearchTerm],
    queryFn: () =>
      FeedService.getFeedPostRecipients({ term: debouncedSearchTerm }),
    keepPreviousData: true,
  });

  return (
    <div className={classes.UsersPopup} ref={popupRef}>
      <div
        onClick={() => {
          setIsPopupVisible(true);
        }}
        className={classes.trigger}
      >
        {children}
      </div>
      {isPopupVisible && (
        <div
          className={classes.container}
          style={{
            visibility: isFixedPostion ? 'visible' : 'hidden',
            position: isFixedPostion ? 'fixed' : '',
            top: coords.y,
            left: coords.x,
          }}
          ref={containerRef}
        >
          <div className={classes.search}>
            <input
              type="text"
              placeholder={t(
                'dashboardComponents.Discover.AddContent.TextEditor.UsersPopup.placeholderText'
              )}
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          </div>
          <ul className={classes.usersList}>
            {!disableAll && (
              <User
                isActive={addressees?.some(
                  (addressee) => addressee.value === 'To all the employee'
                )}
                setAddressees={setAddressees}
                addressees={addressees}
                allMembers
                reference={null}
                user={{
                  id: 'To all the employee',
                  userProfile: {
                    avatar: logo,
                    firstName: t(
                      'dashboardComponents.Discover.AddContent.TextEditor.UsersPopup.all'
                    ),
                    lastName: t(
                      'dashboardComponents.Discover.AddContent.TextEditor.UsersPopup.members'
                    ),
                  },
                }}
              />
            )}
            {users?.map((user) => {
              return (
                <User
                  isActive={addressees?.some(
                    (addressee) => addressee.value === user.id
                  )}
                  onUserAdd={onUserAdd}
                  addressees={addressees}
                  setAddressees={setAddressees}
                  key={user.id}
                  user={user}
                />
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
