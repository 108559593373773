/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useCallback, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import SearchInput from '../SearchInput';
import Assessment from './Assessment';
import classes from './styles.module.scss';

export default function SelectedAssessments({
  fields,
  setFields,
  assignedAssessments,
  setAssignedAssessments,
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    const assigned = [];

    fields.forEach((field) => {
      field.tests.forEach((test) => {
        if (test.isAssigned) {
          assigned.push(test);
        }
      });
    });

    setAssignedAssessments(assigned);
  }, [fields, setAssignedAssessments]);

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const newArray = Array.from(assignedAssessments);
    const [removed] = newArray.splice(sourceIndex, 1);
    newArray.splice(destinationIndex, 0, removed);

    setAssignedAssessments(newArray);
  };

  const deleteAssessment = (field) => {
    setFields((prevFields) => {
      return prevFields.map((prevField) => {
        if (prevField.fieldId !== field.fieldId) {
          return prevField;
        }
        return {
          ...prevField,
          tests: prevField.tests.map((test) => {
            if (test.assessmentId !== field.assessmentId) {
              return test;
            }
            return {
              ...test,
              isAssigned: false,
              isSelected: false,
              threshold: null,
              mentalThreshold: null,
              englishThreshold: null,
            };
          }),
        };
      });
    });
  };

  const setThreshold = useCallback(
    (id, name, value) => {
      setAssignedAssessments((prev) => {
        return prev.map((assessment) => {
          if (assessment.assessmentId !== id) {
            return assessment;
          }
          return {
            ...assessment,
            [name]: value,
          };
        });
      });
    },
    [setAssignedAssessments]
  );

  return (
    <div className={classes.SelectedAssessments}>
      <div className={classes.assessmentHeader}>
        <h2>
          {t('modals.AssessmentModal.selectedAssessments.selectedAssessment')}
        </h2>
        <span
          className={classNames(classes.amount, {
            [classes.green]: assignedAssessments.length > 0,
          })}
        >
          {assignedAssessments.length}
        </span>
      </div>
      <div className={classes.search}>
        <SearchInput value={searchTerm} setValue={setSearchTerm} />
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="assessments">
          {(provided) => (
            <div
              className={classes.content}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {assignedAssessments
                ?.filter(
                  (assessment) =>
                    assessment.name
                      ?.toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    assessment.assessment
                      ?.toLowerCase()
                      .includes(searchTerm.toLowerCase())
                )
                ?.map((assessment, index) => (
                  <Draggable
                    key={`${assessment.assessmentId}${assessment.name}`}
                    draggableId={`item-$${assessment.assessmentId}${assessment.name}`}
                    index={index}
                  >
                    {(provid) => (
                      <div
                        {...provid.draggableProps}
                        {...provid.dragHandleProps}
                        ref={provid.innerRef}
                      >
                        <Assessment
                          key={`${assessment.assessmentId}${assessment.name}`}
                          assessment={assessment}
                          deleteAssessment={() => deleteAssessment(assessment)}
                          setThreshold={setThreshold}
                          touched
                        />
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>
                ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
