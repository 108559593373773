import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Tooltip from '../../Tooltip';
import Button from '../../Button';
import logo from '../../../assets/images/logo-small.png';
import classes from './styles.module.scss';

export default function AuthHeader({ noButton, previousRoute }) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <div className={classes.AuthHeader}>
      <div className={classes.logo}>
        <img src={logo} alt="Logo" />
        <div className={classes.text}>
          <span>{t('common.AuthHeader.poweredBy')}</span>
          <span>Zaphire</span>
        </div>
        {previousRoute && (
          <div className={classes.backButtonContainer}>
            <Tooltip text="Back" direction="left">
              <i
                className={classes.backButton}
                onClick={() => navigate(previousRoute)}
              >
                {t('common.AuthHeader.backTooltip')}
              </i>
            </Tooltip>
          </div>
        )}
      </div>
      {!noButton && (
        <Button
          variant="secondary"
          width={175}
          onClick={() => navigate('/login')}
        >
          {t('common.signIn')}
        </Button>
      )}
    </div>
  );
}
