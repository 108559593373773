import React from 'react';

import { useTranslation } from 'react-i18next';

import ContactsList from './ContactsList';

import classes from './styles.module.scss';

export default function Contacts({
  chatThreads,
  activeContactId,
  setActiveContactId,
  refetchChatThreads,
}) {
  const { t } = useTranslation();

  const threadsWithPinnedContacts = chatThreads?.filter((group) =>
    group.threads.some((thread) => !!thread.isPinned)
  );

  const threadsWithoutNotPinnedContacts = [];

  threadsWithPinnedContacts?.forEach((group) => {
    const threads = group.threads.filter((thread) => !!thread.isPinned);
    // eslint-disable-next-line no-param-reassign
    group.threads = threads;
    threadsWithoutNotPinnedContacts.push(group);
  });

  return (
    <div className={classes.Contacts}>
      <ContactsList
        chatThreads={threadsWithPinnedContacts}
        activeContactId={activeContactId}
        setActiveContactId={setActiveContactId}
        refetchChatThreads={refetchChatThreads}
        type="pinned"
        title={
          <>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.2897 4.52706L9.4745 1.7119C9.29475 1.53219 9.07488 1.39777 8.83299 1.31971C8.59111 1.24165 8.33414 1.22219 8.08325 1.26292C7.83237 1.30365 7.59475 1.40342 7.38999 1.55399C7.18522 1.70456 7.01916 1.90164 6.9055 2.12898L5.48508 4.96981C5.43398 5.07224 5.34477 5.15057 5.23658 5.18798L2.80642 6.02798C2.6896 6.06834 2.58496 6.13773 2.50233 6.22964C2.41969 6.32155 2.36178 6.43295 2.33402 6.55338C2.30625 6.67381 2.30956 6.79933 2.34362 6.91813C2.37769 7.03694 2.44139 7.14514 2.52875 7.23256L4.33942 9.04323L1.75 11.6332V12.2516H2.36833L4.95833 9.66156L6.769 11.4728C6.85642 11.5603 6.96466 11.6241 7.08353 11.6583C7.20241 11.6924 7.32802 11.6958 7.44854 11.668C7.56907 11.6402 7.68055 11.5823 7.7725 11.4995C7.86445 11.4168 7.93385 11.3121 7.97417 11.1951L8.81417 8.76498C8.85144 8.6569 8.92954 8.5677 9.03175 8.51648L11.8726 7.09606C12.0999 6.9824 12.297 6.81634 12.4476 6.61158C12.5981 6.40681 12.6979 6.1692 12.7386 5.91831C12.7794 5.66743 12.7599 5.41046 12.6818 5.16857C12.6038 4.92669 12.4694 4.70681 12.2897 4.52706Z"
                fill="#C1C5D6"
              />
            </svg>
            {t('dashboardComponents.Chat.LeftPanel.Contacts.pinned')}
          </>
        }
      />
      <ContactsList
        chatThreads={chatThreads}
        activeContactId={activeContactId}
        setActiveContactId={setActiveContactId}
        refetchChatThreads={refetchChatThreads}
        title={t('dashboardComponents.Chat.LeftPanel.Contacts.allContacts')}
        type="all"
      />
    </div>
  );
}
