import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';

import Post from '../Feed/Post';

import { useUserStore } from '../../../../store';
import { getAuthData } from '../../../../helpers/authStorage';
import FeedService from '../../../../services/FeedService';
import classes from './styles.module.scss';

export default function SinglePost({ setEditingPost }) {
  const { '*': feedPostId } = useParams();
  const navigate = useNavigate();

  const { userId } = getAuthData();

  const userScopes = useUserStore((state) => state.userScopes);

  const { data: feedPost, refetch } = useQuery({
    queryKey: ['feedPost', feedPostId],
    queryFn: () => FeedService.getFeedPost({ id: feedPostId }),
    onError: () => {
      navigate('/404');
    },
  });

  return (
    <div className={classes.SinglePost}>
      {feedPost && (
        <Post
          attachments={feedPost?.attachments?.filter(
            (attach) =>
              !attach.mimeType.includes('image') &&
              !attach.mimeType.includes('video')
          )}
          isAuthor={feedPost.author?.id === +userId}
          isAdmin={userScopes.includes('api:full')}
          setEditingPost={setEditingPost}
          refetch={refetch}
          feedPost={feedPost}
          key={feedPost.id}
        />
      )}
    </div>
  );
}
