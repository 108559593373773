/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import ToolTip from '../../../../../Tooltip';

import classes from './styles.module.scss';
import thumbsUpIcon from '../../../../../../assets/images/dashboard/discover/emojis/thumbs-up.png';
import heartIcon from '../../../../../../assets/images/dashboard/discover/emojis/heart.png';
import celebrateIcon from '../../../../../../assets/images/dashboard/discover/emojis/celebrate.png';
import laughIcon from '../../../../../../assets/images/dashboard/discover/emojis/laugh.png';
import surprisedIcon from '../../../../../../assets/images/dashboard/discover/emojis/surprised.png';
import sadIcon from '../../../../../../assets/images/dashboard/discover/emojis/sad.png';
import angryIcon from '../../../../../../assets/images/dashboard/discover/emojis/angry.png';

import useOnClickOutside from '../../../../../../hooks/useOnClickOutside';

export default function ReactionPicker({ children, addReaction, disabled }) {
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [pickerPosition, setPickerPosition] = useState({
    x: 0,
    y: 0,
  });

  const reactionPickerRef = useRef();
  const emojiListRef = useRef();

  useOnClickOutside(emojiListRef, () => {
    setIsPickerVisible(false);
  });

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const handleMouseEnter = () => {
    const parentPosition = reactionPickerRef?.current?.getBoundingClientRect();

    if (disabled) {
      return;
    }
    setIsPickerVisible(true);

    if (!isTabletOrMobile) {
      setPickerPosition({ x: parentPosition.x, y: parentPosition.y });
    }
  };

  const handleMouseLeave = () => {
    setIsPickerVisible(false);
  };

  const buttons = [
    {
      icon: thumbsUpIcon,
      label: t(
        'dashboardComponents.Discover.Feed.Post.ReactionPicker.Thumbs Up'
      ),
      value: 'ThumbUp',
    },
    {
      icon: heartIcon,
      label: t('dashboardComponents.Discover.Feed.Post.ReactionPicker.Love'),
      value: 'Love',
    },
    {
      icon: celebrateIcon,
      label: t(
        'dashboardComponents.Discover.Feed.Post.ReactionPicker.Celebrate'
      ),
      value: 'Celebrate',
    },
    {
      icon: laughIcon,
      label: t('dashboardComponents.Discover.Feed.Post.ReactionPicker.Laugh'),
      value: 'Laugh',
    },
    {
      icon: surprisedIcon,
      label: t(
        'dashboardComponents.Discover.Feed.Post.ReactionPicker.Suprised'
      ),
      value: 'Suprised',
    },
    {
      icon: sadIcon,
      label: t('dashboardComponents.Discover.Feed.Post.ReactionPicker.Sad'),
      value: 'Sad',
    },
    {
      icon: angryIcon,
      label: t('dashboardComponents.Discover.Feed.Post.ReactionPicker.Angry'),
      value: 'Angry',
    },
  ];

  return (
    <div
      className={classes.ReactionPicker}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={!isTabletOrMobile ? handleMouseLeave : null}
      onClick={handleMouseEnter}
      ref={reactionPickerRef}
    >
      <div className={classes.trigger}>{children}</div>
      {isPickerVisible && (
        <ul
          ref={emojiListRef}
          className={classes.emojiList}
          style={{
            left: pickerPosition.x - 100,
            top: pickerPosition.y - 15,
          }}
        >
          {buttons.map((button) => (
            <li key={button.label}>
              <ToolTip text={button.label} direction="top" noWrap>
                <button
                  type="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    addReaction(button.value);
                    setIsPickerVisible(false);
                  }}
                >
                  <img src={button.icon} alt={button.label} />
                </button>
              </ToolTip>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
