import React from 'react';
import Assessment from './Assessment';
import Interview from './Interview';

export default function AssessmentAndInterview({ candidate, job, refetch }) {
  return (
    <div>
      <Assessment candidate={candidate} job={job} />
      <Interview candidate={candidate} job={job} refetch={refetch} />
    </div>
  );
}
