import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function Tags({ tags }) {
  const { t } = useTranslation();
  return (
    <div className={classes.Tags}>
      <h3>{t('dashboardComponents.Discover.Feed.Post.Tags.tags')}:</h3>
      <ul>
        {tags.map((tag, index) => (
          <li key={tag.value}>
            {tag.value}
            {index < tags.length - 1 ? ', ' : ''}
          </li>
        ))}
      </ul>
    </div>
  );
}
