import React from 'react';

import { useIsFetching } from '@tanstack/react-query';
import classNames from 'classnames';
import Lottie from 'lottie-react';

import loaderAnimation from '../../assets/animations/loader.json';
import classes from './styles.module.scss';

export default function Loader({ relative, width, isFetching }) {
  const isLoading = useIsFetching({
    predicate: (query) => {
      return (
        query.state.status === 'loading' || query.state.fetchMeta?.fetchMore
      );
    },
  });

  if (!isLoading && !isFetching) {
    return null;
  }

  return (
    <div
      className={classNames(classes.Loader, {
        [classes.relative]: relative,
      })}
    >
      <Lottie
        animationData={loaderAnimation}
        style={{
          width: width || '15%',
        }}
        loop
      />
    </div>
  );
}
