/* eslint-disable no-plusplus */
/* eslint-disable react/no-children-prop */
import React, { useContext, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from '../../../../Button';
import AvailableColumns from './AvailableColumns';
import SelectedColumns from './SelectedColumns';

import { UiContext } from '../../../../../context/UiContext';
import ReportsService from '../../../../../services/ReportsService';
import classes from './styles.module.scss';

function compareArrays(arr1, arr2) {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  for (let i = 0; i < arr1?.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

export default function EditColumnsModal({
  reports,
  activeReport,
  searchTerm,
  activeSortHeader,
  fields,
  setFields,
  refetch,
  handleClose,
  savedReports,
}) {
  const [availableColumns, setAvailableColumns] = useState([]);
  const [availableSelectedColumns, setAvailableSelectedColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const { showModal, setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const addReport = async () => {
    try {
      setIsFetching(true);
      await ReportsService.addReportToStored({
        title: activeReport.title,
        type: activeReport.type,
        term: '',
        orderBy: '',
        fields: selectedColumns,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const updateReport = async () => {
    try {
      setIsFetching(true);
      if (activeReport?.id) {
        await ReportsService.updateStoredReport({
          id: activeReport.id,
          title: activeReport.title,
          type: activeReport.type,
          term: activeReport.term,
          orderBy: activeReport.orderBy,
          fields: selectedColumns,
        });
      } else {
        const savedActiveReport = savedReports?.find(
          (report) => report?.title === activeReport?.title
        );
        if (!savedActiveReport) {
          await addReport();
        } else {
          await ReportsService.updateStoredReport({
            id: savedActiveReport.id,
            title: activeReport.title,
            type: activeReport.type,
            term: searchTerm,
            orderBy: activeSortHeader,
            fields: selectedColumns,
          });
        }
      }

      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const saveFields = () => {
    updateReport();

    setFields([...selectedColumns]);
  };

  const showSaveModal = () => {
    if (!fields?.length) {
      handleClose();
      return;
    }

    showModal({
      title: t(
        'dashboardComponents.Reports.RightPanel.EditColumnsModal.saveChanges.title'
      ),
      text: t(
        'dashboardComponents.Reports.RightPanel.EditColumnsModal.saveChanges.message'
      ),
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
      icon: 'saveChanges',
      onConfirm: () => {
        saveFields();
        setTimeout(() => {
          handleClose();
        }, 200);
      },
      onCancel: () => {
        setTimeout(() => {
          handleClose();
        }, 200);
      },
    });
  };

  const showExitModal = () => {
    if (!fields?.length) {
      handleClose();
      return;
    }

    showModal({
      title: t(
        'dashboardComponents.Reports.RightPanel.EditColumnsModal.exitScreen.title'
      ),
      text: t(
        'dashboardComponents.Reports.RightPanel.EditColumnsModal.exitScreen.message'
      ),
      dismissButtonLabel: t('common.yes'),
      confirmButtonLabel: t('common.no'),
      onConfirm: () => {},
      onCancel: () => {
        setTimeout(() => {
          handleClose();
        }, 200);
      },
    });
  };

  useEffect(() => {
    setAvailableColumns(
      reports?.availableFields?.filter(
        (field) => !selectedColumns?.includes(field)
      )
    );
  }, [selectedColumns, reports]);

  useEffect(() => {
    if (!fields) {
      setFields([]);
      return;
    }

    setSelectedColumns([...fields]);
  }, [fields, setFields]);

  // Hide scroll on container when modal is opened
  useEffect(() => {
    const container = document.body.querySelector('#dashboardPageContainer');

    if (container) {
      container.style.overflow = 'hidden';
    }

    return () => {
      if (container) {
        container.style.overflow = 'unset';
      }
    };
  }, []);

  const closeModal = () => {
    if (!compareArrays(fields, selectedColumns)) {
      showExitModal();
    } else {
      handleClose();
    }
  };

  const reset = () => {
    if (!reports || !fields) {
      return;
    }

    setSelectedColumns(reports?.mandatoryFields);
  };

  const moveColumnsToSelected = () => {
    setSelectedColumns((prev) => [...prev, ...availableSelectedColumns]);
    setAvailableSelectedColumns([]);
  };

  const moveSelectedButton = (
    <span className={classes.moveSelected}>
      <span>
        {t(
          'dashboardComponents.Reports.RightPanel.EditColumnsModal.moveSelected'
        )}
      </span>
      <span className={classes.arrow}>{'>'}</span>
    </span>
  );

  return (
    <Modal
      show
      centered
      className={classes.EditColumnsModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.modalContainer}>
        <div className={classes.header}>
          <div>
            <h1>
              {t(
                'dashboardComponents.Reports.RightPanel.EditColumnsModal.title'
              )}
            </h1>
            <span>
              {t(
                'dashboardComponents.Reports.RightPanel.EditColumnsModal.description'
              )}
            </span>
          </div>
          <span
            className={classes.closeIcon}
            onClick={() => {
              if (!compareArrays(fields, selectedColumns)) {
                showSaveModal();
                return;
              }
              handleClose();
            }}
          />
        </div>

        <div className={classes.container}>
          <AvailableColumns
            columns={availableColumns}
            selectedColumns={availableSelectedColumns}
            setSelectedColumns={setAvailableSelectedColumns}
          />
          <SelectedColumns
            columns={selectedColumns}
            setColumns={setSelectedColumns}
            mandatoryColumns={reports?.mandatoryFields}
          />
        </div>

        <div className={classes.footer}>
          <div className={classes.leftPanel}>
            <span
              className={classNames(classes.reset, {
                [classes.disabled]: compareArrays(
                  selectedColumns,
                  reports?.mandatoryFields
                ),
              })}
              onClick={reset}
            >
              {t(
                'dashboardComponents.Reports.RightPanel.EditColumnsModal.resetToDefault'
              )}
            </span>
            <Button
              width={171}
              height={45}
              children={moveSelectedButton}
              style={{
                fontFamily: 'Gilroy-SemiBold',
                fontSize: '16px',
                color: !availableSelectedColumns?.length ? '' : 'white',
              }}
              disabled={!availableSelectedColumns?.length}
              onClick={moveColumnsToSelected}
            />
          </div>
          <div className={classes.rightPanel}>
            <Button
              width={120}
              height={45}
              children="Save"
              style={{
                fontFamily: 'Gilroy-SemiBold',
                fontSize: '16px',
                color:
                  compareArrays(fields, selectedColumns) || !fields?.length
                    ? ''
                    : 'white',
              }}
              disabled={
                compareArrays(fields, selectedColumns) || !fields?.length
              }
              onClick={saveFields}
            />
            <Button
              width={120}
              height={45}
              children="Cancel"
              variant="delete"
              style={{
                fontFamily: 'Gilroy-SemiBold',
                fontSize: '16px',
              }}
              onClick={closeModal}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
