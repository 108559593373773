import React, { useState } from 'react';

import classNames from 'classnames';

import { ReactComponent as SwitchIcon } from '../../../../../../../../../assets/images/dashboard/brand-kit/switch.svg';

import classes from './styles.module.scss';

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null;
}

export default function ColorInfo({ hexCode }) {
  const [isHex, setIsHex] = useState(true);
  return (
    <div className={classes.ColorInfo}>
      <div className={classes.colorModel}>
        {isHex ? 'Hex' : 'RGB'}
        <button
          className={classNames(classes.switch, {
            [classes.hex]: !isHex,
          })}
          type="button"
          onClick={() => {
            setIsHex((prevState) => !prevState);
          }}
        >
          <SwitchIcon />
        </button>
      </div>
      <div className={classes.color}>
        <span className={classes.gray}>{isHex && '#'}</span>{' '}
        {isHex ? hexCode?.slice(1) : hexToRgb(hexCode?.slice(1))}
      </div>
    </div>
  );
}
