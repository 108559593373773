import React from 'react';

import { useMediaQuery } from 'react-responsive';

import TextInput from '../../../../../../../Form/TextInput';
import classes from './styles.module.scss';

export default function Answer({
  deleteAnswer,
  alphabet,
  changeAnswer,
  value,
  dragAnswer,
  isDuplicate,
  setIsAnswerBeingDragged,
  onEnterPress,
  index,
}) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div
      className={classes.Answer}
      draggable
      onDragStart={dragAnswer}
      onDragEnd={() => {
        if (!setIsAnswerBeingDragged) {
          return;
        }
        setIsAnswerBeingDragged(false);
      }}
    >
      <div className={classes.prefix}>
        <svg
          width="11"
          height="20"
          viewBox="0 0 11 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="2" cy="2" r="2" fill="#C1C5D6" />
          <circle cx="9" cy="2" r="2" fill="#C1C5D6" />
          <circle cx="2" cy="10" r="2" fill="#C1C5D6" />
          <circle cx="9" cy="10" r="2" fill="#C1C5D6" />
          <circle cx="2" cy="18" r="2" fill="#C1C5D6" />
          <circle cx="9" cy="18" r="2" fill="#C1C5D6" />
        </svg>
        <span className={classes.alphabet}>{alphabet}.</span>
      </div>
      <div
        className={classes.inputContainer}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onEnterPress?.();
          }
        }}
      >
        <TextInput
          height={50}
          style={{
            borderRadius: isTabletOrMobile ? 8 : '',
            fontFamily: isTabletOrMobile ? 'Gilroy-Bold' : '',
          }}
          value={value}
          onChange={(event) => changeAnswer(event.target.value)}
          placeholder={`Option ${index + 1}`}
          error={isDuplicate && 'Duplicate'}
          touched={isDuplicate}
        />
      </div>
      <i className={classes.deleteIcon} onClick={deleteAnswer}>
        Delete
      </i>
    </div>
  );
}
