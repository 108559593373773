import i18next from '../i18n';

const t = i18next.t.bind(i18next);

export const PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_URL;
export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;
export const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;
export const JOBS_API_URL = process.env.REACT_APP_JOBS_API_URL;
export const JOBS_API_URL_V2 = process.env.REACT_APP_JOBS_API_URL_V2;
export const CMS_API_URL = process.env.REACT_APP_CMS_API_URL;
export const CANDIDATES_API_URL = process.env.REACT_APP_CANDIDATES_API_URL;
export const PLATFORM_API_URL = process.env.REACT_APP_PLATFORM_API_URL;
export const ANONYMOUS_API_URL = process.env.REACT_APP_ANONYMOUS_API_URL;
export const ANONYMOUS_JOBS_API_URL =
  process.env.REACT_APP_ANONYMOUS_JOBS_API_URL;
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL;
export const MAP_KEY = process.env.REACT_APP_MAP_KEY;
export const TENANT_ID = process.env.REACT_APP_TENANT_ID;

export const JOBS_PORTAL_URL = process.env.REACT_APP_JOBS_PORTAL_URL;

export const PRESENCE_HUB_URL = process.env.REACT_APP_PRESENCE_HUB_URL;
export const CHAT_HUB_URL = process.env.REACT_APP_CHAT_HUB_URL;

export const JOB_APPLICATION_STATUSES = {
  Screen: t('common.jobApplicationStatuses.Screen'),
  Onboard: t('common.jobApplicationStatuses.Onboard'),
  Draft: t('common.jobApplicationStatuses.Draft'),
  New: t('common.jobApplicationStatuses.New'),
  Interview: t('common.jobApplicationStatuses.Interview'),
  Offer: t('common.jobApplicationStatuses.Offer'),
  JobClosed: t('common.jobApplicationStatuses.JobClosed'),
  Invited: t('common.jobApplicationStatuses.Invited'),
};

export const COUNTRY_OPTIONS = [
  { label: t('common.countries.Afghanistan'), value: 'AF' },
  { label: t('common.countries.Aland Islands'), value: 'AX' },
  { label: t('common.countries.Albania'), value: 'AL' },
  { label: t('common.countries.Algeria'), value: 'DZ' },
  { label: t('common.countries.American Samoa'), value: 'AS' },
  { label: t('common.countries.Andorra'), value: 'AD' },
  { label: t('common.countries.Angola'), value: 'AO' },
  { label: t('common.countries.Anguilla'), value: 'AI' },
  { label: t('common.countries.Antarctica'), value: 'AQ' },
  { label: t('common.countries.Antigua and Barbuda'), value: 'AG' },
  { label: t('common.countries.Argentina'), value: 'AR' },
  { label: t('common.countries.Armenia'), value: 'AM' },
  { label: t('common.countries.Aruba'), value: 'AW' },
  { label: t('common.countries.Australia'), value: 'AU' },
  { label: t('common.countries.Austria'), value: 'AT' },
  { label: t('common.countries.Azerbaijan'), value: 'AZ' },
  { label: t('common.countries.Bahamas'), value: 'BS' },
  { label: t('common.countries.Bahrain'), value: 'BH' },
  { label: t('common.countries.Bangladesh'), value: 'BD' },
  { label: t('common.countries.Barbados'), value: 'BB' },
  { label: t('common.countries.Belarus'), value: 'BY' },
  { label: t('common.countries.Belgium'), value: 'BE' },
  { label: t('common.countries.Belize'), value: 'BZ' },
  { label: t('common.countries.Benin'), value: 'BJ' },
  { label: t('common.countries.Bermuda'), value: 'BM' },
  { label: t('common.countries.Bhutan'), value: 'BT' },
  { label: t('common.countries.Bolivia'), value: 'BO' },
  {
    label: t('common.countries.Bonaire, Saint Eustatius and Saba'),
    value: 'BQ',
  },
  { label: t('common.countries.Bosnia and Herzegovina'), value: 'BA' },
  { label: t('common.countries.Botswana'), value: 'BW' },
  { label: t('common.countries.Bouvet Island'), value: 'BV' },
  { label: t('common.countries.Brazil'), value: 'BR' },
  { label: t('common.countries.British Indian Ocean Territory'), value: 'IO' },
  { label: t('common.countries.British Virgin Islands'), value: 'VG' },
  { label: t('common.countries.Brunei'), value: 'BN' },
  { label: t('common.countries.Bulgaria'), value: 'BG' },
  { label: t('common.countries.Burkina Faso'), value: 'BF' },
  { label: t('common.countries.Burundi'), value: 'BI' },
  { label: t('common.countries.Cambodia'), value: 'KH' },
  { label: t('common.countries.Cameroon'), value: 'CM' },
  { label: t('common.countries.Canada'), value: 'CA' },
  { label: t('common.countries.Cape Verde'), value: 'CV' },
  { label: t('common.countries.Cayman Islands'), value: 'KY' },
  { label: t('common.countries.Central African Republic'), value: 'CF' },
  { label: t('common.countries.Chad'), value: 'TD' },
  { label: t('common.countries.Chile'), value: 'CL' },
  { label: t('common.countries.China'), value: 'CN' },
  { label: t('common.countries.Christmas Island'), value: 'CX' },
  { label: t('common.countries.Cocos Islands'), value: 'CC' },
  { label: t('common.countries.Colombia'), value: 'CO' },
  { label: t('common.countries.Comoros'), value: 'KM' },
  { label: t('common.countries.Cook Islands'), value: 'CK' },
  { label: t('common.countries.Costa Rica'), value: 'CR' },
  { label: t('common.countries.Croatia'), value: 'HR' },
  { label: t('common.countries.Cuba'), value: 'CU' },
  { label: t('common.countries.Curacao'), value: 'CW' },
  { label: t('common.countries.Cyprus'), value: 'CY' },
  { label: t('common.countries.Czech Republic'), value: 'CZ' },
  {
    label: t('common.countries.Democratic Republic of the Congo'),
    value: 'CD',
  },
  { label: t('common.countries.Denmark'), value: 'DK' },
  { label: t('common.countries.Djibouti'), value: 'DJ' },
  { label: t('common.countries.Dominica'), value: 'DM' },
  { label: t('common.countries.Dominican Republic'), value: 'DO' },
  { label: t('common.countries.East Timor'), value: 'TL' },
  { label: t('common.countries.Ecuador'), value: 'EC' },
  { label: t('common.countries.Egypt'), value: 'EG' },
  { label: t('common.countries.El Salvador'), value: 'SV' },
  { label: t('common.countries.Equatorial Guinea'), value: 'GQ' },
  { label: t('common.countries.Eritrea'), value: 'ER' },
  { label: t('common.countries.Estonia'), value: 'EE' },
  { label: t('common.countries.Ethiopia'), value: 'ET' },
  { label: t('common.countries.Falkland Islands'), value: 'FK' },
  { label: t('common.countries.Faroe Islands'), value: 'FO' },
  { label: t('common.countries.Fiji'), value: 'FJ' },
  { label: t('common.countries.Finland'), value: 'FI' },
  { label: t('common.countries.France'), value: 'FR' },
  { label: t('common.countries.French Guiana'), value: 'GF' },
  { label: t('common.countries.French Polynesia'), value: 'PF' },
  { label: t('common.countries.French Southern Territories'), value: 'TF' },
  { label: t('common.countries.Gabon'), value: 'GA' },
  { label: t('common.countries.Gambia'), value: 'GM' },
  { label: t('common.countries.Georgia'), value: 'GE' },
  { label: t('common.countries.Germany'), value: 'DE' },
  { label: t('common.countries.Ghana'), value: 'GH' },
  { label: t('common.countries.Gibraltar'), value: 'GI' },
  { label: t('common.countries.Greece'), value: 'GR' },
  { label: t('common.countries.Greenland'), value: 'GL' },
  { label: t('common.countries.Grenada'), value: 'GD' },
  { label: t('common.countries.Guadeloupe'), value: 'GP' },
  { label: t('common.countries.Guam'), value: 'GU' },
  { label: t('common.countries.Guatemala'), value: 'GT' },
  { label: t('common.countries.Guernsey'), value: 'GG' },
  { label: t('common.countries.Guinea'), value: 'GN' },
  { label: t('common.countries.Guinea-Bissau'), value: 'GW' },
  { label: t('common.countries.Guyana'), value: 'GY' },
  { label: t('common.countries.Haiti'), value: 'HT' },
  {
    label: t('common.countries.Heard Island and McDonald Islands'),
    value: 'HM',
  },
  { label: t('common.countries.Honduras'), value: 'HN' },
  { label: t('common.countries.Hong Kong'), value: 'HK' },
  { label: t('common.countries.Hungary'), value: 'HU' },
  { label: t('common.countries.Iceland'), value: 'IS' },
  { label: t('common.countries.India'), value: 'IN' },
  { label: t('common.countries.Indonesia'), value: 'ID' },
  { label: t('common.countries.Iran'), value: 'IR' },
  { label: t('common.countries.Iraq'), value: 'IQ' },
  { label: t('common.countries.Ireland'), value: 'IE' },
  { label: t('common.countries.Isle of Man'), value: 'IM' },
  { label: t('common.countries.Israel'), value: 'IL' },
  { label: t('common.countries.Italy'), value: 'IT' },
  { label: t('common.countries.Ivory Coast'), value: 'CI' },
  { label: t('common.countries.Jamaica'), value: 'JM' },
  { label: t('common.countries.Japan'), value: 'JP' },
  { label: t('common.countries.Jersey'), value: 'JE' },
  { label: t('common.countries.Jordan'), value: 'JO' },
  { label: t('common.countries.Kazakhstan'), value: 'KZ' },
  { label: t('common.countries.Kenya'), value: 'KE' },
  { label: t('common.countries.Kiribati'), value: 'KI' },
  { label: t('common.countries.Kosovo'), value: 'XK' },
  { label: t('common.countries.Kuwait'), value: 'KW' },
  { label: t('common.countries.Kyrgyzstan'), value: 'KG' },
  { label: t('common.countries.Laos'), value: 'LA' },
  { label: t('common.countries.Latvia'), value: 'LV' },
  { label: t('common.countries.Lebanon'), value: 'LB' },
  { label: t('common.countries.Lesotho'), value: 'LS' },
  { label: t('common.countries.Liberia'), value: 'LR' },
  { label: t('common.countries.Libya'), value: 'LY' },
  { label: t('common.countries.Liechtenstein'), value: 'LI' },
  { label: t('common.countries.Lithuania'), value: 'LT' },
  { label: t('common.countries.Luxembourg'), value: 'LU' },
  { label: t('common.countries.Macao'), value: 'MO' },
  { label: t('common.countries.Macedonia'), value: 'MK' },
  { label: t('common.countries.Madagascar'), value: 'MG' },
  { label: t('common.countries.Malawi'), value: 'MW' },
  { label: t('common.countries.Malaysia'), value: 'MY' },
  { label: t('common.countries.Maldives'), value: 'MV' },
  { label: t('common.countries.Mali'), value: 'ML' },
  { label: t('common.countries.Malta'), value: 'MT' },
  { label: t('common.countries.Marshall Islands'), value: 'MH' },
  { label: t('common.countries.Martinique'), value: 'MQ' },
  { label: t('common.countries.Mauritania'), value: 'MR' },
  { label: t('common.countries.Mauritius'), value: 'MU' },
  { label: t('common.countries.Mayotte'), value: 'YT' },
  { label: t('common.countries.Mexico'), value: 'MX' },
  { label: t('common.countries.Micronesia'), value: 'FM' },
  { label: t('common.countries.Moldova'), value: 'MD' },
  { label: t('common.countries.Monaco'), value: 'MC' },
  { label: t('common.countries.Mongolia'), value: 'MN' },
  { label: t('common.countries.Montenegro'), value: 'ME' },
  { label: t('common.countries.Montserrat'), value: 'MS' },
  { label: t('common.countries.Morocco'), value: 'MA' },
  { label: t('common.countries.Mozambique'), value: 'MZ' },
  { label: t('common.countries.Myanmar'), value: 'MM' },
  { label: t('common.countries.Namibia'), value: 'NA' },
  { label: t('common.countries.Nauru'), value: 'NR' },
  { label: t('common.countries.Nepal'), value: 'NP' },
  { label: t('common.countries.Netherlands'), value: 'NL' },
  { label: t('common.countries.New Caledonia'), value: 'NC' },
  { label: t('common.countries.New Zealand'), value: 'NZ' },
  { label: t('common.countries.Nicaragua'), value: 'NI' },
  { label: t('common.countries.Niger'), value: 'NE' },
  { label: t('common.countries.Nigeria'), value: 'NG' },
  { label: t('common.countries.Niue'), value: 'NU' },
  { label: t('common.countries.Norfolk Island'), value: 'NF' },
  { label: t('common.countries.North Korea'), value: 'KP' },
  { label: t('common.countries.Northern Mariana Islands'), value: 'MP' },
  { label: t('common.countries.Norway'), value: 'NO' },
  { label: t('common.countries.Oman'), value: 'OM' },
  { label: t('common.countries.Pakistan'), value: 'PK' },
  { label: t('common.countries.Palau'), value: 'PW' },
  { label: t('common.countries.Palestinian Territory'), value: 'PS' },
  { label: t('common.countries.Panama'), value: 'PA' },
  { label: t('common.countries.Papua New Guinea'), value: 'PG' },
  { label: t('common.countries.Paraguay'), value: 'PY' },
  { label: t('common.countries.Peru'), value: 'PE' },
  { label: t('common.countries.Philippines'), value: 'PH' },
  { label: t('common.countries.Pitcairn'), value: 'PN' },
  { label: t('common.countries.Poland'), value: 'PL' },
  { label: t('common.countries.Portugal'), value: 'PT' },
  { label: t('common.countries.Puerto Rico'), value: 'PR' },
  { label: t('common.countries.Qatar'), value: 'QA' },
  { label: t('common.countries.Republic of the Congo'), value: 'CG' },
  { label: t('common.countries.Reunion'), value: 'RE' },
  { label: t('common.countries.Romania'), value: 'RO' },
  { label: t('common.countries.Russia'), value: 'RU' },
  { label: t('common.countries.Rwanda'), value: 'RW' },
  { label: t('common.countries.Saint Barthelemy'), value: 'BL' },
  { label: t('common.countries.Saint Helena'), value: 'SH' },
  { label: t('common.countries.Saint Kitts and Nevis'), value: 'KN' },
  { label: t('common.countries.Saint Lucia'), value: 'LC' },
  { label: t('common.countries.Saint Martin'), value: 'MF' },
  { label: t('common.countries.Saint Pierre and Miquelon'), value: 'PM' },
  {
    label: t('common.countries.Saint Vincent and the Grenadines'),
    value: 'VC',
  },
  { label: t('common.countries.Samoa'), value: 'WS' },
  { label: t('common.countries.San Marino'), value: 'SM' },
  { label: t('common.countries.Sao Tome and Principe'), value: 'ST' },
  { label: t('common.countries.Saudi Arabia'), value: 'SA' },
  { label: t('common.countries.Senegal'), value: 'SN' },
  { label: t('common.countries.Serbia'), value: 'RS' },
  { label: t('common.countries.Seychelles'), value: 'SC' },
  { label: t('common.countries.Sierra Leone'), value: 'SL' },
  { label: t('common.countries.Singapore'), value: 'SG' },
  { label: t('common.countries.Sint Maarten'), value: 'SX' },
  { label: t('common.countries.Slovakia'), value: 'SK' },
  { label: t('common.countries.Slovenia'), value: 'SI' },
  { label: t('common.countries.Solomon Islands'), value: 'SB' },
  { label: t('common.countries.Somalia'), value: 'SO' },
  { label: t('common.countries.South Africa'), value: 'ZA' },
  {
    label: t('common.countries.South Georgia and the South Sandwich Islands'),
    value: 'GS',
  },
  { label: t('common.countries.South Korea'), value: 'KR' },
  { label: t('common.countries.South Sudan'), value: 'SS' },
  { label: t('common.countries.Spain'), value: 'ES' },
  { label: t('common.countries.Sri Lanka'), value: 'LK' },
  { label: t('common.countries.Sudan'), value: 'SD' },
  { label: t('common.countries.Suriname'), value: 'SR' },
  { label: t('common.countries.Svalbard and Jan Mayen'), value: 'SJ' },
  { label: t('common.countries.Swaziland'), value: 'SZ' },
  { label: t('common.countries.Sweden'), value: 'SE' },
  { label: t('common.countries.Switzerland'), value: 'CH' },
  { label: t('common.countries.Syria'), value: 'SY' },
  { label: t('common.countries.Taiwan'), value: 'TW' },
  { label: t('common.countries.Tajikistan'), value: 'TJ' },
  { label: t('common.countries.Tanzania'), value: 'TZ' },
  { label: t('common.countries.Thailand'), value: 'TH' },
  { label: t('common.countries.Togo'), value: 'TG' },
  { label: t('common.countries.Tokelau'), value: 'TK' },
  { label: t('common.countries.Tonga'), value: 'TO' },
  { label: t('common.countries.Trinidad and Tobago'), value: 'TT' },
  { label: t('common.countries.Tunisia'), value: 'TN' },
  { label: t('common.countries.Turkey'), value: 'TR' },
  { label: t('common.countries.Turkmenistan'), value: 'TM' },
  { label: t('common.countries.Turks and Caicos Islands'), value: 'TC' },
  { label: t('common.countries.Tuvalu'), value: 'TV' },
  { label: t('common.countries.U.S. Virgin Islands'), value: 'VI' },
  { label: t('common.countries.Uganda'), value: 'UG' },
  { label: t('common.countries.Ukraine'), value: 'UA' },
  { label: t('common.countries.United Arab Emirates'), value: 'AE' },
  { label: t('common.countries.United Kingdom'), value: 'GB' },
  { label: t('common.countries.United States'), value: 'US' },
  {
    label: t('common.countries.United States Minor Outlying Islands'),
    value: 'UM',
  },
  { label: t('common.countries.Uruguay'), value: 'UY' },
  { label: t('common.countries.Uzbekistan'), value: 'UZ' },
  { label: t('common.countries.Vanuatu'), value: 'VU' },
  { label: t('common.countries.Vatican'), value: 'VA' },
  { label: t('common.countries.Venezuela'), value: 'VE' },
  { label: t('common.countries.Vietnam'), value: 'VN' },
  { label: t('common.countries.Wallis and Futuna'), value: 'WF' },
  { label: t('common.countries.Western Sahara'), value: 'EH' },
  { label: t('common.countries.Yemen'), value: 'YE' },
  { label: t('common.countries.Zambia'), value: 'ZM' },
  { label: t('common.countries.Zimbabwe'), value: 'ZW' },
];

export default COUNTRY_OPTIONS;

export const ALPHABET = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export const TIMEZONES = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Asmera',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Timbuktu',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavia',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Atka',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Buenos_Aires',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Catamarca',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Coral_Harbour',
  'America/Cordoba',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Ensenada',
  'America/Fort_Nelson',
  'America/Fort_Wayne',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Jujuy',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Knox_IN',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Louisville',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Mendoza',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montreal',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Nuuk',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Porto_Acre',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Punta_Arenas',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Rosario',
  'America/Santa_Isabel',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Shiprock',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Virgin',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/McMurdo',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/South_Pole',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Ashkhabad',
  'Asia/Atyrau',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Barnaul',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Calcutta',
  'Asia/Chita',
  'Asia/Choibalsan',
  'Asia/Chongqing',
  'Asia/Chungking',
  'Asia/Colombo',
  'Asia/Dacca',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Harbin',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Istanbul',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kashgar',
  'Asia/Kathmandu',
  'Asia/Katmandu',
  'Asia/Khandyga',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macao',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qostanay',
  'Asia/Qyzylorda',
  'Asia/Rangoon',
  'Asia/Riyadh',
  'Asia/Saigon',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Tel_Aviv',
  'Asia/Thimbu',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Tomsk',
  'Asia/Ujung_Pandang',
  'Asia/Ulaanbaatar',
  'Asia/Ulan_Bator',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yangon',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faeroe',
  'Atlantic/Faroe',
  'Atlantic/Jan_Mayen',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/ACT',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Canberra',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/LHI',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/NSW',
  'Australia/North',
  'Australia/Perth',
  'Australia/Queensland',
  'Australia/South',
  'Australia/Sydney',
  'Australia/Tasmania',
  'Australia/Victoria',
  'Australia/West',
  'Australia/Yancowinna',
  'Brazil/Acre',
  'Brazil/DeNoronha',
  'Brazil/East',
  'Brazil/West',
  'CET',
  'CST6CDT',
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Canada/Saskatchewan',
  'Canada/Yukon',
  'Chile/Continental',
  'Chile/EasterIsland',
  'Cuba',
  'EET',
  'EST',
  'EST5EDT',
  'Egypt',
  'Eire',
  'Etc/GMT',
  'Etc/GMT+0',
  'Etc/GMT+1',
  'Etc/GMT+10',
  'Etc/GMT+11',
  'Etc/GMT+12',
  'Etc/GMT+2',
  'Etc/GMT+3',
  'Etc/GMT+4',
  'Etc/GMT+5',
  'Etc/GMT+6',
  'Etc/GMT+7',
  'Etc/GMT+8',
  'Etc/GMT+9',
  'Etc/GMT-0',
  'Etc/GMT-1',
  'Etc/GMT-10',
  'Etc/GMT-11',
  'Etc/GMT-12',
  'Etc/GMT-13',
  'Etc/GMT-14',
  'Etc/GMT-2',
  'Etc/GMT-3',
  'Etc/GMT-4',
  'Etc/GMT-5',
  'Etc/GMT-6',
  'Etc/GMT-7',
  'Etc/GMT-8',
  'Etc/GMT-9',
  'Etc/GMT0',
  'Etc/Greenwich',
  'Etc/UCT',
  'Etc/UTC',
  'Etc/Universal',
  'Etc/Zulu',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belfast',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Nicosia',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Tiraspol',
  'Europe/Ulyanovsk',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'GB',
  'GB-Eire',
  'GMT',
  'GMT+0',
  'GMT-0',
  'GMT0',
  'Greenwich',
  'HST',
  'Hongkong',
  'Iceland',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Iran',
  'Israel',
  'Jamaica',
  'Japan',
  'Kwajalein',
  'Libya',
  'MET',
  'MST',
  'MST7MDT',
  'Mexico/BajaNorte',
  'Mexico/BajaSur',
  'Mexico/General',
  'NZ',
  'NZ-CHAT',
  'Navajo',
  'PRC',
  'PST8PDT',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Bougainville',
  'Pacific/Chatham',
  'Pacific/Chuuk',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Johnston',
  'Pacific/Kanton',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Pohnpei',
  'Pacific/Ponape',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Samoa',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Truk',
  'Pacific/Wake',
  'Pacific/Wallis',
  'Pacific/Yap',
  'Poland',
  'Portugal',
  'ROC',
  'ROK',
  'Singapore',
  'Turkey',
  'UCT',
  'US/Alaska',
  'US/Aleutian',
  'US/Arizona',
  'US/Central',
  'US/East-Indiana',
  'US/Eastern',
  'US/Hawaii',
  'US/Indiana-Starke',
  'US/Michigan',
  'US/Mountain',
  'US/Pacific',
  'US/Samoa',
  'UTC',
  'Universal',
  'W-SU',
  'WET',
  'Zulu',
];

export const MONTHS = [
  t('common.months.january'),
  t('common.months.february'),
  t('common.months.march'),
  t('common.months.april'),
  t('common.months.may'),
  t('common.months.june'),
  t('common.months.july'),
  t('common.months.august'),
  t('common.months.september'),
  t('common.months.october'),
  t('common.months.november'),
  t('common.months.december'),
];
