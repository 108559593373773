/* eslint-disable no-shadow */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Template from '../Template';

export default function New({
  deleteNotification,
  isRead,
  notification,
  classes,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const createNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const goToJob = () => {
      navigate(
        `/tools/jobs/my-jobs/${notification.jobApplication?.jobPost?.id}`
      );
      hideNotifications();
    };

    const content = {
      'notitification.applications.pendingreview': {
        title: t(
          'dashboardComponents.Notifications.Notification.New.notificationApplicationsPendingReview'
        ),
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.New.review'
        ),
        onClick: goToJob,
      },
    };

    return (
      content[notification.eventKey] || {
        title: notification.eventNote,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.New.review'
        ),
        onClick: goToJob,
      }
    );
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
