import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

const stars = Array.from(Array(5).keys());

export default function Rating({ rating }) {
  return (
    <div className={classes.Rating}>
      {stars.map((star, index) => (
        <div
          className={classNames(classes.star, {
            [classes.red]: rating < 3 && index < rating,
            [classes.orange]: rating > 2 && index < rating,
            [classes.green]: rating > 3 && index < rating,
          })}
          key={star}
        />
      ))}
    </div>
  );
}
