import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function PrevColors({
  prevPickedColors,
  changeColor,
  currentEditingColor,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.PrevColors}>
      <h3>
        {t(
          'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Color.ColorPalette.PrevColors.previousColorSelections'
        )}
      </h3>
      <div className={classes.content}>
        {prevPickedColors.length ? (
          prevPickedColors.map((color) => {
            return (
              <div
                key={color}
                tabIndex={0}
                role="button"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    changeColor(color);
                  }
                }}
                className={classNames(classes.color, {
                  [classes.active]: color === currentEditingColor,
                })}
                style={{ backgroundColor: color }}
                onClick={() => changeColor(color)}
              />
            );
          })
        ) : (
          <div className={classes.noContent}>
            {t(
              'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Color.ColorPalette.PrevColors.noPreviousColorSelectionsFound'
            )}
          </div>
        )}
      </div>
    </div>
  );
}
