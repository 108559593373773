import React, { useEffect } from 'react';

import classNames from 'classnames';
import Inputmask from 'inputmask';

import classes from './styles.module.scss';

export default function CurrencyInput({
  width,
  height,
  readonly,
  name,
  value,
  onChange,
  onBlur,
  onFocus,
}) {
  useEffect(() => {
    Inputmask('currency').mask(`.CurrencyInput`);
  }, []);

  return (
    <input
      style={{ width, height }}
      type="text"
      className={classNames('CurrencyInput', classes.CurrencyInput)}
      readOnly={readonly}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={readonly ? () => {} : onBlur}
      onFocus={onFocus}
    />
  );
}
