import React, { useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import TextInput from '../../../../../Form/TextInput';
import ChatService from '../../../../../../services/ChatService';
import { UiContext } from '../../../../../../context/UiContext';
import classes from './styles.module.scss';

export default function MessageInput({ activeContact, refetchMessages }) {
  const [message, setMessage] = useState('');

  const { setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const sendMessage = async () => {
    try {
      setIsFetching(true);
      setMessage('');
      await ChatService.sendMessage({
        jobApplicationId: activeContact.jobApplicationId || activeContact.id,
        message,
      });
      await refetchMessages();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.MessageInput}>
      <TextInput
        height={59}
        placeholder={t(
          'dashboardComponents.Chat.RightPanel.ChatWindow.MessageInput.placeholder'
        )}
        width="100%"
        value={message}
        onChange={(event) => setMessage(event.target.value)}
      />
      <button
        type="button"
        className={classes.sendButton}
        disabled={!message}
        onClick={sendMessage}
      >
        Send Message
      </button>
    </div>
  );
}
