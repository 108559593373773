import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import CandidateSourceTag from './CandidateSourceTag';
import Button from '../../../Button';
import FavStatus from '../../Header/Search/FavStatus';
import CandidateFavorites from '../../Jobs/MyJobs/JobDetails/CandidateFavorites';
import DotMenu from './DotMenu';
import CandidateMenu from '../../Jobs/MyJobs/JobDetails/GridView/CandidatesCol/CandidateCard/CandidateMenu';

import classes from './styles.module.scss';
import closeIcon from '../../../../assets/images/modals/close.svg';
import {
  IMAGES_URL,
  JOB_APPLICATION_STATUSES,
} from '../../../../constants/main';
import AdminService from '../../../../services/AdminService';
import UserService from '../../../../services/UserService';
import { getTimezoneDate } from '../../../../helpers/timezones';
import { CandidatesContext } from '../../../../context/CandidatesContext';
import { JobsContext } from '../../../../context/JobsContext';
import { UiContext } from '../../../../context/UiContext';

function getDate(date) {
  const temp = new Date(date);

  return `${temp.getDate()} ${temp
    .toLocaleString('en-US', { month: 'long' })
    .slice(0, 3)} ${temp.getFullYear()}`;
}

const red = [
  ['New', 'Rejected'],
  ['New', 'Declined'],
  ['Screen', 'Rejected'],
  ['Screen', 'Incomplete'],
  ['Interview', 'Rejected'],
  ['Offer', 'Rejected'],
  ['Offer', 'Declined'],
  ['Interview', 'Declined'],
  ['Onboard', 'NoShow'],
  ['Onboard', 'Declined'],
  ['Onboard', 'Rejected'],
  ['Onboard', 'StartDeclined'],
  ['Screen', 'Failed'],
];

const yellow = [
  ['Interview', 'Canceled'],
  ['Interview', 'ChangeRequested'],
  ['Offer', 'Countered'],
  ['Onboard', 'ChangeRequested'],
  ['Onboard', 'Canceled'],
  ['Onboard', 'StartCanceled'],
];

const black = [
  ['Screen', 'Requested'],
  ['Interview', 'Scheduled'],
  ['Interview', 'Accepted'],
  ['Offer', 'Offered'],
  ['Onboard', 'Scheduled'],
];

const green = [
  ['Screen', 'Passed'],
  ['Interview', 'Passed'],
  ['Offer', 'Accepted'],
  ['Onboard', 'Onboarded'],
  ['Onboard', 'StartDateSet'],
  ['Onboard', 'Joined'],
  ['Onboard', 'Accepted'],
  ['Onboard', 'StartConfirmed'],
];

export default function CandidateHeader({
  candidate,
  refetch,
  isApplication,
  job,
  isModal,
  isVisible,
  handleClose,
}) {
  const [currentStep, setCurrentStep] = useState('');
  const [currentSubStatus, setCurrentSubStatus] = useState('');
  const [isTracking, setIsTracking] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  const { t } = useTranslation();

  const { data: me } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  // eslint-disable-next-line no-shadow
  function getCandidateCardStatus(candidate) {
    let returningStatus;
    const initialStatus = candidate?.subStatus;
    if (initialStatus === 'Pending') {
      returningStatus = `${t(
        `common.jobApplicationSubStatuses.${initialStatus}`
      )} ${JOB_APPLICATION_STATUSES[candidate?.status]}`;
    }

    if (
      candidate?.status === 'Invited' &&
      candidate?.subStatus !== 'Rejected'
    ) {
      returningStatus = (
        <span className={classes.invitedStatus}>
          {t('dashboardComponents.Candidate.CandidateHeader.statuses.Invited')}
        </span>
      );
    }

    if (initialStatus === 'Accepted' && candidate?.status === 'Onboard') {
      returningStatus = t(
        'dashboardComponents.Candidate.CandidateHeader.statuses.Confirmed'
      );
    }

    if (
      initialStatus === 'ChangeRequested' &&
      candidate?.status === 'Onboard'
    ) {
      returningStatus = candidate?.jobApplicationJoinings.length
        ? t(
            'dashboardComponents.Candidate.CandidateHeader.statuses.Change Start'
          )
        : t(
            'dashboardComponents.Candidate.CandidateHeader.statuses.Change Onboard'
          );
    }

    if (initialStatus === 'Failed' && candidate?.status === 'Screen') {
      returningStatus = t(
        'dashboardComponents.Candidate.CandidateHeader.statuses.Auto Reject'
      );
    }

    if (initialStatus === 'Canceled' && candidate?.status === 'Onboard') {
      returningStatus = t(
        'dashboardComponents.Candidate.CandidateHeader.statuses.Start Canceled'
      );
    }

    return (
      returningStatus || t(`common.jobApplicationSubStatuses.${initialStatus}`)
    );
  }

  let steps = ['New', 'Interview', 'Offer', 'Onboard'];

  if (
    job?.assignedAssessments?.length ||
    candidate?.assignedAssessments?.length
  ) {
    steps = ['New', 'Screen', 'Interview', 'Offer', 'Onboard'];
  }

  const hasPhoto =
    !!candidate?.userProfile?.avatar || !!candidate?.user?.userProfile?.avatar;
  let candidatePhoto;

  if (hasPhoto) {
    candidatePhoto = `${IMAGES_URL}/avatar/${
      candidate?.userProfile?.avatar || candidate?.user?.userProfile?.avatar
    }`;
  }

  const { showNotification, setIsFetching } = useContext(UiContext);
  const { showInviteToJobModal } = useContext(CandidatesContext);

  const {
    showSetOnboardingModal,
    showScheduleInterviewModal,
    showSetJoiningModal,
    showOfferModal,
  } = useContext(JobsContext);

  useEffect(() => {
    setCurrentStep(candidate?.status);
    setCurrentSubStatus(candidate?.subStatus);
  }, [candidate]);

  // Calculate distance between two steps
  useEffect(() => {
    const stepElements = document.querySelectorAll(`.${classes.step}`);

    if (!stepElements.length) {
      return;
    }

    const { x: firstStepX } = stepElements[0]?.getBoundingClientRect();
    const { x: secondStepX } = stepElements[1]?.getBoundingClientRect();

    const distance = secondStepX - firstStepX;

    const container = document.querySelector(`.${classes.candidate}`);

    if (container && distance) {
      container.style.setProperty('--stepsDistance', `${distance}px`);
    }
  }, [candidate, job, isVisible]);

  let categories;

  if (
    currentStep === 'Interview' &&
    candidate?.jobApplicationInterviews.length
  ) {
    categories = {
      title: t('dashboardComponents.Candidate.CandidateHeader.Interview Date'),
      date: getDate(
        getTimezoneDate(
          candidate.jobApplicationInterviews[
            candidate.jobApplicationInterviews.length - 1
          ].interviewDate,
          me?.userProfile?.timezone
        )[0]
      ),
    };
  }

  if (
    currentStep === 'Onboard' &&
    candidate?.jobApplicationOnboardings.length
  ) {
    categories = {
      title: t('dashboardComponents.Candidate.CandidateHeader.Onboarding Date'),
      date: getDate(
        getTimezoneDate(
          candidate?.jobApplicationOnboardings[
            candidate?.jobApplicationOnboardings.length - 1
          ]?.onboardingDate,
          me?.userProfile?.timezone
        )[0]
      ),
    };
  }

  if (
    (currentStep === 'Onboard' && currentSubStatus === 'StartDateSet') ||
    (currentStep === 'Onboard' &&
      currentSubStatus === 'ChangeRequested' &&
      candidate?.jobApplicationJoinings.length)
  ) {
    categories = {
      title: t('dashboardComponents.Candidate.CandidateHeader.Start Date'),
      date: getDate(
        getTimezoneDate(
          candidate?.jobApplicationJoinings[
            candidate?.jobApplicationJoinings.length - 1
          ]?.joiningDate,
          me?.userProfile?.timezone
        )[0]
      ),
    };
  }

  if (currentStep === 'Offer' && candidate?.jobApplicationOffers.length) {
    categories = {
      title: t('dashboardComponents.Candidate.CandidateHeader.Offer Date'),
      date: getDate(
        getTimezoneDate(
          candidate.jobApplicationOffers[
            candidate.jobApplicationOffers.length - 1
          ].createdAt,
          me?.userProfile?.timezone
        )[0]
      ),
    };
  }

  if (
    currentSubStatus === 'Joined' ||
    currentSubStatus === 'Rejected' ||
    currentSubStatus === 'Canceled' ||
    currentSubStatus === 'Pending' ||
    currentSubStatus === 'Passed' ||
    currentSubStatus === 'Onboarded' ||
    currentSubStatus === 'StartDeclined' ||
    currentSubStatus === 'Withdrawn' ||
    currentSubStatus === 'NoShow' ||
    currentSubStatus === 'Declined'
  ) {
    categories = null;
  }

  useEffect(() => {
    if (currentSubStatus === 'Joined') {
      setIsFinished(true);
      return;
    }

    setIsFinished(false);
  }, [currentStep, currentSubStatus]);

  const showEventDetail = () => {
    setIsTracking(!isTracking);
    if (
      candidate?.status === 'Onboard' &&
      candidate?.subStatus !== 'Onboarded' &&
      !candidate.jobApplicationJoinings.length
    ) {
      showSetOnboardingModal({ candidate, refetch });
    }
    if (candidate?.status === 'Interview') {
      showScheduleInterviewModal({ candidate, refetch });
    }
    if (candidate?.status === 'Offer') {
      showOfferModal({ candidate, refetch });
    }
    if (
      (candidate?.status === 'Onboard' &&
        candidate?.subStatus === 'Onboarded') ||
      candidate.jobApplicationJoinings.length
    ) {
      showSetJoiningModal({ candidate, refetch });
    }
  };

  let calendarIsAvailable = false;

  if (steps.indexOf(currentStep) >= 1) {
    calendarIsAvailable =
      (candidate?.status === 'Interview' &&
        candidate?.subStatus === 'Pending') ||
      (candidate?.status === 'Offer' && candidate?.subStatus === 'Pending') ||
      (candidate?.status === 'Onboard' && candidate?.subStatus === 'Pending') ||
      (candidate?.status === 'Onboard' && candidate?.subStatus === 'Onboarded');
  }

  const calendarEvent = () => {
    if (
      candidate?.status === 'Interview' &&
      candidate?.subStatus === 'Pending'
    ) {
      showScheduleInterviewModal({ candidate, refetch });
    }
    if (candidate?.status === 'Offer' && candidate?.subStatus === 'Pending') {
      showOfferModal({ candidate, refetch });
    }
    if (candidate?.status === 'Onboard' && candidate?.subStatus === 'Pending') {
      showSetOnboardingModal({ candidate, refetch });
    }
    if (
      candidate?.status === 'Onboard' &&
      candidate?.subStatus === 'Onboarded'
    ) {
      showSetJoiningModal({ candidate, refetch });
    }
  };

  const editEvent = () => {
    if (
      candidate?.status === 'Onboard' &&
      candidate?.subStatus !== 'Onboarded' &&
      !candidate.jobApplicationJoinings.length
    ) {
      showSetOnboardingModal({ candidate, refetch });
    }
    if (candidate?.status === 'Interview') {
      showScheduleInterviewModal({ candidate, refetch });
    }
    if (candidate?.status === 'Offer') {
      showOfferModal({ candidate, refetch });
    }
    if (
      (candidate?.status === 'Onboard' &&
        candidate?.subStatus === 'Onboarded') ||
      candidate.jobApplicationJoinings.length
    ) {
      showSetJoiningModal({ candidate, refetch });
    }
  };

  const changeJobApplicationStatus = async (jobApplicationId, status, undo) => {
    try {
      setIsFetching(true);

      await AdminService.changeMyJobApplicationStatus({
        jobApplicationId,
        status: status.status,
        subStatus: status.subStatus,
      });
      await refetch();
      const stageTitle = {
        Screen: t(
          'dashboardComponents.Candidate.CandidateHeader.stageTitles.Screening'
        ),
        Interview: t(
          'dashboardComponents.Candidate.CandidateHeader.stageTitles.Interview'
        ),
        Offer: t(
          'dashboardComponents.Candidate.CandidateHeader.stageTitles.Offer'
        ),
        Onboard: t(
          'dashboardComponents.Candidate.CandidateHeader.stageTitles.Onboarding'
        ),
      };
      showNotification({
        text: `${t(
          'dashboardComponents.Candidate.CandidateHeader.Candidate successfully moved to'
        )} ${stageTitle[status.status]}`,
        undo,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const firstName =
    candidate?.user?.userProfile?.firstName ||
    candidate?.userProfile?.firstName ||
    '';
  const lastName =
    candidate?.user?.userProfile?.lastName ||
    candidate?.userProfile?.lastName ||
    '';

  return (
    <div
      className={classNames(classes.candidate, {
        [classes.finished]: isFinished,
      })}
      style={{ padding: isModal && '17px 32px 15px' }}
    >
      <button type="button" className={classes.close} onClick={handleClose}>
        <img src={closeIcon} alt="close" />
      </button>
      <div className={classes.photoContainer}>
        {hasPhoto ? (
          <img
            src={candidatePhoto}
            alt="canditatePhoto"
            className={classes.photo}
          />
        ) : (
          <div className={classes.noPhoto}>{`${firstName?.[0] || ''}${
            lastName?.[0] || ''
          }`}</div>
        )}
      </div>
      <div className={classes.spaceBetween}>
        <div className={classes.state}>
          <div className={classes.nameAndLocationContainer}>
            <div className={classes.nameContainer}>
              <h1 className={classes.name}>{`${firstName} ${lastName}`}</h1>
              <CandidateSourceTag sourceType={candidate?.sourceType} />
            </div>
            <h2 className={classes.location}>
              {candidate?.applicantLocation ||
                candidate?.userProfile?.city ||
                candidate?.userProfile?.customLocation}
            </h2>
          </div>

          {isApplication && (
            <div className={classes.steps}>
              {steps.map((step, i) => (
                <div className={classes.step} key={step}>
                  <div
                    className={classNames(classes.currentStep, {
                      [classes.completed]:
                        i < steps.indexOf(currentStep) ||
                        currentSubStatus === 'Joined',
                      [classes.active]: currentStep === step,
                      [classes.red]:
                        red.find(
                          (stat) =>
                            candidate?.status === stat[0] &&
                            candidate?.subStatus === stat[1]
                        ) && currentStep === step,
                      [classes.yellow]:
                        yellow.find(
                          (stat) =>
                            candidate?.status === stat[0] &&
                            candidate?.subStatus === stat[1]
                        ) && currentStep === step,
                      [classes.black]:
                        black.find(
                          (stat) =>
                            candidate?.status === stat[0] &&
                            candidate?.subStatus === stat[1]
                        ) && currentStep === step,
                      [classes.green]:
                        green.find(
                          (stat) =>
                            candidate?.status === stat[0] &&
                            candidate?.subStatus === stat[1]
                        ) && currentStep === step,
                    })}
                  >
                    {i >= steps.indexOf(currentStep) &&
                      currentSubStatus !== 'Joined' &&
                      i + 1}
                  </div>
                  <p className={classes.stepName}>{step}</p>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className={classes.settings}>
          <div className={classes.menu}>
            <div>
              {isApplication ? (
                <CandidateFavorites
                  favoritesList={candidate?.jobApplicationFavorites}
                  refetch={refetch}
                  jobApplicationId={candidate?.id}
                  width={40}
                  height={40}
                  white={candidate?.subStatus === 'Joined'}
                />
              ) : (
                <FavStatus
                  candidate={candidate}
                  refetch={refetch}
                  big
                  white={candidate?.subStatus === 'Joined'}
                />
              )}
            </div>
            <div
              className={classNames(classes.calendar, {
                [classes.offCalendar]: !calendarIsAvailable,
              })}
              onClick={calendarEvent}
            />
            {isApplication ? (
              <div
                className={classNames(classes.status, {
                  [classes.yellow]: yellow.find(
                    (stat) =>
                      candidate?.status === stat[0] &&
                      candidate?.subStatus === stat[1]
                  ),
                  [classes.black]: black.find(
                    (stat) =>
                      candidate?.status === stat[0] &&
                      candidate?.subStatus === stat[1]
                  ),
                  [classes.green]: green.find(
                    (stat) =>
                      candidate?.status === stat[0] &&
                      candidate?.subStatus === stat[1]
                  ),
                  [classes.red]: red.find(
                    (stat) =>
                      candidate?.status === stat[0] &&
                      candidate?.subStatus === stat[1]
                  ),
                  [classes.disabled]:
                    candidate?.subStatus === 'Rejected' ||
                    candidate?.subStatus === 'Joined' ||
                    candidate?.subStatus === 'Declined' ||
                    candidate?.subStatus === 'StartDeclined' ||
                    candidate?.subStatus === 'Withdrawn' ||
                    candidate?.subStatus === 'NoShow',
                })}
              >
                <CandidateMenu
                  candidate={candidate}
                  changeJobApplicationStatus={changeJobApplicationStatus}
                  refetch={refetch}
                  jobStatus={job?.status}
                  hasAssessment={!!job?.assignedAssessments?.length}
                  isCandidatePopup
                />
                <span
                  className={classNames(classes.statusTitle, {
                    [classes.invited]: candidate?.status === 'Invited',
                  })}
                >
                  {getCandidateCardStatus(candidate)}
                </span>
              </div>
            ) : (
              <Button
                variant="secondary"
                width={160}
                height={47}
                style={{ fontSize: 16 }}
                onClick={() => showInviteToJobModal(candidate, refetch)}
                disabled={candidate?.userProfile?.isPrivate}
              >
                Invite
              </Button>
            )}
            <DotMenu
              options={[
                'Chat(coming soon)',
                'Share',
                'Email Candidate',
                'Block & Report',
              ]}
              candidate={candidate}
              refetch={refetch}
            />
          </div>
          {isApplication && categories && (
            <div className={classes.cal}>
              <p className={classes.category}>{categories.title}</p>
              <span className={classes.date}>{categories.date}</span>
              <div className={classes.icons}>
                <div
                  className={classNames(classes.closeEye, {
                    [classes.eye]: isTracking,
                  })}
                  onClick={showEventDetail}
                />
                <div className={classes.edit} onClick={editEvent} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
