/* eslint-disable no-plusplus */
/* eslint-disable no-continue */

import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';

import Feedback from './Feedback';

import { UiContext } from '../../../../../context/UiContext';
import AdminService from '../../../../../services/AdminService';
import UserService from '../../../../../services/UserService';
import classes from './styles.module.scss';
import { JobsContext } from '../../../../../context/JobsContext';
import { getTimezoneTime } from '../../../../../helpers/timezones';

function getDate(date) {
  const temp = new Date(date);

  return `${temp.getDate()} ${temp
    .toLocaleString('en-US', { month: 'long' })
    .slice(0, 3)} ${temp.getFullYear()} (${temp.toLocaleString('en-US', {
    month: 'long',
  })})`;
}

function formatString(str = '') {
  let result = '';

  for (let i = 0; i < str.length; i++) {
    const temp = str[i];

    if (i === 0) {
      result += str[i];

      continue;
    }

    if (str[i].toUpperCase() === temp) {
      result += ' ';
    }

    result += str[i];
  }

  return result;
}
export default function Interview({ candidate, job, refetch }) {
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);
  const [selectedInterview, setSelectedInterview] = useState(null);

  const { showModal, showUnknownErrorModal, showNotification, setIsFetching } =
    useContext(UiContext);

  const { showScheduleInterviewModal } = useContext(JobsContext);

  const { t } = useTranslation();

  const handleClickOnFeedback = (interview) => {
    setSelectedInterview(interview);
    setIsFeedbackVisible(true);
  };

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const deleteInterview = async (interviewid) => {
    showModal({
      icon: 'delete',
      title: t(
        'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Delete Interview'
      ),
      text: t(
        'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Are you sure you want to delete?'
      ),
      onConfirm: async () => {
        try {
          setIsFetching(true);
          await AdminService.deleteJobApplicationInterview({
            jobApplicationId: candidate?.id,
            interviewid,
          });
          if (refetch) {
            await refetch();
          }
          showNotification({
            text: t(
              'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Interview deleted successfully'
            ),
          });
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
        } finally {
          setIsFetching(false);
        }
      },
      onCancel: () => {},
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
    });
  };

  return (
    <div className={classes.interviews}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          {t(
            'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Interview'
          )}
        </h2>
        {candidate?.jobApplicationInterviews?.length ? (
          <div className={classes.amount}>
            {candidate.jobApplicationInterviews.length}
          </div>
        ) : null}
      </div>
      {!candidate?.jobApplicationInterviews?.length ? (
        <p className={classes.noData}>
          {t(
            'dashboardComponents.Candidate.AssessmentAndInterview.Interview.No interview scheduled'
          )}
        </p>
      ) : (
        <>
          <div className={classes.interviewList}>
            {candidate?.jobApplicationInterviews.map((interview) => {
              const {
                status,
                interviewDate,
                jobApplicationInterviewFeedbacks,
              } = interview;

              const isButtonDisabled =
                job?.status === 'UnPublished' ||
                new Date(interviewDate) > new Date();

              const button = (
                <button
                  type="button"
                  className={classNames(classes.feedbackButton, {
                    [classes.editFeedback]:
                      jobApplicationInterviewFeedbacks?.length,
                  })}
                  onClick={() => handleClickOnFeedback(interview)}
                  disabled={isButtonDisabled}
                >
                  {jobApplicationInterviewFeedbacks?.length
                    ? t(
                        'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Edit Feedback'
                      )
                    : t(
                        'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Submit Feedback'
                      )}
                </button>
              );

              const inteviewerName = interview?.users[0]
                ? `${interview?.users[0]?.userProfile?.firstName} ${interview?.users[0]?.userProfile?.lastName}`
                : '';
              return (
                <div className={classes.interview} key={interview?.id}>
                  <div className={classes.upper}>
                    <p className={classes.date}>
                      {getDate(interview.interviewDate)}
                    </p>
                    <div className={classes.settings}>
                      {!!jobApplicationInterviewFeedbacks.length && (
                        <div
                          className={classNames(classes.rating, {
                            [classes.noPass]:
                              !jobApplicationInterviewFeedbacks[0]
                                .isRecommended,
                          })}
                        >
                          {jobApplicationInterviewFeedbacks[0].feedbackRating}
                        </div>
                      )}
                      <div
                        className={classNames(classes.status, {
                          [classes.scheduled]: status === 'Scheduled',
                          [classes.declined]: status === 'Declined',
                          [classes.accepted]: status === 'Accepted',
                          [classes.pass]: status === 'Pass',
                          [classes.fail]: status === 'Fail',
                        })}
                      >
                        {t(`common.interviewStatuses.${interview.status}`)}
                      </div>
                      {user?.id === interview?.organizer.id && (
                        <>
                          <div
                            className={classes.edit}
                            onClick={() =>
                              showScheduleInterviewModal({ candidate, refetch })
                            }
                          />
                          <div
                            className={classes.delete}
                            onClick={() => deleteInterview(interview?.id)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className={classNames(classes.lower, {
                      [classes.scheduled]: status === 'Scheduled',
                      [classes.declined]: status === 'Declined',
                      [classes.accepted]: status === 'Accepted',
                      [classes.pass]: status === 'Pass',
                      [classes.fail]: status === 'Fail',
                    })}
                  >
                    <div className={classNames(classes.col, classes.leftPanel)}>
                      <h3 className={classes.name}>{job.jobTitle}</h3>
                      <p className={classes.description}>
                        {t('common.jobId')}: {job.id}
                      </p>
                    </div>
                    <div className={classes.col}>
                      <h3 className={classes.name}>{`${getTimezoneTime(
                        interview?.startTime,
                        user?.userProfile?.timezone
                      )} - ${getTimezoneTime(
                        interview?.endTime,
                        user?.userProfile?.timezone
                      )}`}</h3>
                      <p className={classes.description}>
                        {`${
                          +interview?.endTime?.split(':')[0] -
                          +interview?.startTime?.split(':')[0]
                        } ${t(
                          'dashboardComponents.Candidate.AssessmentAndInterview.Interview.hr'
                        )} ${Math.abs(
                          +interview?.endTime?.split(':')[1] -
                            +interview?.startTime?.split(':')[1]
                        )} ${t(
                          'dashboardComponents.Candidate.AssessmentAndInterview.Interview.mins'
                        )}`}
                      </p>
                    </div>
                    <div className={classes.col}>
                      <h3 className={classes.name}>
                        {formatString(interview.type)}
                      </h3>
                    </div>
                    <div className={classes.col}>
                      <h3 className={classes.name}>{inteviewerName}</h3>
                      <p className={classes.description}>
                        {t(
                          'dashboardComponents.Candidate.AssessmentAndInterview.Interview.Interviewer'
                        )}
                      </p>
                    </div>
                    {button}
                  </div>
                </div>
              );
            })}
            {isFeedbackVisible && (
              <Feedback
                setIsFeedbackVisible={setIsFeedbackVisible}
                candidate={candidate}
                interview={selectedInterview}
                isEditable={
                  selectedInterview?.jobApplicationInterviewFeedbacks.length
                }
                job={job}
                refetch={refetch}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
