/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';

import Media from '../Media';
import Dots from './Dots';

import classes from './styles.module.scss';
import prevMobile from '../../../../../../../assets/images/dashboard/discover/prevMobile.svg';
import nextMobile from '../../../../../../../assets/images/dashboard/discover/nextMobile.svg';

export default function MobileGallery({ media }) {
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  return (
    <div className={classes.MobileGallery}>
      <div className={classes.container}>
        <button
          type="button"
          className={classes.button}
          onClick={() => {
            setCurrentMediaIndex((prevIndex) => {
              if (prevIndex === 0) {
                return media.length - 1;
              }
              return prevIndex - 1;
            });
          }}
        >
          <img src={prevMobile} alt="Prev" />
        </button>
        <div
          className={classes.images}
          style={{
            transform: `translateX(-${currentMediaIndex * 100}%)`,
          }}
        >
          {media.map((mda, index) => (
            <Media key={mda.url} media={mda} index={index} allMedia={media} />
          ))}
        </div>
        <button
          type="button"
          className={classes.button}
          onClick={() => {
            setCurrentMediaIndex((prevIndex) => {
              if (prevIndex === media.length - 1) {
                return 0;
              }
              return prevIndex + 1;
            });
          }}
        >
          <img src={nextMobile} alt="Next" />
        </button>
      </div>
      <Dots
        media={media}
        currentMediaIndex={currentMediaIndex}
        setCurrentMediaIndex={setCurrentMediaIndex}
      />
    </div>
  );
}
