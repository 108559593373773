/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { PathLine } from 'react-svg-pathline';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import GraphDetail from './GraphDetail';

import classes from './styles.module.scss';

export default function GraphPoints({ data }) {
  const [firstPoints, setFirstPoints] = useState([]);
  const [secondPoints, setSecondPoints] = useState([]);
  const [, setIsLoaded] = useState(false);
  const [isDetailInfoVisible, setIsDetailInfoVisible] = useState(false);
  const [graphDetailInfo, setGraphDetailInfo] = useState(null);
  const [clickCoords, setClickCoords] = useState({ x: 0, y: 0 });
  const [applicantCircles, setApplicantCircles] = useState([]);
  const [viewCircles, setViewCircles] = useState([]);
  const [graphBottom, setGraphBottom] = useState(0);

  const graphRef = useRef();

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const totalFirstAmount = Math.max(...data[0]);
  const totalSecondAmount = Math.max(...data[1]);

  useEffect(() => {
    setFirstPoints([
      ...data[0]?.map((applicant, index) => {
        const x = index * (100 / (data[0].length - 1)) * 6.4;
        const y = (applicant / (totalFirstAmount / 100)) * 2.54;

        return [x || 0, -y || 0];
      }),
    ]);
    setSecondPoints([
      ...data[1]?.map((view, index) => {
        const x = index * (100 / (data[1].length - 1)) * 6.4;
        const y = (view / (totalSecondAmount / 100)) * 2.54 - 5;

        return [x || 0, -y || -5];
      }),
    ]);
  }, [data, totalFirstAmount, totalSecondAmount]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const handleClick = (event, points, counts, type) => {
    const rect = graphRef?.current?.getBoundingClientRect();

    setClickCoords({ x: event.clientX, y: event.clientY });

    const currentDataSource =
      type === 'applicant' ? applicantCircles : viewCircles;

    const nearestLeftCircle = [...currentDataSource]
      .reverse()
      .find((item) => item.x <= event.clientX);
    const nearestRightCircle = currentDataSource.find(
      (item) => item.x >= event.clientX
    );

    const nearestCircle =
      Math.abs(event.clientX - nearestLeftCircle.x) >
      Math.abs(event.clientX - nearestRightCircle.x)
        ? nearestRightCircle
        : nearestLeftCircle;

    /* 
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const countIndex = points?.findIndex((item) => {
      if (x <= points[1][0] / 2) {
        return true;
      }

      if (x >= item[0] - points[1][0] / 2 && x <= item[0] + points[1][0] / 2) {
        return true;
      }

      return false;
    });
 */
    setGraphDetailInfo((prev) => ({
      ...prev,
      name: event.target.getAttribute('name'),
      count: nearestCircle.count,
      color: event.target.getAttribute('stroke'),
      x: nearestCircle.x,
      y: nearestCircle.y,
    }));

    setIsDetailInfoVisible(true);
  };

  // Find and store all svg circles with their coordinates and count
  useEffect(() => {
    const getCircleCoordsAndData = (classname) => {
      const circles = document.body.querySelectorAll(`.${classname}`);
      const circlesWithData = Array.from(circles).map((item) => {
        const rct = item.getBoundingClientRect();
        return {
          x: rct.left,
          y: rct.top,
          count: item.getAttribute('data-count'),
        };
      });
      return circlesWithData;
    };

    if (firstPoints?.length) {
      setApplicantCircles(getCircleCoordsAndData('applicantCircle'));
    }

    if (secondPoints?.length) {
      setViewCircles(getCircleCoordsAndData('viewsCircle'));
    }
  }, [firstPoints, secondPoints]);

  // Find bottom coord of the graph
  useEffect(() => {
    const rect = graphRef?.current?.getBoundingClientRect();
    setGraphBottom(rect.bottom);
  }, [isTabletOrMobile, isDetailInfoVisible]);

  // Hide graph detail if user scrolls
  useEffect(() => {
    let prevY;
    let timer;
    if (isDetailInfoVisible) {
      timer = setInterval(() => {
        const coords = graphRef?.current?.getBoundingClientRect();
        if (prevY && prevY !== coords.y) {
          setIsDetailInfoVisible(false);
          clearInterval(timer);
        }
        prevY = coords.y;
      }, 250);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [isDetailInfoVisible]);

  return (
    <div className={classes.views} ref={graphRef}>
      <svg>
        {firstPoints?.map((points, index) => (
          <circle
            className="applicantCircle"
            cx={points[0]}
            cy={points[1]}
            stroke="#00E4AC"
            strokeWidth="6px"
            data-count={data[0][index]}
            name={t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.ViewsGraph.Graph.GraphPoints.applicant'
            )}
            fill="#00E4AC"
            r={2}
            onClick={(event) =>
              handleClick(event, firstPoints, data[0], 'applicant')
            }
            key={points}
          />
        ))}
        <PathLine
          points={
            firstPoints.length
              ? firstPoints?.map((points) => ({
                  x: points[0] || 0,
                  y: points[1] || 0,
                }))
              : [{ x: 0, y: 0 }]
          }
          stroke="#00E4AC"
          strokeWidth="3px"
          name={t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.ViewsGraph.Graph.GraphPoints.applicant'
          )}
          fill="none"
          strokeLinejoin="round"
          strokeLinecap="round"
          r={10}
          onClick={(event) =>
            handleClick(event, firstPoints, data[0], 'applicant')
          }
        />
        {secondPoints?.map((points, index) => (
          <circle
            className="viewsCircle"
            cx={points[0]}
            cy={points[1]}
            data-count={data[1][index]}
            name={t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.ViewsGraph.Graph.GraphPoints.views'
            )}
            stroke="#6722D2"
            strokeWidth="6px"
            fill="#6722D2"
            r={2}
            onClick={(event) =>
              handleClick(event, secondPoints, data[1], 'views')
            }
            key={points}
          />
        ))}
        <PathLine
          points={
            secondPoints.length
              ? secondPoints?.map((points) => ({
                  x: points[0] || 0,
                  y: points[1] || 0,
                }))
              : [{ x: 0, y: 0 }]
          }
          fill="none"
          stroke="#6722D2"
          strokeWidth="3px"
          name={t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.Insights.ViewsGraph.Graph.GraphPoints.views'
          )}
          strokeLinejoin="round"
          strokeLinecap="round"
          r={10}
          onClick={(event) =>
            handleClick(event, secondPoints, data[1], 'views')
          }
        />
      </svg>
      {isDetailInfoVisible &&
        createPortal(
          <GraphDetail
            name={graphDetailInfo?.name}
            color={graphDetailInfo?.color}
            count={graphDetailInfo?.count}
            top={clickCoords.y}
            left={clickCoords.x}
            handleClose={() => setIsDetailInfoVisible(false)}
            graphBottom={graphBottom}
          />,
          document.body
        )}
    </div>
  );
}
