import React from 'react';

export default function NonFavoritesIcon({ size, white }) {
  return white ? (
    <svg
      style={{
        width: size,
        height: size,
      }}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.0547" cy="10.7383" r="10" fill="#F2F4F8" />
      <path
        d="M7.89948 14.5521C7.685 14.6649 7.43432 14.4827 7.47529 14.2439L7.88689 11.844L6.1433 10.1445C5.96978 9.97532 6.06553 9.68063 6.30532 9.64578L8.71492 9.29565L9.79252 7.11219C9.89976 6.8949 10.2096 6.8949 10.3169 7.11219L11.3945 9.29565L13.804 9.64578C14.0438 9.68063 14.1396 9.97532 13.9661 10.1445L12.2225 11.844L12.6341 14.2439C12.675 14.4827 12.4244 14.6649 12.2099 14.5521L10.0547 13.419L7.89948 14.5521Z"
        stroke="#242833"
      />
    </svg>
  ) : (
    <svg
      style={{
        width: size,
        height: size,
      }}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#242833" fillOpacity="0.5" />
      <path
        d="M11.7672 20.7207C11.4455 20.8899 11.0695 20.6167 11.1309 20.2584L11.7483 16.6586L9.13291 14.1093C8.87263 13.8556 9.01626 13.4135 9.37596 13.3613L12.9903 12.8361L14.6067 9.56086C14.7676 9.23492 15.2324 9.23492 15.3933 9.56086L17.0097 12.8361L20.624 13.3613C20.9837 13.4135 21.1274 13.8556 20.8671 14.1093L18.2517 16.6586L18.8691 20.2584C18.9305 20.6167 18.5545 20.8899 18.2328 20.7207L15 19.0211L11.7672 20.7207Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
}
