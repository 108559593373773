/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef } from 'react';

import { useMediaQuery } from 'react-responsive';

import MediaModal from '../../../../../../../../modals/MediaModal';

import classes from './styles.module.scss';
import playIcon from '../../../../../../../../assets/images/dashboard/discover/play.svg';

export default function Video({ allMedia, index, feedPost, isRead }) {
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);

  const videoRef = useRef();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className={classes.Video}>
      <video
        onClick={() => {
          setIsVideoModalVisible(true);
        }}
        src={allMedia[index]?.url}
        ref={videoRef}
      />
      <button
        type="button"
        className={classes.playButton}
        onClick={(event) => {
          event.stopPropagation();
          videoRef.current.play();
        }}
      >
        <img src={playIcon} alt="Play" />
      </button>
      {!isTabletOrMobile && (
        <MediaModal
          isRead={isRead}
          feedPost={feedPost}
          show={isVideoModalVisible}
          handleClose={() => setIsVideoModalVisible(false)}
          allMedia={allMedia}
          index={index}
        />
      )}
    </div>
  );
}
