import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { SearchContext } from '../../../../../context/SearchContext';
import JobCard from './JobCard';
import classes from './styles.module.scss';

export default function Jobs({
  searchTerm,
  jobs,
  activeTab,
  handleClosePopup,
  refetch,
  closeSearchPopup,
}) {
  const {
    setSearchTerm,
    setActiveDropDownOption,
    setIsOpenedSearchScreen,
    setActiveSearchScreenTab,
  } = useContext(SearchContext);

  const { t } = useTranslation();

  return (
    <div
      className={classNames(classes.Jobs, {
        [classes.noBorderTop]:
          activeTab !== t('dashboardComponents.Header.Search.Jobs.All'),
      })}
    >
      {activeTab === t('dashboardComponents.Header.Search.Jobs.All') && (
        <span className={classes.title}>
          {t('dashboardComponents.Header.Search.Jobs.Jobs')}
        </span>
      )}

      {jobs?.length === 0 && (
        <span className={classes.noMatch}>
          {t(
            'dashboardComponents.Header.Search.Jobs.No match for your search term'
          )}
        </span>
      )}
      {activeTab === t('dashboardComponents.Header.Search.Jobs.All') ? (
        <>
          <div className={classes.jobs}>
            {jobs?.slice(0, 2).map((job) => (
              <JobCard
                job={job}
                handleClosePopup={handleClosePopup}
                key={job?.jobPostId}
                refetch={refetch}
              />
            ))}
          </div>
          {jobs?.length > 2 && (
            <Link
              to="/tools/search"
              className={classes.seeAll}
              onClick={() => {
                setSearchTerm(searchTerm);
                setActiveDropDownOption('All');
                setActiveSearchScreenTab('Jobs');
                setIsOpenedSearchScreen(true);
                closeSearchPopup();
              }}
            >
              {t('dashboardComponents.Header.Search.Jobs.See all jobs')}
            </Link>
          )}
        </>
      ) : (
        <>
          <div className={classes.jobs}>
            {jobs?.slice(0, 6).map((job) => (
              <JobCard
                job={job}
                handleClosePopup={handleClosePopup}
                key={job?.jobPostId}
                refetch={refetch}
              />
            ))}
          </div>
          {jobs?.length > 6 && (
            <Link
              to="/tools/search"
              className={classes.seeAll}
              onClick={() => {
                setSearchTerm(searchTerm);
                setActiveDropDownOption('Jobs');
                setIsOpenedSearchScreen(true);
                closeSearchPopup();
              }}
            >
              {t('dashboardComponents.Header.Search.Jobs.See all jobs')}
            </Link>
          )}
        </>
      )}
    </div>
  );
}
