import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import Dropdown from '../../components/Form/Dropdown';
import infoIcon from '../../assets/images/modals/info.svg';
import Button from '../../components/Button';

import AdminService from '../../services/AdminService';
import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';

export default function NextStepsModal({
  show,
  handleClose,
  candidate,
  refetch,
}) {
  const [nextStep, setNextStep] = useState('');

  const { setIsFetching } = useContext(UiContext);

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const moveCandidateToJoined = async () => {
    try {
      setIsFetching(true);

      await AdminService.changeMyJobApplicationStatus({
        jobApplicationId: candidate.id,
        status: 'Onboard',
        subStatus: 'Joined',
      });

      if (nextStep.value === 'Close Job & Inform Candidates') {
        await AdminService.changeMyJobStatus({
          jobId: candidate.jobPostId,
          status: 'Closed',
        });
      }

      await queryClient.refetchQueries({
        queryKey: ['myJob'],
      });
      await refetch();

      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    return () => {
      setNextStep('');
    };
  }, [show]);

  let isSubmitButtonDisabled = false;

  if (!nextStep) {
    isSubmitButtonDisabled = true;
  }

  const options = [
    {
      label: t('modals.NextStepsModal.options.continueHiring'),
      value: 'Continue Hiring',
    },
    {
      label: t('modals.NextStepsModal.options.closeJobInformCandidates'),
      value: 'Close Job & Inform Candidates',
    },
  ];

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.Modal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <img
          width={32}
          height={32}
          className={classes.icon}
          src={infoIcon}
          alt="Icon"
        />
        <h1>{t('modals.NextStepsModal.modalTitle')}</h1>
        <p>{t('modals.NextStepsModal.message')}</p>
        <div>
          <Dropdown
            width={295}
            height={56}
            options={options}
            activeOption={nextStep}
            setActiveOption={setNextStep}
            placeholder={t('modals.NextStepsModal.dropdownPlaceholder')}
          />
        </div>
        <div className={classes.buttons}>
          <Button
            disabled={isSubmitButtonDisabled}
            width={144}
            onClick={moveCandidateToJoined}
          >
            {t('common.submit')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
