/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import { get } from 'lodash';
import moment from 'moment';

import TenantAssessmentAction from './TenantAssessmentAction';
import Note from '../../Dashboard/Candidates/CandidatesList/AllCandidates/CandidateGridCard/Note';
import InviteCandidateButton from './InviteCandidateButton';
import UserWithAvatar from './UserWithAvatar';
import CandidateBooleanFavorite from './CandidateBooleanFavorite';
import CandidateFavorites from '../../Dashboard/Jobs/MyJobs/JobDetails/CandidateFavorites';
import HiringStages from './HiringStages';
import ResumeCell from './ResumeCell';
import Role from '../Role';
import HiringPipelineValue from '../HiringPipelineValue';

import { IMAGES_URL } from '../../../constants/main';
import DaysFromCreation from './DaysFromCreation';
import JobStatus from './JobStatus';
import InterviewInfo from './InterviewInfo';
import HiringSubStatus from './HiringSubStatus';

export default function Td({ classes, column, row, refetch }) {
  let content;

  switch (column.type) {
    case 'scope':
      content = (
        <td>
          <div className={classes.rolesContainer}>
            {get(row, column.field)
              .split(',')
              .map((scope) => (
                <Role key={scope} role={scope} />
              ))}
          </div>
        </td>
      );
      break;
    case 'date':
      content = <td>{moment(get(row, column.field)).format('DD-MMM-YY')}</td>;
      break;
    case 'array':
      content = (
        <td>
          {get(row, column.field)
            ?.map((val) => val.name)
            .join(', ')}
        </td>
      );
      break;
    case 'role':
      content = (
        <td>
          <div className={classes.rolesContainer}>
            {get(row, column.field).map((role) => (
              <Role key={role.id} role={role.name} />
            ))}
          </div>
        </td>
      );
      break;
    case 'cityImage':
      content = (
        <td>
          <img
            alt="city"
            src={`${IMAGES_URL}/city/${get(
              row,
              column.field
            )}?height=70&width=100`}
          />
        </td>
      );
      break;
    case 'picVideo':
      content = (
        <td>
          {row.type === 'Video' ? (
            <video src={row.url} />
          ) : (
            <img alt="" src={`${row.url}?height=98&width=110`} />
          )}
        </td>
      );
      break;
    case 'user':
      content = (
        <td>
          {get(row, `${column.field}.firstName`)}{' '}
          {get(row, `${column.field}.lastName`)}
        </td>
      );
      break;
    case 'userWithAvatar':
      content = (
        <td>
          <UserWithAvatar user={get(row, column.field)} />
        </td>
      );
      break;
    case 'jobStatus':
      content = <JobStatus value={get(row, column.field)} />;
      break;
    case 'hiringPipeline':
      content = (
        <>
          <td align="center">
            <HiringPipelineValue backgroundColor="background: linear-gradient(228.41deg, #00E4AC 0%, #10C5E9 53.88%, #9240DC 106.71%)">
              {get(row, column.field).new}
            </HiringPipelineValue>
          </td>
          <td align="center">
            <HiringPipelineValue backgroundColor="#242833">
              {row.hasAssessment ? get(row, column.field).screen : 'N/A'}
            </HiringPipelineValue>
          </td>
          <td align="center">
            <HiringPipelineValue backgroundColor="#6722D2">
              {get(row, column.field).interview}
            </HiringPipelineValue>
          </td>
          <td align="center">
            <HiringPipelineValue backgroundColor="#00E4AC">
              {get(row, column.field).offer}
            </HiringPipelineValue>
          </td>
          <td align="center">
            <HiringPipelineValue backgroundColor="#C1C5D6">
              {get(row, column.field).onboard}
            </HiringPipelineValue>
          </td>
        </>
      );
      break;
    case 'daysFromCreation':
      content = (
        <DaysFromCreation
          value={moment(new Date()).diff(moment(row.createdAt), 'days')}
        />
      );
      break;
    case 'resume':
      content = (
        <td>
          <ResumeCell row={get(row, column.field)} />
        </td>
      );
      break;
    case 'hiringStages':
      content = (
        <td className={classes.hiringStagesContainer}>
          <HiringStages
            status={row.status}
            subStatus={row.subStatus}
            hasAssessment={!!row?.assignedAssessments.length}
            row={row}
          />
        </td>
      );
      break;
    case 'favorites':
      content = (
        <td className={classes.hiringStagesContainer}>
          <CandidateFavorites
            favoritesList={get(row, column.field)}
            jobApplicationId={row.id}
            refetch={refetch}
          />
        </td>
      );
      break;
    case 'candidateBooleanFavorite':
      content = (
        <td>
          <CandidateBooleanFavorite
            isFavorite={get(row, column.field)}
            candidateId={row.id}
            refetch={refetch}
          />
        </td>
      );
      break;
    case 'hiringSubStatus':
      content = <HiringSubStatus row={row} />;
      break;
    case 'interviewFeedback':
      content = (
        <td>
          <InterviewInfo interview={get(row, column.field)} />
        </td>
      );
      break;
    case 'boolean':
      content = <td>{get(row, column.field) === false ? 'No' : 'Yes'}</td>;
      break;
    case 'count':
      content = (
        <td className={classes.countContainer}>
          <div className={classes.count}>
            {get(row, column.field)?.toString()?.padStart?.(2, '0')}
          </div>
        </td>
      );
      break;
    case 'candidateNote':
      content = (
        <td>
          <div className={classes.candidateNoteContainer}>
            {get(row, column.field) ? (
              <Note
                text={get(row, column.field)}
                style={{ top: 0, right: 'unset', left: 0 }}
              />
            ) : null}
          </div>
        </td>
      );
      break;
    case 'inviteCandidateButton':
      content = (
        <td>
          <InviteCandidateButton candidateId={get(row, column.field)} />
        </td>
      );
      break;
    case 'short':
      content = <td className={classes.short}>{get(row, column.field)}</td>;
      break;
    case 'text':
      content = <td>{column.value}</td>;
      break;
    case 'tenantAssessmentAction':
      content = (
        <td>
          <TenantAssessmentAction
            tenantAssessmentProvider={row}
            refetch={refetch}
          />
        </td>
      );
      break;
    default:
      content = <td>{get(row, column.field) || column.defaultValue}</td>;
  }

  return <>{content}</>;
}
