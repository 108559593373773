/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import usePaginatedDropdownOptions from '../../../hooks/usePaginatedDropdownOptions';
import Tooltip from '../../Tooltip';
import Checkbox from '../Checkbox';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function MultiDropdown({
  options,
  value,
  placeholder,
  width,
  dangerOption,
  height,
  label,
  error,
  touched,
  searchInfo,
  searchInfoWidth,
  name,
  setFieldValue,
  setFieldTouched,
  info,
  note,
  infoWidth,
  fetchOptions,
  maxLength,
  readOnly,
  fixed,
  labelStyle,
  style,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);
  const [isEnoughHeight, setIsEnoughHeight] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [optionsCoords, setOptionsCoords] = useState(null);

  const selectorRef = useRef();
  const dropdownRef = useRef();
  const optionsRef = useRef();
  const isOpened = useRef();

  const { t } = useTranslation();

  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  const setMultiDropdownOption = (option) => {
    if (Array.isArray(value)) {
      if (value.some((val) => val.value === option.value)) {
        const newValues = value.filter((val) => val.value !== option.value);

        if (!newValues.length) {
          setFieldValue?.(name, '');
        } else {
          setFieldValue?.(name, newValues);
        }
      } else {
        if (maxLength && maxLength === value?.length) {
          return;
        }

        setFieldValue?.(name, [...value, option]);
      }
    } else {
      setFieldValue?.(name, [option]);
    }
  };

  // Make sure that options list is visible when user clicks on selector (by setting position to fixed)
  useEffect(() => {
    if (!fixed) {
      return;
    }

    if (isOptionsListVisible) {
      const {
        top,
        bottom,
        left,
        width: selectorWidth,
      } = selectorRef.current.getBoundingClientRect();

      setTimeout(() => {
        if (dropdownRef.current.classList.contains(classes.optionsTop)) {
          setOptionsCoords({
            // top: 'unset',
            top: top - optionsRef.current.clientHeight - 5,
            left,
            width: selectorWidth,
          });
        } else {
          setOptionsCoords({ top: bottom + 10, left, width: selectorWidth });
        }
      }, 0);
    }
  }, [isOptionsListVisible, fixed]);

  useEffect(() => {
    if (isOptionsListVisible) {
      dropdownRef.current.style.setProperty(
        '--optionsHeight',
        `-${optionsRef.current.clientHeight}px`
      );

      const { bottom } = optionsRef.current.getBoundingClientRect();

      if (window.innerHeight - bottom < dropdownRef.current.clientHeight) {
        setIsEnoughHeight(false);
      } else {
        setIsEnoughHeight(true);
      }
    } else {
      setIsEnoughHeight(true);
      if (isOpened.current) {
        setFieldTouched?.(name, true, true);
      }
    }
  }, [isOptionsListVisible, name, setFieldTouched]);

  useEffect(() => {
    if (!isOptionsListVisible) {
      setSearchTerm('');
      setOptionsCoords(null);
    }
  }, [isOptionsListVisible]);

  // Hide options list when user scrolls outside of it
  useEffect(() => {
    const hideOptionsOnScroll = (event) => {
      if (!optionsRef.current?.contains(event.target)) {
        setIsOptionsListVisible(false);
      }
    };

    const preventArrowKeysScroll = (event) => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        event.preventDefault();
      }
    };

    if (isOptionsListVisible) {
      window.addEventListener('wheel', hideOptionsOnScroll);
      window.addEventListener('keydown', preventArrowKeysScroll);
    } else {
      window.removeEventListener('wheel', hideOptionsOnScroll);
      window.removeEventListener('keydown', preventArrowKeysScroll);
    }
  }, [isOptionsListVisible]);

  const { options: fetchedOptions, lastListElementRef } =
    usePaginatedDropdownOptions({
      dataName: fetchOptions?.dataName,
      getDataHandler: fetchOptions?.getDataHandler,
      searchTerm,
      labelName: fetchOptions?.labelName,
      isUserList: fetchOptions?.isUserList,
    });

  const optionsToDisplay = fetchOptions
    ? fetchedOptions
    : options?.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      );

  return (
    <div
      style={{
        width,
      }}
      tabIndex={0}
      role="listbox"
      ref={dropdownRef}
      className={classNames(classes.MultiDropdown, {
        [classes.open]: isOptionsListVisible,
        [classes.error]: error && touched,
        [classes.optionsTop]: !isEnoughHeight,
      })}
    >
      <span className={classes.label} style={labelStyle}>
        {label}{' '}
        {info && (
          <Tooltip text={info} width={infoWidth} direction="top-right">
            <i className={classes.infoIcon}>
              <svg
                width="3"
                height="10"
                viewBox="0 0 3 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.25H1.03125"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 8.75V4.75"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          </Tooltip>
        )}{' '}
        {note && <span className={classes.note}>({note})</span>}
      </span>
      <div
        style={{ ...style, width, height }}
        className={classes.selector}
        ref={selectorRef}
        onClick={() => {
          if (readOnly) {
            return;
          }
          isOpened.current = true;
          setIsOptionsListVisible((prevState) => !prevState);
        }}
      >
        {value && Array.isArray(value) && value.length ? (
          <span className={classes.hasOption}>
            {value?.map((val) => val.label)?.join(', ')}
          </span>
        ) : (
          <span>{placeholder}</span>
        )}
        <div className={classes.arrow} />
      </div>
      <div className={classes.error}>{error}</div>
      {isOptionsListVisible && (
        <div
          className={classes.searchAndOptions}
          ref={optionsRef}
          style={{
            top: optionsCoords?.top,
            left: optionsCoords?.left,
            position: optionsCoords ? 'fixed' : null,
            width: optionsCoords?.width,
            bottom: optionsCoords?.bottom,
          }}
        >
          <div className={classes.search}>
            <input
              type="text"
              placeholder={t('common.search')}
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
            {searchInfo && (
              <Tooltip text={searchInfo} width={searchInfoWidth}>
                <i>Info</i>
              </Tooltip>
            )}
          </div>
          <div className={classes.options}>
            <ul>
              {optionsToDisplay?.map((option, index) => (
                <li
                  ref={
                    index === optionsToDisplay.length - 1
                      ? lastListElementRef
                      : null
                  }
                  key={option.value}
                  onClick={() => {
                    setMultiDropdownOption(option);
                  }}
                  className={classNames({
                    [classes.active]: value?.some?.(
                      (val) => val.value === option.value
                    ),
                    [classes.danger]: dangerOption === option.value,
                  })}
                >
                  <Checkbox
                    isSelected={value?.some?.(
                      (val) => val.value === option.value
                    )}
                  />
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
