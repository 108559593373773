/* eslint-disable react/jsx-handler-names */
import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Switch from '../../../../../Switch';

import { UiContext } from '../../../../../../context/UiContext';
// import handleIcon from '../../../../../../assets/images/dashboard/handle.svg';
import classes from './styles.module.scss';

export default function SocialLink({
  title,
  icon,
  isEnabled,
  url,
  setLinks,
  linkIndex,
  setLinksValidity,
  isValid,
}) {
  const [isDraggable, setIsDraggable] = useState(false);
  const { showModal } = useContext(UiContext);

  const { t } = useTranslation();

  const validateUrl = (event) => {
    const { value } = event.target;

    if (!value) {
      setLinksValidity((prevLinksValidity) => {
        return {
          ...prevLinksValidity,
          [title]: true,
        };
      });
      return;
    }

    if (!value.startsWith('https://') && !value.startsWith('http://')) {
      setLinks((prevLinks) => {
        return prevLinks.map((link) => {
          if (link.title === title) {
            return {
              ...link,
              url: `https://${value}`,
            };
          }

          return link;
        });
      });

      setTimeout(() => {
        validateUrl(event);
      }, 0);

      return;
    }

    // Regular expression to validate URL format
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    const isValidUrl = urlRegex.test(value);

    if (!isValidUrl) {
      showModal({
        title: t(
          'components.Dashboard.ProfileSettings.SocialMedia.Links.SocialLink.invalidUrlTitle'
        ),
        text: t(
          'components.Dashboard.ProfileSettings.SocialMedia.Links.SocialLink.invalidUrlText'
        ),
        dismissButtonVariant: 'modal',
        dismissButtonLabel: t(
          'components.Dashboard.ProfileSettings.SocialMedia.Links.SocialLink.invalidUrlButtonLabel'
        ),
      });
    }

    setLinksValidity((prevLinksValidity) => {
      return {
        ...prevLinksValidity,
        [title]: isValidUrl,
      };
    });
  };

  const toggleLinkVisibility = () => {
    setLinks((prevLinks) => {
      return prevLinks.map((link) => {
        if (link.title === title) {
          return {
            ...link,
            isEnabled: !link.isEnabled,
          };
        }

        return link;
      });
    });
  };

  const changeUrl = (e) => {
    setLinksValidity((prevLinksValidity) => {
      return {
        ...prevLinksValidity,
        [title]: true,
      };
    });

    const newUrl = e.target.value;

    setLinks((prevLinks) => {
      return prevLinks.map((link) => {
        if (link.title === title) {
          return {
            ...link,
            url: newUrl,
          };
        }

        return link;
      });
    });
  };

  const dragLink = (event) => {
    const element = document.elementFromPoint(event.clientX, event.clientY);

    if (!element.classList.contains(classes.handle)) {
      event.preventDefault();
      return;
    }

    event.dataTransfer.setData('linkIndex', linkIndex);
  };

  useEffect(() => {
    if (!isValid || !url) {
      setLinks((prevLinks) => {
        return prevLinks.map((link) => {
          if (link.title === title) {
            return {
              ...link,
              isEnabled: false,
            };
          }

          return link;
        });
      });
    }
  }, [isValid, setLinks, title, url]);

  return (
    <div
      className={classes.SocialLink}
      draggable={isDraggable}
      onDragStart={dragLink}
    >
      <div
        className={classes.handleContainer}
        onTouchStart={() => setIsDraggable(true)}
        onTouchEnd={() => setIsDraggable(false)}
      >
        <div className={classes.handle} />
      </div>
      <div className={classes.content}>
        <header className={classes.header}>
          <h3>{title}</h3>
          <div
            className={classNames(classes.visibilityToggle, {
              [classes.disabled]: !isValid || !url,
            })}
          >
            <span className={classes.label}>Visibility</span>
            <Switch
              isEnabled={isValid ? isEnabled : false}
              onClick={toggleLinkVisibility}
              readOnly={!isValid || !url}
            />
          </div>
        </header>
        <div className={classes.linkContainer}>
          <img src={icon} alt={title} />
          <input
            tabIndex={0}
            className={classNames(classes.link, {
              [classes.invalid]: !isValid,
            })}
            onBlur={validateUrl}
            type="text"
            value={url}
            onChange={changeUrl}
            placeholder="https://"
          />
        </div>
      </div>
    </div>
  );
}
