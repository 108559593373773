/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import ChipsReadonly from '../../../../../../Form/ChipsReadonly';
import ChipsDropdown from '../../../../../../Form/ChipsDropdown';
import { IMAGES_URL } from '../../../../../../../constants/main';
import DocUploader from './DocUploader';
import MediaUploader from './MediaUploader';
import AdminService from '../../../../../../../services/AdminService';
import FormDropdown from '../../../../../../Form/FormDropdown';
import TextInput from '../../../../../../Form/TextInput';
import Textarea from '../../../../../../Form/Textarea';
import MultiDropdown from '../../../../../../Form/MultiDropdown';
import classes from './styles.module.scss';
import NumberRange from '../../../../../../Form/NumberRange';

export default function BasicInfo({
  formik,
  data,
  isTriedToSubmit,
  isEdit,
  readOnly,
}) {
  const { t } = useTranslation();

  const changeVideo = useCallback((file) => {
    formik?.setFieldValue('jobPostVideoId', file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let jobTitleWarning;
  let corporateTitleWarning;
  let coverFileNameError;

  if (formik?.values.jobTitle?.length < 5 && formik?.values.jobTitle) {
    jobTitleWarning = t(
      'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.TitleTooShortError'
    );
  }

  if (
    formik?.values.corporateTitle?.length < 2 &&
    formik?.values.corporateTitle
  ) {
    corporateTitleWarning = t(
      'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.CorporateTitleTooShortError'
    );
  }

  if (formik?.values?.coverFileName?.name?.endsWith('.svg')) {
    coverFileNameError = t(
      'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.WrongPictureFormatError'
    );
    formik.setErrors({ coverFileName: coverFileNameError });
  }

  return (
    <div className={classes.BasicInfo}>
      <div className={classes.col}>
        {formik ? (
          <TextInput
            readonly={readOnly}
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.JobTitleLabel'
            )}
            height={45}
            placeholder={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.JobTitlePlaceholder'
            )}
            value={formik?.values.jobTitle}
            onChange={formik?.handleChange}
            error={
              formik?.errors.jobTitle !==
                t(
                  'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.TitleTooShortError'
                ) && formik?.errors.jobTitle
            }
            warning={jobTitleWarning}
            name="jobTitle"
            touched={isTriedToSubmit}
          />
        ) : (
          <TextInput
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.JobTitleLabel'
            )}
            height={45}
            value={data.jobTitle}
            readonly
          />
        )}
        {formik ? (
          <MultiDropdown
            height={45}
            fixed
            readOnly={readOnly}
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.JobTypeLabel'
            )}
            placeholder={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.pickJobType'
            )}
            value={formik?.values.jobTypeIds}
            setFieldValue={formik?.setFieldValue}
            setFieldTouched={formik?.setFieldTouched}
            name="jobTypeIds"
            width="100%"
            touched={isTriedToSubmit}
            error={formik?.errors.jobTypeIds}
            fetchOptions={{
              dataName: 'jobTypesOptionsBasicInfo',
              getDataHandler: AdminService.getJobTypes,
            }}
          />
        ) : (
          <TextInput
            readonly
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.JobTypeLabel'
            )}
            height={45}
            value={data?.jobTypes.map((type) => type.name).join(', ')}
          />
        )}
        {formik ? (
          <FormDropdown
            height={45}
            fixed
            readOnly={readOnly}
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.CityLabel'
            )}
            placeholder={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.CityPlaceholder'
            )}
            value={formik?.values.locationCityId}
            setFieldValue={formik?.setFieldValue}
            setFieldTouched={formik?.setFieldTouched}
            name="locationCityId"
            error={formik?.errors.locationCityId}
            touched={isTriedToSubmit}
            fetchOptions={{
              dataName: 'citiesBasicInfo',
              getDataHandler: AdminService.getCities,
            }}
          />
        ) : (
          <TextInput
            readonly
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.CityLabel'
            )}
            height={45}
            value={data?.locationCity?.name}
          />
        )}
        {formik ? (
          <FormDropdown
            fixed
            readOnly={readOnly}
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.DistrictLabel'
            )}
            placeholder={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.DistrictPlaceholder'
            )}
            fetchOptions={{
              dataName: 'districtsBasicInfo',
              getDataHandler: AdminService.getDistricts,
            }}
            info="District area the job will be at"
            infoWidth={142}
            height={45}
            note="Optional"
            value={formik?.values.locationCityDistrictId}
            setFieldValue={formik?.setFieldValue}
            setFieldTouched={formik?.setFieldTouched}
            name="locationCityDistrictId"
            touched={isTriedToSubmit}
            error={formik?.errors.locationCityDistrictId}
            searchInfo="Add new City in City admin screen, if you cannot find one."
            searchInfoWidth={173}
          />
        ) : (
          <TextInput
            readonly
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.DistrictLabel'
            )}
            height={45}
            value={data?.locationCityDistrict?.name}
          />
        )}
        {formik ? (
          <FormDropdown
            fixed
            readOnly={readOnly}
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.EducationLabel'
            )}
            placeholder={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.EducationPlaceholder'
            )}
            fetchOptions={{
              dataName: 'educationOptionsBasicInfo',
              getDataHandler: AdminService.getEducations,
            }}
            value={formik?.values.graduation}
            setFieldValue={formik?.setFieldValue}
            setFieldTouched={formik?.setFieldTouched}
            name="graduation"
            touched={isTriedToSubmit}
            error={formik?.errors.graduation}
            height={45}
          />
        ) : (
          <TextInput
            readonly
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.EducationLabel'
            )}
            height={45}
            value={data?.graduation?.name}
          />
        )}
        <div className={classes.mediaContainer}>
          <h1>Upload Picture/Video</h1>
          <div className={classes.uploadersContainer}>
            {formik && !readOnly ? (
              <div>
                <MediaUploader
                  isEdit={isEdit}
                  type="image"
                  file={formik?.values.coverFileName}
                  setFile={(file) =>
                    formik?.setFieldValue('coverFileName', file)
                  }
                />
                {formik?.errors.coverFileName && isTriedToSubmit && (
                  <div className={classes.error}>
                    {formik.errors.coverFileName}
                  </div>
                )}
              </div>
            ) : (
              <div
                style={{
                  visibility:
                    formik?.values.coverFileName || data?.coverFileName
                      ? 'visible'
                      : 'hidden',
                }}
              >
                <img
                  className={classes.savedImage}
                  src={`${IMAGES_URL}/jobpostcover/${
                    formik?.values.coverFileName || data?.coverFileName
                  }`}
                  alt="jobpostcover"
                />
              </div>
            )}
            {formik && !readOnly ? (
              <div className={classes.videoUploaderContainer}>
                <MediaUploader
                  isEdit={isEdit}
                  type="video"
                  file={formik?.values.jobPostVideoId}
                  setFile={changeVideo}
                />
              </div>
            ) : (
              <div className={classes.savedVideo}>
                <video
                  src={
                    formik?.values.jobPostVideo?.url || data?.jobPostVideo?.url
                  }
                />
                <div className={classes.text}>
                  <span>
                    {t(
                      'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.JobSpecsVideoLabel'
                    )}{' '}
                    <br /> {t('common.video')}
                  </span>
                  <span>({t('common.optional')})</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {formik && !readOnly ? (
          <DocUploader
            file={formik?.values.jobPostAttachmentIds}
            setFile={(file) =>
              formik?.setFieldValue('jobPostAttachmentIds', file)
            }
          />
        ) : (
          <DocUploader
            readonly
            filename={formik?.values.jobPostAttachments?.[0]?.displayName}
          />
        )}
      </div>
      <div className={classes.col}>
        {formik ? (
          <TextInput
            readonly={readOnly}
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.CorporateTitleLabel'
            )}
            height={45}
            placeholder={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.CorporateTitlePlaceholder'
            )}
            value={formik?.values.corporateTitle}
            onChange={formik?.handleChange}
            // error={formik?.errors.corporateTitle}
            name="corporateTitle"
            touched={isTriedToSubmit}
            warning={corporateTitleWarning}
            info={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.May be added for RSC roles'
            )}
            infoWidth={171}
            note={t('common.optional')}
          />
        ) : (
          <TextInput
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.CorporateTitleLabel'
            )}
            height={45}
            value={data.corporateTitle}
            name="corporateTitle"
            readonly
          />
        )}
        {formik ? (
          <Textarea
            readonly={readOnly}
            height={186}
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.JobDescriptionLabel'
            )}
            placeholder={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.JobDescriptionPlaceholder'
            )}
            value={formik?.values.jobDescription}
            onChange={formik?.handleChange}
            error={formik?.errors.jobDescription}
            name="jobDescription"
            touched={isTriedToSubmit}
            maxLength={5000}
          />
        ) : (
          <Textarea
            height={197}
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.JobDescriptionLabel'
            )}
            readonly
            value={data.jobDescription}
          />
        )}
        {formik ? (
          <MultiDropdown
            height={45}
            readOnly={readOnly}
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.RestaurantLabel'
            )}
            placeholder={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.RestaurantPlaceholder'
            )}
            fetchOptions={{
              dataName: 'restaurantsOptionsBasicInfo',
              getDataHandler: AdminService.getRestaurants,
            }}
            info={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.Restaurant location for the job'
            )}
            infoWidth={151}
            note={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.Recommended'
            )}
            value={formik?.values.locationIds}
            setFieldValue={formik?.setFieldValue}
            setFieldTouched={formik?.setFieldTouched}
            name="locationIds"
            width="100%"
            touched={isTriedToSubmit}
            error={formik?.errors.locationIds}
            searchInfo={t('common.addNewRestaurant')}
            searchInfoWidth={188}
          />
        ) : (
          <TextInput
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.RestaurantLabel'
            )}
            height={45}
            value={data?.locations?.map((loc) => loc.name).join(', ')}
            readonly
          />
        )}
        {formik ? (
          <FormDropdown
            height={45}
            fixed
            readOnly={readOnly}
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.ExperienceLabel'
            )}
            placeholder={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.ExperiencePlaceholder'
            )}
            fetchOptions={{
              dataName: 'experienceOptionsBasicInfo',
              getDataHandler: AdminService.getWorkExperience,
            }}
            value={formik?.values.workExperienceId}
            setFieldValue={formik?.setFieldValue}
            setFieldTouched={formik?.setFieldTouched}
            name="workExperienceId"
            touched={isTriedToSubmit}
            error={formik?.errors.workExperienceId}
          />
        ) : (
          <TextInput
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.ExperienceLabel'
            )}
            height={45}
            value={data?.workExperience?.name}
            readonly
          />
        )}
        {formik && !readOnly ? (
          <ChipsDropdown
            readOnly={readOnly}
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.KeySkillsLabel'
            )}
            maxLength={10}
            height={192}
            info={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.SkillsNeededInfo'
            )}
            value={formik?.values.skillIds}
            setFieldValue={formik?.setFieldValue}
            name="skillIds"
            infoWidth={137}
            searchInfo={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.SkillsNeededSearchInfo'
            )}
            searchInfoWidth={185}
            fetchOptions={{
              dataName: 'skillsOptionsBasicInfo',
              getDataHandler: () =>
                AdminService.getSkills({
                  orderBy: 'sortOrder',
                  sortOrder: 'asc',
                }),
            }}
          />
        ) : (
          <ChipsReadonly
            values={
              formik?.values.skillIds ||
              data?.skills?.map((skill) => skill.name)
            }
            label={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.KeySkillsLabel'
            )}
            info={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.SkillsNeededInfo'
            )}
            infoWidth={137}
          />
        )}
        {formik && !readOnly ? (
          <NumberRange
            valueTo={formik?.values.salaryTo}
            valueFrom={formik?.values.salaryFrom}
            nameTo="salaryTo"
            nameFrom="salaryFrom"
            setFieldValue={formik?.setFieldValue}
            info={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.SalaryInfo'
            )}
            infoWidth={151}
            error={formik?.errors.salaryTo || formik?.errors.salaryFrom}
            touched={isTriedToSubmit}
            warning={t(
              'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.SalaryTooLowWarning'
            )}
          />
        ) : (
          <div className={classes.numberRangeReadOnly}>
            <TextInput
              label={t(
                'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.Expected Salary'
              )}
              readonly
              width={150}
              height={50}
              value={formik?.values.salaryFrom || data?.salaryFrom}
            />
            <div className={classes.split}>To</div>
            <TextInput
              readonly
              width={150}
              height={50}
              label={<>&nbsp;</>}
              value={formik?.values.salaryTo || data?.salaryTo}
            />
          </div>
        )}
        <div style={{ position: 'relative', top: formik ? 12 : 8 }}>
          {formik ? (
            <Textarea
              readOnly={readOnly}
              height={127}
              label={t(
                'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.NotesLabel'
              )}
              placeholder={t(
                'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.NotesPlaceholder'
              )}
              value={formik?.values.note}
              onChange={formik?.handleChange}
              error={formik?.errors.note}
              name="note"
              touched={isTriedToSubmit}
              info={t(
                'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.InternalNotesInfo'
              )}
              infoWidth={142}
              note={t('common.optional')}
            />
          ) : (
            <Textarea
              height={127}
              label={t(
                'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.NotesLabel'
              )}
              readonly
              value={data.templateNote}
              info={t(
                'dashboardComponents.Console.JobManagement.JobTemplates.Template.BasicInfo.InternalNotesInfo'
              )}
              infoWidth={142}
            />
          )}
        </div>
      </div>
    </div>
  );
}
