import React, { useContext } from 'react';

import Color from './Color';

import { BrandKitContext } from '../../../../../../../context/BrandKitContext';
import classes from './styles.module.scss';

export default function ColorsPreview({ brandColors, isActive }) {
  const { activeColorIndex } = useContext(BrandKitContext);

  return (
    <div className={classes.ColorsPreview}>
      {brandColors?.map((color, index) => (
        <Color
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          hexCode={color}
          isActive={
            isActive &&
            (activeColorIndex === null || activeColorIndex === index)
          }
        />
      ))}
    </div>
  );
}
