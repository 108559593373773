import React, { useMemo, useEffect } from 'react';

import chatConnection from '../../../../helpers/chatConnection';
import NoMessages from './NoMessages';
import RightPanelHeader from './RightPanelHeader';
import ChatWindow from './ChatWindow';

import classes from './styles.module.scss';

export default function RightPanel({
  chatMessages,
  activeContact,
  refetchChatThreads,
  refetchMessages,
  jobApplicationUserFeedbacks,
  messageListHeight,
  isJobApplicationOpened,
}) {
  const connection = useMemo(() => chatConnection(), []);

  useEffect(() => {
    connection?.startConnection();

    return () => {
      connection.endConnection();
    };
  }, [connection]);

  useEffect(() => {
    const newConnection = connection.connection;

    if (newConnection) {
      newConnection.on('NewMessage', () => {
        refetchMessages();
        refetchChatThreads();
      });
    }
  }, [connection, refetchMessages, refetchChatThreads]);

  return (
    <div className={classes.RightPanel}>
      {!chatMessages ? (
        <NoMessages />
      ) : (
        <>
          <RightPanelHeader
            refetchChatThreads={refetchChatThreads}
            activeContact={activeContact}
            isJobApplicationOpened={isJobApplicationOpened}
          />
          <ChatWindow
            refetchMessages={refetchMessages}
            activeContact={activeContact}
            chatMessages={chatMessages}
            jobApplicationUserFeedbacks={jobApplicationUserFeedbacks}
            messageListHeight={messageListHeight}
          />
        </>
      )}
    </div>
  );
}
