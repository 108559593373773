/* eslint-disable no-shadow */
import { useContext } from 'react';

import i18n from '../i18n';
import { UiContext } from '../context/UiContext';

const t = i18n.t.bind(i18n);

const useDeleteData = (
  ids,
  closeView,
  refetch,
  setSelectedRows,
  deleteHandler,
  dataName
) => {
  const { showModal, showUnknownErrorModal, setIsFetching, showConflictModal } =
    useContext(UiContext);

  return {
    deleteData(id) {
      const dataIds = id ? [id] : ids;
      showModal({
        icon: 'delete',
        title: `${t('common.delete')} ${dataName}`,
        text: t('hooks.useDeleteData.confirmDeleteText'),
        confirmButtonLabel: t('common.yes'),
        dismissButtonLabel: t('common.no'),
        onConfirm: async () => {
          try {
            setIsFetching(true);
            const promises = dataIds.map((id) => deleteHandler({ id }));
            await Promise.all(promises);
            await refetch();
            showModal({
              title: t('hooks.useDeleteData.successTitle'),
              text: `${dataName} ${t('hooks.useDeleteData.successMessage')}`,
              dismissButtonLabel: t('common.gotIt'),
              dismissButtonVariant: '',
            });
            closeView();
          } catch (error) {
            if (error.response.status === 409) {
              showConflictModal(error.response.data.message);
            } else {
              showUnknownErrorModal();
            }
          } finally {
            setIsFetching(false);
            setSelectedRows([]);
          }
        },
      });
    },
  };
};

export default useDeleteData;
