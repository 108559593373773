import React from 'react';

import { useTranslation } from 'react-i18next';

import AdminService from '../../../../../services/AdminService';
import TableView from '../../TableView';

export default function Experience({ setItemCount }) {
  const { t } = useTranslation();

  const columns = [
    {
      name: t(
        'dashboardComponents.Console.RefDataManagement.Experience.experienceName'
      ),
      field: 'name',
      sortable: true,
    },
    { name: t('common.sortOrder'), field: 'sortOrder', sortable: true },
    {
      name: t('common.notes'),
      field: 'note',
      sortable: false,
    },
    {
      name: t('common.createdOn'),
      field: 'createdAt',
      sortable: true,
      type: 'date',
    },
  ];

  return (
    <TableView
      setItemCount={setItemCount}
      dataName="Experience"
      getDataHandler={AdminService.getWorkExperience}
      deleteDataHandler={AdminService.deleteWorkExperience}
      createDataHandler={AdminService.createWorkExperience}
      updateDataHandler={AdminService.updateWorkExperience}
      viewLeftColumnFields={[
        {
          label: t(
            'dashboardComponents.Console.RefDataManagement.Experience.experience'
          ),
          field: 'name',
        },
      ]}
      viewRightColumnFields={[
        { label: t('common.sortOrder'), field: 'sortOrder' },
        {
          label: t('common.notes'),
          field: 'note',
          type: 'textarea',
        },
      ]}
      formLeftColumnFields={[
        {
          label: t(
            'dashboardComponents.Console.RefDataManagement.Experience.experience'
          ),
          field: 'name',
          validation: ['string', 'required'],
          placeholder: t(
            'dashboardComponents.Console.RefDataManagement.Experience.experienceNameHere'
          ),
        },
      ]}
      formRightColumnFields={[
        {
          label: t('common.sortOrder'),
          field: 'sortOrder',
          validation: ['string', 'required', 'numbers'],
          placeholder: t(
            'dashboardComponents.Console.RefDataManagement.Experience.specifySortOrderHere'
          ),
          maxLength: 5,
        },
        {
          label: t('common.notes'),
          field: 'note',
          type: 'textarea',
          validation: ['string'],
          placeholder: t(
            'dashboardComponents.Console.RefDataManagement.Experience.enterAnySpecificNotesAboutTheSkill'
          ),
        },
      ]}
      columns={columns}
    />
  );
}
