/* eslint-disable react/jsx-handler-names */
import React, { createContext, useState, useMemo, useCallback } from 'react';

import NextStepsModal from '../modals/NextStepsModal';
import ChangeStageWithExceptionModal from '../modals/ChangeStageWithExceptionModal';
import RejectCandidateWithReasonModal from '../modals/RejectCandidateWIthReasonModal';
import OfferModal from '../modals/OfferModal';
import SetJoiningModal from '../modals/SetJoiningModal';
import ScheduleInterviewModal from '../modals/ScheduleInterviewModal';
import SetOnboardingModal from '../modals/SetOnboardingModal';
import ReportCandaidateModal from '../modals/ReportCandidateModal';
import ShareModal from '../modals/ShareModal';
import ShareJobModal from '../modals/ShareJobModal';
import QRQModal from '../modals/QRQModal';
import PostJobSuccessModal from '../modals/PostJobSuccessModal';
import AssessmentModal from '../modals/AssessmentModal';

export const JobsContext = createContext({
  showReportCandidateModal: () => {},
  showShareModal: () => {},
  showShareJobModal: () => {},
  showQRQModal: () => {},
  showPostJobSuccessPopup: () => {},
  showSetOnboardingModal: () => {},
  showScheduleInterviewModal: () => {},
  showSetJoiningModal: () => {},
  showOfferModal: () => {},
  showRejectCandidateWithReasonModal: () => {},
  showChangeStageWithExceptionModal: () => {},
  showNextStepsModal: () => {},
  showAssessmentModal: () => {},
});

export default function JobsContextProvider({ children }) {
  const [isReportCandidateModalVisible, setIsReportCandidateModalVisible] =
    useState(false);
  const [reportingCandidate, setReportingCandidate] = useState(null);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [sharingCandidate, setSharingCandidate] = useState(null);
  const [sharingEvent, setSharingEvent] = useState(null);
  const [isShareJobVisible, setIsShareJobVisible] = useState(false);
  const [sharingJob, setSharingJob] = useState(null);
  const [isQRQVisible, setIsQRQVisible] = useState(false);
  const [QRQjob, setQRQjob] = useState(null);
  const [isPostJobSuccessPopupVisible, setIsPostJobSuccessPopupVisible] =
    useState(false);
  const [postJobSuccess, setPostJobSuccess] = useState(null);

  const [isOnboardingModalVisible, setIsOnboardingModalVisible] =
    useState(false);
  const [onboardingCandidate, setOnboardingCandidate] = useState(null);
  const [refetchJobApplications, setRefetchJobApplications] = useState(null);
  const [isScheduleInterviewModalVisible, setIsScheduleInterviewModalVisible] =
    useState(false);
  const [interviewCandidate, setInterviewCandidate] = useState({});
  const [isSetJoiningModalVisible, setIsSetJoiningModalVisible] =
    useState(false);
  const [joiningCandidate, setJoiningCandidate] = useState({});
  const [isOfferModalVisible, setIsOfferModalVisible] = useState(false);
  const [offeringCandidate, setOfferingCandidate] = useState(null);
  const [isRejectCandidateModalVisible, setIsRejectCandidateModalVisible] =
    useState(false);
  const [rejectingCandidate, setRejectingCandidate] = useState(null);
  const [isChangeStageModalVisible, setIsChangeStageModalVisible] =
    useState(false);
  const [changingStageCandidate, setChangingStageCandidate] = useState(null);
  const [stage, setStage] = useState(null);
  const [isNextStepsModalVisible, setIsNextStepsModalVisible] = useState(false);
  const [nextStepsCandidate, setNextStepsCandidate] = useState(null);
  const [isAssessmentModalVisible, setIsAssessmentModalVisible] =
    useState(false);
  const [assessmentCandidate, setAssessmentCandidate] = useState(null);
  const [isRetakeAssessment, setIsRetakeAssessment] = useState(false);

  const showReportCandidateModal = useCallback(({ candidate, refetch }) => {
    setReportingCandidate(candidate);
    setIsReportCandidateModalVisible(true);
    setRefetchJobApplications(() => refetch);
  }, []);

  const showShareModal = useCallback(({ candidate, job, event }) => {
    setSharingCandidate(candidate);
    setSharingEvent(event);
    setSharingJob(job);
    setIsShareModalVisible(true);
  }, []);

  const showShareJobModal = useCallback((job) => {
    setSharingJob(job);
    setIsShareJobVisible(true);
  }, []);

  const showQRQModal = useCallback((job) => {
    setQRQjob(job);
    setIsQRQVisible(true);
  }, []);

  const showPostJobSuccessPopup = useCallback((job) => {
    setPostJobSuccess(job);
    setIsPostJobSuccessPopupVisible(true);
  }, []);

  const showSetOnboardingModal = useCallback(({ candidate, refetch }) => {
    setOnboardingCandidate(candidate);
    setRefetchJobApplications(() => refetch);
    setIsOnboardingModalVisible(true);
  }, []);

  const showScheduleInterviewModal = useCallback(({ candidate, refetch }) => {
    setInterviewCandidate(candidate);
    setRefetchJobApplications(() => refetch);
    setIsScheduleInterviewModalVisible(true);
  }, []);

  const showSetJoiningModal = useCallback(({ candidate, refetch }) => {
    setJoiningCandidate(candidate);
    setRefetchJobApplications(() => refetch);
    setIsSetJoiningModalVisible(true);
  }, []);

  const showOfferModal = useCallback(({ candidate, refetch }) => {
    setOfferingCandidate(candidate);
    setRefetchJobApplications(() => refetch);
    setIsOfferModalVisible(true);
  }, []);

  const showRejectCandidateWithReasonModal = useCallback(
    ({ candidate, refetch }) => {
      setRejectingCandidate(candidate);
      setRefetchJobApplications(() => refetch);
      setIsRejectCandidateModalVisible(true);
    },
    []
  );

  const showChangeStageWithExceptionModal = useCallback(
    // eslint-disable-next-line no-shadow
    ({ candidate, refetch, stage }) => {
      setChangingStageCandidate(candidate);
      setRefetchJobApplications(() => refetch);
      setStage(stage);
      setIsChangeStageModalVisible(true);
    },
    []
  );

  const showNextStepsModal = useCallback(({ candidate, refetch }) => {
    setNextStepsCandidate(candidate);
    setIsNextStepsModalVisible(true);
    setRefetchJobApplications(() => refetch);
  }, []);

  const showAssessmentModal = useCallback(({ candidate, refetch, retake }) => {
    setAssessmentCandidate(candidate);
    setRefetchJobApplications(() => refetch);
    setIsAssessmentModalVisible(true);
    setIsRetakeAssessment(retake);
  }, []);

  const value = useMemo(
    () => ({
      showReportCandidateModal,
      showShareModal,
      showShareJobModal,
      showQRQModal,
      showPostJobSuccessPopup,
      showSetOnboardingModal,
      showScheduleInterviewModal,
      showSetJoiningModal,
      showOfferModal,
      showRejectCandidateWithReasonModal,
      showChangeStageWithExceptionModal,
      showNextStepsModal,
      showAssessmentModal,
    }),
    [
      showReportCandidateModal,
      showShareModal,
      showShareJobModal,
      showQRQModal,
      showPostJobSuccessPopup,
      showSetOnboardingModal,
      showScheduleInterviewModal,
      showSetJoiningModal,
      showOfferModal,
      showRejectCandidateWithReasonModal,
      showChangeStageWithExceptionModal,
      showNextStepsModal,
      showAssessmentModal,
    ]
  );

  return (
    <JobsContext.Provider value={value}>
      {children}
      <ReportCandaidateModal
        show={isReportCandidateModalVisible}
        handleClose={() => setIsReportCandidateModalVisible(false)}
        reportingCandidate={reportingCandidate}
        refetch={refetchJobApplications}
      />
      <ShareJobModal
        show={isShareJobVisible}
        handleClose={() => setIsShareJobVisible(false)}
        job={sharingJob}
      />
      <ShareModal
        show={isShareModalVisible}
        handleClose={() => setIsShareModalVisible(false)}
        candidate={sharingCandidate}
        job={sharingJob}
        event={sharingEvent}
      />
      <QRQModal
        show={isQRQVisible}
        handleClose={() => setIsQRQVisible(false)}
        job={QRQjob}
      />
      <PostJobSuccessModal
        show={isPostJobSuccessPopupVisible}
        handleClose={() => setIsPostJobSuccessPopupVisible(false)}
        createdJobPost={postJobSuccess}
      />
      <SetOnboardingModal
        show={isOnboardingModalVisible}
        handleClose={() => setIsOnboardingModalVisible(false)}
        candidate={onboardingCandidate}
        refetch={refetchJobApplications}
      />
      <ScheduleInterviewModal
        show={isScheduleInterviewModalVisible}
        handleClose={() => setIsScheduleInterviewModalVisible(false)}
        candidate={interviewCandidate}
        refetch={refetchJobApplications}
      />
      <SetJoiningModal
        show={isSetJoiningModalVisible}
        handleClose={() => setIsSetJoiningModalVisible(false)}
        candidate={joiningCandidate}
        refetch={refetchJobApplications}
      />
      <OfferModal
        show={isOfferModalVisible}
        handleClose={() => setIsOfferModalVisible(false)}
        candidate={offeringCandidate}
        refetch={refetchJobApplications}
      />
      <RejectCandidateWithReasonModal
        show={isRejectCandidateModalVisible}
        handleClose={() => setIsRejectCandidateModalVisible(false)}
        candidate={rejectingCandidate}
        refetch={refetchJobApplications}
      />
      <ChangeStageWithExceptionModal
        show={isChangeStageModalVisible}
        handleClose={() => setIsChangeStageModalVisible(false)}
        candidate={changingStageCandidate}
        refetch={refetchJobApplications}
        stage={stage}
      />
      <NextStepsModal
        show={isNextStepsModalVisible}
        handleClose={() => setIsNextStepsModalVisible(false)}
        candidate={nextStepsCandidate}
        refetch={refetchJobApplications}
      />
      <AssessmentModal
        show={isAssessmentModalVisible}
        handleClose={() => setIsAssessmentModalVisible(false)}
        candidate={assessmentCandidate}
        retake={isRetakeAssessment}
        refetch={refetchJobApplications}
      />
    </JobsContext.Provider>
  );
}
