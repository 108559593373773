import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Button({ onClick, disabled, type, children }) {
  return (
    <button
      className={classNames(classes.Button, classes[type])}
      type="submit"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
