import React, { useCallback } from 'react';

// import { useQuery } from '@tanstack/react-query';

import Question from './Question';
import AdminService from '../../../../../../../../services/AdminService';

export default function Questions({
  vrQuestions,
  setVrQuestions,
  areVrQuestionsValid,
  isTriedToSubmit,
  isJobPostForm,
}) {
  const deleteQuestion = (id) => {
    setVrQuestions((prevQuestions) =>
      prevQuestions.filter((question) => question.id !== id)
    );
  };

  const setOption = useCallback(
    async (id, option) => {
      try {
        let existingQuestion;
        if (option.value) {
          existingQuestion = await AdminService.getVrQuestion({
            id: option.value,
          });
        }

        setVrQuestions((prevQuestions) =>
          prevQuestions.map((question) => {
            if (question.id !== id) {
              return question;
            }

            return {
              ...question,
              savedQuestion: {
                ...option,
                question: existingQuestion?.question,
                duration: existingQuestion?.duration,
              },
            };
          })
        );
      } catch (error) {
        console.log(error);
      }
    },
    [setVrQuestions]
  );

  const setDuration = useCallback(
    (id, duration) => {
      setVrQuestions((prevQuestions) =>
        prevQuestions.map((question) => {
          if (question.id !== id) {
            return question;
          }
          return { ...question, duration };
        })
      );
    },
    [setVrQuestions]
  );

  const setTitle = useCallback(
    (id, title) => {
      setVrQuestions((prevQuestions) =>
        prevQuestions.map((question) => {
          if (question.id !== id) {
            return question;
          }
          return { ...question, title };
        })
      );
    },
    [setVrQuestions]
  );

  const setQuestion = useCallback(
    (id, question) => {
      setVrQuestions((prevQuestions) =>
        prevQuestions.map((qstn) => {
          if (qstn.id !== id) {
            return qstn;
          }
          return { ...qstn, question };
        })
      );
    },
    [setVrQuestions]
  );

  return (
    <div>
      {vrQuestions?.map((question) => (
        <Question
          key={question.id}
          vrQuestions={vrQuestions}
          question={question}
          onDelete={deleteQuestion}
          value={question.savedQuestion}
          setOption={setOption}
          setDuration={setDuration}
          setQuestion={setQuestion}
          setTitle={setTitle}
          areVrQuestionsValid={areVrQuestionsValid}
          isTriedToSubmit={isTriedToSubmit}
          isJobPostForm={isJobPostForm}
        />
      ))}
    </div>
  );
}
