/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Report from './Report';
import InfoIcon from '../../../../InfoIcon';

import classes from './styles.module.scss';

export default function ReportList({
  listTitle,
  listInfo,
  reports,
  refetch,
  activeReport,
  setActiveReport,
  editable,
  downloadReport,
  printReport,
}) {
  const [isListVisible, setIsListVisible] = useState(false);

  const { t } = useTranslation();

  return (
    <div className={classes.ReportList}>
      <div
        className={classes.listTitle}
        onClick={() => setIsListVisible(!isListVisible)}
      >
        <h2>{listTitle}</h2>
        <div className={classes.infoIconContainer}>
          <InfoIcon
            infoWidth={156}
            info={listInfo}
            tooltipDirection="bottom-left"
          />
          <span
            className={classNames(classes.arrow, {
              [classes.arrowUp]: isListVisible,
            })}
          />
        </div>
      </div>
      {isListVisible &&
        (reports?.length ? (
          <ul>
            {reports.map((report) => (
              <Report
                reportSection={listTitle}
                report={report}
                key={report.title}
                isEditable={editable}
                activeReport={activeReport}
                setActiveReport={setActiveReport}
                refetch={refetch}
                downloadReport={downloadReport}
                printReport={printReport}
              />
            ))}
          </ul>
        ) : (
          <span className={classes.noData}>
            {t(
              'dashboardComponents.Reports.LeftPanel.ReportList.noSavedReportsFound'
            )}{' '}
            <br />
            {t(
              'dashboardComponents.Reports.LeftPanel.ReportList.saveReportOrUpdate'
            )}
          </span>
        ))}
    </div>
  );
}
