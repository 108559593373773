/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import UserService from '../../../../../../services/UserService';
import AddComment from './AddComment';
import Comment from './Comment';
import classes from './styles.module.scss';

function sortByParentId(array, parentId = null, step = 0) {
  const result = [];

  for (const obj of array) {
    if (obj.parentId === parentId) {
      const commentStep = step + 1;
      const children = sortByParentId(array, obj.id, commentStep);
      if (children.length > 0) {
        obj.children = children;
      }
      obj.left = commentStep;
      result.push(obj);
    }
  }

  return result;
}

export default function Comments({ commnts, feedPost, refetch, scrollable }) {
  const [userReply, setUserReply] = useState(null);
  const [filteredComments, setFilteredComments] = useState([]);

  const commentsRef = useRef(null);

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  useEffect(() => {
    setFilteredComments(sortByParentId(commnts));
  }, [commnts]);

  return (
    <div className={classes.Comments} ref={commentsRef}>
      {!!filteredComments.length && (
        <div
          className={classes.commentsContainer}
          style={{
            maxHeight: scrollable && '300px',
            overflowY: scrollable && 'auto',
            overflowX: scrollable && 'auto',
          }}
        >
          {filteredComments.map((comment) => {
            return (
              <Comment
                comment={comment}
                key={comment.id}
                feedPost={feedPost}
                refetch={refetch}
                userReply={userReply}
                setUserReply={setUserReply}
                user={user}
              />
            );
          })}
        </div>
      )}
      <AddComment
        feedPost={feedPost}
        refetch={refetch}
        userReply={userReply}
        setUserReply={setUserReply}
      />
    </div>
  );
}
