export const convertTo12HourFormat = (timeString) => {
  if (!timeString) {
    return '';
  }

  const [hours, minutes] = timeString.split(':');
  let newHours = parseInt(hours, 10);
  const modifier = newHours >= 12 ? 'PM' : 'AM';

  if (newHours === 0) {
    newHours = 12;
  } else if (newHours > 12) {
    newHours -= 12;
  }

  return `${String(newHours).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0'
  )} ${modifier}`;
};

export const addOneHour = (timeStringIn24HoursFormat) => {
  if (!timeStringIn24HoursFormat) {
    return '';
  }

  const timeString = convertTo12HourFormat(timeStringIn24HoursFormat);

  const [time, modifier] = timeString.split(' ');
  const [hours, minutes] = time.split(':');
  let newHours = parseInt(hours, 10);
  let newMinutes = parseInt(minutes, 10) + 60;

  if (newMinutes >= 60) {
    newHours += 1;
    newMinutes -= 60;
  }

  if (newHours > 12) {
    newHours -= 12;
  } else if (newHours === 0) {
    newHours = 12;
  }

  const newModifier = modifier === 'PM' && newHours < 12 ? 'PM' : 'AM';

  return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(
    2,
    '0'
  )} ${newModifier}`;
};
