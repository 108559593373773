/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import React from 'react';
import classes from './styles.module.scss';

const colors = {
  red: [0.5, 1, 1.5, 2],
  orange: [2.5, 3],
  green: [3.5, 4],
  blue: [4.5, 5],
};

export default function Stars({ stars, setStars }) {
  const handleChange = (event) => {
    setStars(+event.target.value);
  };

  return (
    <div
      className={classNames(classes.stars, {
        [classes.blue]: colors.blue.includes(stars),
        [classes.green]: colors.green.includes(stars),
        [classes.orange]: colors.orange.includes(stars),
        [classes.red]: colors.red.includes(stars),
      })}
    >
      <input
        type="radio"
        name="rating"
        value="5"
        id="5"
        onChange={(event) => handleChange(event)}
        checked={stars === 5}
      />
      <label htmlFor="5" className={classes.star} />
      <input
        type="radio"
        name="rating"
        value="4.5"
        id="4.5"
        onChange={(event) => handleChange(event)}
        checked={stars === 4.5}
      />
      <label htmlFor="4.5" className={classes.halfStar} />

      <input
        type="radio"
        name="rating"
        value="4"
        id="4"
        onChange={(event) => handleChange(event)}
        checked={stars === 4}
      />
      <label htmlFor="4" className={classes.star} />
      <input
        type="radio"
        name="rating"
        value="3.5"
        id="3.5"
        onChange={(event) => handleChange(event)}
        checked={stars === 3.5}
      />
      <label htmlFor="3.5" className={classes.halfStar} />

      <input
        type="radio"
        name="rating"
        value="3"
        id="3"
        onChange={(event) => handleChange(event)}
        checked={stars === 3}
      />
      <label htmlFor="3" className={classes.star} />
      <input
        type="radio"
        name="rating"
        value="2.5"
        id="2.5"
        onChange={(event) => handleChange(event)}
        checked={stars === 2.5}
      />
      <label htmlFor="2.5" className={classes.halfStar} />

      <input
        type="radio"
        name="rating"
        value="2"
        id="2"
        onChange={(event) => handleChange(event)}
        checked={stars === 2}
      />
      <label htmlFor="2" className={classes.star} />
      <input
        type="radio"
        name="rating"
        value="1.5"
        id="1.5"
        onChange={(event) => handleChange(event)}
        checked={stars === 1.5}
      />
      <label htmlFor="1.5" className={classes.halfStar} />

      <input
        type="radio"
        name="rating"
        value="1"
        id="1"
        onChange={(event) => handleChange(event)}
        checked={stars === 1}
      />
      <label htmlFor="1" className={classes.star} />
      <input
        type="radio"
        name="rating"
        value="0.5"
        id="0.5"
        onChange={(event) => handleChange(event)}
        checked={stars === 0.5}
      />
      <label htmlFor="0.5" className={classes.halfStar} />
    </div>
  );
}
