import React from 'react';
import Answer from './Answer';

import classes from './styles.module.scss';

export default function Question({
  index,
  question,
  questionsAnswers,
  setQuestionsAnswers,
  hasVoted,
  votes,
  isPollClosed,
}) {
  const answerQuestion = (answer) => {
    setQuestionsAnswers((prevAnswers) => {
      return { ...prevAnswers, [question.id]: { optionIds: [answer.id] } };
    });
  };

  const toggleAnswerQuestion = (answer) => {
    setQuestionsAnswers((prevAnswers) => {
      const prevAnswer = prevAnswers[question.id];
      if (!prevAnswer) {
        return { ...prevAnswers, [question.id]: { optionIds: [answer.id] } };
      }

      const optionIds = prevAnswer.optionIds.includes(answer.id)
        ? prevAnswer.optionIds.filter((id) => id !== answer.id)
        : [...prevAnswer.optionIds, answer.id];

      return { ...prevAnswers, [question.id]: { optionIds } };
    });
  };

  const voteCount = Object.values(votes || {}).reduce(
    (acc, curr) => acc + curr,
    0
  );

  return (
    <div className={classes.Question}>
      <div className={classes.header}>
        <h4>Q.{index + 1}</h4>
      </div>
      <p className={classes.questionTitle}>{question.title}</p>
      <div className={classes.answers}>
        {question.options
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((answer, idx) => (
            <Answer
              votes={votes?.[answer.id]}
              voteCount={voteCount}
              hasVoted={hasVoted}
              isPollClosed={isPollClosed}
              key={answer.id}
              answer={answer}
              index={idx}
              allowsMultipleAnswers={question.allowsMultipleAnswers}
              answerQuestion={
                question.allowsMultipleAnswers
                  ? toggleAnswerQuestion
                  : answerQuestion
              }
              isActive={questionsAnswers[question.id]?.optionIds.includes(
                answer.id
              )}
            />
          ))}
      </div>
    </div>
  );
}
