import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import TextInput from '../../../../Form/TextInput';
import SidePopup from '../../SidePopup';

import useSwitchableData from '../../../../../hooks/useSwitchableData';
import { UiContext } from '../../../../../context/UiContext';
import AdminService from '../../../../../services/AdminService';
import classes from './styles.module.scss';

export default function ViewUser({
  isVisible,
  handleClose,
  users,
  currentUserId,
  refetch,
  setIsEditUserVisible,
  setSelectedRows,
  isOverlayed,
}) {
  const { showModal, showUnknownErrorModal, setIsFetching } =
    useContext(UiContext);

  const { t } = useTranslation();

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableData(users, currentUserId);

  const deleteUser = () => {
    showModal({
      icon: 'delete',
      title: t(
        'dashboardComponents.Console.UserManagement.ViewUser.deleteConfirmation.title'
      ),
      text: t(
        'dashboardComponents.Console.UserManagement.ViewUser.deleteConfirmation.text'
      ),
      onConfirm: async () => {
        try {
          setIsFetching(true);
          await AdminService.deleteUser({
            id: users?.[currentDataIndex].id,
          });

          await refetch();
          handleClose();
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
        } finally {
          setIsFetching(false);
        }
      },
      onCancel: () => {},
      dismissButtonLabel: 'No',
      confirmButtonLabel: 'Yes',
    });
  };

  const editUser = () => {
    setSelectedRows([users[currentDataIndex]?.id]);
    setIsEditUserVisible(true);
  };

  return (
    <SidePopup
      leftButtonVariant="delete"
      isVisible={isVisible}
      handleClose={handleClose}
      title={t('dashboardComponents.Console.UserManagement.ViewUser.title')}
      isOverlayed={isOverlayed}
      leftButtonLabel="Delete"
      onLeftButtonClick={deleteUser}
      rightButtonLabel="Edit"
      onRightButtonClick={editUser}
      switchNext={users?.length > 1 && switchToNextDataElement}
      switchPrev={users?.length > 1 && switchToPrevDataElement}
    >
      <div className={classes.ViewUser}>
        <div className={classes.colsContainer}>
          <div className={classes.col}>
            <TextInput
              label={t(
                'dashboardComponents.Console.UserManagement.ViewUser.labels.firstName'
              )}
              readonly
              height={50}
              value={users?.[currentDataIndex]?.userProfile.firstName}
            />
            <TextInput
              label={t(
                'dashboardComponents.Console.UserManagement.ViewUser.labels.email'
              )}
              readonly
              height={50}
              value={users?.[currentDataIndex]?.userName}
            />
            <TextInput
              label={t(
                'dashboardComponents.Console.UserManagement.ViewUser.labels.city'
              )}
              value={users?.[currentDataIndex]?.cities
                .map((city) => city.name)
                .join(', ')}
              readonly
              height={50}
            />
          </div>
          <div className={classes.col}>
            <TextInput
              label={t(
                'dashboardComponents.Console.UserManagement.ViewUser.labels.lastName'
              )}
              readonly
              height={50}
              value={users?.[currentDataIndex]?.userProfile.lastName}
            />
            <TextInput
              label={t(
                'dashboardComponents.Console.UserManagement.ViewUser.labels.restaurant'
              )}
              readonly
              height={50}
              value={users?.[currentDataIndex]?.locations
                .map((loc) => loc.name)
                .join(', ')}
            />
          </div>
        </div>
        <div className={classes.row}>
          {users?.[currentDataIndex]?.roles.map?.((role, index) => (
            <>
              <div key={role.id}>
                {index === 0 && (
                  <div className={classes.label}>
                    {t(
                      'dashboardComponents.Console.UserManagement.ViewUser.labels.role'
                    )}
                  </div>
                )}
                <div className={classes.role}>{role.name}</div>
              </div>
            </>
          ))}
        </div>
      </div>
    </SidePopup>
  );
}
