import React, { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import AllCandidates from './AllCandidates';
import RecentlyViewed from './RecentlyViewed';
import CandidatesListHeader from './CandidatesListHeader';
import AddCandidate from './AddCandidate';
import Candidate from '../../Candidate';

import { useUiStore } from '../../../../store';
import useDebouncedSearchWithHistory from '../../../../hooks/useDebouncedSearchWithHistory';
import AdminService from '../../../../services/AdminService';
import CandidatesService from '../../../../services/CandidatesService';
import classes from './styles.module.scss';

export default function CandidatesList() {
  const [activeSortOption, setActiveSortOption] = useState({});
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [isFullScreenModeEnabled, setIsFullScreenModeEnabled] = useState(true);
  const [isCandidatePopupVisible, setIsCandidatePopupVisible] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const [view, setView] = useState('list');
  const [isEdit, setIsEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchParams, setSearchParams] = useSearchParams();

  const isAddCandidatePopupVisible = useUiStore(
    (state) => state.isAddCandidatePopupVisible
  );
  const toggleAddCndidatePopup = useUiStore(
    (state) => state.toggleAddCndidatePopup
  );

  const { debouncedSearchTerm, searchHistory } = useDebouncedSearchWithHistory(
    searchTerm,
    'candidates'
  );

  const { data: candidates, refetch: refetchCandidates } = useQuery({
    queryKey: [
      'candidates',
      activeSortOption,
      debouncedSearchTerm,
      sortOrder,
      currentPage,
    ],
    queryFn: () =>
      CandidatesService.getCandidates({
        term: debouncedSearchTerm,
        orderBy: activeSortOption.value,
        sortOrder,
        pageNumber: currentPage,
      }),
    keepPreviousData: true,
  });

  const { data: viewedCandidatesData } = useQuery({
    queryKey: ['viewedCandidates'],
    queryFn: AdminService.getCandidates,
    keepPreviousData: true,
  });

  const toggleCandidateSelection = (candidateId) => {
    setSelectedRows((prevRows) => {
      if (prevRows.includes(candidateId)) {
        return prevRows.filter((row) => row !== candidateId);
      }
      return [...prevRows, candidateId];
    });
  };

  const recentlyViewedCandidates = viewedCandidatesData?.filter(
    (candidate) => candidate.viewedAt
  );

  const showAddCandidatePopup = (shouldEdit) => {
    if (shouldEdit) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }

    toggleAddCndidatePopup(true);
  };

  useEffect(() => {
    if (searchParams.get('candidate')) {
      setCurrentCandidate(
        candidates?.find(({ id }) => id === +searchParams.get('candidate'))
      );
      setIsCandidatePopupVisible(true);
    }
  }, [candidates, searchParams]);

  return (
    <div
      className={classNames(classes.CandidatesList, {
        [classes.tableView]: view === 'list',
        [classes.fullScreen]: isFullScreenModeEnabled,
      })}
    >
      <div className={classes.headerContainer}>
        <CandidatesListHeader
          totalCount={candidates?.length}
          showAddCandidatePopup={showAddCandidatePopup}
        />
      </div>
      {recentlyViewedCandidates?.length ? (
        <div
          className={classes.recentylViewedContainer}
          style={{ display: isFullScreenModeEnabled ? 'none' : 'block' }}
        >
          <RecentlyViewed
            candidates={recentlyViewedCandidates}
            setIsCandidatePopupVisible={setIsCandidatePopupVisible}
            setCurrentCandidate={setCurrentCandidate}
          />
        </div>
      ) : null}
      <div className={classes.allCandidatesContainer}>
        <div className={classes.innerContainer}>
          <AllCandidates
            showAddCandidatePopup={showAddCandidatePopup}
            isFullScreenModeEnabled={isFullScreenModeEnabled}
            setIsFullScreenModeEnabled={setIsFullScreenModeEnabled}
            setSortOrder={setSortOrder}
            sortOrder={sortOrder}
            // sortOptions={sortOptions}
            candidates={candidates}
            refetch={refetchCandidates}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            toggleCandidateSelection={toggleCandidateSelection}
            activeSortOption={activeSortOption}
            setActiveSortOption={setActiveSortOption}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            searchHistory={searchHistory}
            isFullScreenButtonVisible={!!recentlyViewedCandidates?.length}
            setIsCandidatePopupVisible={setIsCandidatePopupVisible}
            setCurrentCandidate={setCurrentCandidate}
            view={view}
            setView={setView}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={candidates?.totalPages}
          />
        </div>
      </div>
      <Candidate
        isVisible={isCandidatePopupVisible}
        setIsVisible={() => {
          setIsCandidatePopupVisible();
          setCurrentCandidate(null);
          setSearchParams({});
        }}
        refetch={refetchCandidates}
        candidate={currentCandidate}
        setCurrentCandidate={setCurrentCandidate}
        candidates={candidates}
      />
      <AddCandidate
        showAddCandidatePopup={showAddCandidatePopup}
        isVisible={isAddCandidatePopupVisible}
        setIsVisible={toggleAddCndidatePopup}
        candidates={
          isEdit
            ? candidates.filter((candidate) =>
                selectedRows.includes(candidate.id)
              )
            : []
        }
        refetch={refetchCandidates}
      />
    </div>
  );
}
