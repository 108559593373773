import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import MoveButton from '../CalendarInfo/MoveButton';
import EventCard from '../EventCard';
import MonthPicker from './MonthPicker';
import classes from './styles.module.scss';
import YearPicker from './YearPicker';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

function getDaysInMonth(month, year) {
  const date = new Date(year, month, 1);
  const dayList = [];

  while (date.getMonth() === month) {
    dayList.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dayList;
}

export default function CalendarForm({
  currentDate,
  setCurrentDate,
  setActiveType,
  isType,
  dateToday,
  events,
  isPopup,
  user,
  refetch,
  onClick,
  calendarIconRef,
}) {
  const [viewDate, setViewDate] = useState(new Date(currentDate));
  const [isYearPicked, setIsYearPicked] = useState(false);
  const [isMonthPicked, setIsMonthPicked] = useState(false);

  const calendarRef = useRef();

  useOnClickOutside(calendarRef, () => onClick?.(), calendarIconRef);

  useEffect(() => {
    setViewDate(currentDate);
  }, [currentDate]);

  const getPrevMonth = () => {
    setViewDate((prev) => new Date(prev.getFullYear(), prev.getMonth() - 1));
  };

  const getNextMonth = () => {
    setViewDate((prev) => new Date(prev.getFullYear(), prev.getMonth() + 1));
  };

  const handleClickOnDay = (day) => {
    setCurrentDate(day);
    setActiveType('Day');
  };

  let prevMonthDays = [];
  let nextMonthDays = [];

  if (viewDate?.getMonth() - 1 === -1) {
    prevMonthDays = getDaysInMonth(11, viewDate?.getFullYear() - 1);
  } else {
    prevMonthDays = getDaysInMonth(
      viewDate?.getMonth() - 1,
      viewDate?.getFullYear()
    );
  }

  if (viewDate?.getMonth() + 1 === 12) {
    nextMonthDays = getDaysInMonth(0, viewDate?.getFullYear() + 1);
  } else {
    nextMonthDays = getDaysInMonth(
      viewDate?.getMonth() + 1,
      viewDate?.getFullYear()
    );
  }

  let days = getDaysInMonth(viewDate?.getMonth(), viewDate?.getFullYear());

  if (isType) {
    switch (days[0]?.getDay()) {
      case 0:
        break;
      case 1:
        days = [...prevMonthDays.slice(-1), ...days];
        break;
      case 2:
        days = [...prevMonthDays.slice(-2), ...days];
        break;
      case 3:
        days = [...prevMonthDays.slice(-3), ...days];
        break;
      case 4:
        days = [...prevMonthDays.slice(-4), ...days];
        break;
      case 5:
        days = [...prevMonthDays.slice(-5), ...days];
        break;
      case 6:
        days = [...prevMonthDays.slice(-6), ...days];
        break;
      default:
        break;
    }

    switch (days[days.length - 1]?.getDay()) {
      case 2:
        days = [...days, ...nextMonthDays.slice(0, 4)];
        break;
      case 3:
        days = [...days, ...nextMonthDays.slice(0, 3)];
        break;
      case 4:
        days = [...days, ...nextMonthDays.slice(0, 2)];
        break;
      case 5:
        days = [...days, ...nextMonthDays.slice(0, 1)];
        break;
      case 6:
        break;
      case 1:
        days = [...days, ...nextMonthDays.slice(0, 5)];
        break;
      case 0:
        days = [...days, ...nextMonthDays.slice(0, 6)];
        break;
      default:
        break;
    }
  } else {
    switch (days[0]?.getDay()) {
      case 0:
        days = [...prevMonthDays.slice(-6), ...days];
        break;
      case 1:
        break;
      case 2:
        days = [...prevMonthDays.slice(-1), ...days];
        break;
      case 3:
        days = [...prevMonthDays.slice(-2), ...days];
        break;
      case 4:
        days = [...prevMonthDays.slice(-3), ...days];
        break;
      case 5:
        days = [...prevMonthDays.slice(-4), ...days];
        break;
      case 6:
        days = [...prevMonthDays.slice(-5), ...days];
        break;
      default:
        break;
    }

    switch (days[days.length - 1]?.getDay()) {
      case 2:
        days = [...days, ...nextMonthDays.slice(0, 5)];
        break;
      case 3:
        days = [...days, ...nextMonthDays.slice(0, 4)];
        break;
      case 4:
        days = [...days, ...nextMonthDays.slice(0, 3)];
        break;
      case 5:
        days = [...days, ...nextMonthDays.slice(0, 2)];
        break;
      case 6:
        days = [...days, ...nextMonthDays.slice(0, 1)];
        break;
      case 1:
        days = [...days, ...nextMonthDays.slice(0, 6)];
        break;
      case 0:
      default:
        break;
    }
  }

  return !isType ? (
    <div
      className={classes.calendar}
      style={{
        position: isPopup ? 'absolute' : 'relative',
        top: isPopup ? '60px' : '',
        right: isPopup ? '0' : '',
        zIndex: isPopup ? '1' : 'auto',
      }}
      ref={calendarRef}
    >
      <div className={classes.header}>
        <div
          className={classes.prev}
          onClick={getPrevMonth}
          role="button"
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              getPrevMonth();
            }
          }}
        />
        <div className={classes.monthYearButtons}>
          <div
            className={classNames(classes.container, {
              [classes.isActive]: isMonthPicked,
            })}
            onClick={() => {
              setIsMonthPicked(!isMonthPicked);
              setIsYearPicked(false);
            }}
            role="button"
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setIsMonthPicked(!isMonthPicked);
                setIsYearPicked(false);
              }
            }}
          >
            <div
              className={classNames(classes.month, {
                [classes.isActive]: isMonthPicked,
              })}
            >
              {`${viewDate?.toLocaleString('en-US', {
                month: 'long',
              })}`}
            </div>
          </div>
          <div
            className={classNames(classes.container, {
              [classes.isActive]: isYearPicked,
            })}
            onClick={() => {
              setIsYearPicked(!isYearPicked);
              setIsMonthPicked(false);
            }}
            role="button"
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setIsYearPicked(!isYearPicked);
                setIsMonthPicked(false);
              }
            }}
          >
            <div
              className={classNames(classes.year, {
                [classes.isActive]: isYearPicked,
              })}
            >
              {viewDate?.getFullYear()}
            </div>
          </div>
        </div>
        <div
          className={classes.next}
          onClick={getNextMonth}
          role="button"
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              getNextMonth();
            }
          }}
        />
      </div>
      {isYearPicked && (
        <YearPicker
          viewDate={viewDate}
          setViewDate={setViewDate}
          handleClose={() => setIsYearPicked(false)}
        />
      )}
      {isMonthPicked && (
        <MonthPicker
          viewDate={viewDate}
          setViewDate={setViewDate}
          handleClose={() => setIsMonthPicked(false)}
        />
      )}

      <div className={classes.days}>
        {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map((day) => (
          <div className={classes.day} key={day}>
            {day}
          </div>
        ))}
      </div>

      <div className={classes.calendarMonth}>
        {days.map((day) => {
          const date = day.getDate();
          const month = day.getMonth();
          const year = day.getFullYear();
          const hasEvent = events?.some((event) => {
            const eventDate = new Date(event.eventDate);

            return (
              eventDate.getDate() === date &&
              eventDate.getMonth() === month &&
              eventDate.getFullYear() === year
            );
          });

          return (
            <div
              className={classNames(classes.day, {
                [classes.selected]:
                  date === currentDate.getDate() &&
                  month === currentDate.getMonth() &&
                  year === currentDate.getFullYear(),
                [classes.today]:
                  date === dateToday.getDate() &&
                  month === dateToday.getMonth() &&
                  year === dateToday.getFullYear(),
                [classes.anotherMonth]:
                  prevMonthDays.slice(-6).includes(day) ||
                  nextMonthDays.slice(0, 6).includes(day),
                [classes.hasEvent]: hasEvent,
              })}
              onClick={() => {
                setCurrentDate(day);
                onClick?.();
              }}
              key={day}
            >
              {day.getDate()}
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div className={classNames(classes.calendar, { [classes.isMode]: isType })}>
      <div className={classes.header}>
        <div className={classes.date}>
          <span className={classes.month}>
            {`${viewDate.toLocaleString('en-US', {
              month: 'long',
            })}`}
          </span>
          <span className={classes.year}>{viewDate.getFullYear()}</span>
        </div>
        <div className={classes.buttons}>
          <MoveButton prev handleClick={getPrevMonth} />
          <MoveButton next handleClick={getNextMonth} />
        </div>
      </div>
      <div className={classes.flexItem}>
        <div className={classes.days}>
          {[
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ].map((day) => (
            <div className={classes.day} key={day}>
              {day}
            </div>
          ))}
        </div>

        <div className={classes.calendarMonth}>
          {days.map((day) => {
            const date = day.getDate();
            const month = day.getMonth();
            const year = day.getFullYear();
            const eventsToday = events.filter((event) => {
              const eventDate = new Date(event.eventDate);

              return (
                eventDate.getDate() === date &&
                eventDate.getMonth() === month &&
                eventDate.getFullYear() === year
              );
            });

            return (
              <div
                className={classNames(classes.day, {
                  [classes.selected]:
                    date === currentDate.getDate() &&
                    month === currentDate.getMonth() &&
                    year === currentDate.getFullYear(),
                  [classes.today]:
                    date === dateToday.getDate() &&
                    month === dateToday.getMonth() &&
                    year === dateToday.getFullYear(),
                  [classes.anotherMonth]:
                    prevMonthDays.slice(-6).includes(day) ||
                    nextMonthDays.slice(0, 6).includes(day),
                })}
                onClick={() => handleClickOnDay(day)}
                key={day}
              >
                <div className={classes.flexContainer}>
                  <span className={classes.dayNumber}>{date}</span>
                  {!!eventsToday.length && (
                    <span className={classes.eventAmount}>
                      {eventsToday.length}
                    </span>
                  )}
                </div>
                {!!eventsToday.length && (
                  <EventCard
                    mode="MONTH"
                    events={eventsToday}
                    user={user}
                    refetch={refetch}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
