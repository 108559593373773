/* eslint-disable no-shadow */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Template from '../Template';

export default function Offer({
  deleteNotification,
  isRead,
  notification,
  classes,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const createNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const goToJob = () => {
      navigate(
        `/tools/jobs/my-jobs/${notification.jobApplication?.jobPost?.id}`
      );
      hideNotifications();
    };

    const content = {
      'Application pending offer more than 5 days': {
        title: t(
          'dashboardComponents.Notifications.Notification.Offer.actionOfferPending'
        ),
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Offer.review'
        ),
        onClick: goToJob,
      },
      OfferDeclined: {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Offer.offerDeclined'
        )} ${notification.jobApplication?.jobPost?.jobTitle} (${
          notification.jobApplication?.jobPost?.id
        })`,
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: '',
      },
      OfferCountered: {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Offer.offerCountered'
        )} ${notification.jobApplication?.jobPost?.jobTitle} (${
          notification.jobApplication?.jobPost?.id
        })`,
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Offer.review'
        ),
        onClick: goToJob,
      },
    };

    return (
      content[notification.eventNote] ||
      content[notification.name] || {
        title: notification.eventNote,
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Offer.review'
        ),
        onClick: goToJob,
      }
    );
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
