/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import classes from './styles.module.scss';

export default function JobsDropdown({
  jobs,
  jobApplications,
  setSelectedJob,
  selectedJob,
  handleClose,
  setFieldValue,
}) {
  const { t } = useTranslation();

  return (
    <>
      <span className={classes.disabled}>
        {t(
          'dashboardComponents.Calendar.EventDetails.Interview.RecruiterInterview.CandidatesDropDown.JobsDropdown.selectJob'
        )}
      </span>
      {jobs?.map((job) => {
        const visibleJob = `${job?.id} - ${job?.jobTitle} - ${
          jobApplications?.filter(
            (application) => application?.jobPost?.id === job?.id
          ).length
        }`;
        return (
          <li
            key={job?.id}
            onClick={() => {
              setSelectedJob(job);
              setFieldValue('job', job);
              handleClose();
            }}
            className={classNames({
              [classes.active]: selectedJob?.id === job?.id,
            })}
          >
            {visibleJob}
          </li>
        );
      })}
    </>
  );
}
