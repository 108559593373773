import React from 'react';

import classNames from 'classnames';

import InfoIcon from '../../../../../../../InfoIcon';

import classes from './styles.module.scss';

export default function SectionTitle({
  title,
  info,
  infoWidth,
  note,
  column,
  style,
}) {
  return (
    <div
      className={classNames(classes.SectionTitle, {
        [classes.column]: column,
      })}
    >
      <h1 style={style}>{title}</h1>{' '}
      {note && <span className={classes.note}>({note})</span>}
      {info && (
        <div className={classes.info}>
          <InfoIcon
            info={info}
            direction="bottom-right"
            infoWidth={infoWidth}
          />
        </div>
      )}
    </div>
  );
}
