import React from 'react';

import { useTranslation } from 'react-i18next';

import AddOrEditJobTemplate from './AddOrEditJobTemplate';
import ViewJobTemplate from './ViewJobTemplate';
import AdminService from '../../../../../services/AdminService';
import TableView from '../../TableView';

export default function JobTemplates({ setItemCount }) {
  const { t } = useTranslation();

  const columns = [
    {
      name: t(
        'dashboardComponents.Console.JobManagement.JobTemplates.JobTemplateName'
      ),
      field: 'templateName',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.JobManagement.JobTemplates.JobTitle'
      ),
      field: 'jobTitle',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.JobManagement.JobTemplates.SortOrder'
      ),
      field: 'templateSortOrder',
      sortable: true,
    },
    {
      name: t('dashboardComponents.Console.JobManagement.JobTemplates.Status'),
      field: 'templateStatus',
      sortable: true,
    },
    {
      name: t('dashboardComponents.Console.JobManagement.JobTemplates.Notes'),
      field: 'note',
      sortable: true,
    },
    {
      name: t('common.createdOn'),
      field: 'createdAt',
      sortable: true,
      type: 'date',
    },
  ];

  return (
    <TableView
      setItemCount={setItemCount}
      dataName={t(
        'dashboardComponents.Console.JobManagement.JobTemplates.JobTemplate'
      )}
      getDataHandler={AdminService.getJobPostTemplates}
      deleteDataHandler={AdminService.deleteJobPostTemplate}
      createDataHandler={AdminService.createJobPostTemplate}
      updateDataHandler={AdminService.updateJobPostTemplate}
      viewLeftColumnFields={[]}
      viewRightColumnFields={[]}
      formLeftColumnFields={[]}
      formRightColumnFields={[]}
      columns={columns}
      viewComponent={ViewJobTemplate}
      addOrEditComponent={AddOrEditJobTemplate}
    />
  );
}
