/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Card from './Card';

import classes from './styles.module.scss';
import AdminService from '../../../../services/AdminService';
import UserService from '../../../../services/UserService';
import CandidateModal from '../CandidateModal';

function compareDate(a, b) {
  return new Date(b.createdAt) - new Date(a.createdAt);
}

export default function RecentlyActivities() {
  const [cards, setCards] = useState([]);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const [isCandidateModalVisible, setIsCandidateModalVisible] = useState(false);

  const cardsContainerRef = useRef(null);
  const cardsRef = useRef(null);

  const { t } = useTranslation();

  const { data: user } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  const { data: candidates, refetch: refetchCandidates } = useQuery({
    queryKey: ['candidates'],
    queryFn: () =>
      AdminService.getCandidates({
        term: '',
        orderBy: 'createdAt',
        sortOrder: 'desc',
      }),
    keepPreviousData: true,
  });

  const { data: jobs, refetch: refetchJobs } = useQuery({
    queryKey: ['jobs'],
    queryFn: () =>
      AdminService.getJobPosts({
        term: '',
        orderBy: 'createdAt',
        sortOrder: 'desc',
      }),
    keepPreviousData: true,
  });

  const refetchData = useCallback(() => {
    refetchJobs();
    refetchCandidates();
  }, [refetchJobs, refetchCandidates]);

  useEffect(() => {
    setCurrentCandidate(
      candidates?.find((cand) => cand?.id === currentCandidate?.id)
    );
  }, [candidates, currentCandidate?.id]);

  useEffect(() => {
    if (!jobs?.data?.length) {
      return;
    }

    if (!candidates?.length) {
      setCards([...jobs.data]);
      return;
    }
    setCards(
      [...jobs?.data.slice(0, 10), ...candidates.slice(0, 10)].sort(compareDate)
    );
  }, [candidates, jobs]);

  const checkButtons = () => {
    setTimeout(() => {
      const scroll = cardsContainerRef?.current?.scrollLeft;

      if (cardsContainerRef.current.clientWidth >= 184 * cards.length) {
        setPrevDisabled(true);
        setNextDisabled(true);
      }
      if (scroll === 0) {
        setPrevDisabled(true);
      } else {
        setPrevDisabled(false);
      }

      if (
        !(
          cardsContainerRef.current.scrollLeft <
          cardsContainerRef.current.scrollWidth -
            cardsContainerRef.current.clientWidth
        )
      ) {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }
    }, 300);
  };

  const prevItem = () => {
    cardsContainerRef.current.scrollBy({ left: -184, behavior: 'smooth' });

    checkButtons();
  };
  const nextItem = () => {
    cardsContainerRef.current.scrollBy({ left: 184, behavior: 'smooth' });

    checkButtons();
  };

  const handleWheel = (event) => {
    event.preventDefault();
    cardsContainerRef.current.scrollBy({
      left: event.deltaY > 0 ? 184 : -184,
      behavior: 'smooth',
    });
    checkButtons();
  };

  useEffect(() => {
    if (!cardsContainerRef.current) {
      return null;
    }

    cardsContainerRef.current.addEventListener('wheel', handleWheel);

    return () => {
      cardsContainerRef.current.removeEventListener('wheel', handleWheel);
    };
  }, [cardsContainerRef.current]);

  const handleMouseDown = (event) => {
    event.preventDefault();
    const startX = event.clientX + cardsContainerRef.current.scrollLeft;

    const handleMouseMove = (e) => {
      e.preventDefault();
      cardsContainerRef.current.scrollLeft = startX - e.clientX;
      checkButtons();
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className={classes.RecentlyActivities}>
      <h2>
        {t('dashboardComponents.Discover.RecentlyActivities.Recent Activities')}
      </h2>
      <div className={classes.content}>
        {!cards?.length ? (
          <div className={classes.noData}>
            {t(
              'dashboardComponents.Discover.RecentlyActivities.You will see the new jobs here as they get'
            )}{' '}
            <br /> {t('dashboardComponents.Discover.RecentlyActivities.posted')}
            .
          </div>
        ) : (
          <>
            <div
              className={classes.container}
              ref={cardsContainerRef}
              onMouseDown={handleMouseDown}
            >
              <div className={classes.cards} ref={cardsRef}>
                {cards?.map((card) => (
                  <Card
                    card={card}
                    key={`${card?.id} ${card.createdAt}`}
                    setCurrentCandidate={setCurrentCandidate}
                    setIsCandidateModalVisible={setIsCandidateModalVisible}
                    refetch={refetchData}
                    user={user}
                  />
                ))}
              </div>
            </div>

            {!prevDisabled && (
              <span className={classes.prevCard} onClick={prevItem} />
            )}
            {!nextDisabled && (
              <span className={classes.nextCard} onClick={nextItem} />
            )}
          </>
        )}
      </div>
      <CandidateModal
        currentCandidate={currentCandidate}
        show={isCandidateModalVisible}
        handleClose={() => {
          setCurrentCandidate(null);
          setIsCandidateModalVisible(false);
        }}
        refetch={refetchData}
      />
    </div>
  );
}
