/* eslint-disable no-param-reassign */
import axios from 'axios';
// import { fetch } from 'whatwg-fetch';

import { getAuthData, clearAuthData } from './authStorage';
import { TENANT_ID } from '../constants/main';

// axios.defaults.baseURL = API_URL;

axios.interceptors.request.use(
  (config) => {
    const customConfig = { ...config };
    const { accessToken } = getAuthData();
    if (accessToken) {
      customConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    config.headers['X-Tenant-Id'] = TENANT_ID;
    return customConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    const currentURL = window.location.href;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      error.response.data?.message !== 'Wrong OTP' &&
      error.response.data?.message !== 'Wrong credentials'
    ) {
      clearAuthData();
      window.location.href = '/login';
      return Promise.reject(error);
    }

    if (error.response.status === 404 && !currentURL.includes('/404')) {
      window.location.href = '/404';
      return Promise.reject(error);
    }

    if (error.response.status === 502 && !currentURL.includes('/502')) {
      window.location.href = '/tools/502';
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

/* axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const { refreshToken, userId } = getAuthData();
      if (!refreshToken) {
        return Promise.reject(error);
      }
      return fetch(`${API_URL}/auth-token-refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refreshToken,
          userId,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.accessToken) {
            saveAuthData({
              accessToken: res.accessToken,
              refreshToken: res.refreshToken,
              userId: res.userId,
            });
            axios.defaults.headers.common.Authorization = `${
              getAuthData().accessToken
            }`;
            return axios(originalRequest);
          }
          return Promise.reject(res);
        })
        .catch((refreshTokenResponse) => {
          clearAuthData();

          document.location.href = '/sign-in';
          return Promise.reject(refreshTokenResponse);
        });
    }
    return Promise.reject(error);
  }
);
 */
