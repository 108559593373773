import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Tabs({ tabs, activeTab, setActiveTab, cleanUp }) {
  return (
    <div className={classes.Tabs}>
      {tabs.map((tab) => {
        return (
          <div
            tabIndex={0}
            className={classNames(classes.tab, {
              [classes.active]: tab === activeTab,
            })}
            role="tab"
            key={tab}
            onClick={() => {
              cleanUp();
              setActiveTab(tab);
            }}
          >
            {tab}
          </div>
        );
      })}
    </div>
  );
}
