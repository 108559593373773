import React, { useState, useEffect, useRef } from 'react';

import TextInput from '../../../../../../../../Form/TextInput';

import editIcon from '../../../../../../../../../assets/images/dashboard/editIcon.svg';
import classes from './styles.module.scss';

export default function Answer({
  deleteAnswer,
  alphabet,
  changeAnswer,
  value,
  dragAnswer,
  isDuplicate,
  setIsAnswerBeingDragged,
  onEnterPress,
  isEdit,
}) {
  const [isEditable, setIsEditable] = useState(false);

  const inputRef = useRef();

  useEffect(() => {
    if (isEdit) {
      setIsEditable(false);
    } else {
      setIsEditable(true);
    }
  }, [isEdit]);

  return (
    <div
      className={classes.Answer}
      draggable
      onDragStart={dragAnswer}
      onDragEnd={() => setIsAnswerBeingDragged(false)}
    >
      <div className={classes.prefix}>
        <svg
          width="11"
          height="20"
          viewBox="0 0 11 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="2" cy="2" r="2" fill="#C1C5D6" />
          <circle cx="9" cy="2" r="2" fill="#C1C5D6" />
          <circle cx="2" cy="10" r="2" fill="#C1C5D6" />
          <circle cx="9" cy="10" r="2" fill="#C1C5D6" />
          <circle cx="2" cy="18" r="2" fill="#C1C5D6" />
          <circle cx="9" cy="18" r="2" fill="#C1C5D6" />
        </svg>
        <span className={classes.alphabet}>{alphabet}.</span>
      </div>
      <div
        className={classes.inputContainer}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onEnterPress?.();
          }
        }}
      >
        <TextInput
          inputRef={inputRef}
          readonly={!isEditable}
          height={50}
          value={value}
          onChange={(event) => changeAnswer(event.target.value)}
          placeholder="Answer choice here"
          error={isDuplicate && 'Duplicate'}
          touched={isDuplicate}
        />
      </div>
      {isEdit && (
        <button
          className={classes.editIcon}
          type="button"
          onClick={() => {
            setIsEditable(true);
            inputRef.current.focus();
          }}
        >
          <img src={editIcon} alt="Edit" />
        </button>
      )}
      <i className={classes.deleteIcon} onClick={deleteAnswer}>
        Delete
      </i>
    </div>
  );
}
