import React, { useState, useContext, useMemo } from 'react';

import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { UiContext } from '../../context/UiContext';
import MultiDropdown from '../../components/Form/MultiDropdown';
import Button from '../../components/Button';
import TextInput from '../../components/Form/TextInput';
import AuthLayout from '../../containers/AuthLayout';
import classes from './styles.module.scss';
import UserService from '../../services/UserService';

export default function RequestAccessPage() {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const navigate = useNavigate();

  const { showUnknownErrorModal, showModal } = useContext(UiContext);

  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object({
        firstName: yup
          .string()
          .trim()
          .required(
            t('pages.RequestAccessPage.validationErrors.requiredField')
          ),
        lastName: yup
          .string()
          .trim()
          .required(
            t('pages.RequestAccessPage.validationErrors.requiredField')
          ),
        email: yup
          .string()
          .trim()
          .email(t('pages.RequestAccessPage.validationErrors.invalidEmail'))
          .required(
            t('pages.RequestAccessPage.validationErrors.requiredField')
          ),
        city: yup
          .array()
          .required(t('pages.RequestAccessPage.validationErrors.emptyCity')),
        restaurant: yup
          .array()
          .required(
            t('pages.RequestAccessPage.validationErrors.emptyRestaurant')
          ),
      }),
    [t]
  );

  const { data } = useQuery({
    queryKey: ['references'],
    queryFn: UserService.getReferences,
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      city: '',
      restaurant: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await UserService.requestAccess({
          email: values.email,
          firstName: values.firstName.replace(/\s/g, ''),
          lastName: values.lastName.replace(/\s/g, ''),
          locations: values.restaurant.map((rest) => rest.value),
          cities: values.city.map((city) => city.value),
        });
        navigate('/request-access/success');
      } catch (error) {
        if (error.response.data.message === 'Already have access') {
          showModal({
            title: t('pages.RequestAccessPage.duplicityErrorTitle'),
            text: t('pages.RequestAccessPage.duplicityErrorMessage'),
            dismissButtonVariant: 'modal',
          });

          return;
        }

        if (error.response.data.message === 'Duplicity') {
          showModal({
            title: t('pages.RequestAccessPage.pendingApprovalTitle'),
            text: t('pages.RequestAccessPage.pendingApprovalMessage'),
            onCancel: () => {},
            dismissButtonLabel: t('pages.RequestAccessPage.sendReminder'),
            dismissButtonVariant: 'modal',
          });
          return;
        }
        showUnknownErrorModal();
      }
    },
  });

  const isSubmitButtonDisabled =
    !formik.values.firstName ||
    !formik.values.lastName ||
    !formik.values.email ||
    !formik.values.city ||
    !formik.values.restaurant;

  return (
    <AuthLayout>
      <div className={classes.RequestAccessPage}>
        <Helmet>
          <title>{t('pages.RequestAccessPage.pageTitle')}</title>
        </Helmet>
        <div className={classes.container}>
          <h1>{t('pages.RequestAccessPage.header')}</h1>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.shortInputsContainer}>
              <div className={classes.inputContainer}>
                <TextInput
                  error={formik.errors.firstName}
                  touched={isTriedToSubmit}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label={t('pages.RequestAccessPage.nameLabel')}
                  name="firstName"
                  placeholder={t(
                    'pages.RequestAccessPage.firstNamePlaceholder'
                  )}
                  height={55}
                />
              </div>
              <div className={classes.inputContainer}>
                <TextInput
                  error={formik.errors.lastName}
                  touched={isTriedToSubmit}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label={<>&nbsp;</>}
                  name="lastName"
                  placeholder={t('pages.RequestAccessPage.lastNamePlaceholder')}
                  height={55}
                />
              </div>
            </div>
            <TextInput
              error={formik.errors.email}
              touched={isTriedToSubmit}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t('pages.RequestAccessPage.emailLabel')}
              name="email"
              placeholder={t('pages.RequestAccessPage.emailPlaceholder')}
              height={55}
            />
            <MultiDropdown
              error={formik.errors.city}
              touched={isTriedToSubmit}
              value={formik.values.city}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              label={t('pages.RequestAccessPage.cityLabel')}
              name="city"
              placeholder={t('pages.RequestAccessPage.cityPlaceholder')}
              height={55}
              width="100%"
              options={data?.cities.map((city) => ({
                label: city.name,
                value: city.id,
              }))}
              setFieldTouched={formik.setFieldTouched}
            />
            <MultiDropdown
              error={formik.errors.restaurant}
              touched={isTriedToSubmit}
              value={formik.values.restaurant}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              label={t('pages.RequestAccessPage.restaurantLabel')}
              name="restaurant"
              placeholder={t('pages.RequestAccessPage.restaurantPlaceholder')}
              height={55}
              width="100%"
              options={data?.locations.map((location) => ({
                label: location.name,
                value: location.id,
              }))}
              setFieldTouched={formik.setFieldTouched}
            />
            <Button
              onClick={() => {
                setIsTriedToSubmit(true);
                formik.handleSubmit();
              }}
              disabled={isSubmitButtonDisabled}
            >
              {t('pages.RequestAccessPage.requestButton')}
            </Button>
          </form>
        </div>
      </div>
    </AuthLayout>
  );
}
