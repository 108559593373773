/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Group from './Group';
import ReportList from './ReportList';
import classes from './styles.module.scss';

import candidatesReportIcon from '../../../../assets/images/dashboard/reports/filter/candidates.svg';
import jobsReportIcon from '../../../../assets/images/dashboard/reports/filter/jobs.svg';

import newApplicationsIcon from '../../../../assets/images/dashboard/reports/filter/new-applications.svg';
import assessmentIcon from '../../../../assets/images/dashboard/reports/filter/assessment.svg';
import interviewIcon from '../../../../assets/images/dashboard/reports/filter/interview.svg';
import offerIcon from '../../../../assets/images/dashboard/reports/filter/offer.svg';
import onboardingIcon from '../../../../assets/images/dashboard/reports/filter/onboarding.svg';
import joiningIcon from '../../../../assets/images/dashboard/reports/filter/joining.svg';

import hideIcon from '../../../../assets/images/dashboard/reports/hide.svg';
import Tooltip from '../../../Tooltip';

export default function LeftPanel({
  savedReports,
  refetch,
  activeReport,
  setActiveReport,
  isLeftPanelOpened,
  setIsLeftPanelOpened,
  downloadReport,
  printReport,
  reportTypes,
}) {
  const { t } = useTranslation();

  const groups = [
    {
      listTitle: t(
        'dashboardComponents.Reports.LeftPanel.groups.byCategory.listTitle'
      ),
      list: [
        {
          icon: candidatesReportIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byCategory.list.candidateReport.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byCategory.list.candidateReport.reportInfo'
          ),
          type: 'Candidates',
        },
        {
          icon: jobsReportIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byCategory.list.jobsReport.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byCategory.list.jobsReport.reportInfo'
          ),
          type: 'Jobs',
        },
      ],
      listInfo: t(
        'dashboardComponents.Reports.LeftPanel.groups.byCategory.listInfo'
      ),
    },
    {
      listTitle: t(
        'dashboardComponents.Reports.LeftPanel.groups.byEvent.listTitle'
      ),
      list: [
        {
          icon: newApplicationsIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.newApplicants.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.newApplicants.reportInfo'
          ),
          type: 'Applications',
        },
        {
          icon: assessmentIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.assessment.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.assessment.reportInfo'
          ),
          // type: 'Assessments',
        },
        {
          icon: interviewIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.interview.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.interview.reportInfo'
          ),
          type: 'Interviews',
        },
        {
          icon: offerIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.offer.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.offer.reportInfo'
          ),
          type: 'Offers',
        },
        {
          icon: onboardingIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.onboarding.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.onboarding.reportInfo'
          ),
          type: 'Onboardings',
        },
        {
          icon: joiningIcon,
          title: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.joining.title'
          ),
          reportInfo: t(
            'dashboardComponents.Reports.LeftPanel.groups.byEvent.list.joining.reportInfo'
          ),
          type: 'Joinings',
        },
      ],
      listInfo: t(
        'dashboardComponents.Reports.LeftPanel.groups.byEvent.listInfo'
      ),
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setActiveReport(groups[0].list[0]);
    }, 300);
  }, []);

  return (
    <div
      className={classNames(classes.LeftPanel, {
        [classes.hide]: !isLeftPanelOpened,
      })}
    >
      <div className={classes.header}>
        <span className={classes.title}>
          {t('dashboardComponents.Reports.LeftPanel.reports')}
        </span>
        <Tooltip
          text={t('dashboardComponents.Reports.LeftPanel.hideSidebar')}
          direction="bottom-left"
          width={120}
        >
          <button
            type="button"
            style={{
              backgroundImage: `url(${hideIcon})`,
            }}
            onClick={() => setIsLeftPanelOpened(false)}
          />
        </Tooltip>
      </div>
      <div className={classes.container}>
        {groups.map((group) => (
          <Group
            group={group}
            activeReport={activeReport}
            setActiveReport={setActiveReport}
            key={group.listTitle}
            downloadReport={downloadReport}
            printReport={printReport}
          />
        ))}
        <ReportList
          listTitle={t('dashboardComponents.Reports.LeftPanel.savedReport')}
          listInfo={t(
            'dashboardComponents.Reports.LeftPanel.seeYourSavedReportsHere'
          )}
          reports={savedReports?.filter(
            (report) =>
              !reportTypes
                ?.map((rep) => rep.split('.').pop())
                .includes(report.title)
          )}
          refetch={refetch}
          activeReport={activeReport}
          setActiveReport={setActiveReport}
          editable
          downloadReport={downloadReport}
          printReport={printReport}
        />
      </div>
    </div>
  );
}
