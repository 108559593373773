import React, { useState, useEffect, useRef, useContext } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '../../../../Button';
import FavStatus from '../../../Header/Search/FavStatus';
import DotMenu from '../../DotMenu';
import JobInfo from './JobInfo';

import { UiContext } from '../../../../../context/UiContext';
import {
  IMAGES_URL,
  JOB_APPLICATION_STATUSES,
} from '../../../../../constants/main';
import AdminService from '../../../../../services/AdminService';
import classes from './styles.module.scss';

export default function CandidateCard({
  favCandidate,
  refetch,
  favorite,
  job,
  setIsCandidatePopupVisible,
  setCurrentCandidate,
  borderRadius = 8,
}) {
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isInvitedJob = favCandidate.jobApplications.findLast(
    (application) => application.jobPost?.id === job?.id
  );

  const candidateCardRef = useRef();

  const { setIsFetching, showNotification } = useContext(UiContext);

  const { t } = useTranslation();

  const hasPhoto = favCandidate?.userProfile?.avatar;
  const note = favCandidate?.note;
  const inviteButtonDisabled = job?.status === 'UnPublished';

  function getCandidateCardStatus(candidate) {
    //  let status = candidate?.subStatus?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' ');

    if (!candidate?.subStatus) {
      return t(`common.jobApplicationSubStatuses.Pending`);
    }

    let returningStatus;
    const initialStatus = candidate?.subStatus;
    if (initialStatus === 'Pending') {
      returningStatus = `${t(
        `common.jobApplicationSubStatuses.${initialStatus}`
      )} ${JOB_APPLICATION_STATUSES[candidate?.status]}`;
    }

    if (
      candidate?.status === 'Invited' &&
      candidate?.subStatus !== 'Rejected'
    ) {
      returningStatus = (
        <span className={classes.invitedStatus}>
          {t('dashboardComponents.Candidate.CandidateHeader.statuses.Invited')}
        </span>
      );
    }

    if (initialStatus === 'Accepted' && candidate?.status === 'Onboard') {
      returningStatus = t(
        'dashboardComponents.Candidate.CandidateHeader.statuses.Confirmed'
      );
    }

    if (
      initialStatus === 'ChangeRequested' &&
      candidate?.status === 'Onboard'
    ) {
      returningStatus = candidate?.jobApplicationJoinings.length
        ? t(
            'dashboardComponents.Candidate.CandidateHeader.statuses.Change Start'
          )
        : t(
            'dashboardComponents.Candidate.CandidateHeader.statuses.Change Onboard'
          );
    }

    return (
      returningStatus || t(`common.jobApplicationSubStatuses.${initialStatus}`)
    );
  }

  const inviteCandidate = async () => {
    try {
      setIsFetching(true);
      await AdminService.inviteCandidate({
        id: favCandidate?.id,
        jobPostId: job?.id,
      });

      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      showNotification({
        text: `${t(
          'dashboardComponents.Favorites.Candidates.CandidateCard.invitationSent'
        )} ${favCandidate?.userProfile?.firstName} ${
          favCandidate?.userProfile?.lastName
        }.`,
      });
      setIsFetching(false);
    }
  };

  useEffect(() => {
    candidateCardRef.current.style.setProperty(
      '--borderRadius',
      `${borderRadius}px`
    );
  }, [borderRadius]);

  return (
    <div
      ref={candidateCardRef}
      style={{
        borderRadius,
      }}
      className={classNames(classes.candidateCard, {
        [classes.active]: isActive,
      })}
      onClick={() => {
        setCurrentCandidate(favCandidate);
        setIsCandidatePopupVisible(true);
      }}
    >
      <div className={classes.header}>
        <div className={classes.candidate}>
          {hasPhoto ? (
            <img
              src={`${IMAGES_URL}/avatar/${favCandidate?.userProfile.avatar}`}
              alt="candidate"
              className={classes.photo}
            />
          ) : (
            <div
              className={classNames(classes.photo, classes.noPhoto)}
            >{`${favCandidate?.userProfile?.firstName[0]}${favCandidate?.userProfile?.lastName[0]}`}</div>
          )}
          <div className={classes.container}>
            <h2
              className={classes.name}
            >{`${favCandidate?.userProfile?.firstName} ${favCandidate?.userProfile?.lastName}`}</h2>
            <p className={classes.location}>{favCandidate?.userProfile.city}</p>
          </div>
        </div>
        <div className={classes.menu}>
          <div className={classes.fav}>
            <FavStatus candidate={favCandidate} refetch={refetch} />
          </div>
          {!favorite && (
            <div
              className={classNames(classes.status, {
                [classes.isInvited]: isInvitedJob,
              })}
            >
              {isInvitedJob || isLoading ? (
                <Button
                  variant="secondary"
                  width={160}
                  height={40}
                  style={{ fontSize: 16 }}
                  disabled
                >
                  {getCandidateCardStatus(isInvitedJob)}
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  width={160}
                  height={40}
                  style={{ fontSize: 16 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    inviteCandidate();
                    setIsLoading(true);
                  }}
                  disabled={inviteButtonDisabled}
                >
                  {t(
                    'dashboardComponents.Favorites.Candidates.CandidateCard.inviteCandidate'
                  )}
                </Button>
              )}
            </div>
          )}
          {favCandidate?.note ? (
            <DotMenu
              options={
                favorite
                  ? [
                      t(
                        'dashboardComponents.Favorites.Candidates.CandidateCard.inviteToJob'
                      ),
                      t(
                        'dashboardComponents.Favorites.Candidates.CandidateCard.editNote'
                      ),
                      t(
                        'dashboardComponents.Favorites.Candidates.CandidateCard.emailCandidate'
                      ),
                      t(
                        'dashboardComponents.Favorites.Candidates.CandidateCard.share'
                      ),
                    ]
                  : [
                      t(
                        'dashboardComponents.Favorites.Candidates.CandidateCard.editNote'
                      ),
                      t(
                        'dashboardComponents.Favorites.Candidates.CandidateCard.emailCandidate'
                      ),
                      t(
                        'dashboardComponents.Favorites.Candidates.CandidateCard.share'
                      ),
                    ]
              }
              setIsActive={setIsActive}
              favCandidate={favCandidate}
              refetch={refetch}
              note={note}
            />
          ) : (
            <DotMenu
              options={
                favorite
                  ? [
                      t(
                        'dashboardComponents.Favorites.Candidates.CandidateCard.inviteToJob'
                      ),
                      t(
                        'dashboardComponents.Favorites.Candidates.CandidateCard.addNote'
                      ),
                      t(
                        'dashboardComponents.Favorites.Candidates.CandidateCard.emailCandidate'
                      ),
                      t(
                        'dashboardComponents.Favorites.Candidates.CandidateCard.share'
                      ),
                    ]
                  : [
                      t(
                        'dashboardComponents.Favorites.Candidates.CandidateCard.addNote'
                      ),
                      t(
                        'dashboardComponents.Favorites.Candidates.CandidateCard.emailCandidate'
                      ),
                      t(
                        'dashboardComponents.Favorites.Candidates.CandidateCard.share'
                      ),
                    ]
              }
              setIsActive={setIsActive}
              favCandidate={favCandidate}
              refetch={refetch}
            />
          )}
          {!!note && (
            <>
              <div
                className={classes.note}
                onClick={(e) => e.stopPropagation()}
              />
              <div className={classes.noteBox}>{note}</div>
            </>
          )}
        </div>
      </div>

      <div className={classes.jobs}>
        <p className={classes.title}>
          {t(
            'dashboardComponents.Favorites.Candidates.CandidateCard.jobApplied'
          )}
        </p>

        <div className={classes.jobsLost}>
          {favCandidate?.jobApplications
            ?.map((application) => (
              <JobInfo application={application} key={application?.id} />
            ))
            .reverse()}
        </div>
      </div>
    </div>
  );
}
