import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import classes from './styles.module.scss';

export default function EventNotification({ event }) {
  const [isEventNotificationEnable, setIsEventNotificationEnable] = useState(
    event.isNotificationEnable
  );
  const [eventEmail, setEventEmail] = useState(event.email);
  const [eventNotification, setEventNotification] = useState(
    event.notification
  );

  const { t } = useTranslation();

  return (
    <div className={classes.eventNotification}>
      <div>
        <span className={classes.title}>{event.type}</span>
        <div
          className={classNames(classes.onNotification, {
            [classes.offNotification]: !isEventNotificationEnable,
          })}
          onClick={() =>
            setIsEventNotificationEnable(!isEventNotificationEnable)
          }
        />
      </div>
      <div
        className={classNames(classes.selectOption, {
          [classes.noActive]: !isEventNotificationEnable,
        })}
      >
        <div
          className={classNames(classes.selected, {
            [classes.noSelected]: !eventEmail,
          })}
          onClick={() => setEventEmail(!eventEmail)}
        />
        <div className={classes.option}>{t('common.email')}</div>
      </div>
      <div
        className={classNames(classes.selectOption, {
          [classes.noActive]: !isEventNotificationEnable,
        })}
      >
        <div
          className={classNames(classes.selected, {
            [classes.noSelected]: !eventNotification,
          })}
          onClick={() => setEventNotification(!eventNotification)}
        />
        <div className={classes.option}>{t('common.notification')}</div>
      </div>
    </div>
  );
}
