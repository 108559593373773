import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';

import { useTranslation } from 'react-i18next';

import DropdownOptions from '../../../../../DropdownOptions';
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function FilterDropdown({ currentOption, setCurrentOption }) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);
  const [translatedOption, setTranslatedOption] = useState('');

  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => setIsOptionsListVisible(false));

  const { t } = useTranslation();

  const options = useMemo(
    () => [
      t(
        'dashboardComponents.Chat.LeftPanel.LeftPanelHeader.FilterDropdown.Unread'
      ),
      t(
        'dashboardComponents.Chat.LeftPanel.LeftPanelHeader.FilterDropdown.Recent'
      ),
    ],
    [t]
  );

  const changeOption = useCallback(
    (option) => {
      setTranslatedOption(option);

      const sortingOption = option === options[0] ? 'Unread' : 'Recent';
      setCurrentOption(sortingOption);

      setIsOptionsListVisible(false);
    },
    [options, setCurrentOption]
  );

  useEffect(() => {
    changeOption(options[1]);
  }, [changeOption, options]);

  return (
    <div className={classes.FilterDropdown} ref={dropdownRef}>
      <div
        className={classes.selector}
        onClick={() => setIsOptionsListVisible((prevState) => !prevState)}
      >
        {currentOption}
      </div>
      {isOptionsListVisible && (
        <DropdownOptions
          changeOption={changeOption}
          options={options}
          currentOption={translatedOption}
        />
      )}
    </div>
  );
}
