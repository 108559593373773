import React, { useState, useEffect, useRef } from 'react';

import { arrayMoveImmutable } from 'array-move';
import { useTranslation } from 'react-i18next';

import NumberInput from '../../../../../../../Form/NumberInput';
import Switch from '../../../../../../../Switch';
import TextInput from '../../../../../../../Form/TextInput';
import { ALPHABET } from '../../../../../../../../constants/main';
import Answer from './Answer';

import editIcon from '../../../../../../../../assets/images/dashboard/editIcon.svg';
import classes from './styles.module.scss';

let timer;

export default function Question({
  question,
  order,
  onToggleMultiple,
  changeQuestion,
  addAnswer,
  deleteAnswer,
  changeAnswer,
  deleteQuestion,
  changeAnswersPositions,
  dragQuestion,
  setIsAnswerBeingDragged,
  isDuplicate,
  setIsQuestionBeingDragged,
  isEdit,
}) {
  const [answersLimit, setAnswersLimit] = useState(2);
  const [isEditable, setIsEditable] = useState(false);

  const answersContainerRef = useRef();
  const inputRef = useRef();

  const { t } = useTranslation();

  const dragAnswer = (event, index) => {
    event.dataTransfer.setData('answerIndex', index);
    setIsAnswerBeingDragged(true);
  };

  const handleAnswerDrop = (event) => {
    event.preventDefault();
    const oldAnswerIndex = event.dataTransfer.getData('answerIndex');

    const coords = answersContainerRef.current.getBoundingClientRect();

    const yPosition = event.clientY - coords.top;

    const newAnswerIndex = Math.max(Math.floor(yPosition / (50 + 20)), 0);

    const newAnswersArray = arrayMoveImmutable(
      question.answers,
      oldAnswerIndex,
      newAnswerIndex
    );

    changeAnswersPositions(question.id, newAnswersArray);
  };

  const createNewAnswer = () => {
    if (
      !question.allowsMultipleAnswers ||
      question.answers?.length >= answersLimit
    ) {
      return;
    }

    if (
      question.allowsMultipleAnswers &&
      question.answers?.length >= answersLimit
    ) {
      return;
    }

    addAnswer(question.id);
  };

  useEffect(() => {
    if (!isEdit) {
      setIsEditable(true);
    }
  }, [isEdit]);

  useEffect(() => {
    clearTimeout(timer);
    if (
      (question.allowsMultipleAnswers &&
        question.answers?.length > answersLimit) ||
      (!question.allowsMultipleAnswers && question.answers?.length > 1)
    ) {
      const maxQuestions = question.allowsMultipleAnswers ? answersLimit : 1;
      const answerToDeleteCount = question.answers?.length - maxQuestions;
      const answersToDelete = question.answers?.slice?.(-answerToDeleteCount);

      timer = setTimeout(() => {
        answersToDelete.forEach((answer) =>
          deleteAnswer(question.id, answer.id)
        );
      }, 1000);
    }
  }, [
    answersLimit,
    deleteAnswer,
    question.allowsMultipleAnswers,
    question.answers,
    question.answers?.length,
    question.id,
  ]);

  return (
    <div
      className={classes.Question}
      draggable
      onDragStart={dragQuestion}
      onDragEnd={() => setIsQuestionBeingDragged(false)}
      data-index={order - 1}
    >
      <header className={classes.header}>
        <h1>
          {t(
            'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.AssessmentQuestions.Question.question'
          )}{' '}
          {order}
        </h1>
        <div className={classes.switches}>
          <Switch
            label={t(
              'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.AssessmentQuestions.Question.multiChoice'
            )}
            onClick={onToggleMultiple}
            isEnabled={question.allowsMultipleAnswers}
          />
          <span
            style={{
              visibility: question.allowsMultipleAnswers ? 'visible' : 'hidden',
            }}
          >
            Limit
          </span>
          <div
            style={{
              visibility: question.allowsMultipleAnswers ? 'visible' : 'hidden',
            }}
          >
            <NumberInput
              height={35}
              minValue={2}
              value={answersLimit}
              setFieldValue={(_name, value) => setAnswersLimit(value)}
            />
          </div>
        </div>
      </header>
      <div className={classes.questionContent}>
        <svg
          width="11"
          height="20"
          viewBox="0 0 11 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="2" cy="2" r="2" fill="#C1C5D6" />
          <circle cx="9" cy="2" r="2" fill="#C1C5D6" />
          <circle cx="2" cy="10" r="2" fill="#C1C5D6" />
          <circle cx="9" cy="10" r="2" fill="#C1C5D6" />
          <circle cx="2" cy="18" r="2" fill="#C1C5D6" />
          <circle cx="9" cy="18" r="2" fill="#C1C5D6" />
        </svg>
        <TextInput
          inputRef={inputRef}
          readonly={!isEditable}
          value={question.name}
          height={50}
          onChange={(event) => changeQuestion(question.id, event.target.value)}
          placeholder={t(
            'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.AssessmentQuestions.Question.enterQuestionHere'
          )}
          error={
            isDuplicate &&
            t(
              'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.AssessmentQuestions.Question.duplicate'
            )
          }
          touched={isDuplicate}
        />
        {isEdit && (
          <button
            className={classes.editIcon}
            type="button"
            onClick={() => {
              setIsEditable(true);
              inputRef.current.focus();
            }}
          >
            <img src={editIcon} alt="Edit" />
          </button>
        )}
        <i
          className={classes.deleteIcon}
          onClick={() => deleteQuestion(question.id)}
        >
          {t('common.delete')}
        </i>
      </div>
      <div
        className={classes.answers}
        onDragOver={(event) => event.preventDefault()}
        onDrop={handleAnswerDrop}
        ref={answersContainerRef}
      >
        {question.answers?.map((answer, index) => {
          const changeHandler = (name) => {
            changeAnswer(question.id, answer.id, name);
          };
          return (
            <Answer
              key={answer.id}
              isEdit={isEdit}
              setIsAnswerBeingDragged={setIsAnswerBeingDragged}
              deleteAnswer={() => deleteAnswer(question.id, answer.id)}
              alphabet={ALPHABET[index].toUpperCase()}
              changeAnswer={changeHandler}
              value={question.answers[index].name}
              dragAnswer={(event) => dragAnswer(event, index)}
              isDuplicate={question.answers.some(
                (answr) =>
                  answr.id !== answer.id &&
                  answr.name &&
                  answr.name === answer.name
              )}
              onEnterPress={
                index === question.answers?.length - 1 ? createNewAnswer : null
              }
            />
          );
        })}
      </div>
      <div className={classes.addMore}>
        <span onClick={createNewAnswer}>
          {t(
            'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.AssessmentQuestions.Question.addMore'
          )}{' '}
          <span className={classes.black}>
            {t(
              'dashboardComponents.Console.JobManagement.Assessments.AddOrEditAssessment.AssessmentQuestions.Question.options'
            )}
          </span>
        </span>
      </div>
    </div>
  );
}
