/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';

import UsersPopup from '../UsersPopup';

import {
  getCaretPosition,
  placeCaretAtEnd,
} from '../../../../../../helpers/contentEditable';
import useMutationObserver from '../../../../../../hooks/useMutationObserver';
import { useFeedStore } from '../../../../../../store';
import classes from './styles.module.scss';

export default function TextBox({
  setMessage,
  maxLength,
  addressees,
  setAddressees,
  mentionUser,
  textBoxRef,
  removeUserFromAddressessWhenRemovedFromText,
  isEditable,
}) {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupCoords, setPopupCoords] = useState({ top: 0, left: 0 });

  const setLastTextBoxRange = useFeedStore(
    (state) => state.setLastTextBoxRange
  );

  useMutationObserver(textBoxRef, () => {
    if (textBoxRef.current?.innerHTML.length > maxLength) {
      textBoxRef.current.innerHTML = textBoxRef.current.innerHTML.slice(
        0,
        maxLength
      );
      placeCaretAtEnd(textBoxRef);
    }
    setMessage(textBoxRef.current?.innerHTML);
    removeUserFromAddressessWhenRemovedFromText(textBoxRef.current?.innerHTML);
  });

  const mentionUserAndHidePopup = useCallback(
    (userName, userId) => {
      mentionUser(userName, userId);
      setIsPopupVisible(false);
    },
    [mentionUser]
  );

  // Remove user tag from the message if the user is removed from the addressees list
  useEffect(() => {
    const mentionUsersElems =
      textBoxRef.current?.querySelectorAll(`[data-userid]`);
    const mentionedUsers = Array.from(mentionUsersElems).map((elem) => ({
      value: elem.dataset.userid,
      label: elem.innerText.trim(),
    }));

    const removedUsers = mentionedUsers.filter((mUser) => {
      return !addressees.find((user) => +user.value === +mUser.value);
    });

    if (removedUsers.length) {
      const mentionedUserElement = textBoxRef.current.querySelector(
        `[data-userid="${removedUsers[0].value}"]`
      );

      if (mentionedUserElement) {
        mentionedUserElement.parentNode?.remove();
      }
    }
  }, [addressees, textBoxRef]);

  const hidePopup = useCallback(() => {
    setIsPopupVisible(false);
  }, []);

  return (
    <>
      <div
        ref={textBoxRef}
        className={classes.TextBox}
        contentEditable={isEditable}
        onPaste={(event) => {
          event.preventDefault();
          const text = event.clipboardData.getData('text/plain');
          document.execCommand('insertHTML', false, text);
        }}
        onKeyDown={(event) => {
          if (event.key === '@') {
            event.preventDefault();

            if (!textBoxRef.current.innerHTML) {
              const rect = textBoxRef.current.getBoundingClientRect();
              setPopupCoords({
                top: rect.top + 36,
                left: rect.left,
              });
            } else {
              const caret = getCaretPosition();
              const range = document.getSelection().getRangeAt(0);
              setLastTextBoxRange(range);

              setPopupCoords({
                top: caret.top + caret.height,
                left: caret.left,
              });
            }

            setIsPopupVisible(true);
            return;
          }

          if (
            textBoxRef.current?.innerText.trim().length >= maxLength &&
            event.key !== 'Backspace' &&
            event.key !== 'Delete'
          ) {
            event.preventDefault();
          }
        }}
      />
      <UsersPopup
        show={isPopupVisible}
        top={popupCoords.top}
        left={popupCoords.left}
        onHide={hidePopup}
        addressees={addressees}
        setAddressees={setAddressees}
        onUserAdd={mentionUserAndHidePopup}
        disableAll
      />
    </>
  );
}
