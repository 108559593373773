import React, { useState, useEffect, useContext } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import ArrowMenu from '../../../../ArrowMenu';
import Table from '../../../../Table';
import CandidateGridCard from './CandidateGridCard';
import TableMenu from '../../../Console/TableMenu';
import FilterTabs from '../FilterTabs';

import CandidatesService from '../../../../../services/CandidatesService';
import { CandidatesContext } from '../../../../../context/CandidatesContext';
import { JobsContext } from '../../../../../context/JobsContext';
import { useUiContext } from '../../../../../context/UiContext';
import tableColsToSortOptions from '../../../../../helpers/tableColsToSortOptions';
import classes from './styles.module.scss';

const sendEmailToCandidate = (candidate) => {
  if (!candidate.userProfile?.email) {
    return;
  }
  window.location.href = `mailto:${candidate.userProfile?.email}`;
};

export default function AllCandidates({
  candidates,
  refetch,
  selectedRows,
  setSelectedRows,
  toggleCandidateSelection,
  // sortOptions,
  activeSortOption,
  setActiveSortOption,
  sortOrder,
  setSortOrder,
  setSearchTerm,
  searchHistory,
  searchTerm,
  isFullScreenModeEnabled,
  setIsFullScreenModeEnabled,
  isFullScreenButtonVisible,
  setIsCandidatePopupVisible,
  setCurrentCandidate,
  view,
  setView,
  showAddCandidatePopup,
  currentPage,
  setCurrentPage,
  totalPages,
}) {
  const [candidatesFilter, setCandidatesFilter] = useState('Qualified');
  const [candidatesCount, setCandidatesCount] = useState({});
  const [sortedCandidates, setSortedCandidates] = useState({});
  const [activeCandidateFilter, setActiveCandidateFilter] = useState('All');
  // eslint-disable-next-line no-unused-vars
  const [isArrowMenuVisible, setIsArrowMenuVisible] = useState(false);

  const { showShareModal } = useContext(JobsContext);
  const { showAddCandidateNoteModal } = useContext(CandidatesContext);
  const [currentCandidate, setCurrentCandidateState] = useState(null);
  const [rightClickCoords, setRightClickCoords] = useState({ x: 0, y: 0 });
  const [notEnoughSpaceBelow, setNotEnoughSpaceBelow] = useState(false);
  const [activeAction, setActiveAction] = useState('');

  const { t } = useTranslation();

  const {
    showSelectionNeededModal,
    showModal,
    showNotification,
    showUnknownErrorModal,
    setIsFetching,
    showConflictModal,
  } = useUiContext();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const filters = [
    'All',
    'New',
    'Invited',
    'In Assessment',
    'In Interview',
    'In Onboarding',
    'Joined',
  ];

  const columns = [
    {
      field: 'isFavorite',
      name: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.candidateTable.columns.favorite'
      ),
      type: 'candidateBooleanFavorite',
      sortable: true,
    },
    {
      field: 'userProfile.fullName',
      name: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.candidateTable.columns.name'
      ),
      type: 'userWithAvatar',
      sortable: true,
    },
    {
      field: 'sourceType',
      name: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.candidateTable.columns.source'
      ),
      sortable: true,
    },
    {
      field: 'userProfile.city',
      name: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.candidateTable.columns.location'
      ),
      sortable: true,
    },
    {
      field: 'userProfile.email',
      name: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.candidateTable.columns.email'
      ),
      sortable: true,
    },
    {
      field: 'userProfile.phone',
      name: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.candidateTable.columns.phone'
      ),
      sortable: true,
    },
    {
      field: 'note',
      name: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.candidateTable.columns.notes'
      ),
      type: 'candidateNote',
      sortable: true,
    },
    {
      field: 'jobsApplied',
      name: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.candidateTable.columns.jobsApplied'
      ),
      type: 'count',
      sortable: true,
    },
    {
      field: 'createdAt',
      name: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.candidateTable.columns.createdOn'
      ),
      sortable: true,
      type: 'date',
    },
    {
      field: 'id',
      name: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.candidateTable.columns.invitation'
      ),
      sortable: false,
      type: 'inviteCandidateButton',
    },
  ];

  const sortOptions = tableColsToSortOptions(columns);

  useEffect(() => {
    setActiveSortOption({
      value: 'createdAt',
      label: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.candidateTable.columns.createdOn'
      ),
    });
  }, [setActiveSortOption, t]);

  useEffect(() => {
    if (!candidates) {
      return;
    }

    const candidatesByStatus = {
      All: candidates,
      New: [],
      Invited: [],
      'In Assessment': [],
      'In Interview': [],
      'In Onboarding': [],
      Joined: [],
    };

    candidates.forEach((candidate) => {
      let isNew;
      let isInvited;
      let isInAssessment;
      let isInInterview;
      let isInOnboarding;
      let isJoined;

      candidate.jobApplications?.forEach?.((jobApp) => {
        if (jobApp.status === 'New') {
          isNew = true;
        }
        if (jobApp.status === 'Invited') {
          isInvited = true;
        }
        if (jobApp.status === 'Screen') {
          isInAssessment = true;
        }
        if (jobApp.status === 'Interview') {
          isInInterview = true;
        }
        if (jobApp.status === 'Onboard') {
          isInOnboarding = true;
        }
        if (jobApp.subStatus === 'Joined') {
          isJoined = true;
        }
      });

      if (isNew) {
        candidatesByStatus.New.push(candidate);
      }
      if (isInvited) {
        candidatesByStatus.Invited.push(candidate);
      }
      if (isInAssessment) {
        candidatesByStatus['In Assessment'].push(candidate);
      }
      if (isInInterview) {
        candidatesByStatus['In Interview'].push(candidate);
      }
      if (isInOnboarding) {
        candidatesByStatus['In Onboarding'].push(candidate);
      }
      if (isJoined) {
        candidatesByStatus.Joined.push(candidate);
      }
    });

    setSortedCandidates(candidatesByStatus);
  }, [candidates]);

  useEffect(() => {
    if (sortedCandidates) {
      const counts = {};

      Object.keys(sortedCandidates).forEach((key) => {
        counts[key] = sortedCandidates[key].length;
      });

      setCandidatesCount(counts);
    }
  }, [sortedCandidates]);

  const qualifiedCandidates = sortedCandidates?.[activeCandidateFilter]?.filter(
    (candidate) =>
      candidate.jobApplications?.every(
        (jobApp) => jobApp.subStatus !== 'Rejected'
      )
  );

  const disqualifiedCandidates = sortedCandidates?.[
    activeCandidateFilter
  ]?.filter((candidate) =>
    candidate.jobApplications?.some((jobApp) => jobApp.subStatus === 'Rejected')
  );

  const currentCandidates =
    candidatesFilter === 'Qualified'
      ? qualifiedCandidates
      : disqualifiedCandidates;

  const showCandidateInfo = (id) => {
    setIsCandidatePopupVisible(true);
    setCurrentCandidate(
      currentCandidates.find((candidate) => candidate.id === id)
    );
  };

  const contextMenuOptions = [
    {
      label: currentCandidate?.note
        ? t(
            'dashboardComponents.Candidates.CandidatesList.AllCandidates.candidateTable.contextMenu.editNote'
          )
        : t(
            'dashboardComponents.Candidates.CandidatesList.AllCandidates.candidateTable.contextMenu.addNote'
          ),
      onClick: () => showAddCandidateNoteModal(currentCandidate, refetch),
    },
    {
      label: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.candidateTable.contextMenu.emailCandidate'
      ),
      onClick: () => sendEmailToCandidate(currentCandidate),
    },
    {
      label: t(
        'dashboardComponents.Candidates.CandidatesList.AllCandidates.candidateTable.contextMenu.share'
      ),
      onClick: () => showShareModal({ currentCandidate }),
    },
  ];

  const actions = [
    {
      label: t('common.add'),
      value: 'Add',
      onClick: showAddCandidatePopup,
    },
    {
      label: t('common.edit'),
      value: 'Edit',
      onClick: () => {
        if (selectedRows.length) {
          showAddCandidatePopup(true);
        } else {
          showSelectionNeededModal();
        }
      },
    },
    {
      label: t('common.delete'),
      value: 'Delete',
      onClick: () => {
        if (selectedRows.length) {
          showModal({
            icon: 'delete',
            title: t(
              'dashboardComponents.Candidates.CandidatesList.AllCandidates.AddCandidate.Delete Candidate/s'
            ),
            text: t('common.deleteConfirmation'),
            onConfirm: async () => {
              try {
                setIsFetching(true);
                await Promise.all(
                  selectedRows.map((candidateId) =>
                    CandidatesService.deleteCandidate(candidateId)
                  )
                );
                showNotification({
                  text: t('Candidate deleted successfully'),
                });
                refetch();
              } catch (error) {
                console.log(error);
                if (error.response.status === 409) {
                  showConflictModal(error.response.data.message);
                } else {
                  showUnknownErrorModal();
                }
              } finally {
                setIsFetching(false);
              }
            },
            onCancel: () => {},
            dismissButtonLabel: t('common.no'),
            confirmButtonLabel: t('common.yes'),
          });
        } else {
          showSelectionNeededModal();
        }
      },
    },
  ];

  const showTableContextMenu = (id, clientX, clientY) => {
    const menuHeight = 131;
    const notEnoughSpace = window.innerHeight - clientY < menuHeight;
    setNotEnoughSpaceBelow(notEnoughSpace);

    setRightClickCoords({
      x: clientX,
      y: notEnoughSpace ? clientY - 131 - 30 : clientY,
    });
    const candidate = currentCandidates.find((cnd) => cnd.id === id);
    setCurrentCandidateState(candidate);
    setIsArrowMenuVisible(true);
  };

  let mainContent;

  if (!currentCandidates?.length) {
    mainContent = (
      <div className={classes.noContent}>
        <p>
          {t(
            'dashboardComponents.Candidates.CandidatesList.AllCandidates.allCandidates.noContent.message1'
          )}
        </p>
        <p>
          {t(
            'dashboardComponents.Candidates.CandidatesList.AllCandidates.allCandidates.noContent.message2'
          )}
        </p>
      </div>
    );
  }

  if (currentCandidates?.length) {
    let tableHeight = isFullScreenModeEnabled
      ? 'calc(100vh - 80px - 22px - 78px - 16px - 20px - 23px - 45px - 95px)'
      : 'calc(100vh - 80px - 22px - 78px - 16px - 273px - 38px - 20px - 23px - 45px - 95px)';

    if (isTabletOrMobile) {
      tableHeight = '';
    }

    mainContent =
      view === 'list' ? (
        <div className={classes.table}>
          <Table
            style={{
              overflowX: isTabletOrMobile ? 'unset' : '',
            }}
            noSort
            height={tableHeight}
            name="Candidates"
            onRowClick={showCandidateInfo}
            onRowRightClick={showTableContextMenu}
            rows={currentCandidates}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            sortOptions={sortOptions}
            setActiveSortOption={setActiveSortOption}
            activeSortOption={activeSortOption}
            setSortOrder={setSortOrder}
            sortOrder={sortOrder}
            columns={columns}
            refetch={refetch}
          />
        </div>
      ) : (
        <div className={classes.gridContainer}>
          <div className={classes.grid}>
            {currentCandidates?.map((candidate) => {
              return (
                <CandidateGridCard
                  selectedRows={selectedRows}
                  key={candidate.id}
                  candidate={candidate}
                  refetch={refetch}
                  toggleCandidateSelection={toggleCandidateSelection}
                  showCandidateInfo={showCandidateInfo}
                />
              );
            })}
          </div>
        </div>
      );
  }

  return (
    <div className={classes.AllCandidates}>
      {isArrowMenuVisible && (
        <div
          className={classes.arrowMenuContainer}
          style={{ top: rightClickCoords.y, left: rightClickCoords.x }}
        >
          <ArrowMenu
            options={contextMenuOptions}
            isVisible
            setIsVisible={setIsArrowMenuVisible}
            bottomArrow={notEnoughSpaceBelow}
          />
        </div>
      )}
      <div className={classes.candidateFiltersConainer}>
        <FilterTabs
          filters={filters}
          count={candidatesCount}
          activeFilter={activeCandidateFilter}
          setActiveFilter={setActiveCandidateFilter}
          translationPath="dashboardComponents.Candidates.CandidatesList.AllCandidates.CandidateFilters"
        />
      </div>
      <div className={classes.viewContainer}>
        <div
          className={classNames(classes.tableMenuContainer, {
            [classes.withoutButton]: !isFullScreenButtonVisible,
          })}
        >
          <div className={classes.innerContainer}>
            <TableMenu
              setSortOrder={setSortOrder}
              style={{
                height: 95,
                backgroundColor: 'transparent',
                flexWrap: isTabletOrMobile ? 'nowrap' : '',
              }}
              actions={actions}
              activeAction={activeAction}
              setActiveAction={setActiveAction}
              dangerAction={t('common.delete')}
              sortOptions={sortOptions}
              activeSortOption={activeSortOption}
              setActiveSortOption={setActiveSortOption}
              selectedCount={selectedRows.length}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchHistory={searchHistory}
              view={view}
              setView={setView}
              isQualifiedSwitchVisible
              candidatesFilter={candidatesFilter}
              setCandidatesFilter={setCandidatesFilter}
              qualifiedCount={qualifiedCandidates?.length}
              disqualifiedCount={disqualifiedCandidates?.length}
              desktopLayoutOnMobile
              withFullScreenButton={isFullScreenButtonVisible}
              isFullScreenModeEnabled={isFullScreenModeEnabled}
              setIsFullScreenModeEnabled={setIsFullScreenModeEnabled}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
        {mainContent}
      </div>
    </div>
  );
}
