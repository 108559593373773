/* eslint-disable react/jsx-handler-names */
import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import DocUploader from '../../components/Dashboard/Console/JobManagement/JobTemplates/Template/BasicInfo/DocUploader';
import CurrencyInput from '../../components/Form/CurrencyInput';
import TextInput from '../../components/Form/TextInput';
import Dropdown from '../../components/Form/Dropdown';
import Textarea from '../../components/Form/Textarea';
import FormDropdown from '../../components/Form/FormDropdown';
import Button from '../../components/Button';

import AdminService from '../../services/AdminService';
import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';

const validationSchema = yup.object({
  currency: yup.object().required('This field is required'),
  amount: yup.string().trim().required('This field is required'),
  location: yup.object().required('This field is required'),
  note: yup.string().trim(),
  customJobTitle: yup.string().trim(),
});

export default function OfferModal({ show, handleClose, candidate, refetch }) {
  const [, setIsTriedToSubmit] = useState(false);
  const [isJobTitleEditMode, setIsJobTitleEditMode] = useState(false);
  const [attachment, setAttachment] = useState(null);

  const { showNotification, setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const { data: job } = useQuery({
    queryKey: ['myJob', candidate?.jobPostId],
    queryFn: () => AdminService.getJobPost({ id: candidate?.jobPostId }),
    enabled: !!candidate?.jobPostId,
  });

  const closeAndNotify = (message) => {
    handleClose();
    refetch();
    showNotification({
      text: message,
    });
  };

  const formik = useFormik({
    initialValues: {
      currency: { label: 'TRY', value: 'TRY' },
      amount: '',
      location: '',
      note: '',
      customJobTitle: job ? job.jobTitle : '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFetching(true);

        let uploadedFile;

        if (attachment) {
          uploadedFile = await AdminService.uploadFile(attachment);
        }

        const offerData = {
          jobApplicationId: candidate.id,
          currency: values.currency.label,
          amount: parseFloat(values.amount.replaceAll(',', '')),
          locationName: values.location.label,
          locationId: values.location.value,
          note: values.note,
          customJobTitle: values.customJobTitle,
        };

        if (uploadedFile) {
          offerData.jobApplicationOfferAttachments = [
            {
              id: uploadedFile.id,
              fileName: uploadedFile.fileName,
              url: uploadedFile.uri,
              mimeType: uploadedFile.mimeType,
              displayName: uploadedFile.displayName,
            },
          ];
        }

        await AdminService.createJobApplicationOffer(offerData);

        await AdminService.changeMyJobApplicationStatus({
          jobApplicationId: candidate.id,
          status: 'Offer',
          subStatus: 'Offered',
        });

        await refetch();
        handleClose();
        closeAndNotify(t('modals.OfferModal.offerCreated'));
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    return () => {
      formik.resetForm();
      setIsTriedToSubmit(false);
      setIsJobTitleEditMode(false);
      setAttachment(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const isDisabled = !formik.values.location || !formik.values.amount;

  const prevOffer =
    candidate?.jobApplicationOffers?.[
      candidate.jobApplicationOffers.length - 1
    ];

  const userLocation =
    candidate?.applicantLocation ||
    candidate?.user?.userProfile?.city ||
    candidate?.user?.userProfile?.customLocation;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.OfferModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header className={classes.header}>
          <h1>{t('modals.OfferModal.modalTitle')}</h1>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <div className={classes.container}>
          <div className={classes.row}>
            <div className={classes.col}>
              <div className={classes.applicationInfo}>
                <h3>{t('modals.OfferModal.candidate')}</h3>
                <h2>
                  {candidate?.user?.userProfile?.firstName}{' '}
                  {candidate?.user?.userProfile?.lastName}
                </h2>
                <span className={classes.location}>{userLocation}</span>
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.applicationInfo}>
                {!isJobTitleEditMode && (
                  <i
                    className={classes.editIcon}
                    onClick={() => setIsJobTitleEditMode(true)}
                  >
                    Edit
                  </i>
                )}
                <h3>{t('modals.OfferModal.position.header')}</h3>
                {isJobTitleEditMode ? (
                  <TextInput
                    height={48}
                    value={formik.values.customJobTitle}
                    name="customJobTitle"
                    onChange={formik.handleChange}
                  />
                ) : (
                  <h2 className={classes.jobTitle}>{job?.jobTitle}</h2>
                )}
                <span className={classes.jobId}>
                  {t('modals.OfferModal.position.jobId')}:{' '}
                  {candidate?.jobPostId}
                </span>
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <div className={classes.dropdowns}>
                <div className={classes.dropdownContainer}>
                  <h4 className={classes.dropdownTitle}>
                    {t('modals.OfferModal.offer.header')}
                  </h4>
                  <Dropdown
                    width={95}
                    height={48}
                    options={[
                      { value: 'TRY', label: 'TRY' },
                      { value: 'USD', label: 'USD' },
                      { value: 'EUR', label: 'EUR' },
                    ]}
                    activeOption={formik.values.currency}
                    setActiveOption={(option) =>
                      formik.setFieldValue('currency', option)
                    }
                  />
                </div>
                <CurrencyInput
                  height={48}
                  name="amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.dropdowns}>
                <div className={classes.dropdownContainer}>
                  <h4
                    className={classNames(classes.dropdownTitle, classes.short)}
                  >
                    {t('modals.OfferModal.offer.previousOffer')}{' '}
                    <span className={classes.note}>(On 14 April 2022)</span>
                  </h4>
                  <TextInput
                    readonly
                    value={prevOffer?.currency}
                    height={48}
                    width={95}
                  />
                </div>
                <CurrencyInput height={48} readonly value={prevOffer?.amount} />
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <FormDropdown
                width={350}
                label={t('modals.OfferModal.jobLocation.label')}
                placeholder={t('modals.OfferModal.jobLocation.placeholder')}
                fetchOptions={{
                  dataName: 'restaurantsOptionsBasicInfo',
                  getDataHandler: AdminService.getRestaurants,
                }}
                value={formik.values.location}
                infoWidth={151}
                name="location"
                searchInfo={t('modals.OfferModal.jobLocation.info')}
                searchInfoWidth={188}
                setFieldValue={formik.setFieldValue}
              />
              <div className={classes.docUploaderContainer}>
                <DocUploader file={attachment} setFile={setAttachment} />
              </div>
            </div>
            <div className={classes.col}>
              <Textarea
                name="note"
                onChange={formik.handleChange}
                value={formik.values.note}
                label={t('modals.OfferModal.emailLabel')}
                note={t('modals.OfferModal.emailNote')}
                height={226}
                placeholder={t('modals.OfferModal.emailPlaceholder')}
                // maxLength={5000}
              />
            </div>
          </div>
        </div>
        <footer>
          <Button
            variant="secondary"
            width={150}
            height={52}
            onClick={formik.resetForm}
          >
            {t('modals.OfferModal.clearButton')}
          </Button>
          <Button
            width={150}
            height={52}
            disabled={isDisabled}
            onClick={() => {
              setIsTriedToSubmit(true);
              formik.handleSubmit();
            }}
          >
            {t('modals.OfferModal.sendButton')}
          </Button>
        </footer>
      </div>
    </Modal>
  );
}
