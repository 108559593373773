import axios from 'axios';

import { JOBS_API_URL } from '../constants/main';

async function getData({ orderBy, term, url }) {
  const response = await axios.get(url, {
    params: {
      Term: term,
      OrderBy: orderBy,
    },
  });

  return response.data;
}

const ChatService = {
  async getThreads({ term, orderBy }) {
    const url = `${JOBS_API_URL}/jobposts/threads`;
    return getData({
      url,
      term,
      orderBy,
    });
  },

  async getMessages({ jobApplicationId }) {
    const url = `${JOBS_API_URL}/jobapplications/${jobApplicationId}/messages`;
    return getData({
      url,
    });
  },

  async sendMessage({ jobApplicationId, message }) {
    const url = `${JOBS_API_URL}/jobapplications/${jobApplicationId}/messages`;
    const response = await axios.post(url, {
      message,
    });

    return response.data;
  },

  async readAllMessagesInThread({ jobApplicationId }) {
    const url = `${JOBS_API_URL}/jobapplications/${jobApplicationId}/messages/read`;
    const response = await axios.post(url);

    return response.data;
  },

  async pinContact({ jobApplicationId }) {
    const url = `${JOBS_API_URL}/jobapplications/${jobApplicationId}/pins`;
    const response = await axios.post(url);

    return response.data;
  },

  async unpinContact({ jobApplicationId }) {
    const url = `${JOBS_API_URL}/jobapplications/${jobApplicationId}/pins`;
    const response = await axios.delete(url);

    return response.data;
  },

  async markMessageAsAddressed({ jobApplicationId, messageId }) {
    const url = `${JOBS_API_URL}/jobapplications/${jobApplicationId}/messages/${messageId}/addressed`;
    const response = await axios.post(url);

    return response.data;
  },
};

export default ChatService;
