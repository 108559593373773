import React from 'react';

import classes from './styles.module.scss';

export default function UserTag({ user, setUser }) {
  const removeUserTag = () => {
    setUser(null);
  };

  return (
    <div className={classes.UserTag}>
      <span>{user.label}</span>
      <span className={classes.remove} onClick={removeUserTag} />
    </div>
  );
}
