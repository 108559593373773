import React from 'react';

import classNames from 'classnames';
import { get } from 'lodash';

import Switch from '../../../Switch';
import TextInput from '../../../Form/TextInput';
import Textarea from '../../../Form/Textarea';
import useSwitchableData from '../../../../hooks/useSwitchableData';
import SidePopup from '../SidePopup';
import classes from './styles.module.scss';

export default function ViewData({
  isVisible,
  handleClose,
  title,
  leftButtonLabel,
  rightButtonLabel,
  onLeftButtonClick,
  onRightButtonClick,
  data,
  currentDataId,
  rightColumnFields,
  leftColumnFields,
  isOverlayed,
}) {
  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableData(data, currentDataId);

  const createDataFields = (fields) => {
    const fieldsJsx = fields.map((field) => {
      if (field.isVisible && !field.isVisible(data?.[currentDataIndex])) {
        return null;
      }

      let fieldJsx = (
        <div
          className={classNames({
            [classes.fullWidth]: field.fullWidth,
          })}
        >
          <TextInput
            width={field.width}
            height={50}
            readonly
            label={field.label}
            key={field.field}
            value={get(data?.[currentDataIndex], field.field) || ''}
            centerText={field.centerText}
            note={field.note}
          />
        </div>
      );

      if (field.type === 'textarea') {
        fieldJsx = (
          <Textarea
            height={100}
            readonly
            label={field.label}
            key={field.field}
            value={get(data?.[currentDataIndex], field.field)}
          />
        );
      } else if (field.type === 'switch') {
        fieldJsx = (
          <div className={classes.switchContainer}>
            <Switch
              label={field.label}
              isEnabled={get(data?.[currentDataIndex], field.field)}
            />
          </div>
        );
      }

      return fieldJsx;
    });

    return fieldsJsx;
  };

  return (
    <SidePopup
      isVisible={isVisible}
      handleClose={handleClose}
      title={title}
      leftButtonLabel={leftButtonLabel}
      rightButtonLabel={rightButtonLabel}
      switchNext={data?.length > 1 && switchToNextDataElement}
      switchPrev={data?.length > 1 && switchToPrevDataElement}
      onRightButtonClick={() =>
        onRightButtonClick(data?.[currentDataIndex]?.id)
      }
      onLeftButtonClick={() => onLeftButtonClick(data?.[currentDataIndex]?.id)}
      leftButtonVariant="delete"
      isOverlayed={isOverlayed}
    >
      <div className={classes.ViewData}>
        <div className={classes.col}>{createDataFields(leftColumnFields)}</div>
        <div className={classes.col}>{createDataFields(rightColumnFields)}</div>
      </div>
    </SidePopup>
  );
}
