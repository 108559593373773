import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function Title({ hideTitle, title, setTitle }) {
  const { t } = useTranslation();

  return (
    <div className={classes.Title}>
      <i className={classes.closeIcon} onClick={hideTitle}>
        Close
      </i>
      <input
        type="text"
        placeholder={t(
          'dashboardComponents.Discover.AddContent.TextEditor.Title.titlePlaceholder'
        )}
        maxLength={100}
        value={title}
        onChange={(event) => setTitle(event.target.value)}
      />
    </div>
  );
}
