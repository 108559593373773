import React from 'react';

import { useTranslation } from 'react-i18next';

import EventNotification from './EventNotification';

import classes from './styles.module.scss';

export default function JobNotification() {
  const { t } = useTranslation();

  const events = [
    {
      type: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.JobSettings.JobNotification.newApplicants'
      ),
      isNotificationEnable: false,
      email: false,
      notification: false,
    },
    {
      type: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.JobSettings.JobNotification.assessment'
      ),
      isNotificationEnable: false,
      email: false,
      notification: false,
    },
    {
      type: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.JobSettings.JobNotification.interview'
      ),
      isNotificationEnable: true,
      email: true,
      notification: true,
    },
    {
      type: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.JobSettings.JobNotification.offer'
      ),
      isNotificationEnable: true,
      email: true,
      notification: true,
    },
    {
      type: t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.JobSettings.JobNotification.onboarding'
      ),
      isNotificationEnable: true,
      email: true,
      notification: true,
    },
  ];

  return (
    <div className={classes.JobNotification}>
      <div className={classes.title}>
        {t(
          'dashboardComponents.Jobs.MyJobs.JobDetails.JobSettings.JobNotification.notification'
        )}
      </div>
      <div className={classes.notifications}>
        {events.map((event) => (
          <EventNotification event={event} key={event.type} />
        ))}
      </div>
    </div>
  );
}
