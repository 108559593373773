import React from 'react';

import { useTranslation } from 'react-i18next';

import { JOB_APPLICATION_STATUSES } from '../../../../constants/main';

export default function HiringSubStatus({ row }) {
  const { t } = useTranslation();

  function getCandidateCardStatus(candidate) {
    let status = candidate?.subStatus?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' ');
    if (status === 'Pending') {
      status = `${status} ${JOB_APPLICATION_STATUSES[candidate?.status]}`;
    }

    if (
      candidate?.status === 'Invited' &&
      candidate?.subStatus !== 'Rejected'
    ) {
      status = t('miscComponents.Table.Td.HiringSubStatus.invited');
    }

    if (status === 'Accepted' && candidate?.status === 'Onboard') {
      status = t('miscComponents.Table.Td.HiringSubStatus.confirmed');
    }

    if (status === 'Change Requested' && candidate?.status === 'Onboard') {
      status = candidate?.jobApplicationJoinings.length
        ? t('miscComponents.Table.Td.HiringSubStatus.changeStart')
        : t('miscComponents.Table.Td.HiringSubStatus.changeOnboard');
    }

    if (status === 'Failed' && candidate?.status === 'Screen') {
      status = t('miscComponents.Table.Td.HiringSubStatus.autoReject');
    }

    if (status === 'Canceled' && candidate?.status === 'Onboard') {
      status = t('miscComponents.Table.Td.HiringSubStatus.Start Canceled');
    }

    return status;
  }

  return <td>{getCandidateCardStatus(row)}</td>;
}
