/* eslint-disable no-param-reassign */
const removeEmptySpans = (textBoxRef) => {
  const container = document.createElement('div');
  container.innerHTML = textBoxRef.current.innerHTML;
  container.innerHTML = container.innerHTML.replaceAll(
    '<span>&#8203;</span>',
    ''
  );

  return container;
};

const replaceQuotes = (container, sharedClasses) => {
  const quoteContainers = container.querySelectorAll('.quoteContainer');

  if (quoteContainers.length) {
    quoteContainers.forEach((quoteContainer) => {
      const quote = quoteContainer.querySelector('blockquote');
      const finishedQuote = document.createElement('blockquote');
      finishedQuote.innerHTML = quote.innerHTML;
      finishedQuote.classList.add(sharedClasses.quote);

      quoteContainer.replaceWith(finishedQuote);
    });
  }

  return container;
};

export default function processPostContent(textBoxRef, sharedClasses) {
  const containerWithoutEmptySpaces = removeEmptySpans(textBoxRef);

  const containerWithReplacedQuotes = replaceQuotes(
    containerWithoutEmptySpaces,
    sharedClasses
  );
  return containerWithReplacedQuotes.innerHTML;
}
