import React from 'react';

import classes from './styles.module.scss';

export default function Chip({ label, handleDelete }) {
  return (
    <div className={classes.Chip}>
      {label}
      <i onClick={handleDelete}>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z"
            stroke="#242833"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.87891 11.12L11.1239 6.875"
            stroke="#242833"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.1239 11.12L6.87891 6.875"
            stroke="#242833"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </i>
    </div>
  );
}
