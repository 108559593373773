import React, { lazy, Suspense } from 'react';

import { useTranslation } from 'react-i18next';

import ColorInfo from './ColorInfo';
import PrevColors from './PrevColors';

import classes from './styles.module.scss';

const ColorPicker = lazy(() => import('./ColorPicker'));

export default function ColorPalette({
  currentEditingColor,
  setCurrentEditingColor,
  prevPickedColors,
  changeColor,
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.ColorPalette}>
      <div className={classes.colorPickerContainer}>
        <Suspense
          fallback={
            <div>
              {t(
                'dashboardComponents.ProfileSettings.BrandKit.LeftPanel.SettingsContainer.Color.ColorPalette.loading'
              )}
            </div>
          }
        >
          <ColorPicker
            currentEditingColor={currentEditingColor}
            setCurrentEditingColor={setCurrentEditingColor}
          />
        </Suspense>
      </div>
      <div className={classes.colorInfoContainer}>
        <ColorInfo hexCode={currentEditingColor} />
      </div>
      <div className={classes.prevColorsContainer}>
        <PrevColors
          prevPickedColors={prevPickedColors}
          changeColor={changeColor}
          currentEditingColor={currentEditingColor}
        />
      </div>
    </div>
  );
}
