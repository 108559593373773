import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function JobFormSteps({ currentFormStep }) {
  const { t } = useTranslation();

  return (
    <header className={classes.JobFormSteps}>
      <ul>
        <li
          className={classNames({
            [classes.active]: currentFormStep >= 1,
          })}
        >
          <span className={classes.stepNumber}>
            {t('dashboardComponents.Jobs.PostAJob.JobFormSteps.step', {
              number: 1,
            })}
          </span>
          <span className={classes.title}>
            {t('dashboardComponents.Jobs.PostAJob.JobFormSteps.step1.title')}
          </span>
          <span className={classes.details}>
            {t('dashboardComponents.Jobs.PostAJob.JobFormSteps.step1.details')}
          </span>
        </li>
        <li
          className={classNames({
            [classes.active]: currentFormStep >= 2,
          })}
        >
          <span className={classes.stepNumber}>
            {t('dashboardComponents.Jobs.PostAJob.JobFormSteps.step', {
              number: 2,
            })}
          </span>
          <span className={classes.title}>
            {t('dashboardComponents.Jobs.PostAJob.JobFormSteps.step2.title')}
          </span>
          <span className={classes.details}>
            {t('dashboardComponents.Jobs.PostAJob.JobFormSteps.step2.details')}
          </span>
        </li>
        <li
          className={classNames({
            [classes.active]: currentFormStep >= 3,
          })}
        >
          <span className={classes.stepNumber}>
            {t('dashboardComponents.Jobs.PostAJob.JobFormSteps.step', {
              number: 3,
            })}
          </span>
          <span className={classes.title}>
            {t('dashboardComponents.Jobs.PostAJob.JobFormSteps.step3.title')}
          </span>
          <span className={classes.details}>
            {t('dashboardComponents.Jobs.PostAJob.JobFormSteps.step3.details')}
          </span>
        </li>
        <li
          className={classNames({
            [classes.active]: currentFormStep === 4,
          })}
        >
          <span className={classes.stepNumber}>
            {t('dashboardComponents.Jobs.PostAJob.JobFormSteps.step', {
              number: 4,
            })}
          </span>
          <span className={classes.title}>
            {t('dashboardComponents.Jobs.PostAJob.JobFormSteps.step4.title')}
          </span>
          <span className={classes.details}>
            {t('dashboardComponents.Jobs.PostAJob.JobFormSteps.step4.details')}
          </span>
        </li>
      </ul>
    </header>
  );
}
