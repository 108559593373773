import React from 'react';

import classNames from 'classnames';

import Tooltip from '../../../../../../../Tooltip';

import classes from './styles.module.scss';
import editIcon from '../../../../../../../../assets/images/dashboard/brand-kit/editColor.svg';

export default function ColorTile({ hexCode, isActive, onClick, isEditing }) {
  return (
    <Tooltip text={hexCode}>
      <div
        onClick={onClick}
        tabIndex={0}
        role="button"
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onClick();
          }
        }}
        className={classNames(classes.ColorTile, {
          [classes.active]: isActive,
        })}
      >
        <div className={classes.outer} />
        <div className={classes.inner} style={{ backgroundColor: hexCode }}>
          {isEditing && <img src={editIcon} alt="edit" />}
        </div>
      </div>
    </Tooltip>
  );
}
