/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import usePaginatedDropdownOptions from '../../../hooks/usePaginatedDropdownOptions';
import Chip from './Chip';
import Checkbox from '../Checkbox';
import Button from '../../Button';
import Tooltip from '../../Tooltip';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function ChipsDropdown({
  label,
  maxLength,
  info,
  options,
  value,
  setFieldValue,
  name,
  searchInfo,
  dangerOption,
  searchInfoWidth,
  infoWidth,
  height,
  direction,
  note,
  setFieldTouched,
  fetchOptions,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isEnoughHeight, setIsEnoughHeight] = useState(true);

  const fieldRef = useRef();
  const optionsRef = useRef();
  const dropdownRef = useRef();
  const isOpened = useRef();

  const { t } = useTranslation();

  useOnClickOutside(optionsRef, () => setIsOptionsListVisible(false));

  const setMultiDropdownOption = (option) => {
    if (Array.isArray(value)) {
      if (value.some((val) => val.value === option.value)) {
        const newValues = value.filter((val) => val.value !== option.value);

        if (!newValues.length) {
          setFieldValue?.(name, '');
        } else {
          setFieldValue?.(name, newValues);
        }
      } else {
        if (value.length === maxLength) {
          return;
        }
        setFieldValue?.(name, [...value, option]);
      }
    } else {
      setFieldValue?.(name, [option]);
    }
  };

  useEffect(() => {
    if (isOptionsListVisible) {
      dropdownRef.current.style.setProperty(
        '--optionsHeight',
        `-${optionsRef.current.clientHeight}px`
      );

      const { bottom } = fieldRef.current.getBoundingClientRect();

      if (window.innerHeight - bottom < dropdownRef.current.clientHeight) {
        setIsEnoughHeight(false);
      } else {
        setIsEnoughHeight(true);
      }
    } else {
      setIsEnoughHeight(true);
      if (isOpened.current) {
        setFieldTouched?.(name, true, true);
      }
    }
  }, [isOptionsListVisible, name, setFieldTouched]);

  const { options: fetchedOptions, lastListElementRef } =
    usePaginatedDropdownOptions({
      dataName: fetchOptions?.dataName,
      getDataHandler: fetchOptions?.getDataHandler,
      searchTerm,
      labelName: fetchOptions?.labelName,
      isUserList: fetchOptions?.isUserList,
    });

  const optionsToDisplay = fetchOptions
    ? fetchedOptions
    : options?.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      );

  return (
    <div
      ref={dropdownRef}
      className={classNames(classes.ChipsDropdown, {
        [classes.optionsTop]: !isEnoughHeight,
      })}
    >
      <header className={classes.header}>
        <span className={classes.label}>
          {label} ({value?.length}/{maxLength})
        </span>
        {info && (
          <Tooltip text={info} direction={direction} width={infoWidth}>
            <i>
              <svg
                width="3"
                height="10"
                viewBox="0 0 3 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.25H1.03125"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 8.75V4.75"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          </Tooltip>
        )}
        {note && <span className={classes.note}>({note})</span>}
      </header>
      <div
        className={classNames(classes.field, {
          [classes.optionsVisible]: isOptionsListVisible,
        })}
        ref={fieldRef}
        style={{ height, maxHeight: height }}
      >
        {value?.length < maxLength && (
          <Button
            width={83}
            height={36}
            style={{
              fontSize: 14,
            }}
            onClick={() => {
              setIsOptionsListVisible((prevState) => !prevState);
            }}
          >
            <span className={classes.buttonContent}>
              Add
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="1.5"
                  y="1.5"
                  width="15"
                  height="15"
                  rx="6"
                  stroke="#242833"
                  strokeWidth="1.5"
                />
                <path
                  d="M9 6L9 12"
                  stroke="#242833"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M12 9H6"
                  stroke="#242833"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </span>
          </Button>
        )}
        {value?.length
          ? value?.map((val) => (
              <Chip
                key={val.value}
                label={val.label}
                handleDelete={() => setMultiDropdownOption(val)}
              />
            ))
          : null}

        {isOptionsListVisible && (
          <div className={classes.searchAndOptions} ref={optionsRef}>
            <div className={classes.search}>
              <input
                type="text"
                placeholder={t('common.search')}
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
              {searchInfo && (
                <Tooltip text={searchInfo} width={searchInfoWidth}>
                  <i>Info</i>
                </Tooltip>
              )}
            </div>
            <div className={classes.options}>
              <ul>
                {optionsToDisplay?.map((option, index) => (
                  <li
                    key={option.value}
                    onClick={() => {
                      setMultiDropdownOption(option);
                    }}
                    className={classNames({
                      [classes.active]: value.some?.(
                        (val) => val.value === option.value
                      ),
                      [classes.danger]: dangerOption === option.value,
                    })}
                    ref={
                      index === optionsToDisplay.length - 1
                        ? lastListElementRef
                        : null
                    }
                  >
                    <Checkbox
                      isSelected={value.some?.(
                        (val) => val.value === option.value
                      )}
                    />
                    {option.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
