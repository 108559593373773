import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import RightPanel from '../../Chat/RightPanel';
import ChatService from '../../../../services/ChatService';

import classes from './styles.module.scss';

export default function CandidateChat({
  candidate,
  chatThreads,
  refetchChatThreads,
}) {
  const { data: chatMessages, refetch: refetchMessages } = useQuery({
    queryKey: ['chatMessages', candidate?.id],
    queryFn: () => ChatService.getMessages({ jobApplicationId: candidate?.id }),
    enabled: !!candidate?.id,
    keepPreviousData: false,
  });
  const jobApplicationUserFeedbacks = useMemo(() => {
    const group = chatThreads?.find((grp) =>
      grp.threads.find((thread) => thread.jobApplicationId === candidate?.id)
    );

    return group?.threads.find(
      (thread) => thread.jobApplicationId === candidate?.id
    )?.jobApplicationUserFeedbacks;
  }, [chatThreads, candidate?.id]);

  useEffect(() => {
    if (chatMessages) {
      refetchChatThreads();
    }
  }, [chatMessages, refetchChatThreads]);

  return (
    <div className={classes.CandidateChat}>
      <RightPanel
        refetchChatThreads={refetchChatThreads}
        chatMessages={chatMessages}
        activeContact={candidate}
        refetchMessages={refetchMessages}
        jobApplicationUserFeedbacks={jobApplicationUserFeedbacks}
        messageListHeight={290}
        isJobApplicationOpened
      />
    </div>
  );
}
