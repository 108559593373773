import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import AdminService from '../../../../../services/AdminService';
import TextInput from '../../../../Form/TextInput';
import SidePopup from '../../SidePopup';

import useSwitchableData from '../../../../../hooks/useSwitchableData';
import { UiContext } from '../../../../../context/UiContext';
import classes from './styles.module.scss';

export default function ViewNewUserRequest({
  isVisible,
  currentNewUserId,
  newUsers,
  handleClose,
  refetch,
  setIsReviewNewUserRequestVisible,
  setSelectedRows,
  isOverlayed,
}) {
  const { showModal, setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableData(newUsers, currentNewUserId);

  const deleteUser = () => {
    showModal({
      icon: 'delete',
      title: t(
        'dashboardComponents.Console.UserManagement.ViewNewUserRequest.deleteConfirmation.title'
      ),
      text: t(
        'dashboardComponents.Console.UserManagement.ViewNewUserRequest.deleteConfirmation.text'
      ),
      onConfirm: async () => {
        try {
          setIsFetching(true);
          await AdminService.deleteNewUser({
            id: newUsers?.[currentDataIndex].id,
          });
          handleClose();
          refetch();
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      },
      onCancel: () => {},
      dismissButtonLabel: t('common.no'),
      confirmButtonLabel: t('common.yes'),
    });
  };

  const review = () => {
    setSelectedRows([newUsers[currentDataIndex].id]);
    setIsReviewNewUserRequestVisible(true);
  };

  return (
    <SidePopup
      leftButtonVariant="delete"
      isVisible={isVisible}
      handleClose={handleClose}
      title={t(
        'dashboardComponents.Console.UserManagement.ViewNewUserRequest.title'
      )}
      isOverlayed={isOverlayed}
      leftButtonLabel={t('common.delete')}
      rightButtonLabel={t('common.review')}
      onLeftButtonClick={deleteUser}
      onRightButtonClick={review}
      switchNext={newUsers?.length > 1 && switchToNextDataElement}
      switchPrev={newUsers?.length > 1 && switchToPrevDataElement}
    >
      <div className={classes.ViewNewUserRequest}>
        <div className={classes.requestDetails}>
          <div className={classes.column}>
            <TextInput
              value={newUsers?.[currentDataIndex]?.firstName}
              label={t(
                'dashboardComponents.Console.UserManagement.ViewNewUserRequest.labels.firstName'
              )}
              readonly
              height={50}
            />
            <TextInput
              label={t(
                'dashboardComponents.Console.UserManagement.ViewNewUserRequest.labels.email'
              )}
              value={newUsers?.[currentDataIndex]?.email}
              readonly
              height={50}
            />
            <TextInput
              label={t(
                'dashboardComponents.Console.UserManagement.ViewNewUserRequest.labels.city'
              )}
              value={newUsers?.[currentDataIndex]?.cities
                ?.map((city) => city.name)
                .join(', ')}
              readonly
              height={50}
            />
          </div>
          <div className={classes.column}>
            <TextInput
              label={t(
                'dashboardComponents.Console.UserManagement.ViewNewUserRequest.labels.lastName'
              )}
              value={newUsers?.[currentDataIndex]?.lastName}
              readonly
              height={50}
            />
            <TextInput
              height={50}
              readonly
              label={t(
                'dashboardComponents.Console.UserManagement.ViewNewUserRequest.labels.restaurant'
              )}
              value={newUsers?.[currentDataIndex]?.locations
                ?.map((location) => location.name)
                .join(', ')}
            />
          </div>
        </div>
      </div>
    </SidePopup>
  );
}
