import React, { useState, useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import CandidateModal from '../../../Discover/CandidateModal';
import Tooltip from '../../../../Tooltip';
import ArrowMenu from '../../../../ArrowMenu';
import SearchInput from '../../../../Form/SearchInput';
import FavStatus from '../../../Header/Search/FavStatus';
import AdminService from '../../../../../services/AdminService';

import { JobsContext } from '../../../../../context/JobsContext';
import ChatService from '../../../../../services/ChatService';
import { IMAGES_URL } from '../../../../../constants/main';
import classes from './styles.module.scss';

export default function RightPanelHeader({
  activeContact,
  refetchChatThreads,
  isJobApplicationOpened,
}) {
  const [isContactMenuVisible, setIsContactMenuVisible] = useState(false);
  const [isCandidateProfileVisible, setIsCandidateProfileVisible] =
    useState(false);

  const { showShareModal } = useContext(JobsContext);

  const { t } = useTranslation();

  const { data: favStatus, refetch: refecthFavStatus } = useQuery({
    queryKey: ['favStatus', activeContact],
    queryFn: () =>
      AdminService.getUserFavoriteStatus({ id: activeContact?.user?.id }),
    keepPreviousData: true,
  });

  const pinContact = async () => {
    try {
      await ChatService.pinContact({
        jobApplicationId: activeContact.jobApplicationId,
      });
      refetchChatThreads();
    } catch (error) {
      console.log(error);
    }
  };

  const unpinContact = async () => {
    try {
      await ChatService.unpinContact({
        jobApplicationId: activeContact.jobApplicationId,
      });
      refetchChatThreads();
    } catch (error) {
      console.log(error);
    }
  };

  const sendEmail = () => {
    window.location.href = `mailto:${activeContact.user.userName}`;
  };

  let options = [
    {
      label: t(
        'dashboardComponents.Chat.RightPanel.RightPanelHeader.Email Candidate'
      ),
      onClick: sendEmail,
    },
    {
      label: t('dashboardComponents.Chat.RightPanel.RightPanelHeader.Share'),
      onClick: () => {
        showShareModal({ activeContact });
      },
    },
  ];

  if (!isJobApplicationOpened) {
    options = [
      {
        label: `${
          activeContact?.isPinned
            ? t(
                'dashboardComponents.Chat.RightPanel.RightPanelHeader.Unpin Contact'
              )
            : t(
                'dashboardComponents.Chat.RightPanel.RightPanelHeader.Pin Contact'
              )
        }`,
        onClick: () => {
          if (activeContact.isPinned) {
            unpinContact();
          } else {
            pinContact();
          }
        },
      },
      ...options,
    ];
  }
  return (
    <div
      className={classes.RightPanelHeader}
      style={{
        marginTop: isJobApplicationOpened && '26px',
        border: isJobApplicationOpened && '3px solid #F8F9FB',
        borderRadius: isJobApplicationOpened && '16px',
        background: isJobApplicationOpened && '#FFF',
      }}
    >
      <div className={classes.col}>
        {activeContact?.user.userProfile.avatar ? (
          <img
            src={`${IMAGES_URL}/avatar/${activeContact?.user.userProfile.avatar}?format=png&mode=crop&width=200&height=200&q=70`}
            alt="User"
            className={classes.userPhoto}
          />
        ) : (
          <div className={classes.noAvatar}>
            {activeContact?.user.userProfile.firstName[0]}{' '}
            {activeContact?.user.userProfile.lastName[0]}
          </div>
        )}
        <div className={classes.user}>
          <div className={classes.userInfo}>
            <h4>
              {activeContact?.user.userProfile.firstName}{' '}
              {activeContact?.user.userProfile.lastName}
            </h4>
            <span className={classes.location}>
              {activeContact?.user.userProfile.city}
            </span>
          </div>
          <div className={classes.userFav}>
            <FavStatus
              candidate={activeContact?.user}
              refetch={refecthFavStatus}
              width={25}
              isCandidateFavorite={favStatus?.isFavorite}
            />
          </div>
          {!isJobApplicationOpened && (
            <button
              type="button"
              className={classes.profileButton}
              onClick={() => setIsCandidateProfileVisible(true)}
            >
              {t(
                'dashboardComponents.Chat.RightPanel.RightPanelHeader.View Profile'
              )}
            </button>
          )}
        </div>
      </div>
      <div
        className={classNames(classes.col, classes.searchInputContainer)}
        style={{ marginTop: isJobApplicationOpened && '6px' }}
      >
        <SearchInput searchHistory={[]} />
        <div className={classes.callButtonsContainer}>
          <Tooltip
            text={t(
              'dashboardComponents.Chat.RightPanel.RightPanelHeader.Coming soon'
            )}
            width={120}
          >
            <button className={classes.videoButton} type="button">
              Video Call
            </button>
          </Tooltip>
          <Tooltip
            text={t(
              'dashboardComponents.Chat.RightPanel.RightPanelHeader.Coming soon'
            )}
            width={120}
          >
            <button className={classes.audioButton} type="button">
              Audio Call
            </button>
          </Tooltip>
        </div>
        <div className={classes.dotsButtonContainer}>
          <ArrowMenu
            isVisible={isContactMenuVisible}
            setIsVisible={setIsContactMenuVisible}
            options={options}
            fixed={isJobApplicationOpened}
          >
            <button className={classes.dotsButton} type="button">
              dots
            </button>
          </ArrowMenu>
        </div>
      </div>
      <CandidateModal
        currentCandidate={activeContact?.user}
        show={isCandidateProfileVisible}
        handleClose={() => {
          setIsCandidateProfileVisible(false);
        }}
        refetch={refetchChatThreads}
      />
    </div>
  );
}
