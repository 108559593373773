import React from 'react';

import { useMediaQuery } from 'react-responsive';

import plusIcon from '../../../../../../../../assets/images/dashboard/candidates/plus-icon.svg';

import classes from './styles.module.scss';

export default function Experience({
  formik,
  warnings,
  inputs,
  renderInputs,
  addExperienceSubForm,
  index,
  touched,
  validatedEmail,
  isReadOnly,
}) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className={classes.Experience}>
      <div className={classes.inputs}>
        {inputs.map((input) =>
          renderInputs({
            input,
            formik,
            warnings,
            inputWidth: isTabletOrMobile ? '100%' : 239,
            customName: `userProfileExperiences[${index}].${input.name}`,
            touched,
            validatedEmail,
            isReadOnly,
          })
        )}
      </div>
      <button
        type="button"
        className={classes.addButton}
        onClick={addExperienceSubForm}
      >
        Add another experience
        <img src={plusIcon} alt="Add" />
      </button>
    </div>
  );
}
