/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useContext } from 'react';

import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import DropdownOptions from '../../../../../../DropdownOptions';

import { UiContext } from '../../../../../../../context/UiContext';
import { IMAGES_URL } from '../../../../../../../constants/main';
import useOnClickOutside from '../../../../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';
import ChatService from '../../../../../../../services/ChatService';

export default function Contact({
  contact,
  activeContactId,
  setActiveContactId,
  refetchChatThreads,
}) {
  const [isContactMenuVisible, setIsContactMenuVisible] = useState(false);

  const { setIsFetching } = useContext(UiContext);

  const contactRef = useRef();

  useOnClickOutside(contactRef, () => setIsContactMenuVisible(false));

  const { t } = useTranslation();

  const showContactMenu = (event) => {
    event.preventDefault();
    setIsContactMenuVisible(true);
  };

  const pinContact = async () => {
    try {
      setIsFetching(true);
      await ChatService.pinContact({
        jobApplicationId: contact.jobApplicationId,
      });
      await refetchChatThreads();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const unpinContact = async () => {
    try {
      setIsFetching(true);
      await ChatService.unpinContact({
        jobApplicationId: contact.jobApplicationId,
      });
      await refetchChatThreads();
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const markAllAsRead = async () => {
    try {
      await ChatService.readAllMessagesInThread({
        jobApplicationId: contact.jobApplicationId,
      });
      refetchChatThreads();
    } catch (error) {
      console.log(error);
    }
  };

  const createdAt = moment(contact.lastMessage?.createdAt);

  const isToday = createdAt.isSame(new Date(), 'day');
  const isYesterday = createdAt.isSame(moment().subtract(1, 'day'), 'day');

  let dateToDisplay = createdAt.format('Do MMM');

  if (isToday) {
    dateToDisplay = createdAt.format('hh:mm A');
  } else if (isYesterday) {
    dateToDisplay = 'Yesterday';
  }

  return (
    <li
      ref={contactRef}
      onContextMenu={showContactMenu}
      className={classNames(classes.Contact, {
        [classes.active]: activeContactId === contact.jobApplicationId,
      })}
      onClick={() => setActiveContactId(contact.jobApplicationId)}
    >
      {isContactMenuVisible && (
        <DropdownOptions
          left={206}
          top={84}
          options={[
            `${
              contact.isPinned
                ? t(
                    'dashboardComponents.Chat.LeftPanel.Contacts.ContactsList.Contact.unpin'
                  )
                : t(
                    'dashboardComponents.Chat.LeftPanel.Contacts.ContactsList.Contact.pin'
                  )
            }`,
            t(
              'dashboardComponents.Chat.LeftPanel.Contacts.ContactsList.Contact.mark'
            ),
          ]}
          width={154}
          changeOption={(option) => {
            if (
              option ===
              t(
                'dashboardComponents.Chat.LeftPanel.Contacts.ContactsList.Contact.pin'
              )
            ) {
              pinContact();
            } else if (
              option ===
              t(
                'dashboardComponents.Chat.LeftPanel.Contacts.ContactsList.Contact.unpin'
              )
            ) {
              unpinContact();
            } else if (
              option ===
              t(
                'dashboardComponents.Chat.LeftPanel.Contacts.ContactsList.Contact.mark'
              )
            ) {
              markAllAsRead();
            }

            setIsContactMenuVisible(false);
          }}
        />
      )}
      <div className={classes.photoContainer}>
        {contact.user.userProfile.avatar ? (
          <img
            src={`${IMAGES_URL}/avatar/${contact.user.userProfile.avatar}?format=png&mode=crop&width=200&height=200&q=70`}
            alt="User"
          />
        ) : (
          <div className={classes.noAvatar}>
            {contact.user.userProfile.firstName[0]}{' '}
            {contact.user.userProfile.lastName[0]}
          </div>
        )}
      </div>
      <div className={classes.messageContainer}>
        <h4>
          {contact.user.userProfile.firstName}{' '}
          {contact.user.userProfile.lastName}
        </h4>
        <p className={classes.message}>{contact.lastMessage?.message}</p>
      </div>
      <div className={classes.infoContainer}>
        <div
          className={classes.createdAt}
          style={{ visibility: contact.lastMessage ? 'visible' : 'hidden' }}
        >
          {dateToDisplay}
        </div>
        {contact.unreadCount ? (
          <div className={classes.unreadMessageCount}>
            {contact.unreadCount}
          </div>
        ) : null}
      </div>
    </li>
  );
}
