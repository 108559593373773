import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import RefreshButton from '../../../Candidate/AssessmentAndInterview/Assessment/RefreshButton';
import Delegates from '../JobForm/Delegates';
import VrAndAssessment from '../../../Console/JobManagement/JobTemplates/Template/VrAndAssessment';
import BasicInfo from '../../../Console/JobManagement/JobTemplates/Template/BasicInfo';

import classes from './styles.module.scss';

const icon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11.4349 16.6402L18.1016 8.64018L16.5651 7.35982L10.5995 14.5186L7.37377 11.2929L5.95956 12.7071L9.95956 16.7071L10.7339 17.4814L11.4349 16.6402Z"
      fill="#C1C5D6"
    />
  </svg>
);

export default function ReviewJobForm({
  basicInfoFormik,
  vrFormik,
  delegatesFormik,
  isDelegated,
  setIsDelegated,
  vrQuestions,
  setVrQuestions,
  useDefaultVrQuestions,
  setUseDefaultVrQuestions,
  hasDuration,
  setHasDuration,
  areVrQuestionsValid,
  readOnly,
  style,
  isTriedToSubmit,
  isEdit,
  assessments,
  setAssessments,
  jobPostDetails,
  jobPostId,
}) {
  const [isStepOneVisible, setIsStepOneVisible] = useState(false);
  const [isStepTwoVisible, setIsStepTwoVisible] = useState(false);
  const [isStepThreeVisible, setIsStepThreeVisible] = useState(false);

  const { t } = useTranslation();

  return (
    <div className={classes.ReviewJobForm} style={style}>
      <div className={classes.stepContainer}>
        <div
          className={classNames(classes.step, {
            [classes.active]: isStepOneVisible,
          })}
          onClick={() => setIsStepOneVisible((prevState) => !prevState)}
        >
          <div>
            <span>
              {t('dashboardComponents.Jobs.PostAJob.ReviewJobForm.step', {
                number: 1,
              })}
            </span>
            <h1>
              {t('dashboardComponents.Jobs.PostAJob.ReviewJobForm.stepOne')}
            </h1>
          </div>
          {icon}
        </div>
        {isStepOneVisible && (
          <BasicInfo
            isTriedToSubmit={isTriedToSubmit}
            formik={basicInfoFormik}
            readOnly={readOnly}
            isEdit={isEdit}
          />
        )}
      </div>
      <div className={classes.stepContainer}>
        <div
          className={classNames(classes.step, {
            [classes.active]: isStepTwoVisible,
          })}
          onClick={() => setIsStepTwoVisible((prevState) => !prevState)}
        >
          <div>
            <span>
              {t('dashboardComponents.Jobs.PostAJob.ReviewJobForm.step', {
                number: 2,
              })}
            </span>
            <h1>
              {t('dashboardComponents.Jobs.PostAJob.ReviewJobForm.stepTwo')}
            </h1>
          </div>
          <div className={classes.refreshButtonContainer}>
            {jobPostDetails && <RefreshButton jobPostId={jobPostId} />}
            {icon}
          </div>
        </div>
        {isStepTwoVisible && (
          <VrAndAssessment
            readOnly={readOnly}
            formik={vrFormik}
            vrQuestions={vrQuestions}
            setVrQuestions={setVrQuestions}
            useDefaultVrQuestions={useDefaultVrQuestions}
            setUseDefaultVrQuestions={setUseDefaultVrQuestions}
            isTriedToSubmit={isTriedToSubmit}
            isJobPostForm
            areVrQuestionsValid={areVrQuestionsValid}
            assessments={assessments}
            setAssessments={setAssessments}
          />
        )}
      </div>
      <div className={classes.stepContainer}>
        <div
          className={classNames(classes.step, {
            [classes.active]: isStepThreeVisible,
          })}
          onClick={() => setIsStepThreeVisible((prevState) => !prevState)}
        >
          <div>
            <span>
              {t('dashboardComponents.Jobs.PostAJob.ReviewJobForm.step', {
                number: 3,
              })}
            </span>
            <h1>
              {t('dashboardComponents.Jobs.PostAJob.ReviewJobForm.stepThree')}
            </h1>
          </div>
          {icon}
        </div>
        {isStepThreeVisible && (
          <Delegates
            isDelegatesTriedToSubmit={isTriedToSubmit}
            readOnly={readOnly}
            delegatesFormik={delegatesFormik}
            isDelegated={isDelegated}
            setIsDelegated={setIsDelegated}
            hasDuration={hasDuration}
            setHasDuration={setHasDuration}
          />
        )}
      </div>
    </div>
  );
}
