import React, { useState, useRef, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import File from './File';

import { UiContext } from '../../../../../../context/UiContext';
import FeedService from '../../../../../../services/FeedService';
import cloudIcon from '../../../../../../assets/images/dashboard/discover/textEditor/cloud.svg';
import uploadIcon from '../../../../../../assets/images/dashboard/discover/textEditor/upload.svg';
import classes from './styles.module.scss';

const allowedFileTypes = [
  'pdf',
  'jpg',
  'jpeg',
  'png',
  'doc',
  'docx',
  'zip',
  'ppt',
  'mp3',
  'xls',
  'xlsx',
  'mp4',
  'avi',
];

export default function Files({
  files = [],
  setFiles,
  setUploadedFiles,
  setFileNames,
  fileNames = [],
  insertFile,
}) {
  const [fileUploadProgress, setFileUploadProgress] = useState([]);

  const fileInputRef = useRef();

  const { showModal } = useContext(UiContext);

  const { t } = useTranslation();

  const addDroppedFile = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];

    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (!allowedFileTypes.includes(fileExtension)) {
      return;
    }
    if (file.size > 25 * 1024 * 1024) {
      showModal({
        type: 'error',
        title: t(
          'dashboardComponents.Discover.AddContent.TextEditor.Files.fileSizeLimitExceededTitle'
        ),
        text: t(
          'dashboardComponents.Discover.AddContent.TextEditor.Files.fileSizeLimitExceededText'
        ),
      });

      return;
    }

    if (files.some((f) => f.name === file.name)) {
      return;
    }

    setFiles((prevState) => [...prevState, file]);
    setFileNames((prevState) => [...prevState, file.name]);
  };

  const uploadFile = async (file) => {
    try {
      const uploadedFile = await FeedService.uploadFile(file, (progress) => {
        setFileUploadProgress((prev) => {
          if (!prev.some((item) => item.name === file.name)) {
            return [...prev, { name: file.name, progress }];
          }
          return prev.map((item) => {
            if (item.name === file.name) {
              return { ...item, progress };
            }
            return item;
          });
        });
      });

      setUploadedFiles((prevFiles) => {
        return prevFiles
          ? [...prevFiles, { ...uploadedFile, fileSize: file.size }]
          : [{ ...uploadedFile, fileSize: file.size }];
      });
    } catch (error) {
      console.log(error);
    }
  };

  const processFiles = () => {
    let newFiles = Array.from(fileInputRef.current.files);

    newFiles = newFiles.filter(
      (file) => !files?.some((f) => f.name === file.name)
    );

    if (!newFiles.length) {
      fileInputRef.current.value = '';
      return;
    }

    if (newFiles.some((nf) => nf.size > 25 * 1024 * 1024)) {
      showModal({
        type: 'error',
        title: t(
          'dashboardComponents.Discover.AddContent.TextEditor.Files.fileSizeLimitExceededTitle'
        ),
        text: t(
          'dashboardComponents.Discover.AddContent.TextEditor.Files.fileSizeLimitExceededText'
        ),
      });
      fileInputRef.current.value = '';
      return;
    }

    setFiles([...files, ...newFiles]);
    setFileNames([...fileNames, ...newFiles.map((file) => file.name)]);
    fileInputRef.current.value = '';

    newFiles.forEach((file) => uploadFile(file));
  };

  return (
    <div className={classes.Files}>
      {files?.length ? (
        <div className={classes.files}>
          {files.map((file, index) => (
            <File
              index={index}
              key={file.name}
              fileName={fileNames?.[index]}
              file={file}
              progress={
                fileUploadProgress.find((item) => item.name === file.name)
                  ?.progress
              }
              setFiles={setFiles}
              setUploadedFiles={setUploadedFiles}
              setFileNames={setFileNames}
              insertFile={insertFile}
            />
          ))}
        </div>
      ) : null}
      <div className={classes.uploader}>
        <button
          type="button"
          className={classes.uploadButton}
          onClick={() => fileInputRef.current.click()}
        >
          <img src={uploadIcon} alt="Upload" />
          {t(
            'dashboardComponents.Discover.AddContent.TextEditor.Files.uploadButton'
          )}
        </button>
        <button type="button" className={classes.uploadButton}>
          <img src={cloudIcon} alt="Cloud" />
          {t(
            'dashboardComponents.Discover.AddContent.TextEditor.Files.myDriveButton'
          )}
        </button>
        <div
          className={classes.dropzone}
          onDragOver={(event) => event.preventDefault()}
          onDrop={addDroppedFile}
        >
          {t(
            'dashboardComponents.Discover.AddContent.TextEditor.Files.dropzone'
          )}
        </div>
        <input
          multiple
          ref={fileInputRef}
          type="file"
          onChange={processFiles}
          accept={allowedFileTypes.map((fileType) => `.${fileType}`).join(',')}
        />
      </div>
    </div>
  );
}
