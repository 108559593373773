import React, { useRef } from 'react';
import useOnClickOutside from '../../../../../../../../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function GraphDetail({
  name,
  count,
  color,
  top,
  left,
  handleClose,
  graphBottom,
}) {
  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, () => {
    handleClose();
  });

  console.log(top, graphBottom);

  return (
    <div
      className={classes.GraphDetail}
      style={{
        top: top - 17,
        left,
      }}
      ref={dropdownRef}
    >
      <div className={classes.label}>
        <span className={classes.name}>{name}</span>
        <span className={classes.amount}>{count}</span>
      </div>

      <div
        className={classes.reference}
        style={{
          height: graphBottom - top,
          borderColor: color,
        }}
      />
    </div>
  );
}
