import React from 'react';

import Tabs from './Tabs';
import SettingsContainer from './SettingsContainer';

import classes from './styles.module.scss';

export default function LeftPanel({
  tabs,
  activeTab,
  setActiveTab,
  brandName,
  setBrandName,
  logo,
  setLogo,
  favicon,
  setFavicon,
  brandColors,
  setBrandColors,
  titleFont,
  setTitleFont,
  subTitleFont,
  setSubTitleFont,
  bodyTextFont,
  setBodyTextFont,
  bodySubTextFont,
  setBodySubTextFont,
  tenantSettings,
  refetchTenantSettings,
  savedLogoSettings,
  setSavedLogoSettings,
  savedColorSettings,
  setSavedColorSettings,
  savedFontSettings,
  setSavedFontSettings,
}) {
  return (
    <div className={classes.LeftPanel}>
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      <SettingsContainer
        tenantSettings={tenantSettings}
        refetchTenantSettings={refetchTenantSettings}
        activeTab={activeTab}
        brandName={brandName}
        setBrandName={setBrandName}
        logo={logo}
        setLogo={setLogo}
        favicon={favicon}
        setFavicon={setFavicon}
        brandColors={brandColors}
        setBrandColors={setBrandColors}
        titleFont={titleFont}
        setTitleFont={setTitleFont}
        subTitleFont={subTitleFont}
        setSubTitleFont={setSubTitleFont}
        bodyTextFont={bodyTextFont}
        setBodyTextFont={setBodyTextFont}
        bodySubTextFont={bodySubTextFont}
        setBodySubTextFont={setBodySubTextFont}
        savedLogoSettings={savedLogoSettings}
        setSavedLogoSettings={setSavedLogoSettings}
        savedColorSettings={savedColorSettings}
        setSavedColorSettings={setSavedColorSettings}
        savedFontSettings={savedFontSettings}
        setSavedFontSettings={setSavedFontSettings}
      />
    </div>
  );
}
