import React, { useContext, useEffect, useRef, useState } from 'react';

import { useReactToPrint } from 'react-to-print';
import { useQuery } from '@tanstack/react-query';

import { useTranslation } from 'react-i18next';
import SaveChangesModal from './RightPanel/SaveChangesModal';

import { JOBS_API_URL, TENANT_ID } from '../../../constants/main';
import { getAuthData } from '../../../helpers/authStorage';
import { PreventNavigation } from '../../../context/PreventNavigationContext';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import classes from './styles.module.scss';
import ReportsService from '../../../services/ReportsService';

const reportsUrl = {
  Candidates: '/reports/candidates',
  Jobs: '/reports/jobs',
  Applications: '/reports/applications',
  Assessments: '/reports/assessments',
  Interviews: '/reports/interviews',
  Offers: '/reports/offers',
  Onboardings: '/reports/onboardings',
  Joinings: '/reports/joinings',
};

export default function Reports() {
  const [activeReport, setActiveReport] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activeSortHeader, setActiveSortHeader] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [fields, setFields] = useState([]);
  const [isLeftPanelOpened, setIsLeftPanelOpened] = useState(true);
  const [isSavedReportModalVisible, setIsSavedReportModalVisible] =
    useState(false);
  const [nextActiveReport, setNextActiveReport] = useState(null);
  const [nextSeachTerm, setNextSeachTerm] = useState(null);
  const [nextActiveSortHeader, setNextActiveSortHeader] = useState(null);
  const [isReportAlreadyAdded, setIsReportAlreadyAdded] = useState(false);

  const { setIsNavigationAllowed, showModal } = useContext(PreventNavigation);

  const tableRef = useRef(null);

  const { t } = useTranslation();

  const reportTypes = [
    t('dashboardComponents.Dashboard.Reports.Candidate Report'),
    t('dashboardComponents.Dashboard.Reports.Jobs Report'),
    t('dashboardComponents.Dashboard.Reports.New Applicants'),
    t('dashboardComponents.Dashboard.Reports.Assessment'),
    t('dashboardComponents.Dashboard.Reports.Interview'),
    t('dashboardComponents.Dashboard.Reports.Offer'),
    t('dashboardComponents.Dashboard.Reports.Onboarding'),
    t('dashboardComponents.Dashboard.Reports.Joining'),
  ];

  const { data: savedReports, refetch } = useQuery({
    queryKey: ['savedReports'],
    queryFn: () =>
      ReportsService.getStoredReports({ term: '', pageSize: 99999 }),
    keepPreviousData: true,
  });

  const { data: reports } = useQuery({
    queryKey: [
      `${activeReport?.title}`,
      searchTerm,
      activeSortHeader,
      sortOrder,
      fields,
      activeReport,
    ],
    queryFn: () =>
      ReportsService.getReports({
        url: reportsUrl[activeReport?.type] || '/candidates',
        term: searchTerm,
        orderBy: activeSortHeader ? `${activeSortHeader}:${sortOrder}` : null,
        fields,
        pageSize: 999999,
      }),
    keepPreviousData: true,
  });

  const savedActiveReport = savedReports?.find(
    (rep) => rep?.title === activeReport?.title
  );

  const isActiveReportChanged =
    (!isReportAlreadyAdded &&
      savedActiveReport &&
      (savedActiveReport?.term !== searchTerm ||
        savedActiveReport?.orderBy !== activeSortHeader)) ||
    (!savedActiveReport && (searchTerm || activeSortHeader));

  useEffect(() => {
    setIsReportAlreadyAdded(false);
  }, [searchTerm, sortOrder, activeSortHeader]);

  useEffect(() => {
    setActiveReport(
      savedReports?.find((report) => report?.id === activeReport?.id) ||
        activeReport
    );
  }, [activeReport, savedReports]);

  useEffect(() => {
    if (activeReport?.fields) {
      setFields(activeReport?.fields);
      return;
    }
    if (savedActiveReport) {
      setFields(savedActiveReport.fields);
      return;
    }

    setFields(reports?.mandatoryFields);
  }, [activeReport, reports, savedReports, savedActiveReport]);

  useEffect(() => {
    if (isActiveReportChanged) {
      setIsNavigationAllowed(false);
    } else {
      setIsNavigationAllowed(true);
    }
  }, [isActiveReportChanged, setIsNavigationAllowed]);

  useEffect(() => {
    if (showModal) {
      setIsSavedReportModalVisible(true);
    }
  }, [showModal]);

  const changeActiveReport = (report) => {
    if (isActiveReportChanged) {
      setIsSavedReportModalVisible(true);
      setNextActiveReport(report);
      setNextSeachTerm(report?.term || '');
      setNextActiveSortHeader(report?.orderBy || '');

      return;
    }

    setActiveReport(report);
    setSearchTerm(report?.term || '');
    setActiveSortHeader(report?.orderBy || '');
  };

  const downloadReport = (report) => {
    const { accessToken } = getAuthData();

    const fetchReportFile = (url, title) => {
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Tenant-Id': TENANT_ID,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const fileUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = `${title}`;
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (report.title === activeReport.title) {
      const sort = activeSortHeader
        ? `&orderBy=${activeSortHeader}:${sortOrder}`
        : '';

      const url = `${JOBS_API_URL}${
        reportsUrl[activeReport?.type]
      }/export?term=${searchTerm}${sort}${
        fields.map((field) => `&fields=${field}`).join('') || ''
      }`;

      fetchReportFile(url, report.title);
    } else {
      const reportFields = report?.fields;
      const sort = report?.orderBy
        ? `&orderBy=${report?.orderBy}:${sortOrder}`
        : '';

      const url = `${JOBS_API_URL}${reportsUrl[report?.type]}/export?term=${
        report?.term
      }${sort}${
        reportFields?.map((field) => `&fields=${field}`).join('') || ''
      }`;

      fetchReportFile(url, report.title);
    }
  };

  const printReport = useReactToPrint({
    content: () => tableRef.current,
  });

  return (
    <div className={classes.Reports}>
      <LeftPanel
        savedReports={savedReports}
        refetch={refetch}
        activeReport={activeReport}
        setActiveReport={changeActiveReport}
        isLeftPanelOpened={isLeftPanelOpened}
        setIsLeftPanelOpened={setIsLeftPanelOpened}
        downloadReport={downloadReport}
        printReport={printReport}
        reportTypes={reportTypes}
      />
      <RightPanel
        activeReport={activeReport}
        reports={reports}
        refetch={refetch}
        fields={fields}
        setFields={setFields}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        activeSortHeader={activeSortHeader}
        setActiveSortHeader={setActiveSortHeader}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        isLeftPanelOpened={isLeftPanelOpened}
        setIsLeftPanelOpened={setIsLeftPanelOpened}
        downloadReport={downloadReport}
        tableRef={tableRef}
        printReport={printReport}
        savedReports={savedReports}
        reportTypes={reportTypes}
        setIsReportAlreadyAdded={setIsReportAlreadyAdded}
      />
      {isSavedReportModalVisible && (
        <SaveChangesModal
          show={isSavedReportModalVisible}
          text="Changes will be lost. Would you like to save the changes?"
          handleClose={() => {
            setActiveReport(nextActiveReport);
            setSearchTerm(nextSeachTerm || '');
            setActiveSortHeader(nextActiveSortHeader || '');
            setIsSavedReportModalVisible(false);
            setIsReportAlreadyAdded(true);
          }}
          reports={reports}
          activeReport={activeReport}
          searchTerm={searchTerm}
          activeSortHeader={activeSortHeader}
          refetch={refetch}
          savedReports={savedReports}
          reportTypes={reportTypes}
        />
      )}
    </div>
  );
}
