import React, { useState, useContext, useMemo } from 'react';

import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UiContext } from '../../context/UiContext';
import Button from '../../components/Button';
import TextInput from '../../components/Form/TextInput';
import AuthLayout from '../../containers/AuthLayout';
import classes from './styles.module.scss';
import UserService from '../../services/UserService';

export default function SetNewPasswordPage() {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { showUnknownErrorModal, showModal, setIsFetching } =
    useContext(UiContext);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const validationErrorMessages = {
    length: t('pages.SetNewPasswordPage.passwordValidationError'),
    upperAndLowerCase: t(
      'pages.SetNewPasswordPage.upperLowerCaseValidationError'
    ),
    number: t('pages.SetNewPasswordPage.numberValidationError'),
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        password: yup
          .string()
          .required(t('pages.SetNewPasswordPage.requiredFieldError')),
        /*     .min(8, validationErrorMessages.length)
    .matches(/^(?=.*[a-z])/, validationErrorMessages.upperAndLowerCase)
    .matches(/^(?=.*[A-Z])/, validationErrorMessages.upperAndLowerCase)
    .matches(/^(?=.*[0-9])/, validationErrorMessages.number), */
        confirmPassword: yup
          .string()
          .required(t('pages.SetNewPasswordPage.requiredFieldError'))
          .oneOf(
            [yup.ref('password'), null],
            t('pages.SetNewPasswordPage.confirmPasswordValidationError')
          ),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFetching(true);
        await UserService.setPassword({ token, password: values.password });
        showModal({
          title: t('pages.SetNewPasswordPage.successTitle'),
          text: t('pages.SetNewPasswordPage.successText'),
        });
        navigate('/login');
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        setIsFetching(false);
      }
    },
  });

  return (
    <AuthLayout>
      <div className={classes.SetNewPasswordPage}>
        <Helmet>
          <title>{t('pages.SetNewPasswordPage.pageTitle')}</title>
        </Helmet>
        <div className={classes.container}>
          <h1>{t('pages.SetNewPasswordPage.pageHeader')}</h1>
          <form onSubmit={formik.handleSubmit}>
            <TextInput
              type="password"
              error={
                !Object.values(validationErrorMessages).includes(
                  formik.errors.password
                ) && formik.errors.password
              }
              // warning={
              //   Object.values(validationErrorMessages).includes(
              //     formik.errors.password
              //   ) && formik.errors.password
              // }
              touched={isTriedToSubmit}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t('pages.SetNewPasswordPage.newPasswordLabel')}
              name="password"
              placeholder={t('pages.SetNewPasswordPage.newPasswordPlaceholder')}
              height={55}
            />
            <TextInput
              type="password"
              error={formik.errors.confirmPassword}
              touched={isTriedToSubmit}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t('pages.SetNewPasswordPage.reenterPasswordLabel')}
              name="confirmPassword"
              placeholder={t(
                'pages.SetNewPasswordPage.reenterPasswordPlaceholder'
              )}
              height={55}
            />
            <Button
              disabled={
                !formik.values.password && !formik.values.confirmPassword
              }
              width={400}
              onClick={() => {
                setIsTriedToSubmit(true);
                formik.handleSubmit();
              }}
            >
              {t('pages.SetNewPasswordPage.submitButton')}
            </Button>
          </form>
        </div>
      </div>
    </AuthLayout>
  );
}
