import React from 'react';

import FilterTabs from '../../FilterTabs';
import Details from './Details';
import Resume from './Resume';
import Applications from './Applications';

import classes from './styles.module.scss';

export default function Main({
  detailsMainInputs,
  formik,
  warnings,
  experienceInputs,
  addExperienceSubForm,
  validatedEmail,
  touched,
  filters,
  tabCount,
  currentTab,
  setCurrentTab,
  currentCandidate,
  isPossibleToEdit,
  isReadOnly,
}) {
  return (
    <div className={classes.Main}>
      <FilterTabs
        maxWidth={498}
        filters={filters}
        translationPath="dashboardComponents.Candidates.CandidatesList.AllCandidates.AddCandidate.headerTabs"
        count={tabCount}
        activeFilter={currentTab}
        setActiveFilter={setCurrentTab}
      />
      {currentTab === 'Details' && (
        <Details
          inputs={detailsMainInputs}
          formik={formik}
          warnings={warnings}
          experienceInputs={experienceInputs}
          addExperienceSubForm={addExperienceSubForm}
          validatedEmail={validatedEmail}
          touched={touched}
          resume={formik.values.resume}
          isReadOnly={isReadOnly}
        />
      )}
      {currentTab === 'Word Resume' && (
        <Resume
          formik={formik}
          isPossibleToEdit={isPossibleToEdit}
          isReadOnly={isReadOnly}
          isEdit={!!currentCandidate}
        />
      )}
      {currentTab === 'Applications' && (
        <Applications
          currentCandidate={currentCandidate}
          isPossibleToEdit={isPossibleToEdit}
          formik={formik}
          isReadOnly={isReadOnly}
          touched={touched}
        />
      )}
    </div>
  );
}
