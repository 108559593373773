import React from 'react';

import { useMediaQuery } from 'react-responsive';

import Tooltip from '../../../../../../../Tooltip';

import infoIcon from '../../../../../../../../assets/images/dashboard/brand-kit/info.svg';
import classes from './styles.module.scss';

export default function TitleWithTooltip({ title, text, tooltipWidth }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className={classes.TitleWithTooltip}>
      <h2>{title}</h2>
      <Tooltip
        text={text}
        fixed
        direction="bottom"
        width={isTabletOrMobile ? '200px' : tooltipWidth}
      >
        <img src={infoIcon} alt="Info" />
      </Tooltip>
    </div>
  );
}
