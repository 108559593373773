import React, { useState, useRef } from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Note({ text, style }) {
  const [isEnoughSpace, setIsEnoughSpace] = useState(true);

  const noteRef = useRef();

  const checkIfEnoughSpaceBelow = () => {
    const noteHeight = noteRef.current.offsetHeight;

    const windowHeight = window.innerHeight;

    const noteTop = noteRef.current.getBoundingClientRect().top;

    const spaceBelow = windowHeight - noteTop;

    if (spaceBelow < noteHeight) {
      setIsEnoughSpace(false);
    } else {
      setIsEnoughSpace(true);
    }
  };

  return (
    <div
      className={classNames(classes.Note, {
        [classes.onTop]: !isEnoughSpace,
      })}
      style={style}
      onMouseEnter={checkIfEnoughSpaceBelow}
      onMouseLeave={checkIfEnoughSpaceBelow}
    >
      <div className={classes.noteContent} ref={noteRef}>
        {text}
      </div>
    </div>
  );
}
