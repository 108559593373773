import React from 'react';

import { useQuery } from '@tanstack/react-query';
import QRCode from 'qrcode.react';

import { IMAGES_URL } from '../../../constants/main';
import logo from '../../../assets/images/dashboard/jobs/KFC.png';
import AdminService from '../../../services/AdminService';

export default function QRCodeGenerator({ value }) {
  const { data } = useQuery({
    queryKey: ['tenantSettings'],
    queryFn: AdminService.getTenantSettings,
  });

  const logoUrl = data?.brand?.logo
    ? `${IMAGES_URL}/brand/${data.brand.logo.split('/').pop()}`
    : logo;

  return (
    <QRCode
      renderAs="svg"
      value={value}
      size={150}
      level="H"
      imageSettings={{
        src: logoUrl,
        height: 39,
        width: 86,
        excavate: true,
      }}
    />
  );
}
