/* eslint-disable no-shadow */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Template from '../Template';

import { convertTo12HourFormat, addOneHour } from '../../../../../helpers/time';
import {
  getTimezoneDate,
  getTimezoneTime,
} from '../../../../../helpers/timezones';

export default function Interview({
  deleteNotification,
  isRead,
  notification,
  classes,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const createNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const goToCalendar = (date) => {
      navigate(
        `/tools/calendar?date=${date}&event=Interview&id=${notification.jobApplication?.jobApplicationInterviews[0]?.id}`
      );
      hideNotifications();
    };

    const goToJob = () => {
      navigate(
        `/tools/jobs/my-jobs/${notification.jobApplication?.jobPost?.id}`
      );
      hideNotifications();
    };

    const content = {
      InterviewDeclined: {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Interview.interviewDeclined.title'
        )} ${notification.jobApplication?.jobPost?.jobTitle} (${
          notification.jobApplication?.jobPost?.id
        })`,
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: '',
        onClick: () => {},
      },
      InterviewAccepted: {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Interview.interviewAccepted.title'
        )} ${notification.jobApplication?.jobPost?.jobTitle} (${
          notification.jobApplication?.jobPost?.id
        })`,
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: '',
        onClick: () => {},
      },
      InterviewChangeRequested: {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Interview.interviewChangeRequested.title'
        )} ${notification.jobApplication?.jobPost?.jobTitle} (${
          notification.jobApplication?.jobPost?.id
        })`,
        text: `Proposed: ${moment(
          getTimezoneDate(
            notification.jobApplication?.jobApplicationInterviews?.[0]
              ?.suggestedInterviewDate,
            userTimezone,
            notification.jobApplication?.jobApplicationInterviews?.[0]
              ?.suggestedStartTime
          )[0]
        ).format('Do MMM YYYY')} ${convertTo12HourFormat(
          getTimezoneTime(
            notification.jobApplication?.jobApplicationInterviews?.[0]
              ?.suggestedStartTime,
            userTimezone
          )
        )} - ${addOneHour(
          getTimezoneTime(
            notification.jobApplication?.jobApplicationInterviews?.[0]
              ?.suggestedStartTime,
            userTimezone
          )
        )}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Interview.review'
        ),
        onClick: () =>
          goToCalendar(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationInterviews?.[0]
                ?.suggestedInterviewDate,
              userTimezone,
              notification.jobApplication?.jobApplicationInterviews?.[0]
                ?.suggestedStartTime
            )[0]
          ),
      },
    };

    return (
      content[notification.name] || {
        title: notification.eventNote,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Interview.review'
        ),
        onClick: goToJob,
      }
    );
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
