import { useState, useEffect } from 'react';

export default function useSwitchableData(data, currentDataId) {
  const [currentDataIndex, setCurrentDataIndex] = useState();

  useEffect(() => {
    const dataIndex = data?.findIndex?.((user) => user.id === currentDataId);

    if (dataIndex !== -1) {
      setCurrentDataIndex(dataIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataId]);

  const switchToPrevDataElement = () => {
    setCurrentDataIndex((prevIndex) => {
      if (prevIndex === 0) {
        return data.length - 1;
      }
      return prevIndex - 1;
    });
  };

  const switchToNextDataElement = () => {
    setCurrentDataIndex((prevIndex) => {
      if (prevIndex === data.length - 1) {
        return 0;
      }
      return prevIndex + 1;
    });
  };

  return {
    currentDataIndex,
    switchToPrevDataElement,
    switchToNextDataElement,
  };
}
