import React from 'react';

import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

function calculatePercentage(value, totalCount) {
  return Math.round((value / totalCount) * 100);
}

export default function RecruitmentFunnel({ data }) {
  const { t } = useTranslation();

  const total =
    data?.newApplications.current +
    data?.assessmentApplications.current +
    data?.interviewedApplications.current +
    data?.offeredApplications.current +
    data?.onboardApplications.current +
    data?.joinedApplications.current;

  const stats = [
    {
      label: t('dashboardComponents.Dashboard.LeftPanel.RecruitmentFunnel.New'),
      value: data?.newApplications.current,
      dynamic: `${
        calculatePercentage(data?.newApplications.current, total) || 0
      }%`,
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.RecruitmentFunnel.Assessment'
      ),
      value: data?.assessmentApplications.current,
      dynamic: `${
        calculatePercentage(data?.assessmentApplications.current, total) || 0
      }%`,
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.RecruitmentFunnel.Interview'
      ),
      value: data?.interviewedApplications.current,
      dynamic: `${
        calculatePercentage(data?.interviewedApplications.current, total) || 0
      }%`,
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.RecruitmentFunnel.Offer'
      ),
      value: data?.offeredApplications.current,
      dynamic: `${
        calculatePercentage(data?.offeredApplications.current, total) || 0
      }%`,
    },
    {
      label: t(
        'dashboardComponents.Dashboard.LeftPanel.RecruitmentFunnel.Onboard'
      ),
      value:
        data?.onboardApplications.current + data?.joinedApplications.current,
      dynamic: `${
        calculatePercentage(
          data?.onboardApplications.current + data?.joinedApplications.current,
          total
        ) || 0
      }%`,
    },
  ];

  return (
    <div className={classes.RecruitmentFunnel}>
      <div className={classes.header}>
        <h2>
          {t('dashboardComponents.Dashboard.LeftPanel.RecruitmentFunnel.Title')}
        </h2>
      </div>
      {!data ? (
        <div className={classes.noData}>
          <p>
            {t(
              'dashboardComponents.Dashboard.LeftPanel.RecruitmentFunnel.NoDataMessage'
            )}
          </p>
        </div>
      ) : (
        <ul>
          {stats.map((stat) => {
            return (
              <li key={stat.label}>
                <div className={classes.title}>{stat.label}</div>
                <div className={classes.data}>
                  <span className={classes.value}>{stat.value}</span>
                  <span className={classes.dynamic}>{stat.dynamic}</span>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
