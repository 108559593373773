import React, { useState, useEffect, Suspense } from 'react';
import { useQuery } from '@tanstack/react-query';

import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import AdminService from '../../../../services/AdminService';

import classes from './styles.module.scss';
import CandidateHeader from '../../Candidate/CandidateHeader';

import Applications from '../../Candidate/Applications';
import Details from '../../Candidate/Details';
import WordResume from '../../Candidate/WordResume';

export default function CandidateModal({
  show,
  handleClose,
  currentCandidate,
  refetch,
}) {
  const { data: candidatesData } = useQuery({
    queryKey: ['candidates'],
    queryFn: () => AdminService.getCandidates({ pageSize: 9999 }),
  });

  const jobApplications = candidatesData?.find(
    (cand) => cand.id === (currentCandidate?.user?.id || currentCandidate?.id)
  )?.jobApplications;

  const { t } = useTranslation();

  const forms = [
    {
      tab: t('dashboardComponents.Discover.CandidateModal.formDetails'),
    },
    {
      tab: t('dashboardComponents.Discover.CandidateModal.formWordResume'),
    },
    {
      tab: t('dashboardComponents.Discover.CandidateModal.formApplications'),
      amount: jobApplications?.length,
    },
  ];

  const [currentForm, setCurrentForm] = useState(
    t('dashboardComponents.Discover.CandidateModal.formDetails')
  );

  useEffect(() => {
    AdminService.addCandidateToViewed({ id: currentCandidate?.user?.id });
  }, [currentCandidate]);

  useEffect(() => {
    setCurrentForm('Details');
  }, [currentCandidate]);

  return (
    <Modal
      show={show}
      centered
      className={classes.InviteToJobModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div
        className={classes.content}
        onClick={(event) => event.stopPropagation()}
      >
        <span className={classes.closeIcon} onClick={handleClose} />
        <div className={classes.header}>
          <CandidateHeader
            candidate={currentCandidate}
            refetch={refetch}
            isModal
          />
        </div>
        <div className={classes.candidateInfo}>
          <ul className={classes.links}>
            {forms.map((form) =>
              ['Applications'].includes(form.tab) ? (
                <div
                  className={classNames(classes.link, {
                    [classes.linkActive]: form.tab === currentForm,
                  })}
                  onClick={() => setCurrentForm(form.tab)}
                  key={form.tab}
                >
                  {form.tab}
                  <span
                    className={classNames(classes.counter, {
                      [classes.counterActive]: form.tab === currentForm,
                      [classes.noData]: !form.amount,
                    })}
                  >
                    {form.amount}
                  </span>
                </div>
              ) : (
                <div
                  className={classNames(classes.link, {
                    [classes.linkActive]: form.tab === currentForm,
                  })}
                  onClick={() => setCurrentForm(form.tab)}
                  key={form.tab}
                >
                  {form.tab}
                </div>
              )
            )}
          </ul>

          <div
            className={classNames(classes.hidden, {
              [classes.open]: currentForm === 'Details',
            })}
          >
            <Details
              candidate={currentCandidate}
              currentForm={currentForm}
              setCurrentForm={setCurrentForm}
              candidates={candidatesData}
              isModal
            />
          </div>
          <div
            className={classNames(classes.hidden, {
              [classes.open]: currentForm === 'Word Resume',
            })}
          >
            <Suspense
              fallback={
                <div>
                  {t('dashboardComponents.Discover.CandidateModal.loading')}
                </div>
              }
            >
              <WordResume
                candidate={currentCandidate}
                isModal
                currentForm={currentForm}
              />
            </Suspense>
          </div>
          <div
            className={classNames(classes.hidden, {
              [classes.open]: currentForm === 'Applications',
            })}
          >
            <Applications
              candidate={currentCandidate}
              candidates={candidatesData}
              jobApplications={jobApplications}
              isModal
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
