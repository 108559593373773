import React from 'react';

import { format } from 'timeago.js';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.scss';

export default function Notification({ notification, withButtons }) {
  const { t } = useTranslation();

  return (
    <div className={classes.Notification}>
      <h4>{notification.eventNote}</h4>
      <div className={classes.content}>
        <span className={classes.text}>
          {notification.jobApplication?.user?.userProfile?.firstName}{' '}
          {notification.jobApplication?.user?.userProfile?.lastName}.
        </span>{' '}
        <span className={classes.timeago}>
          {format(notification.createdAt)}
        </span>
      </div>
      {withButtons && (
        <div className={classes.buttons}>
          <button className={classes.blackButton} type="button">
            {t('common.decline')}
          </button>
          <button className={classes.greenButton} type="button">
            {t('common.accept')}
          </button>
        </div>
      )}
    </div>
  );
}
