import React from 'react';

import { useTranslation } from 'react-i18next';

import UsersPopup from '../UsersPopup';
import Addressee from './Addressee';

import addIcon from '../../../../../../assets/images/dashboard/discover/textEditor/add.svg';
import classes from './styles.module.scss';

export default function Addressees({ addressees, setAddressees }) {
  const { t } = useTranslation();

  return (
    <div className={classes.Addressees}>
      <div className={classes.title}>
        {t(
          'dashboardComponents.Discover.AddContent.TextEditor.Addressees.title'
        )}
      </div>
      <div className={classes.addresseesContainer}>
        {addressees.map((addressee) => (
          <Addressee
            key={addressee.value}
            addressee={addressee}
            setAddressees={setAddressees}
          />
        ))}
        <UsersPopup addressees={addressees} setAddressees={setAddressees}>
          <button type="button" className={classes.addMoreButton}>
            <img src={addIcon} alt="Add more" />{' '}
            {t(
              'dashboardComponents.Discover.AddContent.TextEditor.Addressees.addMoreButton'
            )}
          </button>
        </UsersPopup>
      </div>
    </div>
  );
}
