import React, { useEffect, useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import Badge from './Badge';
import Switch from '../../../components/Switch';

import { UiContext } from '../../../context/UiContext';
import AdminService from '../../../services/AdminService';
import classes from './styles.module.scss';

export default function Badges({ job }) {
  const [areBadgesEnabled, setAreBadgesEnabled] = useState(false);
  const [enabledBadges, setEnabledBadges] = useState([]);

  const { setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const { data: tags } = useQuery({
    queryKey: ['tags'],
    queryFn: () =>
      AdminService.getTags({
        pageSize: 50,
      }),
  });

  const addTagToJobPost = async () => {
    try {
      setIsFetching(true);

      await AdminService.addTagToJobPost({
        id: job?.id,
        jobPostTagIds: areBadgesEnabled
          ? enabledBadges.map((tag) => tag.id)
          : [],
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    addTagToJobPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledBadges]);

  return (
    <div className={classes.badgesContainer}>
      <Switch
        label={t('modals.PostJobSuccessModal.Badges.enableBadgesLabel')}
        isEnabled={areBadgesEnabled}
        onClick={() => setAreBadgesEnabled((prevState) => !prevState)}
      />
      <div
        className={classNames(classes.badges, {
          [classes.active]: areBadgesEnabled,
        })}
      >
        {tags?.data?.map?.((tag) => (
          <Badge
            key={tag.id}
            badge={tag}
            areBadgesEnabled={areBadgesEnabled}
            enabledBadges={enabledBadges}
            setEnabledBadges={setEnabledBadges}
          />
        ))}
      </div>
    </div>
  );
}
