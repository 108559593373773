/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useOnClickOutside from '../../../../../../../hooks/useOnClickOutside';
import Tooltip from '../../../../../../Tooltip';
import JobsDropdown from './JobsDropdown';
import classes from './styles.module.scss';

export default function CandidatesDropDown({
  placeholder,
  width,
  height,
  label,
  error,
  touched,
  searchInfo,
  searchInfoWidth,
  setFieldValue,
  name,
  setFieldTouched,
  maxOptionsHeight,
  info,
  note,
  infoWidth,
  readOnly,
  jobApplications,
  selectedList,
  setSelectedList,
  selectedJob,
  setSelectedJob,
  candidates,
  selectedCandidate,
  setSelectedCandidate,
  databaseCandidates,
  jobs,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);
  const [isEnoughHeight, setIsEnoughHeight] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [candidatesList, setCandidatesList] = useState([]);
  const [isJobsDropdownOpened, setIsJobsDropdownOpened] = useState(false);

  const favoriteCandidates = useMemo(
    () => databaseCandidates?.filter((candidate) => candidate.isFavorite),
    [databaseCandidates]
  );

  const options = useMemo(
    () => [
      `Candidate Database - ${databaseCandidates?.length || '...'}`,
      `My favorites - ${favoriteCandidates?.length || '...'}`,
      'Job Applicants',
    ],
    [databaseCandidates?.length, favoriteCandidates?.length]
  );

  useEffect(() => {
    if (selectedList === options[0] && selectedJob) {
      setCandidatesList(databaseCandidates);
    }
    if (selectedList === options[1] && selectedJob) {
      setCandidatesList(favoriteCandidates);
    }
    if (selectedList === options[2] && selectedJob) {
      setCandidatesList(
        jobApplications
          ?.filter((application) => application?.jobPostId === selectedJob?.id)
          .map((application) =>
            databaseCandidates.find(
              (candidate) => candidate?.id === application?.user?.id
            )
          )
      );
    }
  }, [
    selectedJob,
    selectedList,
    databaseCandidates,
    options,
    jobApplications,
    favoriteCandidates,
  ]);

  useEffect(
    () => setSelectedCandidate(''),
    [selectedJob, setSelectedCandidate]
  );

  const dropdownRef = useRef();
  const optionsRef = useRef();
  const isOpened = useRef();

  useOnClickOutside(dropdownRef, () => {
    setIsOptionsListVisible(false);
    setIsJobsDropdownOpened(false);
  });

  useEffect(() => {
    if (isOptionsListVisible) {
      dropdownRef.current.style.setProperty(
        '--optionsHeight',
        `-${optionsRef.current.clientHeight}px`
      );

      const { bottom } = optionsRef.current.getBoundingClientRect();

      if (window.innerHeight - bottom < dropdownRef.current.clientHeight) {
        setIsEnoughHeight(false);
      } else {
        setIsEnoughHeight(true);
      }
    } else {
      setIsEnoughHeight(true);
      if (isOpened.current) {
        setFieldTouched?.(name, true, true);
      }
    }
  }, [isOptionsListVisible, name, setFieldTouched]);

  useEffect(() => {
    if (!isOptionsListVisible) {
      setSearchTerm('');
    }
  }, [isOptionsListVisible]);

  const optionsToDisplay = useMemo(
    () =>
      candidates
        ? candidatesList?.filter((option) => {
            const visibleCandidate = `${option?.userProfile?.firstName} ${option?.userProfile?.lastName}`;
            return visibleCandidate
              .toLowerCase()
              .includes(searchTerm.toLowerCase());
          })
        : jobs?.filter((option) => {
            const visibleJob = `${option?.id} - ${option?.jobTitle} - ${option?.jobPostInterviewers?.length}`;
            return visibleJob.toLowerCase().includes(searchTerm.toLowerCase());
          }),
    [candidates, candidatesList, jobs, searchTerm]
  );

  return (
    <div
      style={{ width }}
      tabIndex={0}
      role="listbox"
      ref={dropdownRef}
      className={classNames(classes.FormDropdown, {
        [classes.open]: isOptionsListVisible,
        [classes.error]: error && touched,
        [classes.optionsTop]: !isEnoughHeight,
      })}
    >
      <span className={classes.label}>
        {label}{' '}
        {info && (
          <Tooltip text={info} width={infoWidth} direction="top-right">
            <i className={classes.infoIcon}>
              <svg
                width="3"
                height="10"
                viewBox="0 0 3 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.25H1.03125"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 8.75V4.75"
                  stroke="#242833"
                  strokeWidth="2"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          </Tooltip>
        )}{' '}
        {note && <span className={classes.note}>({note})</span>}
      </span>
      <div
        style={{ width, height }}
        className={classes.selector}
        onClick={() => {
          if (readOnly) {
            return;
          }
          isOpened.current = true;
          setIsOptionsListVisible((prevState) => !prevState);
        }}
      >
        {candidates ? (
          selectedList.length && selectedCandidate ? (
            <span
              className={classes.hasOption}
            >{`${selectedCandidate?.userProfile?.firstName} ${selectedCandidate?.userProfile?.lastName}`}</span>
          ) : (
            <span>{placeholder}</span>
          )
        ) : selectedList.length && selectedJob ? (
          <span className={classes.hasOption}>{`${selectedJob?.id} - ${
            selectedJob?.jobTitle
          } - ${
            jobApplications?.filter(
              (application) => application?.jobPost?.id === selectedJob?.id
            )?.length
          }`}</span>
        ) : (
          <span>{placeholder}</span>
        )}
        <div className={classes.arrow} />
      </div>
      <div className={classes.error}>{error}</div>
      {isOptionsListVisible && (
        <div className={classes.searchAndOptions} ref={optionsRef}>
          <div className={classes.search}>
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
            {searchInfo && (
              <Tooltip text={searchInfo} width={searchInfoWidth}>
                <i>Info</i>
              </Tooltip>
            )}
          </div>
          <div className={classes.options}>
            {candidates && optionsToDisplay.length ? (
              <ul style={{ maxHeight: maxOptionsHeight }}>
                {optionsToDisplay?.map((option) => {
                  const visibleCandidate = `${option?.userProfile?.firstName} ${option?.userProfile?.lastName}`;

                  return (
                    <li
                      key={`${option?.id} ${Math.random()}`}
                      onClick={() => {
                        setSelectedCandidate(option);
                        setFieldValue('candidate', option);
                        setIsOptionsListVisible(false);
                      }}
                      className={classNames({
                        [classes.active]: selectedCandidate?.id === option?.id,
                      })}
                    >
                      {visibleCandidate}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <ul style={{ maxHeight: maxOptionsHeight }}>
                {!isJobsDropdownOpened &&
                  [0, 1, 2].map((option) => (
                    <li
                      key={option}
                      onClick={() => {
                        if (selectedList === options[option]) {
                          setSelectedList('');
                          setSelectedCandidate('');
                          setFieldValue('candidate', '');
                          return;
                        }
                        setSelectedList(options[option]);
                        setIsJobsDropdownOpened(true);
                      }}
                      className={classNames(classes.checkbox, {
                        [classes.activeCheckbox]:
                          selectedList === options[option],
                      })}
                    >
                      {options[option]}
                    </li>
                  ))}
                {isJobsDropdownOpened && (
                  <JobsDropdown
                    jobs={optionsToDisplay}
                    selectedJob={selectedJob}
                    setSelectedJob={setSelectedJob}
                    jobApplications={jobApplications}
                    handleClose={() => {
                      setIsJobsDropdownOpened(false);
                      setIsOptionsListVisible(false);
                    }}
                    setFieldValue={setFieldValue}
                  />
                )}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
