import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../../../../Button';
import TableView from '../../TableView';

import AdminService from '../../../../../services/AdminService';

export default function Partners({ setItemCount }) {
  const { t } = useTranslation();

  const columns = [
    {
      name: t(
        'dashboardComponents.Console.JobManagement.Partners.AssessmentProvider'
      ),
      field: 'assessmentProvider.name',
      sortable: true,
    },
    {
      name: t('dashboardComponents.Console.JobManagement.Partners.Name'),
      field: 'name',
      sortable: true,
    },
    {
      name: t(
        'dashboardComponents.Console.JobManagement.Partners.AuthorizationKey'
      ),
      field: 'authorizationKey',
      sortable: true,
      defaultValue: '-',
    },
    {
      name: t('dashboardComponents.Console.JobManagement.Partners.CompanyKey'),
      field: 'authorizationCompanyId',
      sortable: true,
      defaultValue: '-',
      centered: true,
    },
    {
      name: t('common.createdOn'),
      field: 'createdAt',
      sortable: true,
      type: 'date',
    },
    {
      name: t('dashboardComponents.Console.JobManagement.Partners.Action'),
      sortable: false,
      type: 'tenantAssessmentAction',
    },
  ];

  return (
    <TableView
      setItemCount={setItemCount}
      dataName={t(
        'dashboardComponents.Console.JobManagement.Partners.AssessmentProvider'
      )}
      getDataHandler={AdminService.getTenantAssessmentProviders}
      deleteDataHandler={AdminService.deleteTenantAssessmentProvider}
      createDataHandler={AdminService.createTenantAssessmentProvider}
      updateDataHandler={AdminService.updateTenantAssessmentProvider}
      viewLeftColumnFields={[
        {
          label: t(
            'dashboardComponents.Console.JobManagement.Partners.AssessmentProvider'
          ),
          field: 'assessmentProvider.name',
        },
        {
          label: t(
            'dashboardComponents.Console.JobManagement.Partners.AuthorizationKey'
          ),
          field: 'authorizationKey',
          isVisible: (data) => data?.assessmentProvider?.name !== 'Zaphire',
        },
      ]}
      viewRightColumnFields={[
        {
          label: t('dashboardComponents.Console.JobManagement.Partners.Name'),
          field: 'name',
        },
        {
          label: t(
            'dashboardComponents.Console.JobManagement.Partners.CompanyKey'
          ),
          field: 'authorizationCompanyId',
          isVisible: (data) => data?.assessmentProvider?.name !== 'Zaphire',
        },
      ]}
      formLeftColumnFields={[
        {
          label: t(
            'dashboardComponents.Console.JobManagement.Partners.AssessmentProvider'
          ),
          field: 'assessmentProvider',
          validation: ['object', 'required'],
          placeholder: t(
            'dashboardComponents.Console.JobManagement.Partners.PickAssessmentProvider'
          ),
          type: 'dropdown',
          fetchOptions: {
            dataName: 'assessmentProviders',
            getDataHandler: AdminService.getAssessmentProviders,
          },
          isNotEditable: true,
        },
        {
          label: t(
            'dashboardComponents.Console.JobManagement.Partners.AuthorizationKey'
          ),
          field: 'authorizationKey',
          validation: ['string', 'required'],
          placeholder: t(
            'dashboardComponents.Console.JobManagement.Partners.AuthorizationKeyPlaceholder'
          ),
          isVisible: (values) => values.assessmentProvider?.label !== 'Zaphire',
        },
      ]}
      formRightColumnFields={[
        {
          label: t('dashboardComponents.Console.JobManagement.Partners.Name'),
          field: 'name',
          validation: ['string'],
          placeholder: t(
            'dashboardComponents.Console.JobManagement.Partners.CustomNamePlaceholder'
          ),
          maxLength: 30,
        },
        {
          label: t(
            'dashboardComponents.Console.JobManagement.Partners.CompanyKey'
          ),
          field: 'authorizationCompanyId',
          validation: ['numbers', 'required'],
          placeholder: t(
            'dashboardComponents.Console.JobManagement.Partners.CompanyKeyPlaceholder'
          ),
          maxLength: 40,
          isVisible: (values) => values.assessmentProvider?.label !== 'Zaphire',
        },
      ]}
      columns={columns}
      bottomButton={
        <Button disabled>
          {t('dashboardComponents.Console.JobManagement.Partners.Inactive')}
        </Button>
      }
    />
  );
}
