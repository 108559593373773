/* eslint-disable react/jsx-handler-names */
import React, { useState, useEffect, useContext, useMemo } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import TextInput from '../../../../Form/TextInput';
import SidePopup from '../../SidePopup';

import useSwitchableRowData from '../../../../../hooks/useSwitchableRowData';
import { UiContext } from '../../../../../context/UiContext';
import MultiDropdown from '../../../../Form/MultiDropdown';
import AdminService from '../../../../../services/AdminService';
import classes from './styles.module.scss';

export default function ReviewNewUserRequest({
  isVisible,
  newUsers,
  handleClose,
  refetch,
  selectedRows,
  noAnimation,
}) {
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const { t } = useTranslation();

  const {
    showUnknownErrorModal,
    showModal,
    setIsFetching,
    isCreatingOrUpdating,
    setIsCreatingOrUpdating,
  } = useContext(UiContext);

  const { currentDataIndex, switchToPrevDataElement, switchToNextDataElement } =
    useSwitchableRowData(newUsers, selectedRows);

  const validationSchema = useMemo(
    () =>
      yup.object({
        role: yup
          .array()
          .required(
            t(
              'dashboardComponents.Console.UserManagement.ReviewNewUserRequest.errorMessages.roleRequired'
            )
          ),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      role: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFetching(true);
        setIsCreatingOrUpdating(true);
        await AdminService.approveNewUser({
          id: newUsers[currentDataIndex].id,
          firstName: newUsers[currentDataIndex].firstName,
          lastName: newUsers[currentDataIndex].lastName,
          email: newUsers[currentDataIndex].email,
          cityIds: newUsers[currentDataIndex].cities?.map?.((city) => city.id),
          locationIds: newUsers[currentDataIndex].locations?.map(
            (loc) => loc.id
          ),
          roleIds: values.role?.map((role) => role.value),
        });
        await refetch();
        showModal({
          title: t(
            'dashboardComponents.Console.UserManagement.ReviewNewUserRequest.successModal.title'
          ),
          text: t(
            'dashboardComponents.Console.UserManagement.ReviewNewUserRequest.successModal.text'
          ),
          dismissButtonLabel: t('common.gotIt'),
          dismissButtonVariant: '',
        });
        handleClose();
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        setIsFetching(false);
        setIsCreatingOrUpdating(false);
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!isVisible) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <SidePopup
      isVisible={isVisible}
      handleClose={handleClose}
      title={t(
        'dashboardComponents.Console.UserManagement.ReviewNewUserRequest.title'
      )}
      hasNoAnimation={noAnimation}
      leftButtonLabel={t('common.reset')}
      rightButtonLabel={t('common.approve')}
      isRightButtonDisabled={!formik.values.role || isCreatingOrUpdating}
      onLeftButtonClick={formik.resetForm}
      onRightButtonClick={() => {
        setIsTriedToSubmit(true);
        formik.handleSubmit();
      }}
      switchNext={selectedRows.length > 1 && switchToNextDataElement}
      switchPrev={selectedRows.length > 1 && switchToPrevDataElement}
    >
      <div className={classes.ReviewNewUserRequest}>
        <form className={classes.requestDetails}>
          <div className={classes.column}>
            <TextInput
              value={newUsers?.[currentDataIndex]?.firstName}
              label={t(
                'dashboardComponents.Console.UserManagement.ReviewNewUserRequest.labels.firstName'
              )}
              readonly
              height={50}
            />
            <TextInput
              label={t(
                'dashboardComponents.Console.UserManagement.ReviewNewUserRequest.labels.email'
              )}
              value={newUsers?.[currentDataIndex]?.email}
              readonly
              height={50}
            />
            <TextInput
              label="City"
              value={newUsers?.[currentDataIndex]?.cities
                ?.map((city) => city.name)
                .join(', ')}
              readonly
              height={50}
            />
            <MultiDropdown
              error={formik.errors.role}
              touched={isTriedToSubmit}
              value={formik.values.role}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              label={t('common.role')}
              name="role"
              placeholder={t('common.selectRole')}
              height={50}
              width="100%"
              fetchOptions={{
                dataName: 'rolesOptionsNewUser',
                getDataHandler: AdminService.getRoles,
              }}
              searchInfo={t(
                'dashboardComponents.Console.UserManagement.ReviewNewUserRequest.searchInfo'
              )}
              searchInfoWidth={173}
              setFieldTouched={formik.setFieldTouched}
            />
          </div>
          <div className={classes.column}>
            <TextInput
              label={t(
                'dashboardComponents.Console.UserManagement.ReviewNewUserRequest.labels.lastName'
              )}
              value={newUsers?.[currentDataIndex]?.lastName}
              readonly
              height={50}
            />
            <TextInput
              height={50}
              readonly
              label={t(
                'dashboardComponents.Console.UserManagement.ReviewNewUserRequest.labels.restaurant'
              )}
              value={newUsers?.[currentDataIndex]?.locations
                ?.map((location) => location.name)
                .join(', ')}
            />
          </div>
        </form>
      </div>
    </SidePopup>
  );
}
