import React, { useState, useEffect, useRef, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import TextInput from '../../components/Form/TextInput';
import Dropdown from '../../components/Form/Dropdown';
import infoIcon from '../../assets/images/modals/info.svg';
import Button from '../../components/Button';

import {
  moveCandidateToOffer,
  moveCandidateToOnboard,
  moveCandidateToInterview,
} from '../../helpers/candidates';
import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';

export default function ChangeStageWithExceptionModal({
  show,
  handleClose,
  candidate,
  refetch,
  stage,
}) {
  const [rejectionReasonOption, setRejectionReasonOption] = useState('');
  const [rejectionReason, setRejectionReason] = useState('');

  const textInputRef = useRef();

  const { showNotification, setIsFetching } = useContext(UiContext);

  const { t } = useTranslation();

  const options = [
    {
      label: t(
        'modals.ChangeStageWithExceptionModal.options.exceptionalCandidate'
      ),
      value: 'Exceptional candidate',
    },
    {
      label: t('modals.ChangeStageWithExceptionModal.options.exEmployee'),
      value: 'Ex-employee',
    },
    {
      label: t(
        'modals.ChangeStageWithExceptionModal.options.managementException'
      ),
      value: 'Management Exception',
    },
    {
      label: t('modals.ChangeStageWithExceptionModal.options.typeReason'),
      value: 'Type a reason (25 chars only)',
    },
  ];

  const moveCandidateToNextStage = () => {
    if (stage === 'Offer') {
      moveCandidateToOffer(candidate, refetch, showNotification, setIsFetching);
      handleClose();
    } else if (stage === 'Onboard') {
      moveCandidateToOnboard(
        candidate,
        refetch,
        showNotification,
        setIsFetching
      );
      handleClose();
    } else if (stage === 'Interview') {
      moveCandidateToInterview(
        candidate,
        refetch,
        showNotification,
        setIsFetching
      );
      handleClose();
    }
  };

  useEffect(() => {
    return () => {
      setRejectionReasonOption('');
    };
  }, [show]);

  useEffect(() => {
    if (rejectionReasonOption?.value === 'Type a reason (25 chars only)') {
      textInputRef.current?.focus?.();
    }
  }, [rejectionReasonOption]);

  let isSubmitButtonDisabled = false;

  if (!rejectionReasonOption) {
    isSubmitButtonDisabled = true;
  }

  if (
    rejectionReasonOption?.value === 'Type a reason (25 chars only)' &&
    !rejectionReason
  ) {
    isSubmitButtonDisabled = true;
  }

  let info;

  if (stage === 'Offer') {
    info = t('modals.ChangeStageWithExceptionModal.infoOffer');
  } else if (stage === 'Onboard') {
    info = t('modals.ChangeStageWithExceptionModal.infoOnboard');
  } else if (stage === 'Interview') {
    info = t('modals.ChangeStageWithExceptionModal.infoInterview');
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.Modal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <img
          width={32}
          height={32}
          className={classes.icon}
          src={infoIcon}
          alt="Icon"
        />
        <h1>{t('modals.ChangeStageWithExceptionModal.modalTitle')}</h1>
        <p>{info}</p>
        <div>
          {rejectionReasonOption?.value === 'Type a reason (25 chars only)' ? (
            <TextInput
              maxLength={25}
              inputRef={textInputRef}
              width={295}
              height={56}
              placeholder={t(
                'modals.ChangeStageWithExceptionModal.placeholderTypeReason'
              )}
              value={rejectionReason}
              onChange={(event) => setRejectionReason(event.target.value)}
            />
          ) : (
            <Dropdown
              width={295}
              height={56}
              options={options}
              activeOption={rejectionReasonOption}
              setActiveOption={setRejectionReasonOption}
              placeholder={t(
                'modals.ChangeStageWithExceptionModal.selectReasonPlaceholder'
              )}
            />
          )}
        </div>
        <div className={classes.buttons}>
          <Button
            disabled={isSubmitButtonDisabled}
            width={144}
            onClick={moveCandidateToNextStage}
          >
            {t('modals.ChangeStageWithExceptionModal.submitButton')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
