import React from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Button from '../../../../AddContent/Button';

import { useUserStore } from '../../../../../../../store';
import classes from './styles.module.scss';
import FeedService from '../../../../../../../services/FeedService';

export default function PollButtons({
  vote,
  isPollValid,
  clearAnswers,
  hasVoted,
  enableRevote,
  isAuthor,
  stopPoll,
  isPollClosed,
  createdAt,
  resumePoll,
  pollId,
}) {
  const { t } = useTranslation();

  const userScopes = useUserStore((state) => state.userScopes);
  const isAdmin = userScopes.includes('api:full');
  const hasTwoWeeksPassed = moment().diff(createdAt, 'weeks') >= 2;

  const downloadResults = async () => {
    try {
      const blob = await FeedService.downloadPollResults(pollId);

      const fileUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = `Results.xls`;
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const clearButton = (
    <button className={classes.textButton} type="button" onClick={clearAnswers}>
      {t('common.clear')}
    </button>
  );

  const stopButton = (
    <button className={classes.textButton} type="button" onClick={stopPoll}>
      {t('dashboardComponents.Discover.Feed.Post.Poll.PollButtons.Stop')}
    </button>
  );

  const resultsButton = (
    <button
      className={classes.textButton}
      type="button"
      onClick={downloadResults}
    >
      Results.xls
    </button>
  );

  const resumeButton = (
    <Button onClick={resumePoll}>
      {t('dashboardComponents.Discover.Feed.Post.Poll.PollButtons.Resume')}
    </Button>
  );

  let buttons = (
    <>
      <Button onClick={vote} disabled={!isPollValid()}>
        {t('dashboardComponents.Discover.Feed.Post.Poll.PollButtons.Vote')}
      </Button>
      {isAuthor || (isAdmin && hasTwoWeeksPassed) ? stopButton : clearButton}
    </>
  );

  if (hasVoted) {
    buttons = (
      <>
        <Button onClick={enableRevote}>
          {t(
            'dashboardComponents.Discover.Feed.Post.Poll.PollButtons.Vote Again'
          )}
        </Button>
        {isAuthor || (isAdmin && hasTwoWeeksPassed) ? stopButton : clearButton}
      </>
    );
  }

  if (isPollClosed) {
    buttons = (
      <>
        {isAuthor ? resumeButton : null}
        {resultsButton}
      </>
    );
  }

  return <div className={classes.PollButtons}>{buttons}</div>;
}
