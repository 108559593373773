/* eslint-disable no-shadow */
import React from 'react';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Template from '../Template';

import { convertTo12HourFormat, addOneHour } from '../../../../../helpers/time';
import {
  getTimezoneDate,
  getTimezoneTime,
} from '../../../../../helpers/timezones';

export default function Onboarding({
  deleteNotification,
  isRead,
  notification,
  classes,
  hideNotifications,
  userTimezone,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const createFeedbackNotificationContent = (notification) => {
    const feedbackRating =
      notification.jobApplication?.jobApplicationUserFeedbacks?.[0]
        ?.feedbackRating;

    return feedbackRating > 3
      ? {
          title: `${t(
            'dashboardComponents.Notifications.Notification.Onboarding.feedbackReceived'
          )}: ${notification.jobApplication?.user?.userProfile?.fullName}`,
          text: `${t(
            'dashboardComponents.Notifications.Notification.Onboarding.proposed'
          )} ${moment(
            getTimezoneDate(
              notification.jobApplication?.jobApplicationOnboardings?.[0]
                ?.suggestedOnboardingDate,
              userTimezone,
              notification.jobApplication?.jobApplicationOnboardings?.[0]
                ?.suggestedStartTime
            )[0]
          ).format('Do MMM YYYY')} ${convertTo12HourFormat(
            getTimezoneTime(
              notification.jobApplication?.jobApplicationOnboardings?.[0]
                ?.suggestedStartTime,
              userTimezone
            )
          )} - ${addOneHour(
            getTimezoneTime(
              notification.jobApplication?.jobApplicationOnboardings?.[0]
                ?.suggestedStartTime,
              userTimezone
            )
          )}`,
          buttonLabel: t(
            'dashboardComponents.Notifications.Notification.Onboarding.review'
          ),
        }
      : {};
  };

  const createNotificationContent = (
    notification,
    navigate,
    hideNotifications
  ) => {
    const goToJob = () => {
      navigate(
        `/tools/jobs/my-jobs/${notification.jobApplication?.jobPost?.id}`
      );
      hideNotifications();
    };

    const content = {
      'Application pending onboarding more than 5 days': {
        title: t(
          'dashboardComponents.Notifications.Notification.Onboarding.applicationPendingOnboarding'
        ),
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.review'
        ),
        onClick: goToJob,
      },
      OnboardingChangeRequested: {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Onboarding.rescheduleOnboarding'
        )} ${notification.jobApplication?.jobPost?.jobTitle} (${
          notification.jobApplication?.jobPost?.id
        })`,
        text: `${t(
          'dashboardComponents.Notifications.Notification.Onboarding.proposed'
        )} ${moment(
          notification.jobApplication?.jobApplicationOnboardings?.[0]
            ?.suggestedOnboardingDate
        ).format('Do MMM YYYY')} ${convertTo12HourFormat(
          notification.jobApplication?.jobApplicationOnboardings?.[0]
            ?.suggestedStartTime
        )} - ${addOneHour(
          notification.jobApplication?.jobApplicationOnboardings?.[0]
            ?.suggestedStartTime
        )}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.review'
        ),
        onClick: goToJob,
      },
      JoiningChangeRequested: {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Onboarding.rescheduleStart'
        )} ${notification.jobApplication?.jobPost?.jobTitle} (${
          notification.jobApplication?.jobPost?.id
        })`,
        text: `${t(
          'dashboardComponents.Notifications.Notification.Onboarding.proposed'
        )} ${moment(
          notification.jobApplication?.jobApplicationJoinings?.[0]
            ?.suggestedJoiningDate
        ).format('Do MMM YYYY')} ${convertTo12HourFormat(
          notification.jobApplication?.jobApplicationJoinings?.[0]
            ?.suggestedStartTime
        )} - ${addOneHour(
          notification.jobApplication?.jobApplicationJoinings?.[0]
            ?.suggestedStartTime
        )}`,
        buttonLabel: t(
          'dashboardComponents.Notifications.Notification.Onboarding.review'
        ),
        onClick: goToJob,
      },
      JoiningAccepted: {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Onboarding.candidateConfirmedStart'
        )} ${notification.jobApplication?.jobPost?.jobTitle} (${
          notification.jobApplication?.jobPost?.id
        })`,
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: '',
      },
      NewFeedback: createFeedbackNotificationContent(notification),
      ApplicationJoined: {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Onboarding.candidateJoined'
        )} ${notification.jobApplication?.jobPost?.jobTitle} (${
          notification.jobApplication?.jobPost?.id
        })`,
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: '',
      },
      OnboardingAccepted: {
        title: `${t(
          'dashboardComponents.Notifications.Notification.Onboarding.onboardingAccepted'
        )} ${notification.jobApplication?.jobPost?.jobTitle} (${
          notification.jobApplication?.jobPost?.id
        })`,
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: '',
      },
    };

    return (
      content[notification.eventNote] ||
      content[notification.name] || {
        title: notification.eventNote,
        text: `${notification.jobApplication?.user?.userProfile?.fullName}`,
        buttonLabel: 'Review',
        onClick: goToJob,
      }
    );
  };

  const notificationContent = createNotificationContent(
    notification,
    navigate,
    hideNotifications
  );

  return (
    <Template
      deleteNotification={deleteNotification}
      isRead={isRead}
      notification={notification}
      notificationContent={notificationContent}
      classes={classes}
      userTimezone={userTimezone}
    />
  );
}
