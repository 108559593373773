import React, { Suspense, lazy } from 'react';

import { useTranslation } from 'react-i18next';

import ResumeInput from './ResumeInput';

import classes from './styles.module.scss';

const WordResume = lazy(() => import('../../../../../Candidate/WordResume'));

export default function Resume({
  formik,
  isPossibleToEdit,
  isReadOnly,
  isEdit,
}) {
  const { t } = useTranslation();

  const { userProfileFileResume } = formik.values;

  const deleteResume = () => {
    formik.setFieldValue('userProfileFileResume', null);
  };

  return (
    <div className={classes.Resume}>
      {userProfileFileResume ? (
        <Suspense
          fallback={
            <div>
              {t('dashboardComponents.Discover.CandidateModal.loading')}
            </div>
          }
        >
          <WordResume
            candidate={{
              userProfile: {
                userProfileFileResume,
              },
            }}
            isModal
            currentForm={{}}
            addCandidateModal
            deleteResume={deleteResume}
            formik={formik}
            resumeFieldName="userProfileFileResume"
            isPossibleToEdit={isPossibleToEdit}
            isReadOnly={isReadOnly}
            isEdit={isEdit}
          />
        </Suspense>
      ) : (
        <div className={classes.resumeContainer}>
          <ResumeInput
            formik={formik}
            name="userProfileFileResume"
            setFieldValue={formik.setFieldValue}
          />
        </div>
      )}
    </div>
  );
}
