import React from 'react';

import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import AuthLayout from '../../containers/AuthLayout';
import classes from './styles.module.scss';

export default function RequestNewPasswordSuccessPage() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <AuthLayout>
      <div className={classes.RequestNewPasswordSuccessPage}>
        <Helmet>
          <title>{t('pages.RequestNewPasswordSuccessPage.pageTitle')}</title>
        </Helmet>
        <div className={classes.container}>
          <h1>{t('pages.RequestNewPasswordSuccessPage.pageHeader')}</h1>
          <p>
            {t('pages.RequestNewPasswordSuccessPage.notificationText1')} <br />
            <br />
            <br />
            {t('pages.RequestNewPasswordSuccessPage.notificationText2')}
          </p>
          <Button width={400} onClick={() => navigate('/', { replace: true })}>
            {t('pages.RequestNewPasswordSuccessPage.closeButton')}
          </Button>
        </div>
      </div>
    </AuthLayout>
  );
}
