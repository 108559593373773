import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import GradientButton from '../../../../../GradientButton';
import Button from '../../../../../Button';

import { BrandKitContext } from '../../../../../../context/BrandKitContext';
import AdminService from '../../../../../../services/AdminService';
import useUiContext from '../../../../../../hooks/useUiContext';
import classes from './styles.module.scss';

const FONT_TYPES = {
  titleFont: 'Title',
  subTitleFont: 'Subtitle',
  bodyTextFont: 'Body',
  bodySubTextFont: 'Subbody',
};

export default function PreviewHeader({
  fonts,
  colors,
  logoSettings,
  tenantSettings,
  refetchTenantSettings,
}) {
  const { showNotification, setIsFetching, showUnknownErrorModal } =
    useUiContext();
  const { isPublishButtonDisabled } = useContext(BrandKitContext);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const buttons = [
    {
      id: 1,
      name: t(
        'dashboardComponents.ProfileSettings.BrandKit.RightPanel.PreviewHeader.buttons.classic'
      ),
      disabled: true,
      active: false,
    },
    {
      id: 2,
      name: t(
        'dashboardComponents.ProfileSettings.BrandKit.RightPanel.PreviewHeader.buttons.modern'
      ),
      disabled: true,
      active: false,
    },
    {
      id: 3,
      name: t(
        'dashboardComponents.ProfileSettings.BrandKit.RightPanel.PreviewHeader.buttons.bold'
      ),
      disabled: true,
      active: false,
    },
    {
      id: 4,
      name: t(
        'dashboardComponents.ProfileSettings.BrandKit.RightPanel.PreviewHeader.buttons.custom'
      ),
      disabled: false,
      active: true,
    },
  ];

  const publishBrandKitSettings = async () => {
    try {
      setIsFetching(true);

      const images = {
        logo: tenantSettings.brand?.logo,
        favicon: tenantSettings.brand?.favicon,
      };

      const promises = [];

      if (logoSettings.logo instanceof File) {
        promises.push(AdminService.uploadImage(logoSettings.logo, 'brand'));
      } else {
        promises.push(Promise.resolve());
      }

      if (logoSettings.favicon instanceof File) {
        promises.push(AdminService.uploadImage(logoSettings.favicon, 'brand'));
      } else {
        promises.push(Promise.resolve());
      }

      let uploadedImages;

      if (promises.length) {
        uploadedImages = await Promise.all(promises);
      }

      const brand = {
        name: logoSettings.brandName,
        logo: uploadedImages[0]?.uri || images.logo,
        favicon: uploadedImages[1]?.uri || images.favicon,
      };

      const brandFonts = Object.keys(fonts).map((fontType) => {
        return {
          type: FONT_TYPES[fontType],
          familyName: fonts[fontType].fontFamily.value,
          customStyles: fonts[fontType].styles?.length
            ? fonts[fontType].styles.join(',')
            : null,
          urL: tenantSettings?.brandFonts.find(
            (font) => font.type === FONT_TYPES[fontType]
          )?.urL,
        };
      });

      const brandColors = colors?.map((color) => {
        return { value: color };
      });

      await AdminService.updateTenantSettings({
        ...tenantSettings,
        brand,
        brandFonts,
        brandColors,
      });

      refetchTenantSettings();

      showNotification({
        text: t(
          'dashboardComponents.ProfileSettings.BrandKit.RightPanel.PreviewHeader.published'
        ),
      });
    } catch (error) {
      console.log(error);
      showUnknownErrorModal();
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.PreviewHeader}>
      <div className={classes.titleContainer}>
        <h2>
          {t(
            'dashboardComponents.ProfileSettings.BrandKit.RightPanel.PreviewHeader.designPreview'
          )}
        </h2>
        <p>
          <a className={classes.mailLink} href="mailto:hello@zaphire.com">
            {t(
              'dashboardComponents.ProfileSettings.BrandKit.RightPanel.PreviewHeader.contactUs'
            )}
          </a>{' '}
          {t(
            'dashboardComponents.ProfileSettings.BrandKit.RightPanel.PreviewHeader.forCustomDesign'
          )}
        </p>
      </div>
      <div className={classes.buttons}>
        {buttons.map((button) => (
          <GradientButton
            key={button.id}
            disabled={button.disabled}
            active={button.active}
          >
            {button.name}
          </GradientButton>
        ))}
      </div>
      <div className={classes.publishButtonContainer}>
        <Button
          height={40}
          width={isTabletOrMobile ? 100 : 119}
          style={{
            fontSize: 14,
          }}
          disabled={isPublishButtonDisabled}
          onClick={publishBrandKitSettings}
        >
          {t(
            'dashboardComponents.ProfileSettings.BrandKit.RightPanel.PreviewHeader.publishButton'
          )}
        </Button>
      </div>
    </div>
  );
}
