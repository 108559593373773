/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

import Members from './Members';
import classes from './styles.module.scss';

export default function MembersPopup({
  members,
  online,
  views,
  comments,
  isTop,
  closePopup,
  activeReaction,
  reactionLists,
  isRight,
  isLeft,
  noSort,
  containerRef,
  style,
}) {
  const [activeTab, setActiveTab] = useState('');
  const [isMeasured, setIsMeasured] = useState(false);

  const popupRef = useRef();

  useOnClickOutside(popupRef, closePopup, containerRef);

  const tabs = useMemo(() => {
    const all = reactionLists?.map((list) => list.reactions).flat();
    const thumbUp = reactionLists?.find(
      (list) => list.label === 'ThumbUp'
    )?.reactions;
    const love = reactionLists?.find(
      (list) => list.label === 'Love'
    )?.reactions;
    const celebrate = reactionLists?.find(
      (list) => list.label === 'Celebrate'
    )?.reactions;
    const laugh = reactionLists?.find(
      (list) => list.label === 'Laugh'
    )?.reactions;
    const suprised = reactionLists?.find(
      (list) => list.label === 'Suprised'
    )?.reactions;
    const sad = reactionLists?.find((list) => list.label === 'Sad')?.reactions;
    const angry = reactionLists?.find(
      (list) => list.label === 'Angry'
    )?.reactions;

    return [
      { type: 'All', amount: all?.length, list: all },
      { type: 'ThumbUp', amount: thumbUp?.length, list: thumbUp },
      { type: 'Love', amount: love?.length, list: love },
      { type: 'Celebrate', amount: celebrate?.length, list: celebrate },
      { type: 'Laugh', amount: laugh?.length, list: laugh },
      { type: 'Suprised', amount: suprised?.length, list: suprised },
      { type: 'Sad', amount: sad?.length, list: sad },
      { type: 'Angry', amount: angry?.length, list: angry },
    ];
  }, [reactionLists]);

  const membersList =
    members ||
    tabs
      ?.find((tab) => tab.type === activeTab)
      ?.list?.map((reaction) => reaction.author);

  useEffect(() => {
    if (online) {
      return;
    }

    if (views) {
      setActiveTab('Views');

      return;
    }

    if (comments) {
      setActiveTab('Comments');

      return;
    }
    if (
      tabs
        ?.slice(0, 4)
        .map((tab) => tab.type)
        .includes(activeReaction)
    ) {
      setActiveTab(activeReaction);
      return;
    }

    setActiveTab('All');
  }, [activeReaction, comments, online, tabs, views]);

  useEffect(() => {
    const popupRect = popupRef.current.getBoundingClientRect();
    const isEnoughSpace = popupRect.bottom < window.innerHeight;

    if (!isEnoughSpace) {
      popupRef.current.style.top = '-241px';
    }

    setIsMeasured(true);
  }, []);

  let header;

  const tabsWithData = tabs.slice(1).filter((tab) => tab.amount > 0);

  if (online) {
    header = (
      <div className={classes.online}>
        <span>{members.length}</span>
        <span className={classes.headerTitle}>{`Member${
          members.length > 5 ? 's' : ''
        } online`}</span>
      </div>
    );
  } else if (comments) {
    const commentsTab = { type: 'Comments', amount: members.length };

    header = (
      <div className={classes.tabs}>
        <div
          className={classNames(classes.tab, {
            [classes.activeTab]: activeTab === commentsTab.type,
          })}
          onClick={() => {
            setActiveTab(commentsTab.type);
          }}
          key={commentsTab.type}
        >
          <span
            className={classNames(classes.tabIcon, classes[commentsTab.type])}
          />
          <span>{commentsTab.amount}</span>
        </div>
      </div>
    );
  } else if (views) {
    const viewsTab = { type: 'Views', amount: members.length };

    header = (
      <div className={classes.tabs}>
        <div
          className={classNames(classes.tab, {
            [classes.activeTab]: activeTab === viewsTab.type,
          })}
          onClick={() => {
            setActiveTab(viewsTab.type);
          }}
          key={viewsTab.type}
        >
          <span
            className={classNames(classes.tabIcon, classes[viewsTab.type])}
          />
          <span>{viewsTab.amount}</span>
        </div>
      </div>
    );
  } else if (tabsWithData.length === 1) {
    header = (
      <div className={classes.tabs}>
        <div
          className={classNames(classes.tab, {
            [classes.activeTab]: activeTab === tabsWithData[0].type,
          })}
          onClick={() => {
            setActiveTab(tabsWithData[0].type);
          }}
          key={tabsWithData[0].type}
        >
          <span
            className={classNames(
              classes.tabIcon,
              classes[tabsWithData[0].type]
            )}
          />
          <span>{tabsWithData[0].amount}</span>
        </div>
      </div>
    );
  } else {
    header = (
      <div className={classes.tabs}>
        {tabs.slice(0, 4).map((tab) => {
          if (!tab?.list?.length) {
            return null;
          }
          return (
            <div
              className={classNames(classes.tab, {
                [classes.activeTab]: activeTab === tab.type,
              })}
              onClick={() => setActiveTab(tab.type)}
              key={tab.type}
            >
              {tab.type === 'All' ? (
                <span>All</span>
              ) : (
                <span
                  className={classNames(classes.tabIcon, classes[tab.type], {
                    [classes.disabledIcon]: activeTab !== tab.type,
                  })}
                />
              )}
              <span>{tab.amount}</span>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      className={classes.Dropdown}
      style={{
        visibility: isMeasured ? 'visible' : 'hidden',
        top: isTop && 'auto',
        bottom: isTop && 'calc(100% + 5px)',
        right: isRight ? '50%' : isLeft ? 'auto' : '50%',
        left: isLeft ? '50%' : isRight ? 'auto' : '50%',
        ...style,
      }}
      ref={popupRef}
    >
      <div className={classes.header}>{header}</div>
      <div className={classes.content}>
        <Members members={membersList} noSort={noSort} />
      </div>
    </div>
  );
}
