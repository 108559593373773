import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Checkbox({
  backgroundColor,
  isSelected,
  onChange,
  label,
}) {
  return (
    <div
      onClick={onChange}
      className={classNames(classes.Checkbox, {
        [classes.selected]: isSelected,
      })}
      style={{ columnGap: label ? '10px' : '0' }}
    >
      <div style={{ backgroundColor }} className={classes.button} />
      <div className={classes.label}>{label}</div>
    </div>
  );
}
