import React from 'react';
import Row from './Row';

import classes from './styles.module.scss';

export default function Tbody({ data }) {
  return (
    <tbody className={classes.Tbody}>
      {data?.map((item) => (
        <Row key={`${Math.random()}`} row={item} />
      ))}
    </tbody>
  );
}
