/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import saveChangesIcon from '../../../../../assets/images/modals/save-changes.svg';
import Button from '../../../../Button';
import { PreventNavigation } from '../../../../../context/PreventNavigationContext';
import ReportsService from '../../../../../services/ReportsService';
import { UiContext } from '../../../../../context/UiContext';
import classes from './styles.module.scss';

export default function SaveChangesModal({
  show,
  text,
  handleClose,
  reports,
  activeReport,
  searchTerm,
  activeSortHeader,
  refetch,
  savedReports,
  leftButtonLabel,
  rightButtonLabel,
  reportTypes,
}) {
  const [reportName, setReportName] = useState('');

  const { setIsFetching } = useContext(UiContext);

  const { setIsNavigationAllowed, navigationLink } =
    useContext(PreventNavigation);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const constantReports = useMemo(
    () => reportTypes?.map((rep) => rep.split('.').pop()),
    [reportTypes]
  );

  useEffect(() => {
    if (constantReports?.includes(activeReport?.title)) {
      return;
    }
    setReportName(activeReport?.title);
  }, [activeReport, constantReports]);

  const addReport = async (title) => {
    try {
      setIsFetching(true);
      await ReportsService.addReportToStored({
        title,
        type: activeReport.type || 'Assessments',
        term: searchTerm,
        orderBy: activeSortHeader,
        fields: reports?.selectedFields || [],
      });

      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsNavigationAllowed(true);
      setIsFetching(false);
      handleClose();
    }
  };

  const updateReport = async (savedReport) => {
    try {
      setIsFetching(true);
      await ReportsService.updateStoredReport({
        id: savedReport.id,
        title: reportName,
        type: activeReport.type,
        term: searchTerm,
        orderBy: activeSortHeader,
        fields: activeReport?.fields || reports?.selectedFields || [],
      });

      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setIsNavigationAllowed(true);
      setIsFetching(false);
      handleClose();
    }
  };

  const confirm = () => {
    const savedReport = savedReports?.find(
      (report) => report?.title === reportName
    );

    if (
      !constantReports?.includes(reportName) &&
      savedReport?.title === reportName
    ) {
      updateReport(savedReport);
      return;
    }

    if (constantReports?.includes(reportName) || savedReport) {
      const fieldName = reportName;
      let incrementedFieldName = fieldName;
      let counter = 1;

      while (constantReports?.includes(incrementedFieldName)) {
        incrementedFieldName = `${fieldName} ${counter}`;
        counter++;
      }

      addReport(incrementedFieldName);
    } else {
      addReport(reportName);
    }
  };

  const cancel = () => {
    if (navigationLink) {
      navigate(navigationLink, { replace: true });
    }
    setIsNavigationAllowed(true);
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.Modal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <img
          width={32}
          height={32}
          className={classes.icon}
          src={saveChangesIcon}
          alt="Icon"
        />
        <h1>
          {t(
            'dashboardComponents.Reports.RightPanel.SaveChangesModal.saveChanges'
          )}
        </h1>
        <p>{text}</p>
        <div className={classes.buttons}>
          <Button variant="modal" onClick={cancel} width={128}>
            {leftButtonLabel || t('common.cancel')}
          </Button>
          <button
            className={classes.save}
            type="button"
            onClick={confirm}
            disabled={!reportName?.trim().length}
          >
            {rightButtonLabel || t('common.save')}
          </button>
        </div>

        <div className={classes.footer}>
          <span>
            {t(
              'dashboardComponents.Reports.RightPanel.SaveChangesModal.saveAs'
            )}
          </span>
          <input
            type="text"
            value={reportName}
            onChange={(event) => setReportName(event.target.value)}
            placeholder={t(
              'dashboardComponents.Reports.RightPanel.SaveChangesModal.typeAName'
            )}
            maxLength={30}
          />
        </div>
      </div>
    </Modal>
  );
}
