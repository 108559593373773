import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Dots({
  media,
  currentMediaIndex,
  setCurrentMediaIndex,
}) {
  return (
    <div className={classes.Dots}>
      {media.map((mda, index) => (
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            setCurrentMediaIndex(index);
          }}
          key={mda.url}
          className={classNames(classes.dot, {
            [classes.active]: currentMediaIndex === index,
          })}
        />
      ))}
    </div>
  );
}
