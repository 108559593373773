import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function PopupButton({
  children,
  width,
  height,
  variant,
  onClick,
  disabled,
}) {
  return (
    <button
      type="button"
      className={classNames(classes.PopupButton, {
        [classes.muted]: variant === 'muted',
        [classes.green]: variant === 'green',
        [classes.dark]: variant === 'dark',
        [classes.mutedFilled]: variant === 'mutedFilled',
      })}
      disabled={disabled}
      style={{ width, height }}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
