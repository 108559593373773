import React from 'react';

import PreviewHeader from './PreviewHeader';
import Preview from './Preview';

import classes from './styles.module.scss';

export default function RightPanel({
  fonts,
  colors,
  logoSettings,
  tenantSettings,
  refetchTenantSettings,
  activeTab,
}) {
  return (
    <div className={classes.RightPanel}>
      <PreviewHeader
        fonts={fonts}
        colors={colors}
        logoSettings={logoSettings}
        tenantSettings={tenantSettings}
        refetchTenantSettings={refetchTenantSettings}
      />
      <Preview
        brandColors={colors}
        logoSettings={logoSettings}
        fonts={fonts}
        activeTab={activeTab}
      />
    </div>
  );
}
