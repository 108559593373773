import { useState, useEffect, useCallback, useRef } from 'react';

export default function useDebouncedSearchWithHistory(searchTerm, key) {
  const [searchHistory, setSearchHistory] = useState([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const timer = useRef();

  const getSearchHistory = useCallback(() => {
    const savedSearchHistory = localStorage.getItem(key);

    if (!savedSearchHistory) {
      return [];
    }

    try {
      return JSON.parse(savedSearchHistory);
    } catch (error) {
      return [];
    }
  }, [key]);

  const saveSearchHistory = useCallback(
    (term) => {
      const history = getSearchHistory();

      if (history.includes(term)) {
        return;
      }

      if (history.length === 4) {
        history.pop();
      }

      history.unshift(term);
      localStorage.setItem(key, JSON.stringify(history));
      setSearchHistory(history);
    },
    [getSearchHistory, key]
  );

  useEffect(() => {
    const history = getSearchHistory();
    setSearchHistory(history);
  }, [getSearchHistory]);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      if (searchTerm.trim()) {
        saveSearchHistory(searchTerm);
      }
    }, 500);
  }, [saveSearchHistory, searchTerm]);

  return { debouncedSearchTerm, searchHistory };
}
