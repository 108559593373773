import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Contact from '../Contact';

import classes from './styles.module.scss';

export default function Group({
  group,
  activeContactId,
  setActiveContactId,
  refetchChatThreads,
  type,
}) {
  const [isContactsListVisible, setIsContactsListVisible] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (type === 'pinned') {
      setIsContactsListVisible(true);
    }
  }, [type]);

  return (
    <div
      className={classNames(classes.Group, {
        [classes.open]: isContactsListVisible,
      })}
    >
      <div
        className={classes.header}
        onClick={() => setIsContactsListVisible((prevState) => !prevState)}
      >
        <div className={classes.threadCount}>{group.threads.length}</div>
        <h3 className={classes.title}>
          {group.jobPostId} - {group.jobTitle}
        </h3>
        <div
          className={classNames(classes.status, {
            [classes.active]: group.jobPostStatus === 'Active',
            [classes.onHold]: group.jobPostStatus === 'OnHold',
          })}
        >
          {t(`common.jobStatuses.${group.jobPostStatus}`)}
        </div>
      </div>
      {isContactsListVisible && (
        <ul className={classes.contactsList}>
          {group.threads.map((contact) => {
            return (
              <Contact
                activeContactId={activeContactId}
                setActiveContactId={setActiveContactId}
                key={contact.jobApplicationId}
                contact={contact}
                refetchChatThreads={refetchChatThreads}
              />
            );
          })}
        </ul>
      )}
    </div>
  );
}
