import React from 'react';
import DotMenu from '../../DotMenu';
import FavStatus from '../../FavStatus';
import classes from './styles.module.scss';

export default function CalendarEventCard({
  calendarEvent,
  setIsEventDetailsVisible,
  setSelectedEvent,
  userId,
  refetch,
}) {
  const { type, jobPost, userFirstName, userLastName } = calendarEvent;
  const eventDate = new Date(calendarEvent.eventDate);
  const date = eventDate.getDate();
  const month = eventDate.toLocaleString('en-US', { month: 'short' });
  const year = eventDate.getFullYear();

  return (
    <div
      className={classes.CalendarEventCard}
      onClick={() => {
        setSelectedEvent(calendarEvent);
        setIsEventDetailsVisible(true);
      }}
    >
      <div className={classes.calendarIcon}>
        <span className={classes.month}>{month.toUpperCase()}</span>
        <span className={classes.date}>{date}</span>
      </div>
      <div className={classes.info}>
        <span
          className={classes.title}
        >{`${type} for ${jobPost.jobTitle}`}</span>
        <div className={classes.container}>
          <span className={classes.time}>{`${date} ${month} ${year}`}</span>
          <span
            className={classes.person}
          >{`${userFirstName} ${userLastName}`}</span>
        </div>
      </div>
      <div className={classes.settings}>
        <FavStatus
          calendarEvent={calendarEvent}
          userId={userId}
          refetch={refetch}
        />
        <DotMenu event={calendarEvent} />
      </div>
    </div>
  );
}
