import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function RadioButton({
  isSelected,
  onClick,
  label,
  bigAndBold,
  readOnly,
  labelStyle,
}) {
  return (
    <div
      className={classNames(classes.RadioButton, {
        [classes.selected]: isSelected,
        [classes.bigAndBold]: bigAndBold,
      })}
      onClick={() => {
        if (readOnly) {
          return;
        }

        onClick();
      }}
    >
      <div className={classes.button} />
      <div className={classes.label} style={labelStyle}>
        {label}
      </div>
    </div>
  );
}
