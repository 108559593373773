import React, { useContext } from 'react';

import NonFavoritesIcon from '../../Icons/NonFavoritesIcon';
import FavoritesIcon from '../../Icons/FavoritesIcon';

import { UiContext } from '../../../../../../context/UiContext';
import { getAuthData } from '../../../../../../helpers/authStorage';
import classes from './styles.module.scss';
import AdminService from '../../../../../../services/AdminService';

export default function CandidateFavorites({
  favoritesList,
  refetch,
  jobApplicationId,
  width = 20,
  height = 20,
  white,
}) {
  const { userId } = getAuthData();

  const { setIsFetching } = useContext(UiContext);

  const isFavorite = favoritesList?.some?.(
    (favorite) => favorite.userId === +userId
  );

  const stopPropagationAndPreventDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const addToFavorites = async (event) => {
    stopPropagationAndPreventDefault(event);
    try {
      setIsFetching(true);

      await AdminService.addMyJobApplicationToFavorites({ jobApplicationId });

      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const removeFromFavorites = async (event) => {
    stopPropagationAndPreventDefault(event);
    try {
      setIsFetching(true);

      await AdminService.removeMyJobApplicationFromFavorites({
        jobApplicationId,
      });

      if (refetch) {
        await refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div
      className={classes.CandidateFavorites}
      onClick={isFavorite ? removeFromFavorites : addToFavorites}
    >
      {isFavorite ? (
        <FavoritesIcon size={width} />
      ) : (
        <NonFavoritesIcon size={height} white={white} />
      )}
    </div>
  );
}
