export function placeCaretAtEnd(elementRef) {
  elementRef.current.focus();
  if (
    typeof window.getSelection !== 'undefined' &&
    typeof document.createRange !== 'undefined'
  ) {
    const range = document.createRange();
    range.selectNodeContents(elementRef.current);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange !== 'undefined') {
    const textRange = document.body.createTextRange();
    textRange.moveToElementText(elementRef.current);
    textRange.collapse(false);
    textRange.select();
  }
}

export function getCaretPosition() {
  const caret = { top: 0, left: 0 };
  const sel = window.getSelection();
  caret.top = 0;
  caret.height = 0;
  if (sel.rangeCount) {
    const range = sel.getRangeAt(0);
    const rects = range.getClientRects();
    if (rects.length > 0) {
      caret.top = rects[0].top;
      caret.left = rects[0].left;
      caret.height = rects[0].height;
    } else {
      caret.left = range.startContainer.offsetLeft;
      caret.top = range.startContainer.offsetTop - document.body.scrollTop;
      caret.height = range.startContainer.clientHeight;
    }
  }
  return caret;
}
